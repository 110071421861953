import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import { TooltipTip } from '../Tooltip/styled';
import { ZIndexes } from 'common/constants';

export const SelectionPanelContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.25rem;
`;

export const StyledTooltip = styled(Tooltip)`
	${TooltipTip} {
		background-color: ${({ theme }) => theme.colors.primary.main};
		border: 1px solid ${({ theme }) => theme.colors.common.black};
		opacity: 0.8;
		padding: 0.5rem 2rem;
		font-size: 0.9rem;
		z-index: ${ZIndexes.simulationSettingsMenu};
		&::before {
			border-top-color: ${({ theme }) => theme.colors.primary.main};
		}
	}
`;
