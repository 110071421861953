import { Portal } from 'common/components';
import { BackgroundOverlay, ChartContentContainer, SideButton, SideButtonsContainer } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { SettingsMenuView } from '../../../Simulation/views/SettingsMenuView';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsChartSettingsViewOpened, toggleChartSettingsView } from 'store/slices';
import {
	DiscoveryInsightResultsProps,
	DiscoveryInsightSettings,
	DistributionPercentageDnaTypes,
	ViewableType,
	ViewableTypes,
} from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import {
	ClusterSelection,
	DiscoveryInsightResultsMenu,
	DistributionTableView,
	QuadrantGraphType,
	QuadrantGraphTypes,
	QuadrantGraphView,
	QuadrantLegendSortingTypes,
	ViewableTypeSelection,
} from 'features/DiscoveryInsights/components';
import { useEffect, useRef, useState } from 'react';
import { ClusterId } from 'common/types';
import {
	useDiscoveryInsightQuadrantData,
	useDistributionPercentageData,
	useTotalAndCriteriaViewQuadrantData,
} from 'features/DiscoveryInsights/hooks';
import { DefaultClusterId } from 'common/constants';
import { CSSTransition } from 'react-transition-group';
import { AnimationContainer } from 'common/components/AnimatedGraphContainers';

export const DiscoveryInsightResults = ({
	benchmarkConfigurationId,
	projectId,
	data,
	isOpened,
	onClose,
}: DiscoveryInsightResultsProps) => {
	const dispatch = useDispatch();

	const fetchPartialQuadrants = useTotalAndCriteriaViewQuadrantData();
	const fetchAllQuadrants = useDiscoveryInsightQuadrantData();
	const fetchDistributionPercentages = useDistributionPercentageData();

	const [settings, setSettings] = useState(defaultSettings());
	const [viewableType, setViewableType] = useState<ViewableType>(ViewableTypes.QUADRANT_GRAPH);
	const [clusterId, setClusterId] = useState<ClusterId>(DefaultClusterId.Cl00);
	const [quadrantData, setQuadrantData] = useState({
		totalView: data.totalView,
		criteriaView: data.criteriaView,
		highlights: data.highlights,
	});
	const [distributionData, setDistributionData] = useState(data.percentageDistribution);
	const [distributionCriterion, setDistributionCriterion] = useState(settings.criteria);
	const [distributionDNA, setDistributionDNA] = useState(DistributionPercentageDnaTypes.PDNA);

	useEffect(refreshDistributionTableData, [distributionCriterion]);
	useEffect(refreshTotalAndCriteriaView, [settings.criteria]);
	useEffect(refreshQuadrantView, [settings.quadrantCenter, settings.totalThreshold]);

	const backgroundOverlayRef = useRef(null);
	const isSettingsMenuOpened = useSelector(selectIsChartSettingsViewOpened);

	return isOpened ? (
		<Portal wrapperId="discovery-insight-root">
			<BackgroundOverlay>
				<SettingsMenuView>
					<DiscoveryInsightResultsMenu
						criteria={data.criteria}
						settings={settings}
						onSaveSettings={setSettings}
						onQuadrantTypeChange={setQuadrantType}
					/>
				</SettingsMenuView>
				<SideButtonsContainer>
					<SideButton onClick={() => dispatch(toggleChartSettingsView(true))}>
						<FontAwesomeIcon icon={faGear} />
					</SideButton>
					<SideButton onClick={onClose}>
						<FontAwesomeIcon icon={faHouse} />
					</SideButton>
				</SideButtonsContainer>
				<ViewableTypeSelection
					chosenViewableType={viewableType}
					onSelect={setViewableType}
				/>
				<CSSTransition
					in={!isSettingsMenuOpened}
					ref={backgroundOverlayRef}
					timeout={750}
				>
					<AnimationContainer
						ref={backgroundOverlayRef}
						key={`${viewableType}${clusterId}${settings}`}
					>
						<ChartContentContainer>{viewableContainer()}</ChartContentContainer>
					</AnimationContainer>
				</CSSTransition>
				<ClusterSelection onClusterSelection={setClusterId} />
			</BackgroundOverlay>
		</Portal>
	) : (
		<></>
	);

	function viewableContainer() {
		switch (viewableType) {
			case ViewableTypes.QUADRANT_GRAPH:
				return (
					<QuadrantGraphView
						clusterId={clusterId}
						quadrantCenter={settings.quadrantCenter}
						colorPresetId={settings.colorPresetId}
						quadrantType={settings.quadrantType}
						quadrantLegendSorting={settings.quadrantLegendSorting}
						groupFilterThreshold={settings.totalThreshold}
						data={quadrantData}
					/>
				);
			case ViewableTypes.DISTRIBUTION_TABLE:
				return (
					<DistributionTableView
						colorPresetId={settings.colorPresetId}
						clusterId={clusterId}
						selectedCriterion={distributionCriterion}
						criteria={data.criteria}
						distributionDna={distributionDNA}
						data={distributionData}
						groupFilterThreshold={settings.totalThreshold}
						onDistributionCriteriaChange={setDistributionCriterion}
						onDnaTypeChange={setDistributionDNA}
					/>
				);
		}
	}

	function refreshTotalAndCriteriaView() {
		const highlights = quadrantData.highlights;
		fetchPartialQuadrants(
			benchmarkConfigurationId,
			selectedCriteriaId(),
			availableCriteriaId(),
			settings.quadrantCenter,
			projectId,
		).then((response) => {
			const totalView = response.totalView;
			const criteriaView = response.criteriaView;
			setQuadrantData({ criteriaView, totalView, highlights });
		});
	}

	function refreshQuadrantView() {
		fetchAllQuadrants(
			benchmarkConfigurationId,
			selectedCriteriaId(),
			availableCriteriaId(),
			settings.quadrantCenter,
			settings.totalThreshold,
			projectId,
		).then((response) => {
			const totalView = response.totalView;
			const criteriaView = response.criteriaView;
			const highlights = response.highlights;
			setQuadrantData({ criteriaView, totalView, highlights });
		});
	}

	function refreshDistributionTableData() {
		const criterionId = Number(distributionCriterion.id);
		fetchDistributionPercentages(benchmarkConfigurationId, criterionId, projectId).then(setDistributionData);
	}

	function setQuadrantType(quadrantType: QuadrantGraphType) {
		setSettings({ ...settings, quadrantType });
	}

	function defaultSettings(): DiscoveryInsightSettings {
		const first = 0;
		return {
			quadrantType: QuadrantGraphTypes.TOTAL_VIEW,
			criteria: data.criteria[first],
			quadrantLegendSorting: QuadrantLegendSortingTypes.PERCENTAGE_ASCENDING,
			quadrantCenter: { x: 2.5, y: 2.5 },
			colorPresetId: first,
			totalThreshold: 5,
		};
	}

	function selectedCriteriaId() {
		return Number(settings.criteria.id);
	}

	function availableCriteriaId() {
		return data.criteria.map((el) => Number(el.id));
	}
};
