import { IconButton } from 'common/components/IconButton';
import { ZIndexes } from 'common/constants';
import styled from 'styled-components';

export const BackgroundOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: ${ZIndexes.simulation};
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.common.white};
`;

export const SideButtonsContainer = styled.div`
	z-index: ${ZIndexes.simulationNavigation};
	position: absolute;
	height: 100%;
	top: 0em;
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const SideButton = styled(IconButton)`
	background-color: ${({ theme }) => theme.colors.primary.main};
	color: ${({ theme }) => theme.colors.common.white};
	width: 40px;
	height: 40px;
	font-size: 1.5rem;
	border-radius: 0 7.5px 7.5px 0;
	opacity: 0.6;
	transition: opacity 0.25s ease-in-out;

	:hover {
		opacity: 1;
	}
`;

export const ChartContentContainer = styled.div`
	position: relative;
	height: 90vh;
	width: 100vw;
	z-index: ${ZIndexes.simulationContent};
	display: flex;
	justify-content: space-evenly;
	pointer-events: none;
`;
