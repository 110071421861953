import { ApiCluster } from 'common/types';
import { rootApi } from 'store/api';
import { AllClustersResponse } from './clustersApi.types';

export const clustersApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllClusters: builder.query<AllClustersResponse<ApiCluster>, void>({
			query: () => ({
				url: `/api/clusters`,
			}),
		}),
	}),
});

export const { useGetAllClustersQuery } = clustersApi;
