import Builder from '@support/alderbrooke-d3-graphs/dist/Line/Builder';
import SvgElement from '@support/alderbrooke-d3-graphs/dist/Common/SvgElement';
import { ILineChartData } from '@support/alderbrooke-d3-graphs/dist/Line/types';

export class LineChartBuilder {
	constructor(private _data: ILineChartData) {}

	private sortGroups = () => {
		const groups = this._data.groups;

		return groups.sort((a, b) => a.groupId - b.groupId);
	};

	build(): SvgElement {
		const theme = {
			color: {
				series: {
					pdna: '#5FC1FE',
					odna: '#EC7970',
				},
				background: '#404040',
				tickLines: '#7F7F7F',
			},
		};

		const sortedData = {
			...this._data,
			groups: this.sortGroups(),
		};

		return new Builder(sortedData, theme).build();
	}
}
