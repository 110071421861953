import { AdditionalQuestion, ApiAdditionalQuestion } from 'common/types';
import { GetAdditionalProjectQuestionsResponse, useGetAdditionalProjectQuestionsQuery } from 'store/api';

type UseAdditionalQuestionsProps = {
	projectId?: string;
};

export const useAdditionalQuestions = (option?: UseAdditionalQuestionsProps) => {
	const additionalQuestions = useGetAdditionalProjectQuestionsQuery(
		{ projectId: option?.projectId as string },
		{ skip: option?.projectId == null },
	);

	const questions = getProjectDetailsData();

	return {
		additionalQuestions: questions ? transform(questions) : undefined,
	};

	function getProjectDetailsData(): GetAdditionalProjectQuestionsResponse<ApiAdditionalQuestion> | undefined {
		if (!additionalQuestions.isSuccess) return undefined;

		return additionalQuestions.data;
	}

	function transform(
		additionalQuestions: GetAdditionalProjectQuestionsResponse<ApiAdditionalQuestion>,
	): GetAdditionalProjectQuestionsResponse<AdditionalQuestion> {
		return additionalQuestions;
	}
};
