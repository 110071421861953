import {
	useLazyGetAssignmentStatusByProjectIdQuery,
	AssignmentStatusResponse,
	AssignmentStatusOptions,
} from 'store/api';
import { ApiAssignmentStatus, AssignmentStatus } from 'common/types';
import { transformAssignmentStatusResponse } from './mappers';
import { getAssignmentStatusEmptyResponse } from 'store/api';
import { useCriteriaConfiguration } from '../participants/useCriteriaConfiguration';

export const useAssignmentStatus = () => {
	const criteriaConfiguration = useCriteriaConfiguration();
	const emptyResponse = getAssignmentStatusEmptyResponse();

	const [getAssignmentsStatusByProjectId, assignmentsStatusByProjectId] =
		useLazyGetAssignmentStatusByProjectIdQuery();

	const assignmentsStatus = getAssignmentsStatusData();

	return {
		getAssignmentsStatus,
		assignmentsStatus: transform(assignmentsStatus),
	};

	function getAssignmentsStatusData(): AssignmentStatusResponse<ApiAssignmentStatus> {
		if (!assignmentsStatusByProjectId.isSuccess) return emptyResponse;

		return assignmentsStatusByProjectId.data;
	}

	async function getAssignmentsStatus(
		options: AssignmentStatusOptions,
	): Promise<AssignmentStatusResponse<ApiAssignmentStatus>> {
		const response = await getAssignmentsStatusByProjectId(options);

		if (!response || response.data === undefined) {
			return emptyResponse;
		}

		return response.data;
	}

	function transform(
		assignmentsStatus: AssignmentStatusResponse<ApiAssignmentStatus>,
	): AssignmentStatusResponse<AssignmentStatus> {
		return transformAssignmentStatusResponse(assignmentsStatus, criteriaConfiguration);
	}
};
