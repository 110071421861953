import { RadarGraphCategoryLabel, RadarGraphData, RadarGraphDataSeries } from 'features/Graphs';
import { Dna } from './utils.types';

export const prepareSingleDnaGraphData = (dna: Dna, benchmarkIndexes: boolean[], clusterIndex: number) => {
	const defaultBenchmarkIndex = 0;
	let radarData: RadarGraphData = {
		categoryLabels: [],
		data: [],
		title: '',
	};

	let data: RadarGraphDataSeries[] = [];

	benchmarkIndexes.forEach((benchmarkEnabled, index) => {
		const benchmarkExists = dna.clusters[clusterIndex].benchmarks[index];
		if (benchmarkEnabled && benchmarkExists) {
			const selectedCluster = dna.clusters[clusterIndex];
			const currentBenchmark = selectedCluster.benchmarks[index];
			data = data.concat(currentBenchmark.data);
		}
	});

	const atLeastOneBenchmarkChecked = benchmarkIndexes.includes(true);
	const benchmarkContainsData = data.length;

	const shouldGetCategoryLabels = atLeastOneBenchmarkChecked && benchmarkContainsData;
	const categoryLabelsIndex = dna.clusters[clusterIndex].benchmarks.findIndex((value) => value.categoryLabels.length);

	const categoryLabels: RadarGraphCategoryLabel[] = shouldGetCategoryLabels
		? dna.clusters[clusterIndex].benchmarks[categoryLabelsIndex].categoryLabels
		: [];

	radarData = {
		categoryLabels,
		data,
		title: dna.clusters[clusterIndex].benchmarks[defaultBenchmarkIndex].title,
	};

	return radarData;
};
