import { ColumnDef } from '@tanstack/react-table';

import { Participant, TableAction } from 'common/types';
import { Criterion } from 'common/types';
import { Tooltip } from 'common/components';
import { useGetLoggedInUserQuery } from 'store/api';
import React, { useState } from 'react';
import { AssessedParticipantsColumnIds } from './ParticipantsColumnIds';
import { faPenToSquare, faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { TableWrapper, TableIconButton } from 'features/Tables/components';

export const useAssessedParticipantsData = (setModalState: React.Dispatch<React.SetStateAction<boolean>>) => {
	const loggedUser = useGetLoggedInUserQuery();
	const criteriaColumns: ColumnDef<Participant>[] = createCriteriaColumns();

	const [participantToDeactivate, setParticipantToDeactivate] = useState({
		participantName: '',
		participantId: '',
	});

	const onDeactivateClick = (row: Participant | undefined) => {
		if (!row) return;

		setModalState(true);
		setParticipantToDeactivate({
			participantId: row.participantId,
			participantName: `${row.firstName} ${row.lastName}`,
		});
	};

	const actions = React.useMemo<TableAction<Participant>[]>(
		() => [
			{
				label: 'Edit',
				icon: faPenToSquare,
				getHref: (participant: Participant) => `/assessees/${participant.participantId}`,
			},
			{
				label: 'Deactivate',
				icon: faUserMinus,
				onClick: (row) => onDeactivateClick(row),
			},
		],
		[],
	);

	const personalColumns: ColumnDef<Participant>[] = [
		{
			id: AssessedParticipantsColumnIds.Email,
			header: 'Email',
			accessorFn: (p) => p.email,
		},
		{
			id: AssessedParticipantsColumnIds.FirstName,
			header: 'First',
			accessorFn: (p) => p.firstName,
		},
		{
			id: AssessedParticipantsColumnIds.LastName,
			header: 'Last Name',
			accessorFn: (p) => p.lastName,
		},
	];

	const actionColumn: ColumnDef<Participant> = {
		id: AssessedParticipantsColumnIds.Actions,
		header: 'Actions',
		accessorFn: (row) => row,
		cell: ({ getValue }) => {
			const participant = getValue<Participant>();
			return (
				<TableWrapper>
					{actions.map((action) => {
						if (action.isHidden?.(participant)) return;
						return (
							<Tooltip
								key={action.label}
								content={action.label}
								delay={0}
							>
								<TableIconButton
									icon={action.icon}
									href={action.getHref ? action.getHref(participant) : undefined}
									onClick={() => {
										action.onClick && action.onClick(participant);
									}}
								/>
							</Tooltip>
						);
					})}
				</TableWrapper>
			);
		},
		enableSorting: false,
		enableColumnFilter: false,
	};

	const columns = React.useMemo<ColumnDef<Participant>[]>(
		() => personalColumns.concat(criteriaColumns).concat(actionColumn),
		[loggedUser.isSuccess],
	);

	const visibleColumns = columns.filter((col) =>
		[
			AssessedParticipantsColumnIds.Email,
			AssessedParticipantsColumnIds.FirstName,
			AssessedParticipantsColumnIds.LastName,
			AssessedParticipantsColumnIds.Actions,
		].includes(col.id as string),
	);

	return {
		columns,
		visibleColumns,
		participantToDeactivate,
	};

	function createCriteriaColumns(): ColumnDef<Participant>[] {
		const criteria = loggedUser.data?.configuration.criteriaConfiguration ?? [];

		const createColumn = (criterion: Criterion): ColumnDef<Participant> => ({
			id: `c${criterion.id}`,
			header: criterion.name,
			accessorFn: (participant: Participant) => participant.criteria.findById(criterion.id).name,
		});

		return criteria.map(createColumn);
	}
};
