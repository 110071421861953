import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'common/hooks';
import { LoginBackground } from './styled';
import { LoginBox } from 'features/AuthManagement/views';
import { AnimatedBackground, Footer } from 'features/AuthManagement/views';

export const LoginPage = () => {
	const auth = useAuth();

	const navigate = useNavigate();

	useEffect(() => {
		if (auth.user?.permissions) {
			navigate('/');
		}
	}, [auth.user]);

	return (
		<div id="login">
			<LoginBackground>
				<AnimatedBackground />
				<LoginBox />
				<Footer />
			</LoginBackground>
		</div>
	);
};
