import styled from 'styled-components';

export const StyledSidebar = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.common.white};
	border-right: 1px solid ${({ theme }) => theme.colors.common.silver};
	margin-right: 1rem;
	width: 19.5rem;
	position: relative;
`;

export const AnchoredMenuFooter = styled.div`
	position: absolute;
	bottom: 0px;
	z-index: 1;
`;
