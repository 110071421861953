import styled from 'styled-components';
import { Input } from 'common/components/Input';
import { StyledInput as $StyledInput } from 'common/components/Input/styled';

export const StyledInput = styled(Input)`
	margin: 0;
	color: ${({ theme }) => theme.colors.common.mediumGrey};

	${$StyledInput} {
		height: 2.5rem;
	}
`;
