import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const DropdownItemListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	user-select: none;
	font-weight: 300;
	padding: 0.2rem 1.2rem;

	&:not(:last-of-type) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.common.lightSilver};
	}
`;

export const StyledIcon = styled(FontAwesomeIcon)`
	padding-right: 0.1rem;
`;
