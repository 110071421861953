import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapGraphDna, HeatmapGraphState } from './heatmapSlice.types';
import { heatmapGraphConfig } from 'features/Graphs/HeatmapGraph/HeatmapGraphConfig';
import { HeatmapGraphConfig } from 'features/Graphs/HeatmapGraph';
import { RootState } from 'store/store';

const emptyDna: HeatmapGraphDna = {
	clusters: [
		{
			benchmarks: [
				{
					label: '',
					left: {
						indicator: 0,
						numberOfParticipants: 0,
						values: [],
					},
					onHeaderClick: () => null,
					right: {
						indicator: 0,
						numberOfParticipants: 0,
						values: [],
					},
				},
			],
			labels: {
				left: [],
				right: [],
			},
			clusterName: '',
		},
	],
};

const initialState: HeatmapGraphState = {
	chartConfig: heatmapGraphConfig,
	ODNA: emptyDna,
	PDNA: emptyDna,
	COMBINED_ODNA: emptyDna,
	COMBINED_PDNA: emptyDna,
};

export const heatmapGraphSlice = createSlice({
	name: 'heatmapGraph',
	initialState,
	reducers: {
		setHeatmapGraphState: (state, { payload }: PayloadAction<HeatmapGraphState>) => {
			state = payload;
			return state;
		},
		setHeatmapGraphConfig: (state, { payload }: PayloadAction<HeatmapGraphConfig>) => {
			state.chartConfig = payload;
			return state;
		},
		setHeatmapGraphODNA: (state, { payload }: PayloadAction<HeatmapGraphDna>) => {
			state.ODNA = payload;
			return state;
		},
		setHeatmapGraphCombinedODNA: (state, { payload }: PayloadAction<HeatmapGraphDna>) => {
			state.COMBINED_ODNA = payload;
			return state;
		},
		setHeatmapGraphPDNA: (state, { payload }: PayloadAction<HeatmapGraphDna>) => {
			state.PDNA = payload;
			return state;
		},
		setHeatmapGraphCombinedPDNA: (state, { payload }: PayloadAction<HeatmapGraphDna>) => {
			state.COMBINED_PDNA = payload;
			return state;
		},
		resetHeatmapGraphState: () => initialState,
	},
});

export const {
	setHeatmapGraphState,
	resetHeatmapGraphState,
	setHeatmapGraphConfig,
	setHeatmapGraphODNA,
	setHeatmapGraphCombinedODNA,
	setHeatmapGraphPDNA,
	setHeatmapGraphCombinedPDNA,
} = heatmapGraphSlice.actions;
export const heatmapReducer = heatmapGraphSlice.reducer;
export const selectHeatmapGraphODNA = (state: RootState) => state.heatmapGraph.ODNA;
export const selectHeatmapGraphPDNA = (state: RootState) => state.heatmapGraph.PDNA;
export const selectHeatmapGraphCombinedPDNA = (state: RootState) => state.heatmapGraph.COMBINED_PDNA;
export const selectHeatmapGraphCombinedODNA = (state: RootState) => state.heatmapGraph.COMBINED_ODNA;
export const selectHeatmapGraphConfig = (state: RootState) => state.heatmapGraph.chartConfig;
