import { rootApi } from 'store/api';
import {
	ApiRoadmapSummary,
	DeleteRoadmapOptions,
	RoadmapId,
	RoadmapDTO,
	CreatedRoadmap,
	CreateRoadmapRequest,
	SaveRoadmapResponse,
	UpdateRoadmapRequest,
	SaveProjectsRoadmapRequest,
	CreateProjectRoadmapRequest,
	SaveRoadmapBody,
	UpdateRoadmapResponse,
} from './roadmapApi.types';
import { ProjectId } from 'common/types';

export const roadmapApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getRoadmaps: builder.query<ApiRoadmapSummary[], void>({
			query: () => ({
				url: `/api/roadmaps`,
			}),
			providesTags: ['Roadmaps'],
		}),
		getProjectRoadmaps: builder.query<ApiRoadmapSummary[], ProjectId>({
			query: (projectId) => ({
				url: `/api/projects/${projectId}/roadmaps`,
			}),
			providesTags: ['Roadmaps'],
		}),
		deleteRoadmap: builder.mutation<void, DeleteRoadmapOptions>({
			query: (opts) => ({
				url: `/api/roadmaps/${opts.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Roadmaps'],
		}),
		viewRoadmap: builder.query<RoadmapDTO, RoadmapId>({
			query: (id) => ({
				url: `/api/roadmaps/${id}`,
				invalidatesTags: ['Roadmaps'],
			}),
			keepUnusedDataFor: 0,
		}),
		createRoadmap: builder.mutation<CreatedRoadmap, CreateRoadmapRequest>({
			query: (opts) => ({
				url: `/api/roadmaps/create`,
				body: opts,
				method: 'POST',
				invalidatesTags: ['Roadmaps'],
			}),
		}),
		createProjectRoadmap: builder.mutation<CreatedRoadmap, CreateProjectRoadmapRequest>({
			query: (opts) => ({
				url: `api/projects/${opts.projectId}/roadmaps/create`,
				body: opts.requestBody,
				method: 'POST',
				invalidatesTags: ['Roadmaps'],
			}),
		}),
		saveRoadmap: builder.mutation<SaveRoadmapResponse, SaveRoadmapBody>({
			query: (opts) => ({
				url: `api/projects/roadmaps/save`,
				body: opts,
				method: 'POST',
			}),
			invalidatesTags: ['Roadmaps'],
		}),
		updateRoadmap: builder.mutation<UpdateRoadmapResponse, UpdateRoadmapRequest>({
			query: (opts) => ({
				url: `api/roadmaps/${opts.roadmapId}`,
				body: {
					name: opts.name,
					colourPresetId: { id: opts.colourPresetId },
				},
				method: 'PUT',
			}),
			invalidatesTags: ['Roadmaps'],
		}),
		saveProjectsRoadmap: builder.mutation<SaveRoadmapResponse, SaveProjectsRoadmapRequest>({
			query: (opts) => ({
				url: `api/projects/${opts.projectId}/roadmaps/save`,
				body: opts.requestBody,
				method: 'POST',
			}),
			invalidatesTags: ['Roadmaps'],
		}),
	}),
});

export const {
	useGetProjectRoadmapsQuery,
	useGetRoadmapsQuery,
	useDeleteRoadmapMutation,
	useViewRoadmapQuery,
	useCreateRoadmapMutation,
	useCreateProjectRoadmapMutation,
	useSaveRoadmapMutation,
	useUpdateRoadmapMutation,
	useSaveProjectsRoadmapMutation,
} = roadmapApi;
