import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { useUnlockTenantAdminMutation } from 'store/api';
import { UnlockClientAdminConfirmDialogProps } from './UnlockClientAdminConfirmDialog.types';

export const UnlockClientAdminConfirmDialog = ({
	isOpen,
	tenantAdminToUnlock,
	onClose,
	...additionalModalProps
}: UnlockClientAdminConfirmDialogProps) => {
	const [unlockTenantAdmin] = useUnlockTenantAdminMutation();
	const { pushNotification } = useNotifications();

	const onUnlockTenantAdmin = async () => {
		try {
			await unlockTenantAdmin({ tenantAdminId: tenantAdminToUnlock.tenantAdminId }).unwrap();

			pushNotification({
				message: 'Client administrator successfully unlocked',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Something went wrong during the last operation!',
				type: NotificationTypes.Error,
			});
		}
	};

	return (
		<ConfirmationDialog
			id="unlock-client-admin-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onUnlockTenantAdmin}
			title={`Are you sure that you want to unlock ${tenantAdminToUnlock?.username}?`}
			{...additionalModalProps}
		/>
	);
};
