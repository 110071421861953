import styled from 'styled-components';

export const ChartContainer = styled.div`
	margin: 0 auto;
	display: flex;
	flex: 1;
`;

export const SvgContainer = styled.div`
	position: relative;
	height: auto;
	width: 50%;
`;
