import { ProjectResultsGraphType, ProjectResultsGraphTypes } from 'features/Graphs/common';

export const isPdna = (type: ProjectResultsGraphType) => {
	return type === ProjectResultsGraphTypes.PDNA;
};

export const isOdna = (type: ProjectResultsGraphType) => {
	return type === ProjectResultsGraphTypes.ODNA;
};

export const isCombinedPdna = (type: ProjectResultsGraphType) => {
	return type === ProjectResultsGraphTypes.COMBINED_PDNA;
};

export const isCombinedOdna = (type: ProjectResultsGraphType) => {
	return type === ProjectResultsGraphTypes.COMBINED_ODNA;
};
