import { Tooltip } from 'common/components/Tooltip';
import { Button } from 'common/components/Button';
import styled, { css } from 'styled-components';
import { StyledBehaviourButtonProps } from './BehaviourPairTileButton.types';
import { TooltipTip } from 'common/components/Tooltip/styled';

export const TileContainer = styled.div`
	display: flex;

	& > :nth-child(1) {
		margin-right: 1px;
		flex-grow: 1;
		width: 0;
	}

	& > :nth-child(2) {
		margin-left: 1px;
		flex-grow: 1;
		width: 0;
	}
`;

const colouredBehaviourButtonStyles = css<StyledBehaviourButtonProps>`
	color: ${({ theme }) => theme.colors.common.white};
	background-color: ${({ $color }) => $color};
`;

const greyedOutBehaviourButtonStyles = css<StyledBehaviourButtonProps>`
	color: ${({ theme }) => theme.colors.greyedOut.dark};
	background-color: ${({ theme }) => theme.colors.greyedOut.main};
	border: 2px solid ${({ theme }) => theme.colors.greyedOut.dark};
`;

export const BehaviourButton = styled(Button)<StyledBehaviourButtonProps>`
	border: 2px solid ${({ theme }) => theme.colors.common.black};
	color: ${({ $color }) => $color};
	width: 100%;

	${({ $selectionVariant }) => $selectionVariant === 'selected' && colouredBehaviourButtonStyles};
	${({ $selectionVariant }) => $selectionVariant === 'not-selected' && greyedOutBehaviourButtonStyles};
`;

const tooltipCustomDirectionTopArrow = css`
	border-width: 0 1px 1px 0;
	padding: 5px;
	margin-top: -5px;
	transform: rotate(45deg);
`;

const tooltipCustomDirectionLeftArrow = css`
	border-width: 0 1px 1px 0;
	padding: 5px;
	margin-right: 6px;
	transform: rotate(315deg);
`;

const tooltipCustomDirectionRightArrow = css`
	border-width: 0 1px 1px 0;
	padding: 5px;
	margin-left: 0;
	transform: rotate(135deg);
`;

export const StyledTooltip = styled(Tooltip)`
	& > ${TooltipTip} {
		border: 1px solid ${({ theme }) => theme.colors.common.black};
		background-color: ${({ theme }) => theme.colors.common.white};
		min-width: 15rem;
		padding: 0;

		::before {
			border: solid ${({ theme }) => theme.colors.common.black};
			background-color: ${({ theme }) => theme.colors.common.white};

			${({ direction }) => direction === 'top' && tooltipCustomDirectionTopArrow};
			${({ direction }) => direction === 'left' && tooltipCustomDirectionLeftArrow};
			${({ direction }) => direction === 'right' && tooltipCustomDirectionRightArrow};
		}
	}
`;

export const TooltipContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TooltipTitle = styled.p`
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.common.mediumGrey};
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.common.silver};
	font-size: 1.2rem;
	font-weight: 200;
	margin: 0;
	padding: 0.5rem;
	letter-spacing: 1px;
`;

export const TooltipDescription = styled.p`
	margin: 0;
	padding: 0.5rem;
	color: ${({ theme }) => theme.colors.text.primary};
	font-size: 0.9rem;
	font-weight: 300;
	line-height: 1.1rem;

	white-space: pre-wrap;
`;
