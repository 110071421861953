import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const EmptyRoadmapContainer = styled.div`
	height: 100vh;
	width: 100%;
	display: grid;
	grid-template-columns: 15% 45%;
	align-content: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
`;

export const ExclamationIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.colors.primary.main};
	font-size: 8rem;
	opacity: 0.6;
`;

export const Message = styled.b`
	font-size: 48px;
`;
