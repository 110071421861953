import { useSelector } from 'react-redux';
import { ImportParticipantsCsvBody } from 'store/api';
import { selectImportParticipantsState } from 'store/slices';

export const useImportParticipantsFormData = () => {
	const importParticipants = useSelector(selectImportParticipantsState);

	const getCsvFileFromObjectUrl = async () => {
		const objectUrl = importParticipants.fileObjectUrl;
		if (!objectUrl) return;
		const response = await fetch(objectUrl);
		const blob = await response.blob();

		return new File([blob], importParticipants.filename ?? 'import_participants.csv', { type: 'text/csv' });
	};

	const getImportParticipantsData = async (): Promise<Partial<ImportParticipantsCsvBody>> => {
		return {
			file: await getCsvFileFromObjectUrl(),
			filename: importParticipants.filename,
		};
	};

	return { getImportParticipantsFormData: getImportParticipantsData };
};
