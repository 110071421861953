import { GraphLineType } from 'common/types';
import { ProjectResultsGraphType } from 'features/Graphs';
import { isCombinedOdna, isCombinedPdna, isOdna, isPdna } from 'features/Graphs/utils/utils';
import {
	ApiGraphResponseBenchmarks,
	ApiGraphResponseCluster,
	ApiGraphResponseResults,
	ApiResultResponse,
} from 'store/api';
import { BenchmarksNamesKey, GraphDnaSettings } from 'store/index';

export const getResponseClusters = (result: ApiResultResponse) => {
	const emptyCluster: ApiGraphResponseCluster = {
		clusteredResults: {},
		clusterName: '',
		redColorPercentage: 0,
		redColorSide: '',
	};
	const baseResponseClusters: ApiGraphResponseResults = {
		CL00: emptyCluster,
		CL01: emptyCluster,
		CL02: emptyCluster,
		CL03: emptyCluster,
		CL04: emptyCluster,
		CL05: emptyCluster,
		CL06: emptyCluster,
		CL07: emptyCluster,
		CL08: emptyCluster,
		CL09: emptyCluster,
		CL10: emptyCluster,
	};
	const responseClusters = result.benchmarksAndSelectedParticipantClusterResults.results;

	Object.assign(baseResponseClusters, responseClusters);

	return baseResponseClusters;
};

export const getBenchmarkName = (benchmarkNames: Record<BenchmarksNamesKey, string>, index: number) => {
	const benchmarkKey = `benchmark${index}`;
	const benchmarkNameValueIndex = 1;
	const benchmarkNameObject = Object.entries(benchmarkNames).find(([key, _value]) => key === benchmarkKey);
	return benchmarkNameObject && benchmarkNameObject[benchmarkNameValueIndex]
		? benchmarkNameObject[benchmarkNameValueIndex]
		: undefined;
};

export const getBenchmarkColor = (
	benchmarkSettings: Record<BenchmarksNamesKey, GraphDnaSettings>,
	index: number,
	type: ProjectResultsGraphType,
) => {
	const benchmarkKey = `benchmark${index}`;
	const foundBenchmark = Object.entries(benchmarkSettings).find(([key, _value]) => key === benchmarkKey);
	if (!foundBenchmark) return '';
	const benchmarkValueIndex = 1;
	const benchmark = foundBenchmark[benchmarkValueIndex];
	return isPdna(type) || isCombinedPdna(type)
		? `#${benchmark.personal.lineColor}`
		: `#${benchmark.organisational.lineColor}`;
};

export const getBenchmarkLineType = (
	benchmarkSettings: Record<BenchmarksNamesKey, GraphDnaSettings>,
	index: number,
	type: ProjectResultsGraphType,
): GraphLineType => {
	const benchmarkKey = `benchmark${index}`;
	const foundBenchmark = Object.entries(benchmarkSettings).find(([key, _value]) => key === benchmarkKey);
	if (!foundBenchmark) return 'SOLID';
	const benchmarkValueIndex = 1;
	const benchmark = foundBenchmark[benchmarkValueIndex];
	return isPdna(type) || isCombinedPdna(type) ? benchmark.personal.lineType : benchmark.organisational.lineType;
};

export const assignBenchmarks = (
	target: ApiGraphResponseBenchmarks,
	cluster: ApiGraphResponseCluster,
	type: ProjectResultsGraphType,
) => {
	const pdna = cluster.clusteredResults.ALL_PDNA;
	const odna = cluster.clusteredResults.ALL_ODNA;
	const combinedPdna = cluster.clusteredResults.COMBINED_PDNA;
	const combinedOdna = cluster.clusteredResults.COMBINED_ODNA;
	if (isPdna(type) && pdna) {
		Object.assign(target, pdna);
		return;
	}
	if (isOdna(type) && odna) {
		Object.assign(target, odna);
		return;
	}
	if (isCombinedPdna(type) && combinedPdna) {
		Object.assign(target, combinedPdna);
		return;
	}
	if (isCombinedOdna(type) && combinedOdna) {
		Object.assign(target, combinedOdna);
		return;
	}
};
