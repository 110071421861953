import { BenchmarksNamesKey, GraphBenchmarkSettings, GraphConfigurationPayload, GraphDnaSettings } from 'store/index';

export const deselectBenchmarkPersonalAndOrganisational = (benchmark: GraphDnaSettings): GraphDnaSettings => {
	return {
		organisational: {
			lineColor: benchmark.organisational.lineColor,
			lineType: benchmark.organisational.lineType,
			selected: false,
		},
		personal: {
			lineColor: benchmark.personal.lineColor,
			lineType: benchmark.personal.lineType,
			selected: false,
		},
		pno: {
			selected: benchmark.pno.selected,
		},
	};
};

export const deselectBenchmarkPNO = (benchmark: GraphDnaSettings): GraphDnaSettings => {
	return {
		organisational: {
			lineColor: benchmark.organisational.lineColor,
			lineType: benchmark.organisational.lineType,
			selected: benchmark.organisational.selected,
		},
		personal: {
			lineColor: benchmark.personal.lineColor,
			lineType: benchmark.personal.lineType,
			selected: benchmark.personal.selected,
		},
		pno: {
			selected: false,
		},
	};
};

export const deselectAllPersonalAndOrganisational = (
	benchmarksSettings: Record<BenchmarksNamesKey, GraphDnaSettings>,
): Record<BenchmarksNamesKey, GraphDnaSettings> => {
	return {
		benchmark1: deselectBenchmarkPersonalAndOrganisational(benchmarksSettings.benchmark1),
		benchmark2: deselectBenchmarkPersonalAndOrganisational(benchmarksSettings.benchmark2),
		benchmark3: deselectBenchmarkPersonalAndOrganisational(benchmarksSettings.benchmark3),
		benchmark4: deselectBenchmarkPersonalAndOrganisational(benchmarksSettings.benchmark4),
		benchmark5: deselectBenchmarkPersonalAndOrganisational(benchmarksSettings.benchmark5),
	};
};

export const deselectAllPNO = (
	benchmarksSettings: Record<BenchmarksNamesKey, GraphDnaSettings>,
): Record<BenchmarksNamesKey, GraphDnaSettings> => {
	return {
		benchmark1: deselectBenchmarkPNO(benchmarksSettings.benchmark1),
		benchmark2: deselectBenchmarkPNO(benchmarksSettings.benchmark2),
		benchmark3: deselectBenchmarkPNO(benchmarksSettings.benchmark3),
		benchmark4: deselectBenchmarkPNO(benchmarksSettings.benchmark4),
		benchmark5: deselectBenchmarkPNO(benchmarksSettings.benchmark5),
	};
};

const prepareGraphLine = (line: string, settings: GraphBenchmarkSettings) => {
	return {
		line: line,
		color: settings.lineColor,
		type: settings.lineType,
	};
};

export const prepareSaveConfigPayload = (
	colorPresetId: number,
	benchmarkSettings: Record<BenchmarksNamesKey, GraphDnaSettings>,
): GraphConfigurationPayload => {
	return {
		colorPresetId: {
			id: colorPresetId.toString(),
		},
		graphLines: [
			prepareGraphLine('BENCHMARK_1P', benchmarkSettings.benchmark1.personal),
			prepareGraphLine('BENCHMARK_2P', benchmarkSettings.benchmark2.personal),
			prepareGraphLine('BENCHMARK_3P', benchmarkSettings.benchmark3.personal),
			prepareGraphLine('BENCHMARK_4P', benchmarkSettings.benchmark4.personal),
			prepareGraphLine('BENCHMARK_5P', benchmarkSettings.benchmark5.personal),
			prepareGraphLine('BENCHMARK_1O', benchmarkSettings.benchmark1.organisational),
			prepareGraphLine('BENCHMARK_2O', benchmarkSettings.benchmark2.organisational),
			prepareGraphLine('BENCHMARK_3O', benchmarkSettings.benchmark3.organisational),
			prepareGraphLine('BENCHMARK_4O', benchmarkSettings.benchmark4.organisational),
			prepareGraphLine('BENCHMARK_5O', benchmarkSettings.benchmark5.organisational),
		],
	};
};
