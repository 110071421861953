import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
	display: flex;

	& > *:not(:last-child) {
		margin-right: 0.5rem;
	}
`;

export const StyledButton = styled(Button)`
	padding-left: 2rem;
	padding-right: 2rem;
`;
