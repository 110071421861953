import { UserConfiguration } from '.';

export const UserPermissions = {
	VIEW_TENANT: 'OP_VIEW_TENANT',
	MANAGE_ASSESSEE_ASSIGNMENTS: 'OP_MANAGE_ASSESSEE_ASSIGNMENTS',
	VIEW_CONFIGURED_CRITERIA: 'OP_VIEW_CONFIGURED_CRITERIA',
	SIMULATION_REPORT: 'OP_SIMULATION_REPORT',
	MANAGE_BENCHMARK_ASSIGNMENTS: 'OP_MANAGE_BENCHMARK_ASSIGNMENTS',
	EDIT_CLUSTER_CONFIGURATION: 'OP_EDIT_CLUSTER_CONFIGURATION',
	PREDICTIVE_VIEW: 'OP_PREDICTIVE_VIEW',
	VIEW_QUESTIONS_CONFIGURATION: 'OP_VIEW_QUESTIONS_CONFIGURATION',
	SPECIFY_EMAIL_TEMPLATES_FOR_TENANT: 'OP_SPECIFY_EMAIL_TEMPLATES_FOR_TENANT',
	EDIT_QUESTIONS_CONFIGURATION: 'OP_EDIT_QUESTIONS_CONFIGURATION',
	EXPORT_PROJECT_PARTICIPANTS: 'OP_EXPORT_PROJECT_PARTICIPANTS',
	PREDICTIVE_REPORT: 'OP_PREDICTIVE_REPORT',
	EDIT_TENANT: 'OP_EDIT_TENANT',
	VIEW_TENANT_BRAND: 'OP_VIEW_TENANT_BRAND',
	EDIT_TENANT_CONTACT: 'OP_EDIT_TENANT_CONTACT',
	MANAGE_PROJECTS: 'OP_MANAGE_PROJECTS',
	MANAGE_ASSESSEES: 'OP_MANAGE_ASSESSEES',
	TRIGGER_PROJECT_ACTIONS: 'OP_TRIGGER_PROJECT_ACTIONS',
	VIEW_PROJECTS_STATUS: 'OP_VIEW_PROJECTS_STATUS',
	SIMULATION_VIEW: 'OP_SIMULATION_VIEW',
	EDIT_DIMENSION_LEGENDS: 'OP_EDIT_DIMENSION_LEGENDS',
	MANAGE_T_ADMINS: 'OP_MANAGE_T_ADMINS',
	MANAGE_TENANTS_BRANDING: 'OP_MANAGE_TENANTS_BRANDING',
	MANAGE_CRITERIA_CONFIGURATION: 'OP_MANAGE_CRITERIA_CONFIGURATION',
	VIEW_TENANTS: 'OP_VIEW_TENANTS',
	SPECIFY_REPORT_TEMPLATES_FOR_PROJECT: 'OP_SPECIFY_REPORT_TEMPLATES_FOR_PROJECT',
	SPECIFY_REPORT_TEMPLATES_FOR_TENANT: 'OP_SPECIFY_REPORT_TEMPLATES_FOR_TENANT',
	EDIT_TENANTS: 'OP_EDIT_TENANTS',
	GET_ALL_PROJECTS: 'OP_GET_ALL_PROJECTS',
	BILLING_REPORT: 'OP_BILLING_REPORT',
	EXPORT_RESULTS: 'OP_EXPORT_RESULTS',
	MANAGE_APD_MANAGERS: 'OP_MANAGE_APD_MANAGERS',
	USER_SESSIONS_CLEANUP: 'OP_USER_SESSIONS_CLEANUP',
};

export const UserRoles = {
	ADMIN: 'ROLE_T_ADMIN',
	SYSTEM_MANAGER: 'ROLE_SYSTEM_MANAGER',
	MANAGER: 'ROLE_APD_MANAGER',
};

export type UserPermission = typeof UserPermissions[keyof typeof UserPermissions];
export type UserRole = typeof UserRoles[keyof typeof UserRoles];

export type User = {
	username: string;
	authenticatedWithSSO: boolean;
	permissions: (UserPermission | UserRole)[];
	configuration: UserConfiguration;
};
