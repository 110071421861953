import styled from 'styled-components';

export const StyledDiv = styled.div`
	width: fit-content;
	padding: 3rem;
	padding-left: 6rem;
	padding-right: 6rem;
	height: fit-content;
	border: 2px solid ${({ theme }) => theme.colors.primary.main};
	border-radius: 4px;
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 1rem;
	background: white;
`;
