import { PageLayout } from 'common/components';
import { ClientAdminForm } from 'features/ClientConfiguration/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ClientAdminFormLayoutProps } from './ClientAdminFormLayout.types';

export const ClientAdminFormLayout = ({ id, saveTrigger, onSaveButtonClick }: ClientAdminFormLayoutProps) => {
	return (
		<PageLayout
			title="Create Client Administrator"
			id={id}
		>
			<div>
				<ClientAdminForm saveTrigger={saveTrigger} />

				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
