import styled from 'styled-components';
import { StyledTableLabelProps } from 'features/Tables/components/TableHeaderLabel/TableLabel.types';

export const StyledTableHeaderLabel = styled.p<StyledTableLabelProps>`
	width: 100%;
	justify-content: flex-start;
	margin: 0;
	vertical-align: start;
	${({ centered }) => `text-align: ${centered ? 'center' : 'left'} !important`};
	overflow-wrap: anywhere;
	justify-content: space-between;
	cursor: pointer;
	user-select: none;
`;
