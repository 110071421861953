import {
	ClientAdminValidationProperties,
	useClientAdminFormDataValidation,
} from 'features/ClientConfiguration/components';
import { ClientAdminFormLayout } from 'features/ClientConfiguration/views';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateTenantAdminMutation } from 'store/api';
import { selectCreateClientAdminState } from 'store/slices';

export const CreateTenantsAdmin = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const createClientAdminData = useSelector(selectCreateClientAdminState);
	const { validateForm } = useClientAdminFormDataValidation();
	const [createTenantAdmin] = useCreateTenantAdminMutation();
	const navigate = useNavigate();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const validationParameters: ClientAdminValidationProperties = { shouldCheckForTenantAdminIdUniqueness: true };
		const validate = () => validateForm(createClientAdminData, validationParameters);
		const isFormValid = await validate();

		if (!isFormValid) return;

		const response = await createTenantAdmin(createClientAdminData).unwrap();

		if (response.id) {
			navigate('/tadmins');
		}
	};

	return (
		<ClientAdminFormLayout
			id="create-client-admin"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
