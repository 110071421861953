import { TableQueryParams, AssignmentStatus } from 'common/types';
import { AssessmentTypes } from 'common/constants';
import { ColumnFilter } from '@tanstack/react-table';
import { useAssignmentStatusData } from 'features/Tables/hooks';
import { PageLayout, Table } from 'common/components';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAssignmentStatus, useProjectSummary } from 'common/hooks';
import {
	ButtonsContainer,
	ExportParticipantsButton,
	CloseProjectButton,
	DownloadProjectReportButton,
	InputsContainer,
	ProjectActionsForm,
	StyledInput,
	TableContainer,
} from './styled';
import { TableQueryTrigger } from 'common/types';
import { ProjectFinishedStatus } from './FinishedStatus/ProjectFinishedStatus';
import { CloseProjectConfirmationDialog } from 'features/ProjectDetails/components';
import { useLazyGetProjectCompletionReportCsvQuery, useLazyGetProjectParticipantsCsvQuery } from 'store/api';
import { downloadFile, isDate } from 'common/utils';
import { useProjectDetails } from 'common/hooks';

export const ProjectActions = () => {
	const [assignmentsStatusTableQueryTrigger] = useState<TableQueryTrigger>();
	const { columns, visibleColumns } = useAssignmentStatusData();
	const { getAssignmentsStatus, assignmentsStatus } = useAssignmentStatus();
	const { projectId } = useParams();
	const { getProjectSummary, projectSummary } = useProjectSummary();
	const { projectDetails } = useProjectDetails({ projectId: projectId });
	const [getProjectParticipantsCsv] = useLazyGetProjectParticipantsCsvQuery();
	const [getProjectCompletionReportCsv] = useLazyGetProjectCompletionReportCsvQuery();

	const projectFinishedStatus = new ProjectFinishedStatus();

	const shortIdValue = projectSummary?.shortId ?? '';
	const percentageFinishedValue = projectFinishedStatus.formattingResult(projectSummary)?.percentageFinished ?? '';
	const totalFinishedValue = projectFinishedStatus.formattingResult(projectSummary)?.finished ?? '';
	const totalNotFinishedValue = projectFinishedStatus.formattingResult(projectSummary)?.notFinished ?? '';

	const shortIdName = 'Project short ID';
	const percentageFinishedName =
		projectSummary?.projectType === AssessmentTypes.OPDNA ? 'Percentage finished P/O' : 'Percentage finished';
	const finishedName = projectSummary?.projectType === AssessmentTypes.OPDNA ? 'Finished P/O' : 'Finished';
	const notFinishedName = projectSummary?.projectType === AssessmentTypes.OPDNA ? 'Not finished P/O' : 'Not finished';
	const [isCloseProjectModalOpen, setIsCloseProjectModalOpen] = useState(false);

	const onExportParticipantsAction = async () => {
		if (!projectId) return;

		try {
			const response = await getProjectParticipantsCsv({ projectId: projectId }).unwrap();
			downloadFile(response.objectUrl, response.filename);
		} catch {}
	};

	const onGetProjectCompletionReportAction = async () => {
		if (!projectId) return;

		try {
			const response = await getProjectCompletionReportCsv({ projectId: projectId }).unwrap();
			downloadFile(response.objectUrl, response.filename);
		} catch {}
	};

	function ProjectActionsTabPanel() {
		const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
			const { filters, ...restQueryParams } = queryParams;
			const mappedFilters = filters.map(({ id, value }) => {
				if (isDate(value)) {
					return {
						id: id,
						value: value.toISOString(),
					};
				}

				return { id, value };
			});

			try {
				await getAssignmentsStatus(createRequest({ filters: mappedFilters, ...restQueryParams }));
			} catch {}

			await refreshProjectSummary(filters);
		};
		return (
			<>
				<Table<AssignmentStatus>
					tableTypeId="assignment-status"
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={assignmentsStatus.pageNumber}
					pageCount={assignmentsStatus.totalPages}
					data={assignmentsStatus.assignmentStatuses}
					onQueryChange={onQueryChange}
					queryTrigger={assignmentsStatusTableQueryTrigger}
				/>
			</>
		);
	}

	return (
		<PageLayout
			title="Project Actions"
			subtitle="Check the state of the project, generate questionnaire links, and check completion rates"
			id="project-actions"
		>
			<CloseProjectConfirmationDialog
				isOpen={isCloseProjectModalOpen}
				onClose={() => setIsCloseProjectModalOpen(false)}
				projectId={projectId}
			/>
			<ProjectActionsForm>
				<ButtonsContainer>
					{!projectDetails?.closeDate && (
						<CloseProjectButton
							variant="outlined"
							onClick={() => setIsCloseProjectModalOpen(true)}
						>
							CLOSE PROJECT
						</CloseProjectButton>
					)}
					<ExportParticipantsButton
						variant="outlined"
						onClick={onExportParticipantsAction}
					>
						EXPORT PARTICIPANTS
					</ExportParticipantsButton>
					<DownloadProjectReportButton
						variant="outlined"
						onClick={onGetProjectCompletionReportAction}
					>
						DOWNLOAD PROJECT COMPLETION REPORT
					</DownloadProjectReportButton>
				</ButtonsContainer>
				<InputsContainer>
					<StyledInput
						name={shortIdName}
						value={shortIdValue}
						readOnly
					/>
					<StyledInput
						name={percentageFinishedName}
						value={percentageFinishedValue}
						readOnly
					/>
					<StyledInput
						name={finishedName}
						value={totalFinishedValue}
						readOnly
					/>
					<StyledInput
						name={notFinishedName}
						value={totalNotFinishedValue}
						readOnly
					/>
				</InputsContainer>
			</ProjectActionsForm>
			<TableContainer>{ProjectActionsTabPanel()}</TableContainer>
		</PageLayout>
	);

	async function refreshProjectSummary(filters: ColumnFilter[]) {
		const tableFilters = createPageParams(filters);
		await getProjectSummary({
			projectId: projectId || '',
			filters: tableFilters,
		});
	}

	function createFilters(filters: ColumnFilter[]) {
		const isFilterArrayFilled = !!filters.length;
		if (!isFilterArrayFilled) return;
		const mappedFilters: { [key: string]: string }[] | undefined = filters.map((filter) => {
			return {
				[filter.id]: filter.value as string,
			};
		});
		return mappedFilters;
	}

	function createPageParams(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string }[] = filters.map((filter) => {
			return {
				[filter.id]: filter.value as string,
			};
		});
		return mappedFilters;
	}

	function getOptionalProjectId() {
		return projectId || '';
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}
};
