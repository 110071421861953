import { ApiFramework, ApiFrameworkDetails } from 'common/types';
import { rootApi } from 'store/api';
import {
	ActivateFrameworkOptions,
	AllFrameworksResponse,
	CreateFrameworkOptions,
	DeleteFrameworkOptions,
	EditFrameworkOptions,
	FrameworkDetailsOptions,
	FrameworkDetailsResponse,
} from './frameworksApi.types';
import { mapEditFrameworkOptions } from './mappers';

export const frameworksApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllFrameworks: builder.query<AllFrameworksResponse<ApiFramework>, void>({
			query: () => ({
				url: `/api/tenant/current/cluster-frameworks`,
			}),
			providesTags: ['Frameworks'],
		}),
		getFrameworkDetails: builder.query<FrameworkDetailsResponse<ApiFrameworkDetails>, FrameworkDetailsOptions>({
			query: (opts) => ({
				url: `/api/tenant/current/cluster-frameworks/${opts.frameworkId}`,
			}),
			providesTags: ['Framework'],
		}),
		deleteFramework: builder.mutation<void, DeleteFrameworkOptions>({
			query: (opts) => ({
				url: `/api/tenant/current/cluster-frameworks/${opts.frameworkId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Frameworks'],
		}),
		activateFramework: builder.mutation<void, ActivateFrameworkOptions>({
			query: (opts) => ({
				method: 'POST',
				url: `/api/tenant/current/cluster-frameworks/${opts.frameworkId}/activate`,
			}),
			invalidatesTags: ['Frameworks'],
		}),
		createFramework: builder.mutation<void, CreateFrameworkOptions>({
			query: (opts) => ({
				method: 'POST',
				url: `/api/tenant/current/cluster-frameworks`,
				body: {
					name: opts.name,
				},
			}),
			invalidatesTags: ['Frameworks'],
		}),
		editFramework: builder.mutation<void, EditFrameworkOptions>({
			query: (opts) => ({
				method: 'POST',
				url: `/api/tenant/current/cluster-frameworks/${opts.framework.frameworkId}`,
				body: mapEditFrameworkOptions(opts.framework),
			}),
			invalidatesTags: ['Framework', 'Frameworks'],
		}),
	}),
});

export const {
	useGetAllFrameworksQuery,
	useGetFrameworkDetailsQuery,
	useDeleteFrameworkMutation,
	useActivateFrameworkMutation,
	useCreateFrameworkMutation,
	useEditFrameworkMutation,
} = frameworksApi;
