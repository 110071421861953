import styled from 'styled-components';
import { theme } from 'common/constants';

export const StyledCredentialsBox = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;

	background: ${theme.colors.common.lightSilver};

	width: 25%;
	padding: 1%;
	transform: translate(-50%, -50%);

	border: 2px black solid;
	border-radius: 35px;
`;
