import { NotificationTypes, Table, useNotifications } from 'common/components';
import { Participant, ParticipantId, TableQueryParams } from 'common/types';
import { BenchmarkConfigurationLayout } from 'features/ResultsConfiguration/views';
import { useParticipantsData } from 'features/Tables/hooks';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParticipants, useProjectDetails, useSuspenseManually } from 'common/hooks';
import { convertParticipantsSelection } from 'common/utils';
import { BenchmarkConfigurationId } from 'store/api';
import { ColumnFilter } from '@tanstack/react-table';
import { useCreateBenchmarkConfigurationMutation } from 'store/api';
import { useDispatch } from 'react-redux';
import { resetBenchmarkState } from 'store/slices';
import { BenchmarkComparisonViewTypes } from 'common/components/BenchmarkComparisonView/BenchmarkComparisonView.types';

export const BenchmarkComparisonView = ({
	id,
	pageName,
	onViewClick,
	benchmarkTabPanel,
}: BenchmarkComparisonViewTypes) => {
	const zero = 0;
	const { projectId } = useParams();
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);
	const { getParticipants, participants } = useParticipants({ projectId });
	const { projectDetails } = useProjectDetails({ projectId });

	const { columns, visibleColumns } = useParticipantsData(projectId);

	const [benchmarkConfigurationId, setBenchmarkConfigurationId] = useState<BenchmarkConfigurationId>();
	const [benchmarkConfigurationParticipantsCount, setBenchmarkConfigurationParticipantsCount] = useState(zero);

	const { pushNotification } = useNotifications();
	const dispatch = useDispatch();

	const [saveParticipantsForBenchmark] = useCreateBenchmarkConfigurationMutation();

	const onViewButtonClick = () => {
		onViewClick(benchmarkConfigurationId, projectId);
	};

	const suspense = useSuspenseManually();
	useEffect(() => {
		if (participantsWereNotSelected()) {
			setBenchmarkConfigurationId(undefined);
			setBenchmarkConfigurationParticipantsCount(zero);
			return;
		}

		return () => {
			dispatch(resetBenchmarkState());
		};
	}, [selectedParticipantIds]);

	return (
		<>
			<BenchmarkConfigurationLayout
				selectedParticipantsCount={selectedParticipantIds.length}
				benchmarkParticipantsCount={benchmarkConfigurationParticipantsCount}
				onSaveButtonClick={onSaveButtonClick}
				onViewButtonClick={onViewButtonClick}
				closeDate={projectDetails?.closeDate}
				projectShortId={projectDetails?.shortId}
				id={id}
			>
				{ParticipantsTabPanel()}
				{createBenchmarkTab(benchmarkConfigurationId)}
			</BenchmarkConfigurationLayout>
		</>
	);

	function createBenchmarkTab(benchmarkConfigurationId: BenchmarkConfigurationId | undefined) {
		if (benchmarkConfigurationId) {
			return benchmarkTabPanel(benchmarkConfigurationId);
		}

		return <></>;
	}

	function ParticipantsTabPanel() {
		const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
			try {
				await getParticipants(createRequest(queryParams));
			} catch {}
		};

		return (
			<>
				<Table<Participant>
					tableTypeId={`${id}-participants`}
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={participants.pageNumber}
					pageCount={participants.totalPages}
					data={participants.content}
					onQueryChange={onQueryChange}
					onRowSelection={updateSelection}
				/>
			</>
		);
	}

	function updateSelection(selection: Record<string, boolean>) {
		const selectedIds = convertParticipantsSelection(selection);
		setSelectedParticipantIds(selectedIds);
	}

	function createFilters(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function participantsWereNotSelected() {
		return !selectedParticipantIds.length;
	}

	async function onSaveButtonClick() {
		setBenchmarkConfigurationId(undefined);
		suspense.turnOn();
		saveParticipantsForBenchmark(selectedParticipantIds)
			.unwrap()
			.then((benchmarkId) => {
				setBenchmarkConfigurationId(benchmarkId);
				setBenchmarkConfigurationParticipantsCount(selectedParticipantIds.length);
				notifyAboutSuccessfulSave();
			});
	}

	function notifyAboutSuccessfulSave() {
		pushNotification({
			message: `Selected participants have been assigned successfully to ${pageName}`,
			type: NotificationTypes.Success,
		});
	}

	function getOptionalProjectId() {
		return projectId || '';
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}
};
