import { BenchmarksNamesKeys } from 'common/constants';
import { BenchmarkSettingsControl } from '../BenchmarkSettingsControl';
import { ChartSettingsOptions } from '../ChartSettingsOptions';
import { ActionsMenuContainer, ChartConfigGrid, Container, Hr } from './styled';
import {
	BenchmarksNamesKey,
	GraphDnaSettings,
	selectBenchmarkNames,
	selectBenchmarksSettings,
	setBenchmarksSettings,
	setBenchmarksNames,
} from 'store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
	deselectAllPNO,
	deselectAllPersonalAndOrganisational,
	deselectBenchmarkPNO,
	deselectBenchmarkPersonalAndOrganisational,
	prepareSaveConfigPayload,
} from './utils';
import { selectChartSettingsPreset, usePostGraphConfigurationMutation } from 'store/index';

export const ChartSettingsMenu = () => {
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const benchmarkSettings = useSelector(selectBenchmarksSettings);
	const dispatch = useDispatch();
	const [save] = usePostGraphConfigurationMutation();
	const colorPresetIndex = useSelector(selectChartSettingsPreset);
	const [tempBenchmarkNames, setTempBenchmarkNames] = useState<Record<BenchmarksNamesKey, string>>(benchmarkNames);
	const [tempBenchmarkSettings, setTempBenchmarkSettings] =
		useState<Record<BenchmarksNamesKey, GraphDnaSettings>>(benchmarkSettings);

	const pnoChange = (benchmarkKey: BenchmarksNamesKey, benchmarkSettings: GraphDnaSettings) => {
		const newObject = deselectAllPersonalAndOrganisational(tempBenchmarkSettings);
		const newBenchmarkSettings = deselectBenchmarkPersonalAndOrganisational(benchmarkSettings);
		setTempBenchmarkSettings(() => ({
			...newObject,
			[benchmarkKey]: newBenchmarkSettings,
		}));
	};

	const personalOrOrganisationalChange = (benchmarkKey: BenchmarksNamesKey, benchmarkSettings: GraphDnaSettings) => {
		const newObject = deselectAllPNO(tempBenchmarkSettings);
		const newBenchmarkSettings = deselectBenchmarkPNO(benchmarkSettings);
		setTempBenchmarkSettings(() => ({
			...newObject,
			[benchmarkKey]: newBenchmarkSettings,
		}));
	};

	const BenchmarkSettingsControls = (
		<>
			{Object.values(BenchmarksNamesKeys).map((benchmarkKey) => (
				<BenchmarkSettingsControl
					key={benchmarkKey}
					benchmarkSettings={tempBenchmarkSettings[benchmarkKey]}
					benchmarkName={tempBenchmarkNames[benchmarkKey]}
					onBenchmarkNameChange={(benchmarkName) =>
						setTempBenchmarkNames((prevTempBenchmarkNames) => ({
							...prevTempBenchmarkNames,
							[benchmarkKey]: benchmarkName,
						}))
					}
					onBenchmarkSettingsChange={(benchmarkSettings) => {
						if (benchmarkSettings.pno.selected) {
							pnoChange(benchmarkKey, benchmarkSettings);
						} else if (benchmarkSettings.organisational.selected || benchmarkSettings.personal.selected) {
							personalOrOrganisationalChange(benchmarkKey, benchmarkSettings);
						} else {
							setTempBenchmarkSettings((prevTempBenchmark) => ({
								...prevTempBenchmark,
								[benchmarkKey]: benchmarkSettings,
							}));
						}
					}}
				/>
			))}
		</>
	);

	return (
		<Container>
			<ChartConfigGrid>{BenchmarkSettingsControls}</ChartConfigGrid>
			<Hr />
			<ActionsMenuContainer>
				<ChartSettingsOptions
					onClose={() => {
						dispatch(setBenchmarksNames(tempBenchmarkNames));
						dispatch(setBenchmarksSettings(tempBenchmarkSettings));
					}}
					onSaveAndClose={() => {
						dispatch(setBenchmarksNames(tempBenchmarkNames));
						dispatch(setBenchmarksSettings(tempBenchmarkSettings));
						save(prepareSaveConfigPayload(colorPresetIndex, tempBenchmarkSettings));
					}}
				/>
			</ActionsMenuContainer>
		</Container>
	);
};
