import { BehaviourPairTileButtonProps } from './BehaviourPairTileButton.types';
import { BehaviourButton, StyledTooltip, TooltipContainer, TooltipDescription, TooltipTitle } from './styled';

export const BehaviourPairTileButton = ({
	dimension,
	selectionVariant,
	tooltipDirection,
	onClick,
}: BehaviourPairTileButtonProps) => {
	const TooltipContent = (
		<TooltipContainer>
			<TooltipTitle>{dimension.name}</TooltipTitle>
			<TooltipDescription>{dimension.legendText}</TooltipDescription>
		</TooltipContainer>
	);

	return (
		<StyledTooltip
			direction={tooltipDirection}
			content={TooltipContent}
			clickableTooltipTip={false}
		>
			<BehaviourButton
				$color={dimension.color}
				onClick={() => onClick(dimension)}
				variant="outlined"
				$selectionVariant={selectionVariant}
			>
				{dimension.name}
			</BehaviourButton>
		</StyledTooltip>
	);
};
