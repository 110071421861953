import { useGetProjectRoadmapsQuery } from 'store/api/roadmapApi/roadmapApi';
import { ApiRoadmapSummary, RoadmapSummary } from 'store/api/roadmapApi/roadmapApi.types';
import { ProjectId } from 'common/types';

export const useProjectRoadmaps = (projectId: ProjectId) => {
	const roadmaps = useGetProjectRoadmapsQuery(projectId);
	const emptyResponse: ApiRoadmapSummary[] = [];
	const apiData = getApiData();

	function getApiData(): ApiRoadmapSummary[] {
		if (!roadmaps.isSuccess) return emptyResponse;

		return roadmaps.data;
	}

	function transform(roadmaps: ApiRoadmapSummary[]): RoadmapSummary[] {
		return roadmaps.map((roadmap) => ({ ...roadmap }));
	}

	return { data: transform(apiData), areRoadmapsFetched: roadmaps.isSuccess };
};
