import { ChartColors } from 'common/constants';
import { ChartColor } from 'common/types';
import { ColorPickerButton } from 'features/Simulation/components/settings/ChartLineSettingsItem/styled';
import { useRef, useState } from 'react';
import {
	ButtonWithMargin,
	ButtonsContainer,
	Container,
	ExcludedCriteriaContainer,
	FirstContainer,
	Hr,
	SecondContainer,
	StyledConfirmableNameInput,
} from './styled';
import { ChartSettingsOptionItem, ColorPicker } from 'features/Simulation/components/settings';
import { DownloadButton, DownloadIcon } from 'features/Graphs/PptxExportButton';
import { Button, NotificationTypes, Popper, useNotifications } from 'common/components';
import { RoadMapSettingsPanelProps } from './RoadMapSettingsPanel.types';
import { useComponentVisible } from 'common/hooks';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useProjectRoadmaps, useRoadmaps } from 'features/RoadMap/hooks';

export const RoadMapSettingsPanel = ({
	outcomes,
	onClose,
	onExportClick,
	onSaveClick,
	onNameChange,
	roadmapName,
	initialSelectedColor,
	excludedCriteria,
}: RoadMapSettingsPanelProps) => {
	const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
	const [selectedColor, setSelectedColor] = useState<ChartColor>(initialSelectedColor);
	const [blackArrows, setBlackArrows] = useState(true);
	const [criteriaNumber, setCriteriaNumber] = useState(true);
	const {
		isComponentVisible: isColorPickerVisible,
		ref: colorPickerRef,
		setIsComponentVisible: setIsColorPickerVisible,
	} = useComponentVisible<HTMLDivElement>({ initialIsVisible: false, ignoredEl: colorPickerButtonRef });

	const { projectId } = useParams();
	const roadmaps = projectId ? useProjectRoadmaps(projectId) : useRoadmaps();
	const roadmapsNames = roadmaps.data.map((roadmap) => roadmap.name);
	const { pushNotification } = useNotifications();

	const onColorPickerButtonClick = () => {
		setIsColorPickerVisible((prevIsColorPickerVisible) => !prevIsColorPickerVisible);
	};

	const onColorPaletteButtonClick = (color: ChartColor) => {
		setSelectedColor(color);
		setIsColorPickerVisible(false);
	};

	const getExcludedCriteria = () => {
		const sortedCriteria = [...excludedCriteria].sort((a, b) => a.criteriaId - b.criteriaId);
		const criteriaNameList = sortedCriteria.map((criterion) => {
			return criterion.name;
		});

		return criteriaNameList.join(', ');
	};

	const notifyAboutFailedValidation = () => {
		pushNotification({
			message: 'Roadmap name already taken, please create a unique name.',
			type: NotificationTypes.Warning,
		});
	};

	const validateNewName = (newName: string) => {
		if (roadmapsNames.includes(newName)) {
			notifyAboutFailedValidation();
			return;
		}
		onNameChange(newName);
	};

	return (
		<Container>
			<StyledConfirmableNameInput
				id="framework-name"
				value={roadmapName}
				label=""
				onConfirm={(value) => validateNewName(value)}
				maxLength={32}
			/>
			<p>Number of outcomes included: {outcomes}</p>
			<FirstContainer>
				Roadmap colour
				<ColorPickerButton
					ref={colorPickerButtonRef}
					$color={`#${selectedColor}`}
					onClick={onColorPickerButtonClick}
				/>
				<Popper
					isVisible={isColorPickerVisible}
					ref={colorPickerRef}
					anchorRef={colorPickerButtonRef}
				>
					<ColorPicker
						onSelect={onColorPaletteButtonClick}
						selected={selectedColor}
					/>
				</Popper>
			</FirstContainer>
			<ExcludedCriteriaContainer>
				{!!excludedCriteria.length && <p>Criteria excluded from Roadmap: {getExcludedCriteria()}</p>}
			</ExcludedCriteriaContainer>
			<Hr />
			<SecondContainer>
				<div>
					<ChartSettingsOptionItem
						checkButtonType="checkbox"
						checked={blackArrows}
						onClick={() => setBlackArrows(!blackArrows)}
					>
						Black connection arrows
					</ChartSettingsOptionItem>
					<ChartSettingsOptionItem
						checkButtonType="checkbox"
						checked={criteriaNumber}
						onClick={() => setCriteriaNumber(!criteriaNumber)}
					>
						Display Criteria number
					</ChartSettingsOptionItem>
				</div>
				<DownloadButton
					variant="text"
					underlineOnHover
					color="inherit"
					startIcon={<DownloadIcon icon={faDownload} />}
					onClick={() => {
						onExportClick();
					}}
				>
					Download PPT
				</DownloadButton>
			</SecondContainer>
			<ButtonsContainer>
				<ButtonWithMargin
					onClick={() =>
						onClose({
							textColor: getTextColor(),
							nodeColor: selectedColor,
							blackArrows,
							criteriaNumber,
						})
					}
				>
					Close
				</ButtonWithMargin>
				<Button
					onClick={() =>
						onSaveClick({
							textColor: getTextColor(),
							nodeColor: selectedColor,
							blackArrows,
							criteriaNumber,
						})
					}
				>
					Save Roadmap
				</Button>
			</ButtonsContainer>
		</Container>
	);

	function getTextColor() {
		const black = 'black';
		switch (selectedColor) {
			case ChartColors.Olivine:
				return black;
			case ChartColors.SeaSerpent:
				return black;
			case ChartColors.ChineseGold:
				return black;
			case ChartColors.ViridianGreen:
				return black;
			case ChartColors.LightCoral:
				return black;
			default:
				return 'white';
		}
	}
};
