export const ZIndexes = {
	roadmapSelection: 2,
	tableBody: 993,
	tableHeader: 994,
	dropdownList: 997,
	modalBackdrop: 1104,
	modal: 1105,
	additionalQuestionsModalBackdrop: 1106,
	additionalQuestionsModal: 1107,
	tooltip: 1006,
	simulation: 1100,
	simulationNavigation: 1101,
	simulationContent: 1102,
	simulationSettingsMenuOverlay: 1103,
	simulationSettingsMenu: 1104,
	tensionGraphModalBackdrop: 1105,
	tensionGraphModalContent: 1106,
	suspense: 10000,
	notification: 1108,
	notificationContainer: 2000,
};
