import { CloseButton } from 'features/Simulation/components/settings/ChartSettingsOptions/styled';
import { toggleChartSettingsView } from 'store/slices';
import { useDispatch } from 'react-redux';
import { Container } from './styled';
import { PredictiveViewResultMenuProps } from './PredictiveViewResultMenu.types';
import { PredictiveExportButton } from 'features/Predictive/components';
import { QuartileInfo } from 'features/Predictive/components';

export const PredictiveViewResultsMenu = ({ data, settings, setSettings }: PredictiveViewResultMenuProps) => {
	const dispatch = useDispatch();

	return (
		<Container>
			<PredictiveExportButton
				data={data}
				settings={settings}
			/>
			<QuartileInfo
				enabled={settings.quartileInfoEnabled}
				onChange={(value) => setSettings({ quartileInfoEnabled: value })}
			/>
			<CloseButton onClick={onCloseButtonClick}>Close</CloseButton>
		</Container>
	);

	function onCloseButtonClick() {
		dispatch(toggleChartSettingsView(false));
	}
};
