import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph';
import { CategoryDistribution, CriteriaPerQuarter } from 'store/api/discoveryInsightApi';
import { ApiQuarterlyDistributionSummary } from 'store/api';
import { ILegendData } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartData';
import { CategoryComparator } from 'features/DiscoveryInsights/components';

export class CriteriaViewLegendCreator implements QuadrantLegendCreator<CriteriaPerQuarter> {
	constructor(private _comparator: CategoryComparator, private _groupFilterThreshold: number) {}

	createLegend(data: ApiQuarterlyDistributionSummary<CriteriaPerQuarter>): ILegendData {
		const createMap = (data: CategoryDistribution[]) => {
			const map = new Map();
			data.forEach((entry) => {
				map.set(entry.category, entry);
			});
			return map;
		};

		const onlyThoseWithNGreaterOrEqualThanTotalThreshold = (category: CategoryDistribution) => {
			return category.totalAmount >= this._groupFilterThreshold;
		};

		function gatherDataFromAllQuartersOf(distribution: ApiQuarterlyDistributionSummary<CriteriaPerQuarter>) {
			return distribution.q1.criteria.concat(
				distribution.q2.criteria,
				distribution.q3.criteria,
				distribution.q4.criteria,
			);
		}

		function createLabel(category: string, value: CategoryDistribution) {
			if (!value) {
				return `${category}: 0%  0/0`;
			}
			const rounding = 2;
			const percentage = value.percentage.toFixed(rounding);
			return `${category}: ${percentage}%  ${value.membersAmount}/${value.totalAmount}`;
		}

		const categories = gatherDataFromAllQuartersOf(data)
			.filter(onlyThoseWithNGreaterOrEqualThanTotalThreshold)
			.sort(this._comparator)
			.map((el) => el.category);

		const categoryNames = Array.from(new Set(categories));

		const createLabelsFrom = (quarter: CriteriaPerQuarter) => {
			const data = createMap(quarter.criteria);
			const values = categoryNames.map((name) => {
				return { name, value: data.get(name) };
			});
			return values
				.sort((a, b) => this._comparator(a.value, b.value))
				.map((data) => createLabel(data.name, data.value));
		};

		return {
			leftTop: createLabelsFrom(data.q1),
			rightTop: createLabelsFrom(data.q2),
			leftBottom: createLabelsFrom(data.q3),
			rightBottom: createLabelsFrom(data.q4),
		};
	}
}
