import { ProjectFormBody, useLazyCheckProjectIdAvailabilityQuery } from 'store/api';
import { ClosureDateState } from 'features/ProjectConfiguration/components';

export type ProjectValidationProperties = { shouldCheckForShortIdUniqueness: boolean };

export const useProjectFormDataValidation = () => {
	const [validateAvailability] = useLazyCheckProjectIdAvailabilityQuery();

	const validateForm = async (data: ProjectFormBody, props?: ProjectValidationProperties) => {
		if (!data.shortId) return false;

		const shortId = await validateAvailability({ projectShortId: data.shortId }).unwrap();
		if (props?.shouldCheckForShortIdUniqueness && !shortId.available) {
			return false;
		}

		return [
			required(data.shortId),
			required(data.type),
			required(data.description),
			whenFileIsPresentThenShouldBeValid(data.file),
			validCompletedQuestionnairesPercentage(data.closureCriteria?.percentOfCompletedQuestionnaires),
			validClosureDate(data.closureCriteria?.closureDate),
		].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function required(data: string) {
		return data.trim() != null && !!data.trim().length;
	}

	function requiredExtension(data: string, requirement: string) {
		return data === requirement;
	}

	function whenFileIsPresentThenShouldBeValid(file: File | undefined) {
		if (file) {
			const extensionIndex = 1;
			const fileExtension = file.name.split('.')[extensionIndex];
			const requiredFileExtension = 'csv';

			if (!requiredExtension(fileExtension, requiredFileExtension)) {
				return false;
			}

			return true;
		}

		return true;
	}

	function validCompletedQuestionnairesPercentage(percentage: number | undefined) {
		if (!percentage) {
			return true;
		}

		const minValue = 1;
		const maxValue = 100;
		return percentage >= minValue && percentage <= maxValue;
	}

	function validClosureDate(date: string | undefined) {
		return date !== ClosureDateState.INVALID;
	}
};
