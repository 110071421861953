import { ProjectResultsGraphType, ProjectResultsGraphTypes } from 'features/Graphs';
import { useDispatch, useSelector } from 'react-redux';
import {
	ApiAllTranslationsResponse,
	ApiGraphResponseBenchmark,
	ApiGraphResponseBenchmarks,
	ApiResultResponse,
} from 'store/api';
import {
	HeatmapGraphCluster,
	setHeatmapGraphCombinedODNA,
	setHeatmapGraphCombinedPDNA,
	setHeatmapGraphODNA,
	setHeatmapGraphPDNA,
} from 'store/slices/graphSlices';
import { assignBenchmarks, getBenchmarkName, getResponseClusters } from '../utils';
import { HeatmapGraphCategoryLabel } from 'features/Graphs/HeatmapGraph';
import { selectBenchmarkNames } from 'store/index';

export const useHeatmap = (result: ApiResultResponse, translations: ApiAllTranslationsResponse) => {
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const dispatch = useDispatch();

	const getIndicator = (benchmark: ApiGraphResponseBenchmark | undefined) => {
		const divisor = 100;
		const defaultValue = 0;
		return benchmark ? benchmark.indicator / divisor : defaultValue;
	};

	const getNumberOfParticipants = (benchmark: ApiGraphResponseBenchmark | undefined) => {
		const defaultValue = 0;
		return benchmark ? benchmark.numberOfParticipants : defaultValue;
	};

	const getCCBKeys = (benchmark: ApiGraphResponseBenchmark, lookForCCB: boolean) => {
		return Object.keys(benchmark.dimensionKeyCCBMap)
			.filter(
				(key) => benchmark.dimensionKeyCCBMap[key as keyof typeof benchmark.dimensionKeyCCBMap] === lookForCCB,
			)
			.sort();
	};

	const getValues = (benchmark: ApiGraphResponseBenchmark | undefined, lookForCCB: boolean) => {
		const values: number[] = [];
		const emptyValue = 0;

		if (!benchmark) return [];

		const ccbKeys = getCCBKeys(benchmark, lookForCCB);

		ccbKeys.forEach((key) =>
			values.push(
				benchmark.dimensionKeyValueMap[key as keyof typeof benchmark.dimensionKeyValueMap] || emptyValue,
			),
		);

		return values;
	};

	const getLabels = (benchmark: ApiGraphResponseBenchmark | undefined, lookForCCB: boolean) => {
		const labels: HeatmapGraphCategoryLabel[] = [];

		if (!benchmark) return [];

		const ccbKeys = getCCBKeys(benchmark, lookForCCB);

		ccbKeys.forEach((key) =>
			labels.push({
				color: '',
				key,
				label: translations.translations[key as keyof typeof benchmark.dimensionKeyCCBMap],
			}),
		);

		return labels;
	};

	function prepareLabel(benchmarkIndex: number) {
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		return benchmarkName ? `${benchmarkName}` : `b${benchmarkIndex}`;
	}

	const prepareBenchmark = (benchmarkIndex: number, benchmark: ApiGraphResponseBenchmark | undefined) => {
		const label = prepareLabel(benchmarkIndex);

		return {
			label: label,
			left: {
				indicator: getIndicator(benchmark),
				numberOfParticipants: getNumberOfParticipants(benchmark),
				values: getValues(benchmark, true),
			},
			onHeaderClick: () => null,
			right: {
				indicator: getIndicator(benchmark),
				numberOfParticipants: getNumberOfParticipants(benchmark),
				values: getValues(benchmark, false),
			},
		};
	};

	const prepareCluster = (benchmarks: ApiGraphResponseBenchmarks) => {
		const firstBenchmarkIndex = 1;
		const secondBenchmarkIndex = 2;
		const thirdBenchmarkIndex = 3;
		const fourthBenchmarkIndex = 4;
		const fifthBenchmarkIndex = 5;

		const preparedCluster: Omit<HeatmapGraphCluster, 'clusterName'> = {
			benchmarks: [
				prepareBenchmark(firstBenchmarkIndex, benchmarks.BENCHMARK_1),
				prepareBenchmark(secondBenchmarkIndex, benchmarks.BENCHMARK_2),
				prepareBenchmark(thirdBenchmarkIndex, benchmarks.BENCHMARK_3),
				prepareBenchmark(fourthBenchmarkIndex, benchmarks.BENCHMARK_4),
				prepareBenchmark(fifthBenchmarkIndex, benchmarks.BENCHMARK_5),
			],
			labels: {
				left: getLabels(benchmarks.BENCHMARK_1, true),
				right: getLabels(benchmarks.BENCHMARK_1, false),
			},
		};

		return preparedCluster;
	};

	const prepareHeatmapGraphData = (type: ProjectResultsGraphType) => {
		const clusters: HeatmapGraphCluster[] = [];
		const responseClusters = getResponseClusters(result);

		Object.values(responseClusters).map((cluster) => {
			const benchmarks: ApiGraphResponseBenchmarks = {};
			assignBenchmarks(benchmarks, cluster, type);

			const currentCluster = prepareCluster(benchmarks);
			clusters.push({
				benchmarks: currentCluster.benchmarks,
				labels: currentCluster.labels,
				clusterName: cluster.clusterName,
			});
		});

		return { clusters };
	};

	const dataODNA = prepareHeatmapGraphData(ProjectResultsGraphTypes.ODNA);
	const dataPDNA = prepareHeatmapGraphData(ProjectResultsGraphTypes.PDNA);
	const dataCombinedPDNA = prepareHeatmapGraphData(ProjectResultsGraphTypes.COMBINED_PDNA);
	const dataCombinedODNA = prepareHeatmapGraphData(ProjectResultsGraphTypes.COMBINED_ODNA);

	dispatch(setHeatmapGraphODNA(dataODNA));
	dispatch(setHeatmapGraphPDNA(dataPDNA));
	dispatch(setHeatmapGraphCombinedODNA(dataCombinedODNA));
	dispatch(setHeatmapGraphCombinedPDNA(dataCombinedPDNA));
};
