import { QuestionItemProps } from './QuestionItem.types';
import { AnswerListItem, AnswersList, AnswerText, QuestionText, QuestionWrapper } from './styled';

export const QuestionItem = ({ answers, criteriaNumber, questionCode, text }: QuestionItemProps) => {
	const AnswersItems = (
		<>
			{answers.map((answer) => (
				<AnswerListItem key={answer}>
					<AnswerText>{answer}</AnswerText>
				</AnswerListItem>
			))}
		</>
	);

	return (
		<QuestionWrapper>
			<QuestionText>{`${questionCode}. ${text} (Criteria ${criteriaNumber})`}</QuestionText>
			<AnswersList>{AnswersItems}</AnswersList>
		</QuestionWrapper>
	);
};
