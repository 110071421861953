import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'common/types';
import { AuthState } from '.';
import { RootState } from 'store/store';

const initialState: AuthState = {
	user: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, { payload }: PayloadAction<User | null>) => {
			state.user = payload;
			return state;
		},
		reset: () => initialState,
	},
});

export const { setUser, reset } = authSlice.actions;
export const authSliceReducer = authSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const getUserRole = (state: RootState) => {
	const roleIndex = 0;
	return state.auth.user?.permissions.filter((permission) => permission.startsWith('ROLE_'))[roleIndex];
};
