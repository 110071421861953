import { PageLayout } from 'common/components';
import { FilePickerForm } from 'common/components';
import { NotificationTypes, useNotifications } from 'common/components';
import { useEditLogoMutation, useEditQuestionnaireLogoMutation, useGetBrandingQuery } from 'store/api';
import { useParams } from 'react-router-dom';

export const ClientBranding = () => {
	const { pushNotification } = useNotifications();
	const [apiLogoSave] = useEditLogoMutation();
	const [apiQuestionnaireLogoSave] = useEditQuestionnaireLogoMutation();
	const { tenantId } = useParams();
	const logoData = useGetBrandingQuery({ tenantId: tenantId || '' });

	const notifyAboutSuccessUploadedLogo = () => {
		pushNotification({
			message: 'Logo uploaded',
			type: NotificationTypes.Success,
		});
	};

	const notifyAboutSuccessUploadedQuestionnaireLogo = () => {
		pushNotification({
			message: 'Questionnaire login logo uploaded',
			type: NotificationTypes.Success,
		});
	};

	const notifyAboutFailure = () => {
		pushNotification({
			message: `Something went wrong during last operation!`,
			type: NotificationTypes.Error,
		});
	};

	const uploadLogo = async (file: FormData) => {
		try {
			if (tenantId) await apiLogoSave({ file, tenantId }).unwrap();
			notifyAboutSuccessUploadedLogo();
		} catch {
			notifyAboutFailure();
		}
	};

	const uploadQuestionnaireLogo = async (file: FormData) => {
		try {
			if (tenantId) await apiQuestionnaireLogoSave({ file, tenantId }).unwrap();
			notifyAboutSuccessUploadedQuestionnaireLogo();
		} catch {
			notifyAboutFailure();
		}
	};

	const getFilePickers = () => {
		if (!logoData.isSuccess) return <></>;

		const logoPreview = logoData.data.logo ? <a href={`${logoData.data.logo}`}>Current Logo</a> : 'Unspecified';
		const questionnaireLogoPreview = logoData.data.questionnaireLoginLogo ? (
			<a href={`${logoData.data.questionnaireLoginLogo}`}>Current Logo</a>
		) : (
			'Unspecified'
		);

		return (
			<>
				<FilePickerForm
					labelName="Current Logo"
					previewName={logoPreview}
					inputLabel="New Logo"
					placeholderTitle="Choose file..."
					buttonName="SAVE LOGO"
					fileType=".png"
					onFileSave={uploadLogo}
					underline={true}
					errorText="PNG file is required"
				/>
				<FilePickerForm
					labelName="Current Questionnaire Login Logo"
					previewName={questionnaireLogoPreview}
					inputLabel="New Questionnaire Login Logo"
					placeholderTitle="Choose file..."
					buttonName="SAVE QUESTIONAIRE LOGIN LOGO"
					fileType=".png"
					onFileSave={uploadQuestionnaireLogo}
					underline={true}
					errorText="PNG file is required"
				/>
			</>
		);
	};

	return (
		<PageLayout
			title="Branding"
			id="client-branding"
		>
			{getFilePickers()}
		</PageLayout>
	);
};
