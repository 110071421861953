import { PageLayout } from 'common/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ParticipantFormLayoutProps } from './ParticipantFormLayout.types';
import { CriteriaInformation, ParticipantInformation } from 'features/Participants/components';

export const ParticipantFormLayout = ({
	id,
	title,
	subtitle,
	saveTrigger,
	criteriaInformationProps,
	participantInformationProps,
	onSaveButtonClick,
}: ParticipantFormLayoutProps) => {
	return (
		<PageLayout
			title={title}
			subtitle={subtitle}
			id={id}
		>
			<div>
				<ParticipantInformation
					saveTrigger={saveTrigger}
					{...participantInformationProps}
				/>
				<CriteriaInformation
					saveTrigger={saveTrigger}
					{...criteriaInformationProps}
				/>
				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
