import { HeatmapGraphConfig } from './HeatmapGraph.types';

const sideTableScaleFirstTick = 0;
const sideTableScaleSecondTick = 0.4;
const sideTableScaleThirdTick = 0.5;
const sideTableScaleFourthTick = 0.6;
const sideTableScaleFifthTick = 0.7;
const sideTableScaleSixthTick = 1;

const mainTableScaleFirstTick = 0;
const mainTableScaleSecondTick = 2;
const mainTableScaleThirdTick = 2.5;
const mainTableScaleFourthTick = 3;
const mainTableScaleFifthTick = 3.5;
const mainTableScaleSixthTick = 5;

const sideTableScaleTicks = [
	sideTableScaleFirstTick,
	sideTableScaleSecondTick,
	sideTableScaleThirdTick,
	sideTableScaleFourthTick,
	sideTableScaleFifthTick,
	sideTableScaleSixthTick,
];
const mainTableScaleTicks = [
	mainTableScaleFirstTick,
	mainTableScaleSecondTick,
	mainTableScaleThirdTick,
	mainTableScaleFourthTick,
	mainTableScaleFifthTick,
	mainTableScaleSixthTick,
];

export const heatmapGraphConfig: HeatmapGraphConfig = {
	cellHeight: 26,
	cellWidth: 140,

	spacing: 18,
	columnSpacing: 8,
	rowSpacing: 4,

	headers: {
		mainTextStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '15px', 'font-weight': 'bold' },
		subTextStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '14px', 'font-weight': 'bold' },
		backgroundColor: '#d8d8d8',
	},

	categoryLabels: {
		useLabelColor: false,
		textStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '14px', 'font-weight': 'bold' },
		backgroundColor: '#d8d8d8',
	},

	data: {
		textStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '14px' },
	},

	sideTableScale: {
		scaleLength: 288,
		scaleHeight: 13,
		offsetY: 10,
		offsetX: 0,
		rangeStart: 0.0,
		rangeEnd: 1.0,
		ticks: sideTableScaleTicks,
		usePercentage: true,
		colors: ['#ff0000', '#ffbf00', '#f0f0f0', '#c3d69a', '#75923b'],
		textStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '14px', fill: '#9e9e9e' },
		titleTextStyle: {
			'font-family': 'Arial, "Liberation Sans"',
			'font-size': '17px',
			'font-weight': 'bold',
			fill: '#000000',
		},
		titleOffsetY: -2,
		scaleTitle: 'Values Ratings',
		leftValueAnnotation: 'Most Absent',
		rightValueAnnotation: 'Most Present',
	},
	mainTableScale: {
		scaleLength: 680,
		scaleHeight: 13,
		offsetY: 10,
		offsetX: 0,
		rangeStart: 0.0,
		rangeEnd: 5.0,
		ticks: mainTableScaleTicks,
		usePercentage: false,
		colors: ['#ff0000', '#ffbf00', '#f0f0f0', '#c3d69a', '#75923b'],
		textStyle: { 'font-family': 'Arial, "Liberation Sans"', 'font-size': '14px', fill: '#9e9e9e' },
		titleTextStyle: {
			'font-family': 'Arial, "Liberation Sans"',
			'font-size': '17px',
			'font-weight': 'bold',
			fill: '#000000',
		},
		titleOffsetY: -2,
		scaleTitle: 'Behaviour Ratings',
		leftValueAnnotation: 'Most Absent',
		rightValueAnnotation: 'Most Present',
	},
	scaleSpacing: 300,
	scaleBaseYOffset: 600,

	canvas: {
		width: 1400,
		height: 650,
	},
};
