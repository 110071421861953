import { BenchmarkComparisonView } from 'common/components/BenchmarkComparisonView';
import { BehaviourFocusBenchmarkTab } from 'features/BehaviourFocus/components';
import { BenchmarkConfigurationId } from 'store/api';
import { useState } from 'react';
import { BehaviourFocusViewResults, BenchmarkGroupLabels } from 'features/BehaviourFocusResult/views';
import { useParams } from 'react-router-dom';

export const BehaviourFocus = () => {
	const { projectId } = useParams();

	const [isResultViewOpened, setisResultViewOpened] = useState(false);
	const [benchmarkConfigurationId, setBenchmarkConfigurationId] = useState<BenchmarkConfigurationId>();
	const [benchmarkLabels, setBenchmarkLabels] = useState(defaultBenchmarkLabels());

	const onViewButtonClick = (benchmarkConfigurationId?: BenchmarkConfigurationId) => {
		setBenchmarkConfigurationId(benchmarkConfigurationId);
		setisResultViewOpened(true);
	};

	const closeResultView = () => {
		setisResultViewOpened(false);
	};

	const canShowResultView = benchmarkConfigurationId !== undefined && isResultViewOpened;

	return (
		<>
			{canShowResultView && (
				<BehaviourFocusViewResults
					projectId={projectId}
					benchmarkConfigurationId={benchmarkConfigurationId}
					isOpened={isResultViewOpened}
					benchmarkLabels={benchmarkLabels}
					onClose={closeResultView}
				/>
			)}
			<BenchmarkComparisonView
				id="behaviour-focus"
				pageName="Benchmark Comparison"
				onViewClick={onViewButtonClick}
				benchmarkTabPanel={(benchmarkConfigId) => {
					return (
						<BehaviourFocusBenchmarkTab
							id={'"behaviour-focus'}
							benchmarkConfigurationId={benchmarkConfigId}
							onBenchmarkLabelChange={setBenchmarkLabels}
						/>
					);
				}}
			/>
		</>
	);

	function defaultBenchmarkLabels() {
		return new BenchmarkGroupLabels('', '', '', '', '', '', '', '', '', '');
	}
};
