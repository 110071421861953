import { ApiParticipant, ApiParticipantDetails, ParticipantId } from 'common/types';
import { convertObjectToQueryParams, isResponseError } from 'common/utils';
import {
	AllParticipantIdsOptions,
	PagedParticipantsOptions,
	ParticipantIdsByProjectIdOptions,
	rootApi,
} from 'store/api';
import {
	ParticipantsResponse,
	ParticipantsByProjectIdOptions,
	AssessedParticipantsResponse,
	AssessedParticipantsOptions,
	DeactivateParticipantOptions,
	ImportParticipantsCsvBody,
	CreateParticipantBody,
	GetParticipantDetailsOptions,
	EditParticipantOptions,
} from './participantsApi.types';

export const participantsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllParticipants: builder.query<ParticipantsResponse<ApiParticipant>, PagedParticipantsOptions>({
			query: (opts) => ({
				url: 'api/viewmode/participants',
				method: 'POST',
				body: opts,
			}),
		}),
		getParticipantsByProjectId: builder.query<ParticipantsResponse<ApiParticipant>, ParticipantsByProjectIdOptions>(
			{
				query: (opts) => ({
					url: `api/viewmode/participants/${opts.projectId}`,
					method: 'POST',
					body: opts,
				}),
			},
		),
		getAllParticipantIds: builder.query<ParticipantId[], AllParticipantIdsOptions>({
			query: (opts) => ({
				url: `api/viewmode/participants/ids`,
				method: 'POST',
				body: opts,
			}),
		}),
		getParticipantsIdsByProjectId: builder.query<ParticipantId[], ParticipantIdsByProjectIdOptions>({
			query: (opts) => ({
				url: `api/viewmode/participants/${opts.projectId}/ids`,
				method: 'POST',
				body: opts,
			}),
		}),
		getAllAssessedParticipants: builder.query<
			AssessedParticipantsResponse<ApiParticipant>,
			AssessedParticipantsOptions
		>({
			query: (opts) => {
				return {
					url: `/api/assessees?${convertObjectToQueryParams(opts)}`,
					method: 'GET',
				};
			},
			providesTags: ['AssessedParticipants'],
		}),
		createParticipant: builder.mutation<void, CreateParticipantBody>({
			query: (opts) => ({
				url: `api/assessees`,
				method: 'POST',
				body: opts,
			}),
			invalidatesTags: ['AssessedParticipants'],
		}),
		getParticipantDetails: builder.query<ApiParticipantDetails, GetParticipantDetailsOptions>({
			query: (opts) => ({
				url: `/api/assessees/${opts.participantId}`,
				method: 'GET',
			}),
			providesTags: ['AssessedParticipant'],
		}),
		editParticipant: builder.mutation<void, EditParticipantOptions>({
			query: (opts) => ({
				url: `api/assessees/${opts.id}`,
				method: 'PUT',
				body: opts,
			}),
			invalidatesTags: ['AssessedParticipants', 'AssessedParticipant'],
		}),
		deactivateParticipant: builder.mutation<void, DeactivateParticipantOptions>({
			query: (opts) => ({
				url: `api/assessees/${opts.participantId}/deactivate`,
				method: 'PUT',
			}),
			invalidatesTags: ['AssessedParticipants'],
		}),
		importParticipantsCsv: builder.mutation<void, ImportParticipantsCsvBody>({
			queryFn: async (data, _api, _, baseQuery) => {
				const url = `/api/imports/assessees`;
				const formData = new FormData();
				const { file } = data;
				formData.append('filename', data.filename);
				formData.append('file', file);
				const response = await baseQuery({
					url,
					method: 'POST',
					body: formData,
				});

				const error = isResponseError(response.data) ? response.data : undefined;
				if (error) return { error: { error: error.error, status: error.status, data: error.message } };
				return { data: undefined };
			},
			invalidatesTags: ['AssessedParticipants'],
		}),
		checkParticipantEmailAvailability: builder.query<{ available: boolean }, { assesseeEmail: string }>({
			query: (data) => ({
				url: `/api/assessee-email-availability`,
				params: data,
			}),
		}),
	}),
});

export const {
	useLazyGetAllParticipantsQuery,
	useGetParticipantDetailsQuery,
	useEditParticipantMutation,
	useLazyGetParticipantsByProjectIdQuery,
	useLazyGetParticipantsIdsByProjectIdQuery,
	useLazyGetAllParticipantIdsQuery,
	useLazyGetAllAssessedParticipantsQuery,
	useDeactivateParticipantMutation,
	useImportParticipantsCsvMutation,
	useLazyCheckParticipantEmailAvailabilityQuery,
	useCreateParticipantMutation,
} = participantsApi;
