import { useCreateProjectFormData } from 'features/ProjectConfiguration/components/CreateProject/hooks';
import { ProjectFormLayout } from 'features/ProjectConfiguration/views';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AutomaticClosureConditionsProps, ProjectDetailsProps } from 'features/ProjectConfiguration/components';
import { useProjectDetails } from 'common/hooks';
import { emptyIfNullOrUndefined } from 'common/utils';
import { format } from 'date-fns';
import { DateFormatPatterns } from 'common/constants';
import { EditProjectBody, useEditProjectMutation } from 'store/api';
import { useEditProjectFormDataValidation } from 'features/ProjectConfiguration/components/CreateProject/hooks/useEditProjectFormDataValidation';

export const EditProject = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);
	const { projectId } = useParams();
	const { projectDetails } = useProjectDetails({ projectId });
	const { getProjectData } = useCreateProjectFormData();
	const { validateForm } = useEditProjectFormDataValidation();
	const [editProject] = useEditProjectMutation();
	const navigate = useNavigate();

	if (projectDetails === undefined) {
		return <></>;
	}

	return (
		<ProjectFormLayout
			id="edit-project"
			title="EDIT EXISTING PROJECT"
			saveTrigger={saveTrigger}
			projectDetailsProps={getProjectDetails()}
			automaticClosureConditionsProps={getAutomaticClosureConditions()}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);

	function getProjectDetails(): ProjectDetailsProps {
		return {
			disableProjectId: true,
			disableAssessmentType: true,
			projectId: projectDetails?.shortId,
			projectDescription: projectDetails?.description,
			costCentreReference: emptyIfNullOrUndefined(projectDetails?.costCenterReference),
			assessmentType: projectDetails?.type,
		};
	}

	function getAutomaticClosureConditions(): AutomaticClosureConditionsProps {
		const closureDate = projectDetails?.closureCriteria?.closureDate;
		return {
			date: closureDate ? format(closureDate, DateFormatPatterns.Default) : '',
			percentageOfQuestionnaires: projectDetails?.closureCriteria?.percentOfCompletedQuestionnaires,
			disableDate: true,
			disablePercentageOfQuestionnaires: true,
		};
	}

	async function onSaveButtonClick() {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const projectData = await getProjectData();
		const validate = () => validateForm(projectData);
		const isFormValid = validate();

		if (!isFormValid) return;

		await tryToEditProject(projectData);
	}

	async function tryToEditProject(project: EditProjectBody) {
		if (!projectId) {
			return;
		}
		editProject({ ...project, projectId });
		navigate('/projects');
	}
};
