import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { BillingAddressState } from '..';

const initialState: BillingAddressState = {
	firstBillingAddress: '',
	secondBillingAddress: '',
	billingCity: '',
	billingPostcode: '',
};

export const billingAddressSlice = createSlice({
	name: 'billingAddress',
	initialState,
	reducers: {
		setBillingAddressState: (state, { payload }: PayloadAction<BillingAddressState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setBillingAddressState } = billingAddressSlice.actions;

export const billingAddressSliceReducer = billingAddressSlice.reducer;

export const getBillingAddressState = (state: RootState) => {
	return state.createClientReducers.billingAddress;
};
