import { DisappearingElement } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantTypeSelection/styled';
import { Criterion } from 'common/types';
import { StyledCriteriaColumn } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/CriteriaMenuDropdown/styled';
import { CriteriaDropdown } from 'features/DiscoveryInsights/components';

export const CriteriaMenuDropdown = ({
	criterion,
	criteria,
	visible,
	setCriterion,
}: {
	criterion: Criterion;
	criteria: Criterion[];
	visible: boolean;
	setCriterion: (criterion: Criterion) => void;
}) => {
	return (
		<StyledCriteriaColumn>
			<DisappearingElement $appear={visible}>
				<div>Selected criteria:</div>
				<CriteriaDropdown
					criterion={criterion}
					criteria={criteria}
					setCriterion={setCriterion}
				/>
			</DisappearingElement>
		</StyledCriteriaColumn>
	);
};
