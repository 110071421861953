import { SelectionPanel, SelectionPanelItem } from 'common/components';
import { useFrameworkDetails, useFrameworks } from 'common/hooks';
import { Cluster } from 'common/types';
import { useEffect, useState } from 'react';
import { ClusterSelectionProps } from 'features/DiscoveryInsights/components';

const first = 0;
export const ClusterSelection = ({ onClusterSelection }: ClusterSelectionProps) => {
	const clusters = getClusters();
	const [selectedClusterId, setSelectedClusterId] = useState(initialClusterId());
	useEffect(() => onClusterSelection(selectedClusterId), [selectedClusterId]);

	return (
		<SelectionPanel<Cluster>
			id="cluster-selection-panel"
			selectedItemId={selectedClusterId}
			onSelect={(item) => setSelectedClusterId(item.clusterId)}
		>
			{getClusterPanelItems()}
		</SelectionPanel>
	);

	function initialClusterId() {
		return clusters ? clusters[first].cluster.clusterId : 'CL-00';
	}

	function getClusters() {
		const { frameworks } = useFrameworks();
		const frameworkId = frameworks.find((framework) => framework.active)?.frameworkId;
		const { frameworkDetails } = useFrameworkDetails({ frameworkId });
		return frameworkDetails?.clusters;
	}

	function getClusterPanelItems() {
		return clusters
			? clusters.map((clusterDimension) => {
					const cluster = clusterDimension.cluster;
					const shouldBeDisabled = !clusterDimension.selectedDimensions.length;
					return (
						<SelectionPanelItem
							key={cluster.clusterId}
							id={cluster.clusterId}
							fadedWhenInactive={shouldBeDisabled}
							itemData={cluster}
							disabled={shouldBeDisabled}
							TooltipContent={
								<>
									{clusterDimension.selectedDimensions.map((dimension) => (
										<div key={dimension.dimensionId}>{dimension.name}</div>
									))}
								</>
							}
						>
							{cluster.name}
						</SelectionPanelItem>
					);
			  })
			: [];
	}
};
