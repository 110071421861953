import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { Project, TableAction } from 'common/types';
import { ProjectColumnId } from 'features/Tables/hooks/useProjectsData/ProjectColumnIds';
import { format } from 'date-fns';
import { QuestionnaireTypeFilter, Tooltip } from 'common/components';
import { DateFilter } from 'common/components';
import {
	faBolt,
	faChartSimple,
	faCircleQuestion,
	faListCheck,
	faMagnifyingGlass,
	faPenToSquare,
	faUserPlus,
	faWandMagicSparkles,
	faRoute,
} from '@fortawesome/free-solid-svg-icons';
import { TableHeaderLabel, TableIconButton, TableWrapper } from 'features/Tables/components';
import { DateFormatPatterns, TableAssessmentTypes } from 'common/constants';

export const useProjectsData = () => {
	const whenProjectIsClosed = (project: Project) => project.closeDate !== null;
	const actions = React.useMemo<TableAction<Project>[]>(
		() => [
			{
				label: 'Edit Project',
				icon: faPenToSquare,
				isHidden: whenProjectIsClosed,
				getHref: (row: Project) => `/projects/${row.projectId}`,
			},
			{
				label: 'Participant selection',
				icon: faUserPlus,
				isHidden: whenProjectIsClosed,
				getHref: (row: Project) => `/projects/${row.projectId}/management`,
			},
			{
				label: 'Project Actions',
				icon: faChartSimple,
				getHref: (row: Project) => `/projects/${row.projectId}/actions`,
			},
			{
				label: 'View Project Results',
				icon: faListCheck,
				getHref: (row: Project) => `/culture-view/${row.projectId}`,
			},
			{
				label: 'Additional Questions',
				icon: faCircleQuestion,
				getHref: (row: Project) => `/projects/${row.projectId}/projectQuestions`,
			},
			{
				label: 'View Predictive Results',
				icon: faMagnifyingGlass,
				getHref: (row: Project) => `/predictive-view/${row.projectId}`,
			},
			{
				label: 'Discover Insights',
				icon: faWandMagicSparkles,
				getHref: (row: Project) => `/discovery-insights/${row.projectId}`,
			},
			{
				label: 'Behaviour Focus',
				icon: faBolt,
				getHref: (row: Project) => `/behaviour-focus/${row.projectId}`,
			},
			{
				label: 'Roadmap',
				icon: faRoute,
				getHref: (row: Project) => `/road-map/${row.projectId}/overview`,
			},
		],
		[],
	);

	const columns = React.useMemo<ColumnDef<Project>[]>(
		() => [
			{
				id: ProjectColumnId.ShortId,
				header: 'Project ID',
				accessorKey: 'shortId',
			},
			{
				id: ProjectColumnId.Type,
				header: 'Assessment type',
				accessorKey: 'type',
				accessorFn: (row: Project) => {
					return TableAssessmentTypes[row.type];
				},
				meta: {
					customFilterComponent: QuestionnaireTypeFilter,
				},
			},
			{
				id: ProjectColumnId.StartDate,
				header: 'Start date',
				accessorKey: 'startDate',
				accessorFn: ({ startDate }: Project) => {
					return format(new Date(startDate), DateFormatPatterns.Default);
				},
				meta: {
					customFilterComponent: DateFilter,
				},
			},
			{
				id: ProjectColumnId.CloseDate,
				header: 'Completion date',
				accessorKey: 'closeDate',
				accessorFn: ({ closeDate }: Project) => {
					return closeDate && format(new Date(closeDate), DateFormatPatterns.Default);
				},
				meta: {
					customFilterComponent: DateFilter,
				},
			},
			{
				id: ProjectColumnId.Actions,
				header: () => (
					<TableHeaderLabel
						text="Actions"
						centered={true}
					/>
				),
				accessorFn: (row) => row,
				cell: ({ getValue }) => {
					const project = getValue<Project>();

					return (
						<TableWrapper>
							{actions.map((action) => {
								if (action.isHidden?.(project)) return;
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											href={action.getHref ? action.getHref(project) : undefined}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
		],
		[],
	);

	return { columns };
};
