import { ColumnDef, ColumnFilter, HeaderContext } from '@tanstack/react-table';

import { Participant, ParticipantId, Criterion, BenchmarkParticipant, ProjectId } from 'common/types';
import { useGetLoggedInUserQuery } from 'store/api';
import { useState } from 'react';
import { BenchmarkColumn, BenchmarkParticipantColumnIds } from 'features/Tables/hooks';
import { BenchmarkConfigurationId } from 'store/api/benchmarkConfigurationApi';
import { CellContext } from '@tanstack/table-core/build/lib/core/cell';
import { BenchmarkHeader } from 'features/ResultsConfiguration/components';
import { BenchmarkParticipantToggle } from 'features/ResultsConfiguration/components';
import { BenchmarkStateFilter } from 'common/components';

export type OnAssignationChangeCallback = () => void;

export const useBenchmarkParticipantData = (
	projectId: ProjectId | undefined,
	benchmarkConfigurationId: BenchmarkConfigurationId | undefined,
	onAssignationChange: OnAssignationChangeCallback,
	benchmarkColumns: BenchmarkColumn[],
	additionalVisibleColumnIds?: string[],
) => {
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);
	const loggedUser = useGetLoggedInUserQuery();

	const criteriaColumns: ColumnDef<BenchmarkParticipant>[] = createCriteriaColumns();
	const personalColumns: ColumnDef<BenchmarkParticipant>[] = [
		{
			id: BenchmarkParticipantColumnIds.Email,
			header: `Email`,
			accessorFn: (p) => p.email,
		},
		{
			id: BenchmarkParticipantColumnIds.FirstName,
			header: 'First',
			accessorFn: (p) => p.firstName,
		},
		{
			id: BenchmarkParticipantColumnIds.LastName,
			header: 'Last Name',
			accessorFn: (p) => p.lastName,
		},
	];

	const benchmarksColumns = benchmarkColumns.map(createBenchmarkColumn);

	const columnsBenchmarks = personalColumns.concat(criteriaColumns).concat(benchmarksColumns);

	const visibleColumnsBenchmarks = columnsBenchmarks.filter((col) =>
		[
			BenchmarkParticipantColumnIds.Email,
			BenchmarkParticipantColumnIds.FirstName,
			BenchmarkParticipantColumnIds.LastName,
			BenchmarkParticipantColumnIds.Benchmark1,
			BenchmarkParticipantColumnIds.Benchmark2,
			BenchmarkParticipantColumnIds.Benchmark3,
			BenchmarkParticipantColumnIds.Benchmark4,
			BenchmarkParticipantColumnIds.Benchmark5,
		]
			.concat(additionalVisibleColumnIds || [])
			.includes(col.id as string),
	);

	return {
		columnsBenchmarks,
		visibleColumnsBenchmarks,
		selectedParticipantIds,
		setSelectedParticipantIds,
	};

	function createBenchmarkColumn(benchmarkGroup: string): ColumnDef<BenchmarkParticipant> {
		return {
			id: benchmarkGroup,
			header: createHeaderFor(benchmarkGroup),
			cell: createToggleFor(benchmarkGroup),
			accessorFn: (b: BenchmarkParticipant) => b,
			meta: {
				customFilterComponent: BenchmarkStateFilter,
			},
			enableSorting: true,
			enableColumnFilter: true,
		};
	}

	function createCriteriaColumns(): ColumnDef<BenchmarkParticipant>[] {
		const criteria = loggedUser.data?.configuration.criteriaConfiguration ?? [];

		const createColumn = (criterion: Criterion): ColumnDef<BenchmarkParticipant> => ({
			id: `c${criterion.id}`,
			header: criterion.name,
			accessorFn: (participant: Participant) => participant.criteria.findById(criterion.id).name,
		});

		return criteria.map(createColumn);
	}

	function createFiltersList(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function createHeaderFor(benchmarkGroup: string) {
		function Header({ table }: HeaderContext<BenchmarkParticipant, unknown>) {
			const filters = createFiltersList(table.getState().columnFilters);
			return (
				<BenchmarkHeader
					projectId={projectId}
					filters={filters}
					benchmarkGroup={benchmarkGroup}
					benchmarkId={benchmarkConfigurationId}
					onAssignationChange={onAssignationChange}
				/>
			);
		}
		return Header;
	}

	function createToggleFor(benchmarkGroup: string) {
		function Toggle({ getValue }: CellContext<BenchmarkParticipant, unknown>) {
			const participant = getValue<BenchmarkParticipant>();
			return (
				<BenchmarkParticipantToggle
					participant={participant}
					benchmarkConfigurationId={benchmarkConfigurationId}
					benchmarkGroup={benchmarkGroup}
					onAssignationChange={onAssignationChange}
				/>
			);
		}
		return Toggle;
	}
};
