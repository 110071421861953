import { BenchmarkConfigurationId } from 'store/api';
import { useLazyGetCriteriaViewQuery, useLazyGetTotalViewQuery } from 'store/api/discoveryInsightApi';
import { ProjectId } from 'common/types';
import { TotalAndCriteriaViewData } from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import { ApiCriteriaId, ApiQuadrantCenter } from 'store/api/discoveryInsightApi';

export const useTotalAndCriteriaViewQuadrantData = () => {
	const [fetchTotalView] = useLazyGetTotalViewQuery();
	const [fetchCriteriaView] = useLazyGetCriteriaViewQuery();

	return (
		benchmarkConfigurationId: BenchmarkConfigurationId,
		selectedCriterion: ApiCriteriaId,
		highlightCriteria: ApiCriteriaId[],
		quadrantCenter: ApiQuadrantCenter,
		projectId?: ProjectId,
	): Promise<TotalAndCriteriaViewData> => {
		const baseRequest = { benchmarkConfigurationId, projectId };
		const baseQuadrantRequest = { ...baseRequest, quadrantCenter };
		const request = { ...baseQuadrantRequest, criteriaId: selectedCriterion };

		const totalView = fetchTotalView(request);
		const criteriaView = fetchCriteriaView(request);

		const first = 0;
		const second = 1;
		return Promise.all([totalView, criteriaView]).then((result) => {
			const totalView = result[first].data;
			const criteriaView = result[second].data;

			if (!totalView || !criteriaView) {
				throw Error();
			}
			return {
				totalView,
				criteriaView,
			};
		});
	};
};
