import { isQuoted, removeQuotation } from 'common/utils';

export const filterWithQuotation = (value: string, searched: string) => {
	if (filteringShouldBeStrictFor(searched)) {
		const withoutQuotes = removeQuotation(searched);
		return equals(value, withoutQuotes);
	}
	return include(value, searched);

	function filteringShouldBeStrictFor(searched: string) {
		return isQuoted(searched);
	}

	function include(value: string, filterValue: string) {
		const search = filterValue.toLowerCase();
		return Boolean(value.toLowerCase().includes(search));
	}

	function equals(value: string, filterValue: string) {
		return value.toLowerCase() === filterValue.toLowerCase();
	}
};
