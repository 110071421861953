import { useClickOutsideListener } from 'common/hooks';
import { useEffect, useState } from 'react';
import { Container, StyledButton, StyledInput } from './styled';
import { ConfirmableNameInputProps } from './ConfirmableNameInput.types';

export const ConfirmableNameInput = ({
	value,
	onConfirm,
	className,
	label,
	readOnly = false,
	maxLength,
	id,
}: ConfirmableNameInputProps) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [editedValue, setEditedValue] = useState(value);

	const onClickAway = () => {
		setIsEditMode(false);
	};

	const { ref: confirmButtonRef, additionalRef: inputRef } = useClickOutsideListener<
		HTMLButtonElement,
		HTMLInputElement
	>({
		onClickAway: onClickAway,
	});

	useEffect(() => {
		document.addEventListener('keypress', (event) => {
			handleKeyPress(event.key);
		});
		if (!isEditMode) {
			setEditedValue(value);
		}
	}, [isEditMode]);

	useEffect(() => {
		setEditedValue(value);
	}, [value]);

	const onButtonClick = () => {
		if (inputRef.current && !isEditMode) {
			moveCursorToInputEnd(inputRef.current);
			inputRef.current.focus();
			setIsEditMode(true);
			return;
		}

		if (isEditMode) {
			const value = inputRef.current?.value ? inputRef.current.value : editedValue;
			onConfirm(value);
			setIsEditMode(false);
			return;
		}
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;

		if (isEditMode) {
			setEditedValue(inputValue);
		}
	};

	const handleKeyPress = (key: string) => {
		setIsEditMode((isEditMode) => isEditMode);
		if (key === 'Enter' && isEditMode) {
			onButtonClick();
		}
	};

	const moveCursorToInputEnd = (input: HTMLInputElement) => {
		const endIndex = input.value.length;
		input.setSelectionRange(endIndex, endIndex);
	};

	return (
		<Container
			id={id}
			className={className}
		>
			<StyledInput
				inputRef={inputRef}
				readOnly={!isEditMode}
				onChange={onInputChange}
				value={isEditMode ? editedValue : value}
				labelPlacement="left"
				name={label}
				maxLength={maxLength}
			/>
			{!readOnly && (
				<StyledButton
					buttonRef={confirmButtonRef}
					variant="outlined"
					onClick={onButtonClick}
				>
					{isEditMode ? 'Confirm' : 'Rename'}
				</StyledButton>
			)}
		</Container>
	);
};
