import { QuadrantsPerCluster } from 'store/slices';
import {
	ApiBenchmarkQuadrantGraphData,
	ApiBenchmarkQuadrantGraphPerCluster,
	ApiDistributionPerQuarter,
	ApiQuadrantGraphPoint,
	ApiQuadrantResult,
	ApiQuarterlyDistributionSummary,
} from 'store/api';
import { IData, ILegendData } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartData';
import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph';

export class QuadrantStateCreator<T extends ApiDistributionPerQuarter> {
	constructor(private _legendCreator: QuadrantLegendCreator<T>) {}

	public create(data: ApiQuadrantResult<T>) {
		const quadrantsPerCluster = this._prepareQuadrantsPerCluster(data);
		return { clusters: quadrantsPerCluster };
	}

	private _prepareQuadrantsPerCluster(data: ApiQuadrantResult<T>): QuadrantsPerCluster[] {
		const createQuadrantPerCluster = (clusterData: ApiBenchmarkQuadrantGraphPerCluster<T>): QuadrantsPerCluster => {
			const clusterId = clusterData.clusterOrdinal;
			const quadrant = this._createQuadrant(clusterData.data);
			return { clusterId, data: quadrant };
		};

		return data.perCluster.map(createQuadrantPerCluster);
	}

	private _createQuadrant(graphData: ApiBenchmarkQuadrantGraphData<T>) {
		const legend = this._createLegend(graphData.distribution);
		const data = this._createPoints(graphData.points);
		const rScale = this._createRScale(graphData.points);
		return {
			data,
			legend,
			rScale,
		};
	}

	private _createPoints(points: ApiQuadrantGraphPoint[]): IData[] {
		return points.map((point) => ({
			x: point.pdna,
			y: point.odna,
			r: point.size,
		}));
	}

	private _createLegend(distribution: ApiQuarterlyDistributionSummary<T>): ILegendData {
		return this._legendCreator.createLegend(distribution);
	}

	private _createRScale(points: ApiQuadrantGraphPoint[]) {
		const radius = points.map((point) => point.size);

		return {
			min: Math.min(...radius),
			max: Math.max(...radius),
		};
	}
}
