import { ApiDimension, Dimension } from 'common/types';
import {
	AllDimensionsResponse,
	getAllDimensionsEmptyResponse,
	useGetAllDimensionsQuery,
	useGetDimensionsLegendQuery,
	useGetGraphConfigurationQuery,
} from 'store/api';
import { mapGetAllDimensionsResponse } from './mappers';

export const useDimensions = () => {
	const allDimensions = useGetAllDimensionsQuery();
	const emptyResponse = getAllDimensionsEmptyResponse();
	const apiDimensions = getApiDimensionsData();
	const { data: apiDimensionsLegend } = useGetDimensionsLegendQuery();
	const { data: graphConfiguration } = useGetGraphConfigurationQuery();

	function getApiDimensionsData(): AllDimensionsResponse<ApiDimension> {
		if (!allDimensions.isSuccess) return emptyResponse;

		return allDimensions.data;
	}

	function transform(apiDimensions: AllDimensionsResponse<ApiDimension>): AllDimensionsResponse<Dimension> {
		return mapGetAllDimensionsResponse(
			apiDimensions,
			apiDimensionsLegend,
			graphConfiguration?.dimensionColors.colorMap,
		);
	}

	return { dimensions: transform(apiDimensions) };
};
