import {
	ProjectDetails,
	ApiProjectDetails,
	ApiAssignmentStatus,
	Criterion,
	AssignmentStatus,
	Pagination,
	CriterionId,
	CriteriaRepository,
} from 'common/types';
import { ProjectDetailsResponse, AssignmentStatusResponse } from 'store/api';

export const mapGetAssignmentStatusResponse = (
	response: ProjectDetailsResponse<ApiProjectDetails>,
): ProjectDetailsResponse<ProjectDetails> => {
	const { closeDate, closureCriteria } = response;
	return {
		...response,
		closeDate: closeDate ? new Date(closeDate) : null,
		closureCriteria: closureCriteria
			? {
					...closureCriteria,
					closureDate: closureCriteria.closureDate ? new Date(closureCriteria.closureDate) : null,
			  }
			: null,
	};
};

export const transformAssignmentStatusResponse = (
	response: AssignmentStatusResponse<ApiAssignmentStatus>,
	criteriaConfiguration: Criterion[],
): AssignmentStatusResponse<AssignmentStatus> => {
	const apiAssignmentStatuses = response.assignmentStatuses;
	const pagination = createPagination(response);
	return {
		...pagination,
		assignmentStatuses: apiAssignmentStatuses.map((assignmentStatuses) =>
			createAssignmentStatus(criteriaConfiguration, assignmentStatuses),
		),
	};
};

function createAssignmentStatus(
	criteriaConfiguration: Criterion[],
	assignmentStatus: ApiAssignmentStatus,
): AssignmentStatus {
	const { q, c: _c, ...participantCopy } = assignmentStatus;
	return {
		...participantCopy,
		questionnaireDoneDate: q,
		deletionTimestamp: assignmentStatus.del,
		criteria: createCriteria(criteriaConfiguration, assignmentStatus.c),
	};
}

function createCriteria(criteriaConfiguration: Criterion[], criteriaMap: Record<string, string>) {
	const criteria: Criterion[] = criteriaConfiguration.map((criterionConfig) => {
		const id: CriterionId = criterionConfig.id;
		const decreasedByOne = 1;
		const criteriaValue = criteriaMap[parseInt(id) - decreasedByOne];
		return { id, name: criteriaValue, enabled: criterionConfig.enabled };
	});

	return new CriteriaRepository(criteria);
}

function createPagination(response: AssignmentStatusResponse<ApiAssignmentStatus>): Pagination {
	return {
		pageNumber: response.pageNumber,
		totalPages: response.totalPages,
	};
}
