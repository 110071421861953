import { Column } from '@tanstack/react-table';
import { StyledDropdown } from '../QuestionnaireTypeFilter/styled';
import { removeQuotation } from 'common/utils';
import { TableSelectionFilters } from 'common/constants';
import { faSquare, faSquareCheck, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItemProperties } from './BenchmarkStateFilter.types';
import { DropdownItemWithIcon } from 'common/components';

export function BenchmarkStateFilter<T>(column: Column<T>) {
	const dropdownItemProperties = [
		{ value: TableSelectionFilters.Selected, icon: faSquareCheck },
		{ value: TableSelectionFilters.Unselected, icon: faSquare },
		{ value: TableSelectionFilters.All },
	];
	const selected = getSelectionFrom(column);
	const selectedIcon = getSelectionIcon(selected);

	const dropdownItems = createDropdownItems(dropdownItemProperties);

	return (
		<StyledDropdown
			fullContentWidth={true}
			value={selected}
			onChange={onApplyFilter}
			labelIcon={selectedIcon}
		>
			{dropdownItems}
		</StyledDropdown>
	);

	function createDropdownItems(items: DropdownItemProperties[]) {
		return items.map((item) => (
			<DropdownItemWithIcon
				key={items.indexOf(item)}
				value={item.value}
			>
				{item.icon ? <FontAwesomeIcon icon={item.icon} /> : null}
				{item.value}
			</DropdownItemWithIcon>
		));
	}

	function onApplyFilter(filterValue: string | null) {
		const shouldSelectEverything = filterValue === TableSelectionFilters.All || filterValue == null;
		if (shouldSelectEverything) {
			column.setFilterValue(null);
			return;
		}
		switch (filterValue) {
			case TableSelectionFilters.Selected:
				column.setFilterValue(true);
				return;
			case TableSelectionFilters.Unselected:
				column.setFilterValue(false);
				return;
		}
	}

	function getSelectionFrom(column: Column<T>) {
		const selected = column.getFilterValue();
		const nothingWasSelected = selected == null;
		if (nothingWasSelected) {
			return TableSelectionFilters.All;
		}
		if (selected) {
			return TableSelectionFilters.Selected;
		} else {
			return TableSelectionFilters.Unselected;
		}
	}

	function getSelectionIcon(selected: string) {
		let icon: IconDefinition | undefined;
		dropdownItemProperties.forEach((item) => {
			if (item.value === removeQuotation(selected)) {
				icon = item.icon;
			}
		});

		return icon;
	}
}
