import { BenchmarkParticipantColumnIds } from 'features/Tables/hooks';
import { ApiBenchmarkGroup } from 'store/api';

export const translateBenchmarkGroup = (benchmark: string) => {
	switch (benchmark) {
		case BenchmarkParticipantColumnIds.Benchmark1:
			return ApiBenchmarkGroup.Benchmark1;
		case BenchmarkParticipantColumnIds.Benchmark2:
			return ApiBenchmarkGroup.Benchmark2;
		case BenchmarkParticipantColumnIds.Benchmark3:
			return ApiBenchmarkGroup.Benchmark3;
		case BenchmarkParticipantColumnIds.Benchmark4:
			return ApiBenchmarkGroup.Benchmark4;
		case BenchmarkParticipantColumnIds.Benchmark5:
			return ApiBenchmarkGroup.Benchmark5;
		case BenchmarkParticipantColumnIds.Benchmark6:
			return ApiBenchmarkGroup.Benchmark6;
		case BenchmarkParticipantColumnIds.Benchmark7:
			return ApiBenchmarkGroup.Benchmark7;
		case BenchmarkParticipantColumnIds.Benchmark8:
			return ApiBenchmarkGroup.Benchmark8;
		case BenchmarkParticipantColumnIds.Benchmark9:
			return ApiBenchmarkGroup.Benchmark9;
		case BenchmarkParticipantColumnIds.Benchmark10:
			return ApiBenchmarkGroup.Benchmark10;
	}
	throw Error();
};
