import {
	DropdownMenuIcon,
	UserMenuButton,
	UserAvatarIcon,
	UserMenuDropdown,
	UserMenuDropdownItem,
	UserMenuLink,
	UserMenuContainer,
	UsernameParagraph,
} from './styled';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useComponentVisible } from 'common/hooks';
import { getUserRole, resetStore, selectCurrentUser, useLogoutMutation } from 'store';
import { useSelector } from 'react-redux';
import { getFormattedUsername } from 'common/utils';
import { UserRoles } from 'common/types';

export const UserMenu = () => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible<HTMLDivElement>({
		initialIsVisible: false,
	});
	const dropdownMenuIcon = isComponentVisible ? faChevronUp : faChevronDown;
	const user = useSelector(selectCurrentUser);
	const username = user ? user.username : '';
	const [logout] = useLogoutMutation();
	const userRole = useSelector(getUserRole);
	const handleMenuClick = (): void => {
		setIsComponentVisible(!isComponentVisible);
	};

	const closeMenu = () => {
		setIsComponentVisible(false);
	};

	const logoutAndCloseMenu = async () => {
		resetStore();
		closeMenu();
		await logout();
	};

	return (
		<UserMenuContainer
			id="user-menu"
			ref={ref}
		>
			<UserMenuButton
				variant="text"
				onClick={handleMenuClick}
				startIcon={<UserAvatarIcon icon={faCircleUser} />}
				endIcon={<DropdownMenuIcon icon={dropdownMenuIcon} />}
			>
				<UsernameParagraph>{getFormattedUsername(username)}</UsernameParagraph>
			</UserMenuButton>
			<UserMenuDropdown $isHidden={!isComponentVisible}>
				{userCanLogout() && (
					<UserMenuDropdownItem>
						<UserMenuLink
							to="/login"
							onClick={logoutAndCloseMenu}
						>
							Log out
						</UserMenuLink>
					</UserMenuDropdownItem>
				)}
				{userCanChangePassword() && (
					<UserMenuDropdownItem>
						<UserMenuLink
							to="/passwordChange"
							onClick={closeMenu}
						>
							Change password
						</UserMenuLink>
					</UserMenuDropdownItem>
				)}
			</UserMenuDropdown>
		</UserMenuContainer>
	);

	function userCanChangePassword() {
		return userRole != UserRoles.SYSTEM_MANAGER;
	}

	function userCanLogout() {
		return !user?.authenticatedWithSSO;
	}
};
