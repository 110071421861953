import { PieChartDna } from 'store/slices/graphSlices';
import { PieChartDataSeries } from '../../PieChart.types';

export const prepareSingleDnaGraphData = (dna: PieChartDna, benchmarkIndexes: boolean[], clusterIndex: number) => {
	const pieData: PieChartDataSeries[] = [];

	benchmarkIndexes.forEach((benchmarkEnabled, index) => {
		const valueExists = dna.clusters[clusterIndex].benchmarks[index].value;
		if (benchmarkEnabled && valueExists) {
			pieData.push(dna.clusters[clusterIndex].benchmarks[index]);
		}
	});

	return pieData;
};
