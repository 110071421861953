import { rootApi } from 'store/api';
import {
	ApiCriteriaViewResponse,
	ApiHighlightViewResponse,
	ApiTotalViewResponse,
	DiscoveryInsightHighlightsRequest,
	DiscoveryInsightQuadrantRequest,
	DiscoveryInsightRequest,
	PercentageDistributionResponse,
} from 'store/api/discoveryInsightApi';

export const cultureViewApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getTotalView: builder.query<ApiTotalViewResponse, DiscoveryInsightQuadrantRequest>({
			query: (opts) => ({
				url: `api/discovery-insight/total-view`,
				method: 'POST',
				body: {
					benchmarkConfigurationId: opts.benchmarkConfigurationId.id,
					projectId: opts.projectId,
					quadrantCenter: opts.quadrantCenter,
					criteriaId: opts.criteriaId,
				},
			}),
		}),
		getCriteriaView: builder.query<ApiCriteriaViewResponse, DiscoveryInsightQuadrantRequest>({
			query: (opts) => ({
				url: `api/discovery-insight/criteria-view`,
				method: 'POST',
				body: {
					benchmarkConfigurationId: opts.benchmarkConfigurationId.id,
					projectId: opts.projectId,
					quadrantCenter: opts.quadrantCenter,
					criteriaId: opts.criteriaId,
				},
			}),
		}),
		getHighlightView: builder.query<ApiHighlightViewResponse, DiscoveryInsightHighlightsRequest>({
			query: (opts) => ({
				url: `api/discovery-insight/highlight`,
				method: 'POST',
				body: {
					benchmarkConfigurationId: opts.benchmarkConfigurationId.id,
					projectId: opts.projectId,
					quadrantCenter: opts.quadrantCenter,
					criteria: opts.criteria,
					totalThreshold: opts.totalThreshold,
				},
			}),
		}),
		getDistributionPercentages: builder.query<PercentageDistributionResponse, DiscoveryInsightRequest>({
			query: (opts) => ({
				url: `api/discovery-insight/distribution-percentages`,
				method: 'POST',
				body: {
					benchmarkConfigurationId: opts.benchmarkConfigurationId.id,
					projectId: opts.projectId,
					criteriaId: opts.criteriaId,
				},
			}),
		}),
	}),
});
export const {
	useLazyGetTotalViewQuery,
	useLazyGetHighlightViewQuery,
	useLazyGetCriteriaViewQuery,
	useLazyGetDistributionPercentagesQuery,
} = cultureViewApi;
