import { useEffect, useRef, useState } from 'react';
import { useNotifications } from './hooks/useNotifications';
import { NotificationPropsWithoutTimeout, NotificationPropsWithTimeout } from './Notification.types';
import { StyledNotification } from './styled';

export const Notification = ({
	id,
	type,
	message,
	disableAutoHide,
	timeout,
}: NotificationPropsWithoutTimeout | NotificationPropsWithTimeout) => {
	const { clearNotification, hideNotification } = useNotifications();
	const timer = useRef<NodeJS.Timeout>();
	const [isHidden, setIsHidden] = useState(false);

	useEffect(() => {
		if (!disableAutoHide) {
			const localTimer = setTimeout(() => {
				setIsHidden(true);
				hideNotification(id);
			}, timeout);

			if (!timer.current) {
				timer.current = localTimer;
			}

			return () => clearTimeout(timer.current);
		}
	}, [timer]);

	return (
		<StyledNotification
			$type={type}
			onClick={() => clearNotification(id)}
			hidden={isHidden}
			id="notification"
			data-notification-type={type}
			data-notification-uuid={id}
		>
			{message}
		</StyledNotification>
	);
};
