import { PageLayout, Table } from 'common/components';
import { useManagers } from 'common/hooks';
import { Manager } from 'common/types';
import { DeleteManagerConfirmDialog } from 'features/ApdManagers/components';
import { useManagerData } from 'features/Tables/hooks';
import { filterAndSortClientTableData } from 'features/Tables/utils';
import { useEffect, useState } from 'react';
import { CreateManagerButton } from './styled';

export const ApdManagersView = () => {
	const { columns, managerToDelete } = useManagerData(openDeleteDialog);
	const { managers, areManagersFetched } = useManagers();

	const [filteredManagers, setFilteredManagers] = useState<Manager[]>([]);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

	function closeDeleteDialog() {
		setIsDeleteDialogOpened(false);
	}

	function openDeleteDialog() {
		setIsDeleteDialogOpened(true);
	}

	useEffect(() => {
		setFilteredManagers(managers);
	}, [areManagersFetched, managers.length]);

	return (
		<PageLayout
			title="Alderbrooke configuration manager (APD MGR)"
			id="apd-managers"
		>
			{managerToDelete && (
				<DeleteManagerConfirmDialog
					isOpen={isDeleteDialogOpened}
					managerToDelete={managerToDelete}
					onClose={closeDeleteDialog}
				/>
			)}

			<CreateManagerButton
				href="/apdManagers/create"
				variant="outlined"
			>
				Create mgr
			</CreateManagerButton>

			<Table<Manager>
				tableTypeId="apd-managers"
				disableColumnSelector
				columns={columns}
				data={filteredManagers}
				onQueryChange={(queryParams) =>
					filterAndSortClientTableData(queryParams, managers, setFilteredManagers)
				}
			/>
		</PageLayout>
	);
};
