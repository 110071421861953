import { QuadrantGraphType, QuadrantLegendSortingType } from 'features/DiscoveryInsights/components';
import { QuadrantCenter } from 'features/DiscoveryInsights/components';
import { Criterion, ProjectId } from 'common/types';
import { QuadrantColorPresetId } from 'features/Graphs/QuadrantGraph';
import {
	CriteriaPerQuarter,
	PercentageDistributionResponse,
	PercentagePerQuarter,
} from 'store/api/discoveryInsightApi/discoveryInsightApi.types';
import { ApiQuadrantResult, BenchmarkConfigurationId } from 'store/api';

export type DiscoveryInsightResultsProps = {
	benchmarkConfigurationId: BenchmarkConfigurationId;
	projectId?: ProjectId;
	data: DiscoveryInsightResult;
	isOpened: boolean;
	onClose: () => void;
};

export const ViewableTypes = {
	QUADRANT_GRAPH: 0,
	DISTRIBUTION_TABLE: 1,
};

export type CLusterIndex = number;

export type ViewableType = typeof ViewableTypes[keyof typeof ViewableTypes];

export type DiscoveryInsightResult = DiscoveryQuadrantGraphData & {
	criteria: Criterion[];
	percentageDistribution: PercentageDistributionResponse;
};

export type TotalAndCriteriaViewData = {
	totalView: ApiQuadrantResult<PercentagePerQuarter>;
	criteriaView: ApiQuadrantResult<CriteriaPerQuarter>;
};

export type DiscoveryQuadrantGraphData = TotalAndCriteriaViewData & {
	highlights: ApiQuadrantResult<CriteriaPerQuarter>;
};

export type DiscoveryInsightData = DiscoveryQuadrantGraphData & {
	percentagesDistributionView: PercentageDistributionResponse;
};

export type DistributionPercentageCriteria = Criterion;

export const DistributionPercentageDnaTypes = {
	PDNA: 0,
	ODNA: 1,
};

export type DistributionPercentageDnaType =
	typeof DistributionPercentageDnaTypes[keyof typeof DistributionPercentageDnaTypes];

export type DiscoveryInsightSettings = {
	criteria: Criterion;
	quadrantType: QuadrantGraphType;
	quadrantCenter: QuadrantCenter;
	quadrantLegendSorting: QuadrantLegendSortingType;
	colorPresetId: QuadrantColorPresetId;
	totalThreshold: number;
};
