import {
	DistributionPercentageDnaType,
	DistributionPercentageDnaTypes,
} from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import Switch from 'react-switch';
import { StyledLeftSideLabel, StyledRightSideLabel, StyledToggle } from './styled';
import { theme } from 'common/constants';

export const DNATypeToggle = ({
	dnaType,
	setDnaType,
}: {
	dnaType: DistributionPercentageDnaType;
	setDnaType: (type: DistributionPercentageDnaType) => void;
}) => {
	const colors = theme.colors;
	const white = colors.common.white;
	const grey = colors.common.grey;
	const handleDiameter = 32;
	const height = 40;
	const width = 120;
	const borderRadius = 20;

	return (
		<StyledToggle>
			<Switch
				checked={isPdnaEnabled()}
				onChange={toggle}
				handleDiameter={handleDiameter}
				offColor={white}
				onColor={white}
				offHandleColor={grey}
				onHandleColor={grey}
				height={height}
				width={width}
				borderRadius={borderRadius}
				boxShadow={`0px 0px 1px 2px ${grey}`}
				activeBoxShadow={`0px 0px 2px 4px ${grey}`}
				uncheckedIcon={odnaLabel()}
				checkedIcon={pdnaLabel()}
				uncheckedHandleIcon={empty()}
				checkedHandleIcon={empty()}
			/>
		</StyledToggle>
	);

	function odnaLabel() {
		return <StyledLeftSideLabel>ODNA</StyledLeftSideLabel>;
	}

	function pdnaLabel() {
		return <StyledRightSideLabel>PDNA</StyledRightSideLabel>;
	}

	function empty() {
		return <div />;
	}

	function isPdnaEnabled() {
		return dnaType === DistributionPercentageDnaTypes.PDNA;
	}

	function toggle() {
		isPdnaEnabled()
			? setDnaType(DistributionPercentageDnaTypes.ODNA)
			: setDnaType(DistributionPercentageDnaTypes.PDNA);
	}
};
