export const labels = [
	'label-D01A',
	'label-D02A',
	'label-D03A',
	'label-D04A',
	'label-D05A',
	'label-D06A',
	'label-D07A',
	'label-D08A',
	'label-D09A',
	'label-D10A',
	'label-D11A',
	'label-D12A',
	'label-D13A',
	'label-D14A',
	'label-D15A',
	'label-D01B',
	'label-D02B',
	'label-D03B',
	'label-D04B',
	'label-D05B',
	'label-D06B',
	'label-D07B',
	'label-D08B',
	'label-D09B',
	'label-D10B',
	'label-D11B',
	'label-D12B',
	'label-D13B',
	'label-D14B',
	'label-D15B',
];
