import { rootApi } from 'store/api';
import {
	ApiBehaviourFocusRequest,
	ApiBehaviourFocusRequestPerProject,
	ApiBehaviourFocusResponse,
} from './behaviourFocusApi.types';

export const behaviourFocusApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getBehaviourFocusResultBenchmarksPerProject: builder.mutation<
			ApiBehaviourFocusResponse,
			ApiBehaviourFocusRequestPerProject
		>({
			query: (opts) => ({
				url: `api/behaviour-focus/configurations/${opts.benchmarkConfigurationId.id}/projects/${opts.projectId}`,
				method: 'POST',
				body: opts.dimensions,
			}),
		}),
		getBehaviourFocusResultBenchmarks: builder.mutation<ApiBehaviourFocusResponse, ApiBehaviourFocusRequest>({
			query: (opts) => ({
				url: `api/behaviour-focus/configurations/${opts.benchmarkConfigurationId.id}`,
				method: 'POST',
				body: opts.dimensions,
			}),
		}),
	}),
});

export const { useGetBehaviourFocusResultBenchmarksPerProjectMutation, useGetBehaviourFocusResultBenchmarksMutation } =
	behaviourFocusApi;
