import styled from 'styled-components';
import { Input } from 'common/components/Input';

export const StyledFileInput = styled.input`
	display: none;
`;

export const StyledInputStub = styled(Input)`
	display: flex;
	pointer-events: auto;
	caret-color: transparent;

	input {
		cursor: pointer;
	}
`;
