import { Navbar } from 'features/Navigation/views';

import { AnchoredMenuFooter, StyledSidebar } from './styled';
import { FooterLogo, HeaderLogo, LinkList, UserMenu } from 'features/Sidebar/components';

export const Sidebar = () => {
	return (
		<StyledSidebar id="main-menu">
			<HeaderLogo />
			<Navbar />
			<UserMenu />
			<AnchoredMenuFooter>
				<LinkList />
				<FooterLogo />
			</AnchoredMenuFooter>
		</StyledSidebar>
	);
};
