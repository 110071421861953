import { AssignmentParticipants } from 'common/types';
import { TableCheckbox } from 'common/components';
import { useEditParticipantAssignmentToProjectMutation } from 'store/api';
import { useParams } from 'react-router-dom';
import { useSuspenseManually } from 'common/hooks';

export type AssignParticipantToggleProps = {
	participant: AssignmentParticipants;
	onAssignationChange: () => void;
};

export const AssignParticipantToggle = ({ participant, onAssignationChange }: AssignParticipantToggleProps) => {
	const [toggle] = useEditParticipantAssignmentToProjectMutation();
	const suspense = useSuspenseManually();

	const { projectId } = useParams();
	const element = checkbox();
	return element;

	function toggleParticipant() {
		suspense.turnOn();
		const participantData = {
			assesseeId: participant.participantId,
			assign: !participant.assigned,
		};
		if (projectId) {
			toggle({ ...participantData, projectId }).then(() => {
				onAssignationChange();
			});
		}
	}

	function checkbox() {
		return (
			<TableCheckbox
				checked={participant.assigned}
				onChange={() => toggleParticipant()}
			/>
		);
	}
};
