import {
	TensionChartDataRepository,
	TensionGraphDataByCluster,
	createDataWrapper,
	BenchmarkId,
} from 'features/Graphs/TensionGraph';
import { ApiGraphResponseResults } from 'store/api';
import { ClusterId } from 'common/types';

export const useTensionChartDataRepository = function (data: ApiGraphResponseResults): TensionChartDataRepository {
	function findBy(benchmarkId: BenchmarkId, clusterId: ClusterId): TensionGraphDataByCluster {
		const cluster = createDataWrapper(data).findByCluster(clusterId);

		const pdna = cluster.findPdnaBenchmark(benchmarkId);
		const odna = cluster.findOdnaBenchmark(benchmarkId);

		const participants = { pdna: pdna.getParticipantAmount(), odna: odna.getParticipantAmount() };
		const questionnaireStatus = { pdnaPresent: pdna.hasData(), odnaPresent: odna.hasData() };

		const dimensionScores = cluster.findBothScoresWhereSelected(benchmarkId);

		return {
			participants,
			questionnaireStatus,
			dimensionScores,
		};
	}

	return {
		findBy: findBy,
	};
};
