interface RequiredValidationResult {
	isValid: boolean;
	errorMessage?: string;
}

class FileRequiredValidator {
	requireValidate(file: File | null): RequiredValidationResult {
		return file ? { isValid: true } : { isValid: false, errorMessage: 'File is required.' };
	}
}

export { FileRequiredValidator };
