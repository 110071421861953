import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const CreateParticipantButton = styled(Button)`
	padding-left: 2rem;
	padding-right: 2rem;
	margin-bottom: 1rem;
`;
