import { NotificationTypes, PageLayout, TabPanel, useNotifications } from 'common/components';
import { ActionPanel, ProjectSummaryPanel } from 'features/ResultsConfiguration/components';
import { useState } from 'react';
import { BenchmarkConfigurationViewProps } from './BenchmarkConfigurationLayout.types';
import { SelectedCounterText } from './styled';
import { ViewAdditionalQuestionsButton } from 'features/ResultsConfiguration/components';

export const BenchmarkConfigurationLayout = ({
	children,
	selectedParticipantsCount,
	benchmarkParticipantsCount,
	onSaveButtonClick,
	onViewButtonClick,
	closeDate,
	projectShortId,
	id,
}: BenchmarkConfigurationViewProps) => {
	const TabsItems = {
		Participants: 0,
		Benchmarks: 1,
	};

	const { pushNotification } = useNotifications();
	const [selectedTab, setSelectedTab] = useState(TabsItems.Participants);
	const handleTabChange = (tabIndex: number): void => {
		if (!benchmarkParticipantsCount && tabIndex === TabsItems.Benchmarks) {
			pushNotification({
				message:
					'No participants are available to benchmark, assign participants and save to view the benchmark screen',
				type: NotificationTypes.Info,
			});

			return;
		}
		setSelectedTab(tabIndex);
	};

	const getTabPanels = () => {
		return children.map((tabPanel, index) => (
			<TabPanel
				key={index}
				index={index}
				currentSelectedTabIndex={selectedTab}
			>
				{tabPanel}
			</TabPanel>
		));
	};

	return (
		<PageLayout
			title="Benchmark Comparison"
			subtitle="Select and assign participants to a comparison scenario"
			RightSideComponent={
				selectedTab === TabsItems.Participants ? (
					<SelectedCounterText>{`${selectedParticipantsCount} participants selected`}</SelectedCounterText>
				) : (
					<SelectedCounterText>{`${benchmarkParticipantsCount} participants selected`}</SelectedCounterText>
				)
			}
			id={id}
		>
			<ActionPanel
				currentSelectedTab={selectedTab}
				onSaveButtonClick={onSaveButtonClick}
				onViewButtonClick={onViewButtonClick}
				onTabChange={handleTabChange}
				tabs={[{ label: 'Participants' }, { label: 'Benchmarks', faded: !benchmarkParticipantsCount }]}
			>
				{projectShortId && (
					<ProjectSummaryPanel
						projectShortId={projectShortId}
						closeDate={closeDate}
					/>
				)}
				{selectedTab === TabsItems.Benchmarks && <ViewAdditionalQuestionsButton />}
			</ActionPanel>
			{getTabPanels()}
		</PageLayout>
	);
};
