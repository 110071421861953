import { ApiAllTranslationsResponse } from 'store/api';
import {
	DimensionTranslation,
	DimensionTranslationMap,
	DimensionTranslator,
	TranslatedText,
	TranslationId,
} from './useTranslation.types';

export const useTranslation = function (data: ApiAllTranslationsResponse) {
	const translations: DimensionTranslationMap = createTranslations(data);

	return { create };

	function translateDimension(name: TranslationId): DimensionTranslation {
		const primary = translations.get(name) || 'error';
		const opposingName = getOpposingDimensionName(name);
		const contrary = translations.get(opposingName) || 'error';

		return { primary, contrary };
	}

	function getOpposingDimensionName(name: TranslationId) {
		if (name.endsWith('A')) {
			return name.replace('A', 'B');
		} else {
			return name.replace('B', 'A');
		}
	}

	function create(): DimensionTranslator {
		return {
			translateDimension(id: TranslationId): DimensionTranslation {
				return translateDimension(id);
			},
		};
	}

	function createTranslations(translationsData: ApiAllTranslationsResponse): DimensionTranslationMap {
		const translationsMap = new Map<TranslationId, TranslatedText>();
		const key = 0;
		const value = 1;
		Object.entries(translationsData.translations).forEach((entry) => {
			translationsMap.set(entry[key], entry[value]);
		});
		return translationsMap;
	}
};
