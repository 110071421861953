export function quote(text: string) {
	return `"${text}"`;
}

export function removeQuotation(text: string) {
	const findQuotesRegex = /"/g;
	return text.replace(findQuotesRegex, '');
}

export function isQuoted(text: string) {
	return text.startsWith('"') && text.endsWith('"');
}
