import { ApiProject, Project, ApiProjectSummery } from 'common/types';
import { ApiAssignmentStatus } from 'common/types';
import { AllProjectsResponse, AssignmentParticipantsResponse } from 'store/api';
import { AssignmentStatusResponse } from 'store/api';
import { ApiAssignmentParticipants } from 'common/types';
import { ProjectSummeryStatusResponse } from './projectApi.types';

export const getAllProjectsEmptyResponse = (): AllProjectsResponse<Project & ApiProject> => {
	return { projects: [] };
};

export const getProjectSummaryEmptyResponse = (): ProjectSummeryStatusResponse<
	ProjectSummeryStatusResponse<ApiProjectSummery>
> => {
	return {
		odna: {
			finishedQuestionnaires: 0,
			notFinishedQuestionnaires: 0,
			percentageFinished: 0,
			totalQuestionnaires: 0,
		},
		pdna: {
			finishedQuestionnaires: 0,
			notFinishedQuestionnaires: 0,
			percentageFinished: 0,
			totalQuestionnaires: 0,
		},
		projectType: '',
		shortId: '',
	};
};

export const getAssignmentStatusEmptyResponse = (): AssignmentStatusResponse<ApiAssignmentStatus> => {
	return { totalPages: 0, pageNumber: 0, assignmentStatuses: [] };
};

export const getAssignmentParticipantsEmptyResponse = (): AssignmentParticipantsResponse<ApiAssignmentParticipants> => {
	return { totalPages: 0, pageNumber: 0, content: [], totalAssigned: 0 };
};

export const convertRecordToFilterQueryParameters = (filters?: { [key: string]: string | undefined }[]) => {
	if (!filters) {
		return {};
	}
	const filteredFilters = filters.filter((filter) => filter !== undefined);
	const filterMap: Record<string, string> = {};
	filteredFilters.forEach((filter) => {
		Object.keys(filter).forEach((key) => {
			const value = filter[key];
			if (value !== undefined) {
				filterMap[key] = value;
			}
		});
	});
	return filterMap;
};

export const convertObjectToQueryParams = (paramsObj: Record<string, unknown>) =>
	Object.entries(paramsObj)
		.map(([key, value]) => {
			if (value === undefined) return;

			if (Array.isArray(value)) {
				const arrayKey = key;

				return value
					.map((arrayItem) => {
						const arrayItemKey = Object.keys(arrayItem);
						const arrayItemValue = Object.values(arrayItem);
						return `${arrayKey}${encodeURI(`[${arrayItemKey}]`)}=${encodeURI(String(arrayItemValue))}`;
					})
					.join('&');
			}

			return `${encodeURI(key)}=${encodeURI(String(value))}`;
		})
		.join('&');
