import styled from 'styled-components';
import { Button } from 'common/components';

export const Container = styled('div')`
	padding: 15px;
`;

export const CloseButton = styled(Button)`
	margin-right: 1rem;
	height: 6vh;
	width: 10vw;
	margin-top: auto;
`;

export const StyledTitle = styled('div')`
	text-transform: uppercase;
	font-size: 4rem;
	border-bottom: 2px solid ${({ theme }) => theme.colors.primary.main};
	padding-left: 1rem;
	line-height: 5rem;
`;
