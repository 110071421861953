import { DropdownButton, DropdownContainer, DropdownContent, DropdownSelectedLabel } from './styled';
import { useComponentVisible, usePrevious } from 'common/hooks';
import { DropdownProps } from './Dropdown.types';
import { DropdownItemLabel } from './DropdownItemLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

export const Dropdown = ({
	value,
	children,
	onChange,
	onDropdownClose,
	multiple,
	customLabel,
	labelIcon,
	disabled = false,
	fullContentWidth = true,
	color = 'primary',
	buttonProps,
	className,
	disableChevron,
}: DropdownProps) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible<HTMLDivElement>({
		initialIsVisible: false,
	});
	const prevIsComponentVisible = usePrevious<boolean>(isComponentVisible);

	useEffect(() => {
		if (prevIsComponentVisible && onDropdownClose) {
			onDropdownClose();
		}
	}, [isComponentVisible]);

	const handleContentToggle = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	const handleValueChange = (newValue: string) => {
		if (!multiple) {
			onChange(newValue);
			return;
		}

		const isValueAlreadySelected = value.some((chosenItem) => chosenItem === newValue);
		if (isValueAlreadySelected) {
			const newValuesArray: string[] = [...value];
			const newValueIndex = newValuesArray.indexOf(newValue);
			const deleteCount = 1;
			newValuesArray.splice(newValueIndex, deleteCount);
			onChange(newValuesArray);
			return;
		}

		const newValuesArray: string[] = [];
		newValuesArray.push(...value, newValue);
		onChange(newValuesArray);
		return;
	};

	const shouldDisplayChosenIcon = (dropdownItemValue: string): boolean => {
		if (!multiple) return false;

		const isValueSelected = value.some((chosenValue) => chosenValue === dropdownItemValue);
		if (!isValueSelected) {
			return false;
		}

		return true;
	};

	const displayButtonIcon = () => {
		if (labelIcon) {
			return <FontAwesomeIcon icon={labelIcon} />;
		}
	};

	const displayButtonText = () => {
		if (customLabel) {
			return customLabel;
		}
		if (multiple) {
			return value.join(',');
		}
		return value;
	};

	const getValueToDisplay = () => {
		return displayButtonIcon() ? displayButtonIcon() : displayButtonText();
	};

	const dropdownClosedIcon = <FontAwesomeIcon icon={faChevronDown} />;
	const dropdownToggledIcon = <FontAwesomeIcon icon={faChevronUp} />;

	const DropdownItems = children.map((item) => (
		<DropdownItemLabel
			isChosen={shouldDisplayChosenIcon(item.props.value)}
			key={item.props.value}
			onClick={() => handleValueChange(item.props.value)}
		>
			{item.props.children}
		</DropdownItemLabel>
	));
	const endIcon = isComponentVisible ? dropdownToggledIcon : dropdownClosedIcon;
	return (
		<DropdownContainer
			ref={ref}
			className={className}
			$color={color}
		>
			<DropdownButton
				{...buttonProps}
				disabled={disabled}
				onClick={handleContentToggle}
				endIcon={!disabled && !disableChevron && endIcon}
			>
				<DropdownSelectedLabel>{getValueToDisplay()}</DropdownSelectedLabel>
			</DropdownButton>
			{isComponentVisible && (
				<DropdownContent
					$fullContentWidth={fullContentWidth}
					onClick={!multiple ? handleContentToggle : undefined}
				>
					{DropdownItems}
				</DropdownContent>
			)}
		</DropdownContainer>
	);
};
