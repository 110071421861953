import { ApiAdditionalQuestion } from 'common/types';
import { rootApi } from 'store/api';
import { GetAdditionalProjectQuestionsOptions, GetAdditionalProjectQuestionsResponse } from '.';

export const questionsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAdditionalProjectQuestions: builder.query<
			GetAdditionalProjectQuestionsResponse<ApiAdditionalQuestion>,
			GetAdditionalProjectQuestionsOptions
		>({
			query: (data) => ({
				url: `/api/projectquestions/${data.projectId}`,
				method: 'GET',
			}),
		}),
	}),
});

export const { useGetAdditionalProjectQuestionsQuery } = questionsApi;
