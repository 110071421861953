import { InputFile } from 'common/components';
import { FormInputFileProps } from './FormInputFile.types';

export const FormInputFile = ({ title, error, errorText, required, fileInputProps }: FormInputFileProps) => {
	return (
		<InputFile
			name={title}
			error={error}
			errorText={errorText}
			required={required}
			formInput
			{...fileInputProps}
		/>
	);
};
