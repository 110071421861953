import { HeatmapGraphColorConfig } from '../HeatmapGraph';

const heatmapGraphPresetColors = {
	black: '#000000',
	white: '#FFFFFF',
	red: '#FF0000',
	amber: '#FFBF00',
	antiFlashWhite: '#F0F0F0',
	mediumSpringBud: '#C3D69A',
	palmLeaf: '#75923B',
	cetaceanBlue: '#090230',
	blueMagentaViolet: '#4D3293',
	skyMagenta: '#D773B8',
	maximumRedPurple: '#A72F83',
	darkLava: '#4A4230',
	brown: '#A24900',
	spanishGray: '#999999',
	blueJeans: '#56B4E9',
	honoluluBlue: '#0972B2',
};

const heatmapGraphPreset1: HeatmapGraphColorConfig = {
	heatmapMainTableAndScaleColors: {
		color1: {
			backgroundColor: heatmapGraphPresetColors.red,
			textColor: heatmapGraphPresetColors.black,
		},
		color2: {
			backgroundColor: heatmapGraphPresetColors.amber,
			textColor: heatmapGraphPresetColors.black,
		},
		color3: {
			backgroundColor: heatmapGraphPresetColors.antiFlashWhite,
			textColor: heatmapGraphPresetColors.black,
		},
		color4: {
			backgroundColor: heatmapGraphPresetColors.mediumSpringBud,
			textColor: heatmapGraphPresetColors.black,
		},
		color5: {
			backgroundColor: heatmapGraphPresetColors.palmLeaf,
			textColor: heatmapGraphPresetColors.black,
		},
	},
};

const heatmapGraphPreset2 = {
	heatmapMainTableAndScaleColors: {
		color1: {
			backgroundColor: heatmapGraphPresetColors.cetaceanBlue,
			textColor: heatmapGraphPresetColors.white,
		},
		color2: {
			backgroundColor: heatmapGraphPresetColors.blueMagentaViolet,
			textColor: heatmapGraphPresetColors.white,
		},
		color3: {
			backgroundColor: heatmapGraphPresetColors.antiFlashWhite,
			textColor: heatmapGraphPresetColors.black,
		},
		color4: {
			backgroundColor: heatmapGraphPresetColors.skyMagenta,
			textColor: heatmapGraphPresetColors.white,
		},
		color5: {
			backgroundColor: heatmapGraphPresetColors.maximumRedPurple,
			textColor: heatmapGraphPresetColors.white,
		},
	},
};

const heatmapGraphPreset3 = {
	heatmapMainTableAndScaleColors: {
		color1: {
			backgroundColor: heatmapGraphPresetColors.darkLava,
			textColor: heatmapGraphPresetColors.white,
		},
		color2: {
			backgroundColor: heatmapGraphPresetColors.brown,
			textColor: heatmapGraphPresetColors.white,
		},
		color3: {
			backgroundColor: heatmapGraphPresetColors.spanishGray,
			textColor: heatmapGraphPresetColors.white,
		},
		color4: {
			backgroundColor: heatmapGraphPresetColors.blueJeans,
			textColor: heatmapGraphPresetColors.white,
		},
		color5: {
			backgroundColor: heatmapGraphPresetColors.honoluluBlue,
			textColor: heatmapGraphPresetColors.white,
		},
	},
};

export const HeatmapGraphColorPresets = [heatmapGraphPreset1, heatmapGraphPreset2, heatmapGraphPreset3];
