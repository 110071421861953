import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ProjectDetailsState } from '..';

const initialState: ProjectDetailsState = {
	projectId: '',
	assessmentType: '',
	description: '',
	costCentreReference: undefined,
};

export const projectDetailsSlice = createSlice({
	name: 'projectDetails',
	initialState,
	reducers: {
		setProjectDetailsState: (state, { payload }: PayloadAction<ProjectDetailsState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setProjectDetailsState } = projectDetailsSlice.actions;

export const projectDetailsSliceReducer = projectDetailsSlice.reducer;

export const selectProjectDetailsState = (state: RootState) => {
	return state.createProjectReducers.projectDetails;
};
