export const RoadMapGraphLayout = {
	name: 'klay',
	animate: false,
	avoidOverlap: true,
	nodeDimensionsIncludeLabels: true,
	klay: {
		direction: 'RIGHT',
		addUnnecessaryBendpoints: true,
		fixedAlignment: 'BALANCED',
		thoroughness: 20,
		nodePlacement: 'LINEAR_SEGMENTS',
		spacing: 40,
	},
};
