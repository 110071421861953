import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { BehaviourFocusBenchmarkNamesKeys, BehaviourFocusState } from './behaviourFocusSlice.types';

const initialState: BehaviourFocusState = {
	benchmarksNames: {
		benchmark1: '',
		benchmark2: '',
		benchmark3: '',
		benchmark4: '',
		benchmark5: '',
		benchmark6: '',
		benchmark7: '',
		benchmark8: '',
		benchmark9: '',
		benchmark10: '',
	},
};

export const behaviourFocusSlice = createSlice({
	name: 'behaviourFocus',
	initialState,
	reducers: {
		setBehaviourFocusState: (state, { payload }: PayloadAction<BehaviourFocusState>) => {
			state = payload;
			return state;
		},
		resetBehaviourFocusState: () => initialState,
		setBehaviourFocusBenchmarkName: (
			state,
			{ payload }: PayloadAction<{ benchmarkKey: BehaviourFocusBenchmarkNamesKeys; value: string }>,
		) => {
			const { benchmarkKey, value } = payload;
			state.benchmarksNames[benchmarkKey] = value;
			return state;
		},
		setBehaviourFocusBenchmarksNames: (
			state,
			{ payload }: PayloadAction<Record<BehaviourFocusBenchmarkNamesKeys, string>>,
		) => {
			state.benchmarksNames = payload;
			return state;
		},
	},
});

export const {
	setBehaviourFocusState,
	resetBehaviourFocusState,
	setBehaviourFocusBenchmarkName,
	setBehaviourFocusBenchmarksNames,
} = behaviourFocusSlice.actions;
export const behaviourFocusSliceReducer = behaviourFocusSlice.reducer;

export const selectBehaviourFocusState = (state: RootState) => state.behaviourFocus;
