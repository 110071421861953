import { UserRoles } from 'common/types';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/slices';

export const getUserHomeRoute = (): string => {
	const userRole = useSelector(getUserRole);

	switch (userRole) {
		case UserRoles.ADMIN:
			return '/projects';
		case UserRoles.MANAGER:
			return '/tenants';
		case UserRoles.SYSTEM_MANAGER:
			return '/apdManagers';
		default:
			return '';
	}
};

export const getFormattedUsername = (username: string): string => {
	const maxLengthWithoutSplitting = 30;
	const partBeforeAtSign = 0;
	const partAfterAtSign = 1;

	if (username && username.length > maxLengthWithoutSplitting) {
		const splitUsername = username.split('@');
		return splitUsername[partBeforeAtSign] + '@\n' + splitUsername[partAfterAtSign];
	}

	return username;
};
