import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, setUser, useGetLoggedInUserQuery } from 'store';

export const useAuth = () => {
	const loggedInUser = useGetLoggedInUserQuery();
	const user = useSelector(selectCurrentUser);
	const dispatch = useDispatch();

	if (authenticatedUserIsDifferentFromCurrentOne()) {
		updateLoggedUserInfo();
	}

	useEffect(() => {
		if (!user) {
			updateLoggedUserInfo();
		}
	}, [loggedInUser.isSuccess]);

	return useMemo(() => ({ user }), [user]);

	function authenticatedUserIsDifferentFromCurrentOne() {
		return loggedInUser.isSuccess && loggedInUser.data?.username !== user?.username;
	}

	function updateLoggedUserInfo() {
		if (loggedInUser.isSuccess) {
			dispatch(setUser(loggedInUser.data));
		}
	}
};
