export const ChartTypes = {
	Radar: 'radar',
	DataDistribution: 'dataDistribution',
	Heatmap: 'heatmap',
	Indicator: 'indicator',
	Quadrant: 'quadrant',
	ResponseQuality: 'responseQuality',
} as const;

export const GraphLineTypes = {
	Solid: 'SOLID',
	Dashed: 'DASHED',
} as const;

export const ChartColors = {
	SlimyGreen: '2A9900',
	Olivine: '9EAD77',
	SeaSerpent: '47BCCD',
	Ochre: 'C5751D',
	MorningBlue: '8D9A9B',
	DarkCharcoal: '303030',
	ChineseGold: 'D09E00',
	BlueViolet: '821AD8',
	DarkBlue: '0045D0',
	KombuGreen: '2F4420',
	DeepLilac: '9B58B5',
	CarrotOrange: 'EC5D30',
	DingyDungeon: 'BB374D',
	MiddleBluePurple: '8479B3',
	PersianPlum: '6D1118',
	LightCoral: 'EC848B',
	MexicanPink: 'D6007D',
	ViridianGreen: '009B91',
} as const;

export type GraphLineType = typeof GraphLineTypes[keyof typeof GraphLineTypes];
