import { CriterionId } from 'common/types';
import { QuadrantLegendSortingType, QuadrantLegendSortingTypes } from 'features/DiscoveryInsights/components';
import { DisappearingElement, StyledDropdown } from 'features/DiscoveryInsights/components';
import { DropdownItemWithIcon } from 'common/components';
import { StyledLegendColumn } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantLegendSortingDropdown/styled';

export const QuadrantLegendSortingDropdown = ({
	sorting,
	visible,
	setValue,
}: {
	sorting: QuadrantLegendSortingType;
	visible: boolean;
	setValue: (type: QuadrantLegendSortingType) => void;
}) => {
	const items = [
		{ id: QuadrantLegendSortingTypes.PERCENTAGE_ASCENDING, value: 'by percentage ascending' },
		{ id: QuadrantLegendSortingTypes.PERCENTAGE_DESCENDING, value: 'by percentage descending' },
		{ id: QuadrantLegendSortingTypes.NUMBER_ASCENDING, value: 'by number ascending' },
		{ id: QuadrantLegendSortingTypes.NUMBER_DESCENDING, value: 'by number descending' },
	];

	const dropdownItems = items.map((item) => createDropdownItem(item.id, item.value));

	const first = 0;
	const findById = (id: number) => items.find((item) => item.id === id) || items[first];
	const selected = findById(sorting);
	return (
		<StyledLegendColumn>
			<DisappearingElement $appear={visible}>
				<div>Quadrant legend sorting:</div>
				<StyledDropdown
					value={selected.value}
					onChange={(entry) => {
						setValue(Number(entry));
					}}
				>
					{dropdownItems}
				</StyledDropdown>
			</DisappearingElement>
		</StyledLegendColumn>
	);

	function createDropdownItem(id: CriterionId | number, value: string) {
		return (
			<DropdownItemWithIcon
				key={id}
				value={id.toString()}
			>
				{value}
			</DropdownItemWithIcon>
		);
	}
};
