import { SyntheticEvent } from 'react';

export const isKeyDownEventType = (event: SyntheticEvent): event is React.KeyboardEvent => {
	return (
		typeof event === 'object' &&
		event !== null &&
		'type' in event &&
		'key' in event &&
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		event.type === 'keydown'
	);
};
