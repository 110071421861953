import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { radarGraphConfig } from 'features/Graphs/RadarGraph/RadarGraphConfig';
import { RootState } from 'store/store';
import { RadarGraphCluster, RadarGraphState } from './radarSlice.types';
import { RadarGraphConfig } from 'features/Graphs';
import { Dna } from 'features/Graphs/RadarGraph/hoc/utils';

const emptyDna: Dna = {
	clusters: [
		{
			benchmarks: [
				{
					categoryLabels: [],
					data: [],
					title: '',
				},
			],
		},
	],
};

const initialState: RadarGraphState = {
	chartConfig: radarGraphConfig,
	ODNA: emptyDna,
	PDNA: emptyDna,
	COMBINED_ODNA: emptyDna,
	COMBINED_PDNA: emptyDna,
	OPDNA: {
		title: '',
	},
};

export const radarGraphSlice = createSlice({
	name: 'radarGraph',
	initialState,
	reducers: {
		setRadarState: (state, { payload }: PayloadAction<RadarGraphState>) => {
			state = payload;
			return state;
		},
		setRadarConfig: (state, { payload }: PayloadAction<RadarGraphConfig>) => {
			state.chartConfig = payload;
			return state;
		},
		setRadarODNA: (state, { payload }: PayloadAction<RadarGraphCluster[]>) => {
			state.ODNA.clusters = payload;
			return state;
		},
		setRadarPDNA: (state, { payload }: PayloadAction<RadarGraphCluster[]>) => {
			state.PDNA.clusters = payload;
			return state;
		},
		setRadarCombinedPDNA: (state, { payload }: PayloadAction<RadarGraphCluster[]>) => {
			state.COMBINED_PDNA.clusters = payload;
			return state;
		},
		setRadarCombinedODNA: (state, { payload }: PayloadAction<RadarGraphCluster[]>) => {
			state.COMBINED_ODNA.clusters = payload;
			return state;
		},
		setRadarOPDNA: (state, { payload }: PayloadAction<string>) => {
			state.OPDNA.title = payload;
			return state;
		},
		resetRadarState: () => initialState,
	},
});

export const {
	setRadarState,
	resetRadarState,
	setRadarConfig,
	setRadarODNA,
	setRadarPDNA,
	setRadarOPDNA,
	setRadarCombinedPDNA,
	setRadarCombinedODNA,
} = radarGraphSlice.actions;
export const radarGraphReducer = radarGraphSlice.reducer;
export const selectRadarODNA = (state: RootState) => state.radarGraph.ODNA;
export const selectRadarPDNA = (state: RootState) => state.radarGraph.PDNA;
export const selectRadarOPDNA = (state: RootState) => state.radarGraph.OPDNA;
export const selectRadarCombinedPDNA = (state: RootState) => state.radarGraph.COMBINED_PDNA;
export const selectRadarCombinedODNA = (state: RootState) => state.radarGraph.COMBINED_ODNA;
export const selectRadarConfig = (state: RootState) => state.radarGraph.chartConfig;
