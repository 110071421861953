import { Button } from 'common/components/Button';
import { PageLayout } from 'common/components/PageLayout';
import { HeaderContainer } from 'common/components/PageLayout/styled';
import styled from 'styled-components';

export const StyledPageLayout = styled(PageLayout)`
	& > ${HeaderContainer} {
		margin-bottom: 0.5rem;
	}
`;

export const SaveChangesButton = styled(Button)`
	font-size: 1.1rem;
	padding: 0.25rem 1.5rem;
`;

export const ConfigurationContent = styled.div`
	display: flex;
	margin: 0.5rem 0;

	& > :nth-child(1) {
		padding-right: 1.5vw;
	}

	& > :nth-child(2) {
		padding-left: 1.5vw;
	}
`;

export const ClusterSelectionPanelContainer = styled.div`
	margin-top: auto;
	margin-bottom: 1rem;

	& > * {
		margin: 1rem 0;
	}
`;
