import { BenchmarkConfigurationId } from 'store/api';
import { useLazyGetPredictiveGraphDataFromProjectQuery } from 'store/api/predictiveApi/predictiveApi';
import { vetoIfBadRequest } from 'common/hooks/predictiveView/mappers';
import { ProjectId } from 'common/types';

export const usePredictiveViewResultsFromProject = () => {
	const [fetchResult] = useLazyGetPredictiveGraphDataFromProjectQuery();

	return async (benchmarkConfigurationId: BenchmarkConfigurationId, projectId: ProjectId) => {
		const response = await fetchResult({ benchmarkConfigurationId, projectId });

		vetoIfBadRequest(response.data);

		return response.data;
	};
};
