import { QuadrantChartProps } from 'features/Graphs/QuadrantGraph';
import { GraphContainer } from 'features/Graphs/common/components/GraphContainer';
import { useQuadrantGraphCreator } from './hooks';

export const QuadrantChart = ({ containerId, config, center, data, dotColorConfig }: QuadrantChartProps) => {
	const buildGraph = useQuadrantGraphCreator(config, center, data, dotColorConfig);
	return (
		<GraphContainer
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
		/>
	);
};
