import { NavbarContainer } from './styled';
import { AdminNavbar } from './RoleSpecificNavbars/AdminNavbar';
import { ManagerNavbar } from './RoleSpecificNavbars/ManagerNavbar';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/slices';
import { UserRoles } from 'common/types';
import { SystemManagerNavbar } from './RoleSpecificNavbars/SystemManagerNavbar';

export const Navbar = () => {
	const userRole = useSelector(getUserRole);

	const getNavbarForUser = () => {
		switch (userRole) {
			case UserRoles.ADMIN:
				return <AdminNavbar />;
			case UserRoles.MANAGER:
				return <ManagerNavbar />;
			case UserRoles.SYSTEM_MANAGER:
				return <SystemManagerNavbar />;
		}
	};

	return <NavbarContainer id="menu">{getNavbarForUser()}</NavbarContainer>;
};
