import { NotificationTypes, Table, useNotifications } from 'common/components';
import { Criterion, Participant, ParticipantId, TableQueryParams } from 'common/types';
import { convertParticipantsSelection } from 'common/utils';
import { useParams } from 'react-router-dom';
import { ColumnFilter } from '@tanstack/react-table';
import {
	AtLeastOneCriteriaShouldBeEnabled,
	CriteriaHasInvalidFormat,
	useCriteriaConfiguration,
	useDiscoveryInsightsCriteriaValidator,
	useParticipants,
	useProjectDetails,
} from 'common/hooks';
import { useParticipantsData } from 'features/Tables/hooks';
import { useState } from 'react';
import { BenchmarkConfigurationId } from 'store/api';
import { useCreateBenchmarkConfigurationMutation } from 'store/api';
import { CriteriaSelectionPanel } from 'features/ResultsConfiguration/components';
import { CriteriaConfigurationLayout } from 'features/ResultsConfiguration/views/CriteriaConfigurationLayout';
import { CriterionState } from 'features/ResultsConfiguration/components';
import { DiscoveryInsightResults } from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import { DiscoveryInsightResult } from 'features/DiscoveryInsights/views/DiscoveryInsightResults/DiscoveryInsightResults.types';
import { useDiscoveryInsightData } from 'features/DiscoveryInsights/hooks';

export const DiscoveryInsights = () => {
	const { projectId } = useParams();
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);

	const { getParticipants, participants } = useParticipants({ projectId });
	const { projectDetails } = useProjectDetails({ projectId });

	const { columns, visibleColumns } = useParticipantsData(projectId);

	const userCriteria = useCriteriaConfiguration();
	const initialCriteria = getDefaultCriteriaState(userCriteria);
	const [criteria, setCriteria] = useState<CriterionState[]>(initialCriteria);

	const [benchmarkConfigurationId, setBenchmarkConfigurationId] = useState<BenchmarkConfigurationId>();
	const fetchData = useDiscoveryInsightData();
	const { pushNotification } = useNotifications();
	const { validate } = useDiscoveryInsightsCriteriaValidator();
	const [saveParticipantsForBenchmark] = useCreateBenchmarkConfigurationMutation();

	const onViewButtonClick = async () => {
		const valid = tryToValidateCriteria();
		if (!valid) {
			return;
		}
		if (!benchmarkConfigurationId) {
			return;
		}

		const first = 0;
		const quadrantCenter = { x: 2.5, y: 2.5 };
		const transformToRequestCriteria = (criteria: CriterionState) => Number(criteria.id);
		const enabledCriteria = getEnabledCriteria();
		const selectedCriteria = transformToRequestCriteria(enabledCriteria[first]);
		const totalThreshold = 5;
		fetchData(
			benchmarkConfigurationId,
			selectedCriteria,
			enabledCriteria.map(transformToRequestCriteria),
			quadrantCenter,
			totalThreshold,
			projectId,
		).then((r) => {
			const totalView = r.totalView;
			const criteriaView = r.criteriaView;
			const highlights = r.highlights;
			const percentageDistribution = r.percentagesDistributionView;
			setResult({ criteria: enabledCriteria, criteriaView, totalView, highlights, percentageDistribution });
			setIsResultViewOpened(true);
		});
	};
	const [result, setResult] = useState<DiscoveryInsightResult>();

	const [isResultViewOpened, setIsResultViewOpened] = useState(false);

	const closeResultView = () => {
		setIsResultViewOpened(false);
		setResult(undefined);
	};

	return (
		<>
			{result && benchmarkConfigurationId && (
				<DiscoveryInsightResults
					benchmarkConfigurationId={benchmarkConfigurationId}
					projectId={projectId}
					onClose={closeResultView}
					isOpened={isResultViewOpened}
					data={result}
				/>
			)}

			<CriteriaConfigurationLayout
				selectedParticipantsCount={selectedParticipantIds.length}
				shouldEnableCriteriaTab={!!benchmarkConfigurationId}
				onSaveButtonClick={onSaveButtonClick}
				onViewButtonClick={onViewButtonClick}
				criteria={criteria}
				onCriteriaChange={setCriteria}
				closeDate={projectDetails?.closeDate}
				projectShortId={projectDetails?.shortId}
				id="discover-insights"
			>
				{ParticipantsTabPanel()}
				{CriteriaSelectionTabPanel()}
			</CriteriaConfigurationLayout>
		</>
	);

	function getDefaultCriteriaState(userCriteria: Criterion[]): CriterionState[] {
		const onlyThoseEnabledByUser = (criterion: Criterion) => criterion.enabled;
		const criteria = userCriteria || [];
		const createCriteriaState = (criterion: Criterion) => ({
			...criterion,
			enabled: false,
			defaultName: criterion.name,
		});
		return criteria.filter(onlyThoseEnabledByUser).map(createCriteriaState);
	}

	function ParticipantsTabPanel() {
		const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
			await getParticipants(createRequest(queryParams));
		};

		return (
			<>
				<Table<Participant>
					tableTypeId="discover-insights-participants"
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={participants.pageNumber}
					pageCount={participants.totalPages}
					data={participants.content}
					onQueryChange={onQueryChange}
					onRowSelection={updateSelection}
				/>
			</>
		);
	}

	function CriteriaSelectionTabPanel() {
		return (
			<CriteriaSelectionPanel
				criteria={criteria}
				onCriteriaChange={setCriteria}
			/>
		);
	}

	function updateSelection(selection: Record<string, boolean>) {
		const selectedIds = convertParticipantsSelection(selection);
		setSelectedParticipantIds(selectedIds);
	}

	function createFilters(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function participantsWereNotSelected() {
		return !selectedParticipantIds.length;
	}

	async function onSaveButtonClick() {
		setBenchmarkConfigurationId(undefined);
		if (participantsWereNotSelected()) {
			notifyAboutParticipantsNotAssigned();
			return;
		}
		saveParticipantsForBenchmark(selectedParticipantIds)
			.unwrap()
			.then((benchmarkId) => {
				setBenchmarkConfigurationId(benchmarkId);
				notifyAboutSuccessfulSave();
			});
	}

	function notifyAboutParticipantsNotAssigned() {
		pushNotification({
			message: 'Please assign at least one participant to view the results.',
			type: NotificationTypes.Error,
		});
	}

	function notifyAboutSuccessfulSave() {
		pushNotification({
			message: 'Selected participants have been assigned successfully to Discover Insights',
			type: NotificationTypes.Success,
		});
	}

	function notifyAboutNotEnabledCriteria() {
		pushNotification({
			message: 'Select at least one criteria to view results.',
			type: NotificationTypes.Error,
		});
	}

	function notifyAboutCriteriaTooLong() {
		pushNotification({
			message: 'Criteria name cannot be longer than 30 characters.',
			type: NotificationTypes.Error,
		});
	}

	function tryToValidateCriteria(): boolean {
		try {
			validate(criteria);
		} catch (error) {
			if (error instanceof CriteriaHasInvalidFormat) {
				notifyAboutCriteriaTooLong();
				return false;
			}
			if (error instanceof AtLeastOneCriteriaShouldBeEnabled) {
				notifyAboutNotEnabledCriteria();
				return false;
			}
			throw error;
		}
		return true;
	}

	function getOptionalProjectId() {
		return projectId || '';
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}

	function getEnabledCriteria() {
		return criteria.filter((criterion) => criterion.enabled);
	}
};
