import { StyledExportButton } from './styled';
import { DropdownItem } from 'common/components';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { TensionGraphExportButtonProps } from 'features/Graphs/TensionGraph/components/TensionGraphExportButton/TensionGraphExportButton.types';

export const TensionGraphExportButton = ({ exportService }: TensionGraphExportButtonProps) => {
	const downloadCurrentOption = '1';
	const downloadAllOption = '2';
	return (
		<StyledExportButton
			value={''}
			buttonProps={{ color: 'primary', variant: 'text' }}
			onChange={onSelect}
			labelIcon={faDownload}
			disableChevron
		>
			{createItems()}
		</StyledExportButton>
	);

	function onSelect(option: string) {
		switch (option) {
			case downloadCurrentOption:
				exportService.downloadCurrentClusterExport();
				return;
			case downloadAllOption:
				exportService.downloadAllClustersExport();
				return;
		}
	}

	function createItems() {
		return [option('Download Current', downloadCurrentOption), option('Download All', downloadAllOption)];
	}

	function option(name: string, id: string) {
		return (
			<DropdownItem
				key={id}
				value={id}
			>
				{name}
			</DropdownItem>
		);
	}
};
