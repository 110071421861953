import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartColors } from 'common/constants';
import { ColorPickerProps } from './ColorPicker.types';
import { ColorPaletteButton, ColorPaletteContainer } from './styled';

export const ColorPicker = ({ onSelect, selected }: ColorPickerProps) => {
	const ColorPaletteItems = (
		<>
			{Object.values(ChartColors).map((color) => {
				return (
					<ColorPaletteButton
						key={color}
						$isSelected={selected === color}
						$color={`#${color}`}
						onClick={() => onSelect(color)}
					>
						<FontAwesomeIcon icon={faCheck} />
					</ColorPaletteButton>
				);
			})}
		</>
	);

	return <ColorPaletteContainer>{ColorPaletteItems}</ColorPaletteContainer>;
};
