import { PdnaAccuracyChartProps } from './PdnaAccuracyChart.types';
import { AccuracyChart, accuracyChartConfig } from '../../';
import { usePdnaAccuracyChartData } from 'features/Graphs/AccuracyChart';
import { useSelector } from 'react-redux';
import { selectAccuracyChartCombinedPDNA, selectAccuracyChartPDNA } from 'store/slices';

export const PdnaAccuracyChart = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
}: PdnaAccuracyChartProps) => {
	const config = accuracyChartConfig;
	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const pdna = combinedMode ? useSelector(selectAccuracyChartPDNA) : useSelector(selectAccuracyChartCombinedPDNA);
	const accuracyData = usePdnaAccuracyChartData(pdna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<AccuracyChart
			config={config}
			containerId={containerId}
			data={accuracyData}
		/>
	);
};
