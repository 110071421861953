import { DispersionChartColorPresets } from 'features/Graphs/colorPresets';
import { DispersionChart } from '../../DispersionChart';
import { dispersionChartConfig } from '../../DispersionChartConfig';
import { OPdnaDispersionChartProps } from './OPdnaDispersionChart.types';
import { useDispersionChartData } from 'features/Graphs/DispersionChart';
import { useSelector } from 'react-redux';
import {
	selectDispersionChartCombinedODNA,
	selectDispersionChartCombinedPDNA,
	selectDispersionChartODNA,
	selectDispersionChartPDNA,
} from 'store/slices';

export const OPdnaDispersionChart = ({
	containerId,
	clusterIndex,
	pdnaBenchmarkIndexes,
	odnaBenchmarkIndexes,
	pnoBenchmarkIndexes,
	colorPresetIndex,
}: OPdnaDispersionChartProps) => {
	const config = dispersionChartConfig;
	const colorConfig = DispersionChartColorPresets[colorPresetIndex];

	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = combinedMode ? useSelector(selectDispersionChartCombinedPDNA) : useSelector(selectDispersionChartPDNA);
	const odna = combinedMode ? useSelector(selectDispersionChartCombinedODNA) : useSelector(selectDispersionChartODNA);
	const graphBase = { pdna, odna };
	const data = useDispersionChartData(
		graphBase,
		clusterIndex,
		pdnaBenchmarkIndexes,
		odnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
	);
	return (
		<DispersionChart
			config={config}
			colorConfig={colorConfig}
			containerId={containerId}
			data={data}
		/>
	);
};
