import { ApiTenantProject, TenantProject } from 'common/types';
import { AllProjectsResponse } from 'store/api';

export const mapGetAllClientProjectsResponse = (
	response: AllProjectsResponse<ApiTenantProject>,
): AllProjectsResponse<TenantProject> => {
	const { projects } = response;

	const mappedProjects: TenantProject[] = projects.map((project) => {
		return mapApiTenantProject(project);
	});

	return { projects: mappedProjects };
};

export const mapApiTenantProject = (project: ApiTenantProject): TenantProject => {
	const { id, ...projectCopy } = project;
	return {
		...projectCopy,
		tenantProjectId: id,
	};
};
