import { ApiUserConfiguration } from '.';

export const getXSRFCookie = () => {
	const cookieValueIndex = 1;
	const cookies = document.cookie.split('; ');
	const XSRFCookie = cookies.find((cookie) => cookie.startsWith('XSRF-TOKEN='));

	return XSRFCookie?.split('=')[cookieValueIndex];
};

export const getEmptyLoggedUserConfigurationResponse = (): ApiUserConfiguration => {
	const emptyConfig = {
		branding: {
			color: null,
			logo: null,
			questionnaireLoginLogo: null,
		},
		criteriaConfiguration: {
			criteria: [
				{
					enabled: false,
					name: '',
					number: '',
				},
			],
		},
	};

	return emptyConfig;
};

export const getFilenameFromResponse = (response: Response, defaultFilename: string): string => {
	const contentDispositionHeader = response.headers.get('content-disposition');
	if (!contentDispositionHeader) return defaultFilename;

	const filenameArrayIndex = 1;
	const filenameFromServer = contentDispositionHeader.split('filename=')[filenameArrayIndex];

	return filenameFromServer;
};
