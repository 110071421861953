import { Button } from 'common/components/Button';
import { Tabs } from 'common/components/Tabs';
import styled from 'styled-components';

export const ActionPanelContainer = styled.div`
	display: flex;
	align-items: center;

	& > * {
		margin-right: 2rem;
	}
`;

export const StyledTabs = styled(Tabs)`
	background-color: ${({ theme }) => theme.colors.common.white};
`;

const StyledButton = styled(Button)`
	text-transform: capitalize;
	height: fit-content;
	font-size: 1.3rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
`;

export const SaveButton = StyledButton;
export const ViewButton = StyledButton;
export const ReturnButton = StyledButton;
