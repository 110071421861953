import { heatmapChartIcon, scatterChartIcon } from 'common/assets/images';
import { ChartTypeMenu, ChartTypeMenuItem } from 'features/Simulation/components';
import { ViewableType, ViewableTypes } from 'features/DiscoveryInsights/views/DiscoveryInsightResults';

export const ViewableTypeSelection = ({
	chosenViewableType,
	onSelect,
}: {
	chosenViewableType: ViewableType;
	onSelect: (viewableType: ViewableType) => void;
}) => {
	return (
		<ChartTypeMenu
			selectedChartType={chosenViewableType}
			onSelect={onSelect}
		>
			<ChartTypeMenuItem
				iconSrc={scatterChartIcon}
				chartType={ViewableTypes.QUADRANT_GRAPH}
			/>
			<ChartTypeMenuItem
				iconSrc={heatmapChartIcon}
				chartType={ViewableTypes.DISTRIBUTION_TABLE}
			/>
		</ChartTypeMenu>
	);
};
