import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph';
import { CategoryDistribution, CriteriaPerQuarter } from 'store/api/discoveryInsightApi';
import { ApiQuarterlyDistributionSummary } from 'store/api';
import { ILegendData } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartData';
import { CategoryComparator } from 'features/DiscoveryInsights/components';

export class HighlightLegendCreator implements QuadrantLegendCreator<CriteriaPerQuarter> {
	constructor(private _comparator: CategoryComparator) {}
	createLegend(data: ApiQuarterlyDistributionSummary<CriteriaPerQuarter>): ILegendData {
		const roundTo = 2;

		const createLabel = (criteria: CategoryDistribution) =>
			`${criteria.category}: ${criteria.percentage.toFixed(roundTo)}% ${criteria.membersAmount}/${
				criteria.totalAmount
			}`;

		const fromBeginning = 0;
		const top10 = 10;
		const createLabelsFrom = (quarter: CriteriaPerQuarter) => {
			return [...quarter.criteria].sort(this._comparator).slice(fromBeginning, top10).map(createLabel);
		};

		return {
			leftTop: createLabelsFrom(data.q1),
			rightTop: createLabelsFrom(data.q2),
			leftBottom: createLabelsFrom(data.q3),
			rightBottom: createLabelsFrom(data.q4),
		};
	}
}
