import { useCriteriaConfiguration } from 'common/hooks/participants/useCriteriaConfiguration';
import { ParticipantsResponse } from 'store/api';
import { ApiBenchmarkParticipant, BenchmarkParticipant } from 'common/types';
import { transformBenchmarkParticipantResponse } from 'common/hooks/participants/mappers';
import { useLazyGetBenchmarkParticipantsQuery } from 'store/api';
import { BenchmarkParticipantOptions } from 'store/api';

export const useBenchmarkParticipants = () => {
	const emptyResponse = getParticipantsEmptyResponse();

	const criteriaConfiguration = useCriteriaConfiguration();
	const [getBenchmarkParticipants, benchmarkParticipants] = useLazyGetBenchmarkParticipantsQuery();

	const participants = getParticipantsData();

	return {
		getBenchmarkParticipants: getBenchmarkParticipantsIfNotEmpty,
		benchmarkParticipants: transform(participants),
	};

	function getParticipantsData(): ParticipantsResponse<ApiBenchmarkParticipant> {
		if (!benchmarkParticipants.isSuccess) return emptyResponse;

		return benchmarkParticipants.data;
	}

	async function getBenchmarkParticipantsIfNotEmpty(
		option: BenchmarkParticipantOptions,
	): Promise<ParticipantsResponse<BenchmarkParticipant>> {
		try {
			const response = await getBenchmarkParticipants(option).unwrap();

			return transform(response);
		} catch {
			return emptyResponse;
		}
	}

	function transform(
		participants: ParticipantsResponse<ApiBenchmarkParticipant>,
	): ParticipantsResponse<BenchmarkParticipant> {
		return transformBenchmarkParticipantResponse(participants, criteriaConfiguration);
	}
};

export const getParticipantsEmptyResponse = (): ParticipantsResponse<
	ApiBenchmarkParticipant & BenchmarkParticipant
> => {
	return { totalPages: 0, pageNumber: 0, content: [] };
};
