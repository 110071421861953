import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 1rem;
	margin-right: 3rem;
`;

export const SelectedCounterText = styled.p`
	margin: 0;
	margin-left: auto;
	font-size: 1.4rem;
`;
