import { SortIcon, StyledSortTrigger, StyledSortWrapper } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { StyledTableSortControlsProps } from './TableSortControls.types';

export const TableSortControls = ({ sortDirection }: StyledTableSortControlsProps) => {
	const basePosition = 2;
	const iconSize = 30;

	return (
		<StyledSortWrapper>
			<StyledSortTrigger active={isSortingAscending()}>{sortingUpIcon()}</StyledSortTrigger>
			<StyledSortTrigger active={isSortingDescending()}>{sortingDownIcon()}</StyledSortTrigger>
		</StyledSortWrapper>
	);

	function isSortingAscending() {
		return sortDirection === 'asc';
	}

	function isSortingDescending() {
		return sortDirection === 'desc';
	}

	function sortingUpIcon() {
		const transform = { y: basePosition, size: iconSize };
		return (
			<SortIcon>
				<FontAwesomeIcon
					transform={transform}
					icon={faCaretUp}
				/>
			</SortIcon>
		);
	}

	function sortingDownIcon() {
		const yOffset = 4;
		const yPosition = basePosition - yOffset;
		const transform = { y: yPosition, size: iconSize };
		return (
			<SortIcon>
				<FontAwesomeIcon
					transform={transform}
					icon={faCaretDown}
				/>
			</SortIcon>
		);
	}
};
