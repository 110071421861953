import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph';
import { PercentagePerQuarter } from 'store/api/discoveryInsightApi';
import { ApiQuarterlyDistributionSummary } from 'store/api';
import { ILegendData } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartData';

export class TotalViewLegendCreator implements QuadrantLegendCreator<PercentagePerQuarter> {
	createLegend(data: ApiQuarterlyDistributionSummary<PercentagePerQuarter>): ILegendData {
		return {
			leftTop: this._toPercentage(data.q1),
			rightTop: this._toPercentage(data.q2),
			leftBottom: this._toPercentage(data.q3),
			rightBottom: this._toPercentage(data.q4),
		};
	}

	_toPercentage(quarter: PercentagePerQuarter) {
		const rounding = 2;
		return [`${quarter.percentage.toFixed(rounding)}%`];
	}
}
