import styled, { css } from 'styled-components';
import { StyledTableSortTriggerProps } from './TableSortControls.types';

export const StyledSortWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-content: flex-start;
	flex-wrap: wrap;
`;

export const SortIcon = styled.span`
	padding: 0 0.5rem;
	color: ${({ theme }) => theme.colors.common.white};
`;

const activeSortingIcon = css`
	${SortIcon} {
		color: ${({ theme }) => theme.colors.common.black};
	}
`;

export const StyledSortTrigger = styled.div<StyledTableSortTriggerProps>`
	${({ active }) => active && activeSortingIcon}
`;
