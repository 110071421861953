import { getFilenameFromResponse, rootApi } from 'store/api';
import { GetBillingReportCsvBody, GetBillingReportCsvResponse } from './billingReportApi.types';

export const billingReportApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getBillingReportCsv: builder.mutation<GetBillingReportCsvResponse, GetBillingReportCsvBody>({
			query: (data) => ({
				url: '/api/billingreport',
				method: 'POST',
				body: data,
				responseHandler: async (response) => {
					if (!response.ok) return;

					const defaultFilename = 'Billing_Report.csv';
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});

export const { useGetBillingReportCsvMutation } = billingReportApi;
