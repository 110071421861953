import { ApiTenantAdmin } from 'common/types';
import { rootApi, TenantAdminToUnlockOptions } from 'store/api';
import {
	AllTenantsAdminsResponse,
	CreateTenantAdminBody,
	CreateTenantAdminResponse,
	TenantAdminToDeleteOptions,
} from './tenantAdminApi.types';

export const tenantAdminApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllTenantsAdmins: builder.query<AllTenantsAdminsResponse<ApiTenantAdmin>, void>({
			query: () => ({
				url: `/api/tadmins`,
			}),
			providesTags: ['TenantsAdmins'],
		}),
		createTenantAdmin: builder.mutation<CreateTenantAdminResponse, CreateTenantAdminBody>({
			query: (data) => ({
				url: `/api/tadmins`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['TenantsAdmins'],
		}),
		checkTenantAdminIdAvailability: builder.query<{ available: boolean }, { username: string }>({
			query: (data) => ({
				url: `/api/username-availability`,
				params: data,
			}),
		}),
		deleteTenantAdmin: builder.mutation<void, TenantAdminToDeleteOptions>({
			query: (opts) => ({
				method: 'DELETE',
				url: `/api/tadmins/${opts.tenantAdminId}`,
			}),
			invalidatesTags: ['TenantsAdmins'],
		}),
		unlockTenantAdmin: builder.mutation<void, TenantAdminToUnlockOptions>({
			query: (opts) => ({
				method: 'PUT',
				url: `/api/tadmins/${opts.tenantAdminId}`,
			}),
			invalidatesTags: ['TenantsAdmins'],
		}),
	}),
});

export const {
	useGetAllTenantsAdminsQuery,
	useLazyCheckTenantAdminIdAvailabilityQuery,
	useDeleteTenantAdminMutation,
	useCreateTenantAdminMutation,
	useUnlockTenantAdminMutation,
} = tenantAdminApi;
