import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { NotificationTypes, useNotifications } from 'common/components';
import { HttpResponseStatusCodes } from 'common/types';
import {
	ProjectValidationProperties,
	useCreateProjectFormData,
	useProjectFormDataValidation,
} from 'features/ProjectConfiguration/components/CreateProject/hooks';
import { ProjectFormLayout } from 'features/ProjectConfiguration/views';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectFormBody, useCreateProjectMutation } from 'store/api';

export const CreateProject = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const { getProjectData } = useCreateProjectFormData();
	const { validateForm } = useProjectFormDataValidation();
	const [createProject] = useCreateProjectMutation();
	const navigate = useNavigate();
	const { pushNotification } = useNotifications();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const projectData = await getProjectData();
		const validationParameters: ProjectValidationProperties = { shouldCheckForShortIdUniqueness: true };
		const validate = () => validateForm(projectData, validationParameters);
		const isFormValid = await validate();

		if (!isFormValid) return;

		await tryToCreateProject(projectData);
	};

	return (
		<ProjectFormLayout
			id="create-project"
			title="CREATE NEW PROJECT"
			saveTrigger={saveTrigger}
			includeAdditionalQuestions={true}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);

	async function tryToCreateProject(projectData: ProjectFormBody) {
		try {
			await createProject(projectData).unwrap();
			navigate('/projects');
		} catch (error) {
			const errorObj = error as FetchBaseQueryError;

			if (errorObj.status === HttpResponseStatusCodes.BAD_REQUEST) {
				const errorMessage = errorObj.data as string;
				pushNotification({ message: errorMessage, type: NotificationTypes.Error });
			}
		}
	}
};
