import { ModalContainer } from 'features/ClusterConfiguration/components';
import { GraphContainer } from 'features/Graphs/common/components';
import { StyledSvgContainer } from './styled';
import { TensionGraphModalContentProps } from 'features/Graphs/TensionGraph';

export const TensionGraphModalContent = ({ graphBuilder }: TensionGraphModalContentProps) => {
	const tensionGraphId = 'tension-graph-id';
	return (
		<ModalContainer>
			<StyledSvgContainer id={tensionGraphId} />
			<GraphContainer
				containerId={tensionGraphId}
				buildGraph={graphBuilder}
				data={[]}
			/>
		</ModalContainer>
	);
};
