import { rootApi } from 'store/api';
import { EditLogoOptions, GetLogoOptions, TenantBrandingResponse } from './tenantBrandingApi.types';

export const tenantBrandingApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		editLogo: builder.mutation<void, EditLogoOptions>({
			query: (data) => ({
				method: 'POST',
				url: `/api/tenants/${data.tenantId}/logo`,
				body: data.file,
			}),
			invalidatesTags: ['Logo'],
		}),
		editQuestionnaireLogo: builder.mutation<void, EditLogoOptions>({
			query: (data) => ({
				method: 'POST',
				url: `/api/tenants/${data.tenantId}/logo/questionnaire`,
				body: data.file,
			}),
			invalidatesTags: ['Logo'],
		}),
		getBranding: builder.query<TenantBrandingResponse, GetLogoOptions>({
			query: (data) => ({
				url: `api/tenants/${data.tenantId}/branding`,
				method: 'GET',
			}),
			providesTags: ['Logo'],
		}),
	}),
});

export const { useEditQuestionnaireLogoMutation, useEditLogoMutation, useGetBrandingQuery } = tenantBrandingApi;
