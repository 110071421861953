import { StyledCheckbox, StyledQuartileInfo } from './styled';

export const QuartileInfo = ({ enabled, onChange }: { enabled: boolean; onChange: (value: boolean) => void }) => {
	return (
		<StyledQuartileInfo>
			<StyledCheckbox
				checked={enabled}
				onChange={() => onChange(!enabled)}
			/>
			Show Quartile info
		</StyledQuartileInfo>
	);
};
