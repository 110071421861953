import { Roadmap } from 'store/api/roadmapApi';
import { RoadMapSettingsOptions } from 'features/RoadMap/components/RoadMapSettingsPanel/RoadMapSettingsPanel.types';
import { useEffect, useState } from 'react';
import pptxgen from 'pptxgenjs';
import { Core } from 'cytoscape';
import { StyledRoadMapGraphContainer } from './styled';
import { RoadMapGraph } from 'features/RoadMap/components/RoadMapGraph';
import { EmptyRoadmap } from './EmptyRoadmap/EmptyRoadmap';

export const AllRoadMapsGraphs = ({
	roadmap,
	style,
	selectedRoadmap,
	exportTrigger,
}: {
	roadmap: Roadmap;
	style: RoadMapSettingsOptions;
	selectedRoadmap: string;
	exportTrigger: number;
}) => {
	const [mainCy, setMainCy] = useState<Core>();
	const [altCy, setAltCy] = useState<Core>();
	const [sAltCy, setSAltCy] = useState<Core>();

	const prepareSlide = (presentation: pptxgen, cy: Core | undefined) => {
		if (!cy || !cy.elements().length) return;
		const image = cy.png();
		const slide = presentation.addSlide({ masterName: 'Path' });
		slide.addImage({ data: image, w: '100%', h: '100%' });
	};

	useEffect(() => {
		if (!exportTrigger) return;
		const presentation = new pptxgen();
		prepareSlide(presentation, mainCy);
		prepareSlide(presentation, altCy);
		prepareSlide(presentation, sAltCy);

		presentation.writeFile();
	}, [exportTrigger]);

	const mainRoadmapExists = !!roadmap.mainRoadmapGraph.vertices.length;

	return mainRoadmapExists ? (
		<>
			<StyledRoadMapGraphContainer $visible={selectedRoadmap === '0'}>
				<RoadMapGraph
					data={roadmap.mainRoadmapGraph}
					style={style}
					setCy={(cy) => setMainCy(cy)}
					exportStarted={!!exportTrigger}
				/>
			</StyledRoadMapGraphContainer>
			<StyledRoadMapGraphContainer $visible={selectedRoadmap === '1'}>
				<RoadMapGraph
					data={roadmap.alternateRoadmapGraph}
					style={style}
					setCy={(cy) => setAltCy(cy)}
					exportStarted={!!exportTrigger}
				/>
			</StyledRoadMapGraphContainer>
			<StyledRoadMapGraphContainer $visible={selectedRoadmap === '2'}>
				<RoadMapGraph
					data={roadmap.secondaryAlternateRoadmapGraph}
					style={style}
					setCy={(cy) => setSAltCy(cy)}
					exportStarted={!!exportTrigger}
				/>
			</StyledRoadMapGraphContainer>
		</>
	) : (
		<EmptyRoadmap />
	);
};
