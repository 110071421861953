import { StyledTableCellFilter, StyledTHead } from './styled';
import { TableCellProps } from './TableHead.types';
import { TableCell, TableFilter, TableHeadLabel, TableRow } from 'common/components';
import { Header } from '@tanstack/react-table';

export const TableHead = <T extends object>({ headerGroup }: TableCellProps<T>) => {
	const getHeaderLabelRow = (): JSX.Element[] => {
		return headerGroup.map((group) => {
			return <TableRow key={group.id}>{getHeaderLabels(group.headers)}</TableRow>;
		});
	};

	const getHeaderLabels = (headers: Header<T, unknown>[]): JSX.Element[] => {
		return headers.map((header) => {
			return (
				<TableCell key={header.id}>{!header.isPlaceholder && <TableHeadLabel<T> header={header} />}</TableCell>
			);
		});
	};

	const getHeaderFilterRow = (): JSX.Element[] => {
		return headerGroup.map((group) => {
			return <TableRow key={group.id}>{getHeaderFilters(group.headers)}</TableRow>;
		});
	};

	const getHeaderFilters = (headers: Header<T, unknown>[]): JSX.Element[] => {
		return headers.map((header) => {
			return (
				<StyledTableCellFilter key={header.id}>
					{header.column.getCanFilter() && <TableFilter<T> column={header.column} />}
				</StyledTableCellFilter>
			);
		});
	};

	return (
		<StyledTHead>
			{getHeaderLabelRow()}
			{getHeaderFilterRow()}
		</StyledTHead>
	);
};
