import { PageLayout } from 'common/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { BillingReportFormLayoutProps } from './BillingReportFormLayout.types';
import { BillingReportForm } from 'features/BillingReport/components';

export const BillingReportFormLayout = ({ id, saveTrigger, onSaveButtonClick }: BillingReportFormLayoutProps) => {
	return (
		<PageLayout
			title="Billing Report Across All CLients"
			id={id}
		>
			<div>
				<BillingReportForm saveTrigger={saveTrigger} />

				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Generate Report</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
