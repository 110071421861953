import styled from 'styled-components';
import { StyledColumn } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantTypeSelection/styled';
import { DropdownSelectedLabel } from 'common/components/Dropdown/styled';

export const StyledCriteriaColumn = styled(StyledColumn)`
	min-width: 12rem;
	max-width: 12rem;

	${DropdownSelectedLabel} {
		max-width: 90%;
		overflow: hidden;
		white-space: nowrap;
		display: block;
		text-overflow: ellipsis;
	}
`;
