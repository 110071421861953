import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'common/components';
import { BehaviourListItemProps } from './BehaviourListItem.types';
import { BehaviourNameText, GridListItem, StyledIconButton } from './styled';

export const BehaviourListItem = ({ children, onDeleteClick, hideDeleteIcon = false }: BehaviourListItemProps) => {
	return (
		<GridListItem>
			<BehaviourNameText>{children}</BehaviourNameText>
			{!hideDeleteIcon && (
				<Tooltip content="Delete Behaviour">
					<StyledIconButton onClick={onDeleteClick}>
						<FontAwesomeIcon icon={faTrash} />
					</StyledIconButton>
				</Tooltip>
			)}
		</GridListItem>
	);
};
