import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { OrganisationInformationState } from '.';

const initialState: OrganisationInformationState = {
	organisationName: '',
	organisationId: '',
	purchaseOrderReference: '',
	supplierAgreementReference: '',
	telephoneNumber: '',
	emailAddress: '',
};

export const organisationInformationSlice = createSlice({
	name: 'organisationInformation',
	initialState,
	reducers: {
		setOrganisationInformationState: (state, { payload }: PayloadAction<OrganisationInformationState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setOrganisationInformationState } = organisationInformationSlice.actions;

export const organisationInformationSliceReducer = organisationInformationSlice.reducer;

export const getOrganisationInformationState = (state: RootState) => {
	return state.createClientReducers.organisationInformation;
};
