import IDataSeries from '@support/alderbrooke-d3-graphs/dist/Curve/IDataSeries';
import { PieChartBase } from 'features/Graphs/PptxExportButton';

export const useOPdnaPieChartData = (
	graphBase: PieChartBase,
	clusterIndex: number,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = graphBase.pdna;
	const odna = graphBase.odna;

	const checkedPdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : pdnaBenchmarkIndexes;
	const checkedOdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : odnaBenchmarkIndexes;
	const benchmarksLength = pdnaBenchmarkIndexes.length;

	const pieData: IDataSeries[] = [];

	for (let benchmarkIndex = 0; benchmarkIndex < benchmarksLength; benchmarkIndex++) {
		const pdnaBenchmarkEnabled = checkedPdnaBenchmarks[benchmarkIndex];
		const odnaBenchmarkEnabled = checkedOdnaBenchmarks[benchmarkIndex];
		const pdnaValueExists = pdna.clusters[clusterIndex].benchmarks[benchmarkIndex].value;
		const odnaValueExists = odna.clusters[clusterIndex].benchmarks[benchmarkIndex].value;

		if (pdnaBenchmarkEnabled && pdnaValueExists) {
			pieData.push(pdna.clusters[clusterIndex].benchmarks[benchmarkIndex]);
		}

		if (odnaBenchmarkEnabled && odnaValueExists) {
			pieData.push(odna.clusters[clusterIndex].benchmarks[benchmarkIndex]);
		}
	}

	return pieData;
};
