import { rootApi, CultureViewResultsRequest, ApiQuadrantResultsResponse, ApiResultResponse } from 'store/api';

export const cultureViewApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCommonGraphData: builder.query<ApiResultResponse, CultureViewResultsRequest>({
			query: (opts) => ({
				url: `api/participants/results`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
		getQuadrantData: builder.query<ApiQuadrantResultsResponse, CultureViewResultsRequest>({
			query: (opts) => ({
				url: `api/participants/quadrant`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
		getCommonGraphDataFromProject: builder.query<ApiResultResponse, CultureViewResultsRequest>({
			query: (opts) => ({
				url: `api/participants/project/${opts.projectId}/results`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
		getQuadrantDataFromProject: builder.query<ApiQuadrantResultsResponse, CultureViewResultsRequest>({
			query: (opts) => ({
				url: `api/participants/project/${opts.projectId}/quadrant`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
	}),
});
export const {
	useLazyGetCommonGraphDataFromProjectQuery,
	useLazyGetCommonGraphDataQuery,
	useLazyGetQuadrantDataFromProjectQuery,
	useLazyGetQuadrantDataQuery,
} = cultureViewApi;
