import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BillingAddressState, setBillingAddressState } from 'store/slices/';
import { handleRequiredFormValue, createFormState } from 'common/utils';
import { BillingAddressProps } from './BillingAddress.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const BillingAddress = ({
	saveTrigger,
	disableFirstBillingAddress,
	disableSecondBillingAddress,
	disableBillingCity,
	disableBillingPostcode,
	firstBillingAddress: initialFirstBillingAddress,
	secondBillingAddress: initialSecondBillingAddress,
	billingCity: initialBillingCity,
	billingPostcode: initialBillingPostcode,
}: BillingAddressProps) => {
	const [firstBillingAddress, setFirstBillingAddress] = useState(emptyIfNullOrUndefined(initialFirstBillingAddress));
	const [secondBillingAddress, setSecondBillingAddress] = useState(
		emptyIfNullOrUndefined(initialSecondBillingAddress),
	);

	const [billingCity, setBillingCity] = useState<RequiredFormInputState>(createFormState(initialBillingCity));

	const [billingPostcode, setBillingPostcode] = useState<RequiredFormInputState>(
		createFormState(initialBillingPostcode),
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(billingCity.value, 'Billing city is required!', setBillingCity);
		handleRequiredFormValue(billingPostcode.value, 'Billing zip code is required!', setBillingPostcode);
	};

	const setSliceState = () => {
		const sliceState: BillingAddressState = {
			firstBillingAddress: firstBillingAddress,
			secondBillingAddress: secondBillingAddress,
			billingCity: billingCity.value,
			billingPostcode: billingPostcode.value,
		};

		dispatch(setBillingAddressState(sliceState));
	};

	return (
		<>
			<Input
				name="Billing address line 1"
				placeholder="e.g. Building, Floor"
				maxLength={50}
				value={firstBillingAddress}
				disabled={disableFirstBillingAddress}
				formInput
				onChange={(event) => {
					setFirstBillingAddress(event.target.value);
				}}
				onBlur={(event) => {
					setFirstBillingAddress(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				name="Billing address line 2"
				placeholder="e.g. Street"
				value={secondBillingAddress}
				maxLength={50}
				disabled={disableSecondBillingAddress}
				formInput
				onChange={(event) => {
					setSecondBillingAddress(event.target.value);
				}}
				onBlur={(event) => {
					setSecondBillingAddress(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				{...billingCity}
				name="Billing City / Place"
				placeholder="If applicable add State"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingCity}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing city is required!', setBillingCity);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing city is required!', setBillingCity);
					setSliceState();
				}}
			/>
			<Input
				{...billingPostcode}
				name="Billing Postcode / ZIP"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingPostcode}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing zip code is required!', setBillingPostcode);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing zip code is required!', setBillingPostcode);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
