import { useCloseProjectMutation } from 'store/api';
import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { DeleteFrameworkConfirmDialogProps } from './CloseProjectConfirmationDialog.types';

export const CloseProjectConfirmationDialog = ({
	isOpen,
	projectId,
	onClose,
	...additionalModalProps
}: DeleteFrameworkConfirmDialogProps) => {
	const [closeProject] = useCloseProjectMutation();
	const { pushNotification } = useNotifications();

	const onCloseProjectAction = async () => {
		if (!projectId) return;

		try {
			await closeProject({ projectId: projectId }).unwrap();
			pushNotification({
				message: 'Project closure triggered successfully!',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Could not close project!',
				type: NotificationTypes.Error,
			});
		}
	};

	return (
		<ConfirmationDialog
			id="close-project-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onCloseProjectAction}
			title="Are you sure that you want to close project?"
			{...additionalModalProps}
		/>
	);
};
