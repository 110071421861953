import { Criterion } from 'common/types';
import { useAuth } from '../useAuth';

export const useCriteriaConfiguration = (): Criterion[] => {
	const loggedUser = useAuth();

	return getCriteriaConfiguration();

	function getCriteriaConfiguration() {
		return loggedUser?.user?.configuration.criteriaConfiguration ?? [];
	}
};
