import { BenchmarkParticipantColumnIds, useBenchmarkParticipantData } from 'features/Tables/hooks';
import { BenchmarkParticipant, TableQueryParams, TableQueryTrigger } from 'common/types';
import { Table } from 'common/components';
import { useParams } from 'react-router-dom';
import { ApiBenchmarkGroup, BenchmarkConfigurationId, useUnAssignParticipantsMutation } from 'store/api';
import { useState } from 'react';
import { BenchmarksPanel } from 'features/ResultsConfiguration/components';
import { useBenchmarkParticipants } from 'common/hooks/participants/useBenchmarkParticipants';
import { ColumnFilter } from '@tanstack/react-table';

export const CommonFiveBenchmarkTab = ({
	id,
	benchmarkConfigurationId,
}: {
	id: string;
	benchmarkConfigurationId: BenchmarkConfigurationId;
}) => {
	const { projectId } = useParams();
	const [benchmarkTableQueryTrigger, setBenchmarkTableQueryTrigger] = useState<TableQueryTrigger>();
	const { getBenchmarkParticipants, benchmarkParticipants } = useBenchmarkParticipants();
	const [unAssign] = useUnAssignParticipantsMutation();

	const { columnsBenchmarks, visibleColumnsBenchmarks } = useBenchmarkParticipantData(
		projectId,
		benchmarkConfigurationId,
		triggerBenchmarkDataReload,
		[
			BenchmarkParticipantColumnIds.Benchmark1,
			BenchmarkParticipantColumnIds.Benchmark2,
			BenchmarkParticipantColumnIds.Benchmark3,
			BenchmarkParticipantColumnIds.Benchmark4,
			BenchmarkParticipantColumnIds.Benchmark5,
		],
	);

	const onBenchmarkQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
		if (benchmarkConfigurationId === undefined) {
			return;
		}
		await getDataForBenchmarkParticipantsTable(benchmarkConfigurationId, queryParams);
	};

	return (
		<>
			<BenchmarksPanel onUnassignButtonClick={onUnassignButtonClick} />

			<Table<BenchmarkParticipant>
				tableTypeId={`${id}-benchmark-participants`}
				visibleColumns={visibleColumnsBenchmarks}
				columns={columnsBenchmarks}
				currentPageIndex={benchmarkParticipants.pageNumber}
				pageCount={benchmarkParticipants.totalPages}
				data={benchmarkParticipants.content}
				onQueryChange={onBenchmarkQueryChange}
				queryTrigger={benchmarkTableQueryTrigger}
			/>
		</>
	);

	function onUnassignButtonClick() {
		if (!benchmarkConfigurationId) return;

		const request = {
			benchmarkConfigurationId: benchmarkConfigurationId,
			projectId: projectId,
			benchmarkGroup: [
				ApiBenchmarkGroup.Benchmark1,
				ApiBenchmarkGroup.Benchmark2,
				ApiBenchmarkGroup.Benchmark3,
				ApiBenchmarkGroup.Benchmark4,
				ApiBenchmarkGroup.Benchmark5,
			],
			pageParams: { filters: {} },
		};
		unAssign(request).then(triggerBenchmarkDataReload);
	}

	async function getDataForBenchmarkParticipantsTable(
		benchmarkConfigurationId: BenchmarkConfigurationId,
		queryParams: TableQueryParams,
	): Promise<void> {
		try {
			await getBenchmarkParticipants({
				benchmarkConfigurationId: benchmarkConfigurationId,
				...createRequest(queryParams),
			});
		} catch {}
	}

	function triggerBenchmarkDataReload() {
		return setBenchmarkTableQueryTrigger(new TableQueryTrigger());
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}

	function createFilters(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function getOptionalProjectId() {
		return projectId || '';
	}
};
