import { BoxHeaderProps } from './BoxHeader.types';
import { Header, StyledHr } from './styled';

export const BoxHeader = ({ title }: BoxHeaderProps) => {
	return (
		<>
			<Header>{title}</Header>
			<StyledHr />
		</>
	);
};
