import { BenchmarkParticipantColumnIds, useBenchmarkParticipantData } from 'features/Tables/hooks';
import { BenchmarkParticipant, TableQueryParams, TableQueryTrigger } from 'common/types';
import { Table } from 'common/components';
import { useParams } from 'react-router-dom';
import { ApiBenchmarkGroup, BenchmarkConfigurationId, useUnAssignParticipantsMutation } from 'store/api';
import { useState } from 'react';
import { useBenchmarkParticipants } from 'common/hooks/participants/useBenchmarkParticipants';
import { ColumnDef, ColumnFilter } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from './styled';
import { BehaviourFocusBenchmarksPanel } from 'features/BehaviourFocus/components';
import { BenchmarkGroupLabels } from 'features/BehaviourFocusResult/views';

export const BehaviourFocusBenchmarkTab = ({
	id,
	benchmarkConfigurationId,
	onBenchmarkLabelChange,
}: {
	id: string;
	benchmarkConfigurationId: BenchmarkConfigurationId;
	onBenchmarkLabelChange: (labels: BenchmarkGroupLabels) => void;
}) => {
	const { projectId } = useParams();
	const [benchmarkTableQueryTrigger, setBenchmarkTableQueryTrigger] = useState<TableQueryTrigger>();
	const { getBenchmarkParticipants, benchmarkParticipants } = useBenchmarkParticipants();
	const [unAssign] = useUnAssignParticipantsMutation();
	const [fromOneToFiveBenchmarksSelected, setFromOneToFiveBenchmarksSelected] = useState(true);
	const [userSelectedColumnsManually, setUserSelectedColumnsManually] = useState(false);
	const [columnSelection, setColumnSelection] = useState<ColumnDef<BenchmarkParticipant>[]>();

	const { columnsBenchmarks } = useBenchmarkParticipantData(
		projectId,
		benchmarkConfigurationId,
		triggerBenchmarkDataReload,
		[
			BenchmarkParticipantColumnIds.Benchmark1,
			BenchmarkParticipantColumnIds.Benchmark2,
			BenchmarkParticipantColumnIds.Benchmark3,
			BenchmarkParticipantColumnIds.Benchmark4,
			BenchmarkParticipantColumnIds.Benchmark5,
			BenchmarkParticipantColumnIds.Benchmark6,
			BenchmarkParticipantColumnIds.Benchmark7,
			BenchmarkParticipantColumnIds.Benchmark8,
			BenchmarkParticipantColumnIds.Benchmark9,
			BenchmarkParticipantColumnIds.Benchmark10,
		],
	);

	const onBenchmarkQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
		if (benchmarkConfigurationId === undefined) {
			return;
		}
		await getDataForBenchmarkParticipantsTable(benchmarkConfigurationId, queryParams);
	};

	const visibleColumnsBenchmarks = userSelectedColumnsManually
		? getUserColumnSelection()
		: getDefaultBenchmarkColumn();

	return (
		<>
			<BehaviourFocusBenchmarksPanel
				onUnAssignButtonClick={onUnassignButtonClick}
				firstTabActive={fromOneToFiveBenchmarksSelected}
				onBenchmarkLabelChange={onBenchmarkLabelChange}
			/>
			{getBenchmarkSelectorButton()}

			<Table<BenchmarkParticipant>
				tableTypeId={`${id}-benchmark-participants`}
				visibleColumns={visibleColumnsBenchmarks}
				columns={columnsBenchmarks}
				currentPageIndex={benchmarkParticipants.pageNumber}
				pageCount={benchmarkParticipants.totalPages}
				data={benchmarkParticipants.content}
				onQueryChange={onBenchmarkQueryChange}
				queryTrigger={benchmarkTableQueryTrigger}
				onColumnSelection={onColumnSelection}
			/>
		</>
	);

	function getUserColumnSelection() {
		return columnSelection?.length ? columnSelection : [];
	}

	function onColumnSelection(changedColumns: ColumnDef<BenchmarkParticipant>[]) {
		setUserSelectedColumnsManually(true);
		setColumnSelection(changedColumns);
	}

	function getDefaultBenchmarkColumn(): ColumnDef<BenchmarkParticipant>[] {
		const benchmarks = fromOneToFiveBenchmarksSelected
			? [
					BenchmarkParticipantColumnIds.Benchmark1,
					BenchmarkParticipantColumnIds.Benchmark2,
					BenchmarkParticipantColumnIds.Benchmark3,
					BenchmarkParticipantColumnIds.Benchmark4,
					BenchmarkParticipantColumnIds.Benchmark5,
			  ]
			: [
					BenchmarkParticipantColumnIds.Benchmark6,
					BenchmarkParticipantColumnIds.Benchmark7,
					BenchmarkParticipantColumnIds.Benchmark8,
					BenchmarkParticipantColumnIds.Benchmark9,
					BenchmarkParticipantColumnIds.Benchmark10,
			  ];
		return columnsBenchmarks.filter((col) =>
			[
				BenchmarkParticipantColumnIds.Email,
				BenchmarkParticipantColumnIds.FirstName,
				BenchmarkParticipantColumnIds.LastName,
			]
				.concat(benchmarks)
				.includes(col.id as string),
		);
	}

	function getBenchmarkSelectorButton() {
		const caretLeftIcon = <FontAwesomeIcon icon={faCaretLeft} />;
		const caretRightIcon = <FontAwesomeIcon icon={faCaretRight} />;

		return fromOneToFiveBenchmarksSelected ? (
			<StyledButton
				endIcon={caretRightIcon}
				variant="outlined"
				onClick={() => {
					setFromOneToFiveBenchmarksSelected(!fromOneToFiveBenchmarksSelected);
				}}
			>
				Benchmarks 6 - 10
			</StyledButton>
		) : (
			<StyledButton
				startIcon={caretLeftIcon}
				variant="outlined"
				onClick={() => {
					setFromOneToFiveBenchmarksSelected(!fromOneToFiveBenchmarksSelected);
				}}
			>
				Benchmarks 1 - 5
			</StyledButton>
		);
	}

	function onUnassignButtonClick() {
		if (!benchmarkConfigurationId) return;

		const request = {
			benchmarkConfigurationId: benchmarkConfigurationId,
			projectId: projectId,
			benchmarkGroup: [
				ApiBenchmarkGroup.Benchmark1,
				ApiBenchmarkGroup.Benchmark2,
				ApiBenchmarkGroup.Benchmark3,
				ApiBenchmarkGroup.Benchmark4,
				ApiBenchmarkGroup.Benchmark5,
				ApiBenchmarkGroup.Benchmark6,
				ApiBenchmarkGroup.Benchmark7,
				ApiBenchmarkGroup.Benchmark8,
				ApiBenchmarkGroup.Benchmark9,
				ApiBenchmarkGroup.Benchmark10,
			],
			pageParams: { filters: {} },
		};
		unAssign(request).then(triggerBenchmarkDataReload);
	}

	async function getDataForBenchmarkParticipantsTable(
		benchmarkConfigurationId: BenchmarkConfigurationId,
		queryParams: TableQueryParams,
	): Promise<void> {
		try {
			await getBenchmarkParticipants({
				benchmarkConfigurationId: benchmarkConfigurationId,
				...createRequest(queryParams),
			});
		} catch {}
	}

	function triggerBenchmarkDataReload() {
		return setBenchmarkTableQueryTrigger(new TableQueryTrigger());
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}

	function createFilters(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function getOptionalProjectId() {
		return projectId || '';
	}
};
