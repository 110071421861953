import { StyledTBody } from './styled';
import { TableBodyProps } from './TableBody.types';
import { TableCell, TableRow } from 'common/components';
import { flexRender, Row } from '@tanstack/react-table';

export const TableBody = <T extends object>({ rows }: TableBodyProps<T>) => {
	const getRows = (): JSX.Element[] => {
		return rows.map((row) => {
			return <TableRow key={row.id}>{getRowCells(row)}</TableRow>;
		});
	};

	const getRowCells = (row: Row<T>): JSX.Element[] => {
		return row.getVisibleCells().map((cell) => {
			const cellTemplate = cell.column.columnDef.cell;
			return <TableCell key={cell.id}>{flexRender(cellTemplate, cell.getContext())}</TableCell>;
		});
	};

	return <StyledTBody>{getRows()}</StyledTBody>;
};
