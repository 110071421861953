import { useDeleteFrameworkMutation } from 'store/api';
import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { DeleteFrameworkConfirmDialogProps } from './DeleteFrameworkConfirmDialog.types';

export const DeleteFrameworkConfirmDialog = ({
	isOpen,
	frameworkDataId,
	frameworkName,
	onClose,
	...additionalModalProps
}: DeleteFrameworkConfirmDialogProps) => {
	const [deleteFramework] = useDeleteFrameworkMutation();
	const { pushNotification } = useNotifications();

	const onDeleteFramework = async () => {
		try {
			await deleteFramework({ frameworkId: frameworkDataId }).unwrap();
			pushNotification({
				message: 'Framework successfully deleted',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Something went wrong during last operation!',
				type: NotificationTypes.Error,
			});
		}
	};

	return (
		<ConfirmationDialog
			id="deleting-framework-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onDeleteFramework}
			title={`Remove the framework '${frameworkName}'?`}
			{...additionalModalProps}
		/>
	);
};
