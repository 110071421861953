import { CloseButton, Container, StyledTitle } from './styled';
import { toggleChartSettingsView } from 'store/slices';
import { useDispatch } from 'react-redux';
import { BehaviourFocusResultSettings } from 'features/BehaviourFocusResult/views/BehaviourViewResults/BehaviourFocusResults.types';
import { BehaviourList, BehaviourListItem } from 'features/ClusterConfiguration/components';
import { Modal } from 'common/components';
import { useState } from 'react';
import { BehaviourPairModalContent } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent';
import { SelectedDimension } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent/BehaviourPairModalContent.types';

export const BehaviourFocusResultMenu = ({ dimensions, onDimensionsChange }: BehaviourFocusResultSettings) => {
	const dispatch = useDispatch();
	const [isBehaviourModalOpened, setIsBehaviourModalOpened] = useState(false);

	return (
		<>
			<StyledTitle>Add/ remove behaviours</StyledTitle>
			<Container>
				<Modal
					id="behaviours-modal-behaviour-selection"
					isOpen={isBehaviourModalOpened}
					hiddenCloseButton
					onClose={() => setIsBehaviourModalOpened(false)}
				>
					<BehaviourPairModalContent
						selectedDimensions={dimensions}
						onConfirm={(dimensions) => {
							onDimensionsChange(dimensions);
							setIsBehaviourModalOpened(false);
						}}
					/>
				</Modal>
				{getBehaviourList()}
				<CloseButton onClick={onCloseButtonClick}>Close</CloseButton>
			</Container>
		</>
	);

	function onCloseButtonClick() {
		dispatch(toggleChartSettingsView(false));
	}

	function getBehaviourList() {
		const oneDimensionLength = 1;
		const isOnlyOneDimensionSelected = dimensions.length === oneDimensionLength;
		const isDeleteIconHidden = isOnlyOneDimensionSelected;

		const ListItems = dimensions.map((dimension) => (
			<BehaviourListItem
				key={dimension.dimensionId}
				onDeleteClick={() => onDeleteBehaviour(dimension)}
				hideDeleteIcon={isDeleteIconHidden}
			>
				{dimension.name}
			</BehaviourListItem>
		));

		return (
			<BehaviourList onAddNewBehaviourClick={() => setIsBehaviourModalOpened(true)}>{ListItems}</BehaviourList>
		);
	}

	function onDeleteBehaviour(dimension: SelectedDimension) {
		const newDimensions = dimensions.filter(
			(editedDimension) => editedDimension.dimensionId !== dimension.dimensionId,
		);
		onDimensionsChange(newDimensions);
	}
};
