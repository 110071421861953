import styled from 'styled-components';
import { ZIndexes } from 'common/constants/zIndexes';
import { Button } from 'common/components/Button';
import { ModalBackdropProps } from './Modal.types';

export const ModalBackdrop = styled.div<ModalBackdropProps>`
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: ${(props) => (props.$backdropZIndex ? props.$backdropZIndex : ZIndexes.modalBackdrop)};
`;

export const StyledModal = styled.div<ModalBackdropProps>`
	border: 2px solid ${(props) => (props.className === 'primary' ? props.theme.colors.primary.main : null)};
	border-radius: ${(props) => (props.className === 'primary' ? '10px' : null)};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: white;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow-y: auto;
	max-height: 80vh;
	z-index: ${(props) => (props.$modalZIndex ? props.$modalZIndex : ZIndexes.modal)};
`;

export const StyledModalHeader = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100%;

	align-content: flex-end;
`;

export const CloseButton = styled(Button)`
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: ${ZIndexes.modal};
	width: 8em;
`;

export const StyledModalContent = styled.div`
	min-width: 37.5rem;
	min-height: 7rem;
	padding: 1.25rem;
`;
