import {
	ParticipantsByProjectIdOptions,
	ParticipantsResponse,
	useLazyGetParticipantsByProjectIdQuery,
} from 'store/api';
import { Participant, ApiParticipant } from 'common/types';
import { transformParticipantsResponse } from './mappers';
import { getParticipantsEmptyResponse } from 'store/api';
import { useCriteriaConfiguration } from './useCriteriaConfiguration';

export const useProjectParticipants = () => {
	const criteriaConfiguration = useCriteriaConfiguration();
	const emptyResponse = getParticipantsEmptyResponse();
	const [getParticipantsByProjectId, participantsByProjectId] = useLazyGetParticipantsByProjectIdQuery();

	const participants = getParticipantsData();

	return {
		getParticipants,
		participants: transform(participants),
	};

	function getParticipantsData(): ParticipantsResponse<ApiParticipant> {
		if (!participantsByProjectId.isSuccess) return emptyResponse;

		return participantsByProjectId.data;
	}

	async function getParticipants(option: ParticipantsByProjectIdOptions): Promise<ParticipantsResponse<Participant>> {
		const response = await getParticipantsByProjectId(option);

		if (!response || response.data === undefined) {
			return emptyResponse;
		}

		return transform(response.data);
	}

	function transform(participants: ParticipantsResponse<ApiParticipant>): ParticipantsResponse<Participant> {
		return transformParticipantsResponse(participants, criteriaConfiguration);
	}
};
