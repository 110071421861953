import { Cluster, ClusterId, DimensionCluster } from 'common/types';
import { useFrameworkDetails, useFrameworks } from 'common/hooks';
import { DefaultClusterId } from 'common/constants';

export const useClusterRepository = () => {
	const clusters = getClusters();

	return { clusters, findCluster, findIndexOf };

	function getClusters(): Cluster[] {
		const framework = useFrameworks().frameworks.find((f) => f.active);
		const { frameworkDetails } = useFrameworkDetails({ frameworkId: framework?.frameworkId });
		const onlyActive = (dimensionCluster: DimensionCluster) => dimensionCluster.selectedDimensions.length;
		return frameworkDetails?.clusters.filter(onlyActive).map((cluster) => cluster.cluster) ?? [];
	}

	function findCluster(id: ClusterId): Cluster {
		const cluster = clusters.find((cluster) => toNormalizedId(cluster.clusterId) === toNormalizedId(id));
		if (!cluster) {
			throw new Error('Cluster not found');
		}
		return cluster;
	}

	function findIndexOf(clusterId: ClusterId) {
		const cluster = findCluster(clusterId);
		return Object.keys(DefaultClusterId).findIndex((clusterId) => {
			return toNormalizedId(cluster.clusterId) === toNormalizedId(clusterId);
		});
	}

	function toNormalizedId(id: ClusterId) {
		return id.replace('-', '').toUpperCase();
	}
};
