import { PageLayout } from 'common/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ProjectFormLayoutProps } from './ProjectFormLayout.types';
import {
	AdditionalQuestions,
	AutomaticClosureConditions,
	ProjectDetails,
	ProjectFormSectionLayout,
} from 'features/ProjectConfiguration/components';

export const ProjectFormLayout = ({
	id,
	title,
	subtitle,
	includeAdditionalQuestions,
	saveTrigger,
	projectDetailsProps,
	automaticClosureConditionsProps,
	onSaveButtonClick,
}: ProjectFormLayoutProps) => {
	return (
		<PageLayout
			title={title}
			subtitle={subtitle}
			id={id}
		>
			<div>
				<ProjectFormSectionLayout title="Project Details">
					<ProjectDetails
						saveTrigger={saveTrigger}
						{...projectDetailsProps}
					/>
				</ProjectFormSectionLayout>
				<ProjectFormSectionLayout title="Automatic Closure Conditions">
					<AutomaticClosureConditions
						saveTrigger={saveTrigger}
						{...automaticClosureConditionsProps}
					/>
				</ProjectFormSectionLayout>
				{includeAdditionalQuestions && (
					<ProjectFormSectionLayout title="Additional Questions">
						<AdditionalQuestions />
					</ProjectFormSectionLayout>
				)}

				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
