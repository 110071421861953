import { useDispatch } from 'react-redux';
import { setQuadrantState } from 'store/slices/graphSlices';
import { ApiDistributionPerQuarter, ApiQuadrantResult } from 'store/api';
import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph/QuadrantGraph.types';
import { QuadrantStateCreator } from 'features/GraphDataSeparator/utils/QuadrantStateCreator';

export const useQuadrant = <T extends ApiDistributionPerQuarter>(
	apiData: ApiQuadrantResult<T>,
	legendCreator: QuadrantLegendCreator<T>,
) => {
	const dispatch = useDispatch();
	const quadrant = new QuadrantStateCreator(legendCreator).create(apiData);
	dispatch(setQuadrantState(quadrant));
};
