import { TableAction } from 'common/types';
import { Manager } from 'common/types';
import React, { useState } from 'react';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { ManagerAdminId } from './ManagerColumnId';
import { TableIconButton, TableWrapper } from 'features/Tables/components';
import { Tooltip } from 'common/components';

export const useManagerData = (openDeleteDialog: () => void) => {
	const [managerToDelete, setManagerToDelete] = useState<Manager>();
	const onDeleteManagerClick = (manager: Manager) => {
		setManagerToDelete(manager);
		openDeleteDialog();
	};

	const actions = React.useMemo<TableAction<Manager>[]>(
		() => [
			{
				label: 'Delete APD Mgr',
				icon: faTrashCan,
			},
		],
		[],
	);

	const columns = React.useMemo<ColumnDef<Manager>[]>(
		() => [
			{
				id: ManagerAdminId.Username,
				header: 'APD Mgr ID',
				accessorKey: 'username',
			},
			{
				id: ManagerAdminId.Name,
				header: 'Name',
				accessorKey: 'name',
				accessorFn: (row: Manager) => row.name,
			},
			{
				id: ManagerAdminId.Actions,
				header: 'Actions',
				accessorFn: (row) => row,
				cell: ({ getValue }) => {
					return (
						<TableWrapper>
							{actions.map((action) => {
								if (action.isHidden) return;
								const row = getValue<Manager>();
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											onClick={() => onDeleteManagerClick(row)}
											href={action.getHref ? action.getHref(row) : undefined}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
		],
		[],
	);

	return { columns, managerToDelete };
};
