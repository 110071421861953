import styled, { css } from 'styled-components';
import { StyledTextareaProps } from './FormTextarea.types';

const errorStyle = css`
	border-color: ${({ theme }) => theme.colors.error.main};
	color: ${({ theme }) => theme.colors.error.main};
`;

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
	display: flex;
	align-items: center;
	outline: none;
	resize: vertical;
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
	border: 1px solid;
	border-radius: 5px;
	border-color: ${({ theme }) => theme.colors.common.black};
	font-family: 'Mukta';
	font-weight: 400;
	font-size: 1rem;
	padding: 0.7rem;
	min-height: ${({ $minHeight }) => $minHeight};

	${({ $error }) => $error && errorStyle};
`;
