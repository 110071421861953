export const getCapitalizedText = (text: string): string => {
	const formatted = text.toLocaleLowerCase().replaceAll('_', ' ');
	const firstLetterIndex = 0;
	const secondLetterIndex = 1;
	const capitalizedText = formatted.charAt(firstLetterIndex).toLocaleUpperCase() + formatted.slice(secondLetterIndex);

	return capitalizedText;
};

export const emptyIfNullOrUndefined = (value: string | null | undefined): string => {
	return value || '';
};

export const undefinedIfEmptyString = (str: string) => {
	if (str === '') return;

	return str;
};
