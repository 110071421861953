/* eslint-disable no-magic-numbers */
import { RadarGraphConfig } from './RadarGraph.types';

export const radarGraphConfig: RadarGraphConfig = {
	radius: 250,
	categoryGridLines: {
		style: { stroke: '#7A7A7A', fill: 'none', 'stroke-width': '1.5px', 'stroke-linejoin': 'bevel' },
	},
	categoryLabels: {
		style: { 'font-family': 'Arial, "Liberation Sans"', 'font-weight': 'bold', stroke: 'none' },
		textSize: 14,
		width: 140,
	},
	header: {
		height: 70,
		style: {
			fill: '#3a3a3a',
			'font-family': 'Arial, "Liberation Sans"',
			'font-size': '16px',
			'font-weight': 'bold',
		},
	},
	legend: {
		height: 85,
		strokeWidth: 4,
		textLeftMargin: 5,
		textRightMargin: 15,
		textSize: 13,
		textStyle: { 'font-weight': 'bold', 'font-family': 'Arial, "Liberation Sans"', fill: '#000000' },
		topMargin: 80,
	},
	valueAxis: {
		domain: [0, 5],
		circleStyle: {
			stroke: '#989898',
			fill: 'none',
			'stroke-width': '1px',
			'stroke-linejoin': 'bevel',
			'stroke-dasharray': '3.0, 3.0',
		},
		lineStyle: { stroke: '#808080', fill: 'none', 'stroke-width': '0.5px' },
		textStyle: {
			'font-size': '10px',
			'font-family': 'Arial, "Liberation Sans"',
			'stroke-width': 0,
			fill: '#000000',
		},
		ticks: 10,
	},
	series: {
		dashedLineStyle: {
			fill: 'none',
			'stroke-width': '3px',
			'stroke-linejoin': 'bevel',
			'stroke-dasharray': '8.0, 6.0',
		},
		solidLineStyle: {
			fill: 'none',
			'stroke-width': '3px',
			'stroke-linejoin': 'bevel',
		},
	},
};
