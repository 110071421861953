import { RegexPatterns } from 'common/constants';
import { RequiredFormInputState } from 'common/types';
import { emptyIfNullOrUndefined, getUTCDateFromString, parseDateString } from 'common/utils';
import { PasswordValidator } from 'features/AuthManagement/views/ChangePasswordBox/utils';

export const createFormState = (value?: string | null): RequiredFormInputState => ({
	value: emptyIfNullOrUndefined(value),
	error: false,
	errorText: '',
});

const handleInvalidFormValue = (
	value: string,
	errorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	stateSetter({
		value: value,
		error: true,
		errorText,
	});
};

const setValidFormValue = (value: string, stateSetter: (state: RequiredFormInputState) => void) => {
	stateSetter({
		value: value,
		error: false,
		errorText: '',
	});
};

export const handleRequiredFormValue = (
	value: string,
	errorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	const normalizedValue = value.replace(RegexPatterns.AllWhitespaceChars, '');

	if (!normalizedValue) {
		handleInvalidFormValue(value, errorText, stateSetter);
		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleRequiredFormTelephoneBlur = (
	value: string,
	requiredErrorText: string,
	formatErrorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		handleInvalidFormValue(value, requiredErrorText, stateSetter);

		return;
	}

	const incorrectFormat = !value.match(RegexPatterns.InternationalTelephoneFormat);

	if (incorrectFormat) {
		handleInvalidFormValue(value, formatErrorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleFormTelephoneBlur = (
	value: string,
	errorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		return;
	}

	const incorrectFormat = !value.match(RegexPatterns.InternationalTelephoneFormat);

	if (incorrectFormat) {
		handleInvalidFormValue(value, errorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleRequiredFormMailBlur = (
	value: string,
	requiredErrorText: string,
	formatErrorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		handleInvalidFormValue(value, requiredErrorText, stateSetter);

		return;
	}

	const incorrectFormat = !value.match(RegexPatterns.Email);

	if (incorrectFormat) {
		handleInvalidFormValue(value, formatErrorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleFormMailBlur = (
	value: string,
	errorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		return;
	}

	const incorrectFormat = !value.match(RegexPatterns.Email);

	if (incorrectFormat) {
		handleInvalidFormValue(value, errorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleRequiredFormPasswordBlur = (
	value: string,
	requiredErrorText: string,
	notMatchErrorText: string,
	validator: PasswordValidator,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		handleInvalidFormValue(value, requiredErrorText, stateSetter);

		return;
	}

	const passwordValidationResult = validator.validatePassword(value);

	if (!passwordValidationResult.success) {
		handleInvalidFormValue(value, notMatchErrorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handleRequiredFormRepeatPasswordBlur = (
	passwordValue: string,
	value: string,
	requiredErrorText: string,
	formatErrorText: string,
	validator: PasswordValidator,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		handleInvalidFormValue(value, requiredErrorText, stateSetter);

		return;
	}

	const passwordValidationResult = validator.comparePasswords(passwordValue, value);

	if (!passwordValidationResult.success) {
		handleInvalidFormValue(value, formatErrorText, stateSetter);

		return;
	}

	setValidFormValue(value, stateSetter);
};

const checkDateValidation = (value: string, minDate: Date, maxDate: Date) => {
	if (!value) return false;

	const normalizedDateString = value.replace(RegexPatterns.AllWhitespaceChars, '');
	const filterDate = parseDateString(normalizedDateString);
	if (!filterDate) return false;

	if (filterDate < minDate) return false;
	if (filterDate > maxDate) return false;

	return true;
};

const validateDatesRelation = (
	startDateString: string,
	endDateString: string,
	invalidDateErrorText: string,
	startDateSetter: (state: RequiredFormInputState) => void,
	endDateSetter: (state: RequiredFormInputState) => void,
) => {
	const utcStartDate = getUTCDateFromString(startDateString);
	const utcEndDate = getUTCDateFromString(endDateString);
	if (!utcStartDate || !utcEndDate) return;

	if (utcStartDate >= utcEndDate) {
		handleInvalidFormValue(startDateString, invalidDateErrorText, startDateSetter);
		handleInvalidFormValue(endDateString, invalidDateErrorText, endDateSetter);
		return;
	}

	setValidFormValue(startDateString, startDateSetter);
	setValidFormValue(endDateString, endDateSetter);
};

export const handleRequiredFormRangedRelatedDateBlur = (
	value: string,
	startDateString: string,
	endDateString: string,
	minDate: Date,
	maxDate: Date,
	requiredErrorText: string,
	tooEarlyErrorText: string,
	tooLateErrorText: string,
	invalidDateErrorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
	startDateSetter: (state: RequiredFormInputState) => void,
	endDateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		handleInvalidFormValue(value, requiredErrorText, stateSetter);
		return;
	}

	const normalizedDateString = value.replace(RegexPatterns.AllWhitespaceChars, '');
	const filterDate = parseDateString(normalizedDateString);
	if (!filterDate) {
		handleInvalidFormValue(value, invalidDateErrorText, stateSetter);
		return;
	}

	if (filterDate < minDate) {
		handleInvalidFormValue(value, tooEarlyErrorText, stateSetter);
		return;
	}

	if (filterDate > maxDate) {
		handleInvalidFormValue(value, tooLateErrorText, stateSetter);
		return;
	}

	setValidFormValue(value, stateSetter);

	const isStartDateValid = checkDateValidation(startDateString, minDate, maxDate);
	const isEndDateValid = checkDateValidation(endDateString, minDate, maxDate);
	if (!isStartDateValid || !isEndDateValid) return;

	validateDatesRelation(startDateString, endDateString, invalidDateErrorText, startDateSetter, endDateSetter);
};

export const handleOnlyFutureDateBlur = (
	value: string,
	minDate: Date,
	invalidDateErrorText: string,
	tooEarlyErrorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		setValidFormValue(value, stateSetter);
		return;
	}

	const normalizedDateString = value.replace(RegexPatterns.AllWhitespaceChars, '');
	const filterDate = parseDateString(normalizedDateString);
	if (!filterDate) {
		handleInvalidFormValue(value, invalidDateErrorText, stateSetter);
		return;
	}

	if (filterDate < minDate) {
		handleInvalidFormValue(value, tooEarlyErrorText, stateSetter);
		return;
	}

	setValidFormValue(value, stateSetter);
};

export const handlePercentageNumberFormBlur = (
	value: string,
	notInRangeErrorText: string,
	stateSetter: (state: RequiredFormInputState) => void,
) => {
	if (!value) {
		setValidFormValue(value, stateSetter);
		return;
	}

	const numberValue = Number(value);
	const minPercentage = 1;
	const maxPercentage = 100;
	if (numberValue < minPercentage || numberValue > maxPercentage) {
		handleInvalidFormValue(value, notInRangeErrorText, stateSetter);
		return;
	}

	setValidFormValue(value, stateSetter);
};
