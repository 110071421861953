import { InputProps } from './Input.types';
import {
	Container,
	ErrorText,
	InputContainer,
	InputLabel,
	InputWrapper,
	StyledEndElement,
	StyledInput,
	CheckboxContainer,
} from './styled';
import { Checkbox } from 'common/components';

export const Input = ({
	name,
	errorText,
	inputRef,
	error = false,
	errorPlacement = 'bottom',
	errorTextAlwaysVisible = false,
	disabled = false,
	readOnly = false,
	disabledErrorBorder = false,
	fullWidth = false,
	labelPlacement = 'left',
	labelTextTransform = 'capitalize',
	type = 'text',
	endElement,
	onChange,
	placeholder,
	value,
	className,
	required,
	formInput = false,
	checkbox = false,
	disableCheckbox = false,
	onCheckboxChange,
	index,
	checked = false,
	additionalIcon,
	...htmlInputProps
}: InputProps) => {
	const InputLabelElement = (
		<>
			{name && (
				<InputLabel
					htmlFor={name}
					$labelPlacement={labelPlacement}
					$labelTextTransform={labelTextTransform}
					$error={error}
					$isErrorTextDeclared={!!errorText}
					$formInput={formInput}
					$checkbox={checkbox}
					onClick={(event) => {
						if (disabled) event.preventDefault();
					}}
				>
					{`${name}${required && !disabled ? '*' : ''}`}
				</InputLabel>
			)}
		</>
	);

	const CheckboxElement = (
		<>
			{checkbox && (
				<CheckboxContainer>
					<Checkbox
						id={`${index}-checkbox`}
						checked={checked}
						onChange={() => {
							onCheckboxChange?.(index as number, !checked);
						}}
						disabled={disabled || disableCheckbox}
					/>
				</CheckboxContainer>
			)}
		</>
	);

	const InputElement = (
		<>
			<InputContainer
				$disabled={disabled}
				$readOnly={readOnly}
				$error={error}
				$errorPlacement={errorPlacement}
				$disabledErrorBorder={disabledErrorBorder}
				$fullWidth={fullWidth}
				$labelPlacement={labelPlacement}
				$disableMargin={name === undefined}
				$formInput={formInput}
			>
				<StyledInput
					{...htmlInputProps}
					ref={inputRef}
					id={name}
					readOnly={readOnly}
					type={type}
					name={name}
					onChange={onChange}
					placeholder={disabled ? '' : placeholder}
					value={value}
				/>
				{endElement && <StyledEndElement>{endElement}</StyledEndElement>}
			</InputContainer>
		</>
	);

	const ErrorTextElement = (
		<ErrorText
			$errorTextAlwaysVisible={errorTextAlwaysVisible}
			$error={error}
			$errorPlacement={errorPlacement}
			$isErrorTextDeclared={!!errorText}
		>
			{checkbox && CheckboxElement}
			{(error && errorText) || (errorTextAlwaysVisible && errorText)}
		</ErrorText>
	);

	return (
		<Container
			className={className}
			$labelPlacement={labelPlacement}
			$formInput={formInput}
		>
			{InputLabelElement}
			<InputWrapper
				$errorPlacement={errorPlacement}
				$formInput={formInput}
				$checkbox={checkbox}
				$additionalIcon={!!additionalIcon}
			>
				{InputElement}
				{ErrorTextElement}
				{additionalIcon}
			</InputWrapper>
		</Container>
	);
};
