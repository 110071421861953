import { ApiGraphResponseClusteredResult, ApiGraphResponseResults } from 'store/api';
import { createCluster } from 'features/Graphs/TensionGraph';
import { toClusterMap } from 'features/Graphs/TensionGraph';
import { ClusterId } from 'common/types';

export function createDataWrapper(result: ApiGraphResponseResults) {
	const byCluster = toClusterMap(result);

	const findResults = (clusterId: ClusterId): ApiGraphResponseClusteredResult => {
		const cluster = byCluster.get(clusterId);
		if (cluster === undefined) {
			throw Error();
		}
		return cluster.clusteredResults;
	};

	const findByCluster = (clusterId: ClusterId) => createCluster(findResults(clusterId));

	return { findByCluster };
}
