import {
	ApiDimensionCluster,
	ApiFramework,
	ApiFrameworkDetails,
	Cluster,
	DefaultClusterName,
	Dimension,
	DimensionCluster,
	Framework,
	FrameworkDetails,
} from 'common/types';
import { AllClustersResponse, AllDimensionsResponse, AllFrameworksResponse, FrameworkDetailsResponse } from 'store/api';

const mapApiSelectedDimensionsForCluster = (
	apiDimensionCluster: ApiDimensionCluster | undefined,
	dimensions: Dimension[],
): Dimension[] => {
	if (!apiDimensionCluster) return [];
	return dimensions.filter((dimension) => {
		const isDimensionSelected = apiDimensionCluster.selectedDimensions.some(
			(apiSelectedDimension) => apiSelectedDimension.id === dimension.dimensionId,
		);
		return isDimensionSelected;
	});
};

const mapApiDimensionClusters = (
	apiDimensionClusters: ApiDimensionCluster[],
	clusters: Cluster[],
	dimensions: Dimension[],
): DimensionCluster[] => {
	const mappedClusters: DimensionCluster[] = clusters.map((cluster) => {
		const matchedCluster = apiDimensionClusters.find(
			(apiDimensionCluster) => apiDimensionCluster.index.id === cluster.clusterId,
		);
		const mappedSelectedDimensionsForCluster = mapApiSelectedDimensionsForCluster(matchedCluster, dimensions);
		return {
			cluster: matchedCluster
				? {
						clusterId: matchedCluster.index.id,
						name: matchedCluster.name,
						defaultName: matchedCluster.index.id as DefaultClusterName,
				  }
				: cluster,
			selectedDimensions: mappedSelectedDimensionsForCluster,
		};
	});

	return mappedClusters;
};

export const mapGetFrameworkDetailsResponse = (
	apiFrameworkDetails: FrameworkDetailsResponse<ApiFrameworkDetails>,
	clusters: AllClustersResponse<Cluster>,
	dimensions: AllDimensionsResponse<Dimension>,
): FrameworkDetailsResponse<FrameworkDetails> => {
	const allDimensions: Dimension[] = [];
	dimensions.forEach((dimension) => allDimensions.push(...Object.values(dimension)));

	const { id: _id, ...apiFrameworkDetailsCopy } = apiFrameworkDetails;
	const mappedFrameworkDetails: FrameworkDetails = {
		...apiFrameworkDetailsCopy,
		frameworkId: _id,
		clusters: mapApiDimensionClusters(apiFrameworkDetails.clusters, clusters, allDimensions),
	};

	return mappedFrameworkDetails;
};

export const mapGetAllFrameworkResponse = (
	response: AllFrameworksResponse<ApiFramework>,
): AllFrameworksResponse<Framework> => {
	const apiFrameworks = response;

	const mappedFrameworks: Framework[] = apiFrameworks.map((apiFramework) => {
		const { id: _id, ...apiFrameworkCopy } = apiFramework;
		return {
			...apiFrameworkCopy,
			frameworkId: _id,
		};
	});

	return mappedFrameworks;
};
