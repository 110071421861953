import { useEffect, useState } from 'react';
import {
	ColumnDef,
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	PaginationState,
	Row,
	RowSelectionState,
	SortingState,
	TableOptions,
	useReactTable,
} from '@tanstack/react-table';
import { TableProps } from './Table.types';
import { TableColumnSelector } from 'common/components';
import { TableContent } from './TableContent';
import { TablePagination } from './TablePagination';
import { TableSortingDirections } from 'common/constants';
import { TableSortingDirection, TableQueryParams, SelectableRowIds } from 'common/types';
import { useDebounce, useSuspenseManually } from 'common/hooks';

const firstPageIndex = 0;
const one = 1;
export const Table = <T extends object & SelectableRowIds>({
	tableTypeId,
	visibleColumns,
	disableColumnSelector,
	columns,
	data,
	onRowSelection,
	currentPageIndex = firstPageIndex,
	pageCount = firstPageIndex,
	onQueryChange,
	variant = 'primary',
	dynamicColumnNumber,
	dynamicColumnName,
	queryTrigger,
	initialSorting,
	onColumnSelection,
}: TableProps<T>) => {
	const initVisibleColumns = disableColumnSelector === true ? columns : visibleColumns;
	const [selectedColumns, setSelectedColumns] = useState<ColumnDef<T>[]>(initVisibleColumns);
	const [idRowSelection, setIdRowSelection] = useState<RowSelectionState>({});
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: currentPageIndex,
		pageSize: 50,
	});

	const [sorting, setSorting] = useState<SortingState>(
		initialSorting || [
			{
				id: '',
				desc: false,
			},
		],
	);

	const debouncedColumnFilters = useDebounce(columnFilters);

	const suspense = useSuspenseManually();

	useEffect(() => refreshSelectedColumns(selectedColumns), whenDataChanges());

	useEffect(() => refreshSelectedColumns(initVisibleColumns), whenInitialSelectionChanges());

	useEffect(executeOnQueryParamsChange, [queryTrigger, pagination]);

	useEffect(executeOnRowSelection, [idRowSelection, setIdRowSelection]);

	useEffect(executeOnQueryParamsChange, [initialSorting]);

	const firstPage = 1;
	const onePage = 1;
	const noPages = 0;
	const isDataPageable = pageCount !== firstPage && pageCount !== noPages;
	const shouldPaginationBeVisible = isDataPageable;
	const table = useReactTable<T>(createTableConfiguration());
	const shouldColumnSelectorBeVisible = !disableColumnSelector;

	useEffect(resetPagination, [debouncedColumnFilters, sorting, shouldPaginationBeVisible]);
	useEffect(() => {
		if (pageCount && pageCount > noPages && pagination.pageIndex > firstPage) {
			goBackOnePage();
		}
	}, [pageCount]);

	return (
		<>
			{shouldColumnSelectorBeVisible && (
				<TableColumnSelector
					selected={selectedColumns}
					columns={columns}
					onChange={(columns) => {
						setSelectedColumns(columns);
						onColumnSelection && onColumnSelection(columns);
					}}
				/>
			)}
			<TableContent<T>
				table={table}
				variant={variant}
				dynamicColumnNumber={dynamicColumnNumber}
				dynamicColumnName={dynamicColumnName}
			/>
			{shouldPaginationBeVisible && (
				<TablePagination
					id={`${tableTypeId}_pagination`}
					canGoToNextPage={table.getCanNextPage()}
					canGoToPreviousPage={table.getCanPreviousPage()}
					currentPageIndex={pagination.pageIndex}
					totalPages={pageCount}
					goToFirstPage={() => table.setPageIndex(firstPageIndex)}
					goToLastPage={() => table.setPageIndex(pageCount - one)}
					goToNextPage={() => table.nextPage()}
					goToPreviousPage={() => table.previousPage()}
				/>
			)}
		</>
	);

	function createTableConfiguration(): TableOptions<T> {
		const getRowId = (row: T, index: number, _parent?: Row<T> | undefined): string => {
			if (row.participantId) {
				return row.participantId;
			}

			return index.toString();
		};

		return {
			data,
			columns: selectedColumns,
			state: {
				sorting,
				pagination,
				columnFilters,
				rowSelection: idRowSelection,
			},
			pageCount: pageCount,
			enableSortingRemoval: false,
			enableMultiRowSelection: true,
			enableRowSelection: true,
			manualSorting: true,
			manualFiltering: true,
			manualPagination: true,
			onSortingChange: setSorting,
			getRowId: getRowId,
			onPaginationChange: setPagination,
			onRowSelectionChange: setIdRowSelection,
			onColumnFiltersChange: setColumnFilters,
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
		};
	}

	function whenDataChanges() {
		return [queryTrigger, initVisibleColumns, data];
	}

	function whenInitialSelectionChanges() {
		function stringifyColumnIds(initVisibleColumns: ColumnDef<T, unknown>[] | undefined) {
			return initVisibleColumns?.map((h) => h.id).join();
		}

		return [stringifyColumnIds(initVisibleColumns)];
	}

	function executeOnRowSelection() {
		if (!onRowSelection) {
			return;
		}

		onRowSelection(table.getState().rowSelection);
	}

	function executeOnQueryParamsChange() {
		if (!onQueryChange) return;
		suspense.turnOn();
		onQueryChange(getQueryParams());
		suspense.turnOff();
	}

	function getQueryParams() {
		const singleColumnSortIndex = 0;
		const { id: orderBy, desc } = sorting[singleColumnSortIndex];
		const { pageIndex: pageNumber, pageSize: size } = pagination;
		const sortingDirection: TableSortingDirection = desc ? TableSortingDirections.Desc : TableSortingDirections.Asc;

		const tableQueryParams: TableQueryParams = {
			direction: sortingDirection,
			filters: [...columnFilters],
			pageNumber,
			orderBy,
			size,
		};

		return tableQueryParams;
	}

	function resetPagination() {
		setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
	}

	function goBackOnePage() {
		setPagination({ pageIndex: pagination.pageIndex - onePage, pageSize: pagination.pageSize });
	}

	function refreshSelectedColumns(selected: ColumnDef<T>[]) {
		const findNewColumnById = (id: string) => {
			return columns.find((col) => col.id === id);
		};
		const onlyThoseThatExists = (found: ColumnDef<T> | undefined): found is ColumnDef<T> => found != null;
		const getId = (column: ColumnDef<T>) => column.id as string;

		const columnsBasedOnPreviousSelection = selected.map(getId).map(findNewColumnById).filter(onlyThoseThatExists);
		setSelectedColumns(columnsBasedOnPreviousSelection);
	}
};
