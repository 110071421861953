import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrganisationAddressState, setOrganisationAddressState } from 'store/slices';
import { handleRequiredFormValue, createFormState } from 'common/utils';
import { OrganisationAddressProps } from './OrganisationAddress.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const OrganisationAddress = ({
	saveTrigger,
	disableCountry,
	country: initialCountry,
	firstAddressLine: initialFirstAddressLine,
	secondAddressLine: initialSecondAddressLine,
	city: initialCity,
	postcodeZIP: initialPostcodeZIP,
}: OrganisationAddressProps) => {
	const [firstAddress, setFirstAddress] = useState(emptyIfNullOrUndefined(initialFirstAddressLine));
	const [secondAddress, setSecondAddress] = useState(emptyIfNullOrUndefined(initialSecondAddressLine));

	const [country, setCountry] = useState<RequiredFormInputState>(createFormState(initialCountry));

	const [city, setCity] = useState<RequiredFormInputState>(createFormState(initialCity));

	const [postcode, setPostcode] = useState<RequiredFormInputState>(createFormState(initialPostcodeZIP));

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(country.value, 'Country is required', setCountry);
		handleRequiredFormValue(city.value, 'City is required!', setCity);
		handleRequiredFormValue(postcode.value, 'Zip code is required!', setPostcode);
	};

	const setSliceState = () => {
		const sliceState: OrganisationAddressState = {
			city: city.value,
			country: country.value,
			firstAddress: firstAddress,
			postcode: postcode.value,
			secondAddress: secondAddress,
		};

		dispatch(setOrganisationAddressState(sliceState));
	};

	return (
		<>
			<Input
				{...country}
				name="Country"
				maxLength={50}
				errorPlacement="right"
				disabled={disableCountry}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Country is required!', setCountry);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Country is required!', setCountry);
					setSliceState();
				}}
			/>
			<Input
				name="Address line 1"
				placeholder="e.g. Building, Floor"
				maxLength={30}
				value={firstAddress}
				formInput
				onChange={(event) => {
					setFirstAddress(event.target.value);
				}}
				onBlur={(event) => {
					setFirstAddress(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				name="Address line 2"
				placeholder="e.g. Street"
				maxLength={50}
				value={secondAddress}
				formInput
				onChange={(event) => {
					setSecondAddress(event.target.value);
				}}
				onBlur={(event) => {
					setSecondAddress(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				{...city}
				name="City"
				placeholder="If applicable add State"
				maxLength={50}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'City is required!', setCity);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'City is required!', setCity);
					setSliceState();
				}}
			/>
			<Input
				{...postcode}
				name="Postcode / ZIP"
				maxLength={50}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Zip code is required!', setPostcode);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Zip code is required!', setPostcode);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
