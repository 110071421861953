import { useParams } from 'react-router-dom';
import { useGetCriteriaConfigurationQuery, useUpdateCriteriaConfigurationMutation, CriterionSetting } from 'store/api/';

export const useCriteriaConfigurationSettings = () => {
	const { tenantId } = useParams();
	const apiCriteriaConfiguration = useGetCriteriaConfigurationQuery({ tenantId: tenantId || '' });
	const [updateCriteriaConfigurationMutation] = useUpdateCriteriaConfigurationMutation();

	const getCriteriaConfigurationData = () => {
		if (!apiCriteriaConfiguration.isSuccess) return undefined;

		return apiCriteriaConfiguration.data;
	};

	const updateCriteriaConfiguration = (criteria: CriterionSetting[]) => {
		return updateCriteriaConfigurationMutation({ tenantId: tenantId || '', criteria });
	};

	const criteriaConfiguration = getCriteriaConfigurationData();

	return { criteriaConfiguration, updateCriteriaConfiguration };
};
