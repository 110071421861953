import { IconButton } from 'common/components/IconButton';
import styled from 'styled-components';
import { StyledColorPaletteButton } from '../ChartLineSettingsItem/ChartLineSettingsItem.types';

export const ColorPaletteButton = styled(IconButton)<StyledColorPaletteButton>`
	height: 1.75rem;
	width: 1.75rem;
	background-color: ${({ $color }) => $color};
	border-radius: 5px;
	color: ${({ theme }) => theme.colors.common.white};

	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.common.orange};
	}

	& > * {
		display: ${({ $isSelected }) => !$isSelected && 'none'};
	}
`;

export const ColorPaletteContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-row-gap: 0.3rem;
	grid-column-gap: 0.2rem;

	padding: 0.8rem;
	margin-top: -0.5rem;
	border: 2px solid ${({ theme }) => theme.colors.common.mediumGrey};
	border-radius: 0px 10px 10px 10px;
	background-color: ${({ theme }) => theme.colors.common.white};
`;
