import {
	useOdnaPieChartData,
	useOPdnaPieChartData,
	usePdnaPieChartData,
	usePieChartCreator,
} from 'features/Graphs/PieChart';
import {
	CommonColorPresets,
	DispersionChartColorPresets,
	HeatmapGraphColorPresets,
	RadarGraphData,
	useOdnaRadarGraphData,
	useOPdnaRadarGraphData,
	usePdnaRadarGraphData,
	useRadarGraphCreator,
} from 'features/Graphs';
import {
	accuracyChartConfig,
	AccuracyChartData,
	useAccuracyChartCreator,
	useOdnaAccuracyChartData,
	useOPdnaAccuracyChartData,
	usePdnaAccuracyChartData,
} from 'features/Graphs/AccuracyChart';
import { useHeatmapCreator, useHeatmapGraphData } from 'features/Graphs/HeatmapGraph';
import { useQuadrantColorPreset, useQuadrantGraphCreator } from 'features/Graphs/QuadrantGraph/hooks';
import { useDispersionChartCreator, useDispersionChartData } from 'features/Graphs/DispersionChart';
import { dispersionChartConfig } from 'features/Graphs/DispersionChart/DispersionChartConfig';
import SvgElement from '@support/alderbrooke-d3-graphs/dist/Common/SvgElement';
import { heatmapGraphConfig } from 'features/Graphs/HeatmapGraph/HeatmapGraphConfig';
import { useQuadrantChartConfig } from 'features/Graphs/QuadrantGraph/hooks/useQuadrantChartConfig';
import { QuadrantCenter } from '@support/alderbrooke-d3-graphs/dist/Quadrant/QuadrantCenter';
import { pieChartConfig } from 'features/Graphs/PieChart/PieChartConfig';
import { radarGraphConfig } from 'features/Graphs/RadarGraph/RadarGraphConfig';
import { SvgGraphs } from 'store/api/graphsExportApi';
import { GraphBase } from 'features/Graphs/PptxExportButton';
import IDataSeries from '@support/alderbrooke-d3-graphs/dist/Curve/IDataSeries';

export const useSvgGraphsFactory = (
	graphBase: GraphBase,
	combinedMode: boolean,
	clusterIndex: number,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
	colorPresetId: number,
	quadrantCenter: QuadrantCenter,
): SvgGraphs => {
	const colorPreset = CommonColorPresets[colorPresetId];

	return {
		dataDistribution: dispersionChart(),
		heatMap: heatMapGraph(),
		quadrant: quadrantGraph(),

		indicatorWithAllOrganisationAndPersonalBenchmarks: opdnaPieChart(),
		indicatorWithAllOrganisationBenchmarks: odnaPieChart(),
		indicatorWithAllPersonalBenchmarks: pdnaPieChart(),

		radarWithAllOrganisationAndPersonalBenchmarks: opdnaRadarGraph(),
		radarWithAllOrganisationBenchmarks: odnaRadarGraph(),
		radarWithAllPersonalBenchmarks: pdnaRadarGraph(),

		responseQualityWithAllOrganisationAndPersonalBenchmarks: opdnaAccuracyChart(),
		responseQualityWithAllOrganisationBenchmarks: odnaAccuracyChart(),
		responseQualityWithAllPersonalBenchmarks: pdnaAccuracyChart(),
	};

	function dispersionChart() {
		const dispersionChartData = useDispersionChartData(
			graphBase.dispersionChartBase,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);

		const colorConfig = DispersionChartColorPresets[colorPresetId];
		return toSvg(
			useDispersionChartCreator(dispersionChartData, dispersionChartConfig, colorConfig).createGraphExport(),
		);
	}

	function heatMapGraph() {
		const doNothingOnClick = () => {
			return;
		};
		const heatmapGraphData = useHeatmapGraphData(
			graphBase.heatmapChartBase,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
			doNothingOnClick,
		);
		const heatmapColorConfig = HeatmapGraphColorPresets[colorPresetId];
		return toSvg(useHeatmapCreator(heatmapGraphConfig, heatmapGraphData, heatmapColorConfig).createGraphExport());
	}

	function quadrantGraph() {
		const dotColorConfig = useQuadrantColorPreset(colorPresetId);
		const quadrantChartConfig = useQuadrantChartConfig();
		const quadrant = graphBase.quadrantBase;
		const quadrantData = quadrant.clusters[clusterIndex].data;

		return toSvg(useQuadrantGraphCreator(quadrantChartConfig, quadrantCenter, quadrantData, dotColorConfig)());
	}

	function opdnaPieChart() {
		const data = useOPdnaPieChartData(
			graphBase.pieChartBase,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createPieChart(data);
		return onlyWhenCombined(svg);
	}

	function odnaPieChart() {
		const data = useOdnaPieChartData(
			graphBase.pieChartBase.odna,
			clusterIndex,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createPieChart(data);
		return onlyWhenNotCombined(svg);
	}

	function pdnaPieChart() {
		const data = usePdnaPieChartData(
			graphBase.pieChartBase.pdna,
			clusterIndex,
			pdnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createPieChart(data);
		return onlyWhenNotCombined(svg);
	}

	function createPieChart(data: IDataSeries[]) {
		return toSvg(usePieChartCreator(pieChartConfig, data, colorPreset).createExportGraph());
	}

	function opdnaRadarGraph() {
		const data = useOPdnaRadarGraphData(
			graphBase.radarGraphBase,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createRadarGraph(data);
		return onlyWhenCombined(svg);
	}

	function odnaRadarGraph() {
		const data = useOdnaRadarGraphData(
			graphBase.radarGraphBase.odna,
			clusterIndex,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createRadarGraph(data);
		return onlyWhenNotCombined(svg);
	}

	function pdnaRadarGraph() {
		const data = usePdnaRadarGraphData(
			graphBase.radarGraphBase.pdna,
			clusterIndex,
			pdnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createRadarGraph(data);
		return onlyWhenNotCombined(svg);
	}

	function createRadarGraph(data: RadarGraphData) {
		return toSvg(useRadarGraphCreator(radarGraphConfig, data).createExportGraph());
	}

	function opdnaAccuracyChart() {
		const data = useOPdnaAccuracyChartData(
			graphBase.accuracyGraphBase,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
		);
		const svg = createAccuracyChart(data);
		return onlyWhenCombined(svg);
	}

	function odnaAccuracyChart() {
		const data = useOdnaAccuracyChartData(
			graphBase.accuracyGraphBase.odna,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
		);
		const svg = createAccuracyChart(data);
		return onlyWhenNotCombined(svg);
	}

	function pdnaAccuracyChart() {
		const data = usePdnaAccuracyChartData(
			graphBase.accuracyGraphBase.pdna,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
		);
		const svg = createAccuracyChart(data);
		return onlyWhenNotCombined(svg);
	}

	function createAccuracyChart(data: AccuracyChartData) {
		return toSvg(useAccuracyChartCreator(data, accuracyChartConfig).createGraphExport());
	}

	function onlyWhenNotCombined(svg: string) {
		return combinedMode ? undefined : svg;
	}

	function onlyWhenCombined(svg: string) {
		return combinedMode ? svg : undefined;
	}

	function toSvg(element: SvgElement) {
		const svg = element.getSvgAsXml();
		element.dispose();
		return svg;
	}
};
