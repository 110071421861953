import { PageLayout } from 'common/components';
import { FilePickerForm } from 'common/components';
import { NotificationTypes, useNotifications } from 'common/components';
import { useEditConfigurationTooltipMutation, useGetConfigurationTooltipQuery } from 'store/api';
import { useParams } from 'react-router-dom';
import { HttpResponseStatusCodes } from 'common/types';
import { useEffect, useState } from 'react';

export const ClientUploadDimensionTooltips = () => {
	const { pushNotification } = useNotifications();
	const [apiConfigurationTooltipSave] = useEditConfigurationTooltipMutation();
	const { tenantId } = useParams();
	const [tooltipFileName, setTooltipFileName] = useState('');
	if (tenantId) {
		const configurationTooltipDetails = useGetConfigurationTooltipQuery(tenantId);
		const configurationFileName = configurationTooltipDetails.data?.filename;

		useEffect(() => {
			if (typeof configurationFileName === 'string') {
				setTooltipFileName(configurationFileName ?? '');
			}
		}, [configurationFileName]);
	}

	const notifyAboutSuccessUploadedLogo = () => {
		pushNotification({
			message: 'Dimension tooltips configuration file uploaded successfully.',
			type: NotificationTypes.Success,
		});
	};

	const notifyAboutFailure = (message: string) => {
		pushNotification({
			message,
			type: NotificationTypes.Error,
		});
	};

	const uploadConfigurationTooltip = async (file: FormData) => {
		try {
			if (tenantId) {
				const result = await apiConfigurationTooltipSave({ file, tenantId }).unwrap();
				if (!result || result.status !== HttpResponseStatusCodes.BAD_REQUEST) {
					notifyAboutSuccessUploadedLogo();
				} else {
					notifyAboutFailure(result.message);
				}
			}
		} catch {
			notifyAboutFailure('Something went wrong');
		}
	};

	return (
		<PageLayout
			title="Upload Dimension Tooltips Configuration"
			id="client-dimension-tooltips-configuration"
		>
			{tenantId && (
				<FilePickerForm
					labelName="Current Dimension Tooltips Configuration File"
					previewName={tooltipFileName || 'Unspecified'}
					inputLabel="Dimension Tooltips Configuration File"
					placeholderTitle="Choose file..."
					buttonName="Save Dimension Tooltips Configuration"
					fileType=".xlsx"
					onFileSave={uploadConfigurationTooltip}
					underline={false}
				/>
			)}
		</PageLayout>
	);
};
