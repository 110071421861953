import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { DeleteRoadmapConfirmDialogProps } from './DeleteFrameworkConfirmDialog.types';
import { useDeleteRoadmapMutation } from 'store/api/roadmapApi/roadmapApi';

export const DeleteRoadmapConfirmDialog = ({
	isOpen,
	roadmapId,
	roadmapName,
	onClose,
	...additionalModalProps
}: DeleteRoadmapConfirmDialogProps) => {
	const [deleteFramework] = useDeleteRoadmapMutation();
	const { pushNotification } = useNotifications();

	const onDeleteFramework = async () => {
		try {
			await deleteFramework({ id: roadmapId }).unwrap();
			pushNotification({
				message: 'Roadmap successfully deleted',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Something went wrong during last operation!',
				type: NotificationTypes.Error,
			});
		}
	};

	return (
		<ConfirmationDialog
			id="deleting-roadmap-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onDeleteFramework}
			title={`Remove the roadmap '${roadmapName}'?`}
			{...additionalModalProps}
		/>
	);
};
