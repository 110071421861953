import { FormDateInput } from 'common/components';
import { DateFormatPatterns, DateBilingReportPickerRange } from 'common/constants';
import { createFormState, getUTCDateFromString, handleRequiredFormRangedRelatedDateBlur } from 'common/utils';
import { format, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BillingReportSlice, setBillingReportState } from 'store/slices';
import { BillingReportFormProps } from './BillingReportForm.types';

export const BillingReportForm = ({ saveTrigger }: BillingReportFormProps) => {
	const [startDate, setStartDate] = useState(createFormState());
	const [endDate, setEndDate] = useState(createFormState());
	const minDate = DateBilingReportPickerRange.MinDate;
	const maxDate = DateBilingReportPickerRange.MaxDate;

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormRangedRelatedDateBlur(
			startDate.value,
			startDate.value,
			endDate.value,
			minDate,
			maxDate,
			'Start Date is required!',
			`Chosen date should be after ${formattedMinDate}!`,
			`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
			'Invalid date format!',
			setStartDate,
			setStartDate,
			setEndDate,
		);

		handleRequiredFormRangedRelatedDateBlur(
			endDate.value,
			startDate.value,
			endDate.value,
			minDate,
			maxDate,
			'End Date is required!',
			`Chosen date should be after ${formattedMinDate}!`,
			`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
			'Invalid date format!',
			setEndDate,
			setStartDate,
			setEndDate,
		);
	};

	const setSliceState = () => {
		const sliceState: BillingReportSlice = {
			startDate: startDate.value,
			endDate: endDate.value,
		};

		dispatch(setBillingReportState(sliceState));
	};

	const formattedMinDate = format(minDate, DateFormatPatterns.Default);
	const formattedMaxDate = format(maxDate, DateFormatPatterns.Default);

	const startDateFromDateString = getUTCDateFromString(startDate.value);
	const endDateFromDateString = getUTCDateFromString(endDate.value);
	const daysToSubtract = 1;

	return (
		<>
			<FormDateInput
				{...startDate}
				title="Start Date"
				required
				dateInputProps={{
					maxDate: maxDate,
					minDate: minDate,
					onCalendarClose: () => {
						handleRequiredFormRangedRelatedDateBlur(
							startDate.value,
							startDate.value,
							endDate.value,
							minDate,
							maxDate,
							'Start Date is required!',
							`Chosen date should be after ${formattedMinDate}!`,
							`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
							'Invalid date format!',
							setStartDate,
							setStartDate,
							setEndDate,
						);
						setSliceState();
					},
					reactDatePickerProps: {
						excludeDateIntervals:
							endDateFromDateString && !endDate.error
								? [{ start: subDays(endDateFromDateString, daysToSubtract), end: maxDate }]
								: undefined,
					},
				}}
				onChange={(newValue) => {
					handleRequiredFormRangedRelatedDateBlur(
						newValue,
						newValue,
						endDate.value,
						minDate,
						maxDate,
						'Start Date is required!',
						`Chosen date should be after ${formattedMinDate}!`,
						`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
						'Invalid date format!',
						setStartDate,
						setStartDate,
						setEndDate,
					);
				}}
			/>
			<FormDateInput
				{...endDate}
				required
				title="End Date"
				dateInputProps={{
					maxDate: maxDate,
					minDate: minDate,
					onCalendarClose: () => {
						handleRequiredFormRangedRelatedDateBlur(
							endDate.value,
							startDate.value,
							endDate.value,
							minDate,
							maxDate,
							'End Date is required!',
							`Chosen date should be after ${formattedMinDate}!`,
							`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
							'Invalid date format!',
							setEndDate,
							setStartDate,
							setEndDate,
						);
						setSliceState();
					},
					reactDatePickerProps: {
						excludeDateIntervals:
							startDateFromDateString && !startDate.error
								? [{ start: minDate, end: startDateFromDateString }]
								: undefined,
					},
				}}
				onChange={(newValue) => {
					handleRequiredFormRangedRelatedDateBlur(
						newValue,
						startDate.value,
						newValue,
						minDate,
						maxDate,
						'End Date is required!',
						`Chosen date should be after ${formattedMinDate}!`,
						`Wrong date. Chosen date should be before ${formattedMaxDate}!`,
						'Invalid date format!',
						setEndDate,
						setStartDate,
						setEndDate,
					);
				}}
			/>
		</>
	);
};
