interface FileTypeValidationResult {
	isValid: boolean;
	errorMessage?: string;
	file?: File;
	fileExtension?: string;
}

class FileTypeValidator {
	typeValidate(file: File, fileExtension: string): FileTypeValidationResult {
		const first = 1;
		const uploadFileExtension = file.name.split('.')[first];
		const definedExtension = fileExtension.split('.')[first];

		if (!file) {
			return { isValid: false, file };
		}

		if (definedExtension !== uploadFileExtension) {
			return { isValid: false, errorMessage: `Only ${definedExtension.toUpperCase()} files are allowed ` };
		}

		return { isValid: true };
	}
}

export { FileTypeValidator };
