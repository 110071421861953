import { ProjectResultsGraphType, ProjectResultsGraphTypes } from 'features/Graphs';
import { useDispatch, useSelector } from 'react-redux';
import { ApiGraphResponseBenchmark, ApiGraphResponseBenchmarks, ApiResultResponse } from 'store/api';
import {
	DispersionChartCluster,
	setDispersionChartCombinedODNA,
	setDispersionChartCombinedPDNA,
	setDispersionChartODNA,
	setDispersionChartPDNA,
} from 'store/slices/graphSlices';
import {
	assignBenchmarks,
	getBenchmarkColor,
	getBenchmarkLineType,
	getBenchmarkName,
	getResponseClusters,
} from '../utils';
import { DispersionChartData, DispersionChartDataSeries } from 'features/Graphs/DispersionChart';
import { isCombinedPdna, isPdna } from 'features/Graphs/utils';
import { selectBenchmarkNames, selectBenchmarksSettings } from 'store/index';

export const useDispersionChart = (result: ApiResultResponse) => {
	const benchmarkSettings = useSelector(selectBenchmarksSettings);
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const dispatch = useDispatch();

	const getEmptyBenchmark = () => {
		const defaultNumberOfParticipants = 0;
		return {
			color: '',
			key: '',
			lineType: 'SOLID',
			numberOfParticipants: defaultNumberOfParticipants,
			type: '',
			values: [],
		};
	};

	const prepareThrottledData = (data: DispersionChartDataSeries[], seriesMaxValue: number) => {
		const throttle = 2;
		const outputData = [];
		const cyclesCount = seriesMaxValue / throttle;

		for (let i = 0; i <= cyclesCount; i++) {
			const summaryEntry = {
				percent: i * throttle,
				value: 0,
			};
			for (let j = 0; j < throttle; j++) {
				const index = i * throttle + j;
				if (index < data.length) {
					summaryEntry.value += data[index].value;
				}
			}

			if (summaryEntry.value) {
				outputData.push(summaryEntry);
			}
		}
		return outputData;
	};

	const addLowerBoundary = (data: DispersionChartDataSeries[]) => {
		return data.unshift({
			percent: 0,
			value: 0,
		});
	};

	const addUpperBoundary = (data: DispersionChartDataSeries[], seriesMaxValue: number) => {
		data.push({
			percent: seriesMaxValue,
			value: 0,
		});
	};

	const addDataBorders = (data: DispersionChartDataSeries[]) => {
		const seriesMaxValue = 100;
		const zero = 0;
		const indexingOffset = 1;
		const noLowerBoundary = data[zero].percent !== zero;
		const lastValue = data.length - indexingOffset;
		const noUpperBoundary = data[lastValue].percent !== seriesMaxValue;

		if (data.length > zero) {
			if (noLowerBoundary) {
				addLowerBoundary(data);
			}

			if (noUpperBoundary) {
				addUpperBoundary(data, seriesMaxValue);
			}
		}
		return data;
	};

	const prepareValues = (values: DispersionChartDataSeries | unknown) => {
		if (!values) return [];
		const seriesMaxValue = 100;

		const data: DispersionChartDataSeries[] = [];

		for (let i = 0; i < seriesMaxValue; i++) {
			data.push({
				percent: i,
				value: 0,
			});
		}

		Object.entries(values).forEach((entry) => {
			const percentageIndex = 0;
			const valueIndex = 1;

			const percent = parseInt(entry[percentageIndex]);
			const value = entry[valueIndex];

			data[percent] = {
				percent,
				value,
			};
		});

		const throttledData = prepareThrottledData(data, seriesMaxValue);
		return addDataBorders(throttledData);
	};

	const prepareKey = (benchmarkIndex: number) => {
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		return benchmarkName ? `${benchmarkName}` : `b${benchmarkIndex}`;
	};

	const prepareBenchmark = (
		benchmark: ApiGraphResponseBenchmark | undefined,
		type: ProjectResultsGraphType,
		index: number,
	) => {
		if (!benchmark) return getEmptyBenchmark();

		const labelType = isPdna(type) || isCombinedPdna(type) ? 'pdna' : 'odna';

		return {
			color: getBenchmarkColor(benchmarkSettings, index, type),
			key: prepareKey(index),
			lineType: getBenchmarkLineType(benchmarkSettings, index, type),
			numberOfParticipants: benchmark.numberOfParticipants,
			type: labelType,
			values: prepareValues(benchmark.dataDistribution),
		};
	};

	const prepareCluster = (benchmarks: ApiGraphResponseBenchmarks, type: ProjectResultsGraphType) => {
		const firstBenchmarkIndex = 1;
		const secondBenchmarkIndex = 2;
		const thirdBenchmarkIndex = 3;
		const fourthBenchmarkIndex = 4;
		const fifthBenchmarkIndex = 5;
		const cluster: DispersionChartData = {
			data: [
				prepareBenchmark(benchmarks.BENCHMARK_1, type, firstBenchmarkIndex),
				prepareBenchmark(benchmarks.BENCHMARK_2, type, secondBenchmarkIndex),
				prepareBenchmark(benchmarks.BENCHMARK_3, type, thirdBenchmarkIndex),
				prepareBenchmark(benchmarks.BENCHMARK_4, type, fourthBenchmarkIndex),
				prepareBenchmark(benchmarks.BENCHMARK_5, type, fifthBenchmarkIndex),
			],
		};

		return cluster;
	};

	const prepareDispersionChartData = (type: ProjectResultsGraphType) => {
		const clusters: DispersionChartCluster[] = [];
		const responseClusters = getResponseClusters(result);

		Object.values(responseClusters).map((cluster) => {
			const benchmarks: ApiGraphResponseBenchmarks = {};
			assignBenchmarks(benchmarks, cluster, type);

			const clusterBenchmarks = prepareCluster(benchmarks, type);
			clusters.push({
				benchmarks: clusterBenchmarks,
			});
		});

		return { clusters };
	};

	const dataODNA = prepareDispersionChartData(ProjectResultsGraphTypes.ODNA);
	const dataPDNA = prepareDispersionChartData(ProjectResultsGraphTypes.PDNA);
	const dataCombinedPDNA = prepareDispersionChartData(ProjectResultsGraphTypes.COMBINED_PDNA);
	const dataCombinedODNA = prepareDispersionChartData(ProjectResultsGraphTypes.COMBINED_ODNA);

	dispatch(setDispersionChartODNA(dataODNA));
	dispatch(setDispersionChartPDNA(dataPDNA));
	dispatch(setDispersionChartCombinedPDNA(dataCombinedPDNA));
	dispatch(setDispersionChartCombinedODNA(dataCombinedODNA));
};
