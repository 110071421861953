import { PredictiveResult } from 'store/api/predictiveApi';
import SvgElement from '@support/alderbrooke-d3-graphs/dist/Common/SvgElement';
import IChartCfg from '@support/alderbrooke-d3-graphs/dist/VerticalBar/IChartCfg';
import { SVGGraph } from 'store/api/predictiveExportApi/predictiveExportApi.types';
import { OddsRatioConfig } from 'features/Graphs/OddsRatioGraph/hooks/useOddsRatioCreator.types';

export type OddsRatioGraphProps = {
	data: PredictiveResult;
	config: OddsRatioConfig;
};
export type OddsRatioLegendCfg = typeof OddsRatioLegendConfig;

export const OddsRatioLegendConfig = {
	verticalBarChartColors: {
		oddsFor: '#4472c4',
		oddsAgainst: '#ff0000',
	},
	verticalBarChartLabels: {
		oddsFor: 'Odds for',
		oddsAgainst: 'Odds against',
	},
};

/* eslint-disable no-magic-numbers */
export type OddsRatioCfg = IChartCfg;
export const OddsRatioGraphConfig: OddsRatioCfg = {
	axisY: {
		domain: [-0.5, 0.5],
		ticks: 15,
		lineStyle: { stroke: '#4e4d50', fill: 'none', 'stroke-width': '1px' },
		textStyle: { 'font-size': '12px', 'font-family': 'Arial' },
		label: {
			rightPadding: 10,
		},
	},
	axisX: {
		padding: 0.3,
		textStyle: { 'font-size': '14px', 'font-weight': 'bold', 'font-family': 'Arial' },
		label: {
			leftPadding: 20,
			rightPadding: 10,
		},
	},
	bar: {
		textStyle: { 'font-size': '14px', 'font-family': 'Arial' },
	},
	legend: {
		quartileInfo: {
			enabled: false,
			textSize: 21,
		},
		descriptionLeftPosition: 100,
		leftPosition: 400,
		squareSize: 10,
		squareTopMargin: 4,
		textLeftMargin: 5,
		textRightMargin: 15,
		textStyle: { 'font-size': '16px', 'font-weight': 'bold', 'font-family': 'Arial' },
		topMarginForTitle: 20,
		topMarginForLegend: 520,
		leftMargin: 30,
		title: '',
		title2: 'Probability of behaviours presence or absence',
	},
	canvas: {
		margins: {
			left: 120,
			right: 20,
			bottom: 120,
			top: 50,
		},
		width: 700,
		height: 600,
	},
};

export type OddsRatioDescription = {
	title: string;
	subtitle: string;
};

export interface IVerticalBarGraph {
	getExportedSVG(): SVGGraph;
	getSVG(): SvgElement;
	dispose(): void;
}
