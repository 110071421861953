import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { CheckboxInput, CheckboxIcon, CheckboxLabel } from './styled';
import { CheckboxProps } from './CheckBox.types';

export const Checkbox = ({ className, checked, onChange, id, disabled = false, readOnly }: CheckboxProps) => {
	const checkboxIcon = checked ? faSquareCheck : faSquare;
	return (
		<CheckboxLabel className={className}>
			<CheckboxIcon
				$disabled={disabled}
				icon={checkboxIcon}
			/>
			<CheckboxInput
				id={id}
				type="checkbox"
				readOnly={readOnly}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
		</CheckboxLabel>
	);
};
