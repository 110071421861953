export const isEmptyArray = (array: unknown[]): boolean => {
	return !array.length;
};

export const isNumberOfItemsOdd = (array: unknown[]): boolean => {
	const modTwo = 2;
	const evenModResult = 0;
	const isNumberOfItemsOdd = array.length % modTwo !== evenModResult;

	return isNumberOfItemsOdd;
};
