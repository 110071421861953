import { combineReducers } from '@reduxjs/toolkit';
import { billingAddressSlice } from './billingAddressSlice';
import { billingContactSlice } from './billingContactSlice';
import { billingInformationSlice } from './billingInformationSlice';
import { organisationAddressSlice } from './organisationAddressSlice';
import { organisationInformationSlice } from './organisationInformationSlice';
import { stakeholderContactSlice } from './stakeholderContactSlice';
import { stakeholderInformationSlice } from './stakeholderInformationSlice';

export const createClientReducers = combineReducers({
	billingAddress: billingAddressSlice.reducer,
	billingContact: billingContactSlice.reducer,
	billingInformation: billingInformationSlice.reducer,
	organisationAddress: organisationAddressSlice.reducer,
	organisationInformation: organisationInformationSlice.reducer,
	stakeholderContact: stakeholderContactSlice.reducer,
	stakeholderInformation: stakeholderInformationSlice.reducer,
});
