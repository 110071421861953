import { CustomFormItemLayout } from '../CustomFormItemLayout';
import { FormDropdownProps } from './FormDropdown.types';
import { StyledDropdown } from './styled';

export const FormDropdown = ({ title, error, errorText, required, ...dropdownProps }: FormDropdownProps) => {
	return (
		<CustomFormItemLayout
			title={title}
			error={error}
			disabled={dropdownProps.disabled}
			errorText={errorText}
			required={required}
		>
			<StyledDropdown
				$error={error}
				{...dropdownProps}
			/>
		</CustomFormItemLayout>
	);
};
