export const RegexPatterns = {
	MatchValidDateFormatParts: /(\d{2})\/(\d{2})\/(\d{4})$/g,
	AllWhitespaceChars: /\s+/g,
	NotUppercaseCharacters: /[^A-Z]/g,
	NotLowercaseCharacters: /[^a-z]/g,
	NotDigits: /[^0-9]/g,
	NotSpecialCharacters: /[a-zA-Z0-9 ]/g,
	OnlyDigits: /\D/g,
	InternationalTelephoneFormat:
		/^(\+|00)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
	Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
} as const;
