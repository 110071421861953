import { ApiBenchmarkParticipant } from 'common/types';
import { ParticipantsResponse } from '../participantsApi/participantsApi.types';
import {
	BenchmarkParticipantOptions,
	ParticipantAssignationRequest,
	ParticipantToggleRequest,
} from './benchmarkConfigurationApi.types';
import { rootApi } from 'store/api';

export const benchmarkParticipantsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getBenchmarkParticipants: builder.query<
			ParticipantsResponse<ApiBenchmarkParticipant>,
			BenchmarkParticipantOptions
		>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.benchmarkConfigurationId.id}/members`,
				method: 'POST',
				body: opts,
			}),
		}),
		toggleParticipant: builder.query<void, ParticipantToggleRequest>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.benchmarkConfigurationId.id}/members/${opts.participantId}/toggle-assignment`,
				method: 'POST',
				body: { benchmarkGroup: opts.benchmarkGroup },
			}),
		}),
		assignParticipants: builder.mutation<void, ParticipantAssignationRequest>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.benchmarkConfigurationId.id}/members/assignment`,
				method: 'POST',
				body: {
					...opts,
					action: 'ASSIGN',
				},
			}),
		}),
		unAssignParticipants: builder.mutation<void, ParticipantAssignationRequest>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.benchmarkConfigurationId.id}/members/assignment`,
				method: 'POST',
				body: {
					...opts,
					action: 'UNASSIGN',
				},
			}),
		}),
	}),
});

export const {
	useLazyGetBenchmarkParticipantsQuery,
	useAssignParticipantsMutation,
	useUnAssignParticipantsMutation,
	useLazyToggleParticipantQuery,
} = benchmarkParticipantsApi;
