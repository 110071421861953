import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuadrantGraphState } from './quadrantSlice.types';
import { RootState } from 'store/store';

const initialState: QuadrantGraphState = {
	clusters: [
		{
			clusterId: '0',
			data: {
				data: [],
				legend: {
					leftTop: [],
					rightTop: [],
					leftBottom: [],
					rightBottom: [],
				},
				rScale: {
					min: 0,
					max: 0,
				},
			},
		},
	],
};

export const quadrantSlice = createSlice({
	name: 'quadrantGraph',
	initialState,
	reducers: {
		setQuadrantState: (state, { payload }: PayloadAction<QuadrantGraphState>) => {
			state.clusters = payload.clusters;
		},
		resetQuadrantState: () => initialState,
	},
});

export const { setQuadrantState, resetQuadrantState } = quadrantSlice.actions;
export const quadrantReducer = quadrantSlice.reducer;
export const selectQuadrantState = (state: RootState) => state.quadrantGraph;
