import { ApiGraphConfiguration } from 'common/types';
import { rootApi } from 'store/api';
import { GraphConfigurationPayload, GraphConfigurationResponse } from './graphApi.types';

export const graphApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getGraphConfiguration: builder.query<GraphConfigurationResponse<ApiGraphConfiguration>, void>({
			query: () => ({
				url: `/api/account/graph-configuration`,
			}),
		}),
		postGraphConfiguration: builder.mutation<void, GraphConfigurationPayload>({
			query: (graphConfig) => ({
				url: '/api/account/graph-configuration',
				method: 'POST',
				body: graphConfig,
			}),
		}),
	}),
});

export const { useGetGraphConfigurationQuery, useLazyGetGraphConfigurationQuery, usePostGraphConfigurationMutation } =
	graphApi;
