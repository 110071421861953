import { RegexPatterns } from 'common/constants';
import { isDate as _isDate } from 'date-fns';

export const isDate = (value: unknown): value is Date => {
	return _isDate(value);
};

export const getUTCDate = (date: Date) => {
	const monthOffset = 1;
	const maxDigit = 9;
	const year = date.getFullYear();
	const month = date.getMonth() + monthOffset;
	const day = date.getDate();
	const monthString = month <= maxDigit ? `0${month}` : `${month}`;
	const dayString = day <= maxDigit ? `0${day}` : `${day}`;

	const isoDateString = `${year}-${monthString}-${dayString}`;
	return new Date(isoDateString);
};

export const getUTCDateFromString = (dateString: string | undefined): Date | undefined => {
	if (!dateString) return;

	const normalizedDateString = dateString.replace(RegexPatterns.AllWhitespaceChars, '');
	const date = parseDateString(normalizedDateString);
	if (!date) return;

	return date;
};

export const parseDateString = (dateString: string): Date | undefined => {
	const matches = [...dateString.matchAll(RegexPatterns.MatchValidDateFormatParts)];
	const noDateMatches = 0;
	if (matches.length === noDateMatches) return;

	const matchIndex = 0;
	const yearIndex = 3;
	const monthIndex = 2;
	const dayIndex = 1;

	const matchArray = matches[matchIndex];
	if (!matchArray[dayIndex] || !matchArray[monthIndex] || !matchArray[yearIndex]) return;

	const isoDateString = `${matchArray[yearIndex]}-${matchArray[monthIndex]}-${matchArray[dayIndex]}`;

	return new Date(isoDateString);
};

export const getDateFromUnixUTC = (seconds: number) => {
	const secondsSinceStartOfAnEpoch = 0;
	const date = new Date(secondsSinceStartOfAnEpoch);

	date.setSeconds(seconds);

	return date;
};
