import { NavbarListItem, PageNameText, StyledIcon, StyledNavLink } from './styled';
import { ReactNode } from 'react';

type NavbarItemProps = {
	path: string;
	pageName: string;
	icon: ReactNode;
};

export const NavbarItem = ({ path, pageName, icon }: NavbarItemProps) => {
	return (
		<NavbarListItem>
			<StyledNavLink to={path}>
				<StyledIcon>{icon}</StyledIcon>
				<PageNameText>{pageName}</PageNameText>
			</StyledNavLink>
		</NavbarListItem>
	);
};
