import { Criterion } from 'common/types';

export const QuadrantGraphTypes = {
	TOTAL_VIEW: 0,
	CRITERIA_VIEW: 1,
	HIGHLIGHTS: 2,
};

export const QuadrantLegendSortingTypes = {
	PERCENTAGE_ASCENDING: 0,
	PERCENTAGE_DESCENDING: 1,
	NUMBER_ASCENDING: 2,
	NUMBER_DESCENDING: 3,
};

export type QuadrantLegendSortingType = typeof QuadrantLegendSortingTypes[keyof typeof QuadrantLegendSortingTypes];

export type QuadrantGraphType = typeof QuadrantGraphTypes[keyof typeof QuadrantGraphTypes];

export type DiscoveryInsightModeSelectionProps = {
	criteria: Criterion[];
	quadrantType: QuadrantGraphType;
	criterion: Criterion;
	quadrantSorting: QuadrantLegendSortingType;
	onStateChange: (mode: QuadrantGraphType, sorting: QuadrantLegendSortingType, criterion: Criterion) => void;
};

export type DisappearingColumnProps = {
	$appear: boolean;
};
