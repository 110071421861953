import { ZIndexes } from 'common/constants';
import styled, { keyframes } from 'styled-components';
import { StyledSuspenseContainerProps } from './Suspense.types';

const imageRotateAnimation = keyframes`
	0% {
		transform: rotate3d(0, 1, 0, 0);
	}

	100% {
		transform: rotate3d(0, 1, 0, 360deg);
	}
`;

export const SuspenseContainer = styled.div<StyledSuspenseContainerProps>`
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: ${ZIndexes.suspense};
	background-color: ${({ theme }) => theme.colors.disabled.main};
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	visibility: ${({ $isHidden }) => $isHidden && 'hidden'};
`;

export const SuspenseImage = styled.img`
	width: 10rem;
	animation-name: ${imageRotateAnimation};
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
`;
