import { getFilenameFromResponse, rootApi } from 'store/api';
import {
	ApiPredictiveExportPayload,
	ApiPredictiveExportResponse,
} from 'store/api/predictiveExportApi/predictiveExportApi.types';
import { format } from 'date-fns';
import { DateFormatPatterns } from 'common/constants';

export const predictiveExportApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		exportPredictiveExport: builder.mutation<ApiPredictiveExportResponse, ApiPredictiveExportPayload>({
			query: (data) => ({
				url: `/api/viewmode/results/predictive/pptx`,
				body: data,
				method: 'POST',
				responseHandler: async (response) => {
					if (!response.ok) return;
					const currentDate = format(new Date(), DateFormatPatterns.Export);
					const defaultFilename = `predictive_chart_${currentDate}.pptx`;
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});
export const { useExportPredictiveExportMutation } = predictiveExportApi;
