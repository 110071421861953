import { StyledReactSlider, StyledThumb, StyledTrack } from 'features/DiscoveryInsights/components';

export const QuadrantSlider = ({ value, onChange }: { value: number; onChange: (value: string) => void }) => {
	const min = 1;
	const max = 4;
	const increment = 0.05;

	return (
		<StyledReactSlider
			min={min}
			max={max}
			step={increment}
			value={value}
			renderThumb={(props) => (
				<StyledThumb>
					<div {...props} />
				</StyledThumb>
			)}
			renderTrack={(props) => (
				<StyledTrack>
					<div {...props} />
				</StyledTrack>
			)}
			onChange={(value) => onChange(value.toString())}
		/>
	);
};
