import { OdnaAccuracyChartProps } from './OdnaAccuracyChart.types';
import { accuracyChartConfig, AccuracyChart } from '../..';
import { useOdnaAccuracyChartData } from 'features/Graphs/AccuracyChart/hooks/useOdnaAccuracyChartData';
import { useSelector } from 'react-redux';
import { selectAccuracyChartCombinedODNA, selectAccuracyChartODNA } from 'store/slices';

export const OdnaAccuracyChart = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
}: OdnaAccuracyChartProps) => {
	const config = accuracyChartConfig;
	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const odna = combinedMode ? useSelector(selectAccuracyChartODNA) : useSelector(selectAccuracyChartCombinedODNA);
	const accuracyData = useOdnaAccuracyChartData(odna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<AccuracyChart
			config={config}
			containerId={containerId}
			data={accuracyData}
		/>
	);
};
