import { QuadrantColorPreset, QuadrantColorPresetId } from 'features/Graphs/QuadrantGraph/QuadrantGraph.types';

export const useQuadrantColorPreset = (colorPresetId: QuadrantColorPresetId): QuadrantColorPreset => {
	function createPreset1(): QuadrantColorPreset {
		const backgroundColors = {
			color1: '#ed6c6d', // _addLeftBottomQuadrant
			color2: '#ffe082', // _addRightBottomQuadrant
			color3: '#000000',
			color4: '#000000',
			color5: '#6d9e6e', // _addRightTopQuadrant
		};

		const dotColors = {
			color: '#5fc1fe',
			hoveredColor: '#821ad8',
			hoveredOutlineColor: '#6313a4',
		};

		const tooltipColors = {
			color: '#821ad8',
			outlineColor: '#6313a4',
			textColor: '#ffffff',
		};

		return {
			backgroundColors,
			dotColors,
			tooltipColors,
		};
	}

	function createPreset2(): QuadrantColorPreset {
		const backgroundColors = {
			color1: '#6b6783',
			color2: '#9484be',
			color3: '#000000',
			color4: '#000000',
			color5: '#ca82b5',
		};

		const dotColors = {
			color: '#F0F0F0',
			hoveredColor: '#7F7F7F',
			hoveredOutlineColor: '#E7E6E6',
		};

		const tooltipColors = {
			color: '#7F7F7F',
			outlineColor: '#E7E6E6',
			textColor: '#ffffff',
		};

		return {
			backgroundColors,
			dotColors,
			tooltipColors,
		};
	}

	function createPreset3(): QuadrantColorPreset {
		const backgroundColors = {
			color1: '#928e83', // _addLeftBottomQuadrant
			color2: '#c79266', // _addRightBottomQuadrant
			color3: '#000000',
			color4: '#000000',
			color5: '#6baad1', // _addRightTopQuadrant
		};

		const dotColors = {
			color: '#000000',
			hoveredColor: '#7F7F7F',
			hoveredOutlineColor: '#000000',
		};

		const tooltipColors = {
			color: '#7F7F7F',
			outlineColor: '#000000',
			textColor: '#ffffff',
		};

		return {
			backgroundColors,
			dotColors,
			tooltipColors,
		};
	}

	const presets = [createPreset1(), createPreset2(), createPreset3()];

	return presets[colorPresetId];
};
