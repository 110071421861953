import { useSelector } from 'react-redux';
import { ProjectFormBody } from 'store/api';
import {
	selectAdditionalQuestionsState,
	SelectAutomaticClosureConditionsState,
	selectProjectDetailsState,
} from 'store/slices';

export const useCreateProjectFormData = () => {
	const projectDetails = useSelector(selectProjectDetailsState);
	const automaticClosureConditions = useSelector(SelectAutomaticClosureConditionsState);
	const additionalQuestions = useSelector(selectAdditionalQuestionsState);

	const getCsvFileFromObjectUrl = async () => {
		const objectUrl = additionalQuestions.fileObjectUrl;
		if (!objectUrl) return;
		const response = await fetch(objectUrl);
		const blob = await response.blob();

		return new File([blob], additionalQuestions.filename ?? 'questions_configuration.csv', { type: 'text/csv' });
	};

	const percentOfCompletedQuestionnaires = automaticClosureConditions.percentOfCompletedQuestionnaires
		? Number(automaticClosureConditions.percentOfCompletedQuestionnaires)
		: undefined;

	const getClosureCriteria = () => {
		if (!automaticClosureConditions.closureDate && !automaticClosureConditions.percentOfCompletedQuestionnaires)
			return;

		return {
			closureDate: automaticClosureConditions.closureDate,
			percentOfCompletedQuestionnaires: percentOfCompletedQuestionnaires,
		};
	};

	const getProjectFormData = async (): Promise<ProjectFormBody> => {
		return {
			shortId: projectDetails.projectId,
			description: projectDetails.description,
			type: projectDetails.assessmentType,
			costCenterReference: projectDetails.costCentreReference,
			closureCriteria: getClosureCriteria(),
			file: await getCsvFileFromObjectUrl(),
			introduction: additionalQuestions.introduction,
		};
	};

	return { getProjectData: getProjectFormData };
};
