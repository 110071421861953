import { ZIndexes } from 'common/constants';
import styled, { css } from 'styled-components';
import { StyledTooltipTipProps } from './Tooltip.types';

export const TooltipWrapper = styled.div`
	display: flex;
	position: relative;
`;

const arrowSizePx = '6px';
const tooltipMarginPx = '24px';

const leftDirection = css`
	left: auto;
	right: calc(100% + ${tooltipMarginPx} / 3);
	top: 50%;
	transform: translateX(0) translateY(-50%);

	&::before {
		left: auto;
		right: calc(${arrowSizePx} * -2);
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-left-color: ${({ theme }) => theme.colors.common.darkGrey};
	}
`;

const rightDirection = css`
	left: calc(100% + ${tooltipMarginPx} / 3);
	top: 50%;
	transform: translateX(0) translateY(-50%);

	&::before {
		left: calc(${arrowSizePx} * -1);
		top: 50%;
		transform: translateX(0) translateY(-50%);
		border-right-color: ${({ theme }) => theme.colors.common.darkGrey};
	}
`;

const topDirection = css<StyledTooltipTipProps>`
	top: ${({ $height }) => `calc(-${$height}px + (-${tooltipMarginPx} / 2))`};

	&::before {
		top: 100%;
		border-top-color: ${({ theme }) => theme.colors.common.darkGrey};
	}
`;

const bottomDirection = css<StyledTooltipTipProps>`
	bottom: ${({ $height }) => `calc(-${$height}px + (-${tooltipMarginPx} / 2))`};

	&::before {
		bottom: 100%;
		border-bottom-color: ${({ theme }) => theme.colors.common.darkGrey};
	}
`;

export const TooltipTip = styled.div<StyledTooltipTipProps>`
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-50%);
	padding: 0.3rem;
	color: ${({ theme }) => theme.colors.common.white};
	background: ${({ theme }) => theme.colors.common.darkGrey};
	font-size: 0.8rem;
	line-height: 1;
	z-index: ${ZIndexes.tooltip};
	white-space: nowrap;

	&::before {
		content: ' ';
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: ${arrowSizePx};
		margin-left: calc(${arrowSizePx} * -1);
	}

	${({ $direction }) => $direction === 'top' && topDirection};
	${({ $direction }) => $direction === 'bottom' && bottomDirection};
	${({ $direction }) => $direction === 'left' && leftDirection};
	${({ $direction }) => $direction === 'right' && rightDirection};
`;
