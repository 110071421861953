import styled from 'styled-components';
import { Dropdown } from 'common/components/Dropdown';
import { DropdownButton, DropdownContent } from 'common/components/Dropdown/styled';
import { DropdownItemListItem } from 'common/components/Dropdown/DropdownItemLabel/styled';
import { theme, ZIndexes } from 'common/constants';

export const StyledExportButton = styled(Dropdown)`
	position: absolute;
	right: 5.5rem;
	top: 5rem;
	z-index: ${ZIndexes.modal};
	${DropdownButton} {
		width: 1rem;
		font-size: 2.2rem;
		color: ${theme.colors.common.black};
		letter-spacing: 0px;
		text-transform: uppercase;
	}

	${DropdownContent} {
		width: 10rem;
	}
	${DropdownItemListItem} {
		text-transform: capitalize;
		width: 20rem;
	}
`;
