import type { Engine } from 'tsparticles-engine';
import { loadLinksPreset } from 'tsparticles-preset-links';
import { AnimatedBackgroundContainer, StyledParticles } from './styled';
import { ISourceOptions } from 'tsparticles-engine';
import { theme } from 'common/constants';

export const AnimatedBackground = () => {
	const customInit = async (engine: Engine): Promise<void> => {
		await loadLinksPreset(engine);
	};

	const animationOptions = {
		fullScreen: false,
		fps_limit: 60,
		background: {
			color: '',
			opacity: 0,
		},
		particles: {
			number: {
				value: 100,
			},
			color: {
				value: theme.colors.common.purple,
			},
			links: {
				color: theme.colors.common.purple,
				distance: 150,
				enable: true,
				opacity: 5,
				width: 2,
			},
			size: {
				value: 10,
				random: true,
			},
			move: {
				enable: true,
			},
		},
	} as ISourceOptions;

	return (
		<AnimatedBackgroundContainer>
			<StyledParticles
				init={customInit}
				options={animationOptions}
			/>
		</AnimatedBackgroundContainer>
	);
};
