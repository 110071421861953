import { Button } from 'common/components/Button/Button';
import styled from 'styled-components';

export const ViewButton = styled(Button)`
	text-transform: capitalize;
	height: fit-content;
	font-size: 1.3rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
`;
