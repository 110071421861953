import { RadarGraphData } from '../../RadarGraph.types';

export const modifyLabel = (benchmark: RadarGraphData, labelSuffix: string) => {
	const defaultBenchmarkIndex = 0;
	return {
		categoryLabels: benchmark.categoryLabels,
		title: benchmark.title,
		data: {
			color: benchmark.data[defaultBenchmarkIndex].color,
			values: benchmark.data[defaultBenchmarkIndex].values,
			label: `${benchmark.data[defaultBenchmarkIndex].label} - ${labelSuffix}`,
			lineType: benchmark.data[defaultBenchmarkIndex].lineType,
		},
	};
};
