import { useDimensions } from 'common/hooks';
import { Dimension } from 'common/types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditedDimensionsForChosenCluster, setEditedFrameworkDimensionsForSelectedCluster } from 'store/slices';
import { BehaviourPairTile } from '../BehaviourPairTile';
import { BehaviourPairModalContentProps } from './BehaviourPairModalContent.types';
import { ConfirmButton, GridContainer, ModalContainer, Title } from './styled';
import { getBehaviourTooltipDirection } from './utils';

export const BehaviourPairModalContent = ({ onConfirm }: BehaviourPairModalContentProps) => {
	const { dimensions } = useDimensions();

	const dispatch = useDispatch();
	const initialEditedDimensions = useSelector(selectEditedDimensionsForChosenCluster);

	const [editedDimensions, setEditedDimensions] = useState(initialEditedDimensions ?? []);

	const onClickConfirmButton = () => {
		if (onConfirm) {
			onConfirm();
		}

		dispatch(setEditedFrameworkDimensionsForSelectedCluster(editedDimensions));
	};

	const onSelectDimension = (dimension: Dimension) => {
		const isDimensionAlreadySelected = editedDimensions.some(
			(editedDimension) => editedDimension.dimensionId === dimension.dimensionId,
		);

		if (isDimensionAlreadySelected) return;

		const newSelectedDimensions: Dimension[] = [...editedDimensions];
		const foundOppositeDimensionIndex = editedDimensions.findIndex(
			(editedDimension) => editedDimension.dimensionId === dimension.contraryDimensionId,
		);

		const notFoundIndex = -1;
		if (foundOppositeDimensionIndex !== notFoundIndex) {
			const elementsToRemove = 1;
			newSelectedDimensions.splice(foundOppositeDimensionIndex, elementsToRemove);
		}

		newSelectedDimensions.push(dimension);

		setEditedDimensions(newSelectedDimensions);
	};

	const getBehaviourPairTiles = () => {
		return dimensions.map((dimension, index) => {
			const selectedBehaviour = editedDimensions?.find(
				(editedDimension) =>
					editedDimension.dimensionId === dimension.primary.dimensionId ||
					editedDimension.dimensionId === dimension.contrary.dimensionId,
			);

			const selectedDimensionId = selectedBehaviour?.dimensionId;

			return (
				<BehaviourPairTile
					tooltipDirection={getBehaviourTooltipDirection(index)}
					key={dimension.primary.dimensionId}
					primaryDimension={dimension.primary}
					onSelect={onSelectDimension}
					contraryDimension={dimension.contrary}
					selectedDimensionId={selectedDimensionId}
				/>
			);
		});
	};

	return (
		<ModalContainer>
			<Title>Behaviour Selection</Title>
			<GridContainer>{getBehaviourPairTiles()}</GridContainer>
			<ConfirmButton onClick={onClickConfirmButton}>Done</ConfirmButton>
		</ModalContainer>
	);
};
