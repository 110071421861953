import { FormInputFile } from 'common/components';
import { FileTypeValidator } from 'common/components/UploadData/ValidationRules/FileValidatorType';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImportParticipantsState, selectImportParticipantsState, setImportParticipantsState } from 'store/slices';
import { ParticipantsFileFormProps } from './ParticipantsFileForm.types';

export const ParticipantsFileForm = ({ saveTrigger }: ParticipantsFileFormProps) => {
	const importParticipants = useSelector(selectImportParticipantsState);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [fileError, setFileError] = useState(false);
	const fileTypeValidator = new FileTypeValidator();

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateRequiredFileInput();
		}
		setSliceState();
	}, [saveTrigger]);

	useEffect(() => {
		setSliceState();
	}, [selectedFile]);

	const validateRequiredFileInput = () => {
		const fileNotProvided = !selectedFile;
		setFileError(fileNotProvided);
	};

	const setSliceState = () => {
		const fileObjectUrl = selectedFile ? URL.createObjectURL(selectedFile) : undefined;
		const filename = selectedFile ? selectedFile.name : undefined;
		const sliceState: ImportParticipantsState = {
			fileObjectUrl: fileObjectUrl,
			filename: filename,
		};

		dispatch(setImportParticipantsState(sliceState));
	};

	const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, fileExtension: string) => {
		const first = 0;
		const file = event.target.files && event.target.files[first];
		if (selectedFile !== file && file) {
			const typeValidation = fileTypeValidator.typeValidate(file, fileExtension);

			if (importParticipants.fileObjectUrl) {
				URL.revokeObjectURL(importParticipants.fileObjectUrl);
			}

			const fileNotValid = !typeValidation.isValid;
			setFileError(fileNotValid);

			setSelectedFile(file);
		}
	};

	return (
		<>
			<FormInputFile
				title="CSV File To Import"
				required
				error={fileError}
				errorText="CSV file is required."
				fileInputProps={{
					accept: '.csv',
					placeholder: 'Choose file...',
					onChange: (event) => {
						handleFileInputChange(event, '.csv');
					},
				}}
			/>
		</>
	);
};
