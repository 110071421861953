import { faMagnifyingGlass, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { StyledIcon } from './styled';
import { BenchmarkButtonProps } from './BenchmarkButton.types';

export const BenchmarkButton = ({ onClick, modified }: BenchmarkButtonProps) => {
	const icon = modified ? faMagnifyingGlassChart : faMagnifyingGlass;

	return (
		<StyledIcon
			icon={icon}
			onClick={() => onClick()}
		/>
	);
};
