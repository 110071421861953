import { BenchmarkConfigurationId } from 'store/api';
import { useLazyGetPredictiveGraphDataQuery } from 'store/api/predictiveApi/predictiveApi';
import { vetoIfBadRequest } from 'common/hooks/predictiveView/mappers';

export const usePredictiveViewResults = () => {
	const [fetchResult] = useLazyGetPredictiveGraphDataQuery();

	return async (benchmarkConfigurationId: BenchmarkConfigurationId) => {
		const response = await fetchResult({ benchmarkConfigurationId });

		vetoIfBadRequest(response.data);

		return response.data;
	};
};
