import Particles from 'react-particles';
import styled from 'styled-components';

export const AnimatedBackgroundContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const StyledParticles = styled(Particles)`
	height: 100%;
`;
