import { ZIndexes } from 'common/constants';
import styled from 'styled-components';
import { TableCell } from '../TableCell';

export const StyledTHead = styled.thead`
	font-size: 1.2em;
	position: sticky;
	z-index: ${ZIndexes.tableHeader};
	top: 0;
`;

export const StyledTableCellFilter = styled(TableCell)`
	padding-top: 0;
`;
