import { charts } from '@support/alderbrooke-d3-graphs';
import {
	BenchmarkAlias,
	TensionGraphFactory,
	TensionGraphData,
	TensionGraphRequest,
	TensionGraphTheme,
	TensionLegendConfiguration,
	useTranslation,
	useTensionChartDataRepository,
	useHeatmapChartStyleFactory,
	useTensionChartDataProvider,
	useTensionGraphColorPresetService,
} from 'features/Graphs/TensionGraph';
import IChartData from '@support/alderbrooke-d3-graphs/dist/Tension/model/IChartData';
import { ApiAllTranslationsResponse, ApiGraphResponseResults } from 'store/api';

export const useTensionGraphFactory = function (
	data: ApiGraphResponseResults,
	translations: ApiAllTranslationsResponse,
): TensionGraphFactory {
	const translatorFactory = useTranslation(translations);
	const tensionChartDataRepository = useTensionChartDataRepository(data);

	const tensionChartDataProvider = useTensionChartDataProvider(tensionChartDataRepository, translatorFactory);
	const tensionGraphColorPresetService = useTensionGraphColorPresetService();
	const heatmapChartStyleFactory = useHeatmapChartStyleFactory();

	return { create: create };

	function create(request: TensionGraphRequest, tensionLegendConfiguration: TensionLegendConfiguration) {
		const clusterId = request.clusterId;
		const benchmarkId = request.benchmarkId;
		const data = tensionChartDataProvider.getData(clusterId, benchmarkId);

		const benchmarkAlias = request.benchmarkAlias;
		const chartData = createChartData(benchmarkAlias, data, tensionLegendConfiguration);

		const colorPresetId = request.colorPresetId;
		const colorStyle = tensionGraphColorPresetService.getPreset(colorPresetId);
		const chartConfig = creatChartConfig(colorStyle);

		return new charts.Tension.Builder(chartConfig, chartData).build();
	}

	function createChartData(
		benchmarkAlias: BenchmarkAlias,
		data: TensionGraphData,
		legendConfiguration: TensionLegendConfiguration,
	): IChartData {
		return {
			benchmarkName: benchmarkAlias,
			pdnaParticipants: data.pdnaAmount,
			odnaParticipants: data.odnaAmount,
			questionnaireStatus: data.questionnaireStatus,
			entries: data.entries,
			legendConfiguration,
		};
	}

	function creatChartConfig(style: TensionGraphTheme) {
		const heatmapConfig = heatmapChartStyleFactory.create(style.heatmapTable);

		const dimensions = { positionX: 250, positionY: 0 };

		const barTheme = style.tension.barTheme;
		const tensionChartConfig = {
			dimensions,
			barTheme,
		};

		const chartConfig = {
			marginLeft: 60,
			marginTop: 30,
			width: 1200,
			height: 540,
		};
		return { chartConfig, tensionChartConfig, heatmapConfig };
	}
};
