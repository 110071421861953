import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const createAndAppendWrapper = (id: string) => {
	const wrapper = document.createElement('div');
	wrapper.setAttribute('id', id);

	document.body.appendChild(wrapper);
	return wrapper;
};

type PortalProps = {
	children: React.ReactNode;
	wrapperId: string;
};

export const Portal = ({ children, wrapperId }: PortalProps) => {
	const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

	useLayoutEffect(() => {
		let element = document.getElementById(wrapperId);

		if (!element) {
			element = createAndAppendWrapper(wrapperId);
		}

		setWrapperElement(element);
	}, [wrapperId]);

	if (wrapperElement === null) return null;

	return ReactDOM.createPortal(children, wrapperElement);
};
