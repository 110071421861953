import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { theme } from 'common/constants';
import { ZIndexes } from 'common/constants/zIndexes';
import { StyledDropdownContainerProps, StyledDropdownContentProps } from './Dropdown.types';

export const DropdownSelectedLabel = styled('div')``;

const fullContentWidth = css`
	width: 100%;
`;

export const DropdownContent = styled.ul<StyledDropdownContentProps>`
	position: absolute;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 50%;
	min-width: 7rem;
	right: 0;
	max-height: 18rem;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: ${ZIndexes.dropdownList};

	${({ $fullContentWidth }) => $fullContentWidth && fullContentWidth}

	border: 1px solid ${({ theme }) => theme.colors.common.grey};
`;

const primaryColor = css`
	color: ${({ theme }) => theme.colors.primary.main};
`;

const secondaryColor = css`
	color: ${({ theme }) => theme.colors.secondary.main};
`;

const inheritColor = css`
	color: inherit;
`;

export const DropdownContainer = styled.div<StyledDropdownContainerProps>`
	position: relative;
	font-size: 1rem;

	${({ $color }) => $color === 'primary' && primaryColor};
	${({ $color }) => $color === 'secondary' && secondaryColor};
	${({ $color }) => $color === 'inherit' && inheritColor};

	& > ${DropdownContent} {
		background-color: ${theme.colors.common.white};
	}
`;

export const DropdownButton = styled(Button)`
	width: 100%;
	min-height: 1.5rem;
	word-break: keep-all;
	overflow-wrap: normal;
	padding: 0;
	text-transform: none;
	display: flex;
	justify-content: space-between;
`;
