import { ColumnDef, ColumnFilter, Table } from '@tanstack/react-table';

import { Participant, ParticipantId, ProjectId } from 'common/types';
import { Criterion } from 'common/types';
import { TableCheckbox, TableAssignHeader } from 'common/components';
import {
	useGetLoggedInUserQuery,
	useLazyGetAllParticipantIdsQuery,
	useLazyGetParticipantsIdsByProjectIdQuery,
} from 'store/api';
import React, { useState } from 'react';
import { convertParticipantsSelection } from 'common/utils';
import { ParticipantsColumnIds } from './ParticipantsColumnIds';

export const useParticipantsData = (projectId: ProjectId | undefined) => {
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);

	const loggedUser = useGetLoggedInUserQuery();
	const criteriaColumns: ColumnDef<Participant>[] = createCriteriaColumns();
	const [getParticipantIds] = useLazyGetAllParticipantIdsQuery();
	const [getParticipantIdsByProject] = useLazyGetParticipantsIdsByProjectIdQuery();

	const personalColumns: ColumnDef<Participant>[] = [
		{
			id: ParticipantsColumnIds.Email,
			header: 'Email',
			accessorFn: (p) => p.email,
		},
		{
			id: ParticipantsColumnIds.FirstName,
			header: 'First',
			accessorFn: (p) => p.firstName,
		},
		{
			id: ParticipantsColumnIds.LastName,
			header: 'Last Name',
			accessorFn: (p) => p.lastName,
		},
	];

	const assignColumn: ColumnDef<Participant> = {
		id: ParticipantsColumnIds.Assign,
		header: ({ table }) => {
			return (
				<TableAssignHeader
					title="Assign"
					selected={table.getIsAllPageRowsSelected()}
					onAssignAllClick={() => onAssignChange(table, true)}
					onRemoveAllClick={() => onAssignChange(table, false)}
				/>
			);
		},
		cell: ({ row }) => {
			return (
				<TableCheckbox
					checked={row.getIsSelected()}
					onChange={row.getToggleSelectedHandler()}
				/>
			);
		},
		accessorFn: () => null,
		enableSorting: false,
		enableColumnFilter: false,
	};

	const columns = React.useMemo<ColumnDef<Participant>[]>(
		() => personalColumns.concat(criteriaColumns).concat(assignColumn),
		[loggedUser.isSuccess],
	);

	const visibleColumns = columns.filter((col) =>
		[
			ParticipantsColumnIds.Email,
			ParticipantsColumnIds.FirstName,
			ParticipantsColumnIds.LastName,
			ParticipantsColumnIds.Assign,
		].includes(col.id as string),
	);

	return {
		columns,
		visibleColumns,
		selectedParticipantIds,
		setSelectedParticipantIds,
	};

	async function onAssignChange(table: Table<Participant>, assign: boolean) {
		const filters = table.getState().columnFilters;
		const participantIds = await getParticipantsIds(filters);
		const selection: Record<string, boolean> = { ...table.getState().rowSelection };

		participantIds.forEach((id) => (selection[id] = assign));
		table.setRowSelection(() => selection);

		const selectedIds = convertParticipantsSelection(selection);
		setSelectedParticipantIds(selectedIds);
	}

	function createCriteriaColumns(): ColumnDef<Participant>[] {
		const criteria = loggedUser.data?.configuration.criteriaConfiguration ?? [];

		const createColumn = (criterion: Criterion): ColumnDef<Participant> => ({
			id: `c${criterion.id}`,
			header: criterion.name,
			accessorFn: (participant: Participant) => participant.criteria.findById(criterion.id).name,
		});

		return criteria.map(createColumn);
	}

	function createFiltersList(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}

	function getParticipantsIds(filters: ColumnFilter[]): Promise<ParticipantId[]> {
		const list: string[] = [];
		const options = {
			filters: createFiltersList(filters),
			list,
		};
		if (projectId) {
			return getParticipantIdsByProject({ ...options, projectId }).unwrap();
		}

		return getParticipantIds(options).unwrap();
	}
};
