import { CustomFormItemLayoutProps } from './CustomFormItemLayout.types';
import { CustomItemWrapper, ErrorText, CustomFormItemWrapper, Title } from './styled';

export const CustomFormItemLayout = ({
	title,
	error,
	errorText,
	required,
	disabled,
	children,
}: CustomFormItemLayoutProps) => {
	return (
		<CustomFormItemWrapper $error={error}>
			<Title>{`${title}${required && !disabled ? '*' : ''}`}</Title>
			<CustomItemWrapper>
				{children}
				<ErrorText $error={error}>{error && errorText}</ErrorText>
			</CustomItemWrapper>
		</CustomFormItemWrapper>
	);
};
