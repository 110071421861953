import { Dna, prepareSingleDnaGraphData } from 'features/Graphs/RadarGraph/hoc/utils';

export const useOdnaRadarGraphData = (
	odna: Dna,
	clusterIndex: number,
	benchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const checkedBenchmarks = combinedMode ? pnoBenchmarkIndexes : benchmarkIndexes;

	return prepareSingleDnaGraphData(odna, checkedBenchmarks, clusterIndex);
};
