import { BenchmarksNamesKey } from 'store/index';

export const getBenchmarkLabels = (benchmarkNames: Record<BenchmarksNamesKey, string>) => {
	return new Map(
		Object.entries({
			b1: benchmarkNames.benchmark1,
			b2: benchmarkNames.benchmark2,
			b3: benchmarkNames.benchmark3,
			b4: benchmarkNames.benchmark4,
			b5: benchmarkNames.benchmark5,
		}),
	);
};
