import { Input } from 'common/components/Input';
import styled from 'styled-components';
import { InputContainer, StyledInput as $StyledInput } from 'common/components/Input/styled';
import { StyledInputProps } from './BenchmarkSettingsControl.types';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledInput = styled(Input)<StyledInputProps>`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	color: ${({ theme }) => theme.colors.common.darkGrey};

	${$StyledInput} {
		height: 2.5rem;
	}

	${InputContainer} {
		border-color: ${({ $isEmpty, theme }) => ($isEmpty ? theme.colors.common.grey : theme.colors.common.black)};
		background-color: ${({ theme }) => theme.colors.common.white};

		&:hover,
		&:focus-within {
			border-color: ${({ theme }) => theme.colors.common.black};
		}
	}
`;
