export type AutomaticClosureConditionsProps = {
	date?: string;
	percentageOfQuestionnaires?: number;
	disableDate?: boolean;
	disablePercentageOfQuestionnaires?: boolean;
	saveTrigger?: number;
};

export const ClosureDateState = {
	INVALID: 'invalid',
	EMPTY: undefined,
};
