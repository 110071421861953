import { AssessmentTypes, TableAssessmentTypes } from 'common/constants';
import { AssessmentType } from 'common/types';

export const getAssessmentName = (value: string) => {
	if (Object.keys(AssessmentTypes).includes(value)) {
		return TableAssessmentTypes[value as AssessmentType];
	}

	return value;
};
