import { NotificationTypes, PageLayout, useNotifications } from 'common/components';
import {
	ActionPanel,
	ProjectSummaryPanel,
	ViewAdditionalQuestionsButton,
} from 'features/ResultsConfiguration/components';
import { RoadMapBenchmarksContent } from 'features/RoadMap/components/RoadMapBenchmarksContent';
import { useProjectDetails } from 'common/hooks';
import { useParams } from 'react-router-dom';
import { SelectedCounterText } from 'common/components/PageLayout/styled';
import { useValidateBenchmarkConfigurationMutation } from 'store/api';
import { HttpResponseStatusCodes } from 'common/types';
import { isResponseError } from 'common/utils';
import { RoadMapBenchmarksProps } from './RoadMapBenchmarks.types';
export const RoadMapBenchmarks = ({
	benchmarkedCriterion,
	onSaveButtonClick,
	onReturnButtonClick,
	selectedParticipantsCount,
}: RoadMapBenchmarksProps) => {
	const { projectId } = useParams();
	const { projectDetails } = useProjectDetails({ projectId });
	const [validateBenchmark] = useValidateBenchmarkConfigurationMutation();
	const { pushNotification } = useNotifications();

	return (
		<>
			<PageLayout
				title="Benchmark Comparison"
				subtitle="Select and assign participants to a comparison scenario"
				RightSideComponent={
					<SelectedCounterText>{`${selectedParticipantsCount} participants selected`}</SelectedCounterText>
				}
				id={projectDetails?.shortId}
			>
				<ActionPanel
					currentSelectedTab={0}
					onSaveButtonClick={() => validateThenSave()}
					onViewButtonClick={() => () => null}
					onReturnButtonClick={() => onReturnButtonClick(benchmarkedCriterion)}
					onTabChange={() => null}
					tabs={[{ label: 'Benchmarks' }]}
				>
					{projectDetails?.shortId && (
						<ProjectSummaryPanel
							projectShortId={projectDetails?.shortId}
							closeDate={projectDetails?.closeDate}
						/>
					)}

					<ViewAdditionalQuestionsButton />
				</ActionPanel>
				<RoadMapBenchmarksContent
					id="predictive-view"
					benchmarkConfigurationId={benchmarkedCriterion.benchmarkConfigurationId}
					benchmarkedCriteriaId={benchmarkedCriterion.criteriaId}
				/>
			</PageLayout>
		</>
	);

	async function validateThenSave() {
		const result = await validateBenchmark(benchmarkedCriterion.benchmarkConfigurationId).unwrap();
		if (isResponseError(result)) {
			type Error = { status: number; message: string };
			const errorObj = result as Error;

			if (errorObj.status === HttpResponseStatusCodes.BAD_REQUEST) {
				const errorMessage = errorObj.message as string;
				pushNotification({ message: errorMessage, type: NotificationTypes.Error });
			}
			return;
		}

		onSaveButtonClick(benchmarkedCriterion);
	}
};
