import styled from 'styled-components';
import { StyledContainerProps } from './Popper.types';

export const Container = styled.div<StyledContainerProps>`
	position: absolute;
	visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};

	left: calc(${({ $left }) => $left}px);
	top: calc(${({ $bottom }) => $bottom}px);
`;
