import { Criterion, User, UserConfiguration, UserPermission, UserRole } from 'common/types';
import { getEmptyLoggedUserConfigurationResponse } from '..';

type ApiUser = User & {
	username: string;
	authenticatedWithSSO: boolean;
	permissions: (UserPermission | UserRole)[];
	configuration: ApiUserConfiguration;
};

export type ApiUserConfiguration = Pick<UserConfiguration, 'branding'> & {
	criteriaConfiguration: ApiCriteriaConfiguration;
};

type ApiCriteriaConfiguration = {
	criteria: ApiCriterion[];
};

type ApiCriterion = {
	number: string;
	name: string;
	enabled: boolean;
};

export const mapLoggedUserResponse = (user: ApiUser): User => {
	const configuration = user ? user.configuration : getEmptyLoggedUserConfigurationResponse();

	const criteriaConfiguration = configuration.criteriaConfiguration
		? configuration.criteriaConfiguration.criteria.map(convertCriterion)
		: [];

	return {
		...user,
		configuration: {
			...configuration,
			criteriaConfiguration,
		},
	};
};

function convertCriterion(criterion: ApiCriterion): Criterion {
	const id = criterion.number;
	return {
		...criterion,
		id,
	};
}
