import { PredictiveResult } from 'store/api/predictiveApi';
import { OddsRatioLegendConfig, OddsRatioGraphConfig } from 'features/Graphs/OddsRatioGraph';
import { VerticalBarFactory } from 'features/Graphs/OddsRatioGraph';
import { OddsRatioConfig } from 'features/Graphs/OddsRatioGraph/hooks/useOddsRatioCreator.types';

export const useOddsRatioCreator = (data: PredictiveResult, config: OddsRatioConfig) => {
	const defaultTextSize = 21;
	const quartileInfo = {
		enabled: config.enableQuartileInfo,
		textSize: config.quartileInfoTextSize || defaultTextSize,
	};

	const graphConfig = {
		...OddsRatioGraphConfig,
		legend: { ...OddsRatioGraphConfig.legend, quartileInfo },
	};
	return {
		buildPersonalGraph: () => {
			const graphCreator = new VerticalBarFactory();

			const personal = 0;
			const oddsRatioData = data.oddsRatios[personal];

			return graphCreator.createVerticalBar(
				'Predictive Analytics for Personal Behaviours',
				oddsRatioData,
				{
					title: 'Personal Quartiles',
					subtitle: 'Personal Behaviours',
				},
				graphConfig,
				OddsRatioLegendConfig,
			);
		},
		buildOrganisationalGraph: () => {
			const graphCreator = new VerticalBarFactory();

			const organisational = 1;
			const oddsRatioData = data.oddsRatios[organisational];

			return graphCreator.createVerticalBar(
				'Predictive Analytics for Organisational Behaviours',
				oddsRatioData,
				{
					title: 'Organisational Quartiles',
					subtitle: 'Organisational Behaviours',
				},
				graphConfig,
				OddsRatioLegendConfig,
			);
		},
	};
};
