import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { CheckboxInput, CheckboxIcon, CheckboxLabel } from './styled';

type TableCheckboxProps = {
	className?: string;
	indeterminate?: boolean;
	onChange: (event: unknown) => void;
	checked: boolean;
};

export const TableCheckbox = ({ className, checked, onChange }: TableCheckboxProps) => {
	const checkboxIcon = checked ? faSquareCheck : faSquare;
	return (
		<CheckboxLabel className={className}>
			<CheckboxIcon icon={checkboxIcon} />
			<CheckboxInput
				type="checkbox"
				checked={checked}
				onChange={onChange}
			/>
		</CheckboxLabel>
	);
};
