import {
	ApplyButton,
	QuadrantMarginsProps,
	StyledInputColumn,
	StyledInputPrompt,
	StyledQuadrantSliderWrapper,
	StyledSliderColumn,
	StyledText,
	StyledTextColumn,
} from 'features/DiscoveryInsights/components';
import { Container, StyledInputContainer, StyledTitle } from 'features/DiscoveryInsights/components';
import { StyledRow } from '../QuadrantTypeSelection/styled';
import { Input } from 'common/components';
import { QuadrantInputFormatter } from './model/QuadrantInputFormatter';
import { ChangeEvent, useEffect, useState } from 'react';
import { QuadrantInputValidator } from './model/QuadrantInputValidator';
import { QuadrantSlider } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantMargins/component';

export const QuadrantMargins = ({
	quadrantCenter: initialQuadrantCenter,
	onApply,
	clearTrigger,
}: QuadrantMarginsProps) => {
	const fixedDigits = 2;
	const promptText = 'Type a value between 1.00 and 4.00';
	const [quadrantCenter, setQuadrantCenter] = useState(initialQuadrantCenter);
	const [pdnaText, setPdnaText] = useState(formatAsText(quadrantCenter.x));
	const [pdnaError, setPDNAError] = useState(false);
	const [pdnaSelected, setPdnaSelected] = useState(false);

	const [odnaText, setOdnaText] = useState(formatAsText(quadrantCenter.y));
	const [odnaError, setODNAError] = useState(false);
	const [odnaSelected, setOdnaSelected] = useState(false);
	const [changesWereMade, setChangesWereMade] = useState(false);

	useEffect(() => {
		setQuadrantCenter(initialQuadrantCenter);
	}, [clearTrigger]);

	useEffect(() => {
		updateChangesWereMade();
		updatePdnaOdnaText();
	}, [quadrantCenter]);

	const pdnaFormatter = new QuadrantInputFormatter(quadrantCenter.x);
	const odnaFormatter = new QuadrantInputFormatter(quadrantCenter.y);
	const quadrantInputValidator = new QuadrantInputValidator();
	return (
		<Container>
			<StyledTitle>Quadrant Margins</StyledTitle>
			<StyledRow>
				<StyledTextColumn>
					<StyledText>PDNA Quadrant</StyledText>
					<StyledText>ODNA Quadrant</StyledText>
				</StyledTextColumn>
				<StyledInputColumn>
					<StyledInputContainer>
						<Input
							formInput
							value={pdnaText}
							onFocus={() => setPdnaSelected(true)}
							onChange={onDnaChange(setPdnaText, setPDNAError)}
							onBlur={() => {
								const pdna = pdnaFormatter.format(pdnaText);
								notifyAboutPdnaChange(pdna);
								resetPdnaPrompt();
							}}
						/>
					</StyledInputContainer>
					<StyledInputContainer>
						<Input
							formInput
							value={odnaText}
							onFocus={() => setOdnaSelected(true)}
							onChange={onDnaChange(setOdnaText, setODNAError)}
							onBlur={() => {
								const odna = odnaFormatter.format(odnaText);
								notifyAboutOdnaChange(odna);
							}}
						/>
					</StyledInputContainer>
				</StyledInputColumn>
				<StyledSliderColumn>
					<StyledQuadrantSliderWrapper>
						<StyledInputPrompt
							$visible={pdnaSelected}
							$error={pdnaError}
						>
							{promptText}
						</StyledInputPrompt>
						<QuadrantSlider
							value={quadrantCenter.x}
							onChange={(value) => notifyAboutPdnaChange(value.toString())}
						/>
					</StyledQuadrantSliderWrapper>
					<StyledQuadrantSliderWrapper>
						<StyledInputPrompt
							$visible={odnaSelected}
							$error={odnaError}
						>
							{promptText}
						</StyledInputPrompt>
						<QuadrantSlider
							value={quadrantCenter.y}
							onChange={(value) => notifyAboutOdnaChange(value.toString())}
						/>
					</StyledQuadrantSliderWrapper>
				</StyledSliderColumn>
				<ApplyButton
					$highlight={changesWereMade}
					onClick={() => {
						setChangesWereMade(false);
						onApply(quadrantCenter);
					}}
				>
					APPLY
				</ApplyButton>
			</StyledRow>
		</Container>
	);

	function updateChangesWereMade() {
		const pdnaHasChanged = initialQuadrantCenter.x !== quadrantCenter.x;
		const odnaHasChanged = initialQuadrantCenter.y !== quadrantCenter.y;
		setChangesWereMade(pdnaHasChanged || odnaHasChanged);
	}

	function updatePdnaOdnaText() {
		const pdna = pdnaFormatter.format(quadrantCenter.x.toString());
		const odna = odnaFormatter.format(quadrantCenter.y.toString());
		setPdnaText(pdna);
		setOdnaText(odna);
	}

	function onDnaChange(setDnaText: (text: string) => void, setDnaError: (error: boolean) => void) {
		return (event: ChangeEvent<HTMLInputElement>) => {
			const fromStart = 0;
			const maxLength = 4;
			const value = event.target.value.substring(fromStart, maxLength).replace(/[^\d.-]/g, '');
			setDnaText(value);
			setDnaError(!quadrantInputValidator.validate(value));
		};
	}

	function formatAsText(val: number) {
		return val.toFixed(fixedDigits).replace(',', '.');
	}

	function resetOdnaPrompt() {
		setODNAError(false);
		setOdnaSelected(false);
	}

	function resetPdnaPrompt() {
		setPDNAError(false);
		setPdnaSelected(false);
	}

	function notifyAboutOdnaChange(y: number | string) {
		setQuadrantCenter({ ...quadrantCenter, y: Number(y) });
		resetOdnaPrompt();
	}

	function notifyAboutPdnaChange(x: number | string) {
		setQuadrantCenter({ ...quadrantCenter, x: Number(x) });
		resetPdnaPrompt();
	}
};
