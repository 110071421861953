import { TensionGraphModalContent, TensionGraphExportButton } from 'features/Graphs/TensionGraph/components';
import { StyledModal } from 'features/Graphs/TensionGraph/TensionGraphModal/styled';
import { TensionGraphModalProps } from './TensionGraphModal.types';
import { useTensionGraphClusterExportService } from 'features/Graphs/TensionGraph/hooks/export';
import { ZIndexes } from 'common/constants';

export const TensionGraphModal = ({
	open,
	onClose,
	tensionGraphRequest,
	tensionGraphFactory,
}: TensionGraphModalProps) => {
	const exportService = useTensionGraphClusterExportService(tensionGraphRequest, tensionGraphFactory);

	return (
		<>
			<StyledModal
				id="tension-graph-modal"
				isOpen={open}
				onClose={onClose}
				headerChildren={exportButton()}
				backdropZIndex={ZIndexes.tensionGraphModalBackdrop}
			>
				{tensionGraph()}
			</StyledModal>
		</>
	);

	function tensionGraph() {
		const graphBuilder = () => {
			return tensionGraphFactory(tensionGraphRequest);
		};
		return <TensionGraphModalContent graphBuilder={graphBuilder} />;
	}

	function exportButton() {
		return <TensionGraphExportButton exportService={exportService} />;
	}
};
