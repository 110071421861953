import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { CreateManagerSlice } from './createManagerSlice.types';

const initialState: CreateManagerSlice = {
	name: '',
	password: '',
	retypedPassword: '',
	username: '',
};

export const createManagerSlice = createSlice({
	name: 'createManager',
	initialState,
	reducers: {
		setCreateManagerState: (state, { payload }: PayloadAction<CreateManagerSlice>) => {
			state = payload;
			return state;
		},

		resetCreateAdminState: () => initialState,
	},
});

export const { resetCreateAdminState, setCreateManagerState } = createManagerSlice.actions;

export const selectCreateManagerState = (state: RootState) => state.createManager;
