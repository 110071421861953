import styled from 'styled-components';
import { Button } from 'common/components';

export const Container = styled('div')`
	padding: 15px;
`;

export const CloseButton = styled(Button)`
	margin-top: 2rem;
	flex-grow: 1;
	margin-right: 1rem;
	margin-left: auto;
`;

export const StyledTitle = styled('div')`
	font-size: 2.5rem;
	border-bottom: 2px solid ${({ theme }) => theme.colors.common.black};
	padding-left: 1rem;
	line-height: 3rem;
`;
