import { CustomFormItemLayout, FormInputFile, FormTextarea } from 'common/components';
import { FileTypeValidator } from 'common/components/UploadData/ValidationRules/FileValidatorType';
import { downloadFile, emptyIfNullOrUndefined, undefinedIfEmptyString } from 'common/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetQuestionsConfigFileTemplateQuery } from 'store/api';
import { AdditionalQuestionsState, selectAdditionalQuestionsState, setAdditionalQuestionsState } from 'store/slices';
import { AdditionalQuestionsProps } from './AdditionalQuestions.types';
import { DownloadFileLink } from './styled';

export const AdditionalQuestions = ({ saveTrigger }: AdditionalQuestionsProps) => {
	const dispatch = useDispatch();
	const additionalQuestions = useSelector(selectAdditionalQuestionsState);

	const [introduction, setIntroduction] = useState(emptyIfNullOrUndefined(undefined));
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [fileError, setFileError] = useState(false);
	const fileTypeValidator = new FileTypeValidator();

	const [getQuestionsConfigFileTemplate] = useLazyGetQuestionsConfigFileTemplateQuery();

	useEffect(() => {
		setSliceState();
	}, [saveTrigger, selectedFile]);

	const setSliceState = () => {
		const fileObjectUrl = selectedFile ? URL.createObjectURL(selectedFile) : undefined;
		const filename = selectedFile ? selectedFile.name : undefined;
		const sliceState: AdditionalQuestionsState = {
			introduction: undefinedIfEmptyString(introduction),
			fileObjectUrl: fileObjectUrl,
			filename: filename,
		};

		dispatch(setAdditionalQuestionsState(sliceState));
	};

	const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, fileExtension: string) => {
		const first = 0;
		const file = event.target.files && event.target.files[first];
		if (selectedFile !== file && file) {
			const typeValidation = fileTypeValidator.typeValidate(file, fileExtension);

			if (additionalQuestions.fileObjectUrl) {
				URL.revokeObjectURL(additionalQuestions.fileObjectUrl);
			}

			if (!typeValidation.isValid) {
				setFileError(true);
			} else {
				setFileError(false);
			}

			setSelectedFile(file);
		}
	};

	const downloadConfigTemplateFile = async () => {
		try {
			const response = await getQuestionsConfigFileTemplate().unwrap();
			downloadFile(response.objectUrl, response.filename);
		} catch {}
	};

	return (
		<>
			<FormTextarea
				value={introduction}
				title="Introduction"
				minHeight="10rem"
				placeholder="Max 300 characters"
				maxLength={300}
				onChange={(event) => {
					setIntroduction(event.target.value);
				}}
				onBlur={(event) => {
					setIntroduction(event.target.value);
					setSliceState();
				}}
			/>
			<CustomFormItemLayout title="Additional Questions Configuration Template">
				<DownloadFileLink onClick={downloadConfigTemplateFile}>
					questions_configuration_template.csv
				</DownloadFileLink>
			</CustomFormItemLayout>
			<FormInputFile
				title="Additional Questions Configuration File"
				error={fileError}
				errorText="Only comma separated value files (*.csv) are allowed."
				fileInputProps={{
					accept: '.csv',
					placeholder: 'Choose file...',
					onChange: (event) => {
						handleFileInputChange(event, '.csv');
					},
				}}
			/>
		</>
	);
};
