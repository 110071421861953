import { ApiFrameworkDetails, FrameworkDetails, FrameworkId } from 'common/types';
import { FrameworkDetailsResponse, useGetFrameworkDetailsQuery } from 'store/api';
import { useClusters } from '../clusters';
import { useDimensions } from '../dimensions';
import { mapGetFrameworkDetailsResponse } from './mappers';

type UseFrameworkDetailsProps = {
	frameworkId?: FrameworkId;
};

export const useFrameworkDetails = (option?: UseFrameworkDetailsProps) => {
	const apiFrameworkDetails = useGetFrameworkDetailsQuery(
		{ frameworkId: option?.frameworkId as FrameworkId },
		{ skip: option?.frameworkId == null },
	);
	const { clusters } = useClusters();
	const { dimensions } = useDimensions();
	const apiFramework = getApiFrameworkDetailsData();

	return {
		frameworkDetails: apiFramework ? transform(apiFramework) : undefined,
		isFrameworkDetailsFetched: apiFrameworkDetails.isSuccess,
	};

	function getApiFrameworkDetailsData(): FrameworkDetailsResponse<ApiFrameworkDetails> | undefined {
		if (!apiFrameworkDetails.isSuccess) return undefined;

		return apiFrameworkDetails.data;
	}

	function transform(
		apiFrameworkDetails: FrameworkDetailsResponse<ApiFrameworkDetails>,
	): FrameworkDetailsResponse<FrameworkDetails> {
		return mapGetFrameworkDetailsResponse(apiFrameworkDetails, clusters, dimensions);
	}
};
