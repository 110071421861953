import { TableIconButton, TableWrapper } from 'features/Tables/components';
import { Tooltip } from 'common/components';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { RoadmapSummary } from 'store/api/roadmapApi/roadmapApi.types';
import { faEye, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { getDateFromUnixUTC } from 'common/utils';
import { DateFormatPatterns } from 'common/constants';
import { CellContext } from '@tanstack/table-core/build/lib/core/cell';

export const useRoadmapColumns = ({
	onRoadmapDelete,
	onRoadmapView,
}: {
	onRoadmapView: (roadmap: RoadmapSummary) => void;
	onRoadmapDelete: (roadmap: RoadmapSummary) => void;
}) => {
	const actions = [
		{
			label: 'View roadmap',
			icon: faEye,
			onClick: (roadmap: RoadmapSummary) => roadmap && onRoadmapView(roadmap),
		},
		{
			label: 'Delete roadmap',
			icon: faTrashCan,
			onClick: (roadmap: RoadmapSummary) => {
				roadmap && onRoadmapDelete(roadmap);
			},
		},
	];

	return React.useMemo<ColumnDef<RoadmapSummary>[]>(createColumns, []);

	function createColumns() {
		return [
			{
				id: 'roadmapName',
				header: 'Roadmap Name',
				accessorKey: 'name',
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: 'dateCreated',
				header: 'Date Created',
				accessorKey: 'dateCreated',
				accessorFn: (row: RoadmapSummary) =>
					format(getDateFromUnixUTC(parseFloat(row.dateCreated)), DateFormatPatterns.Default),
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: 'numberOfOutcomes',
				header: 'Number Of Outcomes',
				accessorKey: 'numberOfOutcomes',
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: 'actions',
				header: 'Actions',
				accessorKey: 'actions',
				enableSorting: false,
				enableColumnFilter: false,
				accessorFn: (roadmap: RoadmapSummary) => roadmap,
				cell: ({ getValue }: CellContext<RoadmapSummary, unknown>) => {
					const roadmap = getValue<RoadmapSummary>();
					return (
						<TableWrapper>
							{actions.map((action) => {
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											onClick={() => action.onClick(roadmap)}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
			},
		];
	}
};
