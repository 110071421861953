import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ClusterId, Dimension, FrameworkDetails } from 'common/types';
import { FrameworkState } from '.';
import { RootState } from 'store/store';

const initialState: FrameworkState = {
	editedFramework: undefined,
	chosenEditedDimensionClusterId: undefined,
};

export const frameworkSlice = createSlice({
	name: 'framework',
	initialState,
	reducers: {
		setEditedFramework: (state, { payload }: PayloadAction<FrameworkDetails>) => {
			state.editedFramework = payload;
		},
		setEditedFrameworkName: (state, { payload }: PayloadAction<string>) => {
			if (state.editedFramework) {
				state.editedFramework.name = payload;
			}
		},
		setEditedFrameworkDescription: (state, { payload }: PayloadAction<string>) => {
			if (state.editedFramework) {
				state.editedFramework.description = payload;
			}
		},
		setEditedFrameworkClusterName: (state, { payload }: PayloadAction<{ clusterName: string }>) => {
			if (state.editedFramework) {
				state.editedFramework?.clusters.map((dimensionCluster) => {
					if (dimensionCluster.cluster.clusterId === state.chosenEditedDimensionClusterId) {
						dimensionCluster.cluster.name = payload.clusterName;
					}
				});
			}
		},
		setChosenEditedDimensionClusterId: (state, { payload }: PayloadAction<ClusterId>) => {
			state.chosenEditedDimensionClusterId = payload;
		},
		setEditedFrameworkDimensionsForSelectedCluster: (state, { payload }: PayloadAction<Dimension[]>) => {
			const editedDimensionCluster = state.editedFramework?.clusters.map((dimensionCluster) => {
				if (dimensionCluster.cluster.clusterId === state.chosenEditedDimensionClusterId) {
					return {
						cluster: dimensionCluster.cluster,
						selectedDimensions: payload,
					};
				}

				return dimensionCluster;
			});

			if (editedDimensionCluster && state.editedFramework) {
				state.editedFramework = { ...state.editedFramework, clusters: editedDimensionCluster };
			}
		},
		resetFrameworkState: () => initialState,
	},
});

export const {
	setEditedFramework,
	setEditedFrameworkName,
	setEditedFrameworkDescription,
	setEditedFrameworkClusterName,
	setChosenEditedDimensionClusterId,
	setEditedFrameworkDimensionsForSelectedCluster,
	resetFrameworkState,
} = frameworkSlice.actions;
export const frameworkSliceReducer = frameworkSlice.reducer;

export const selectEditedFramework = (state: RootState) => state.framework.editedFramework;

export const selectSelectedEditedDimensionCluster = (state: RootState) => {
	const { chosenEditedDimensionClusterId, editedFramework } = state.framework;
	return editedFramework?.clusters.find(
		(dimensionCluster) => dimensionCluster.cluster.clusterId === chosenEditedDimensionClusterId,
	);
};

export const selectEditedDimensionsForChosenCluster = (state: RootState) => {
	const { chosenEditedDimensionClusterId, editedFramework } = state.framework;
	const foundDimensionCluster = editedFramework?.clusters.find(
		(dimensionCluster) => dimensionCluster.cluster.clusterId === chosenEditedDimensionClusterId,
	);

	return foundDimensionCluster?.selectedDimensions;
};
