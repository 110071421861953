import { Input } from 'common/components/Input';
import { Button } from 'common/components/Button';
import { Tabs } from 'common/components/Tabs';
import styled from 'styled-components';
import { InputContainer, StyledInput as $StyledInput, InputLabel } from 'common/components/Input/styled';

export const BenchmarksContainer = styled.div`
	border-radius: 2.5rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	display: grid;
	align-items: start;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	padding: 2rem 1rem 0.5rem 1rem;
	background-color: ${({ theme }) => theme.colors.common.white};
	border: 1px solid ${({ theme }) => theme.colors.common.silver};
`;

export const StyledTabs = styled(Tabs)`
	display: flex;
`;

export const UnassignButton = styled(Button)`
	text-transform: uppercase;
	width: 97.5%;
	font-size: 1.3rem;
	padding: 0.3rem 1.5rem;
	color: ${({ theme }) => theme.colors.primary.main};
	background-color: ${({ theme }) => theme.colors.common.white};
	border: 0.05rem solid ${({ theme }) => theme.colors.common.silver};
`;

export const StyledInput = styled(Input)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	color: ${({ theme }) => theme.colors.common.darkGrey};

	${$StyledInput} {
		height: 2.8rem;
	}

	${InputContainer} {
		border-color: ${({ theme }) => theme.colors.common.silver};
		&:hover,
		&:focus-within {
			border-color: ${({ theme }) => theme.colors.common.black};
		}
	}

	${InputLabel} {
		width: 26%;
	}
`;
