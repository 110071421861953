import { ApiManager, Manager } from 'common/types';

export const mapGetAllManagersResponse = (apiManagers: ApiManager[]): Manager[] => {
	const mappedManagers: Manager[] = apiManagers.map((apiManager) => {
		return mapApiManager(apiManager);
	});

	return mappedManagers;
};

export const mapApiManager = (apiManager: ApiManager): Manager => {
	const { id, password: _, retypedPassword: __, ...apiManagerCopy } = apiManager;
	return {
		...apiManagerCopy,
		managerId: id,
	};
};
