import { ConfirmationDialogProps } from './ConfirmationDialog.types';
import { ConfirmButtonsWrapper, StyledButton, StyledModal } from './styled';

export const ConfirmationDialog = ({
	id,
	isOpen,
	onClose,
	onCancel,
	onConfirm,
	title,
	...additionalModalProps
}: ConfirmationDialogProps) => {
	const onCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
		onClose();
	};

	const onConfirmClick = () => {
		onConfirm();
		onClose();
	};

	return (
		<StyledModal
			id={id}
			isOpen={isOpen}
			onClose={onClose}
			hiddenCloseButton
			{...additionalModalProps}
		>
			{title}
			<ConfirmButtonsWrapper>
				<StyledButton
					color={'primary'}
					underlineOnHover
					onClick={onCancelClick}
					variant="outlined"
				>
					NO
				</StyledButton>
				<StyledButton
					color={'primary'}
					variant="filled"
					underlineOnHover
					onClick={onConfirmClick}
				>
					YES
				</StyledButton>
			</ConfirmButtonsWrapper>
		</StyledModal>
	);
};
