import styled from 'styled-components';
import { SvgContainer } from 'features/Graphs';
import { Modal } from 'common/components/Modal';

export const StyledSvgContainer = styled(SvgContainer)`
	width: 100%;
	height: 100%;
`;

export const StyledModal = styled(Modal)`
	height: 80%;
`;
