import { Tab } from 'common/components';
import { ActionPanelProps } from './ActionPanel.types';
import { ActionPanelContainer, StyledTabs, SaveButton, ViewButton, ReturnButton } from './styled';

export const ActionPanel = ({
	tabs,
	currentSelectedTab,
	onTabChange,
	onSaveButtonClick,
	onViewButtonClick,
	onReturnButtonClick,
	children,
}: ActionPanelProps) => {
	const getTabs = () => {
		return tabs.map((tab, index) => (
			<Tab
				key={index}
				label={tab.label}
				faded={tab.faded}
			/>
		));
	};

	const getFirstTabButtons = () => {
		return (
			<>
				<ViewButton onClick={onViewButtonClick}>View</ViewButton>
				{onReturnButtonClick ? (
					<ReturnButton
						onClick={onReturnButtonClick}
						variant="outlined"
					>
						Return
					</ReturnButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const getSecondTabButtons = () => {
		return (
			<>
				<SaveButton onClick={onSaveButtonClick}>Save</SaveButton>
				{onReturnButtonClick ? (
					<ReturnButton
						onClick={onReturnButtonClick}
						variant="outlined"
					>
						Return
					</ReturnButton>
				) : (
					<></>
				)}
			</>
		);
	};

	return (
		<ActionPanelContainer>
			<StyledTabs
				onChange={onTabChange}
				value={currentSelectedTab}
			>
				{getTabs()}
			</StyledTabs>
			{currentSelectedTab ? getFirstTabButtons() : getSecondTabButtons()}
			{children}
		</ActionPanelContainer>
	);
};
