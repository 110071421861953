import styled from 'styled-components';

export const StyledPanel = styled.div`
	min-width: 160px;
	max-width: 35%;
	padding-top: 9rem;
	pointer-events: all;
`;

export const StyledCentered = styled.div`
	display: flex;
	padding: 1rem;
	padding-bottom: 3rem;
`;
