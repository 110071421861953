import { FilePickerFormProps } from './FilePickerFormProps.types';
import {
	ContentWrapper,
	LogoConfigItem,
	LogoConfigItemLabel,
	LogoCustomizationWrapper,
	StyledInputFile,
	LogoSaveButton,
	LogoSaveButtonBlock,
	UnderLine,
	LogoConfigItemName,
	UploadBlock,
} from './styled';
import { FileTypeValidator } from '../ValidationRules/FileValidatorType';
import { FileRequiredValidator } from '../ValidationRules/FileValidatorRequire';
import { useState } from 'react';

export const FilePickerForm = ({
	labelName: labelName,
	previewName,
	buttonName,
	inputLabel,
	placeholderTitle,
	fileType,
	onFileSave,
	underline,
	errorText,
}: FilePickerFormProps) => {
	const first = 0;
	const fileTypeValidator = new FileTypeValidator();
	const fileRequiredValidator = new FileRequiredValidator();

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [fileName, setFileName] = useState('');

	const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, fileExtension: string) => {
		const file = event.target.files && event.target.files[first];
		if (event.target.files) setFileName(event.target.files[first].name);

		if (selectedFile !== file && file) {
			const typeValidator = fileTypeValidator.typeValidate(file, fileExtension);
			const requireValidator = fileRequiredValidator.requireValidate(file);

			if (!typeValidator.isValid) {
				setError(typeValidator.isValid);
				setErrorMessage(errorText || typeValidator.errorMessage || '');
				setSelectedFile(null);
			} else if (!requireValidator.isValid) {
				setError(requireValidator.isValid);
				setErrorMessage(errorText || requireValidator.errorMessage || '');
				setSelectedFile(null);
			} else {
				setSelectedFile(file);
				setError(false);
				setErrorMessage('');
			}
		}
	};

	const handleClick = async (fileName: string) => {
		const requireValidator = new FileRequiredValidator().requireValidate(selectedFile);
		if (!requireValidator.isValid) {
			setError(!requireValidator.isValid);
			setErrorMessage(errorText || requireValidator.errorMessage || '');
		}

		if (!selectedFile) return;
		const form = new FormData();
		form.append('file', selectedFile, fileName);
		onFileSave(form);
	};
	return (
		<>
			<ContentWrapper>
				<LogoCustomizationWrapper>
					<LogoConfigItem>
						<LogoConfigItemLabel>{labelName}</LogoConfigItemLabel>
						<LogoConfigItemName>{previewName}</LogoConfigItemName>
					</LogoConfigItem>
					<UploadBlock>
						<StyledInputFile
							labelPlacement="left"
							name={inputLabel}
							error={error}
							errorText={errorMessage}
							accept={fileType}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								if (fileType) handleFileInputChange(event, fileType);
							}}
							placeholder={placeholderTitle}
						/>
					</UploadBlock>

					<LogoSaveButtonBlock>
						<LogoSaveButton
							onClick={() => {
								handleClick(fileName);
							}}
							underlineOnHover
						>
							{buttonName}
						</LogoSaveButton>
					</LogoSaveButtonBlock>
				</LogoCustomizationWrapper>
			</ContentWrapper>
			{underline && <UnderLine />}
		</>
	);
};
