import { Table } from 'common/components';
import { PercentageDistributionPerCategory } from 'store/api/discoveryInsightApi';
import { StyledScrollableContainer, StyledTableContainer, StyledTitle } from './styled';
import { usePercentageDistributionColumns } from './hooks';
import { usePercentageDistributionStyle } from './hooks';
import { compare, filterAndSortClientTableData } from 'features/Tables/utils';
import { useEffect, useState } from 'react';
import { ColumnSort } from '@tanstack/table-core/src/features/Sorting';
import { DistributionTableColumnId } from './DistributionTable.types';
import { TableQueryParams } from 'common/types';
import { TableSortingDirections } from 'common/constants';

export const DistributionTable = ({
	groupFilterThreshold,
	colorPresetId,
	data,
}: {
	groupFilterThreshold: number;
	colorPresetId: number;
	data: PercentageDistributionPerCategory[];
}) => {
	const columns = usePercentageDistributionColumns();

	const tableStyle = usePercentageDistributionStyle(colorPresetId);

	const onlyThoseWithNGreaterOrEqualThanTotalThreshold = (value: PercentageDistributionPerCategory) =>
		value.total >= groupFilterThreshold;

	const filteredData: PercentageDistributionPerCategory[] = data.filter(
		onlyThoseWithNGreaterOrEqualThanTotalThreshold,
	);

	const [distributionData, setDistributionData] = useState(filteredData);
	const [sorting, setSorting] = useState<ColumnSort>(initialSortingColumn());

	useEffect(refreshTable, [whenSortingChanges(), data]);

	return (
		<StyledScrollableContainer $shouldScroll={true}>
			<StyledTableContainer {...tableStyle}>
				<StyledTitle>Distribution table</StyledTitle>
				<Table<PercentageDistributionPerCategory>
					tableTypeId="distribution-table"
					disableColumnSelector
					columns={columns}
					data={distributionData}
					initialSorting={[sorting]}
					onQueryChange={(queryParams) => {
						setSorting(createSorting(queryParams));
						filterAndSortClientTableData(
							queryParams,
							filteredData,
							setDistributionData,
							compareCategoriesWhenValuesAreEqual,
						);
					}}
				/>
			</StyledTableContainer>
		</StyledScrollableContainer>
	);

	function compareCategoriesWhenValuesAreEqual(
		orderBy: string,
		direction: string,
		first: PercentageDistributionPerCategory,
		second: PercentageDistributionPerCategory,
	): number {
		const comparisonResult = compare(orderBy, direction, first, second);
		const indicatesThatFieldsAreEqual = 0;
		if (comparisonResult == indicatesThatFieldsAreEqual) {
			return compare(DistributionTableColumnId.Category, TableSortingDirections.Asc, first, second);
		}
		return comparisonResult;
	}

	function createSorting(queryParams: TableQueryParams) {
		return { id: queryParams.orderBy || '', desc: queryParams.direction === TableSortingDirections.Desc };
	}

	function refreshTable() {
		setDistributionData(filteredData);
	}

	function whenSortingChanges() {
		const first = 0;
		return distributionData[first];
	}

	function initialSortingColumn() {
		return { id: DistributionTableColumnId.Fifth, desc: true };
	}
};
