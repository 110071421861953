import { ApiGraphConfiguration } from 'common/types';
import {
	ProjectResultsGraphType,
	ProjectResultsGraphTypes,
	RadarGraphCategoryLabel,
	RadarGraphDataSeries,
} from 'features/Graphs';
import { isCombinedPdna, isPdna } from 'features/Graphs/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	ApiAllTranslationsResponse,
	ApiGraphResponseBenchmark,
	ApiGraphResponseBenchmarks,
	ApiResultResponse,
	GraphConfigurationResponse,
} from 'store/api';
import {
	RadarGraphBenchmark,
	RadarGraphCluster,
	setRadarCombinedODNA,
	setRadarCombinedPDNA,
	setRadarODNA,
	setRadarOPDNA,
	setRadarPDNA,
} from 'store/slices/graphSlices';
import {
	assignBenchmarks,
	getBenchmarkColor,
	getBenchmarkLineType,
	getBenchmarkName,
	getResponseClusters,
} from '../utils';
import { selectBenchmarkNames, selectBenchmarksSettings } from 'store/index';

export const useRadar = (
	result: ApiResultResponse,
	graphConfiguration: GraphConfigurationResponse<ApiGraphConfiguration>,
	translations: ApiAllTranslationsResponse,
) => {
	const dispatch = useDispatch();
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const benchmarkSettings = useSelector(selectBenchmarksSettings);

	function prepareCategoryLabels(benchmark: ApiGraphResponseBenchmark) {
		const keys = Object.keys(benchmark.dimensionKeyValueMap);
		const labels = translations.translations;
		const colors = graphConfiguration.dimensionColors.colorMap;
		const ccbMap = benchmark.dimensionKeyCCBMap;
		const categoryLabels: RadarGraphCategoryLabel[] = [];

		keys.map((key) => {
			categoryLabels.push({
				label: labels[key as keyof typeof labels],
				key,
				color: `#${colors[key as keyof typeof colors]}`,
				underline: ccbMap[key as keyof typeof ccbMap] || false,
			});
		});

		return categoryLabels;
	}

	function prepareData(
		benchmark: ApiGraphResponseBenchmark,
		type: ProjectResultsGraphType,
		benchmarkIndex: number,
	): RadarGraphDataSeries[] {
		const values = Object.values(benchmark.dimensionKeyValueMap);
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		const label = benchmarkName
			? `${benchmark.numberOfParticipants} - ${benchmarkName}`
			: `${benchmark.numberOfParticipants} - b${benchmarkIndex}`;

		return [
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkIndex, type),
				values,
				label,
				lineType: getBenchmarkLineType(benchmarkSettings, benchmarkIndex, type),
			},
		];
	}

	function fillBenchmarks(
		benchmarksData: RadarGraphBenchmark[],
		benchmarks: ApiGraphResponseBenchmarks,
		type: ProjectResultsGraphType,
	) {
		const pdnaTitle = translations.translations.GTC001
			? translations.translations.GTC001
			: 'Personal Culture / Benchmark Comparison';
		const odnaTitle = translations.translations.GTC002
			? translations.translations.GTC002
			: 'Organisation Culture / Benchmark Comparison';
		const pdnaType = isPdna(type) || isCombinedPdna(type);
		const title = pdnaType ? pdnaTitle : odnaTitle;

		const firstBenchmarkIndex = 1;
		const secondBenchmarkIndex = 2;
		const thirdBenchmarkIndex = 3;
		const fourthBenchmarkIndex = 4;
		const fifthBenchmarkIndex = 5;

		benchmarksData.push({
			categoryLabels: benchmarks.BENCHMARK_1 ? prepareCategoryLabels(benchmarks.BENCHMARK_1) : [],
			data: benchmarks.BENCHMARK_1 ? prepareData(benchmarks.BENCHMARK_1, type, firstBenchmarkIndex) : [],
			title,
		});

		benchmarksData.push({
			categoryLabels: benchmarks.BENCHMARK_2 ? prepareCategoryLabels(benchmarks.BENCHMARK_2) : [],
			data: benchmarks.BENCHMARK_2 ? prepareData(benchmarks.BENCHMARK_2, type, secondBenchmarkIndex) : [],
			title,
		});

		benchmarksData.push({
			categoryLabels: benchmarks.BENCHMARK_3 ? prepareCategoryLabels(benchmarks.BENCHMARK_3) : [],
			data: benchmarks.BENCHMARK_3 ? prepareData(benchmarks.BENCHMARK_3, type, thirdBenchmarkIndex) : [],
			title,
		});

		benchmarksData.push({
			categoryLabels: benchmarks.BENCHMARK_4 ? prepareCategoryLabels(benchmarks.BENCHMARK_4) : [],
			data: benchmarks.BENCHMARK_4 ? prepareData(benchmarks.BENCHMARK_4, type, fourthBenchmarkIndex) : [],
			title,
		});

		benchmarksData.push({
			categoryLabels: benchmarks.BENCHMARK_5 ? prepareCategoryLabels(benchmarks.BENCHMARK_5) : [],
			data: benchmarks.BENCHMARK_5 ? prepareData(benchmarks.BENCHMARK_5, type, fifthBenchmarkIndex) : [],
			title,
		});
	}

	function prepareRadarData(type: ProjectResultsGraphType) {
		const clusters: RadarGraphCluster[] = [];
		const responseClusters = getResponseClusters(result);

		Object.values(responseClusters).map((cluster) => {
			const benchmarks: ApiGraphResponseBenchmarks = {};
			assignBenchmarks(benchmarks, cluster, type);

			const benchmarksData: RadarGraphBenchmark[] = [];
			fillBenchmarks(benchmarksData, benchmarks, type);

			const currentCluster: RadarGraphCluster = {
				benchmarks: benchmarksData,
			};
			clusters.push(currentCluster);
		});

		return clusters;
	}

	const dataODNA = prepareRadarData(ProjectResultsGraphTypes.ODNA);
	const dataPDNA = prepareRadarData(ProjectResultsGraphTypes.PDNA);
	const dataCombinedPDNA = prepareRadarData(ProjectResultsGraphTypes.COMBINED_PDNA);
	const dataCombinedODNA = prepareRadarData(ProjectResultsGraphTypes.COMBINED_ODNA);
	const titleOPDNA = translations.translations.GTIC001
		? translations.translations.GTIC001
		: 'Personal vs Organisation Culture Graph';

	dispatch(setRadarODNA(dataODNA));
	dispatch(setRadarPDNA(dataPDNA));
	dispatch(setRadarCombinedPDNA(dataCombinedPDNA));
	dispatch(setRadarCombinedODNA(dataCombinedODNA));
	dispatch(setRadarOPDNA(titleOPDNA));
};
