import { QuadrantChart } from 'features/Graphs/QuadrantGraph';
import { useSelector } from 'react-redux';
import { selectQuadrantState } from 'store/slices/graphSlices';
import { BenchmarkQuadrantGraphProps } from 'features/Graphs/QuadrantGraph/BenchmarkQuadrantGraph/BenchmarkQuadrantGraph.types';
import { useQuadrantColorPreset } from 'features/Graphs/QuadrantGraph/hooks';
import { useQuadrantChartConfig } from 'features/Graphs/QuadrantGraph/hooks/useQuadrantChartConfig';

export const BenchmarkQuadrantGraph = ({ containerId, colorPresetId, clusterIndex }: BenchmarkQuadrantGraphProps) => {
	const dotColorConfig = useQuadrantColorPreset(colorPresetId);
	const chartConfig = useQuadrantChartConfig();
	const quadrant = useSelector(selectQuadrantState);

	const clusterIndexStart = 2;
	const selectedCluster = quadrant.clusters.find(
		(value) => parseInt(value.clusterId.substring(clusterIndexStart)) === clusterIndex,
	);

	if (!selectedCluster) return <></>;

	const center = nonConfigurableQuadrantCenter();

	return (
		<QuadrantChart
			containerId={containerId}
			config={chartConfig}
			center={center}
			data={selectedCluster.data}
			dotColorConfig={dotColorConfig}
		/>
	);

	function nonConfigurableQuadrantCenter() {
		return { x: 2.5, y: 2.5 };
	}
};
