import { AssessmentTypes } from 'common/constants';
import { ApiProjectSummery } from 'common/types';
import { toFixedIfDecimal } from 'common/utils';

class ProjectFinishedStatus {
	formattingResult(projectSummary?: ApiProjectSummery) {
		let percentageFinished: string;
		let finished: string;
		let notFinished: string;
		switch (projectSummary?.projectType) {
			case AssessmentTypes.OPDNA:
				const opdnaPercentageFinished = this.PercentageFinished(projectSummary);
				const opdnaFinished = this.Finished(projectSummary);
				const opdnaNotFinished = this.NotFinished(projectSummary);
				percentageFinished = `${opdnaPercentageFinished.pdnaPercentageFinished}%/${opdnaPercentageFinished.odnaPercentageFinished}%`;
				finished = `${opdnaFinished.pdnaPFinished}/${opdnaFinished.odnaFinished}`;
				notFinished = `${opdnaNotFinished.pdnaNotFinished}/${opdnaNotFinished.odnaNotFinished}`;
				return { percentageFinished, finished, notFinished };
			case AssessmentTypes.ODNA:
				const odnaPercentageFinished = this.PercentageFinished(projectSummary);
				const odnaFinished = this.Finished(projectSummary);
				const odnaNotFinished = this.NotFinished(projectSummary);
				percentageFinished = `${odnaPercentageFinished.odnaPercentageFinished}%`;
				finished = `${odnaFinished.odnaFinished}`;
				notFinished = `${odnaNotFinished.odnaNotFinished}`;
				return { percentageFinished, finished, notFinished };
			case AssessmentTypes.PDNA:
				const pdnaPercentageFinished = this.PercentageFinished(projectSummary);
				const pdnaFinished = this.Finished(projectSummary);
				const pdnaNotFinished = this.NotFinished(projectSummary);
				percentageFinished = `${pdnaPercentageFinished.pdnaPercentageFinished}%`;
				finished = `${pdnaFinished.pdnaPFinished}`;
				notFinished = `${pdnaNotFinished.pdnaNotFinished}`;
				return { percentageFinished, finished, notFinished };
			default:
				break;
		}
	}

	PercentageFinished(projectSummary?: ApiProjectSummery) {
		const fixedOffset = 1;

		const odnaPercentageFinished = toFixedIfDecimal(projectSummary?.odna?.percentageFinished, fixedOffset);
		const pdnaPercentageFinished = toFixedIfDecimal(projectSummary?.pdna?.percentageFinished, fixedOffset);
		return { odnaPercentageFinished, pdnaPercentageFinished };
	}

	Finished(projectSummary?: ApiProjectSummery) {
		const fixedOffset = 1;

		const odnaFinished = toFixedIfDecimal(projectSummary?.odna?.finishedQuestionnaires, fixedOffset);
		const pdnaPFinished = toFixedIfDecimal(projectSummary?.pdna?.finishedQuestionnaires, fixedOffset);
		return { odnaFinished, pdnaPFinished };
	}

	NotFinished(projectSummary?: ApiProjectSummery) {
		const fixedOffset = 1;

		const odnaNotFinished = toFixedIfDecimal(projectSummary?.odna?.notFinishedQuestionnaires, fixedOffset);
		const pdnaNotFinished = toFixedIfDecimal(projectSummary?.pdna?.notFinishedQuestionnaires, fixedOffset);
		return { odnaNotFinished, pdnaNotFinished };
	}
}

export { ProjectFinishedStatus };
