import styled from 'styled-components';
import { Button } from 'common/components/Button';

export const StyledButton = styled(Button)`
	width: 100%;
	margin-top: 18px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	margin-bottom: 1rem;
	width: 100%;

	& > ${StyledButton} {
		:first-child {
			width: 45%;
		}

		:last-child {
			width: 45%;
			background-color: ${({ theme }) => theme.colors.common.mediumGrey};
		}
	}
`;
