import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { BillingContactState } from '.';

const initialState: BillingContactState = {
	billingContactName: '',
	billingContactEmail: '',
	billingContactTelephone: '',
	billingContactIM: '',
};

export const billingContactSlice = createSlice({
	name: 'billingContact',
	initialState,
	reducers: {
		setBillingContactState: (state, { payload }: PayloadAction<BillingContactState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setBillingContactState } = billingContactSlice.actions;

export const billingContactSliceReducer = billingContactSlice.reducer;

export const getBillingContactState = (state: RootState) => {
	return state.createClientReducers.billingContact;
};
