import { faBuildingLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavbarItem } from 'features/Navigation/components';
import { NavbarList } from '../styled';

export const SystemManagerNavbar = () => {
	return (
		<NavbarList>
			<NavbarItem
				pageName="APD Managers"
				path="/apdManagers"
				icon={<FontAwesomeIcon icon={faBuildingLock} />}
			/>
		</NavbarList>
	);
};
