import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { DisabledCheckboxStyleProps } from './CheckBox.types';

export const CheckboxInput = styled.input`
	display: none;
`;

export const CheckboxLabel = styled.label`
	cursor: pointer;
`;

export const disabled = css`
	color: ${({ theme }) => theme.colors.disabled.main};
`;

export const CheckboxIcon = styled(FontAwesomeIcon)<DisabledCheckboxStyleProps>`
	color: ${({ theme }) => theme.colors.primary.main};

	${({ $disabled }) => $disabled && disabled};
`;
