import { CriterionId } from 'common/types';
import { CriterionState } from 'features/ResultsConfiguration/components';
import {
	BenchmarksContainer,
	StyledErrorText,
	StyledInput,
	StyledInputContainer,
} from 'features/ResultsConfiguration/components/CriteriaSelectionPanel/styled';
import { BenchmarkButton } from './BenchmarkButton/BenchmarkButton';
import { BenchmarkedCriterionState, RoadMapCriteriaSelectionPanelProps } from './RoadMapCriteriaSelection.types';

export const RoadMapCriteriaSelectionPanel = ({
	criteria,
	onCriteriaChange,
	onIconClick,
}: RoadMapCriteriaSelectionPanelProps) => {
	const criteriaInputs = createCriteriaInputs(criteria);

	return <BenchmarksContainer>{criteriaInputs}</BenchmarksContainer>;

	function createCriteriaInputs(criteria: BenchmarkedCriterionState[]) {
		const maxInputLength = 30;

		const checkIfCriteriaAliasTooLong = (inputValue: string): boolean => {
			return inputValue.length > maxInputLength;
		};

		const placeholder = 'Max 30 characters';
		const errorText = 'Criteria name cannot be longer than 30 characters';
		return criteria.map((criterion) => {
			const name = `Criteria ${criterion.id}*`;
			const errorHasOccurred = checkIfCriteriaAliasTooLong(criterion.name);
			return (
				<StyledInputContainer key={criterion.id}>
					<StyledInput
						name={name}
						placeholder={placeholder}
						error={errorHasOccurred}
						onChange={(event) => {
							const newName = event.target.value;
							const changeName = (criterion: CriterionState) => ({ ...criterion, name: newName });
							findAndApplyChangeToCriteria(criterion.id, changeName);
						}}
						onCheckboxChange={() => {
							const newEnabledState = !criterion.enabled;
							const changeEnabled = (criterion: CriterionState) => ({
								...criterion,
								enabled: newEnabledState,
							});
							findAndApplyChangeToCriteria(criterion.id, changeEnabled);
						}}
						value={criterion.name}
						onBlur={(target) => {
							const newNameIsEmpty = !target.target.value.length;
							if (newNameIsEmpty) {
								const changeName = (criterion: CriterionState) => ({
									...criterion,
									name: criterion.defaultName,
								});
								findAndApplyChangeToCriteria(criterion.id, changeName);
							}
						}}
						checkbox
						checked={criterion.enabled}
						disableCheckbox={!criterion.benchmarkConfigurationId}
						additionalIcon={getAdditionalIcon(criterion)}
					/>
					{errorHasOccurred && <StyledErrorText>{errorText}</StyledErrorText>}
				</StyledInputContainer>
			);
		});
	}

	function findAndApplyChangeToCriteria(id: CriterionId, change: (criterion: CriterionState) => CriterionState) {
		const newArray = criteria.map((criterion) => {
			if (id === criterion.id) {
				return change(criterion);
			}
			return criterion;
		});
		onCriteriaChange(newArray);
	}

	function getAdditionalIcon(criterion: BenchmarkedCriterionState) {
		return (
			<BenchmarkButton
				modified={!!criterion.benchmarkConfigurationId}
				onClick={() => onIconClick(criterion)}
			/>
		);
	}
};
