import {
	TensionGraphSVGFactory,
	TensionGraphRequest,
	TensionLegendConfiguration,
	useTensionGraphFactory,
} from 'features/Graphs/TensionGraph';
import { ApiAllTranslationsResponse, ApiGraphResponseResults } from 'store/api';
import { useDispatch, useSelector } from 'react-redux';
import { getTensionGraphTooltipEnabled, setTensionGraphTooltipState } from 'store/slices';
import { OnLegendStateChangeEvent } from '@support/alderbrooke-d3-graphs/dist/Tension/parts/legend/tension/OnLegendStateChangeEvent';

export const useTensionGraph = (
	data: ApiGraphResponseResults,
	translations: ApiAllTranslationsResponse,
): TensionGraphSVGFactory => {
	const tensionFactory = useTensionGraphFactory(data, translations);
	const tooltipEnabled = useSelector(getTensionGraphTooltipEnabled);
	const dispatch = useDispatch();

	return (requestData: TensionGraphRequest) => {
		const request = createRequest(requestData);
		const legend = createLegend(requestData.legend);
		return tensionFactory.create(request, legend);
	};

	function createRequest(requestData: TensionGraphRequest) {
		const clusterId = requestData.clusterId;
		const benchmarkId = requestData.benchmarkId;
		const benchmarkAlias = requestData.benchmarkAlias;
		const colorPresetId = requestData.colorPresetId;
		return {
			clusterId,
			benchmarkId,
			benchmarkAlias,
			colorPresetId,
		};
	}
	function createLegend(legend: TensionLegendConfiguration | undefined) {
		const defaultLegend = {
			enabled: tooltipEnabled,
			onChange: changeTooltipState,
			position: { x: 10, y: 340 },
			toggleVisible: true,
		};

		return {
			...defaultLegend,
			...legend,
		};
	}

	function changeTooltipState(event: OnLegendStateChangeEvent) {
		dispatch(setTensionGraphTooltipState(event.isLegendEnabled()));
	}
};
