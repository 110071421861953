import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
	opacity: {
		main: 0.38,
	},
	colors: {
		primary: {
			light: '#efe3ff',
			main: '#bd007e',
		},
		secondary: {
			light: '#f2e2ff',
			medium: '#8f70c2',
			main: '#51299b',
		},
		tertiary: {
			main: '#4c2b95',
		},
		error: {
			main: '#ff001e',
		},
		text: {
			primary: '#000000',
			secondary: '#525252',
		},
		common: {
			white: '#FFFFFF',
			lightSilver: '#f2f2f2',
			mediumSilver: '#d9d9d9',
			silver: '#e6e5e5',
			lightGrey: '#f6f6f6',
			grey: '#b9b9b9',
			greyX11: '#bababa',
			mediumGrey: '#6a6f6f',
			darkGrey: '#404040',
			black: '#000000',
			purple: '#5d176f',
			darkBlue: '#0e3256',
			lightBlue: '#0093ff',
			blue: '#0000FF',
			mint: '#00abb4',
			orange: '#ffa000',
		},
		disabled: {
			light: 'rgba(53, 59, 88, 0.12)',
			main: 'rgba(53, 59, 88, 0.38)',
		},
		greyedOut: {
			main: '#7F7F7F',
			dark: '#595959',
		},
		scrollbar: {
			hovered: '#D42D8C',
			main: '#BD007E',
		},
	},
};
