import { StyledButton, TabListItem } from './styled';
import { TabLabelProps } from './TabLabel.types';

export const TabLabel = ({ label, tabIndex, onTabClick, isActive, faded }: TabLabelProps) => {
	return (
		<TabListItem>
			<StyledButton
				faded={faded}
				variant={isActive ? 'filled' : 'text'}
				onClick={() => onTabClick(tabIndex)}
			>
				{label}
			</StyledButton>
		</TabListItem>
	);
};
