import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { CriteriaInformationState } from './criteriaInformationSlice.types';

const initialState: CriteriaInformationState = {
	criteria: {},
};

export const criteriaInformationSlice = createSlice({
	name: 'criteriaInformation',
	initialState,
	reducers: {
		setCriteriaInformationState: (state, { payload }: PayloadAction<CriteriaInformationState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setCriteriaInformationState } = criteriaInformationSlice.actions;

export const selectCriteriaInformationState = (state: RootState) => {
	return state.createParticipantReducers.criteriaInformation;
};
