import { AliasedCriterionProps } from './AliasedCriterion.types';
import { StyledParagraph, StyledSpan } from './styled';

export const AliasedCriterion = ({ criterionName, criterionAlias }: AliasedCriterionProps) => {
	const alias = criterionAlias !== criterionName ? <StyledSpan>({criterionAlias})</StyledSpan> : <></>;
	return (
		<StyledParagraph>
			{`${criterionName} `}
			{alias}
		</StyledParagraph>
	);
};
