import styled, { css, keyframes } from 'styled-components';

export const fadeInAnimationDuration = '1s';

export const fadeInTransition = css`
	&.enter {
		opacity: 0;
	}

	&.enter-active {
		opacity: 1;
		transition: opacity ${fadeInAnimationDuration} ease-in-out;
	}

	&.enter-done {
		opacity: 1;
	}
`;

export const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

export const AnimationContainer = styled.div`
	animation-name: ${fadeInAnimation};
	animation-duration: ${fadeInAnimationDuration};
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	${fadeInTransition}
`;
