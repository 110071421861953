import { ProjectId } from 'common/types';
import { BenchmarkConfigurationId } from 'store/api';
import { SelectedDimension } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent/BehaviourPairModalContent.types';

export type BehaviourFocusViewResultsProps = {
	projectId?: ProjectId;
	benchmarkConfigurationId: BenchmarkConfigurationId;
	isOpened: boolean;
	benchmarkLabels: BenchmarkGroupLabels;
	onClose: () => void;
};

export type BehaviourFocusResultSettings = {
	dimensions: SelectedDimension[];
	onDimensionsChange: (dimensions: SelectedDimension[]) => void;
};

export class BenchmarkGroupLabels {
	constructor(
		private benchmark1: string,
		private benchmark2: string,
		private benchmark3: string,
		private benchmark4: string,
		private benchmark5: string,
		private benchmark6: string,
		private benchmark7: string,
		private benchmark8: string,
		private benchmark9: string,
		private benchmark10: string,
	) {}

	findAlias(index: number): string {
		return (
			[
				'',
				this.benchmark1,
				this.benchmark2,
				this.benchmark3,
				this.benchmark4,
				this.benchmark5,
				this.benchmark6,
				this.benchmark7,
				this.benchmark8,
				this.benchmark9,
				this.benchmark10,
			][index] || ''
		);
	}
}
