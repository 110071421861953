import { CloseButton, ModalBackdrop, StyledModal, StyledModalContent, StyledModalHeader } from './styled';
import { ModalProps } from './Modal.types';
import { Portal } from '../Portal';

export const Modal = ({
	id,
	children,
	isOpen,
	onClose,
	disabledBackdropClose = false,
	hiddenCloseButton = false,
	className,
	headerChildren,
	backdropZIndex,
	modalZIndex,
}: ModalProps) => {
	const close = () => {
		onClose();
	};

	const onBackdropClick = disabledBackdropClose ? () => null : close;

	return isOpen ? (
		<Portal wrapperId="modal-root">
			<ModalBackdrop
				id="modal-backdrop"
				onClick={() => onBackdropClick()}
				$backdropZIndex={backdropZIndex}
			></ModalBackdrop>
			<StyledModal
				id={id}
				className={className}
				$modalZIndex={modalZIndex}
			>
				{!hiddenCloseButton && (
					<StyledModalHeader id="modal-header">
						<CloseButton
							id="modal-header-close"
							onClick={close}
							variant="outlined"
						>
							Close
						</CloseButton>
						{headerChildren && headerChildren}
					</StyledModalHeader>
				)}
				<StyledModalContent id="modal-content">{children}</StyledModalContent>
			</StyledModal>
		</Portal>
	) : null;
};
