import { Button } from 'common/components/Button';
import styled, { css } from 'styled-components';
const customDisabledStyle = css`
	color: ${({ theme }) => theme.colors.primary.main};
	border-color: ${({ theme }) => theme.colors.primary.main};
	opacity: ${({ theme }) => theme.opacity.main};
`;

export const StyledButton = styled(Button)`
	text-transform: none;
	font-size: 0.75rem;
	overflow-wrap: anywhere;
	width: 6rem;
	min-width: 5rem;
	min-height: 3rem;
	padding: 0.1rem;

	${({ disabled }) => disabled && customDisabledStyle};
`;
