import { useLayoutEffect, useRef, useState } from 'react';
import { TooltipTip, TooltipWrapper } from './styled';
import { TooltipProps } from './Tooltip.types';

export const Tooltip = ({
	delay,
	content,
	direction = 'top',
	children,
	className,
	clickableTooltipTip = true,
}: TooltipProps) => {
	let timeout: NodeJS.Timeout;
	const defaultDelay = 0;
	const initialTipHeight = 0;
	const initialTipWidth = 0;

	const [active, setActive] = useState(false);
	const [tipHeight, setTipHeight] = useState(initialTipHeight);
	const [tipWidth, setTipWidth] = useState(initialTipWidth);
	const tipRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (tipRef.current) {
			setTipHeight(tipRef.current.clientHeight);
			setTipWidth(tipRef.current.clientWidth);
		}
	}, [active]);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, delay || defaultDelay);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<TooltipWrapper
			className={className}
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			{children}
			{active && (
				<TooltipTip
					ref={tipRef}
					onMouseEnter={clickableTooltipTip ? undefined : hideTip}
					$direction={direction}
					$width={tipWidth}
					$height={tipHeight}
				>
					{content}
				</TooltipTip>
			)}
		</TooltipWrapper>
	);
};
