import { toggleChartSettingsView } from 'store/slices';
import { useDispatch } from 'react-redux';
import { CloseButton, Container, StyledTitle } from './styled';
import { useEffect, useState } from 'react';
import { QuadrantCenter, QuadrantTypeSelection } from 'features/DiscoveryInsights/components';
import { Criterion } from 'common/types';
import { QuadrantGraphType, QuadrantLegendSortingType } from 'features/DiscoveryInsights/components';
import { QuadrantMargins } from 'features/DiscoveryInsights/components';
import { ColorPresets } from 'features/DiscoveryInsights/components';
import { QuadrantColorPresetId } from 'features/Graphs/QuadrantGraph';
import { DiscoveryInsightResultsMenuProps } from 'features/DiscoveryInsights/components';
import { StyledRow } from 'features/DiscoveryInsights/components';
import { ResultThreshold } from './components/ResultThreshold';

export const DiscoveryInsightResultsMenu = ({
	criteria,
	settings,
	onSaveSettings,
	onQuadrantTypeChange,
}: DiscoveryInsightResultsMenuProps) => {
	const zero = 0;
	const dispatch = useDispatch();

	const [quadrantType, setQuadrantType] = useState(settings.quadrantType);
	const [criterion, setCriterion] = useState(settings.criteria);
	const [sorting, setSorting] = useState(settings.quadrantLegendSorting);
	const [colorPreset, setColorPreset] = useState<QuadrantColorPresetId>(settings.colorPresetId);
	const [marginsClearTrigger, setMarginsClearTrigger] = useState(zero);
	const [totalThreshold, setTotalThreshold] = useState(settings.totalThreshold);

	useEffect(() => onQuadrantTypeChange(quadrantType), [quadrantType]);

	return (
		<>
			<StyledTitle>Quadrant Settings</StyledTitle>
			<Container>
				<QuadrantTypeSelection
					criteria={criteria}
					quadrantType={quadrantType}
					criterion={criterion}
					quadrantSorting={sorting}
					onStateChange={onModePanelChange}
				/>
			</Container>
			<QuadrantMargins
				quadrantCenter={settings.quadrantCenter}
				onApply={onMarginsApply}
				clearTrigger={marginsClearTrigger}
			/>
			<Container>
				<StyledRow>
					<ResultThreshold
						value={totalThreshold}
						onChange={setTotalThreshold}
					/>
				</StyledRow>
				<StyledRow>
					<ColorPresets
						colorPreset={colorPreset}
						onColorPresetChange={setColorPreset}
					/>
					<CloseButton onClick={onCloseButtonClick}>Close</CloseButton>
				</StyledRow>
			</Container>
		</>
	);

	function onModePanelChange(mode: QuadrantGraphType, sorting: QuadrantLegendSortingType, criterion: Criterion) {
		setQuadrantType(mode);
		setSorting(sorting);
		setCriterion(criterion);
	}

	function onMarginsApply(quadrantCenter: QuadrantCenter) {
		onSaveSettings({
			...settings,
			quadrantCenter,
		});
	}

	function onCloseButtonClick() {
		const one = 1;
		setMarginsClearTrigger(marginsClearTrigger + one);
		onSaveSettings({
			quadrantType: quadrantType,
			criteria: criterion,
			quadrantLegendSorting: sorting,
			quadrantCenter: settings.quadrantCenter,
			colorPresetId: colorPreset,
			totalThreshold,
		});
		dispatch(toggleChartSettingsView(false));
	}
};
