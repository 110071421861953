import { charts } from '@support/alderbrooke-d3-graphs';
import { AccuracyChartConfig, AccuracyChartData } from 'features/Graphs/AccuracyChart';

export const useAccuracyChartCreator = (data: AccuracyChartData, config: AccuracyChartConfig) => {
	return { createGraph, createGraphExport };

	function createGraph() {
		const graph = createBuilder();
		return graph.build();
	}

	function createGraphExport() {
		const graph = createBuilder();
		graph.useAbsoluteDimensioning();
		return graph.build();
	}

	function createBuilder() {
		return new charts.Bar.Builder(config, data);
	}
};
