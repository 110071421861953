import { ipsychtecIcon } from 'common/assets/images';
import { useSelector } from 'react-redux';
import { selectIsSomeQueryPending } from 'store/api';
import { selectIsSuspenseManualTriggerActive } from 'store/slices';
import { SuspenseContainer, SuspenseImage } from './styled';

export const Suspense = () => {
	const isSomeQueryPending = useSelector(selectIsSomeQueryPending);
	const isSuspenseManualTriggerActive = useSelector(selectIsSuspenseManualTriggerActive);
	const isSuspenseVisible = isSomeQueryPending || isSuspenseManualTriggerActive;

	return (
		<SuspenseContainer $isHidden={!isSuspenseVisible}>
			<SuspenseImage
				src={ipsychtecIcon}
				title="suspense-loading-background"
			></SuspenseImage>
		</SuspenseContainer>
	);
};
