import styled from 'styled-components';
import { Modal } from 'common/components/Modal';
import { ZIndexes } from 'common/constants';
import { ModalBackdrop } from 'common/components/Modal/styled';

export const StyledBackdrop = styled(ModalBackdrop)`
	z-index: ${ZIndexes.tensionGraphModalBackdrop};
`;

export const StyledModal = styled(Modal)`
	height: 80%;
	z-index: ${ZIndexes.tensionGraphModalContent};
	#modal-root {
		background-color: blue;
	}
`;
