import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { useDeleteManagerMutation } from 'store/api';
import { DeleteManagerConfirmDialogProps } from './DeleteManagerConfirmDialog.types';

export const DeleteManagerConfirmDialog = ({
	isOpen,
	managerToDelete,
	onClose,
	...additionalModalProps
}: DeleteManagerConfirmDialogProps) => {
	const [deleteManager] = useDeleteManagerMutation();
	const { pushNotification } = useNotifications();

	const onDeleteManager = async () => {
		try {
			await deleteManager({ managerId: managerToDelete.managerId }).unwrap();

			pushNotification({
				message: 'Manager successfully deleted',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Something went wrong during the last operation!',
				type: NotificationTypes.Error,
			});
		}
	};

	return (
		<ConfirmationDialog
			id="deleting-client-admin-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onDeleteManager}
			title={`Are you sure that you want to delete ${managerToDelete.username}?`}
			{...additionalModalProps}
		/>
	);
};
