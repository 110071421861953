import { PieChartConfig } from './PieChart.types';

const firstTick = 1;
const secondTick = 0.7;
const thirdTick = 0.6;
const fourthTick = 0.5;
const fifthTick = 0.4;
const sixthTick = 0;

const ticks = [firstTick, secondTick, thirdTick, fourthTick, fifthTick, sixthTick];

export const pieChartConfig: PieChartConfig = {
	axis: {
		colors: function (_data: unknown, i: number) {
			const colors = ['#75923b', '#c3d69a', '#f0f0f0', '#ffbf00', '#FF0000'];

			return colors[i];
		},
		innerRadius: 200,
		outerRadius: 240,
		label: {
			bottomPadding: 15,
			height: 55,
		},
		padding: 2,
		style: {
			label: {
				'font-family': 'Arial, "Liberation Sans"',
				'font-size': '16px',
				fill: 'black',
			},
		},
		ticks,
	},
	dataCurve: {
		padding: 5,
		style: {
			label: {
				'font-family': 'Arial, "Liberation Sans"',
				fill: '#000000',
			},
		},
		width: 21,
	},
	legend: {
		style: {
			label: {
				'font-family': 'Arial, "Liberation Sans"',
				fill: '#000000',
				'font-weight': 'bold',
			},
		},
		yTranslation: 30,
	},
	rangeDegrees: 270,
	scale: {
		ticks,
	},
	startAngle: 0,
};
