import { DescriptionTextareaProps } from './DescriptionTextarea.styles';
import { Textarea } from './styled';

export const DescriptionTextarea = ({ value, onChange, maxLength }: DescriptionTextareaProps) => {
	return (
		<Textarea
			value={value}
			onChange={onChange}
			maxLength={maxLength}
		/>
	);
};
