import { BenchmarksContainer, UnassignButton } from 'features/ResultsConfiguration/components/BenchmarksPanel/styled';
import { BehaviourFocusInputTab } from 'features/BehaviourFocus/components';
import { BenchmarkGroupLabels } from 'features/BehaviourFocusResult/views';

export const BehaviourFocusBenchmarksPanel = ({
	onUnAssignButtonClick,
	firstTabActive,
	onBenchmarkLabelChange,
}: {
	onUnAssignButtonClick: () => void;
	firstTabActive: boolean;
	onBenchmarkLabelChange: (labels: BenchmarkGroupLabels) => void;
}) => {
	return (
		<BenchmarksContainer>
			<BehaviourFocusInputTab
				displayOneToFiveBenchmarks={firstTabActive}
				onBenchmarkLabelChange={onBenchmarkLabelChange}
			/>
			<UnassignButton onClick={onUnAssignButtonClick}>Unassign all participants from benchmarks</UnassignButton>
		</BenchmarksContainer>
	);
};
