import { ApiDimension, ApiDimensionLegend } from 'common/types';
import { rootApi } from 'store/api';
import { AllDimensionsResponse, DimensionsLegendResponse } from './dimensionsApi.types';

export const dimensionsApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllDimensions: builder.query<AllDimensionsResponse<ApiDimension>, void>({
			query: () => ({
				url: `/api/dimensions`,
			}),
		}),
		getDimensionsLegend: builder.query<DimensionsLegendResponse<ApiDimensionLegend>, void>({
			query: () => ({
				url: `/api/dimensionlegends`,
			}),
		}),
	}),
});

export const { useGetAllDimensionsQuery, useGetDimensionsLegendQuery } = dimensionsApi;
