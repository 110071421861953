import { TableContentProps } from './TableContent.types';
import { StyledTable, TableWrapperBenchmarks, TableWrapperParticipants } from './styled';
import { TableBody, TableHead } from 'common/components';

const undefinedColumnNumber = 0;

export const TableContent = <T extends object>({
	table,
	variant = 'primary',
	dynamicColumnNumber = undefinedColumnNumber,
	dynamicColumnName,
}: TableContentProps<T>) => {
	const columnQuantity = table.getAllColumns().length;
	const columnNameExist = table.getAllColumns().some((column) => column.id === dynamicColumnName);

	const TableWrapper = (
		columnNameExist && columnQuantity <= dynamicColumnNumber ? TableWrapperParticipants : TableWrapperBenchmarks
	) as React.ElementType;

	return (
		<TableWrapper>
			<StyledTable $variant={variant}>
				<TableHead<T> headerGroup={table.getHeaderGroups()} />
				<TableBody<T> rows={table.getRowModel().rows} />
			</StyledTable>
		</TableWrapper>
	);
};
