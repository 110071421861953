import { QuadrantInput } from 'features/DiscoveryInsights/components';

export class QuadrantInputValidator {
	public validate(input: QuadrantInput) {
		return Number(input) && this._isBetweenMinAndMax(input);
	}

	private _isBetweenMinAndMax(input: QuadrantInput): boolean {
		const min = 1;
		const max = 4;
		const inputAsNumber = Number(input);
		return inputAsNumber >= min && inputAsNumber <= max;
	}
}
