import { DropdownItem } from 'common/components';
import { TableColumnSelectorProps } from 'common/components/Table/TableColumnSelector/TableColumnSelector.types';
import { StyledTableColumnSelector } from './styled';
import { ColumnDef } from '@tanstack/react-table';

export const TableColumnSelector = <T extends object>({ selected, columns, onChange }: TableColumnSelectorProps<T>) => {
	const selectedIds = extractIdsFrom(selected);

	const onColumnsChange = createCallbackBasedOn(onChange);

	return (
		<StyledTableColumnSelector
			value={selectedIds}
			onChange={onColumnsChange}
			multiple={true}
			buttonProps={{ color: 'primary', variant: 'text' }}
			customLabel="Selected Columns"
		>
			{createDropdownItemsFrom(columns)}
		</StyledTableColumnSelector>
	);

	function extractIdsFrom<T>(selected: ColumnDef<T>[]) {
		return selected.map((col) => col.id as string);
	}

	function createCallbackBasedOn(onChange: (newValue: ColumnDef<T>[]) => void) {
		const findColumnsFromSelection = (selection: string[]) => {
			return selection.map((id) => {
				const found = columns.find((col) => col.id === id);
				if (found === undefined) {
					throw Error(`Column with id: ${id} not found`);
				}
				return found;
			});
		};

		const sortUsingColumnOrder = (selection: ColumnDef<T>[]) => {
			const columnOrder = columns.map((col) => col.id as string);

			const findColumnById = (id: string) => {
				return selection.find((col) => col.id === id);
			};

			const onlyThoseThatExists = (found: ColumnDef<T> | undefined): found is ColumnDef<T> => found != null;

			return columnOrder.map(findColumnById).filter(onlyThoseThatExists);
		};

		const onChangeCallback = (selection: string[]) => {
			const selectedColumns = findColumnsFromSelection(selection);
			const sortedByOrder = sortUsingColumnOrder(selectedColumns);
			onChange(sortedByOrder);
		};
		return onChangeCallback;
	}

	function createDropdownItemsFrom<T>(columns: ColumnDef<T>[]) {
		const resolveName = (col: ColumnDef<T>) => {
			const header = col.header;
			const headerIsNotText = typeof header !== 'string';

			if (headerIsNotText) {
				return col.id;
			}
			return header;
		};

		return columns.map((column) => {
			const id = column.id as string;
			const name = resolveName(column);

			return (
				<DropdownItem
					key={id}
					value={id}
				>
					{name}
				</DropdownItem>
			);
		});
	}
};
