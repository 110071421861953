import styled from 'styled-components';

export const LayoutWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const Main = styled.main`
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
`;
