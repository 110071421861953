import { Portal, SelectionPanel, SelectionPanelItem } from 'common/components';
import { useEffect, useRef, useState } from 'react';
import { SimulationViewProps } from './SimulationView.types';
import { BackgroundOverlay, ChartContentContainer, SideButton, SideButtonsContainer } from './styled';
import { GraphEnglishTranslations } from 'store/api/graphApi/constants/allMock';
import { useRadar } from 'features/GraphDataSeparator/hooks/useRadar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { ChartTypes } from 'common/constants';
import { ChartType, Cluster } from 'common/types';
import { SettingsMenuView } from '../SettingsMenuView';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectBenchmarksSettings,
	selectChartSettingsPreset,
	selectChartSettingsShowDataOnOneGraph,
	toggleChartSettingsView,
} from 'store/slices';
import { useFrameworkDetails, useFrameworks } from 'common/hooks';
import { SimulationViewChartTypeMenu } from './SimulationViewChartTypeMenu';
import {
	getOrganisationalBenchmarkSelection,
	getPersonalBenchmarkSelection,
	getPnoBenchmarkSelection,
} from './utils/getBenchmarkSelection';
import { ChartSelector } from './ChartSelector';
import {
	useAccuracyChart,
	useDispersionChart,
	useHeatmap,
	usePieChart,
	useQuadrant,
} from 'features/GraphDataSeparator/hooks';
import { BenchmarkLegendDistributionCreator } from 'features/Graphs/QuadrantGraph/model';
import {
	ApiPerBenchmarkDistribution,
	selectBenchmarkNames,
	selectChartSettingsIncludeClusters,
	selectIsChartSettingsViewOpened,
} from 'store/index';
import { ChartSettingsMenu } from 'features/Simulation/components/settings';
import { useChartConfig } from 'features/GraphDataSeparator/hooks/useChartConfig';
import { getBenchmarkLabels } from './utils/getBenchmarkLabels';
import { useTensionGraph } from 'features/Graphs/TensionGraph';
import { CSSTransition } from 'react-transition-group';

export const SimulationView = ({ isOpened, onClose, queryResult }: SimulationViewProps) => {
	const graphData = queryResult.commonResults;
	const graphConfig = queryResult.configResults;
	const quadrantData = queryResult.quadrantResults;

	const firstLoad = useRef(true);
	if (firstLoad.current) {
		useChartConfig(graphConfig);
		firstLoad.current = false;
	}

	useRadar(graphData, graphConfig, GraphEnglishTranslations);
	usePieChart(graphData);
	useAccuracyChart(graphData);
	useHeatmap(graphData, GraphEnglishTranslations);
	useDispersionChart(graphData);
	const tensionGraphFactory = useTensionGraph(
		graphData.benchmarksAndSelectedParticipantClusterResults.results,
		GraphEnglishTranslations,
	);

	const benchmarkNames = useSelector(selectBenchmarkNames);
	const benchmarkLabels = getBenchmarkLabels(benchmarkNames);

	const quadrantLegendCreator = new BenchmarkLegendDistributionCreator(benchmarkLabels);
	useQuadrant<ApiPerBenchmarkDistribution>(quadrantData, quadrantLegendCreator);
	const [chosenChartType, setChosenChartType] = useState<ChartType>(ChartTypes.Radar);
	const showDataOnOneGraph = useSelector(selectChartSettingsShowDataOnOneGraph);
	const colorPresetIndex = useSelector(selectChartSettingsPreset);
	const settings = useSelector(selectBenchmarksSettings);
	const includeClusters = useSelector(selectChartSettingsIncludeClusters);

	const personal = getPersonalBenchmarkSelection(settings);
	const organisational = getOrganisationalBenchmarkSelection(settings);
	const pno = getPnoBenchmarkSelection(settings);

	const first = 0;
	const clusterIndexStart = 3;
	const clusters = getClusters();
	const [selectedClusterId, setSelectedClusterId] = useState(initialClusterId());
	const selectedClusterIndex = selectedClusterId ? parseInt(selectedClusterId.substring(clusterIndexStart)) : first;

	const dispatch = useDispatch();

	const chartContentContainerRef = useRef(null);
	const backgroundOverlayRef = useRef(null);

	const isSettingsMenuOpened = useSelector(selectIsChartSettingsViewOpened);

	useEffect(() => {
		if (!includeClusters) {
			setSelectedClusterId(initialClusterId());
		}
	}, [includeClusters]);

	const onHomeButtonClick = () => {
		onClose();

		setChosenChartType(ChartTypes.Radar);
	};

	return isOpened ? (
		<Portal wrapperId="simulation-view-root">
			<BackgroundOverlay ref={backgroundOverlayRef}>
				<SettingsMenuView>
					<ChartSettingsMenu />
				</SettingsMenuView>
				<SideButtonsContainer>
					<SideButton onClick={() => dispatch(toggleChartSettingsView(true))}>
						<FontAwesomeIcon icon={faGear} />
					</SideButton>
					<SideButton onClick={onHomeButtonClick}>
						<FontAwesomeIcon icon={faHouse} />
					</SideButton>
				</SideButtonsContainer>
				<CSSTransition
					in={!isSettingsMenuOpened}
					ref={chartContentContainerRef}
					timeout={750}
				>
					<ChartContentContainer
						ref={chartContentContainerRef}
						key={`${chosenChartType}${selectedClusterIndex}`}
					>
						<ChartSelector
							chartType={chosenChartType}
							tensionGraphFactory={tensionGraphFactory}
							showDataOnOneGraph={showDataOnOneGraph}
							personal={personal}
							organisational={organisational}
							pno={pno}
							selectedClusterIndex={selectedClusterIndex}
							colorPresetIndex={colorPresetIndex}
						/>
					</ChartContentContainer>
				</CSSTransition>
				<SimulationViewChartTypeMenu
					chosenChartType={chosenChartType}
					onSelect={(chartType) => setChosenChartType(chartType)}
				/>
				<SelectionPanel<Cluster>
					id="cluster-selection-panel"
					selectedItemId={selectedClusterId}
					onSelect={(item) => setSelectedClusterId(item.clusterId)}
				>
					{getClusterPanelItems()}
				</SelectionPanel>
			</BackgroundOverlay>
		</Portal>
	) : (
		<></>
	);

	function getClusterPanelItems() {
		return clusters
			? clusters.map((clusterDimension) => {
					const cluster = clusterDimension.cluster;
					const shouldBeDisabled =
						!clusterDimension.selectedDimensions.length ||
						(!includeClusters && selectedClusterId !== cluster.clusterId);
					return (
						<SelectionPanelItem
							key={cluster.clusterId}
							id={cluster.clusterId}
							fadedWhenInactive={shouldBeDisabled}
							itemData={cluster}
							disabled={shouldBeDisabled}
						>
							{cluster.name}
						</SelectionPanelItem>
					);
			  })
			: [];
	}

	function initialClusterId() {
		return clusters ? clusters[first].cluster.clusterId : 'CL-00';
	}

	function getClusters() {
		const { frameworks } = useFrameworks();
		const frameworkId = frameworks.find((framework) => framework.active)?.frameworkId;
		const { frameworkDetails } = useFrameworkDetails({ frameworkId });
		return frameworkDetails?.clusters;
	}
};
