import { ApiReducedTenant, ApiTenant, TenantId } from 'common/types';
import { EditTenantBody, rootApi } from 'store/api';
import { AllTenantsResponse, ReducedTenantsListResponse, CreateTenantBody, CreateTenantResponse } from '.';

export const tenantApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllTenants: builder.query<AllTenantsResponse<ApiTenant>, void>({
			query: () => ({
				url: `/api/tenants`,
			}),
			providesTags: ['Tenants'],
		}),
		getReducedTenantsList: builder.query<ReducedTenantsListResponse<ApiReducedTenant>, void>({
			query: () => ({
				url: `/api/tenants/references`,
			}),
			providesTags: ['Tenants'],
		}),
		getTenant: builder.query<ApiTenant, TenantId>({
			query: (tenantId: TenantId) => ({
				url: `/api/tenants/${tenantId}`,
			}),
			providesTags: ['Tenants'],
		}),
		createTenant: builder.mutation<CreateTenantResponse, CreateTenantBody>({
			query: (data: CreateTenantBody) => ({
				url: `/api/tenants`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Tenants'],
		}),
		editTenant: builder.mutation<void, EditTenantBody>({
			query: (data: EditTenantBody) => ({
				url: `/api/tenants`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Tenants'],
		}),
		checkTenantIdAvailability: builder.mutation<{ available: boolean }, { tenantShortId: string }>({
			query: (data: { tenantShortId: string }) => ({
				url: `/api/tenant-short-id-availability`,
				params: data,
			}),
		}),
	}),
});

export const {
	useGetAllTenantsQuery,
	useCreateTenantMutation,
	useEditTenantMutation,
	useCheckTenantIdAvailabilityMutation,
	useGetTenantQuery,
	useGetReducedTenantsListQuery,
} = tenantApi;
