import { DispersionChartProps } from './DispersionChart.types';
import { GraphContainer } from '../common/components/GraphContainer';
import { useDispersionChartCreator } from 'features/Graphs/DispersionChart/hooks';

export const DispersionChart = ({ containerId, data, config, colorConfig }: DispersionChartProps) => {
	const buildGraph = useDispersionChartCreator(data, config, colorConfig).createGraph;

	return (
		<GraphContainer
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
		/>
	);
};
