import styled, { css } from 'styled-components';
import { Button } from 'common/components';
import ReactSlider from 'react-slider';
import { ApplyButtonProps, QuadrantPromptProps } from 'features/DiscoveryInsights/components';

export const Container = styled('div')`
	padding: 1rem;
	padding-bottom: 2rem;
	height: 17rem;
	border-bottom: 2px solid ${({ theme }) => theme.colors.common.black};
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
	border-top: 2px solid ${({ theme }) => theme.colors.common.black};
	font-size: 1.5rem;
`;

export const StyledSliderColumn = styled('div')`
	width: 40%;
	display: flex;
	flex-direction: column;
`;

export const StyledReactSlider = styled(ReactSlider)`
	width: 90%;
`;

export const StyledTextColumn = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 2rem;
`;

export const StyledText = styled('div')`
	font-size: 1.2rem;
	white-space: nowrap;
`;

const propsStyle = css<QuadrantPromptProps>`
	visibility: ${({ $visible }) => ($visible ? 'unset' : 'hidden')};
	color: ${({ $error }) => ($error ? 'red' : 'black')};
`;

export const StyledInputPrompt = styled('div')`
	font-size: 0.8rem;
	white-space: nowrap;
	${propsStyle};
`;

export const StyledQuadrantSliderWrapper = styled('div')`
	margin-top: 0.7rem;
	margin-bottom: 3.4rem;
`;

export const DisableUpDown = css`
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='text'] {
		-moz-appearance: textfield;
	}
`;

export const StyledInputContainer = styled('div')`
	${'div'} {
		border-radius: 15px;
		font-size: 1.2rem;
		width: 140px;
	}

	${DisableUpDown}
`;

export const StyledInputColumn = styled('div')`
	display: flex;
	flex-direction: column;
	width: 8rem;
`;

export const StyledTitle = styled('div')`
	text-transform: uppercase;
	font-size: 3rem;
	line-height: 5rem;
`;

export const ApplyButton = styled(Button)<ApplyButtonProps>`
	text-transform: capitalize;
	height: 6vh;
	width: 10vw;
	height: fit-content;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	margin-left: auto;
	margin-right: 15px;
	margin-top: auto;
	margin-bottom: 15px;
	background-color: ${({ theme, $highlight }) => ($highlight ? theme.colors.primary : theme.colors.common.grey)};
`;
