import { endOfYear, addYears } from 'date-fns';

const yearsToAddToMaxDate = 1;

export const DateFilterPickerRange = {
	MinDate: new Date('2015/01/01'),
	MaxDate: endOfYear(addYears(new Date(), yearsToAddToMaxDate)),
};

export const DateBilingReportPickerRange = {
	MinDate: new Date('2015/01/01'),
	MaxDate: endOfYear(new Date()),
};

export const DateFormatPatterns = {
	Default: 'dd/MM/yyyy',
	Export: 'yyyy-MM-dd',
};
