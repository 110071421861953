import { masterLogo } from 'common/assets/images';
import { RedirectingMasterLogoProps } from './RedirectingMasterLogo.types';

export const RedirectingMasterLogo = ({ className, id }: RedirectingMasterLogoProps) => {
	return (
		<a
			id={id}
			href="https://ipsychtec.com/"
			rel="noreferrer"
			target="_blank"
		>
			<img
				className={className}
				src={masterLogo}
				alt="masterLogo"
			/>
		</a>
	);
};
