import { PageLayout, Table } from 'common/components';
import { useFrameworks } from 'common/hooks';
import { Framework } from 'common/types';
import { useClusterConfigurationData } from 'features/Tables/hooks';
import { useState } from 'react';
import { CreateFrameworkButton } from './styled';
import { useCreateFrameworkMutation } from 'store/api';
import { sortFrameworks, getMissingNumbers } from './utils';
import { NotificationTypes, useNotifications } from 'common/components/Notifications';
import { DeleteFrameworkConfirmDialog } from 'features/ClusterConfiguration/components';

export const ClusterConfiguration = () => {
	const [deleteFrameworkModalIsOpen, setDeleteFrameworkModalIsOpen] = useState(false);
	const { columns, frameworkToDelete } = useClusterConfigurationData(setDeleteFrameworkModalIsOpen);
	const { frameworks } = useFrameworks();
	const [addFramework] = useCreateFrameworkMutation();
	const { pushNotification } = useNotifications();

	const sortedFrameworks = sortFrameworks(frameworks);

	const onAddFrameworkButtonClick = async () => {
		const maxFrameworkQuantity = 7;
		const firstIndexInArray = 0;
		if (frameworks.length < maxFrameworkQuantity) {
			const missingNumbers = getMissingNumbers(frameworks);
			try {
				await addFramework({ name: 'Framework ' + missingNumbers[firstIndexInArray] }).unwrap();
				pushNotification({
					message: 'Framework successfully added',
					type: NotificationTypes.Success,
				});
			} catch {
				pushNotification({
					message: `Something went wrong during last operation!`,
					type: NotificationTypes.Error,
				});
			}
		} else {
			pushNotification({
				message: `Maximum number of frameworks already exist`,
				type: NotificationTypes.Info,
			});
		}
	};

	return (
		<PageLayout
			title="Cluster Configuration"
			id="cluster-configuration"
		>
			<>
				<CreateFrameworkButton
					onClick={onAddFrameworkButtonClick}
					variant="outlined"
				>
					Add Framework
				</CreateFrameworkButton>

				<DeleteFrameworkConfirmDialog
					isOpen={deleteFrameworkModalIsOpen}
					onClose={() => {
						setDeleteFrameworkModalIsOpen(false);
					}}
					frameworkDataId={frameworkToDelete.frameworkDataId}
					frameworkName={frameworkToDelete.frameworkName}
				/>

				<Table<Framework>
					tableTypeId="cluster-configuration"
					disableColumnSelector
					columns={columns}
					data={sortedFrameworks}
				/>
			</>
		</PageLayout>
	);
};
