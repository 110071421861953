import { DispersionChartColorConfig } from '../DispersionChart';

const dispersionChartPresetColors = {
	lightCarminePink: '#ED6C6D',
	jasmine: '#FFE082',
	cultured: '#F5F5F5',
	jetStream: '#C1D2B9',
	oxley: '#6D9E6E',
	independence: '#534E6F',
	middleBluePurple: '#8370B4',
	orchid: '#E39DCD',
	superPink: '#C26EA8',
	sonicSilver: '#817B6F',
	deer: '#BE804D',
	philippineSilver: '#B8B8B8',
	babyBlue: '#89CBF0',
	carolinaBlue: '#539DC9',
};

const dispersionChartPreset1: DispersionChartColorConfig = {
	backgroundAndTableColors: {
		color1: dispersionChartPresetColors.lightCarminePink,
		color2: dispersionChartPresetColors.jasmine,
		color3: dispersionChartPresetColors.cultured,
		color4: dispersionChartPresetColors.jetStream,
		color5: dispersionChartPresetColors.oxley,
	},
};

const dispersionChartPreset2 = {
	backgroundAndTableColors: {
		color1: dispersionChartPresetColors.independence,
		color2: dispersionChartPresetColors.middleBluePurple,
		color3: dispersionChartPresetColors.cultured,
		color4: dispersionChartPresetColors.orchid,
		color5: dispersionChartPresetColors.superPink,
	},
};

const dispersionChartPreset3 = {
	backgroundAndTableColors: {
		color1: dispersionChartPresetColors.sonicSilver,
		color2: dispersionChartPresetColors.deer,
		color3: dispersionChartPresetColors.philippineSilver,
		color4: dispersionChartPresetColors.babyBlue,
		color5: dispersionChartPresetColors.carolinaBlue,
	},
};

export const DispersionChartColorPresets = [dispersionChartPreset1, dispersionChartPreset2, dispersionChartPreset3];
