import styled from 'styled-components';

export const StyledLinkList = styled.span`
	list-style-type: none;
	margin: auto 0;
	display: flex;
	justify-content: space-around;
`;

export const StyledListItem = styled.span`
	display: flex;
`;

export const StyledAnchor = styled.a`
	text-decoration: none;
	font-size: 0.95rem;
	padding: 0.25rem 0;
	font-weight: 300;
	color: ${({ theme }) => theme.colors.text.secondary};

	&:hover {
		text-decoration: underline;
	}
`;
