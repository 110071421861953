import { Button } from 'common/components/Button';
import styled from 'styled-components';
import { Input } from 'common/components/Input';
import { InputContainer, InputLabel } from 'common/components/Input/styled';

export const ProjectActionsForm = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
	width: 40%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const CloseProjectButton = styled(Button)`
	width: 40%;
	padding-left: 2rem;
	padding-right: 2rem;
	margin-bottom: 1rem;
`;

export const ExportParticipantsButton = styled(Button)`
	width: 57%;
	padding-left: 2rem;
	padding-right: 2rem;
	margin-bottom: 1rem;
`;

export const DownloadProjectReportButton = styled(Button)`
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	margin-bottom: 1rem;
`;

export const InputsContainer = styled.div`
	width: 55%;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
`;

export const StyledInput = styled(Input)`
	width: 15.5vw;
	white-space: normal;
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	justify-items: end;
	align-items: center;
	${InputLabel} {
		width: 3.5rem;
		font-size: 0.85rem;
		white-space: normal;
		line-height: 0.85rem;
		text-align: right;
		display: flex;
		justify-content: flex-end;
	}

	${InputContainer} {
		height: 2.2rem;
	}
`;

export const TableContainer = styled.div``;
