import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
	&.react-tooltip {
		width: max-content;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0px;
		border-radius: 4px;
		pointer-events: none;
		opacity: 1;
		background-color: #ffffff;
		transition: opacity 0.3s ease-out;
		will-change: opacity, visibility;
		border: 1px solid black;
		width: 20rem;
		display: flex;
		flex-direction: column;
		.react-tooltip-arrow {
			position: absolute;
			background: inherit;
			width: 8px;
			height: 8px;
			border: solid black;
			border-width: 0px 1px 1px 0px;
		}
	}
`;
