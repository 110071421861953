import { ColumnDef } from '@tanstack/react-table';
import { PercentageDistributionPerCategory } from 'store/api/discoveryInsightApi';
import { DistributionTableColumnId } from 'features/DiscoveryInsights/components/DistributionTableView/components/DistributionTable/DistributionTable.types';

export const usePercentageDistributionColumns = (): ColumnDef<PercentageDistributionPerCategory>[] => {
	return [
		{
			id: DistributionTableColumnId.Category,
			header: 'Categories',
			accessorFn: (p) => p.category,
			enableSorting: false,
		},
		{
			id: DistributionTableColumnId.First,
			header: '0 - 40%',
			accessorFn: (p) => round(p.first),
		},
		{
			id: DistributionTableColumnId.Second,
			header: '40 - 50%',
			accessorFn: (p) => round(p.second),
		},
		{
			id: DistributionTableColumnId.Third,
			header: '50 - 60%',
			accessorFn: (p) => round(p.third),
		},
		{
			id: DistributionTableColumnId.Fourth,
			header: '60 - 70%',
			accessorFn: (p) => round(p.fourth),
		},
		{
			id: DistributionTableColumnId.Fifth,
			header: '70 - 100%',
			accessorFn: (p) => round(p.fifth),
		},
		{
			id: DistributionTableColumnId.Total,
			header: 'Total N',
			accessorFn: (p) => p.total,
		},
	];

	function round(val: number) {
		const rounding = 2;
		return `${val.toFixed(rounding)}%`;
	}
};
