import React, { ChangeEvent } from 'react';
import { Column } from '@tanstack/react-table';

import { StyledInput, StyledTableFilter } from './styled';
import { TableFilterProps } from './TableFilter.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

export const TableFilter = <T extends object>({ column }: TableFilterProps<T>) => {
	const handleColumnTextFilterChange = (e: ChangeEvent<HTMLInputElement>, column: Column<T>) => {
		column.setFilterValue(e.target.value);
	};

	const customFilterComponent = column.columnDef.meta?.customFilterComponent;

	if (customFilterComponent) {
		return <StyledTableFilter>{customFilterComponent(column)}</StyledTableFilter>;
	}

	return (
		<StyledTableFilter>
			<StyledInput
				fullWidth
				onChange={(e) => handleColumnTextFilterChange(e, column)}
				value={(column.getFilterValue() as string) || ''}
				placeholder="Search..."
				endElement={<FontAwesomeIcon icon={faMagnifyingGlass} />}
			/>
		</StyledTableFilter>
	);
};
