import styled from 'styled-components';

export const TileContainer = styled.div`
	display: flex;

	& > :nth-child(1) {
		margin-right: 1px;
		flex-grow: 1;
		width: 0;
	}

	& > :nth-child(2) {
		margin-left: 1px;
		flex-grow: 1;
		width: 0;
	}
`;
