import { IconButtonProps } from './IconButton.types';
import { StyledIconButton, StyledIconButtonLink } from './styled';
import React, { ForwardedRef, forwardRef } from 'react';

const _IconButton = (
	{ disabled, color = 'default', title, onClick, className, children, href }: IconButtonProps,
	buttonRef: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
) => {
	const onButtonClick = (event: React.MouseEvent): void => {
		if (disabled) return;
		if (!onClick) return;

		onClick(event);
	};

	if (href !== undefined) {
		return (
			<StyledIconButtonLink
				to={href}
				disabled={disabled}
				ref={buttonRef as ForwardedRef<HTMLAnchorElement>}
				className={className}
				$color={color}
				onClick={onButtonClick}
			>
				{children}
			</StyledIconButtonLink>
		);
	}

	return (
		<StyledIconButton
			type="button"
			title={title}
			ref={buttonRef as ForwardedRef<HTMLButtonElement>}
			disabled={disabled}
			onClick={onButtonClick}
			className={className}
			$color={color}
		>
			{children}
		</StyledIconButton>
	);
};

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(_IconButton);

IconButton.displayName = 'IconButton';
