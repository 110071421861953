import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DispersionChartDna, DispersionChartState } from './dispersionChartSlice.types';
import { dispersionChartConfig } from 'features/Graphs/DispersionChart/DispersionChartConfig';
import { DispersionChartConfig } from 'features/Graphs/DispersionChart';
import { RootState } from 'store/store';

const emptyDna: DispersionChartDna = {
	clusters: [],
};

const initialState: DispersionChartState = {
	chartConfig: dispersionChartConfig,
	ODNA: emptyDna,
	PDNA: emptyDna,
	COMBINED_ODNA: emptyDna,
	COMBINED_PDNA: emptyDna,
};

export const dispersionChartSlice = createSlice({
	name: 'dispersionChart',
	initialState,
	reducers: {
		setDispersionChartState: (state, { payload }: PayloadAction<DispersionChartState>) => {
			state = payload;
		},
		setDispersionChartConfig: (state, { payload }: PayloadAction<DispersionChartConfig>) => {
			state.chartConfig = payload;
			return state;
		},
		setDispersionChartODNA: (state, { payload }: PayloadAction<DispersionChartDna>) => {
			state.ODNA = payload;
			return state;
		},
		setDispersionChartCombinedODNA: (state, { payload }: PayloadAction<DispersionChartDna>) => {
			state.COMBINED_ODNA = payload;
			return state;
		},
		setDispersionChartPDNA: (state, { payload }: PayloadAction<DispersionChartDna>) => {
			state.PDNA = payload;
			return state;
		},
		setDispersionChartCombinedPDNA: (state, { payload }: PayloadAction<DispersionChartDna>) => {
			state.COMBINED_PDNA = payload;
			return state;
		},
		resetDispersionChartState: () => initialState,
	},
});

export const {
	setDispersionChartState,
	setDispersionChartConfig,
	setDispersionChartODNA,
	setDispersionChartCombinedODNA,
	setDispersionChartPDNA,
	setDispersionChartCombinedPDNA,
	resetDispersionChartState,
} = dispersionChartSlice.actions;
export const dispersionChartReducer = dispersionChartSlice.reducer;
export const selectDispersionChartODNA = (state: RootState) => state.dispersionChart.ODNA;
export const selectDispersionChartPDNA = (state: RootState) => state.dispersionChart.PDNA;
export const selectDispersionChartCombinedPDNA = (state: RootState) => state.dispersionChart.COMBINED_PDNA;
export const selectDispersionChartCombinedODNA = (state: RootState) => state.dispersionChart.COMBINED_ODNA;
export const selectDispersionChartConfig = (state: RootState) => state.dispersionChart.chartConfig;
