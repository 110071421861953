import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const PaginationContainer = styled.div`
	padding: 1rem 0;
	display: flex;
	justify-content: center;

	& > *:not(:last-of-type) {
		margin-right: 0.5rem;
	}
`;

export const StyledButton = styled(Button)`
	padding-left: 2rem;
	padding-right: 2rem;
`;
