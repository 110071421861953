const commonPresetColors = {
	red: '#FF0000',
	amber: '#FFBF00',
	reservedWhite: '#F0F0F0',
	lightGreen: '#C3D69A',
	olive: '#75923B',
	darkBlue: '#090230',
	darkPurple: '#4D3293',
	pink: '#D773B8',
	darkPink: '#A72F83',
	greyishBrown: '#4A4230',
	brown: '#A24900',
	reservedGrey: '#999999',
	lightBlue: '#56B4E9',
	blue: '#0972B2',
};

const preset1 = {
	color1: commonPresetColors.red,
	color2: commonPresetColors.amber,
	color3: commonPresetColors.reservedWhite,
	color4: commonPresetColors.lightGreen,
	color5: commonPresetColors.olive,
};

const preset2 = {
	color1: commonPresetColors.darkBlue,
	color2: commonPresetColors.darkPurple,
	color3: commonPresetColors.reservedWhite,
	color4: commonPresetColors.pink,
	color5: commonPresetColors.darkPink,
};

const preset3 = {
	color1: commonPresetColors.greyishBrown,
	color2: commonPresetColors.brown,
	color3: commonPresetColors.reservedGrey,
	color4: commonPresetColors.lightBlue,
	color5: commonPresetColors.blue,
};

export const CommonColorPresets = [preset1, preset2, preset3];
