import { ChartColors } from 'common/constants';
import { ChartColor } from 'common/types';

export class ColorSelector {
	private chartColors = ChartColors;

	public getChartColor(id: number) {
		const ret = Object.values(this.chartColors)[id];
		return ret;
	}

	public getColorId(color: ChartColor) {
		const ret = Object.values(this.chartColors).findIndex((iteratedColor) => iteratedColor === color);
		return ret;
	}
}
