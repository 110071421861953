export type Criterion = {
	id: CriterionId;
	name: string;
	enabled: boolean;
};

export type CriterionId = string;

class NotFoundError extends Error {}

export class CriteriaRepository<T extends Criterion> {
	private readonly _criteriaMap: Record<string, T>;

	constructor(private criteria: T[]) {
		this._criteriaMap = createCriteriaMap(criteria);
	}

	findAll() {
		return Object.entries(this._criteriaMap).map(([, value]) => value);
	}

	findById(id: CriterionId): Criterion {
		const criterion = this._criteriaMap[id];
		if (criterion === undefined) {
			throw new NotFoundError(`Criterion ${id} does not exist`);
		}
		return criterion;
	}

	checkIfEnabled(id: CriterionId): boolean {
		try {
			const criterion = this.findById(id);
			return this.checkIfExists(criterion) && criterion.enabled;
		} catch (err) {
			if (err instanceof NotFoundError) {
				return false;
			}
			throw err;
		}
	}

	private checkIfExists(criteria: Criterion | undefined): boolean {
		return criteria === undefined;
	}
}

function createCriteriaMap<T extends Criterion>(criteria: T[]) {
	const criteriaMap: Record<string, T> = {};
	criteria.forEach((criterion: T) => {
		criteriaMap[criterion.id] = criterion;
	});
	return criteriaMap;
}
