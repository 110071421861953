import { rootApi } from 'store/api';
import { TenantId } from 'common/types';
import { ConfigurationTooltipOptions, ConfigurationTooltipResponse } from './tenantConfigurationTooltipApi.types';

export const tenantConfigurationTooltipApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		editConfigurationTooltip: builder.mutation<ConfigurationTooltipResponse, ConfigurationTooltipOptions>({
			query: (opts) => ({
				method: 'POST',
				url: `/api/dimensionlegends/${opts.tenantId}`,
				body: opts.file,
			}),
		}),
		getConfigurationTooltip: builder.query<ConfigurationTooltipResponse, TenantId>({
			query: (tenantId: TenantId) => ({
				url: `/api/dimensionlegendsfile/${tenantId}`,
			}),
		}),
	}),
});

export const { useEditConfigurationTooltipMutation, useGetConfigurationTooltipQuery } = tenantConfigurationTooltipApi;
