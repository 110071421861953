import { useLazyGetDistributionPercentagesQuery } from 'store/api/discoveryInsightApi';
import { BenchmarkConfigurationId } from 'store/api';
import { ProjectId } from 'common/types';
import { ApiCriteriaId, PercentageDistributionResponse } from 'store/api/discoveryInsightApi';

export const useDistributionPercentageData = () => {
	const [fetchDistributionPercentages] = useLazyGetDistributionPercentagesQuery();

	return (
		benchmarkConfigurationId: BenchmarkConfigurationId,
		selectedCriterion: ApiCriteriaId,
		projectId?: ProjectId,
	): Promise<PercentageDistributionResponse> => {
		const baseRequest = { benchmarkConfigurationId, projectId };
		const request = { ...baseRequest, criteriaId: selectedCriterion };
		const percentageDistribution = fetchDistributionPercentages(request);

		return percentageDistribution.then((result) => {
			const percentagesDistributionView = result.data;

			if (!percentagesDistributionView) {
				throw Error();
			}
			return percentagesDistributionView;
		});
	};
};
