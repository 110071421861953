import { ApiManager } from 'common/types';
import { rootApi } from 'store/api';
import {
	AllManagersResponse,
	CreateManagerBody,
	CreateManagerResponse,
	DeleteManagerOptions,
} from './managerApi.types';

export const managerApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllManagers: builder.query<AllManagersResponse<ApiManager>, void>({
			query: () => ({
				url: 'api/apdmanagers',
			}),
			providesTags: ['Managers'],
		}),
		createManager: builder.mutation<CreateManagerResponse, CreateManagerBody>({
			query: (data) => ({
				url: `/api/apdmanagers`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Managers'],
		}),
		deleteManager: builder.mutation<void, DeleteManagerOptions>({
			query: (opts) => ({
				url: `api/apdmanagers/${opts.managerId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Managers'],
		}),
		checkManagerIdAvailability: builder.query<{ available: boolean }, { username: string }>({
			query: (data) => ({
				url: `/api/username-availability`,
				params: data,
			}),
		}),
	}),
});

export const {
	useGetAllManagersQuery,
	useDeleteManagerMutation,
	useCreateManagerMutation,
	useLazyCheckManagerIdAvailabilityQuery,
} = managerApi;
