import { AccuracyChartProps } from './AccuracyChart.types';
import { GraphContainer } from 'features/Graphs/common/components';
import { useAccuracyChartCreator } from 'features/Graphs/AccuracyChart/hooks/useAccuracyChartCreator';

export const AccuracyChart = ({ containerId, data, config }: AccuracyChartProps) => {
	const buildGraph = useAccuracyChartCreator(data, config).createGraph;

	return (
		<GraphContainer
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
		/>
	);
};
