import { useNavigate, useParams } from 'react-router-dom';
import { useViewRoadmapQuery } from 'store/api/roadmapApi';
import { RoadMapView } from '../RoadMapView/RoadMapView';

export const StandaloneRoadmapView = () => {
	const navigate = useNavigate();
	const { projectId, roadmapId } = useParams();
	if (!roadmapId) {
		throw Error('Roadmap does not exists');
	}
	const roadmap = useViewRoadmapQuery(roadmapId).data;

	if (!roadmap) {
		return <></>;
	}

	return (
		<RoadMapView
			roadmap={roadmap}
			isOpen={true}
			onClose={onClose}
			id={roadmapId}
			projectId={projectId}
			criteria={roadmap.allCriteriaAssigned}
		/>
	);

	function onClose() {
		if (projectId) {
			navigate(`/road-map/${projectId}/overview`);
		} else {
			navigate(`/road-map/overview`);
		}
	}
};
