import { CreateManagerBody, useLazyCheckManagerIdAvailabilityQuery } from 'store/api';
import { RegexPatterns } from 'common/constants';
import { PasswordValidator } from 'features/AuthManagement/views/ChangePasswordBox/utils';

export type ManagerValidationProperties = { shouldCheckForManagerIdUniqueness: boolean };

export const useManagerFormDataValidation = () => {
	const [validateAvailability] = useLazyCheckManagerIdAvailabilityQuery();
	const validator = new PasswordValidator();

	const validateForm = async (data: CreateManagerBody, props?: ManagerValidationProperties) => {
		const username = await validateAvailability({ username: data.username }).unwrap();
		if (props?.shouldCheckForManagerIdUniqueness && !username.available) {
			return false;
		}

		return [
			shouldBeProperEmail(data.username),
			required(data.name),
			shouldBeProperPassword(data.password),
			shouldBeProperRetypedPassword(data.password, data.retypedPassword),
		].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function shouldBeProperEmail(data: string) {
		return required(data) && !!data.match(RegexPatterns.Email);
	}

	function shouldBeProperPassword(data: string) {
		const { success: isPasswordValid } = validator.validatePassword(data);
		return required(data) && isPasswordValid;
	}

	function shouldBeProperRetypedPassword(password: string, retypedPassword: string) {
		const { success: arePasswordsEqual } = validator.comparePasswords(password, retypedPassword);
		return required(retypedPassword) && arePasswordsEqual;
	}

	function required(data: string) {
		return data != null && !!data.length;
	}
};
