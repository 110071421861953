import { PageLayout } from 'common/components';
import {
	BillingAddress,
	BillingContact,
	BillingInformation,
	OrganisationAddress,
	OrganisationInformation,
	StakeholderContact,
	StakeholderInformation,
} from 'features/ClientConfiguration/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ClientFormLayoutProps } from './CreateClientLayout.types';

export const ClientFormLayout = ({
	id,
	title,
	subtitle,
	saveTrigger,
	onSaveButtonClick,
	organisationInformation,
	organisationAddress,
	stakeholderInformation,
	stakeholderContact,
	billingInformation,
	billingAddress,
	billingContact,
}: ClientFormLayoutProps) => {
	return (
		<PageLayout
			title={title}
			subtitle={subtitle}
			id={id}
		>
			<div>
				<OrganisationInformation
					{...organisationInformation}
					saveTrigger={saveTrigger}
				/>
				<OrganisationAddress
					{...organisationAddress}
					saveTrigger={saveTrigger}
				/>
				<StakeholderInformation
					{...stakeholderInformation}
					saveTrigger={saveTrigger}
				/>
				<StakeholderContact
					{...stakeholderContact}
					saveTrigger={saveTrigger}
				/>
				<BillingInformation
					{...billingInformation}
					saveTrigger={saveTrigger}
				/>
				<BillingAddress
					{...billingAddress}
					saveTrigger={saveTrigger}
				/>
				<BillingContact
					{...billingContact}
					saveTrigger={saveTrigger}
				/>

				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
