import styled from 'styled-components';

export const TabsHeaderList = styled.ul`
	list-style: none;
	display: flex;
	padding: 0.5rem 0;
	margin: 0;
	width: fit-content;
	border: solid 1px;
	border-radius: 10px;
	border-color: ${({ theme }) => theme.colors.common.grey};
`;
