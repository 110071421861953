import { ApiDimensionCluster, ApiFrameworkDetails, DimensionCluster, FrameworkDetails } from 'common/types';

const onlyClustersWithDimensions = (dimensionCluster: DimensionCluster) => !!dimensionCluster.selectedDimensions.length;

const dimensionClusterToApiObject = (dimensionCluster: DimensionCluster): ApiDimensionCluster => {
	const { cluster, selectedDimensions } = dimensionCluster;
	return {
		index: {
			id: cluster.clusterId,
		},
		defaultName: cluster.clusterId,
		name: cluster.name,
		selectedDimensions: selectedDimensions.map((dimension) => {
			return {
				id: dimension.dimensionId,
				name: dimension.name,
			};
		}),
	};
};

export const mapEditFrameworkOptions = (frameworkDetails: FrameworkDetails): ApiFrameworkDetails => {
	return {
		id: frameworkDetails.frameworkId,
		active: frameworkDetails.active,
		description: frameworkDetails.description,
		name: frameworkDetails.name,
		clusters: frameworkDetails.clusters.filter(onlyClustersWithDimensions).map(dimensionClusterToApiObject),
	};
};
