import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;

export const HeaderText = styled.h3`
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.common.white};
	background-color: ${({ theme }) => theme.colors.primary.main};
	border: 1px solid ${({ theme }) => theme.colors.common.black};
	font-weight: 300;
	letter-spacing: 2px;
	font-size: 1rem;
	padding: 0.5rem;
	margin: 0;
	border-radius: 10px;

	margin-bottom: 0.5rem;

	justify-content: center;
	display: flex;
`;
