import styled from 'styled-components';

export const StyledParagraph = styled.p`
	color: ${({ theme }) => theme.colors.text.secondary};
	margin: 0.5rem;
	font-size: 1.5rem;
	font-weight: 400;
`;

export const StyledSpan = styled.span`
	border: 2px solid ${({ theme }) => theme.colors.common.black};
	border-radius: 10px;
	padding: 0.1rem 0.5rem;
	margin-left: 0.5rem;
`;
