import { Checkbox } from 'common/components';
import { ChartSettingsOptionItemProps } from './ChartSettingsOptionItem.types';
import { Container, Title } from './styled';
import { Radio } from 'common/components';

export const ChartSettingsOptionItem = ({
	checked,
	checkButtonType,
	disabled,
	children,
	onClick,
}: ChartSettingsOptionItemProps) => {
	const onOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		if (disabled) return;
		onClick();
	};

	return (
		<Container
			$disabled={disabled}
			onClick={onOptionClick}
		>
			{checkButtonType === 'checkbox' && (
				<Checkbox
					checked={checked}
					readOnly
				/>
			)}
			{checkButtonType === 'radio' && (
				<Radio
					checked={checked}
					readOnly
				/>
			)}
			<Title>{children}</Title>
		</Container>
	);
};
