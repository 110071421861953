import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { DisabledRadioStyleProps } from './Radio.types';

export const RadioInput = styled.input`
	display: none;
`;

export const RadioLabel = styled.label`
	cursor: pointer;
`;

export const disabled = css`
	color: ${({ theme }) => theme.colors.disabled.main};
`;

export const RadioIcon = styled(FontAwesomeIcon)<DisabledRadioStyleProps>`
	color: ${({ theme }) => theme.colors.primary.main};

	${({ $disabled }) => $disabled && disabled};
`;
