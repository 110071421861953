import { DateInputProps } from './DateInput.types';
import { InputProps } from '../Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { differenceInYears } from 'date-fns';
import { DatePickerWrapperStyles, StyledInput } from './styled';
import 'react-datepicker/dist/react-datepicker.css';
import { usePrevious } from 'common/hooks';
import { DateFormatPatterns } from 'common/constants';

const DatePickerInput = forwardRef((props: InputProps, _ref: React.ForwardedRef<HTMLInputElement>) => (
	<StyledInput {...props} />
));

DatePickerInput.displayName = DatePickerInput.name;

export const DateInput = ({
	onClickOutside,
	onSelectDate,
	onEnterKeydown,
	onCalendarClose,
	maxDate,
	minDate,
	onInputChange,
	dateValue,
	inputValue,
	inputProps,
	className,
	placeholder,
	disabled = false,
	reactDatePickerProps,
	disableErrorOnFocus = false,
}: DateInputProps) => {
	const [isCalendarOpened, setIsCalendarOpened] = useState(false);
	const prevIsCalendarOpened = usePrevious(isCalendarOpened);

	useEffect(() => {
		if (prevIsCalendarOpened && onCalendarClose) {
			onCalendarClose();
		}
	}, [isCalendarOpened]);

	const openCalendar = () => {
		setIsCalendarOpened(true);
	};

	const closeCalendar = () => {
		setIsCalendarOpened(false);
		blurDatePickerInput();
	};

	const blurDatePickerInput = () => {
		if (document.activeElement instanceof HTMLInputElement) {
			document.activeElement.blur();
		}
	};

	const onChangeRawDateStringHandler = (event: React.FocusEvent<HTMLInputElement, Element>) => {
		if (onInputChange) {
			onInputChange(event);
		}
	};

	const onClickOutsideHandler = () => {
		closeCalendar();
		if (onClickOutside) {
			onClickOutside();
		}
	};

	const onSelectHandler = (date: Date, event: React.SyntheticEvent) => {
		closeCalendar();
		if (onSelectDate) {
			onSelectDate(date, event);
		}
	};

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if ((event.key === 'Enter' || event.key === 'NumpadEnter') && onEnterKeydown) {
			closeCalendar();
			onEnterKeydown();
		}
	};

	const inputErrorHandler = (): boolean => {
		if (document.activeElement instanceof HTMLInputElement && !disableErrorOnFocus) {
			return false;
		}

		return !!inputProps?.error;
	};

	const yearDifference = maxDate && minDate && differenceInYears(maxDate, minDate);
	return (
		<>
			<DatePickerWrapperStyles />
			<ReactDatePicker
				selected={dateValue}
				open={isCalendarOpened}
				value={inputValue}
				strictParsing
				dateFormat={DateFormatPatterns.Default}
				excludeDateIntervals={reactDatePickerProps?.excludeDateIntervals}
				popperPlacement="bottom-end"
				disabledKeyboardNavigation
				showMonthDropdown
				showYearDropdown
				useShortMonthInDropdown
				yearDropdownItemNumber={yearDifference}
				placeholderText={placeholder}
				minDate={minDate}
				maxDate={maxDate}
				disabled={disabled}
				onChangeRaw={onChangeRawDateStringHandler}
				onInputClick={openCalendar}
				onFocus={openCalendar}
				onClickOutside={onClickOutsideHandler}
				onSelect={onSelectHandler}
				onKeyDown={onKeyDownHandler}
				onChange={() => true}
				customInput={
					<DatePickerInput
						fullWidth
						className={className}
						error={inputErrorHandler()}
						disabledErrorBorder={inputProps?.disabledErrorBorder}
						endElement={
							!disabled && (
								<FontAwesomeIcon
									onClick={openCalendar}
									icon={faCalendarDays}
								/>
							)
						}
					/>
				}
			/>
		</>
	);
};
