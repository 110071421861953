import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavbarListItem = styled.li`
	display: flex;
`;

export const StyledIcon = styled.span`
	display: flex;
	padding-right: 1.5rem;
	width: 3rem;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
`;

export const StyledNavLink = styled(NavLink)`
	display: flex;
	color: ${({ theme }) => theme.colors.text.secondary};
	width: 100%;
	font-size: 0.95rem;
	text-decoration: none;
	padding: 0.5rem 0.5rem 0.5rem 1.25rem;
	margin: 0.15rem 0;

	&:hover {
		color: ${({ theme }) => theme.colors.secondary.main};
		& > ${StyledIcon} {
			color: ${({ theme }) => theme.colors.text.secondary};
		}
	}

	&.active {
		color: ${({ theme }) => theme.colors.secondary.main};
		background-color: ${({ theme }) => theme.colors.secondary.light};
		border-radius: 25px 0 0 25px;

		& > ${StyledIcon} {
			color: ${({ theme }) => theme.colors.secondary.medium};
		}
	}

	&:not(.active) {
		& > ${StyledIcon} {
			opacity: 0.8;
		}
	}
`;

export const PageNameText = styled.span``;
