import { Dimension } from 'common/types';
import { AllDimensionsResponse } from 'store/index';
import { TooltipSetters } from './GraphContainerWithTooltip.types';

const foundLabelIndex = 0;
const labelIndexStart = 7;
const labelIndexEnd = 9;
const dimensionIndexOffset = 1;

const mouseOver = (label: string, setters: TooltipSetters, dimensions?: AllDimensionsResponse<Dimension>) => {
	const primaryDimension = label.endsWith('A') ? true : false;
	const labelIndex = parseInt(label.substring(labelIndexStart, labelIndexEnd)) - dimensionIndexOffset;

	if (dimensions) {
		const dimension = primaryDimension ? dimensions[labelIndex].primary : dimensions[labelIndex].contrary;
		const dimensionTitle = dimension.name;
		const dimensionDescription = dimension.legendText;

		setters.setTitle(dimensionTitle ? dimensionTitle : '');
		setters.setDescription(dimensionDescription ? dimensionDescription : '');
		setters.setIsTooltipOpen(true);
	}
};

export const appendTooltip = (
	labels: string[],
	containerId: string,
	setters: TooltipSetters,
	dimensions?: AllDimensionsResponse<Dimension>,
) => {
	labels.forEach((label) => {
		const container = document.getElementById(containerId);

		if (!container) return;

		const foundLabel = container.getElementsByClassName(label)[foundLabelIndex];
		foundLabel?.setAttribute('cursor', 'pointer');
		foundLabel?.addEventListener('mouseover', () => {
			mouseOver(label, setters, dimensions);
		});
		foundLabel?.addEventListener('mouseout', () => {
			setters.setIsTooltipOpen(false);
		});
	});
};

export const cleanupEvents = (
	labels: string[],
	containerId: string,
	setters: TooltipSetters,
	dimensions?: AllDimensionsResponse<Dimension>,
) => {
	labels.forEach((label) => {
		const container = document.getElementById(containerId);
		if (!container) return;
		const foundLabel = container.getElementsByClassName(label)[foundLabelIndex];
		foundLabel?.removeEventListener('mouseover', () => {
			mouseOver(label, setters, dimensions);
		});
		foundLabel?.removeEventListener('mouseout', () => {
			setters.setIsTooltipOpen(false);
		});
	});
};
