import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const CheckboxInput = styled.input`
	display: none;
`;

export const CheckboxLabel = styled.label`
	cursor: pointer;
`;

export const CheckboxIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.colors.common.mediumGrey};
`;
