import styled from 'styled-components';
import { Dropdown } from 'common/components/Dropdown';
import { DropdownButton } from 'common/components/Dropdown/styled';
import { DropdownItemListItem } from 'common/components/Dropdown/DropdownItemLabel/styled';

export const StyledTableColumnSelector = styled(Dropdown)`
	width: 15rem;
	margin-left: auto;
	justify-content: flex-end;

	${DropdownButton} {
		width: 12.5rem;
		margin-left: auto;
		font-size: 1.2rem;
		letter-spacing: 0px;
		text-transform: uppercase;
	}

	${DropdownItemListItem} {
		text-transform: capitalize;
	}
`;
