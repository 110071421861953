import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ImportParticipantsState } from './importParticipantsSlice.types';

const initialState: ImportParticipantsState = {
	fileObjectUrl: undefined,
	filename: undefined,
};

export const importParticipantsSlice = createSlice({
	name: 'importParticipants',
	initialState,
	reducers: {
		setImportParticipantsState: (state, { payload }: PayloadAction<ImportParticipantsState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setImportParticipantsState } = importParticipantsSlice.actions;

export const selectImportParticipantsState = (state: RootState) => {
	return state.importParticipants;
};
