import { OddsRatio, Quartile } from 'store/api/predictiveApi';
import { IData } from '@support/alderbrooke-d3-graphs/dist/VerticalBar/IChartData';

export class GraphDataFactory {
	public create(data: OddsRatio): IData[] {
		return [
			this._createBar('1', data.firstQuartile),
			this._createBar('2', data.secondQuartile),
			this._createBar('3', data.thirdQuartile),
			this._createBar('4', data.fourthQuartile),
		];
	}
	private _createBar(label: string, quartile: Quartile): IData {
		const percentage = 100;
		const zero = 0;
		const key = quartile.odds > zero ? 'oddsFor' : 'oddsAgainst';
		return {
			label: label,
			series: [
				{
					key: key,
					value: quartile.odds / percentage,
				},
			],
		};
	}
}
