import { StyledDropdown } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantTypeSelection/styled';
import { Criterion, CriterionId } from 'common/types';
import { DropdownItemWithIcon } from 'common/components';

export const CriteriaDropdown = ({
	criterion,
	criteria,
	setCriterion,
}: {
	criterion: Criterion;
	criteria: Criterion[];
	setCriterion: (criterion: Criterion) => void;
}) => {
	const dropdownItems = criteria.map((item) => createDropdownItem(item.id, item.name));

	const searchCriteria = (id: string) => {
		const found = criteria.find((c) => c.id == id);
		if (!found) throw Error();
		return found;
	};

	return (
		<StyledDropdown
			value={criterion.name}
			onChange={(entry) => setCriterion(searchCriteria(entry))}
		>
			{dropdownItems}
		</StyledDropdown>
	);

	function createDropdownItem(id: CriterionId | number, value: string) {
		return (
			<DropdownItemWithIcon
				key={id}
				value={id.toString()}
			>
				{value}
			</DropdownItemWithIcon>
		);
	}
};
