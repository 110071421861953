import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStakeholderInformationState, StakeholderInformationState } from 'store/slices';
import { handleFormMailBlur, handleRequiredFormValue, handleFormTelephoneBlur, createFormState } from 'common/utils';
import { StakeholderInformationProps } from './StakeholderInformation.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const StakeholderInformation = ({
	saveTrigger,
	projectStakeholderIMContact: initialProjectStakeholderIMContact,
	projectStakeholderTelephoneNumber: initialProjectStakeholderTelephoneNumber,
	projectStakeholderMobileNumber: initialProjectStakeholderMobileNumber,
	projectStakeholderEmail: initialProjectStakeholderEmail,
	projectStakeholderName: initialProjectStakeholderName,
}: StakeholderInformationProps) => {
	const [stakeholderIM, setStakeholderIM] = useState(emptyIfNullOrUndefined(initialProjectStakeholderIMContact));

	const [stakeholderName, setStakeholderName] = useState<RequiredFormInputState>(
		createFormState(initialProjectStakeholderName),
	);

	const [stakeholderMail, setStakeholderMail] = useState<RequiredFormInputState>(
		createFormState(initialProjectStakeholderEmail),
	);

	const [stakeholderTelephone, setStakeholderTelephone] = useState<RequiredFormInputState>(
		createFormState(initialProjectStakeholderTelephoneNumber),
	);

	const [stakeholderMobile, setStakeholderMobile] = useState<RequiredFormInputState>(
		createFormState(initialProjectStakeholderMobileNumber),
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}

		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(stakeholderName.value, 'Project stakeholder name is required!', setStakeholderName);
		handleFormMailBlur(
			stakeholderMail.value,
			'Project stakeholder contact email does not have correct format!',
			setStakeholderMail,
		);
		handleFormTelephoneBlur(
			stakeholderTelephone.value,
			'Project stakeholder telephone is not in correct format!',
			setStakeholderTelephone,
		);
		handleFormTelephoneBlur(
			stakeholderMobile.value,
			'Project stakeholder mobile is not in correct format!',
			setStakeholderMobile,
		);
	};

	const setSliceState = () => {
		const sliceState: StakeholderInformationState = {
			stakeholderIM: stakeholderIM,
			stakeholderMail: stakeholderMail.value,
			stakeholderMobile: stakeholderMobile.value,
			stakeholderName: stakeholderName.value,
			stakeholderTelephone: stakeholderTelephone.value,
		};

		dispatch(setStakeholderInformationState(sliceState));
	};

	return (
		<>
			<Input
				{...stakeholderName}
				name="Name of project stakeholder"
				maxLength={50}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(
						event.target.value,
						'Project stakeholder name is required!',
						setStakeholderName,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(
						event.target.value,
						'Project stakeholder name is required!',
						setStakeholderName,
					);
					setSliceState();
				}}
			/>
			<Input
				{...stakeholderMail}
				name="Email of project stakeholder"
				maxLength={50}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setStakeholderMail({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormMailBlur(
						event.target.value,
						'Project stakeholder contact email does not have correct format!',
						setStakeholderMail,
					);
					setSliceState();
				}}
			/>
			<Input
				{...stakeholderTelephone}
				name="Telephone of project stakeholder"
				placeholder="Please use international format"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setStakeholderTelephone({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormTelephoneBlur(
						event.target.value,
						'Project stakeholder telephone is not in correct format!',
						setStakeholderTelephone,
					);
					setSliceState();
				}}
			/>
			<Input
				{...stakeholderMobile}
				name="Mobile number of project stakeholder"
				placeholder="Please use international format"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setStakeholderMobile({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormTelephoneBlur(
						event.target.value,
						'Project stakeholder mobile is not in correct format!',
						setStakeholderMobile,
					);
					setSliceState();
				}}
			/>
			<Input
				name="IM contact of project stakeholder"
				maxLength={30}
				value={stakeholderIM}
				formInput
				onChange={(event) => {
					setStakeholderIM(event.target.value);
				}}
				onBlur={(event) => {
					setStakeholderIM(event.target.value);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
