import { ChartTypeMenuItemLabelProps } from './ChartTypeMenuItemLabel.types';
import { IconImage, StyledIconButton } from './styled';

export const ChartTypeMenuItemLabel = <T,>({ isSelected, iconSrc, onClick }: ChartTypeMenuItemLabelProps<T>) => {
	return (
		<StyledIconButton
			$isSelected={isSelected}
			onClick={onClick}
		>
			<IconImage src={iconSrc} />
		</StyledIconButton>
	);
};
