import { ZIndexes } from 'common/constants';
import styled from 'styled-components';

export const StyledTBody = styled.tbody`
	z-index: ${ZIndexes.tableBody};
	position: inherit;
	tr:nth-child(even) {
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
	}
`;
