import { Portal } from 'common/components';
import { BackgroundOverlay, ChartContentContainer, SideButton, SideButtonsContainer } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { SettingsMenuView } from '../../../Simulation/views/SettingsMenuView';
import { useDispatch } from 'react-redux';
import { toggleChartSettingsView } from 'store/slices';
import { PredictiveViewResultsProps } from './PredictiveViewResults.types';
import { PredictiveViewResultsMenu } from 'features/Predictive/components';
import { OddsRatioGraph } from 'features/Graphs/OddsRatioGraph';
import { useState } from 'react';

export const PredictiveViewResults = ({ data, isOpened, onClose }: PredictiveViewResultsProps) => {
	const dispatch = useDispatch();

	const [menuSettings, setMenuSettings] = useState({ quartileInfoEnabled: false });

	return isOpened ? (
		<Portal wrapperId="predictive-view-root">
			<BackgroundOverlay>
				<SettingsMenuView>
					<PredictiveViewResultsMenu
						data={data}
						settings={menuSettings}
						setSettings={setMenuSettings}
					/>
				</SettingsMenuView>
				<SideButtonsContainer>
					<SideButton onClick={() => dispatch(toggleChartSettingsView(true))}>
						<FontAwesomeIcon icon={faGear} />
					</SideButton>
					<SideButton onClick={onClose}>
						<FontAwesomeIcon icon={faHouse} />
					</SideButton>
				</SideButtonsContainer>
				<ChartContentContainer>
					<OddsRatioGraph
						data={data}
						config={graphConfiguration()}
					/>
				</ChartContentContainer>
			</BackgroundOverlay>
		</Portal>
	) : (
		<></>
	);

	function graphConfiguration() {
		const textSize = 21;
		return {
			enableQuartileInfo: menuSettings.quartileInfoEnabled,
			quartileInfoTextSize: textSize,
		};
	}
};
