import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
	GraphDnaSettings,
	BenchmarkState,
	BenchmarksNamesKey,
	BenchmarkColorConfig,
	BenchmarkColorConfigGraphLine,
} from './benchmarkSlice.types';
import {
	defaultBenchmark1,
	defaultBenchmark2,
	defaultBenchmark3,
	defaultBenchmark4,
	defaultBenchmark5,
} from './constants';

const initialState: BenchmarkState = {
	benchmarksNames: {
		benchmark1: '',
		benchmark2: '',
		benchmark3: '',
		benchmark4: '',
		benchmark5: '',
	},
	benchmarksSettings: {
		benchmark1: defaultBenchmark1,
		benchmark2: defaultBenchmark2,
		benchmark3: defaultBenchmark3,
		benchmark4: defaultBenchmark4,
		benchmark5: defaultBenchmark5,
	},
};

export const benchmarkSlice = createSlice({
	name: 'benchmark',
	initialState,
	reducers: {
		setBenchmarkName: (state, { payload }: PayloadAction<{ benchmarkKey: BenchmarksNamesKey; value: string }>) => {
			const { benchmarkKey, value } = payload;
			state.benchmarksNames[benchmarkKey] = value;
			return state;
		},
		setBenchmarksNames: (state, { payload }: PayloadAction<Record<BenchmarksNamesKey, string>>) => {
			state.benchmarksNames = payload;
			return state;
		},
		setBenchmarkSettings: (
			state,
			{ payload }: PayloadAction<{ benchmarkKey: BenchmarksNamesKey; value: GraphDnaSettings }>,
		) => {
			const { benchmarkKey, value } = payload;
			state.benchmarksSettings[benchmarkKey] = value;
			return state;
		},
		setBenchmarksSettings: (state, { payload }: PayloadAction<Record<BenchmarksNamesKey, GraphDnaSettings>>) => {
			state.benchmarksSettings = payload;
			return state;
		},
		setBenchmarkColorConfig: (state, { payload }: PayloadAction<BenchmarkColorConfig>) => {
			setStateBenchmark(state.benchmarksSettings.benchmark1, payload.graphLines.benchmark1);
			setStateBenchmark(state.benchmarksSettings.benchmark2, payload.graphLines.benchmark2);
			setStateBenchmark(state.benchmarksSettings.benchmark3, payload.graphLines.benchmark3);
			setStateBenchmark(state.benchmarksSettings.benchmark4, payload.graphLines.benchmark4);
			setStateBenchmark(state.benchmarksSettings.benchmark5, payload.graphLines.benchmark5);

			return state;
		},
		resetBenchmarkState: () => initialState,
	},
});

const setStateBenchmark = (stateBenchmark: GraphDnaSettings, payloadBenchmark: BenchmarkColorConfigGraphLine) => {
	stateBenchmark.organisational = {
		...stateBenchmark.organisational,
		...payloadBenchmark.organisational,
	};
	stateBenchmark.personal = {
		...stateBenchmark.personal,
		...payloadBenchmark.personal,
	};
};

export const {
	setBenchmarkName,
	setBenchmarksNames,
	setBenchmarkSettings,
	setBenchmarksSettings,
	resetBenchmarkState,
	setBenchmarkColorConfig,
} = benchmarkSlice.actions;
export const benchmarkSliceReducer = benchmarkSlice.reducer;

export const selectBenchmarkNames = (state: RootState) => state.benchmark.benchmarksNames;
export const selectBenchmarksSettings = (state: RootState) => state.benchmark.benchmarksSettings;
