import { SelectionPanelProps } from './SelectionPanel.types';
import { SelectionPanelButton } from './SelectionPanelButton';
import { SelectionPanelContainer, StyledTooltip } from './styled';

export const SelectionPanel = <T extends object>({
	children,
	className,
	selectedItemId,
	onSelect,
	id,
	customButtonVariants,
}: SelectionPanelProps<T>) => {
	const SelectionPanelItems = children.map(({ props }) => {
		const { children, id, fadedWhenInactive, itemData, disabled, TooltipContent } = props;

		const PanelItemButton = (
			<SelectionPanelButton
				key={id}
				onClick={() => onSelect(itemData)}
				faded={fadedWhenInactive && selectedItemId !== id}
				disabled={disabled}
				isSelected={selectedItemId === id}
				customButtonVariants={customButtonVariants}
			>
				{children}
			</SelectionPanelButton>
		);

		if (disabled || fadedWhenInactive || !TooltipContent) {
			return PanelItemButton;
		}

		return (
			<StyledTooltip
				key={id}
				content={TooltipContent}
			>
				{PanelItemButton}
			</StyledTooltip>
		);
	});

	return (
		<SelectionPanelContainer
			id={id}
			className={className}
		>
			{SelectionPanelItems}
		</SelectionPanelContainer>
	);
};
