import {
	ApiPredictiveResult,
	PredictiveResult,
	PredictiveViewValidationError,
} from 'store/api/predictiveApi/predictiveApi.types';
import { isResponseError } from 'common/utils';
import { HttpResponseStatusCodes } from 'common/types';

export const mapPredictiveData = (data: ApiPredictiveResult | undefined): PredictiveResult | undefined => {
	return data;
};

export function vetoIfBadRequest(data: unknown) {
	const error = isResponseError(data) ? data : undefined;
	const errorObj = error ? { error: error.error, status: error.status, data: error.message } : undefined;

	if (errorObj && errorObj.status === HttpResponseStatusCodes.BAD_REQUEST) {
		const message = errorObj.data as string;
		throw new PredictiveViewValidationError(message);
	}
}
