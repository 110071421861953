import { ApiProject, Project } from 'common/types';
import { AllProjectsResponse, useGetAllProjectsQuery } from 'store/api';
import { getAllProjectsEmptyResponse } from 'store/api';
import { mapGetAllProjectsResponse } from './mappers';

export const useProjects = () => {
	const allProjects = useGetAllProjectsQuery();
	const emptyResponse = getAllProjectsEmptyResponse();
	const projects = getProjectsData();

	function getProjectsData(): AllProjectsResponse<ApiProject> {
		if (!allProjects.isSuccess) return emptyResponse;

		return allProjects.data;
	}

	function transform(projects: AllProjectsResponse<ApiProject>): AllProjectsResponse<Project> {
		return mapGetAllProjectsResponse(projects);
	}

	return { projects: transform(projects).projects, areProjectsFetched: allProjects.isSuccess };
};
