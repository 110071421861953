import { CategoryComparator, QuadrantGraphViewProps } from 'features/DiscoveryInsights/components/';
import { QuadrantGraphTypes } from 'features/DiscoveryInsights/components';
import { QuadrantChart, QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph';
import { useQuadrantColorPreset } from 'features/Graphs/QuadrantGraph/hooks';
import { useQuadrantChartConfig } from 'features/Graphs/QuadrantGraph/hooks';
import { QuadrantGraphState } from 'store/slices';
import { ClusterId } from 'common/types';
import { CriteriaPerQuarter, PercentagePerQuarter } from 'store/api/discoveryInsightApi';
import {
	CriteriaViewLegendCreator,
	HighlightLegendCreator,
	TotalViewLegendCreator,
} from 'features/DiscoveryInsights/model';
import { ApiDistributionPerQuarter, ApiQuadrantResult } from 'store/api';
import { QuadrantStateCreator } from 'features/GraphDataSeparator/utils/QuadrantStateCreator';
import { useComparatorFactory } from 'features/DiscoveryInsights/components';
import { GraphContainer } from './styled';

export const QuadrantGraphView = ({
	clusterId,
	quadrantType,
	data,
	quadrantCenter,
	colorPresetId,
	quadrantLegendSorting,
	groupFilterThreshold,
}: QuadrantGraphViewProps) => {
	const comparatorFactory = useComparatorFactory();
	const dotColorConfig = useQuadrantColorPreset(colorPresetId);

	const chartConfig = getGraphConfigByType();
	const graphData = getGraphByType();
	const quadrantData = selectClusterById(graphData, clusterId.replace('-', ''));

	return (
		<>
			<GraphContainer
				id={'1'}
				key={`${quadrantType}${quadrantCenter.x}${quadrantCenter.y}`}
			/>
			<QuadrantChart
				containerId={'1'}
				config={chartConfig}
				center={quadrantCenter}
				data={quadrantData}
				dotColorConfig={dotColorConfig}
			/>
		</>
	);

	function selectClusterById(quadrant: QuadrantGraphState, clusterId: ClusterId) {
		const selectedCluster = quadrant.clusters.find((value) => value.clusterId === clusterId);
		if (!selectedCluster) {
			throw Error();
		}
		return selectedCluster.data;
	}

	function getGraphByType(): QuadrantGraphState {
		const comparator = comparatorFactory.createComparator(quadrantLegendSorting);
		switch (quadrantType) {
			case QuadrantGraphTypes.TOTAL_VIEW:
				return createQuadrant(data.totalView, totalViewLegendCreator());
			case QuadrantGraphTypes.CRITERIA_VIEW:
				return createQuadrant(data.criteriaView, criteriaViewLegendCreator(comparator, groupFilterThreshold));
			case QuadrantGraphTypes.HIGHLIGHTS:
				return createQuadrant(data.highlights, highlightViewLegendCreator(comparator));
		}
		throw Error();
	}

	function getGraphConfigByType() {
		const config = useQuadrantChartConfig();
		const createTitle = (title: string) =>
			`QUADRANT DISPERSION FOR PERSONAL VS ORGANISATIONAL BEHAVIOURS ( ${title} )`;
		switch (quadrantType) {
			case QuadrantGraphTypes.TOTAL_VIEW:
				return { ...config, graphTitle: createTitle('TOTAL VIEW') };
			case QuadrantGraphTypes.CRITERIA_VIEW:
				return { ...config, graphTitle: createTitle('CRITERIA VIEW') };
			case QuadrantGraphTypes.HIGHLIGHTS:
				return { ...config, graphTitle: createTitle('HIGHLIGHTS') };
		}
		return config;
	}

	function totalViewLegendCreator(): QuadrantLegendCreator<PercentagePerQuarter> {
		return new TotalViewLegendCreator();
	}

	function criteriaViewLegendCreator(comparator: CategoryComparator, groupFilterThreshold: number) {
		return new CriteriaViewLegendCreator(comparator, groupFilterThreshold);
	}

	function highlightViewLegendCreator(comparator: CategoryComparator): QuadrantLegendCreator<CriteriaPerQuarter> {
		return new HighlightLegendCreator(comparator);
	}

	function createQuadrant<T extends ApiDistributionPerQuarter>(
		data: ApiQuadrantResult<T>,
		legendCreator: QuadrantLegendCreator<T>,
	): QuadrantGraphState {
		return new QuadrantStateCreator(legendCreator).create(data);
	}
};
