import { BenchmarkParticipant } from 'common/types';
import { TableCheckbox } from 'common/components';
import React from 'react';
import { BenchmarkConfigurationId } from 'store/api';
import { BenchmarkParticipantColumnIds } from 'features/Tables/hooks/useBenchmarkParticipantData/BenchmarkParticipantColumnIds';
import { useLazyToggleParticipantQuery } from 'store/api';
import { useSuspenseManually } from 'common/hooks';
import { translateBenchmarkGroup } from 'store/api/benchmarkConfigurationApi/BenchmarkGroupTranslator';

export type BenchmarkToggleProps = {
	benchmarkConfigurationId: BenchmarkConfigurationId | undefined;
	benchmarkGroup: string;
	participant: BenchmarkParticipant;
	onAssignationChange: () => void;
};

export const BenchmarkParticipantToggle = ({
	benchmarkConfigurationId,
	benchmarkGroup,
	participant,
	onAssignationChange,
}: BenchmarkToggleProps) => {
	const suspense = useSuspenseManually();
	const [toggle] = useLazyToggleParticipantQuery();
	const assignedToGroup = getBenchmarkValue(participant, benchmarkGroup);

	return React.useMemo(checkbox, []);

	function toggleParticipant() {
		if (!benchmarkConfigurationId) {
			return;
		}
		const participantRequest = {
			participantId: participant.participantId,
			benchmarkGroup: translateBenchmarkGroup(benchmarkGroup),
			benchmarkConfigurationId,
		};
		suspense.turnOn();
		toggle(participantRequest).then(() => {
			onAssignationChange();
		});
	}

	function checkbox() {
		return (
			<TableCheckbox
				checked={assignedToGroup}
				onChange={() => toggleParticipant()}
			/>
		);
	}
	function getBenchmarkValue(participant: BenchmarkParticipant, benchmark: string): boolean {
		switch (benchmark) {
			case BenchmarkParticipantColumnIds.Benchmark1:
				return participant.assignedToGroup1;
			case BenchmarkParticipantColumnIds.Benchmark2:
				return participant.assignedToGroup2;
			case BenchmarkParticipantColumnIds.Benchmark3:
				return participant.assignedToGroup3;
			case BenchmarkParticipantColumnIds.Benchmark4:
				return participant.assignedToGroup4;
			case BenchmarkParticipantColumnIds.Benchmark5:
				return participant.assignedToGroup5;
			case BenchmarkParticipantColumnIds.Benchmark6:
				return participant.assignedToGroup6;
			case BenchmarkParticipantColumnIds.Benchmark7:
				return participant.assignedToGroup7;
			case BenchmarkParticipantColumnIds.Benchmark8:
				return participant.assignedToGroup8;
			case BenchmarkParticipantColumnIds.Benchmark9:
				return participant.assignedToGroup9;
			case BenchmarkParticipantColumnIds.Benchmark10:
				return participant.assignedToGroup10;
		}
		return false;
	}
};
