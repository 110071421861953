import { ColumnDef, ColumnFilter, HeaderContext } from '@tanstack/react-table';
import { Participant, ParticipantId, AssignmentParticipants } from 'common/types';
import { Criterion } from 'common/types';
import { useGetLoggedInUserQuery } from 'store/api';
import React, { useState } from 'react';
import { AssignmentParticipantsDataColumnIds } from './assignParticipantsToProject';
import { AssignHeaderHeader } from 'features/ProjectConfiguration/components/AssignHeader';
import { AssignParticipantToggle } from 'features/ProjectConfiguration/components/AssignParticipantToggle';
import { CellContext } from '@tanstack/table-core/build/lib/core/cell';
import { OnAssignationChangeCallback } from 'features/Tables/hooks';

export const useAssignParticipantsToProject = (onAssignmentChange: OnAssignationChangeCallback) => {
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);
	const loggedUser = useGetLoggedInUserQuery();
	const criteriaColumns: ColumnDef<AssignmentParticipants>[] = createCriteriaColumns();

	const personalColumns: ColumnDef<AssignmentParticipants>[] = [
		{
			id: AssignmentParticipantsDataColumnIds.Email,
			header: 'Email',
			accessorFn: (p) => p.email,
		},
		{
			id: AssignmentParticipantsDataColumnIds.FirstName,
			header: 'First',
			accessorFn: (p) => p.firstName,
		},
		{
			id: AssignmentParticipantsDataColumnIds.LastName,
			header: 'Last Name',
			accessorFn: (p) => p.lastName,
		},
	];
	const assignColumn: ColumnDef<AssignmentParticipants>[] = [AssignmentParticipantsDataColumnIds.Assign].map(
		createAssignColumn,
	);

	const columns = React.useMemo<ColumnDef<AssignmentParticipants>[]>(
		() => personalColumns.concat(criteriaColumns).concat(assignColumn),
		[loggedUser.isSuccess],
	);

	const visibleColumns = columns.filter((col) =>
		[
			AssignmentParticipantsDataColumnIds.Email,
			AssignmentParticipantsDataColumnIds.FirstName,
			AssignmentParticipantsDataColumnIds.LastName,
			AssignmentParticipantsDataColumnIds.Assign,
		].includes(col.id as string),
	);

	return {
		columns,
		visibleColumns,
		selectedParticipantIds,
		setSelectedParticipantIds,
	};

	function createAssignColumn(assignParticipant: string): ColumnDef<AssignmentParticipants> {
		return {
			id: assignParticipant,
			header: createHeaderFor(),
			cell: createToggleFor(),
			accessorFn: (assignParticipantData: AssignmentParticipants) => assignParticipantData,
			enableSorting: false,
			enableColumnFilter: false,
		};
	}

	function createHeaderFor() {
		function Header({ table }: HeaderContext<AssignmentParticipants, unknown>) {
			const filters = createFiltersList(table.getState().columnFilters);
			return (
				<AssignHeaderHeader
					filters={filters}
					assignName={'Assign'}
					onAssignationChange={onAssignmentChange}
				/>
			);
		}
		return Header;
	}

	function createToggleFor() {
		function Toggle({ getValue }: CellContext<AssignmentParticipants, unknown>) {
			const participant = getValue<AssignmentParticipants>();
			return (
				<AssignParticipantToggle
					participant={participant}
					onAssignationChange={onAssignmentChange}
				/>
			);
		}
		return Toggle;
	}

	function createCriteriaColumns(): ColumnDef<AssignmentParticipants>[] {
		const criteria = loggedUser.data?.configuration.criteriaConfiguration ?? [];

		const createColumn = (criterion: Criterion): ColumnDef<AssignmentParticipants> => ({
			id: `c${criterion.id}`,
			header: criterion.name,
			accessorFn: (participant: Participant) => participant.criteria.findById(criterion.id).name,
		});

		return criteria.map(createColumn);
	}

	function createFiltersList(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	}
};
