import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { TensionChartConfiguration } from 'store/slices';

const initialState: TensionChartConfiguration = {
	tooltipEnabled: true,
};

export const tensionGraphConfigurationSlice = createSlice({
	name: 'tensionGraphConfigSlice',
	initialState,
	reducers: {
		setTensionGraphTooltipState: (state, { payload }: PayloadAction<boolean>) => {
			state.tooltipEnabled = payload;
			return state;
		},
		reset: () => initialState,
	},
});

export const { setTensionGraphTooltipState } = tensionGraphConfigurationSlice.actions;
export const tensionGraphConfigurationReducer = tensionGraphConfigurationSlice.reducer;
export const getTensionGraphTooltipEnabled = (state: RootState) => state.tensionGraphConfig.tooltipEnabled;
