import { ApiCluster, Cluster, DefaultClusterName } from 'common/types';
import { AllClustersResponse } from 'store/api';

export const mapGetAllClustersResponse = (response: AllClustersResponse<ApiCluster>): AllClustersResponse<Cluster> => {
	const apiClusters = response;

	const mappedClusters: Cluster[] = apiClusters.map((apiCluster) => {
		return {
			defaultName: apiCluster.name as DefaultClusterName,
			clusterId: apiCluster.name,
			name: apiCluster.name,
		};
	});

	return mappedClusters;
};
