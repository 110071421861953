import { useState, useEffect, useRef } from 'react';

type UseComponentVisibleReturnType<T> = {
	ref: React.RefObject<T>;
	isComponentVisible: boolean;
	setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

type UseComponentVisibleProps = {
	initialIsVisible: boolean;
	ignoredEl?: React.RefObject<unknown>;
};

export const useComponentVisible = <T>({
	initialIsVisible,
	ignoredEl,
}: UseComponentVisibleProps): UseComponentVisibleReturnType<T> => {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef<T>(null);

	const handleClickOutside = (e: MouseEvent) => {
		const isNodeType = e.target instanceof Node;
		const isHtmlElementType = ref.current instanceof HTMLElement;
		if (ref.current && isHtmlElementType && isNodeType && !ref.current.contains(e.target)) {
			const isIgnoredElHtmlElementType = ignoredEl?.current instanceof HTMLElement;
			if (isIgnoredElHtmlElementType && e.target === ignoredEl.current) return;
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return { ref, isComponentVisible, setIsComponentVisible };
};
