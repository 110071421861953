import { DropdownItem, DropdownItemProps, FormDropdown, FormTextarea, Input } from 'common/components';
import { AssessmentTypes } from 'common/constants';
import { RequiredFormInputState } from 'common/types';
import {
	createFormState,
	emptyIfNullOrUndefined,
	getAssessmentName,
	getKeyByValue,
	handleRequiredFormValue,
	undefinedIfEmptyString,
} from 'common/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyCheckProjectIdAvailabilityQuery } from 'store/api';
import { ProjectDetailsState, setProjectDetailsState } from 'store/slices';
import { ProjectDetailsProps } from './ProjectDetails.types';

export const ProjectDetails = ({
	projectId: initialProjectId,
	projectDescription: initialProjectDescription,
	assessmentType: initialAssessmentType,
	costCentreReference: initialCostCentreReference,
	disableProjectId,
	disableAssessmentType,
	saveTrigger,
}: ProjectDetailsProps) => {
	const [validateAvailability] = useLazyCheckProjectIdAvailabilityQuery();
	const dispatch = useDispatch();

	const [projectId, setProjectId] = useState<RequiredFormInputState>(createFormState(initialProjectId));
	const [projectDescription, setProjectDescription] = useState<RequiredFormInputState>(
		createFormState(initialProjectDescription),
	);
	const [assessmentType, setAssessmentType] = useState<RequiredFormInputState>(
		createFormState(initialAssessmentType),
	);
	const [costCentreReference, setCostCentreReference] = useState(emptyIfNullOrUndefined(initialCostCentreReference));

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(projectId.value, 'Project ID is required!', setProjectId);
		handleRequiredFormValue(projectDescription.value, 'Project Description is required!', setProjectDescription);
		handleRequiredFormValue(assessmentType.value, 'Assessment Type is required!', setAssessmentType);

		validateProjectIdAvailability();
	};

	const setSliceState = () => {
		const sliceState: ProjectDetailsState = {
			projectId: projectId.value,
			description: projectDescription.value,
			assessmentType: assessmentType.value as keyof typeof AssessmentTypes,
			costCentreReference: undefinedIfEmptyString(costCentreReference),
		};

		dispatch(setProjectDetailsState(sliceState));
	};

	const validateProjectIdAvailability = async () => {
		if (!disableProjectId && projectId.value) {
			const { available } = await validateAvailability({ projectShortId: projectId.value }).unwrap();

			if (!available) {
				setProjectId({
					value: projectId.value,
					error: !available,
					errorText: 'ID already exists!',
				});
			}
		}
	};

	const dropdownAssessments = [
		{
			assessmentType: getKeyByValue(AssessmentTypes, AssessmentTypes.OPDNA),
			name: getAssessmentName(AssessmentTypes.OPDNA),
		},
		{
			assessmentType: getKeyByValue(AssessmentTypes, AssessmentTypes.ODNA),
			name: getAssessmentName(AssessmentTypes.ODNA),
		},
		{
			assessmentType: getKeyByValue(AssessmentTypes, AssessmentTypes.PDNA),
			name: getAssessmentName(AssessmentTypes.PDNA),
		},
	];

	const AssessmentsDropdownItems: React.ReactElement<DropdownItemProps>[] = [
		...dropdownAssessments.map((assessment) => {
			if (!assessment.assessmentType) return <></>;
			return (
				<DropdownItem
					key={assessment.assessmentType}
					value={assessment.assessmentType}
				>
					{assessment.name}
				</DropdownItem>
			);
		}),
	];

	const assessmentDropdownLabel = dropdownAssessments.find(
		(assessment) => assessment.assessmentType === assessmentType.value,
	)?.name;

	return (
		<>
			<Input
				{...projectId}
				name="Project ID"
				placeholder="Max 20 characters"
				maxLength={20}
				errorPlacement="right"
				disabled={disableProjectId}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Project ID is required!', setProjectId);
				}}
				onBlur={(event) => {
					const trimmedValue = event.target.value.trim();
					handleRequiredFormValue(trimmedValue, 'Project ID is required!', setProjectId);

					validateProjectIdAvailability();
					setSliceState();
				}}
			/>
			<FormTextarea
				{...projectDescription}
				title="Project Description"
				placeholder="Max 50 characters"
				maxLength={50}
				required
				onChange={(event) => {
					handleRequiredFormValue(
						event.target.value,
						'Project Description is required!',
						setProjectDescription,
					);
				}}
				onBlur={(event) => {
					const trimmedValue = event.target.value.trim();
					handleRequiredFormValue(trimmedValue, 'Project Description is required!', setProjectDescription);
					setSliceState();
				}}
			/>
			<FormDropdown
				{...assessmentType}
				required
				customLabel={assessmentDropdownLabel}
				disabled={disableAssessmentType}
				onChange={(value) => {
					handleRequiredFormValue(value, 'Assessment Type is required!', setAssessmentType);
					setSliceState();
				}}
				onDropdownClose={() => {
					handleRequiredFormValue(assessmentType.value, 'Assessment Type is required!', setAssessmentType);
					setSliceState();
				}}
				title="Assessment Type"
			>
				{AssessmentsDropdownItems}
			</FormDropdown>
			<Input
				value={costCentreReference}
				name="Cost Centre Reference"
				placeholder="If available"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setCostCentreReference(event.target.value);
				}}
				onBlur={(event) => {
					setCostCentreReference(event.target.value);
					setSliceState();
				}}
			/>
		</>
	);
};
