import { PasswordChangeBackground } from './styled';
import { AnimatedBackground, ChangePasswordBox, Footer, PasswordConditionsBox } from 'features/AuthManagement/views';

export const PasswordChange = () => {
	return (
		<div id="password-change">
			<PasswordChangeBackground>
				<AnimatedBackground />
				<PasswordConditionsBox />
				<ChangePasswordBox />
				<Footer />
			</PasswordChangeBackground>
		</div>
	);
};
