import { OPdnaRadarGraph, OdnaRadarGraph, PdnaRadarGraph } from 'features/Graphs';
import { SimulationViewRadarProps } from './SimulationViewRadar.types';
import { ChartContainers } from '../ChartContainers';

export const SimulationViewRadar = ({
	showDataOnOneGraph,
	personal,
	organisational,
	pno,
	selectedClusterIndex,
}: SimulationViewRadarProps) => {
	return (
		<>
			<ChartContainers showDataOnOneGraph={showDataOnOneGraph} />
			<>
				<PdnaRadarGraph
					containerId="1"
					benchmarkIndexes={personal}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
				<OdnaRadarGraph
					containerId="2"
					benchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
				<OPdnaRadarGraph
					containerId="3"
					odnaBenchmarkIndexes={organisational}
					pdnaBenchmarkIndexes={personal}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
			</>
		</>
	);
};
