import { useAuth } from 'common/hooks';
import { StyledImgLink, StyledLogo } from './styled';
import { cultureScopeLogo } from 'common/assets/images';

export const HeaderLogo = () => {
	const auth = useAuth();

	return (
		<StyledImgLink
			id="header-logo"
			to={'/'}
		>
			<StyledLogo
				src={
					auth.user?.configuration.branding?.logo ? auth.user?.configuration.branding.logo : cultureScopeLogo
				}
				alt={'logo'}
			/>
		</StyledImgLink>
	);
};
