import { useDispatch, useSelector } from 'react-redux';
import {
	setPieChartCombinedODNA,
	setPieChartCombinedPDNA,
	setPieChartODNA,
	setPieChartPDNA,
} from 'store/slices/graphSlices';
import { ApiGraphResponseBenchmarks, ApiResultResponse } from 'store/api';
import { isCombinedPdna, isPdna } from 'features/Graphs/utils';
import { PieChartDataSeries } from 'features/Graphs/PieChart/PieChart.types';
import { ProjectResultsGraphType, ProjectResultsGraphTypes } from 'features/Graphs';
import {
	assignBenchmarks,
	getBenchmarkColor,
	getBenchmarkName,
	getResponseClusters,
} from 'features/GraphDataSeparator/utils';
import { selectBenchmarkNames, selectBenchmarksSettings } from 'store/index';

export const usePieChart = (result: ApiResultResponse) => {
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const benchmarkSettings = useSelector(selectBenchmarksSettings);
	const dispatch = useDispatch();

	function prepareValue(value: number | undefined) {
		const defaultValue = 0;
		const divider = 100;
		return value ? value / divider : defaultValue;
	}

	function getLabel(numberOfParticipants: number | undefined, benchmarkIndex: number, labelType: string) {
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		return benchmarkName
			? `${numberOfParticipants} - ${benchmarkName} - ${labelType}`
			: `${numberOfParticipants} - b${benchmarkIndex} - ${labelType}`;
	}

	function prepareCluster(benchmarks: ApiGraphResponseBenchmarks, type: ProjectResultsGraphType) {
		const labelType =
			isPdna(type) || isCombinedPdna(type) ? ProjectResultsGraphTypes.PDNA : ProjectResultsGraphTypes.ODNA;

		const benchmarkOne = 1;
		const benchmarkTwo = 2;
		const benchmarkThree = 3;
		const benchmarkFour = 4;
		const benchmarkFive = 5;

		return [
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkOne, type),
				label: getLabel(benchmarks.BENCHMARK_1?.numberOfParticipants, benchmarkOne, labelType),
				value: prepareValue(benchmarks.BENCHMARK_1?.indicator),
			},
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkTwo, type),
				label: getLabel(benchmarks.BENCHMARK_2?.numberOfParticipants, benchmarkTwo, labelType),
				value: prepareValue(benchmarks.BENCHMARK_2?.indicator),
			},
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkThree, type),
				label: getLabel(benchmarks.BENCHMARK_3?.numberOfParticipants, benchmarkThree, labelType),
				value: prepareValue(benchmarks.BENCHMARK_3?.indicator),
			},
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkFour, type),
				label: getLabel(benchmarks.BENCHMARK_4?.numberOfParticipants, benchmarkFour, labelType),
				value: prepareValue(benchmarks.BENCHMARK_4?.indicator),
			},
			{
				color: getBenchmarkColor(benchmarkSettings, benchmarkFive, type),
				label: getLabel(benchmarks.BENCHMARK_5?.numberOfParticipants, benchmarkFive, labelType),
				value: prepareValue(benchmarks.BENCHMARK_5?.indicator),
			},
		];
	}

	function preparePieChartData(type: ProjectResultsGraphType) {
		const clusters: { benchmarks: PieChartDataSeries[] }[] = [];
		const responseClusters = getResponseClusters(result);

		Object.values(responseClusters).map((cluster) => {
			const benchmarks: ApiGraphResponseBenchmarks = {};
			assignBenchmarks(benchmarks, cluster, type);

			const currentCluster = prepareCluster(benchmarks, type);
			clusters.push({
				benchmarks: currentCluster,
			});
		});

		return { clusters };
	}

	const dataODNA = preparePieChartData(ProjectResultsGraphTypes.ODNA);
	const dataPDNA = preparePieChartData(ProjectResultsGraphTypes.PDNA);
	const dataCombinedPDNA = preparePieChartData(ProjectResultsGraphTypes.COMBINED_PDNA);
	const dataCombinedODNA = preparePieChartData(ProjectResultsGraphTypes.COMBINED_ODNA);

	dispatch(setPieChartODNA(dataODNA));
	dispatch(setPieChartPDNA(dataPDNA));
	dispatch(setPieChartCombinedPDNA(dataCombinedPDNA));
	dispatch(setPieChartCombinedODNA(dataCombinedODNA));
};
