import {
	ApiProject,
	ApiProjectDetails,
	ApiTenantProject,
	ApiAssignmentStatus,
	ApiProjectSummery,
	ApiAssignmentParticipants,
	ApiAssignmentAllParticipants,
} from 'common/types';
import {
	EditProjectBody,
	getFilenameFromResponse,
	rootApi,
	EditParticipantAssignment,
	ProjectSummaryOptions,
} from 'store/api';
import { isResponseError } from 'common/utils';

import {
	AllProjectsResponse,
	ProjectFormBody,
	GetQuestionsConfigFileTemplateResponse,
	ProjectDetailsOptions,
	ProjectDetailsResponse,
	AssignmentStatusResponse,
	AssignmentStatusOptions,
	ProjectSummeryStatusResponse,
	GetProjectParticipantsCsvResponse,
	GetProjectParticipantsCsvOptions,
	GetProjectCompletionReportCsvResponse,
	GetProjectCompletionReportCsvOptions,
	CloseProjectOptions,
	AssignmentParticipantsResponse,
} from './projectApi.types';
import { convertObjectToQueryParams } from './utils';

export const projectApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllProjects: builder.query<AllProjectsResponse<ApiProject>, void>({
			query: () => ({
				url: `/api/projects`,
			}),
			providesTags: ['Projects'],
		}),
		getAllClientProjects: builder.query<AllProjectsResponse<ApiTenantProject>, void>({
			query: () => ({
				url: `/api/projects/all`,
			}),
		}),
		getAssignmentStatusByProjectId: builder.query<
			AssignmentStatusResponse<ApiAssignmentStatus>,
			AssignmentStatusOptions
		>({
			query: (opts) => {
				const { projectId, ...optsCopy } = opts;
				return {
					url: `api/projects/${projectId}/status?${convertObjectToQueryParams(optsCopy)}`,
					method: 'GET',
				};
			},
		}),
		getAssignmentParticipantsByProjectId: builder.query<
			AssignmentParticipantsResponse<ApiAssignmentParticipants>,
			AssignmentStatusOptions
		>({
			query: (opts) => {
				const { projectId, ...optsCopy } = opts;
				return {
					url: `api/projects/${projectId}/participants?${convertObjectToQueryParams(optsCopy)}`,
					method: 'GET',
				};
			},
		}),
		getProjectSummery: builder.query<ProjectSummeryStatusResponse<ApiProjectSummery>, ProjectSummaryOptions>({
			query: (opts) => {
				return {
					method: 'GET',
					url: `/api/projects/${opts.projectId}/summary?${convertObjectToQueryParams(opts)}`,
				};
			},
			providesTags: ['ProjectSummary'],
		}),
		getQuestionsConfigFileTemplate: builder.query<GetQuestionsConfigFileTemplateResponse, void>({
			query: () => ({
				url: '/api/questionsconfigurationfile',
				method: 'GET',
				responseHandler: async (response) => {
					if (!response.ok) return;

					const defaultFilename = 'questions_configuration_template.csv';
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
		getProjectDetails: builder.query<ProjectDetailsResponse<ApiProjectDetails>, ProjectDetailsOptions>({
			query: (opts) => ({
				url: `/api/projects/${opts.projectId}`,
			}),
			providesTags: ['Project'],
		}),
		editProject: builder.mutation<void, ProjectDetailsOptions & EditProjectBody>({
			query: (opts) => ({
				method: 'PUT',
				url: `/api/projects/${opts.projectId}`,
				body: opts,
			}),
			invalidatesTags: ['Project', 'Projects'],
		}),
		editParticipantAssignmentToProject: builder.mutation<void, ProjectDetailsOptions & EditParticipantAssignment>({
			query: (opts) => ({
				method: 'PUT',
				url: `/api/projects/${opts.projectId}/participant`,
				body: opts,
			}),
			invalidatesTags: ['ProjectSummary'],
		}),
		editAllParticipantsAssignmentToProject: builder.mutation<
			AssignmentParticipantsResponse<ApiAssignmentAllParticipants>,
			ApiAssignmentAllParticipants
		>({
			query: (opts) => ({
				method: 'PUT',
				url: `/api/projects/${opts.projectId}/participants`,
				body: opts,
			}),
		}),
		closeProject: builder.mutation<void, CloseProjectOptions>({
			query: (opts) => ({
				method: 'PUT',
				url: `/api/projects/${opts.projectId}/close`,
			}),
			invalidatesTags: ['Project', 'Projects'],
		}),
		createProject: builder.mutation<void, ProjectFormBody>({
			queryFn: async (data, _api, _, baseQuery) => {
				if (data.file) {
					const url = `/api/projects/withAQ`;
					const formData = new FormData();
					const { file, ...projectData } = data;
					const userDataBlob = new Blob([JSON.stringify(projectData)], { type: 'application/json' });
					formData.append('project', userDataBlob);
					formData.append('file', file);
					const response = await baseQuery({
						url,
						method: 'POST',
						body: formData,
					});

					const error = isResponseError(response.data) ? response.data : undefined;
					if (error) return { error: { error: error.error, status: error.status, data: error.message } };
					return { data: undefined };
				}

				const url = `/api/projects`;
				const response = await baseQuery({
					url,
					method: 'POST',
					body: data,
				});

				const error = isResponseError(response.data) ? response.data : undefined;
				if (error) return { error: { error: error.error, status: error.status, data: error.message } };
				return { data: undefined };
			},
			invalidatesTags: ['Projects'],
		}),
		checkProjectIdAvailability: builder.query<{ available: boolean }, { projectShortId: string }>({
			query: (data) => ({
				method: 'GET',
				url: `/api/project-short-id-availability`,
				params: data,
			}),
		}),
		getProjectParticipantsCsv: builder.query<GetProjectParticipantsCsvResponse, GetProjectParticipantsCsvOptions>({
			query: (data) => ({
				url: `/api/projects/${data.projectId}/export-participants`,
				method: 'GET',
				responseHandler: async (response) => {
					if (!response.ok) return;

					const defaultFilename = 'Participant_Export.csv';
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
		getProjectCompletionReportCsv: builder.query<
			GetProjectCompletionReportCsvResponse,
			GetProjectCompletionReportCsvOptions
		>({
			query: (data) => ({
				url: `/api/projects/${data.projectId}/completion-export`,
				method: 'GET',
				responseHandler: async (response) => {
					if (!response.ok) return;

					const defaultFilename = 'Project_Completion_Export.csv';
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});

export const {
	useGetProjectDetailsQuery,
	useGetAllProjectsQuery,
	useLazyGetQuestionsConfigFileTemplateQuery,
	useLazyGetAssignmentStatusByProjectIdQuery,
	useLazyGetAssignmentParticipantsByProjectIdQuery,
	useLazyGetProjectSummeryQuery,
	useLazyCheckProjectIdAvailabilityQuery,
	useEditProjectMutation,
	useEditParticipantAssignmentToProjectMutation,
	useEditAllParticipantsAssignmentToProjectMutation,
	useCreateProjectMutation,
	useGetAllClientProjectsQuery,
	useCloseProjectMutation,
	useLazyGetProjectCompletionReportCsvQuery,
	useLazyGetProjectParticipantsCsvQuery,
} = projectApi;
