import { OPdnaHeatmapGraph } from 'features/Graphs/HeatmapGraph';
import { SimulationViewHeatmapProps } from './SimulationViewHeatmap.types';
import { useState } from 'react';
import { BenchmarkId, TensionGraphModal, TensionGraphRequest } from 'features/Graphs/TensionGraph';
import { ClusterId } from 'common/types';
import { useSelector } from 'react-redux';
import { selectBenchmarkNames } from 'store/index';
import { getBenchmarkName } from 'features/GraphDataSeparator/utils';
import { StyledContainer, StyledFullChartContainer } from './styled';

export const SimulationViewHeatmap = ({
	selectedClusterIndex,
	personal,
	organisational,
	pno,
	colorPresetIndex,
	tensionGraphFactory,
}: SimulationViewHeatmapProps) => {
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const [tensionGraphOpened, setTensionGraphOpened] = useState(false);
	const [tensionGraphData, setTensionGraphData] = useState<TensionGraphRequest | null>(null);

	const getBenchmarkAlias = (benchmarkId: BenchmarkId) => {
		const benchmarkNameValueIndex = 1;
		const benchmarkIndex = parseInt(benchmarkId.charAt(benchmarkId.length - benchmarkNameValueIndex));
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		return benchmarkName ? `${benchmarkName}` : `b${benchmarkIndex}`;
	};

	const onBenchmarkClick = (benchmarkId: BenchmarkId, clusterId: ClusterId) => {
		const benchmarkAlias = getBenchmarkAlias(benchmarkId);
		const tensionChartData = { benchmarkId, clusterId, benchmarkAlias, colorPresetId: colorPresetIndex };
		setTensionGraphData(tensionChartData);
		setTensionGraphOpened(true);
	};

	return (
		<>
			{tensionGraphData && (
				<TensionGraphModal
					tensionGraphRequest={tensionGraphData}
					tensionGraphFactory={tensionGraphFactory}
					open={tensionGraphOpened}
					onClose={() => setTensionGraphOpened(false)}
				/>
			)}
			<StyledContainer>
				<StyledFullChartContainer id="1"></StyledFullChartContainer>
			</StyledContainer>
			<>
				<OPdnaHeatmapGraph
					containerId={'1'}
					clusterIndex={selectedClusterIndex}
					pdnaBenchmarkIndexes={personal}
					odnaBenchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					colorPresetIndex={colorPresetIndex}
					onBenchmarkClick={onBenchmarkClick}
				/>
			</>
		</>
	);
};
