import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'common/components';
import { Header, HeaderContainer, IconsWrapper, StyledIconButton } from './styled';
import { TableAssignHeaderProps } from './TableAssignHeader.types';

export const TableAssignHeader = ({ title, selected, onAssignAllClick, onRemoveAllClick }: TableAssignHeaderProps) => {
	return (
		<HeaderContainer>
			<Header>{title}</Header>
			<IconsWrapper>
				<Tooltip content="Select All Visible Rows">
					<StyledIconButton onClick={selected ? undefined : onAssignAllClick}>
						<FontAwesomeIcon icon={faSquareCheck} />
					</StyledIconButton>
				</Tooltip>
				<Tooltip content="Unselect All Visible Rows">
					<StyledIconButton onClick={onRemoveAllClick}>
						<FontAwesomeIcon icon={faSquare} />
					</StyledIconButton>
				</Tooltip>
			</IconsWrapper>
		</HeaderContainer>
	);
};
