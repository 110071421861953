import { FormDateInput, Input } from 'common/components';
import { RequiredFormInputState } from 'common/types';
import {
	createFormState,
	getUTCDate,
	getUTCDateFromString,
	handleOnlyFutureDateBlur,
	handlePercentageNumberFormBlur,
	undefinedIfEmptyString,
} from 'common/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AutomaticClosureConditionsState, setAutomaticClosureConditionsSlice } from 'store/slices';
import { AutomaticClosureConditionsProps, ClosureDateState } from './AutomaticClosureConditions.types';

export const AutomaticClosureConditions = ({
	percentageOfQuestionnaires: initialPercentageOfQuestionnaires,
	date: initialDate,
	disablePercentageOfQuestionnaires,
	disableDate,
	saveTrigger,
}: AutomaticClosureConditionsProps) => {
	const dispatch = useDispatch();

	const [date, setDate] = useState<RequiredFormInputState>(createFormState(initialDate));
	const fractionDigits = 0;
	const [percentageOfQuestionnaires, setPercentageOfQuestionnaires] = useState(
		createFormState(initialPercentageOfQuestionnaires?.toFixed(fractionDigits)),
	);

	const minDate = getUTCDate(new Date());

	const getClosureDate = () => {
		const isoDate = getUTCDateFromString(date.value);

		if (!date.value) {
			return ClosureDateState.EMPTY;
		}

		if (date.value && isoDate) {
			return isoDate?.toISOString();
		}
		return ClosureDateState.INVALID;
	};

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		if (!disableDate) {
			handleOnlyFutureDateBlur(
				date.value,
				minDate,
				'Invalid date format!',
				'Date should not be in the past!',
				setDate,
			);
		}
		if (!disablePercentageOfQuestionnaires) {
			handlePercentageNumberFormBlur(
				percentageOfQuestionnaires.value,
				'Value should be between 1 and 100%!',
				setPercentageOfQuestionnaires,
			);
		}
	};

	const setSliceState = () => {
		const closureDate = getClosureDate();

		const sliceState: AutomaticClosureConditionsState = {
			closureDate,
			percentOfCompletedQuestionnaires: undefinedIfEmptyString(percentageOfQuestionnaires.value),
		};

		dispatch(setAutomaticClosureConditionsSlice(sliceState));
	};

	return (
		<>
			<FormDateInput
				{...date}
				title="Date"
				disabled={disableDate}
				onChange={(newDateString) => {
					handleOnlyFutureDateBlur(
						newDateString,
						minDate,
						'Invalid date format!',
						'Date should not be in the past!',
						setDate,
					);
					setSliceState();
				}}
				dateInputProps={{
					minDate: minDate,
					onCalendarClose: () => {
						setSliceState();
					},
				}}
			/>
			<Input
				{...percentageOfQuestionnaires}
				type="number"
				min="1"
				max="100"
				name="% Of Completed Questionnaires"
				maxLength={20}
				errorPlacement="right"
				disabled={disablePercentageOfQuestionnaires}
				formInput
				onChange={(event) => {
					handlePercentageNumberFormBlur(
						event.target.value,
						'Value should be between 1 and 100%!',
						setPercentageOfQuestionnaires,
					);
				}}
				onBlur={(event) => {
					handlePercentageNumberFormBlur(
						event.target.value,
						'Value should be between 1 and 100%!',
						setPercentageOfQuestionnaires,
					);

					setSliceState();
				}}
			/>
		</>
	);
};
