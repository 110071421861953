import styled from 'styled-components';

export const StyledPage = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 10%;
	${'svg'} {
		width: 10%;
		height: 10%;
		color: ${({ theme }) => theme.colors.primary.main};
	}
	${'h1'} {
		font-size: 3em;
		margin-bottom: 0px;
	}
	${'h3'} {
		text-transform: uppercase;
	}
`;
