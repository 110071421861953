import { BenchmarksNamesKeys } from 'common/constants';
import { StyledInput } from '../../styled';
import { useDispatch } from 'react-redux';
import { setBenchmarkName } from 'store/slices';
import { useState } from 'react';

export const DefaultBenchmarkInputs = () => {
	const benchmarkNumberIncrement = 1;
	const maxInputLength = 24;
	const placeholder = `Max ${maxInputLength} characters`;

	const dispatch = useDispatch();

	const [tempBenchmarkNames, setTempBenchmarkNames] = useState({
		benchmark1: '',
		benchmark2: '',
		benchmark3: '',
		benchmark4: '',
		benchmark5: '',
	});

	return (
		<>
			{Object.values(BenchmarksNamesKeys).map((benchmarkKey, index) => (
				<StyledInput
					key={benchmarkKey}
					name={`Name Of Benchmark ${index + benchmarkNumberIncrement}`}
					placeholder={placeholder}
					maxLength={maxInputLength}
					onChange={(event) => {
						const value = event.target.value;
						setTempBenchmarkNames({ ...tempBenchmarkNames, [benchmarkKey]: value });
					}}
					onBlur={(event) => dispatch(setBenchmarkName({ benchmarkKey, value: event.target.value }))}
					value={tempBenchmarkNames[benchmarkKey]}
				/>
			))}
		</>
	);
};
