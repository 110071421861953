import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { CriteriaInformationProps } from './CriteriaInformation.types';
import { useCriteriaConfiguration } from 'common/hooks';
import { CriteriaInformationState, setCriteriaInformationState } from 'store/slices';
import { useDispatch } from 'react-redux';
import { ParticipantDetailsCriteriaKey } from 'common/types';

export const CriteriaInformation = ({ saveTrigger, criteria: initialCriteria }: CriteriaInformationProps) => {
	const [criteria, setCriteria] = useState<Record<ParticipantDetailsCriteriaKey, string>>(initialCriteria ?? {});
	const userCriteria = useCriteriaConfiguration();
	const dispatch = useDispatch();

	useEffect(() => {
		setSliceState();
	}, [saveTrigger]);

	useEffect(() => {
		if (initialCriteria) return;

		const initCriteriaState: Record<string, string> = {};
		userCriteria.forEach(
			(userCriterion) => (initCriteriaState[mapCriteriaNameToParticipantCriteriaKey(userCriterion.name)] = ''),
		);
		setCriteria(initCriteriaState);
	}, [userCriteria.length, initialCriteria]);

	const mapCriteriaNameToParticipantCriteriaKey = (criteriaName: string): ParticipantDetailsCriteriaKey => {
		return criteriaName.toLocaleLowerCase().replace(/\s/, '') as ParticipantDetailsCriteriaKey;
	};

	const setSliceState = () => {
		const sliceState: CriteriaInformationState = {
			criteria: criteria,
		};

		dispatch(setCriteriaInformationState(sliceState));
	};

	const CriteriaInputs = (
		<>
			{userCriteria.map((userCriterion) => {
				const participantCriteriaKey = mapCriteriaNameToParticipantCriteriaKey(userCriterion.name);

				return (
					<Input
						key={userCriterion.id}
						maxLength={50}
						value={criteria[participantCriteriaKey] ?? ''}
						name={userCriterion.name}
						errorPlacement="right"
						formInput
						onChange={(event) => {
							setCriteria((prevState) => ({
								...prevState,
								[participantCriteriaKey]: event.target.value,
							}));
							setSliceState();
						}}
						onBlur={(event) => {
							setCriteria((prevState) => ({
								...prevState,
								[participantCriteriaKey]: event.target.value,
							}));
							setSliceState();
						}}
					/>
				);
			})}
		</>
	);

	return CriteriaInputs;
};
