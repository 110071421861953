import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { EmptyRoadmapContainer, ExclamationIcon, Message } from './styled';

export const EmptyRoadmap = () => {
	return (
		<EmptyRoadmapContainer>
			<ExclamationIcon icon={faCircleExclamation} />
			<Message>No Roadmap produced using the selected criteria / benchmarks</Message>
		</EmptyRoadmapContainer>
	);
};
