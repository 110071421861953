import { DispersionChartValues } from 'features/Graphs/DispersionChart';
import { DispersionChartBase } from 'features/Graphs/PptxExportButton';

export const useDispersionChartData = (
	graphBase: DispersionChartBase,
	clusterIndex: number,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = graphBase.pdna;
	const odna = graphBase.odna;

	const checkedPdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : pdnaBenchmarkIndexes;
	const checkedOdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : odnaBenchmarkIndexes;
	const benchmarksLength = checkedPdnaBenchmarks.length;

	const data: DispersionChartValues[] = [];

	for (let benchmarkIndex = 0; benchmarkIndex < benchmarksLength; benchmarkIndex++) {
		const currentPdnaBenchmark = pdna.clusters[clusterIndex].benchmarks.data[benchmarkIndex];
		const currentOdnaBenchmark = odna.clusters[clusterIndex].benchmarks.data[benchmarkIndex];

		const pdnaBenchmarkEnabled = checkedPdnaBenchmarks[benchmarkIndex];
		const odnaBenchmarkEnabled = checkedOdnaBenchmarks[benchmarkIndex];

		const pdnaValueExists = currentPdnaBenchmark && !!currentPdnaBenchmark.numberOfParticipants;
		const odnaValueExists = currentOdnaBenchmark && !!currentOdnaBenchmark.numberOfParticipants;

		if (pdnaBenchmarkEnabled && pdnaValueExists) {
			data.push(currentPdnaBenchmark);
		}

		if (odnaBenchmarkEnabled && odnaValueExists) {
			data.push(currentOdnaBenchmark);
		}
	}
	return { data: data };
};
