import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HttpResponseStatusCodes } from 'common/types';
import { resetStore, RootState } from 'store/store';
import { getXSRFCookie } from '.';

const baseQuery = fetchBaseQuery({
	baseUrl: '/',
	credentials: 'include',
	prepareHeaders: (headers, {}) => {
		const XSRFCookie = getXSRFCookie();

		if (XSRFCookie) {
			headers.set('X-Requested-With', 'XMLHttpRequest');
			headers.set('X-XSRF-TOKEN', XSRFCookie);
		}
		return headers;
	},

	validateStatus(response, _body) {
		const sessionExpired =
			response.status === HttpResponseStatusCodes.FORBIDDEN ||
			response.status === HttpResponseStatusCodes.UNAUTHORIZED;

		if (sessionExpired) {
			resetStore();
			const sessionExpiredEvent = new Event('sessionExpired');
			dispatchEvent(sessionExpiredEvent);

			return false;
		}

		return true;
	},
});

export const rootApi = createApi({
	baseQuery: baseQuery,
	reducerPath: 'rootApi',
	refetchOnMountOrArgChange: 60,
	tagTypes: [
		'Frameworks',
		'Framework',
		'Tenants',
		'TenantsAdmins',
		'Managers',
		'CriteriaConfiguration',
		'Projects',
		'Project',
		'AssessedParticipants',
		'AssessedParticipant',
		'ProjectSummary',
		'Auth',
		'Roadmaps',
		'Logo',
	],
	endpoints: () => ({}),
});

export const selectIsSomeQueryPending = (state: RootState) =>
	Object.values(state.rootApi.queries).some((query) => query && query.status === 'pending') ||
	Object.values(state.rootApi.mutations).some((query) => query && query.status === 'pending');
