import {
	CriteriaRepository,
	Criterion,
	CriterionId,
	Participant,
	ApiParticipant,
	ApiBenchmarkParticipant,
	BenchmarkParticipant,
	GroupAssignation,
	Pagination,
	ApiParticipantDetails,
	ParticipantDetails,
} from 'common/types';
import { ParticipantsResponse } from 'store/api';

export const transformParticipantsResponse = (
	response: ParticipantsResponse<ApiParticipant>,
	criteriaConfiguration: Criterion[],
): ParticipantsResponse<Participant> => {
	const apiParticipants = response.content;
	const pagination = createPagination(response);
	return {
		...pagination,
		content: apiParticipants.map((participant) => createParticipant(criteriaConfiguration, participant)),
	};
};

export const transformBenchmarkParticipantResponse = (
	response: ParticipantsResponse<ApiBenchmarkParticipant>,
	criteriaConfiguration: Criterion[],
): ParticipantsResponse<BenchmarkParticipant> => {
	const apiParticipants = response.content;
	const pagination = createPagination(response);

	return {
		...pagination,
		content: apiParticipants.map(createBenchmarkParticipant),
	};

	function createBenchmarkParticipant(data: ApiBenchmarkParticipant): BenchmarkParticipant {
		const participant = createParticipant(criteriaConfiguration, data);
		const assignation = createAssignation(data);
		return {
			...participant,
			...assignation,
		};
	}

	function createAssignation(data: ApiBenchmarkParticipant): GroupAssignation {
		return {
			assignedToGroup1: data.assignedToGroup1,
			assignedToGroup2: data.assignedToGroup2,
			assignedToGroup3: data.assignedToGroup3,
			assignedToGroup4: data.assignedToGroup4,
			assignedToGroup5: data.assignedToGroup5,
			assignedToGroup6: data.assignedToGroup6,
			assignedToGroup7: data.assignedToGroup7,
			assignedToGroup8: data.assignedToGroup8,
			assignedToGroup9: data.assignedToGroup9,
			assignedToGroup10: data.assignedToGroup10,
		};
	}
};

function createPagination<T>(response: ParticipantsResponse<T>): Pagination {
	return {
		pageNumber: response.pageNumber,
		totalPages: response.totalPages,
	};
}

function createParticipant(criteriaConfiguration: Criterion[], participant: ApiParticipant): Participant {
	const { c: _c, ...participantCopy } = participant;
	return {
		...participantCopy,
		criteria: createCriteria(criteriaConfiguration, participant.c),
	};
}

function createCriteria(criteriaConfiguration: Criterion[], criteriaMap: Record<string, string>) {
	const criteria: Criterion[] = criteriaConfiguration.map((criterionConfig) => {
		const id: CriterionId = criterionConfig.id;
		const decreasedByOne = 1;
		const criteriaValue = criteriaMap[Number.parseInt(id) - decreasedByOne];
		return { id, name: criteriaValue, enabled: criterionConfig.enabled };
	});

	return new CriteriaRepository(criteria);
}

export const mapGetParticipantDetailsResponse = (apiParticipantDetails: ApiParticipantDetails): ParticipantDetails => {
	const { id, ...apiParticipantDetailsCopy } = apiParticipantDetails;
	return {
		...apiParticipantDetailsCopy,
		participantId: id,
	};
};
