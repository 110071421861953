export const DefaultClusterId = {
	Cl00: 'CL00',
	Cl01: 'CL01',
	Cl02: 'CL02',
	Cl03: 'CL03',
	Cl04: 'CL04',
	Cl05: 'CL05',
	Cl06: 'CL06',
	Cl07: 'CL07',
	Cl08: 'CL08',
	Cl09: 'CL09',
	Cl10: 'CL10',
} as const;

export const DefaultClusterNames = {
	Cl00: 'CL-00',
	Cl01: 'CL-01',
	Cl02: 'CL-02',
	Cl03: 'CL-03',
	Cl04: 'CL-04',
	Cl05: 'CL-05',
	Cl06: 'CL-06',
	Cl07: 'CL-07',
	Cl08: 'CL-08',
	Cl09: 'CL-09',
	Cl10: 'CL-10',
} as const;
