export type BenchmarkColumn = string;

export const BenchmarkParticipantColumnIds = {
	Email: 'email',
	FirstName: 'firstName',
	LastName: 'lastName',
	Benchmark1: 'B1',
	Benchmark2: 'B2',
	Benchmark3: 'B3',
	Benchmark4: 'B4',
	Benchmark5: 'B5',
	Benchmark6: 'B6',
	Benchmark7: 'B7',
	Benchmark8: 'B8',
	Benchmark9: 'B9',
	Benchmark10: 'B10',
};
