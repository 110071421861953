import { NotificationPropsWithoutTimeout, Notification, NotificationPropsWithTimeout } from '.';
import { useNotifications } from './hooks/useNotifications';
import { StyledNotificationContainer } from './styled';

export const NotificationsContainer = () => {
	const { notifications } = useNotifications();

	return (
		<StyledNotificationContainer id="notification-container">
			{notifications.map((notification: NotificationPropsWithoutTimeout | NotificationPropsWithTimeout) => {
				return (
					<Notification
						key={notification.id}
						id={notification.id}
						message={notification.message}
						type={notification.type}
						disableAutoHide={notification.disableAutoHide}
						timeout={notification.timeout}
					/>
				);
			})}
		</StyledNotificationContainer>
	);
};
