import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccuracyChartDna, AccuracyChartState } from './accuracyChartSlice.types';
import { accuracyChartConfig, AccuracyChartConfig } from 'features/Graphs/AccuracyChart';
import { RootState } from 'store/store';

const emptyDna: AccuracyChartDna = {
	clusters: [
		{
			benchmarks: [
				{
					colors: {
						accuracy: '',
					},
					data: [],
					labels: {
						accuracy: '',
					},
				},
			],
		},
	],
};

const initialState: AccuracyChartState = {
	chartConfig: accuracyChartConfig,
	ODNA: emptyDna,
	PDNA: emptyDna,
	COMBINED_ODNA: emptyDna,
	COMBINED_PDNA: emptyDna,
};

export const accuracyChartSlice = createSlice({
	name: 'accuracyChart',
	initialState,
	reducers: {
		setAccuracyChartState: (state, { payload }: PayloadAction<AccuracyChartState>) => {
			state = payload;
		},
		setAccuracyChartConfig: (state, { payload }: PayloadAction<AccuracyChartConfig>) => {
			state.chartConfig = payload;
			return state;
		},
		setAccuracyChartODNA: (state, { payload }: PayloadAction<AccuracyChartDna>) => {
			state.ODNA = payload;
			return state;
		},
		setAccuracyChartCombinedODNA: (state, { payload }: PayloadAction<AccuracyChartDna>) => {
			state.COMBINED_ODNA = payload;
			return state;
		},
		setAccuracyChartPDNA: (state, { payload }: PayloadAction<AccuracyChartDna>) => {
			state.PDNA = payload;
			return state;
		},
		setAccuracyChartCombinedPDNA: (state, { payload }: PayloadAction<AccuracyChartDna>) => {
			state.COMBINED_PDNA = payload;
			return state;
		},
		resetAccuracyChartState: () => initialState,
	},
});

export const {
	setAccuracyChartState,
	setAccuracyChartConfig,
	setAccuracyChartODNA,
	setAccuracyChartCombinedODNA,
	setAccuracyChartPDNA,
	setAccuracyChartCombinedPDNA,
	resetAccuracyChartState,
} = accuracyChartSlice.actions;
export const accuracyChartReducer = accuracyChartSlice.reducer;
export const selectAccuracyChartODNA = (state: RootState) => state.accuracyChart.ODNA;
export const selectAccuracyChartPDNA = (state: RootState) => state.accuracyChart.PDNA;
export const selectAccuracyChartCombinedPDNA = (state: RootState) => state.accuracyChart.COMBINED_PDNA;
export const selectAccuracyChartCombinedODNA = (state: RootState) => state.accuracyChart.COMBINED_ODNA;
export const selectAccuracyChartConfig = (state: RootState) => state.accuracyChart.chartConfig;
