import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { StakeholderInformationState } from '.';

const initialState: StakeholderInformationState = {
	stakeholderName: '',
	stakeholderMail: '',
	stakeholderTelephone: '',
	stakeholderMobile: '',
	stakeholderIM: '',
};

export const stakeholderInformationSlice = createSlice({
	name: 'stakeholderInformation',
	initialState,
	reducers: {
		setStakeholderInformationState: (state, { payload }: PayloadAction<StakeholderInformationState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setStakeholderInformationState } = stakeholderInformationSlice.actions;

export const stakeholderInformationSliceReducer = stakeholderInformationSlice.reducer;

export const getStakeholderInformationState = (state: RootState) => {
	return state.createClientReducers.stakeholderInformation;
};
