import { CommonColorPresets } from 'features/Graphs/colorPresets';
import { PieChart } from '../../PieChart';
import { pieChartConfig } from '../../PieChartConfig';
import { OPdnaPieChartProps } from './OPdnaPieChart.types';
import { useOPdnaPieChartData } from 'features/Graphs/PieChart';
import { useSelector } from 'react-redux';
import {
	selectPieChartCombinedODNA,
	selectPieChartCombinedPDNA,
	selectPieChartODNA,
	selectPieChartPDNA,
} from 'store/slices';

export const OPdnaPieChart = ({
	containerId,
	clusterIndex,
	pdnaBenchmarkIndexes,
	odnaBenchmarkIndexes,
	pnoBenchmarkIndexes,
	colorPresetIndex,
}: OPdnaPieChartProps) => {
	const colorPreset = CommonColorPresets[colorPresetIndex];
	const config = pieChartConfig;

	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = combinedMode ? useSelector(selectPieChartCombinedPDNA) : useSelector(selectPieChartPDNA);
	const odna = combinedMode ? useSelector(selectPieChartCombinedODNA) : useSelector(selectPieChartODNA);
	const data = { pdna, odna };
	const pieData = useOPdnaPieChartData(
		data,
		clusterIndex,
		pdnaBenchmarkIndexes,
		odnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
	);
	return (
		<PieChart
			containerId={containerId}
			colorConfig={colorPreset}
			config={config}
			data={pieData}
		/>
	);
};
