import { Button } from 'common/components/Button';
import { Input } from 'common/components/Input';

import { InputContainer, InputLabel, StyledInput as $StyledInput } from 'common/components/Input/styled';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledInput = styled(Input)`
	width: 100%;

	${InputLabel} {
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.text.secondary};
		font-size: 1.5rem;
	}

	${InputContainer} {
		border: none;
	}

	${$StyledInput} {
		font-size: 1.5rem;
		color: ${({ theme }) => theme.colors.text.secondary};
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
		font-weight: 500;
		padding: 0;

		&:focus {
			color: ${({ theme, readOnly }) => !readOnly && theme.colors.primary.main};
		}
	}
`;

export const StyledButton = styled(Button)`
	font-size: 1.1rem;
	margin-left: auto;
	padding: 0.25rem 1.5rem;
`;
