import { ApiCluster, Cluster } from 'common/types';
import { AllClustersResponse, getAllClustersEmptyResponse, useGetAllClustersQuery } from 'store/api';
import { mapGetAllClustersResponse } from './mappers';

export const useClusters = () => {
	const allClusters = useGetAllClustersQuery();
	const emptyResponse = getAllClustersEmptyResponse();
	const apiClusters = getApiClustersData();

	function getApiClustersData(): AllClustersResponse<ApiCluster> {
		if (!allClusters.isSuccess) return emptyResponse;

		return allClusters.data;
	}

	function transform(apiClusters: AllClustersResponse<ApiCluster>): AllClustersResponse<Cluster> {
		return mapGetAllClustersResponse(apiClusters);
	}

	return { clusters: transform(apiClusters) };
};
