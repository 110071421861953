import { ApiConfigResultsResponse, ApiQuadrantResultsResponse, ApiResultResponse, CultureViewResults } from 'store/api';

export function mapCultureViewResults(
	common: ApiResultResponse | undefined,
	quadrant: ApiQuadrantResultsResponse | undefined,
	config: ApiConfigResultsResponse | undefined,
): CultureViewResults {
	if (!common || !quadrant || !config) throw new GraphDataNotFoundException();
	return {
		commonResults: common,
		quadrantResults: quadrant,
		configResults: config,
	};
}

class GraphDataNotFoundException extends Error {}
