import { useEffect, useState } from 'react';
import { GraphContainerWithTooltipsProps } from './GraphContainerWithTooltip.types';
import { StyledTooltip } from './styled';
import {
	TooltipContainer,
	TooltipDescription,
	TooltipTitle,
} from 'features/ClusterConfiguration/components/BehaviourPairTileButton/styled';
import { labels } from './constants';
import { appendTooltip, cleanupEvents } from './extendedLabelFunctionalities';

export const GraphContainerWithTooltips = ({
	containerId,
	data,
	buildGraph,
	dimensions,
}: GraphContainerWithTooltipsProps) => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const renderGraph = (svgContainer: HTMLElement) => {
		const svg = buildGraph();
		svg.dispose();
		const svgNode = svg.getSvgNode();

		svgContainer.appendChild(svgNode);

		if (dimensions) {
			appendTooltip(
				labels,
				containerId,
				{
					setDescription: setDescription,
					setIsTooltipOpen: setIsTooltipOpen,
					setTitle: setTitle,
				},
				dimensions,
			);
		}
	};

	const tooltipContent = (
		<TooltipContainer>
			<TooltipTitle>{title}</TooltipTitle>
			<TooltipDescription>{description}</TooltipDescription>
		</TooltipContainer>
	);

	const cleanOldGraph = (svgContainer: HTMLElement, child: ChildNode) => {
		svgContainer.removeChild(child);
	};

	useEffect(() => {
		const svgContainer = document.getElementById(containerId);
		if (svgContainer?.firstChild) {
			cleanOldGraph(svgContainer, svgContainer.firstChild);
		}
		if (svgContainer) {
			renderGraph(svgContainer);
		}

		return cleanupEvents(
			labels,
			containerId,
			{
				setDescription: setDescription,
				setIsTooltipOpen: setIsTooltipOpen,
				setTitle: setTitle,
			},
			dimensions,
		);
	}, [data]);

	return (
		<StyledTooltip
			anchorSelect="[class^='label-D']"
			isOpen={isTooltipOpen}
		>
			{tooltipContent}
		</StyledTooltip>
	);
};
