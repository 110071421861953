import { PaginationContainer, StyledButton } from './styled';

type TablePaginationProps = {
	id: string;
	canGoToPreviousPage: boolean;
	canGoToNextPage: boolean;
	currentPageIndex: number;
	totalPages: number;
	goToPreviousPage: () => void;
	goToNextPage: () => void;
	goToFirstPage: () => void;
	goToLastPage: () => void;
};

export const TablePagination = ({
	id,
	canGoToNextPage,
	canGoToPreviousPage,
	currentPageIndex,
	totalPages,
	goToFirstPage,
	goToLastPage,
	goToNextPage,
	goToPreviousPage,
}: TablePaginationProps) => {
	const firstPageIndex = 0;
	const one = 1;
	const isFirstPage = currentPageIndex === firstPageIndex;
	const isLastPage = currentPageIndex + one === totalPages;
	return (
		<PaginationContainer id={id}>
			<StyledButton
				variant="outlined"
				onClick={goToFirstPage}
				disabled={isFirstPage}
			>
				First
			</StyledButton>
			<StyledButton
				variant="outlined"
				onClick={goToPreviousPage}
				disabled={!canGoToPreviousPage}
			>{`<`}</StyledButton>
			<StyledButton
				readOnly
				variant="outlined"
			>
				Page {currentPageIndex + one} of {totalPages}
			</StyledButton>
			<StyledButton
				variant="outlined"
				onClick={goToNextPage}
				disabled={!canGoToNextPage}
			>{`>`}</StyledButton>
			<StyledButton
				variant="outlined"
				onClick={goToLastPage}
				disabled={isLastPage}
			>
				Last
			</StyledButton>
		</PaginationContainer>
	);
};
