import { SelectionPanelButtonProps } from './SelectionPanelButton.types';
import { StyledButton } from './styled';

export const SelectionPanelButton = ({
	faded,
	disabled,
	onClick,
	isSelected,
	children,
	customButtonVariants,
}: SelectionPanelButtonProps) => {
	return (
		<StyledButton
			faded={faded}
			disabled={disabled}
			underlineOnHover
			onClick={onClick}
			variant={
				isSelected
					? customButtonVariants?.selected || 'filled'
					: customButtonVariants?.notSelected || 'outlined'
			}
		>
			{children}
		</StyledButton>
	);
};
