import { TensionGraphSVGFactory, TensionGraphRequest } from 'features/Graphs/TensionGraph';
import {
	TensionChartClusterExportService,
	TensionGraphClusterExport,
} from './useTensionGraphClusterExportService.types';
import { useTensionGraphDownload } from 'features/Graphs/TensionGraph/components/TensionGraphExportButton/useTensionGraphDownload';
import { downloadFile } from 'common/utils';
import { format } from 'date-fns';
import { useCreateExport } from './useCreateExport';
import { useClusterRepository } from './useClusterRepository';
import { DateFormatPatterns } from 'common/constants';

export const useTensionGraphClusterExportService = (
	request: TensionGraphRequest,
	tensionGraphSVGFactory: TensionGraphSVGFactory,
): TensionChartClusterExportService => {
	const generateSlides = useTensionGraphDownload();
	const createExport = useCreateExport(request, tensionGraphSVGFactory);
	const { clusters, findCluster } = useClusterRepository();

	return {
		downloadCurrentClusterExport: downloadCurrent,
		downloadAllClustersExport: downloadAll,
	};

	function downloadCurrent() {
		const cluster = findCluster(request.clusterId);
		const data = [createExport(cluster)];
		const filename = createExportFilename(cluster.name);
		downloadPptx(filename, data);
	}

	function downloadAll() {
		const data = createExportAll();
		const filename = createExportFilename('All');
		downloadPptx(filename, data);
	}

	function createExportAll(): TensionGraphClusterExport[] {
		return clusters.map((cluster) => {
			return createExport(cluster);
		});
	}

	function createExportFilename(name: string) {
		const currentDate = format(new Date(), DateFormatPatterns.Export);
		return `Tension_chart_${currentDate}_${request.benchmarkAlias}_${name}`;
	}

	function downloadPptx(filename: string, data: TensionGraphClusterExport[]) {
		generateSlides(filename, data)
			.unwrap()
			.then((response) => {
				downloadFile(response.objectUrl, response.filename);
			});
	}
};
