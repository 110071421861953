import { Button } from 'common/components/Button';
import { AnimatedBackground } from 'features/AuthManagement/views';
import { useNavigate, useRouteError } from 'react-router-dom';
import { StyledDiv } from './styled';

export const GlobalErrorBoundary = () => {
	const error = useRouteError();
	const navigate = useNavigate();

	console.error(error);

	return (
		<>
			<AnimatedBackground />
			<StyledDiv>
				<h1>Something went wrong.</h1>
				<p>Please either refresh the page or return home to try again.</p>
				<p>If the issue continues, please get in touch</p>
				<Button
					color="primary"
					onClick={() => {
						navigate('/');
					}}
				>
					Home
				</Button>
			</StyledDiv>
		</>
	);
};
