import styled from 'styled-components';
import { Button } from 'common/components/Button';

export const StyledButton = styled(Button)`
	padding-left: 2em;
	padding-right: 2em;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	margin-right: 2em;
	height: fit-content;
	font-size: 1.3rem;
`;
