import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { ProtectedRoutes } from 'common/components';
import {
	PasswordChange,
	LoginPage,
	NotFound,
	PredictiveViewPage,
	CultureView,
	ProjectsPage,
	ClusterConfigurationDetails,
	ClusterConfiguration,
	CreateTenants,
	ClientConfiguration,
	ClientAdministrators,
	ClientBranding,
	CriteriaConfiguration,
	EditTenant,
	CreateTenantsAdmin,
	BillingReport,
	ClientUploadDimensionTooltips,
	DatabaseExport,
	CreateManager,
	MyOrganisation,
	ProjectActions,
	CreateProject,
	EditProject,
	ParticipantSelection,
	AdditionalQuestions,
	AssessedParticipants,
	ImportParticipants,
	CreateParticipant,
	EditParticipant,
	Root,
} from './pages';
import { UserRoles } from 'common/types';
import { UnderConstructionPage } from 'common/components/UnderConstructionPage/UnderConstructionPage';
import { RedirectHub } from './pages/RedirectHub';
import { ApdManagersView } from './pages/ApdManagers';
import { DiscoveryInsights } from 'router/pages/DiscoveryInsights/DiscoveryInsights';
import { BehaviourFocus } from './pages/BehaviourFocus/BehaviourFocus';
import { RoadMap } from './pages/RoadMap';
import { RoadMapOverview } from 'features/RoadMap/views/RoadMapOverview';
import { StandaloneRoadmapView } from 'features/RoadMap/views/StandaloneRoadMapView/StandaloneRoadMapView';
import { GlobalErrorBoundary } from 'common/components/ErrorBoundaries/GlobalErrorBoundary';

export const router = createHashRouter(
	createRoutesFromElements(
		<>
			<Route
				path="login"
				element={<LoginPage />}
			/>
			<Route
				path="*"
				element={<NotFound />}
			/>
			<Route
				path="/"
				element={<ProtectedRoutes page={<Root />} />}
				errorElement={<GlobalErrorBoundary />}
			>
				<Route
					index
					element={<RedirectHub />}
				/>
				<Route
					path="passwordChange"
					element={<PasswordChange />}
				/>
				<Route path="apdManagers">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.SYSTEM_MANAGER}
								page={<ApdManagersView />}
							/>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.SYSTEM_MANAGER}
								page={<CreateManager />}
							/>
						}
					/>
				</Route>
				<Route path="assessees">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<AssessedParticipants />}
							/>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<CreateParticipant />}
							/>
						}
					/>
					<Route
						path=":assesseeId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<EditParticipant />}
							/>
						}
					/>
				</Route>
				<Route
					path="imports/participants"
					element={
						<ProtectedRoutes
							requiredRole={UserRoles.ADMIN}
							page={<ImportParticipants />}
						/>
					}
				/>
				<Route
					path="mytenant"
					element={
						<ProtectedRoutes
							requiredRole={UserRoles.ADMIN}
							page={<MyOrganisation />}
						/>
					}
				/>
				<Route
					path="billingReport"
					element={
						<ProtectedRoutes
							requiredRole={UserRoles.MANAGER}
							page={<BillingReport />}
						/>
					}
				/>
				<Route path="tenants">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.MANAGER}
								page={<ClientConfiguration />}
							/>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.MANAGER}
								page={<CreateTenants />}
							/>
						}
					/>
					<Route path=":tenantId">
						<Route
							index
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.MANAGER}
									page={<EditTenant />}
								/>
							}
						/>
						<Route
							path="branding"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.MANAGER}
									page={<ClientBranding />}
								/>
							}
						/>
						<Route
							path="criteriaConfiguration"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.MANAGER}
									page={<CriteriaConfiguration />}
								/>
							}
						/>
						<Route
							path="dimensionLegends"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.MANAGER}
									page={<ClientUploadDimensionTooltips />}
								/>
							}
						/>
						<Route
							path="reportTemplates"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.MANAGER}
									page={<UnderConstructionPage title="Report Templates" />}
								/>
							}
						/>
					</Route>
				</Route>
				<Route path="clusterConfiguration">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={
									<Navigate
										to="overview"
										replace
									/>
								}
							/>
						}
					/>
					<Route
						path="overview"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<ClusterConfiguration />}
							/>
						}
					/>
					<Route
						path=":frameworkId/details"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<ClusterConfigurationDetails />}
							/>
						}
					/>
				</Route>
				<Route
					path="databaseExport"
					element={
						<ProtectedRoutes
							requiredRole={UserRoles.MANAGER}
							page={<DatabaseExport />}
						/>
					}
				/>
				<Route path="tadmins">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.MANAGER}
								page={<ClientAdministrators />}
							/>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.MANAGER}
								page={<CreateTenantsAdmin />}
							/>
						}
					/>
				</Route>
				<Route path="discovery-insights">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<DiscoveryInsights key={'discover-insights'} />}
							/>
						}
					/>
					<Route
						path=":projectId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<DiscoveryInsights key={'discover-insights/:projectId'} />}
							/>
						}
					/>
				</Route>
				<Route path="predictive-view">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<PredictiveViewPage key={'predictive-view'} />}
							/>
						}
					/>
					<Route
						path=":projectId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<PredictiveViewPage key={'predictive-view/:projectId'} />}
							/>
						}
					/>
				</Route>
				<Route path="projects">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<ProjectsPage />}
							/>
						}
					/>
					<Route
						path="create"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<CreateProject />}
							/>
						}
					/>
					<Route path=":projectId">
						<Route
							index
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.ADMIN}
									page={<EditProject />}
								/>
							}
						/>
						<Route
							path="management"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.ADMIN}
									page={<ParticipantSelection />}
								/>
							}
						/>
						<Route
							path="actions"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.ADMIN}
									page={<ProjectActions />}
								/>
							}
						/>
						<Route
							path="projectQuestions"
							element={
								<ProtectedRoutes
									requiredRole={UserRoles.ADMIN}
									page={<AdditionalQuestions />}
								/>
							}
						/>
					</Route>
				</Route>
				<Route path="culture-view">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<CultureView key={'culture-view'} />}
							/>
						}
					/>
					<Route
						path=":projectId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<CultureView key={'culture-view/:projectId'} />}
							/>
						}
					/>
				</Route>
				<Route path="behaviour-focus">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<BehaviourFocus key={'behaviour-focus'} />}
							/>
						}
					/>
					<Route
						path=":projectId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<BehaviourFocus key={'behaviour-focus/:projectId'} />}
							/>
						}
					/>
				</Route>
				<Route path="road-map">
					<Route
						index
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<RoadMap />}
							/>
						}
					/>
					<Route
						path="overview"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<RoadMapOverview />}
							/>
						}
					/>
					<Route
						path=":projectId/overview"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<RoadMapOverview />}
							/>
						}
					/>
					<Route
						path=":projectId"
						element={
							<ProtectedRoutes
								requiredRole={UserRoles.ADMIN}
								page={<RoadMap />}
							/>
						}
					/>
					<Route
						path=":roadmapId/view"
						element={<StandaloneRoadmapView />}
					/>
					<Route
						path=":projectId/:roadmapId/view"
						element={<StandaloneRoadmapView />}
					/>
				</Route>
			</Route>
			<Route
				path="*"
				element={<NotFound />}
			/>
		</>,
	),
);
