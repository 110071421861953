import { RootState } from 'store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PieChartDna, PieChartState } from './pieChartSlice.types';
import { pieChartConfig } from 'features/Graphs/PieChart/PieChartConfig';
import { PieChartConfig } from 'features/Graphs/PieChart/PieChart.types';

const emptyDna: PieChartDna = {
	clusters: [
		{
			benchmarks: [{ color: '', label: '', value: 0 }],
		},
	],
};

const initialState: PieChartState = {
	chartConfig: pieChartConfig,
	ODNA: emptyDna,
	COMBINED_ODNA: emptyDna,
	PDNA: emptyDna,
	COMBINED_PDNA: emptyDna,
};

export const pieChartSlice = createSlice({
	name: 'pieChart',
	initialState,
	reducers: {
		setPieChartState: (state, { payload }: PayloadAction<PieChartState>) => {
			state = payload;
			return state;
		},
		setPieChartConfig: (state, { payload }: PayloadAction<PieChartConfig>) => {
			state.chartConfig = payload;
			return state;
		},
		setPieChartODNA: (state, { payload }: PayloadAction<PieChartDna>) => {
			state.ODNA = payload;
			return state;
		},
		setPieChartCombinedODNA: (state, { payload }: PayloadAction<PieChartDna>) => {
			state.COMBINED_ODNA = payload;
			return state;
		},
		setPieChartPDNA: (state, { payload }: PayloadAction<PieChartDna>) => {
			state.PDNA = payload;
			return state;
		},
		setPieChartCombinedPDNA: (state, { payload }: PayloadAction<PieChartDna>) => {
			state.COMBINED_PDNA = payload;
			return state;
		},
		resetPieChartState: () => initialState,
	},
});

export const {
	setPieChartState,
	resetPieChartState,
	setPieChartConfig,
	setPieChartODNA,
	setPieChartCombinedODNA,
	setPieChartPDNA,
	setPieChartCombinedPDNA,
} = pieChartSlice.actions;
export const pieChartReducer = pieChartSlice.reducer;
export const selectPieChartODNA = (state: RootState) => state.pieChart.ODNA;
export const selectPieChartPDNA = (state: RootState) => state.pieChart.PDNA;
export const selectPieChartCombinedPDNA = (state: RootState) => state.pieChart.COMBINED_PDNA;
export const selectPieChartCombinedODNA = (state: RootState) => state.pieChart.COMBINED_ODNA;
export const selectPieChartConfig = (state: RootState) => state.pieChart.chartConfig;
