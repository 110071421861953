import { rootApi } from '../root';
import { CriteriaConfigurationResponse, CriterionSetting } from './criteriaApi.types';

export const criteriaApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getCriteriaConfiguration: builder.query<CriteriaConfigurationResponse, { tenantId: string }>({
			query: (opts) => ({
				url: `api/tenants/${opts.tenantId}/criteriaconfiguration`,
			}),
			providesTags: ['CriteriaConfiguration'],
		}),
		updateCriteriaConfiguration: builder.mutation<unknown, { tenantId: string; criteria: CriterionSetting[] }>({
			query: (opts) => ({
				method: 'PUT',
				url: `api/tenants/${opts.tenantId}/criteriaconfiguration`,
				body: { criteria: opts.criteria },
			}),
			invalidatesTags: ['CriteriaConfiguration'],
		}),
	}),
});

export const { useGetCriteriaConfigurationQuery, useUpdateCriteriaConfigurationMutation } = criteriaApi;
