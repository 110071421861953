import { AccuracyChartDna } from 'store/slices';
import {
	AccuracyChartData,
	AccuracyChartDataValues,
	defaultAccuracyChartBarColor,
	defaultAccuracyChartLabel,
} from '../..';

export const prepareAccuracyData = (
	dna: AccuracyChartDna,
	data: AccuracyChartDataValues[],
	atLeastOneBenchmarkChecked: boolean,
	clusterIndex: number,
) => {
	const defaultIndex = 0;

	const accuracyData: AccuracyChartData = {
		colors: atLeastOneBenchmarkChecked
			? dna.clusters[clusterIndex].benchmarks[defaultIndex].colors
			: { accuracy: defaultAccuracyChartBarColor },
		data: data,
		labels: atLeastOneBenchmarkChecked
			? dna.clusters[clusterIndex].benchmarks[defaultIndex].labels
			: { accuracy: defaultAccuracyChartLabel },
	};

	return accuracyData;
};

export const prepareSingleDnaGraphData = (dna: AccuracyChartDna, benchmarkIndexes: boolean[], clusterIndex: number) => {
	const defaultIndex = 0;

	const data: AccuracyChartDataValues[] = [];

	benchmarkIndexes.forEach((benchmarkEnabled, index) => {
		const benchmarkExists = dna.clusters[clusterIndex].benchmarks[index];
		const benchmarkContainsData =
			dna.clusters[clusterIndex].benchmarks[index].data[defaultIndex].series[defaultIndex].value;
		if (benchmarkEnabled && benchmarkExists && benchmarkContainsData) {
			data.push(...dna.clusters[clusterIndex].benchmarks[index].data);
		}
	});

	const atLeastOneBenchmarkChecked = benchmarkIndexes.includes(true);

	return prepareAccuracyData(dna, data, atLeastOneBenchmarkChecked, clusterIndex);
};
