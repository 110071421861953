import {
	Container,
	StyledCheckbox,
	DashedLine,
	Title,
	SquareIcon,
	SolidLineIcon,
	LinePickerButton,
	ColorPickerButton,
	TitleContainer,
} from './styled';
import { faMinus, faSquare } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import { ChartLineSettingsItemProps } from './ChartLineSettingsItem.types';
import { ChartColor, GraphLineType } from 'common/types';
import { GraphLineTypes } from 'common/constants';
import { Popper } from 'common/components';
import { useComponentVisible } from 'common/hooks';
import { ColorPicker } from '../ColorPicker';

const DashedLineIcon = React.memo(() => (
	<DashedLine>
		<SquareIcon icon={faSquare} />
		<SquareIcon icon={faSquare} />
		<SquareIcon icon={faSquare} />
		<SquareIcon icon={faSquare} />
	</DashedLine>
));
DashedLineIcon.displayName = 'DashedLineIcon';

const notFoundIndex = -1;
const nextLineTypeIndexAddend = 1;
const firstLineTypeIndex = 0;

export const ChartLineSettingsItem = ({
	selected,
	children,
	colorLinePickerDisabled,
	initialState,
	onChange,
}: ChartLineSettingsItemProps) => {
	const [selectedLineType, setSelectedLineType] = useState<GraphLineType>(initialState.lineType);
	const [selectedColor, setSelectedColor] = useState<ChartColor>(initialState.lineColor);

	const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
	const {
		isComponentVisible: isColorPickerVisible,
		ref: colorPickerRef,
		setIsComponentVisible: setIsColorPickerVisible,
	} = useComponentVisible<HTMLDivElement>({ initialIsVisible: false, ignoredEl: colorPickerButtonRef });

	const lineTypes: GraphLineType[] = Object.values(GraphLineTypes);

	const onSelectItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();

		onChange({
			selected: !selected,
			lineType: selectedLineType,
			lineColor: selectedColor,
		});
	};

	const onLinePickerButtonClick = () => {
		const foundIndex = lineTypes.findIndex((lineType) => lineType === selectedLineType);
		if (foundIndex === notFoundIndex) {
			return;
		}

		const nextLineTypeIndex = foundIndex + nextLineTypeIndexAddend;
		if (nextLineTypeIndex === lineTypes.length) {
			setSelectedLineType(lineTypes[firstLineTypeIndex]);
			onChange({
				selected: selected,
				lineType: lineTypes[firstLineTypeIndex],
				lineColor: selectedColor,
			});
			return;
		}

		setSelectedLineType(lineTypes[nextLineTypeIndex]);
		onChange({
			selected: selected,
			lineType: lineTypes[nextLineTypeIndex],
			lineColor: selectedColor,
		});
		return;
	};

	const onColorPickerButtonClick = () => {
		setIsColorPickerVisible((prevIsColorPickerVisible) => !prevIsColorPickerVisible);
	};

	const onColorPaletteButtonClick = (color: ChartColor) => {
		setSelectedColor(color);
		setIsColorPickerVisible(false);
		onChange({
			selected: selected,
			lineType: selectedLineType,
			lineColor: color,
		});
	};

	return (
		<Container>
			<TitleContainer onClick={onSelectItemClick}>
				<StyledCheckbox
					checked={selected}
					readOnly
				/>
				<Title>{children}</Title>
			</TitleContainer>

			{!colorLinePickerDisabled && (
				<>
					<LinePickerButton
						onClick={onLinePickerButtonClick}
						$color={`#${selectedColor}`}
					>
						{selectedLineType === GraphLineTypes.Dashed && <DashedLineIcon />}
						{selectedLineType === GraphLineTypes.Solid && <SolidLineIcon icon={faMinus} />}
					</LinePickerButton>
					<ColorPickerButton
						ref={colorPickerButtonRef}
						$color={`#${selectedColor}`}
						onClick={onColorPickerButtonClick}
					/>
					<Popper
						isVisible={isColorPickerVisible}
						ref={colorPickerRef}
						anchorRef={colorPickerButtonRef}
					>
						<ColorPicker
							onSelect={onColorPaletteButtonClick}
							selected={selectedColor}
						/>
					</Popper>
				</>
			)}
		</Container>
	);
};
