import React, { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { TableAction, TenantAdmin } from 'common/types';
import { Tooltip } from 'common/components';
import { faLock, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { TenantsAdminsColumnId } from './TenantsAdminsColumnId';
import { TableIconButton, TableWrapper } from 'features/Tables/components';

export const useTenantsAdminsData = (openDeleteConfirmDialog: () => void, openUnlockConfirmDialog: () => void) => {
	const [tenantAdminToDelete, setTenantAdminToDelete] = useState<TenantAdmin>();
	const [tenantAdminToUnlock, setTenantAdminToUnlock] = useState<TenantAdmin>();
	const onDeleteTenantAdminClick = (tenantAdmin: TenantAdmin) => {
		setTenantAdminToDelete(tenantAdmin);
		openDeleteConfirmDialog();
	};

	const onUnlockTenantAdminClick = (tenantAdmin: TenantAdmin) => {
		setTenantAdminToUnlock(tenantAdmin);
		openUnlockConfirmDialog();
	};

	const actions = React.useMemo<TableAction<TenantAdmin>[]>(
		() => [
			{
				label: 'Delete Client Admin',
				icon: faTrashCan,
				onClick: (tenantAdmin) => (tenantAdmin ? onDeleteTenantAdminClick(tenantAdmin) : undefined),
			},
			{
				label: 'Unlock Client Admin',
				icon: faLock,
				onClick: (tenantAdmin) => (tenantAdmin ? onUnlockTenantAdminClick(tenantAdmin) : undefined),
				isHidden: (tenantAdmin) => !tenantAdmin.accountLocked,
			},
		],
		[],
	);

	const columns = React.useMemo<ColumnDef<TenantAdmin>[]>(
		() => [
			{
				id: TenantsAdminsColumnId.Username,
				header: 'Email',
				accessorKey: 'username',
			},
			{
				id: TenantsAdminsColumnId.Name,
				header: 'Name Of Client Admin',
				accessorKey: 'name',
				accessorFn: (row: TenantAdmin) => {
					return row.name;
				},
			},
			{
				id: TenantsAdminsColumnId.TenantShortId,
				header: 'Client Name',
				accessorKey: 'tenantShortId',
				accessorFn: (row: TenantAdmin) => {
					return row.tenantShortId;
				},
			},
			{
				id: TenantsAdminsColumnId.Actions,
				header: 'Actions',
				accessorFn: (row) => row,
				cell: ({ getValue }) => {
					return (
						<TableWrapper>
							{actions.map((action) => {
								const tenantAdmin = getValue<TenantAdmin>();
								if (action.isHidden?.(tenantAdmin)) return;
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											onClick={() => (action.onClick ? action.onClick(tenantAdmin) : undefined)}
											href={action.getHref ? action.getHref(tenantAdmin) : undefined}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
		],
		[],
	);

	return { columns, tenantAdminToDelete, tenantAdminToUnlock };
};
