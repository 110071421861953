import { ApiAllTranslationsResponse } from '../graphApi.types';

export const GraphEnglishTranslations: ApiAllTranslationsResponse = {
	translations: {
		D11B: 'Nonconformity',
		D01B: 'Individual',
		D11A: 'Conformity',
		D13B: 'Status',
		D01A: 'Collective',
		D03B: 'Delivery Focus',
		D13A: 'Achievement',
		D15B: 'Outer Focus',
		D03A: 'People Focus',
		D05B: 'Flexible',
		D15A: 'Internal Focus',
		D05A: 'Standardised',
		D07B: 'Self Focus',
		D07A: 'Team Focus ',
		D09B: 'Passive Learning ',
		D09A: 'Active Learning ',
		GTC002: 'Organisation Culture / Benchmark Comparison',
		RL004: 'Accuracy',
		GTI001: 'Personal Culture Graph',
		GTC001: 'Personal Culture / Benchmark Comparison',
		RL002: 'Accuracy',
		GTIC001: 'Personal vs Organisation Culture Graph',
		GTI002: 'Organisation Culture Graph',
		D10B: 'Consolidate',
		D10A: 'Innovate',
		D12B: 'Relationship',
		D02B: 'Empower',
		D12A: 'Transact',
		D14B: 'Synchronous',
		D02A: 'Disenfranchise',
		D04B: 'Expressive',
		D14A: 'Sequential ',
		D04A: 'Neutral',
		D06B: 'Radical',
		D06A: 'Moderate',
		D08B: 'Strategic',
		D08A: 'Tactical',
		RGT001: 'Response Quality in %',
		RGT002: 'Response Quality in %',
		GTIB002: 'Organisation Culture vs Benchmark',
		GTIB001: 'Personal Culture vs Benchmark',
	},
};
