import { useClusterRepository } from 'features/Graphs/TensionGraph/hooks/export/useClusterRepository';
import { DefaultClusterId } from 'common/constants';
import { useSvgGraphsFactory } from 'features/Graphs/PptxExportButton/hooks/useSvgGraphsFactory';
import { QuadrantCenter } from '@support/alderbrooke-d3-graphs/dist/Quadrant/QuadrantCenter';
import { ClusterId } from 'common/types';
import { GraphsExport, SvgGraphs, useExportGraphsMutation } from 'store/api/graphsExportApi';
import { downloadFile } from 'common/utils';
import { GraphBase } from 'features/Graphs/PptxExportButton';

export const usePptxExportService = (
	projectShortId: string | undefined,
	graphBase: GraphBase,
	combinedMode: boolean,
	includeClusters: boolean,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
	colorPresetId: number,
	quadrantCenter: QuadrantCenter,
) => {
	const clusterRepo = useClusterRepository();
	const [exportGraphs] = useExportGraphsMutation();
	return () => downloadGraphs();

	function downloadGraphs() {
		const graphs = createExport();
		exportGraphs(graphs)
			.unwrap()
			.then((file) => {
				downloadFile(file.objectUrl, file.filename);
			});
	}

	function createExport(): GraphsExport {
		const clusterAll = createSvgGraphForExistingCluster(DefaultClusterId.Cl00);
		if (!clusterAll) {
			throw Error();
		}

		const timestampConversion = 1000;
		const timestamp = Date.now() / timestampConversion;
		if (!includeClusters) {
			return {
				timestamp,
				projectShortId,
				CL00: clusterAll,
			};
		}

		return {
			timestamp,
			projectShortId,
			CL00: clusterAll,
			CL01: createSvgGraph(DefaultClusterId.Cl01),
			CL02: createSvgGraph(DefaultClusterId.Cl02),
			CL03: createSvgGraph(DefaultClusterId.Cl03),
			CL04: createSvgGraph(DefaultClusterId.Cl04),
			CL05: createSvgGraph(DefaultClusterId.Cl05),
			CL06: createSvgGraph(DefaultClusterId.Cl06),
			CL07: createSvgGraph(DefaultClusterId.Cl07),
			CL08: createSvgGraph(DefaultClusterId.Cl08),
			CL09: createSvgGraph(DefaultClusterId.Cl09),
			CL10: createSvgGraph(DefaultClusterId.Cl10),
		};
	}

	function createSvgGraph(clusterId: ClusterId): SvgGraphs | undefined {
		try {
			return createSvgGraphForExistingCluster(clusterId);
		} catch (e) {
			return undefined;
		}
	}

	function createSvgGraphForExistingCluster(clusterId: ClusterId): SvgGraphs {
		const clusterIndex = clusterRepo.findIndexOf(clusterId);

		return useSvgGraphsFactory(
			graphBase,
			combinedMode,
			clusterIndex,
			pdnaBenchmarkIndexes,
			odnaBenchmarkIndexes,
			pnoBenchmarkIndexes,
			colorPresetId,
			quadrantCenter,
		);
	}
};
