import { rootApi } from 'store/api';
import { ChangePasswordRequest, ChangePasswordResponse } from '.';

export const passwordChangeApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
			query: (data: ChangePasswordRequest) => ({
				url: 'api/account/password',
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const { useChangePasswordMutation } = passwordChangeApi;
