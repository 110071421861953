import styled from 'styled-components';
import { Button } from 'common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DownloadButton = styled(Button)`
	color: ${({ theme }) => theme.colors.text.primary};
	font-size: 1rem;
`;

export const DownloadIcon = styled(FontAwesomeIcon)`
	font-size: 1.75rem;
	margin-right: 2rem;
`;
