import { NotificationTypes, useNotifications } from 'common/components';
import { getUserHomeRoute } from 'common/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseManually } from 'common/hooks';

export const RedirectHub = () => {
	const userHomeRoute = getUserHomeRoute();
	const navigate = useNavigate();
	const { pushNotification } = useNotifications();
	const suspense = useSuspenseManually();

	const setupCommonListeners = () => {
		addEventListener('sessionExpired', () => {
			pushNotification({
				message: 'Your session expired.',
				type: NotificationTypes.Error,
			});
			suspense.turnOff();
		});
	};

	useEffect(() => {
		setupCommonListeners();
		navigate(userHomeRoute);
	});

	return <></>;
};
