import { DateFilterPickerRange, RegexPatterns } from 'common/constants';
import { BillingReportSlice } from 'store/slices';
import { getUTCDateFromString, parseDateString } from 'common/utils';

export const useBillingReportFormDataValidation = () => {
	const validateForm = async (data: BillingReportSlice) => {
		return [
			shouldBeProperDate(data.startDate),
			shouldBeProperDate(data.endDate),
			shouldDatesBeInProperOrder(data.startDate, data.endDate),
		].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function shouldBeProperDate(data: string) {
		if (!required(data)) return false;

		const normalizedDateString = data.replace(RegexPatterns.AllWhitespaceChars, '');
		const filterDate = parseDateString(normalizedDateString);
		if (!filterDate) return false;

		if (filterDate < DateFilterPickerRange.MinDate) return false;

		if (filterDate > DateFilterPickerRange.MaxDate) return false;

		return true;
	}

	function shouldDatesBeInProperOrder(startDate: string, endDate: string) {
		const utcStartDate = getUTCDateFromString(startDate);
		const utcEndDate = getUTCDateFromString(endDate);

		if (!utcStartDate || !utcEndDate) {
			return false;
		}

		if (utcStartDate >= utcEndDate) {
			return false;
		}

		return true;
	}

	function required(data: string) {
		return data != null && !!data.length;
	}
};
