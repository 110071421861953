import { ApiTenantProject, TenantProject } from 'common/types';
import { AllProjectsResponse, useGetAllClientProjectsQuery } from 'store/api';
import { getAllTenantsProjectsEmptyResponse } from 'store/api';
import { mapGetAllClientProjectsResponse } from './mappers';

export const useClientProjects = () => {
	const allClientProjects = useGetAllClientProjectsQuery();
	const emptyResponse = getAllTenantsProjectsEmptyResponse();
	const projects = getProjectsData();

	function getProjectsData(): AllProjectsResponse<ApiTenantProject> {
		if (!allClientProjects.isSuccess) return emptyResponse;

		return allClientProjects.data;
	}

	function transform(projects: AllProjectsResponse<ApiTenantProject>): AllProjectsResponse<TenantProject> {
		return mapGetAllClientProjectsResponse(projects);
	}

	return { projects: transform(projects).projects, areProjectsFetched: allClientProjects.isSuccess };
};
