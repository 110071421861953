import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ChartSettingsSlice } from './chartSettingsSlice.types';

const defaultPreset = 0;

const initialState: ChartSettingsSlice = {
	isOpened: false,
	showDataOnOneGraph: false,
	includeClusters: true,
	preset: defaultPreset,
};

export const chartSettingsSlice = createSlice({
	name: 'chartSettings',
	initialState,
	reducers: {
		toggleChartSettingsView: (state, { payload }: PayloadAction<boolean>) => {
			state.isOpened = payload;
			return state;
		},

		setChartSettingsShowOneGraph: (state, { payload }: PayloadAction<boolean>) => {
			state.showDataOnOneGraph = payload;
			return state;
		},

		setChartSettingsIncludeClusters: (state, { payload }: PayloadAction<boolean>) => {
			state.includeClusters = payload;
			return state;
		},

		setChartSettingsPreset: (state, { payload }: PayloadAction<number>) => {
			state.preset = payload;
			return state;
		},

		resetChartSettingsState: () => initialState,
	},
});

export const {
	toggleChartSettingsView,
	setChartSettingsShowOneGraph,
	setChartSettingsIncludeClusters,
	setChartSettingsPreset,
	resetChartSettingsState,
} = chartSettingsSlice.actions;

export const selectChartSettings = (state: RootState) => state.chartSettings;
export const selectIsChartSettingsViewOpened = (state: RootState) => state.chartSettings.isOpened;
export const selectChartSettingsShowDataOnOneGraph = (state: RootState) => state.chartSettings.showDataOnOneGraph;
export const selectChartSettingsIncludeClusters = (state: RootState) => state.chartSettings.includeClusters;
export const selectChartSettingsPreset = (state: RootState) => state.chartSettings.preset;
