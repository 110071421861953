import { charts } from '@support/alderbrooke-d3-graphs';
import { QuadrantCenter } from '@support/alderbrooke-d3-graphs/dist/Quadrant/QuadrantCenter';
import { IColorConfig } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IColorConfig';
import { QuadrantChartConfig, QuadrantChartData } from 'features/Graphs/QuadrantGraph';

export const useQuadrantGraphCreator = (
	config: QuadrantChartConfig,
	center: QuadrantCenter,
	data: QuadrantChartData,
	dotColorConfig: IColorConfig,
) => {
	return () => {
		const graph = new charts.Quadrant.Builder(config, center, data, dotColorConfig);
		return graph.build();
	};
};
