import { accuracyChartConfig } from '../../AccuracyChartConfig';
import { OPdnaAccuracyChartProps } from './OPdnaAccuracyChart.types';
import { AccuracyChart } from '../../';
import { useOPdnaAccuracyChartData } from 'features/Graphs/AccuracyChart/hooks/useOPdnaAccuracyChartData';
import { useSelector } from 'react-redux';
import {
	selectAccuracyChartCombinedODNA,
	selectAccuracyChartCombinedPDNA,
	selectAccuracyChartODNA,
	selectAccuracyChartPDNA,
} from 'store/slices';

export const OPdnaAccuracyChart = ({
	containerId,
	clusterIndex,
	odnaBenchmarkIndexes,
	pdnaBenchmarkIndexes,
	pnoBenchmarkIndexes,
}: OPdnaAccuracyChartProps) => {
	const config = accuracyChartConfig;
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = combinedMode ? useSelector(selectAccuracyChartPDNA) : useSelector(selectAccuracyChartCombinedPDNA);
	const odna = combinedMode ? useSelector(selectAccuracyChartODNA) : useSelector(selectAccuracyChartCombinedODNA);
	const base = { pdna, odna };

	const accuracyData = useOPdnaAccuracyChartData(
		base,
		clusterIndex,
		odnaBenchmarkIndexes,
		pdnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
	);
	return (
		<AccuracyChart
			config={config}
			containerId={containerId}
			data={accuracyData}
		/>
	);
};
