import { ClientFormLayout } from 'features/ClientConfiguration/views';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTenant } from 'common/hooks';
import { Tenant } from 'common/types';
import {
	useClientFormWithUniquenessValidation,
	useCreateClientFormData,
} from 'features/ClientConfiguration/components';
import { TenantDetailsExtractor } from 'router/pages/EditTenant/TenantDetailsExtractor';
import { useEditTenantMutation } from 'store/api';
import { emptyIfNullOrUndefined } from 'common/utils';

export const EditTenant = () => {
	const { tenantId } = useParams();
	const { tenant, areTenantsFetched } = useTenant(emptyIfNullOrUndefined(tenantId));
	const navigate = useNavigate();

	const initialSaveTriggerValue = 0;
	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);
	const [foundTenant, setFoundTenant] = useState<Tenant>();

	const { data } = useCreateClientFormData();
	const { validateForm } = useClientFormWithUniquenessValidation();

	useEffect(() => {
		if (tenant == null) {
			return;
		}
		setFoundTenant(tenant);
	}, [areTenantsFetched]);

	const [editTenant] = useEditTenantMutation();

	const saveTriggerIncrement = 1;
	const onSaveButtonClick = async () => {
		if (foundTenant === undefined) {
			return;
		}
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);

		validateForm(data).then((formIsValid) => {
			if (!formIsValid) return;
			const versionedData = { ...data, id: foundTenant.tenantId, version: foundTenant.version };
			editTenant(versionedData).then(() => navigate('/tenants'));
		});
	};

	if (foundTenant === undefined) {
		return <div />;
	}

	const details = new TenantDetailsExtractor(foundTenant);
	return (
		<ClientFormLayout
			id="edit-client"
			title="EDIT CLIENT"
			organisationInformation={details.getOrganisationInformation()}
			organisationAddress={details.getOrganizationAddress()}
			stakeholderInformation={details.getStakeholderInformation()}
			stakeholderContact={details.getStakeholderContact()}
			billingInformation={details.getBillingInformation()}
			billingAddress={details.getBillingAddress()}
			billingContact={details.getBillingContract()}
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
