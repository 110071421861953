import { useColorPreset } from './useColorPreset';

export const usePercentageDistributionStyle = (colorPresetId: number) => {
	const colorPreset = useColorPreset(colorPresetId);

	return {
		$firstColumnColor: colorPreset.first.backgroundColor,
		$firstTextColor: colorPreset.first.text,
		$secondColumnColor: colorPreset.second.backgroundColor,
		$secondTextColor: colorPreset.second.text,
		$thirdColumnColor: colorPreset.third.backgroundColor,
		$thirdTextColor: colorPreset.third.text,
		$fourthColumnColor: colorPreset.fourth.backgroundColor,
		$fourthTextColor: colorPreset.fourth.text,
		$fifthColumnColor: colorPreset.fifth.backgroundColor,
		$fifthTextColor: colorPreset.fifth.text,
	};
};
