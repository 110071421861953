import { PieChartProps } from './PieChart.types';
import { GraphContainer } from 'features/Graphs/common/components/GraphContainer';
import { usePieChartCreator } from 'features/Graphs/PieChart';

export const PieChart = ({ containerId, config, data, colorConfig }: PieChartProps) => {
	const buildGraph = usePieChartCreator(config, data, colorConfig).createGraph;

	return (
		<GraphContainer
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
		/>
	);
};
