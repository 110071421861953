import { NotificationTypes, PageLayout, TabPanel, useNotifications } from 'common/components';
import { ActionPanel, CriterionState } from 'features/ResultsConfiguration/components';
import { useEffect, useState } from 'react';
import { CriteriaConfigurationViewProps } from './CriteriaConfigurationLayout.types';
import { SelectedCounterText } from './styled';

import { ProjectSummaryPanel } from 'features/ResultsConfiguration/components';
import { SelectDeselectCriteriaButton } from 'features/ResultsConfiguration/components';
import { ViewAdditionalQuestionsButton } from 'features/ResultsConfiguration/components';

export const CriteriaConfigurationLayout = <T extends CriterionState>({
	children,
	selectedParticipantsCount,
	shouldEnableCriteriaTab,
	onSaveButtonClick,
	onViewButtonClick,
	onReturnButtonClick,
	criteria,
	onCriteriaChange,
	closeDate,
	projectShortId,
	id,
	title,
	disableFirstTab,
	changeTabToIndex,
}: CriteriaConfigurationViewProps<T>) => {
	const TabsItems = {
		Participants: 0,
		Criteria: 1,
	};

	const { pushNotification } = useNotifications();
	const [selectedTab, setSelectedTab] = useState(TabsItems.Participants);

	useEffect(() => {
		if (changeTabToIndex) {
			setSelectedTab(changeTabToIndex);
		}
	}, [changeTabToIndex]);

	const handleTabChange = (tabIndex: number): void => {
		if (!shouldEnableCriteriaTab && tabIndex === TabsItems.Criteria) {
			pushNotification({
				message:
					'No participants are available to investigate, assign participants and save to view the criteria screen',
				type: NotificationTypes.Info,
			});

			return;
		}
		if (disableFirstTab && tabIndex === TabsItems.Participants) {
			setSelectedTab(TabsItems.Criteria);
			return;
		}
		setSelectedTab(tabIndex);
	};

	const getTabPanels = () => {
		return children.map((tabPanel, index) => (
			<TabPanel
				key={index}
				index={index}
				currentSelectedTabIndex={selectedTab}
			>
				{tabPanel}
			</TabPanel>
		));
	};

	const criteriaTabIsSelected = selectedTab === TabsItems.Criteria;

	return (
		<PageLayout
			title={title ? title : 'Discover Insights'}
			subtitle="Select and assign participants to investigate"
			RightSideComponent={
				<SelectedCounterText>{`${selectedParticipantsCount} participants selected`}</SelectedCounterText>
			}
			id={id}
		>
			<ActionPanel
				currentSelectedTab={selectedTab}
				onSaveButtonClick={onSaveButtonClick}
				onViewButtonClick={onViewButtonClick}
				onReturnButtonClick={onReturnButtonClick}
				onTabChange={handleTabChange}
				tabs={[
					{ label: 'Participants', faded: disableFirstTab },
					{ label: 'Criteria', faded: !shouldEnableCriteriaTab },
				]}
			>
				{!criteriaTabIsSelected && projectShortId && (
					<ProjectSummaryPanel
						projectShortId={projectShortId}
						closeDate={closeDate}
					/>
				)}
				{criteriaTabIsSelected && (
					<>
						<SelectDeselectCriteriaButton
							criteria={criteria}
							onCriteriaChange={onCriteriaChange}
						/>
						<ViewAdditionalQuestionsButton />
					</>
				)}
			</ActionPanel>
			{getTabPanels()}
		</PageLayout>
	);
};
