import { ApiTenant, Tenant } from 'common/types';
import { AllTenantsResponse, getAllTenantsEmptyResponse, useGetAllTenantsQuery } from 'store/api';
import { mapGetAllTenantsResponse } from './mappers';

export const useTenants = () => {
	const allTenants = useGetAllTenantsQuery();
	const emptyResponse = getAllTenantsEmptyResponse();
	const tenants = getTenantsData();

	function getTenantsData(): AllTenantsResponse<ApiTenant> {
		if (!allTenants.isSuccess) return emptyResponse;

		return allTenants.data;
	}

	function transform(tenants: AllTenantsResponse<ApiTenant>): AllTenantsResponse<Tenant> {
		return mapGetAllTenantsResponse(tenants);
	}

	return { tenants: transform(tenants).tenants, areTenantsFetched: allTenants.isSuccess };
};
