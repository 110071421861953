import { ApiGraphConfiguration, ChartColor } from 'common/types';
import { useDispatch } from 'react-redux';
import {
	BenchmarkColorConfig,
	GraphConfigurationResponse,
	setBenchmarkColorConfig,
	setChartSettingsPreset,
} from 'store/index';

export const useChartConfig = (config: GraphConfigurationResponse<ApiGraphConfiguration>) => {
	const dispatch = useDispatch();

	const personalBenchmark1 = 0;
	const organisationalBenchmark1 = 1;
	const personalBenchmark2 = 2;
	const organisationalBenchmark2 = 3;
	const personalBenchmark3 = 4;
	const organisationalBenchmark3 = 5;
	const personalBenchmark4 = 6;
	const organisationalBenchmark4 = 7;
	const personalBenchmark5 = 8;
	const organisationalBenchmark5 = 9;

	const prepareBenchmark = (personalIndex: number, organisationalIndex: number) => {
		return {
			organisational: {
				lineColor: config.graphLines[organisationalIndex].color as ChartColor,
				lineType: config.graphLines[organisationalIndex].type,
			},
			personal: {
				lineColor: config.graphLines[personalIndex].color as ChartColor,
				lineType: config.graphLines[personalIndex].type,
			},
		};
	};

	dispatch(setChartSettingsPreset(config.colorPresetId.id));
	if (config.graphLines.length) {
		const savedColorConfig: BenchmarkColorConfig = {
			colorPresetId: {
				id: config.colorPresetId.id.toString(),
			},
			graphLines: {
				benchmark1: prepareBenchmark(personalBenchmark1, organisationalBenchmark1),
				benchmark2: prepareBenchmark(personalBenchmark2, organisationalBenchmark2),
				benchmark3: prepareBenchmark(personalBenchmark3, organisationalBenchmark3),
				benchmark4: prepareBenchmark(personalBenchmark4, organisationalBenchmark4),
				benchmark5: prepareBenchmark(personalBenchmark5, organisationalBenchmark5),
			},
		};
		dispatch(setBenchmarkColorConfig(savedColorConfig));
	}
};
