import styled, { css } from 'styled-components';
import { StyledErrorProps } from './CustomFormItemLayout.types';

const errorStyle = css`
	border-color: ${({ theme }) => theme.colors.error.main};
	color: ${({ theme }) => theme.colors.error.main};
`;

export const CustomFormItemWrapper = styled.div<StyledErrorProps>`
	display: flex;
	margin: 1rem;
	align-items: center;

	${({ $error }) => $error && errorStyle};
`;

export const Title = styled.label`
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex: 1;
	text-transform: capitalize;
	justify-content: flex-end;
`;

export const CustomItemWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	flex: 2;

	& > :first-child {
		min-width: 45%;
		max-width: 45%;
		margin: 0 1rem;
	}
`;

export const ErrorText = styled.span<StyledErrorProps>`
	display: flex;
	align-items: center;
	height: 1.5rem;
	margin-left: 1rem;
	line-height: 1.5rem;
`;
