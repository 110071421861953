import { Column } from '@tanstack/react-table';
import { AssessmentTypes } from 'common/constants';
import { DropdownItem } from 'common/components';
import { StyledDropdown } from './styled';
import { getAssessmentName, quote, removeQuotation } from 'common/utils';

export function QuestionnaireTypeFilter<T>(column: Column<T>, excludedValues?: string[]) {
	const selectAll = 'ALL';
	const defaultValues = [selectAll, AssessmentTypes.OPDNA, AssessmentTypes.ODNA, AssessmentTypes.PDNA];
	const filteredValues = defaultValues.filter(
		(defaultValue) => !excludedValues?.some((excludedValue) => excludedValue === defaultValue),
	);

	const selected = getSelectionFrom(column);

	const dropdownItems = createDropdownItems(filteredValues);

	return (
		<StyledDropdown
			fullContentWidth={false}
			value={selected}
			onChange={onApplyFilter}
			customLabel={removeQuotation(selected)}
		>
			{dropdownItems}
		</StyledDropdown>
	);

	function createDropdownItems(filteredValues: string[]) {
		return filteredValues.map((value) => (
			<DropdownItem
				key={filteredValues.indexOf(value)}
				value={value}
			>
				{getAssessmentName(value)}
			</DropdownItem>
		));
	}

	function onApplyFilter(text: string | null) {
		const shouldSelectEverything = text === selectAll || text == null;
		if (shouldSelectEverything) {
			column.setFilterValue(null);
			return;
		}
		const strictlyFilteredValue = quote(text);
		column.setFilterValue(strictlyFilteredValue);
	}

	function getSelectionFrom(column: Column<T>) {
		const selected = column.getFilterValue();
		if (typeof selected !== 'string') {
			return selectAll;
		}
		return getAssessmentName(removeQuotation(selected));
	}
}
