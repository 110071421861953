import { Button } from 'common/components/Button/Button';
import { ZIndexes } from 'common/constants';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	z-index: ${ZIndexes.roadmapSelection};
	top: 90%;
`;

export const ViewCriteriaButton = styled(Button)`
	position: absolute;
	right: 10rem;
`;
