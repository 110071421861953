import { Input } from 'common/components/Input';
import { StyledInput as $StyledInput } from 'common/components/Input/styled';
import styled, { createGlobalStyle } from 'styled-components';

export const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker-popper[data-placement^=bottom-end] {
		padding-top: 0;
	}

	.react-datepicker-popper[data-placement^=top] {
		padding-bottom: 0;
	}

	.react-datepicker {
		margin-top: -10px;
		margin-bottom: -10px;
		font-family: 'Mukta';
		border: 1px solid ${({ theme }) => theme.colors.common.grey};
		border-radius: 0;

		&__triangle {
			display: none;
		}

		&__navigation {
			height: 25px;
			width: 20px;
			&:hover {
				*::before {
					border-color: ${({ theme }) => theme.colors.common.grey};
				}
			}
		}

		&__navigation-icon {
			font-size: inherit;
			
			&::before {
				border-color: ${({ theme }) => theme.colors.common.black};
				width: 6px;
				height: 6px;
			}
		}

		&__header {
			background-color: ${({ theme }) => theme.colors.common.white};
			border-bottom-color: ${({ theme }) => theme.colors.common.lightSilver};
			padding-top: 0.1rem;

			&__dropdown {
				padding-top: 0.3rem;
			}
		}

		&__current-month {
			border-bottom: 2px solid ${({ theme }) => theme.colors.primary.main};
			margin: 0 2.7rem;
			font-weight: 300;
			height: 23px;
			font-size: 0.9rem;
		}

		&__day-names {
			padding-top: 0.2rem;

			.react-datepicker__day-name {
				font-size: 1rem;
				font-weight: 300;
				color: ${({ theme }) => theme.colors.text.secondary};
				margin-bottom: 0;
				line-height: normal;
				height: 23px;
			}
		}

		&__month {
			.react-datepicker__week {
				.react-datepicker__day {
					font-size: 1rem;
					font-weight: 300;

					&.react-datepicker__day--selected {
						background-color: ${({ theme }) => theme.colors.primary.main};
					}

					&.react-datepicker__day--keyboard-selected {
						background-color: ${({ theme }) => theme.colors.primary.main};
					}
				}
			}
		}

		&__month-read-view, &__year-read-view {
			font-size: 0.9rem;
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			width: 3.5rem;
			justify-content: space-evenly;

			&:hover {
				.react-datepicker__month-read-view--down-arrow {
					border-color: ${({ theme }) => theme.colors.common.grey};
				}

				.react-datepicker__year-read-view--down-arrow {
					border-color: ${({ theme }) => theme.colors.common.grey};
				}
			}

			&--down-arrow {
				position: initial;
				margin-bottom: 0.3rem;
				border-color: ${({ theme }) => theme.colors.common.black};
			}
		}

		&__year-dropdown {
			min-height: 190px;
			display: flex;
			flex-direction: column-reverse;
		}

		&__month-dropdown, &__year-dropdown {
			background-color: ${({ theme }) => theme.colors.common.white};
			font-size: 0.9rem;
		}

		&__month-option, &__year-option {
			line-height: 23px;
		}

		&__day--outside-month {
			opacity: 0.4;
		}
	}
`;

export const StyledInput = styled(Input)`
	${$StyledInput} {
		cursor: pointer;
	}
`;
