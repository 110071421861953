import { NotificationTypes, Table, useNotifications } from 'common/components';
import { Criterion, Participant, ParticipantId, TableQueryParams } from 'common/types';
import { convertParticipantsSelection } from 'common/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnFilter } from '@tanstack/react-table';
import { useCriteriaConfiguration, useParticipants, useProjectDetails } from 'common/hooks';
import { useParticipantsData } from 'features/Tables/hooks';
import { useState } from 'react';
import {
	BenchmarkConfigurationId,
	useCopyBenchmarkConfigurationMutation,
	useRemoveBenchmarkConfigurationMutation,
} from 'store/api';
import { useCreateBenchmarkConfigurationMutation } from 'store/api';
import { CriteriaConfigurationLayout } from 'features/ResultsConfiguration/views/CriteriaConfigurationLayout';
import { BenchmarksModal } from './styled';
import { RoadMapBenchmarks } from 'features/RoadMap/views/RoadMapBenchmarks';
import { RoadMapCriteriaSelectionPanel } from 'features/RoadMap/components/RoadMapCriteriaSelection';
import {
	BenchmarkedCriterion,
	BenchmarkedCriterionState,
} from 'features/RoadMap/components/RoadMapCriteriaSelection/RoadMapCriteriaSelection.types';
import {
	CreatedRoadmap,
	CreateRoadmapRequest,
	CriteriaBenchmarkRequest,
	CriteriaGroup,
	useCreateProjectRoadmapMutation,
	useCreateRoadmapMutation,
} from 'store/api/roadmapApi';
import { RoadMapView } from 'features/RoadMap/views/RoadMapView/RoadMapView';

export const RoadMap = () => {
	const minimumNumberOfParticipants = 20;
	const { projectId } = useParams();
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<ParticipantId[]>([]);

	const { getParticipants, participants } = useParticipants({ projectId });
	const { projectDetails } = useProjectDetails({ projectId });

	const { columns, visibleColumns } = useParticipantsData(projectId);

	const [benchmarkConfigurationId, setBenchmarkConfigurationId] = useState<BenchmarkConfigurationId>();
	const [editedCriteriaBenchmark, setEditedCriteriaBenchmark] = useState<BenchmarkedCriterion>();
	const { pushNotification } = useNotifications();
	const [saveParticipantsForBenchmark] = useCreateBenchmarkConfigurationMutation();
	const [copyBenchmarkConfiguration] = useCopyBenchmarkConfigurationMutation();
	const [removeBenchmarkConfiguration] = useRemoveBenchmarkConfigurationMutation();
	const [createBenchmark] = useCreateRoadmapMutation();
	const [createProjectRoadmap] = useCreateProjectRoadmapMutation();

	const [isBenchmarksModalOpen, setIsBenchmarksModalOpen] = useState(false);
	const [createdRoadmap, setCreatedRoadmap] = useState<CreatedRoadmap>();
	const [isRoadMapViewOpen, setIsRoadMapViewOpen] = useState(false);
	const navigate = useNavigate();
	const userCriteria = useCriteriaConfiguration();
	const initialCriteria = getDefaultCriteriaState(userCriteria);
	const [criteria, setCriteria] = useState<BenchmarkedCriterionState[]>(initialCriteria);
	const [blockParticipantsTab, setBlockParticipantsTab] = useState(false);

	const criteriaTabIndex = 1;

	const ParticipantsTabPanel = () => {
		const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
			await getParticipants(createRequest(queryParams));
		};

		return (
			<>
				<Table<Participant>
					tableTypeId="road-map-participants"
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={participants.pageNumber}
					pageCount={participants.totalPages}
					data={participants.content}
					onQueryChange={onQueryChange}
					onRowSelection={updateSelection}
				/>
			</>
		);
	};

	const updateSelection = (selection: Record<string, boolean>) => {
		const selectedIds = convertParticipantsSelection(selection);
		setSelectedParticipantIds(selectedIds);
	};

	const createFilters = (filters: ColumnFilter[]) => {
		const mappedFilters: { [key: string]: string } = filters.reduce(
			(accumulator, filter) => ({ ...accumulator, [filter.id]: filter.value }),
			{},
		);
		return mappedFilters;
	};

	const notEnoughParticipantsSelected = () => {
		return selectedParticipantIds.length < minimumNumberOfParticipants;
	};

	async function onSaveButtonClick() {
		setBenchmarkConfigurationId(undefined);
		if (notEnoughParticipantsSelected()) {
			notifyAboutNotEnoughParticipants();
			return;
		}
		saveParticipantsForBenchmark(selectedParticipantIds)
			.unwrap()
			.then((benchmarkId) => {
				setBenchmarkConfigurationId(benchmarkId);
				notifyAboutSuccessfulSave();
			});
		setBlockParticipantsTab(true);
	}

	const notifyAboutNotEnoughParticipants = () => {
		pushNotification({
			message: `Not enough participants have been assigned, assign at least ${minimumNumberOfParticipants} participants and save to view the criteria screen`,
			type: NotificationTypes.Error,
		});
	};

	const notifyAboutSuccessfulSave = () => {
		pushNotification({
			message: 'Selected participants have been assigned successfully and can no longer be changed',
			type: NotificationTypes.Success,
		});
	};

	const getOptionalProjectId = () => {
		return projectId || '';
	};

	const createRequest = (queryParams: TableQueryParams) => {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	};

	const onViewButtonClick = () => {
		const onlyThoseEnabled = criteria.filter((c) => c.enabled);
		const configuredCriteriaRequirement = 3;
		if (onlyThoseEnabled.length < configuredCriteriaRequirement) {
			pushNotification({
				message: 'Select at least three criteria to view results.',
				type: NotificationTypes.Error,
			});
			return;
		}

		const createCriteria = (criteria: BenchmarkedCriterionState): CriteriaGroup => ({
			criteriaId: Number.parseInt(criteria.id),
			name: criteria.name,
		});

		const criteriaRequests: CriteriaBenchmarkRequest[] = onlyThoseEnabled.map((criteria) => ({
			criteriaGroupRequest: createCriteria(criteria),
			benchmarkConfigurationId: criteria.benchmarkConfigurationId?.id || '',
		}));

		const request: CreateRoadmapRequest = {
			criteriaBenchmarkRequests: criteriaRequests,
			roadmapName: '',
			colourPresetId: 0,
		};

		if (!projectId) {
			createBenchmark(request)
				.unwrap()
				.then((roadmap) => {
					setCreatedRoadmap(roadmap);
					setIsRoadMapViewOpen(true);
				});
		} else {
			createProjectRoadmap({ projectId, requestBody: request })
				.unwrap()
				.then((roadmap) => {
					setCreatedRoadmap(roadmap);
					setIsRoadMapViewOpen(true);
				});
		}
	};

	function CriteriaSelectionTabPanel() {
		return (
			<RoadMapCriteriaSelectionPanel
				criteria={criteria}
				onCriteriaChange={setCriteria}
				onIconClick={(criterion: BenchmarkedCriterionState) => {
					const id = getBenchmarkConfigurationIdFrom(criterion);
					copyBenchmarkConfiguration(id)
						.unwrap()
						.then((newBenchmarkConfigurationId) => {
							const edited = {
								criteriaId: criterion.id,
								benchmarkConfigurationId: newBenchmarkConfigurationId,
							};
							setEditedCriteriaBenchmark(edited);
							setIsBenchmarksModalOpen(true);
						});
				}}
			/>
		);

		function getBenchmarkConfigurationIdFrom(state: BenchmarkedCriterionState) {
			if (!benchmarkConfigurationId) {
				throw Error('Benchmark Configuration Id seems to be missing');
			}

			return state.benchmarkConfigurationId ? state.benchmarkConfigurationId : benchmarkConfigurationId;
		}
	}

	return (
		<CriteriaConfigurationLayout<BenchmarkedCriterionState>
			selectedParticipantsCount={selectedParticipantIds.length}
			shouldEnableCriteriaTab={!!benchmarkConfigurationId}
			onSaveButtonClick={onSaveButtonClick}
			onViewButtonClick={onViewButtonClick}
			onReturnButtonClick={onReturnClick}
			criteria={criteria}
			onCriteriaChange={(criteria) => {
				const newCriteria = criteria.map((c) => {
					if (!c.benchmarkConfigurationId) {
						c.enabled = false;
					}
					return c;
				});
				setCriteria(newCriteria);
			}}
			closeDate={projectDetails?.closeDate}
			projectShortId={projectDetails?.shortId}
			id="road-map"
			title={'Benchmark Comparison'}
			disableFirstTab={blockParticipantsTab}
			changeTabToIndex={blockParticipantsTab ? criteriaTabIndex : undefined}
		>
			{ParticipantsTabPanel()}
			{CriteriaSelectionTabPanel()}
			<BenchmarksModal
				id="road-map-modal-benchmarks"
				isOpen={isBenchmarksModalOpen}
				hiddenCloseButton
				disabledBackdropClose
				onClose={() => {
					setIsBenchmarksModalOpen(false);
					editedCriteriaBenchmark &&
						removeBenchmarkConfiguration(editedCriteriaBenchmark.benchmarkConfigurationId);
				}}
			>
				{editedCriteriaBenchmark && benchmarkConfigurationId && (
					<RoadMapBenchmarks
						benchmarkedCriterion={editedCriteriaBenchmark}
						onSaveButtonClick={onCriteriaBenchmarkSave}
						onReturnButtonClick={onModalReturnButtonClick}
						selectedParticipantsCount={selectedParticipantIds.length}
					/>
				)}
			</BenchmarksModal>
			{createdRoadmap && (
				<RoadMapView
					roadmap={createdRoadmap}
					isOpen={isRoadMapViewOpen}
					onClose={() => {
						setIsRoadMapViewOpen(false);
						setCreatedRoadmap(undefined);
					}}
					projectId={projectId}
					criteria={criteria}
				/>
			)}
		</CriteriaConfigurationLayout>
	);

	function getDefaultCriteriaState(userCriteria: Criterion[]): BenchmarkedCriterionState[] {
		const onlyThoseEnabledByUser = (criterion: Criterion) => criterion.enabled;
		const criteria = userCriteria || [];
		const createCriteriaState = (criterion: Criterion) => ({
			...criterion,
			enabled: false,
			defaultName: criterion.name,
		});
		return criteria.filter(onlyThoseEnabledByUser).map(createCriteriaState);
	}

	function onReturnClick() {
		if (projectId) {
			navigate(`/road-map/${projectId}/overview`);
		} else {
			navigate(`/road-map/overview`);
		}
	}

	function onCriteriaBenchmarkSave(saved: BenchmarkedCriterion) {
		setIsBenchmarksModalOpen(false);
		const found = criteria.find((c) => c.id === saved.criteriaId);
		if (found == null) {
			return;
		}

		const previousBenchmarkConfig = found.benchmarkConfigurationId;

		if (previousBenchmarkConfig) {
			removePreviousBenchmarkConfiguration(previousBenchmarkConfig);
		}
		found.benchmarkConfigurationId = saved.benchmarkConfigurationId;
		setCriteria([...criteria]);
	}

	function onModalReturnButtonClick(criterion: BenchmarkedCriterion) {
		setIsBenchmarksModalOpen(false);
		removeBenchmarkConfiguration(criterion.benchmarkConfigurationId);
	}

	function removePreviousBenchmarkConfiguration(id: BenchmarkConfigurationId | undefined) {
		if (!id) {
			return;
		}

		if (benchmarkConfigurationId === id) {
			return;
		}
		removeBenchmarkConfiguration(id);
	}
};
