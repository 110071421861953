import { InputFile } from 'common/components/InputFile';
import { Button } from 'common/components/Button';
import { InputContainer, InputLabel, StyledInput } from 'common/components/Input/styled';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`;

export const LogoCustomizationWrapper = styled.div`
	display: grid;
	grid-template-columns: 55rem;
	grid-row-gap: 0.625rem;
	justify-content: center;
	margin-bottom: 2rem;
`;

export const UploadBlock = styled.div`
	display: grid;
	grid-template-columns: 21rem 45vw;
	justify-content: start;
	margin-bottom: 1.3rem;
`;

export const StyledInputFile = styled(InputFile)`
	display: grid;
	grid-template-columns: 21rem 45vw;
	justify-content: start;
	margin-bottom: 1.3rem;
	& ${InputLabel} {
		justify-content: flex-end;
		margin-right: 2rem;
		color: ${({ error, theme }) => (error ? theme.colors.error.main : theme.colors.greyedOut.dark)};
	}

	& ${InputContainer} {
		margin: 0rem;
		width: 27vw;
		display: grid;
		justify-content: start;
	}
	& ${StyledInput} {
		width: 27vw;
		padding: 0.3rem 0.7rem;
		::-webkit-input-placeholder {
			color: ${({ error, theme }) => (error ? theme.colors.error.main : theme.colors.greyedOut.dark)};
		}
		::-moz-placeholder {
			color: ${({ error, theme }) => (error ? theme.colors.error.main : theme.colors.greyedOut.dark)};
		}
	}
`;

export const LogoConfigItem = styled.div`
	display: grid;
	grid-template-columns: 21rem 45vw;
	justify-content: start;
	padding: 0.4rem 0;
`;

export const LogoConfigItemLabel = styled.label`
	align-items: center;
	white-space: nowrap;
	text-align: right;
	color: ${({ theme }) => theme.colors.greyedOut.dark};
	margin-right: 2rem;
`;

export const LogoConfigItemName = styled.div`
	width: max-content;
	color: ${({ theme }) => theme.colors.greyedOut.dark};
`;

export const LogoSaveButtonBlock = styled.div`
	display: grid;
	grid-template-columns: 21rem 27vw;
`;

export const LogoSaveButton = styled(Button)`
	width: 100%;
	display: grid;
	grid-row-start: 1;
	grid-column-start: 2;
`;

export const UnderLine = styled.hr`
	width: 100%;
`;
