import styled from 'styled-components';
import { loginBackground } from 'common/assets/images';

export const LoginBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	background: no-repeat center/100% url(${loginBackground});
`;
