import { ConditionsList, ListHeader, StyledPasswordConditionsBox } from './styled';

export const PasswordConditionsBox = () => {
	return (
		<StyledPasswordConditionsBox>
			<ListHeader>Password conditions:</ListHeader>
			<ConditionsList>
				<li>Use at least 10 characters</li>
				<li>
					At least one upper case and one <br /> lower letter
				</li>
				<li>At least 3 number digits</li>
				<li>Must include a symbol (&, ?, !, etc...)</li>
			</ConditionsList>
		</StyledPasswordConditionsBox>
	);
};
