import styled from 'styled-components';
import { Button, Dropdown } from 'common/components';
import { DropdownButton } from 'common/components/Dropdown/styled';
import { DropdownItemListItem } from 'common/components/Dropdown/DropdownItemLabel/styled';
import { DisappearingColumnProps } from 'features/DiscoveryInsights/components';

export const StyledDropdown = styled(Dropdown)`
	${DropdownButton} {
		width: 100%;
		padding: 0.3rem;
		font-size: 1rem;
		color: ${({ theme }) => theme.colors.text.primary};
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
		border: 1px solid;
		border-radius: 5px;
		border-color: ${({ theme }) => theme.colors.common.black};
		height: 4vh;
	}

	${DropdownItemListItem} {
		letter-spacing: 1px;
	}
`;

export const StyledColumn = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const StyledModeSelection = styled(StyledColumn)`
	width: 30%;
`;

export const DisappearingElement = styled('div')<DisappearingColumnProps>`
	transition: 0.2s linear all;
	opacity: ${({ $appear }) => ($appear ? '1' : '0')};
	pointer-events: ${({ $appear }) => ($appear ? 'auto' : 'none')};
`;

export const StyledCriteriaAndLegendSelection = styled(StyledColumn)`
	width: 60%;
	padding-top: 5.5%;
	white-space: nowrap;
	${StyledColumn} {
		width: 20vw;
		text-wrap: none;
		margin-left: 5%;
		height: 10vh;
	}
`;

export const StyledRow = styled('div')`
	display: flex;
`;

export const StyledButton = styled(Button)`
	margin-right: 1rem;
	margin-bottom: 1rem;
	width: 100%;
`;
