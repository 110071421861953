import { RadioInput, RadioIcon, RadioLabel } from './styled';
import { RadioProps } from './Radio.types';
import { faCircle, faCircleDot } from '@fortawesome/free-regular-svg-icons';

export const Radio = ({ className, checked, onChange, id, disabled = false, readOnly }: RadioProps) => {
	const radioIcon = checked ? faCircleDot : faCircle;
	return (
		<RadioLabel className={className}>
			<RadioIcon
				$disabled={disabled}
				icon={radioIcon}
			/>
			<RadioInput
				id={id}
				type="radio"
				readOnly={readOnly}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
		</RadioLabel>
	);
};
