import { BehaviourFocusBenchmarkNamesKeys } from 'common/constants';
import { StyledInput } from '../../../ResultsConfiguration/components/BenchmarksPanel/styled';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setBehaviourFocusBenchmarkName } from 'store/index';
import { BenchmarkGroupLabels } from 'features/BehaviourFocusResult/views';

export const BehaviourFocusInputTab = ({
	displayOneToFiveBenchmarks,
	onBenchmarkLabelChange,
}: {
	displayOneToFiveBenchmarks: boolean;
	onBenchmarkLabelChange: (labels: BenchmarkGroupLabels) => void;
}) => {
	const maxInputLength = 24;
	const placeholder = `Max ${maxInputLength} characters`;

	const dispatch = useDispatch();

	const [benchmarkNames, setBenchmarkNames] = useState({
		benchmark1: '',
		benchmark2: '',
		benchmark3: '',
		benchmark4: '',
		benchmark5: '',
		benchmark6: '',
		benchmark7: '',
		benchmark8: '',
		benchmark9: '',
		benchmark10: '',
	});
	const benchmarkFields = getBenchmarkFields();

	useEffect(
		() =>
			onBenchmarkLabelChange(
				new BenchmarkGroupLabels(
					benchmarkNames.benchmark1,
					benchmarkNames.benchmark2,
					benchmarkNames.benchmark3,
					benchmarkNames.benchmark4,
					benchmarkNames.benchmark5,
					benchmarkNames.benchmark6,
					benchmarkNames.benchmark7,
					benchmarkNames.benchmark8,
					benchmarkNames.benchmark9,
					benchmarkNames.benchmark10,
				),
			),
		[benchmarkNames],
	);

	return (
		<>
			{benchmarkFields.map((benchmarkKey) => (
				<StyledInput
					key={benchmarkKey}
					name={`Name Of Benchmark ${getBenchmarkIndex(benchmarkKey)}`}
					placeholder={placeholder}
					maxLength={maxInputLength}
					onChange={(event) => {
						const value = event.target.value;
						setBenchmarkNames({ ...benchmarkNames, [benchmarkKey]: value });
					}}
					onBlur={(event) =>
						dispatch(setBehaviourFocusBenchmarkName({ benchmarkKey, value: event.target.value }))
					}
					value={benchmarkNames[benchmarkKey]}
				/>
			))}
		</>
	);

	function getBenchmarkIndex(benchmarkKey: string) {
		return benchmarkKey.replace('benchmark', '');
	}

	function getBenchmarkFields() {
		const benchmarks = displayOneToFiveBenchmarks
			? [
					BehaviourFocusBenchmarkNamesKeys.First,
					BehaviourFocusBenchmarkNamesKeys.Second,
					BehaviourFocusBenchmarkNamesKeys.Third,
					BehaviourFocusBenchmarkNamesKeys.Fourth,
					BehaviourFocusBenchmarkNamesKeys.Fifth,
			  ]
			: [
					BehaviourFocusBenchmarkNamesKeys.Sixth,
					BehaviourFocusBenchmarkNamesKeys.Seventh,
					BehaviourFocusBenchmarkNamesKeys.Eighth,
					BehaviourFocusBenchmarkNamesKeys.Ninth,
					BehaviourFocusBenchmarkNamesKeys.Tenth,
			  ];

		return Object.values(benchmarks);
	}
};
