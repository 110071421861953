import { CriterionState } from 'features/ResultsConfiguration/components';
import { StyledButton } from './styled';

type SelectDeselectCriteriaButtonProps<T extends CriterionState> = {
	criteria: T[];
	onCriteriaChange: (criteria: T[]) => void;
};

export const SelectDeselectCriteriaButton = <T extends CriterionState>({
	criteria,
	onCriteriaChange,
}: SelectDeselectCriteriaButtonProps<T>) => {
	return (
		<StyledButton
			variant="outlined"
			onClick={onClick}
		>
			SELECT / DESELECT ALL
		</StyledButton>
	);

	function onClick() {
		if (thereAreNoCriteriaCurrentlySelected()) {
			const changedCriteria = selectAllCriteria();
			onCriteriaChange(changedCriteria);
			return;
		}

		if (atLeastOneCriteriaSelected()) {
			const changedCriteria = deselectWhicheverCriteriaWereAlreadySelected();
			onCriteriaChange(changedCriteria);
			return;
		}
	}

	function thereAreNoCriteriaCurrentlySelected(): boolean {
		return getStateOfCriteria().every((enabled) => !enabled);
	}

	function atLeastOneCriteriaSelected(): boolean {
		return getStateOfCriteria().some((enabled) => enabled);
	}

	function getStateOfCriteria() {
		return criteria.map((criterion) => criterion.enabled);
	}

	function deselectWhicheverCriteriaWereAlreadySelected(): T[] {
		return createCriteriaWithEnabledState(false);
	}

	function selectAllCriteria(): T[] {
		return createCriteriaWithEnabledState(true);
	}

	function createCriteriaWithEnabledState(enabled: boolean) {
		return criteria.map((criterion) => ({ ...criterion, enabled }));
	}
};
