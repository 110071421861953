import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ColumnFilter } from '@tanstack/react-table';
import { TableSelectionFilters, TableSortingDirections } from 'common/constants';
import { ParticipantId } from './participants';

export type TableSortingDirection = typeof TableSortingDirections[keyof typeof TableSortingDirections];

export type TableSelectionFilter = typeof TableSelectionFilters[keyof typeof TableSelectionFilters];

export type TableAction<T> = {
	label: string;
	icon: IconDefinition;
	isHidden?: (row: T) => boolean;
	getHref?: (row: T) => string;
	onClick?: (row: T | undefined) => void;
};

export type TableQueryParams = {
	orderBy?: string;
	direction: TableSortingDirection;
	filters: ColumnFilter[];
	pageNumber: number;
	size: number;
};

export type SelectableRowIds = {
	participantId?: ParticipantId;
};

export type Pagination = {
	pageNumber: number;
	totalPages: number;
};

export class TableQueryTrigger {}
