import { PageLayout, Table } from 'common/components';
import { Participant, TableQueryParams } from 'common/types';
import { useAssessedParticipantsData } from 'features/Tables/hooks';
import { useAssessedParticipants } from 'common/hooks';
import { ColumnFilter } from '@tanstack/react-table';
import { ButtonsContainer, StyledButton } from './styled';
import { useState } from 'react';
import { DeactivateParticipantConfirmationDialog } from 'features/Participants/components';

export const AssessedParticipants = () => {
	const { getAssessedParticipants, assessedParticipants } = useAssessedParticipants();
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	const { columns, visibleColumns, participantToDeactivate } =
		useAssessedParticipantsData(setIsConfirmationModalOpen);

	const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;

		await getAssessedParticipants({ orderBy, direction, size, pageNumber, filters: createFilters(filters) });
	};

	function createFilters(filters: ColumnFilter[]) {
		const isFilterArrayFilled = !!filters.length;
		if (!isFilterArrayFilled) return;
		const mappedFilters: { [key: string]: string }[] = filters.map((filter) => {
			return {
				[filter.id]: filter.value as string,
			};
		});
		return mappedFilters;
	}

	return (
		<>
			<DeactivateParticipantConfirmationDialog
				isOpen={isConfirmationModalOpen}
				participantId={participantToDeactivate.participantId}
				participantName={participantToDeactivate.participantName}
				onClose={() => setIsConfirmationModalOpen(false)}
			/>
			<PageLayout
				title="Participants"
				id="participants"
			>
				<ButtonsContainer>
					<StyledButton
						href="/assessees/create"
						variant="outlined"
					>
						Add
					</StyledButton>
					<StyledButton
						href="/imports/participants"
						variant="outlined"
					>
						Import Participants
					</StyledButton>
				</ButtonsContainer>
				<Table<Participant>
					tableTypeId="assessed-participants"
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={assessedParticipants.pageNumber}
					pageCount={assessedParticipants.totalPages}
					data={assessedParticipants.content}
					onQueryChange={onQueryChange}
				/>
			</PageLayout>
		</>
	);
};
