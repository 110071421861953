import { useSelector } from 'react-redux';
import { selectRadarCombinedPDNA, selectRadarConfig, selectRadarPDNA } from 'store/slices/graphSlices';
import { PdnaRadarGraphProps } from './PdnaRadarGraph.types';
import { RadarGraph, usePdnaRadarGraphData } from 'features/Graphs';

export const PdnaRadarGraph = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
}: PdnaRadarGraphProps) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const pdna = combinedMode ? useSelector(selectRadarCombinedPDNA) : useSelector(selectRadarPDNA);
	const config = useSelector(selectRadarConfig);
	const radarData = usePdnaRadarGraphData(pdna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<RadarGraph
			config={config}
			containerId={containerId}
			data={radarData}
		/>
	);
};
