import useInterval from 'common/hooks/useInterval';
import { isEmptyArray } from 'common/utils';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
	AddNotification,
	NotificationPropsWithoutTimeout,
	NotificationPropsWithTimeout,
	NotificationsContextApi,
	NotificationsContextProps,
	NotificationTypes,
} from './Notification.types';

const defaultApi: NotificationsContextApi = {
	notifications: [],
	pushNotification: (_notification: AddNotification) => '',
	clearNotification: (_id: string) => null,
	hideNotification: (_id: string) => null,
};

export const NotificationsContext = React.createContext<NotificationsContextApi>(defaultApi);

export const NotificationsProvider = ({ interval, children }: NotificationsContextProps) => {
	const [notifications, setNotifications] = useState<
		(NotificationPropsWithTimeout | NotificationPropsWithoutTimeout)[]
	>(defaultApi.notifications);
	const [hiddenNotifications, setHiddenNotifications] = useState<string[]>([]);

	const pushNotification = React.useCallback(
		(notification: AddNotification) => {
			const newNotification = {
				id: uuid(),
				type: NotificationTypes.Error,
				disableAutoHide: false,
				timeout: 5000,
				...notification,
			};

			const nextNotifications = notifications.concat(newNotification);

			setNotifications(nextNotifications);

			return newNotification.id;
		},
		[notifications, setNotifications],
	);

	const clearNotification = React.useCallback(
		(id: string) => {
			const filteredNotifications = notifications.filter((notification) => notification.id !== id);
			setNotifications(filteredNotifications);

			return null;
		},
		[notifications, setNotifications],
	);

	const hideNotification = React.useCallback(
		(id: string) => {
			const updatedHiddenNotifications = hiddenNotifications;
			updatedHiddenNotifications.push(id);

			setHiddenNotifications(updatedHiddenNotifications);
			return null;
		},
		[hiddenNotifications, setHiddenNotifications],
	);

	const handleHiddenNotifications = React.useCallback(() => {
		if (isEmptyArray(hiddenNotifications)) return;
		for (const notification of hiddenNotifications) {
			clearNotification(notification);
		}
		setHiddenNotifications([]);
	}, [notifications, hiddenNotifications, clearNotification, setHiddenNotifications]);

	useInterval(handleHiddenNotifications, interval);

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				pushNotification,
				clearNotification,
				hideNotification,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
};
