import { CriterionSetting } from 'store/api';

export const validateForm = (criteria: CriterionSetting[]) => {
	let valid = true;

	criteria.forEach((criterion) => {
		if (!criterion.name) {
			valid = false;
			return;
		}
	});

	return valid;
};
