import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const ExportButtonBlock = styled.div`
	margin-top: 1rem;
	display: flex;
	width: 99.5%;
	justify-content: center;
	::before {
		content: '';
		flex: 1;
	}
`;

export const ExportButton = styled(Button)`
	margin-right: 1rem;
	margin-bottom: 1rem;
	width: 45%;
`;

export const ButtonWrapper = styled.div`
	flex: 2;
	margin-left: 2rem;
`;
