import { useDispatch } from 'react-redux';
import { setSuspenseManualTriggerVisibility } from 'store/slices';
import { ManualSuspenseControls } from 'common/hooks';

export const useSuspenseManually = (): ManualSuspenseControls => {
	const dispatch = useDispatch();

	return {
		turnOn,
		turnOff,
	};

	function turnOn() {
		dispatch(setSuspenseManualTriggerVisibility(true));
	}

	function turnOff() {
		dispatch(setSuspenseManualTriggerVisibility(false));
	}
};
