import { Button, NotificationTypes, PageLayout, Table, useNotifications } from 'common/components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoadmaps } from 'features/RoadMap/hooks';
import { useRoadmapColumns } from 'features/RoadMap/hooks';
import { RoadmapSummary } from 'store/api/roadmapApi/roadmapApi.types';
import { DeleteRoadmapConfirmDialog } from 'features/RoadMap/components';
import { useProjectRoadmaps } from 'features/RoadMap/hooks';

export const RoadMapOverview = () => {
	const navigate = useNavigate();
	const { projectId } = useParams();
	const roadmaps = projectId ? useProjectRoadmaps(projectId) : useRoadmaps();

	const notifications = useNotifications();

	const [roadmapToDelete, setRoadmapToDelete] = useState<RoadmapSummary>();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const columns = useRoadmapColumns({
		onRoadmapDelete,
		onRoadmapView,
	});

	return (
		<PageLayout
			title="RoadMap Overview"
			id="road-map-overview"
		>
			<>
				{deleteRoadmapDialog()}
				<Button
					onClick={tryToNavigateToCreateRoadmap}
					variant="outlined"
				>
					Add Roadmap
				</Button>

				<br />

				<Table<RoadmapSummary>
					tableTypeId="roadmap"
					disableColumnSelector
					columns={columns}
					data={roadmaps.data}
				/>
			</>
		</PageLayout>
	);

	function deleteRoadmapDialog() {
		return (
			roadmapToDelete && (
				<DeleteRoadmapConfirmDialog
					roadmapId={roadmapToDelete.roadmapId}
					roadmapName={roadmapToDelete.name}
					isOpen={deleteDialogOpen}
					onClose={() => {
						setDeleteDialogOpen(false);
					}}
				/>
			)
		);
	}

	function tryToNavigateToCreateRoadmap() {
		if (numberOfRoadmapsIsExceeded()) {
			displayNotificationAboutExceedingRoadmapAmount();
		} else {
			navigateToCreateRoadmap();
		}
	}

	function navigateToCreateRoadmap() {
		if (projectId) {
			navigate(`/road-map/${projectId}`);
		} else {
			navigate(`/road-map`);
		}
	}

	function displayNotificationAboutExceedingRoadmapAmount() {
		notifications.pushNotification({
			message: 'Maximum number of Roadmaps already exists',
			type: NotificationTypes.Info,
		});
	}

	function numberOfRoadmapsIsExceeded() {
		const limit = 5;
		return roadmaps.data.length >= limit;
	}

	function onRoadmapDelete(roadmap: RoadmapSummary) {
		setRoadmapToDelete(roadmap);
		setDeleteDialogOpen(true);
	}

	function onRoadmapView(roadmap: RoadmapSummary) {
		if (projectId) {
			navigate(`/road-map/${projectId}/${roadmap.roadmapId}/view`);
		} else {
			navigate(`/road-map/${roadmap.roadmapId}/view`);
		}
	}
};
