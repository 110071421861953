import { RegexPatterns } from 'common/constants';

type PasswordValidatorSettings = {
	minimumLength: number;
	uppercaseCharacters: number;
	lowercaseCharacters: number;
	digits: number;
	specialCharacters: number;
};

const bullet = '•';

const ErrorMessages = {
	length: `${bullet} Password must contain at least 10 characters\n`,
	characterCase: `${bullet} Password must contain at least one upper case and one lower case letter\n`,
	digits: `${bullet} Password must contain at least 3 digits\n`,
	specialCharacters: `${bullet} Password must include a symbol\n`,
	matching: `${bullet} New passwords don't match\n`,
};

const defaultSettings: PasswordValidatorSettings = {
	minimumLength: 10,
	uppercaseCharacters: 1,
	lowercaseCharacters: 1,
	digits: 3,
	specialCharacters: 1,
};

export type PasswordValidationResult = {
	success: boolean;
	message: string;
};

export class PasswordValidator {
	private settings: PasswordValidatorSettings;

	constructor(settings?: PasswordValidatorSettings) {
		this.settings = settings ? settings : defaultSettings;
	}

	public validatePassword(password: string) {
		return this.checkRules(password);
	}

	public validatePasswords(password: string, repeatPassword: string) {
		const passwordsMatch = this.comparePasswords(password, repeatPassword);
		if (!passwordsMatch.success) {
			return passwordsMatch;
		}

		return this.checkRules(password);
	}

	public comparePasswords = (password: string, repeatPassword: string): PasswordValidationResult => {
		if (password !== repeatPassword) {
			return {
				success: false,
				message: ErrorMessages.matching,
			};
		}

		return {
			success: true,
			message: '',
		};
	};

	private checkLength = (password: string) => {
		return password.length >= this.settings.minimumLength;
	};

	private checkUppercase(password: string) {
		// regex that will match anything other than uppercase letter
		// anything that's not a uppercase letter will get replaced with empty character therefore the length returns number of uppercase letters
		return password.replace(RegexPatterns.NotUppercaseCharacters, '').length >= this.settings.uppercaseCharacters;
	}

	private checkLowercase(password: string) {
		// regex that will match anything other than lowercase letter
		// anything that's not a lowercase letter will get replaced with empty character therefore the length returns number of lowercase letters
		return password.replace(RegexPatterns.NotLowercaseCharacters, '').length >= this.settings.lowercaseCharacters;
	}

	private checkDigits(password: string) {
		// regex that will match anything other than digit
		// anything that's not a digit will get replaced with empty character therefore the length returns number of digits
		return password.replace(RegexPatterns.NotDigits, '').length >= this.settings.digits;
	}

	private checkSpecialCharacters(password: string) {
		// regex that will match any lowercase letter, uppercase letter, digit or space
		// anything that matches will get replaced with empty character therefore length returns number of special characters
		return password.replace(RegexPatterns.NotSpecialCharacters, '').length >= this.settings.specialCharacters;
	}

	private checkUppercaseAndLowercase(password: string) {
		const validUppercase = this.checkUppercase(password);
		const validLowercase = this.checkLowercase(password);

		return validUppercase && validLowercase;
	}

	private checkRules(password: string): PasswordValidationResult {
		const validLength = this.checkLength(password);
		const validCase = this.checkUppercaseAndLowercase(password);
		const validDigits = this.checkDigits(password);
		const validSpecialCharacters = this.checkSpecialCharacters(password);

		let success = true;
		let message = '';

		if (!validLength) {
			success = false;
			message += ErrorMessages.length;
		}

		if (!validCase) {
			success = false;
			message += ErrorMessages.characterCase;
		}

		if (!validDigits) {
			success = false;
			message += ErrorMessages.digits;
		}

		if (!validSpecialCharacters) {
			success = false;
			message += ErrorMessages.specialCharacters;
		}

		return {
			success,
			message,
		};
	}
}
