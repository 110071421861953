import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from 'common/components/IconButton';
import { Checkbox } from 'common/components/CheckBox';
import styled from 'styled-components';
import { StyledColorPickerButtonProps, StyledLinePickerButtonProps } from './ChartLineSettingsItem.types';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.35rem 0 0 0;
`;

export const StyledCheckbox = styled(Checkbox)`
	padding: 0 0 0 0.2rem;
`;

export const TitleContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

export const Title = styled.p`
	margin: 0 0 0 0.75rem;
`;

export const DashedLine = styled.div`
	display: flex;

	& > * {
		margin: 0 0.075rem;
	}
`;

export const SquareIcon = styled(FontAwesomeIcon)`
	height: 0.4rem;
	width: 0.4rem;
	color: inherit;
	display: flex;
	justify-content: center;
`;

export const SolidLineIcon = styled(FontAwesomeIcon)`
	font-size: 2.5rem;
	color: inherit;
	display: flex;
`;

export const LinePickerButton = styled(IconButton)<StyledLinePickerButtonProps>`
	padding: 0;
	height: 1rem;
	width: 2.2rem;
	margin-left: auto;
	overflow: hidden;
	border-radius: 0;

	color: ${({ $color }) => $color};
`;

export const ColorPickerButton = styled(IconButton)<StyledColorPickerButtonProps>`
	height: 1.75rem;
	width: 1.75rem;
	margin-left: 0.5rem;
	background-color: ${({ $color }) => $color};
	border-radius: 5px;
`;
