import { PageLayout } from 'common/components';
import { ManagerForm } from 'features/ClientConfiguration/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ManagerFormLayoutProps } from './ManagerFormLayout.types';

export const ManagerFormLayout = ({ id, saveTrigger, onSaveButtonClick }: ManagerFormLayoutProps) => {
	return (
		<PageLayout
			title="Create Alderbrooke Configuration Manager (APD MGR)"
			id={id}
		>
			<div>
				<ManagerForm saveTrigger={saveTrigger} />

				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
