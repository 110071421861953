export const DefaultBenchmarkId = {
	BENCHMARK_1: 'BENCHMARK_1',
	BENCHMARK_2: 'BENCHMARK_2',
	BENCHMARK_3: 'BENCHMARK_3',
	BENCHMARK_4: 'BENCHMARK_4',
	BENCHMARK_5: 'BENCHMARK_5',
} as const;

export const BenchmarksNamesKeys = {
	First: 'benchmark1',
	Second: 'benchmark2',
	Third: 'benchmark3',
	Fourth: 'benchmark4',
	Fifth: 'benchmark5',
} as const;

export const BehaviourFocusBenchmarkNamesKeys = {
	First: 'benchmark1',
	Second: 'benchmark2',
	Third: 'benchmark3',
	Fourth: 'benchmark4',
	Fifth: 'benchmark5',
	Sixth: 'benchmark6',
	Seventh: 'benchmark7',
	Eighth: 'benchmark8',
	Ninth: 'benchmark9',
	Tenth: 'benchmark10',
} as const;

export type BenchmarkKey = typeof BenchmarksNamesKeys[keyof typeof BenchmarksNamesKeys];
export type BehaviourFocusBenchmarkKey =
	typeof BehaviourFocusBenchmarkNamesKeys[keyof typeof BehaviourFocusBenchmarkNamesKeys];
