import { BenchmarksContainer, StyledErrorText, StyledInput, StyledInputContainer } from './styled';
import { Criterion, CriterionId } from 'common/types';
import { CriteriaSelectionPanelProps, CriterionState } from 'features/ResultsConfiguration/components';

export const CriteriaSelectionPanel = ({ criteria, onCriteriaChange }: CriteriaSelectionPanelProps) => {
	const criteriaInputs = createCriteriaInputs(criteria);

	return <BenchmarksContainer>{criteriaInputs}</BenchmarksContainer>;

	function createCriteriaInputs(criteria: Criterion[]) {
		const maxInputLength = 30;

		const checkIfCriteriaAliasTooLong = (inputValue: string): boolean => {
			return inputValue.length > maxInputLength;
		};

		const placeholder = 'Max 30 characters';
		const errorText = 'Criteria name cannot be longer than 30 characters';
		return criteria.map((criterion) => {
			const name = `Criteria ${criterion.id}*`;
			const errorHasOccurred = checkIfCriteriaAliasTooLong(criterion.name);
			return (
				<StyledInputContainer key={criterion.id}>
					<StyledInput
						name={name}
						placeholder={placeholder}
						error={errorHasOccurred}
						onChange={(event) => {
							const newName = event.target.value;
							const changeName = (criterion: CriterionState) => ({ ...criterion, name: newName });
							findAndApplyChangeToCriteria(criterion.id, changeName);
						}}
						onCheckboxChange={() => {
							const newEnabledState = !criterion.enabled;
							const changeEnabled = (criterion: CriterionState) => ({
								...criterion,
								enabled: newEnabledState,
							});
							findAndApplyChangeToCriteria(criterion.id, changeEnabled);
						}}
						value={criterion.name}
						onBlur={(target) => {
							const newNameIsEmpty = !target.target.value.length;
							if (newNameIsEmpty) {
								const changeName = (criterion: CriterionState) => ({
									...criterion,
									name: criterion.defaultName,
								});
								findAndApplyChangeToCriteria(criterion.id, changeName);
							}
						}}
						checkbox
						checked={criterion.enabled}
					/>
					{errorHasOccurred && <StyledErrorText>{errorText}</StyledErrorText>}
				</StyledInputContainer>
			);
		});
	}

	function findAndApplyChangeToCriteria(id: CriterionId, change: (criterion: CriterionState) => CriterionState) {
		const newArray = criteria.map((criterion) => {
			if (id === criterion.id) {
				return change(criterion);
			}
			return criterion;
		});
		onCriteriaChange(newArray);
	}
};
