import {
	faBuilding,
	faRectangleList,
	faUsers,
	faBars,
	faChartLine,
	faGear,
	faBolt,
	faRoute,
	faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavbarItem } from 'features/Navigation/components';
import { NavbarList } from '../styled';

export const AdminNavbar = () => {
	return (
		<NavbarList>
			<NavbarItem
				pageName="My Organisation"
				path="/mytenant"
				icon={<FontAwesomeIcon icon={faBuilding} />}
			/>
			<NavbarItem
				pageName="Projects"
				path="/projects"
				icon={<FontAwesomeIcon icon={faRectangleList} />}
			/>
			<NavbarItem
				pageName="Participants"
				path="/assessees"
				icon={<FontAwesomeIcon icon={faUsers} />}
			/>
			<NavbarItem
				pageName="Culture View"
				path="/culture-view"
				icon={<FontAwesomeIcon icon={faBars} />}
			/>
			<NavbarItem
				pageName="Predictive View"
				path="/predictive-view"
				icon={<FontAwesomeIcon icon={faChartLine} />}
			/>
			<NavbarItem
				pageName="Discover Insights"
				path="/discovery-insights"
				icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
			/>
			<NavbarItem
				pageName="Behaviour Focus"
				path="/behaviour-focus"
				icon={<FontAwesomeIcon icon={faBolt} />}
			/>
			<NavbarItem
				pageName="Roadmap"
				path="/road-map/overview"
				icon={<FontAwesomeIcon icon={faRoute} />}
			/>
			<NavbarItem
				pageName="Cluster Configuration"
				path="/clusterConfiguration"
				icon={<FontAwesomeIcon icon={faGear} />}
			/>
		</NavbarList>
	);
};
