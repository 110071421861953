import { PagedParticipantsOptions } from 'store/api';
import { ParticipantId } from 'common/types';

export type BenchmarkConfigurationId = {
	id: string;
};

export type BenchmarkParticipantId = string;

export type BenchmarkConfigurationOptions = {
	benchmarkConfigurationId: BenchmarkConfigurationId;
	projectId?: string;
};

export type ParticipantToggleRequest = BenchmarkConfigurationOptions & {
	participantId: ParticipantId;
	benchmarkGroup: string;
};

export type ParticipantAssignationRequest = BenchmarkConfigurationOptions & {
	benchmarkGroup: string[];
	pageParams: { filters: Record<string, string> };
};

export const ApiBenchmarkGroup = {
	Benchmark1: 'BENCHMARK_1',
	Benchmark2: 'BENCHMARK_2',
	Benchmark3: 'BENCHMARK_3',
	Benchmark4: 'BENCHMARK_4',
	Benchmark5: 'BENCHMARK_5',
	Benchmark6: 'BENCHMARK_6',
	Benchmark7: 'BENCHMARK_7',
	Benchmark8: 'BENCHMARK_8',
	Benchmark9: 'BENCHMARK_9',
	Benchmark10: 'BENCHMARK_10',
};

export type BenchmarkParticipantOptions = PagedParticipantsOptions & BenchmarkConfigurationOptions;
