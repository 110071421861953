import { StyledButton } from './styled';
import { useState } from 'react';
import { Modal } from 'common/components';
import { AdditionalQuestions } from 'router/pages';
import { useParams } from 'react-router-dom';
import { useAdditionalQuestions } from 'common/hooks';
import { ZIndexes } from 'common/constants';

export const ViewAdditionalQuestionsButton = () => {
	const [displayModal, setDisplayModal] = useState(false);
	const { projectId } = useParams();
	const { additionalQuestions } = useAdditionalQuestions({ projectId: projectId });
	const canDisplayAdditionalQuestions = additionalQuestions && !!additionalQuestions.length;
	return (
		<>
			{canDisplayAdditionalQuestions && (
				<>
					{additionalQuestionModal()}
					{viewButton()}
				</>
			)}
		</>
	);

	function viewButton() {
		return (
			<StyledButton
				variant="outlined"
				onClick={() => setDisplayModal(true)}
			>
				View Additional Questions
			</StyledButton>
		);
	}

	function additionalQuestionModal() {
		return (
			<Modal
				id="view-additional-questions-modal"
				isOpen={displayModal}
				onClose={() => {
					setDisplayModal(false);
				}}
				className="primary"
				backdropZIndex={ZIndexes.additionalQuestionsModalBackdrop}
				modalZIndex={ZIndexes.additionalQuestionsModal}
			>
				<AdditionalQuestions />
			</Modal>
		);
	}
};
