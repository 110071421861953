import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const OptionsContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;

	& > *:nth-child(2) {
		margin-bottom: 2rem;
	}
`;

export const ActionButtonsContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	& > *:not(:last-child) {
		margin: 1rem 0;
	}
`;

export const SaveButtonsWrapper = styled.div`
	display: flex;
	margin-top: auto;
`;

export const CloseButton = styled(Button)`
	margin-top: 2 rem;
	flex-grow: 1;
	margin-right: 1rem;
`;

export const SaveCloseButton = styled(Button)`
	flex-grow: 2;
`;
