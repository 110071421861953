import { DropdownItemLabelProps } from './DropdownItemLabel.types';
import { DropdownItemListItem as DropdownItemLabelListItem, StyledIcon } from './styled';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const DropdownItemLabel = ({ children, isChosen, onClick }: DropdownItemLabelProps) => {
	const chosenItemIcon = <StyledIcon icon={faCheck} />;
	return (
		<DropdownItemLabelListItem onClick={onClick}>
			{isChosen && chosenItemIcon}
			{children}
		</DropdownItemLabelListItem>
	);
};
