import { ChangeEvent, MouseEvent, useRef, useState } from 'react';

import { StyledFileInput, StyledInputStub } from './styled';
import { InputFileProps } from './InputFile.types';

export const InputFile = ({
	name,
	errorText,
	error = false,
	disabled = false,
	errorPlacement = 'right',
	labelPlacement,
	endElement,
	onChange,
	placeholder,
	className,
	accept,
	...inputProps
}: InputFileProps) => {
	const options = {
		name,
		errorText,
		error,
		disabled,
		labelPlacement,
		endElement,
		placeholder,
		className,
	};
	const [fileName, setFileName] = useState('');
	const inputFileRef = useRef<HTMLInputElement>(null);
	const onInputClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		inputFileRef.current?.click();
	};
	const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const first = 0;

		const thereIsNoFilePresent = !event.target.files?.length;
		if (thereIsNoFilePresent) return;
		setFileName(event.target.files?.[first].name || '');

		if (onChange) {
			onChange(event);
		}
	};

	return (
		<>
			<StyledInputStub
				onChange={(file: ChangeEvent<HTMLInputElement>) => {
					onFileInputChange(file);
				}}
				{...inputProps}
				value={fileName}
				{...options}
				title={fileName}
				error={error}
				errorText={errorText}
				errorPlacement={errorPlacement}
				onClick={(event) => onInputClick(event)}
			/>
			<StyledFileInput
				ref={inputFileRef}
				id={name}
				type={'file'}
				onChange={(event) => {
					onFileInputChange(event);
					inputFileRef;
				}}
				disabled={disabled}
				accept={accept}
				multiple={false}
			/>
		</>
	);
};
