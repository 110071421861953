import { Core, NodeSingular } from 'cytoscape';
import { PositioningStrategy } from '@popperjs/core/lib/types';
import { top } from '@popperjs/core/lib/enums';

export const setupPopper = (cy: Core) => {
	const strategy: PositioningStrategy = 'fixed';

	const popperOptions = (cy: Core, node: NodeSingular) => ({
		content: () => {
			const div = document.createElement('div');

			div.innerHTML = node.data('label');
			div.style.cssText = `z-index: 999; font-size: 2em; border: 1px solid black; margin: 2px; padding: 0.2%; margin: 0.2%; visibility: visible;`;
			cy.container()?.appendChild(div);

			return div;
		},
		popper: { placement: top, modifiers: [], strategy },
	});

	const poppers = cy.nodes().map((node) => {
		return node.popper(popperOptions(cy, node));
	});

	const update = () => {
		poppers.forEach((p) => p.update());
	};

	cy.nodes().forEach((node) => {
		node.on('position', update);
	});

	cy.on('pan zoom resize', update);
};
