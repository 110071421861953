import { useEffect } from 'react';
import { GraphContainerProps } from './GraphContainer.types';

export const GraphContainer = ({ containerId, data, buildGraph }: GraphContainerProps) => {
	const renderGraph = (svgContainer: HTMLElement) => {
		const svg = buildGraph();
		svg.dispose();
		const svgNode = svg.getSvgNode();

		svgContainer.appendChild(svgNode);
	};

	const cleanOldGraph = (svgContainer: HTMLElement, child: ChildNode) => {
		svgContainer.removeChild(child);
	};

	useEffect(() => {
		const svgContainer = document.getElementById(containerId);
		if (svgContainer?.firstChild) {
			cleanOldGraph(svgContainer, svgContainer.firstChild);
		}
		if (svgContainer) {
			renderGraph(svgContainer);
		}
	}, [data]);

	return <></>;
};
