import { ProjectFormSectionLayoutProps } from './ProjectFormSectionLayout.types';
import { Hr, Title } from './styled';

export const ProjectFormSectionLayout = ({ title, children }: ProjectFormSectionLayoutProps) => {
	return (
		<>
			<Title>{title}</Title>
			{children}
			<Hr />
		</>
	);
};
