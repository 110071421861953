import { useAuth } from 'common/hooks';
import { UserPermission } from 'common/types';
import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../Notifications';

type ProtectedRoutesProps = {
	requiredRole?: UserPermission;
	page: JSX.Element;
};

export const ProtectedRoutes = ({ requiredRole, page }: ProtectedRoutesProps) => {
	const auth = useAuth();
	const isChangePasswordRoute = useMatch('/passwordChange');
	const { pushNotification } = useNotifications();

	if (!auth.user?.permissions) {
		return (
			<Navigate
				to="/login"
				replace
			/>
		);
	}

	if (requiredRole) {
		const userHasNecessaryPermission = auth.user?.permissions.includes(requiredRole);

		if (!userHasNecessaryPermission) {
			pushNotification({
				message: 'Access forbidden.',
				type: NotificationTypes.Info,
			});
			return (
				<Navigate
					to="/login"
					replace
				/>
			);
		}
	}

	if (isChangePasswordRoute) {
		return <Outlet />;
	}

	return page;
};
