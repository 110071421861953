import { ImportParticipantsCsvBody } from 'store/api';

export type ProjectValidationProperties = { shouldCheckForShortIdUniqueness: boolean };

export const useImportParticipantsDataValidation = () => {
	const validateForm = async (data: Partial<ImportParticipantsCsvBody>) => {
		return [requiredFileShouldBeValid(data.file), required(data.filename)].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function required(data: string | undefined) {
		if (data === undefined) return false;

		return data.trim() != null && !!data.trim().length;
	}

	function requiredExtension(data: string, requirement: string) {
		return data === requirement;
	}

	function requiredFileShouldBeValid(file: File | undefined) {
		if (!file) return false;

		const extensionIndex = 1;
		const fileExtension = file.name.split('.')[extensionIndex];
		const requiredFileExtension = 'csv';

		if (!requiredExtension(fileExtension, requiredFileExtension)) {
			return false;
		}

		return true;
	}
};
