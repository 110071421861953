import styled from 'styled-components';

export const QuestionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const QuestionText = styled.h3`
	color: ${({ theme }) => theme.colors.text.secondary};
	font-size: 1rem;
	font-weight: 400;
	margin: 0;
`;

export const AnswersList = styled.ul``;

export const AnswerListItem = styled.li`
	line-height: 1.4rem;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.text.secondary};

	&::marker {
		font-size: 0.8rem;
	}
`;

export const AnswerText = styled.p`
	font-size: 1rem;
	margin: 0;
`;
