import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { StakeholderContactState } from '.';

const initialState: StakeholderContactState = {
	secondaryContactName: '',
	secondaryContactMail: '',
	secondaryContactTelephone: '',
	secondaryContactMobile: '',
	secondaryContactIM: '',
};

export const stakeholderContactSlice = createSlice({
	name: 'stakeholderContact',
	initialState,
	reducers: {
		setStakeholderContactState: (state, { payload }: PayloadAction<StakeholderContactState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setStakeholderContactState } = stakeholderContactSlice.actions;

export const stakeholderContactSliceReducer = stakeholderContactSlice.reducer;

export const getStakeholderContactState = (state: RootState) => {
	return state.createClientReducers.stakeholderContact;
};
