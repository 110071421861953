import { BenchmarkConfigurationId } from 'store/api';
import { TableAssignHeader } from 'common/components';
import React from 'react';
import { OnAssignationChangeCallback } from 'features/Tables/hooks';
import { useAssignParticipantsMutation, useUnAssignParticipantsMutation } from 'store/api';
import { useSuspenseManually } from 'common/hooks';
import { ProjectId } from 'common/types';
import { translateBenchmarkGroup } from 'store/api/benchmarkConfigurationApi/BenchmarkGroupTranslator';

export type BenchmarkHeaderProps = {
	projectId: ProjectId | undefined;
	filters: { [key: string]: string };
	benchmarkId: BenchmarkConfigurationId | undefined;
	benchmarkGroup: string;
	onAssignationChange: OnAssignationChangeCallback;
};

export const BenchmarkHeader = ({
	projectId,
	filters,
	benchmarkId,
	benchmarkGroup,
	onAssignationChange,
}: BenchmarkHeaderProps) => {
	const [assign] = useAssignParticipantsMutation();
	const [unAssign] = useUnAssignParticipantsMutation();
	const suspense = useSuspenseManually();
	return (
		<TableAssignHeader
			key={benchmarkGroup}
			title={benchmarkGroup}
			selected={false}
			onAssignAllClick={(e) => onAssign(e)}
			onRemoveAllClick={(e) => onUnAssign(e)}
		/>
	);

	function onAssign(event: React.MouseEvent) {
		event.stopPropagation();
		const request = createRequest();
		if (!request) return;
		suspense.turnOn();
		assign(request).then(onAssignationChange);
	}
	function onUnAssign(event: React.MouseEvent) {
		event.stopPropagation();
		const request = createRequest();
		if (!request) return;
		suspense.turnOn();
		unAssign(request).then(onAssignationChange);
	}

	function createRequest() {
		if (!benchmarkId) return;

		return {
			projectId: projectId,
			benchmarkConfigurationId: benchmarkId,
			benchmarkGroup: [translateBenchmarkGroup(benchmarkGroup)],
			pageParams: { filters: filters },
		};
	}
};
