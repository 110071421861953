import React from 'react';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { TableAction, Framework } from 'common/types';
import { FrameworkColumnId } from 'features/Tables/hooks';
import { Tooltip } from 'common/components';
import { faGear, faTrashCan, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useActivateFrameworkMutation } from 'store/api';
import { NotificationTypes, useNotifications } from 'common/components/Notifications';
import { TableIconButton, TableWrapper } from 'features/Tables/components';

export const useClusterConfigurationData = (setModalState: React.Dispatch<React.SetStateAction<boolean>>) => {
	const [frameworkToDelete, setFrameworkNeedToDelete] = useState({
		frameworkDataId: '',
		frameworkName: '',
	});
	const [activateFramework] = useActivateFrameworkMutation();
	const { pushNotification } = useNotifications();

	const onDeleteClick = (row: Framework) => {
		setModalState(true);
		setFrameworkNeedToDelete({
			frameworkDataId: row.frameworkId,
			frameworkName: row.name,
		});
	};

	const onActivateClick = async (row: Framework) => {
		try {
			await activateFramework({ frameworkId: row.frameworkId }).unwrap();
			pushNotification({
				message: 'Framework activated successfully',
				type: NotificationTypes.Success,
			});
		} catch {
			pushNotification({
				message: 'Something went wrong during last operation!',
				type: NotificationTypes.Error,
			});
		}
	};

	const whenFrameworkIsActive = (framework: Framework) => framework.active;
	const actions = React.useMemo<TableAction<Framework>[]>(
		() => [
			{
				label: 'Run framework',
				icon: faPlay,
				onClick: async (framework) => {
					framework !== undefined && onActivateClick(framework);
				},
				isHidden: whenFrameworkIsActive,
			},
			{
				label: 'Edit framework',
				icon: faGear,
				getHref: (framework: Framework) => `/clusterConfiguration/${framework.frameworkId}/details`,
			},
			{
				label: 'Delete framework',
				icon: faTrashCan,
				onClick: (framework) => {
					framework !== undefined && onDeleteClick(framework);
				},
				isHidden: whenFrameworkIsActive,
			},
		],
		[],
	);

	const columns = React.useMemo<ColumnDef<Framework>[]>(
		() => [
			{
				id: FrameworkColumnId.FrameworkId,
				header: 'Framework Name',
				accessorKey: 'frameworkId',
				accessorFn: (row: Framework) => {
					return row.name;
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: FrameworkColumnId.Description,
				header: 'Description',
				accessorKey: 'description',
				accessorFn: (row: Framework) => {
					return row.description;
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: FrameworkColumnId.Status,
				header: 'Status',
				accessorKey: 'status',
				accessorFn: (row: Framework) => {
					return row.active ? 'ACTIVE' : 'INACTIVE';
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
			{
				id: FrameworkColumnId.Actions,
				header: 'Actions',
				accessorFn: (row) => row,
				cell: ({ getValue }) => {
					const framework = getValue<Framework>();
					return (
						<TableWrapper>
							{actions.map((action) => {
								if (action.isHidden?.(framework)) return;
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											href={action.getHref ? action.getHref(framework) : undefined}
											onClick={() => {
												action.onClick && action.onClick(framework);
											}}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
		],
		[],
	);

	return { columns, frameworkToDelete };
};
