import { ResponseError } from 'common/types';

export const convertObjectToQueryParams = (paramsObj: Record<string, unknown>) =>
	Object.entries(paramsObj)
		.map(([key, value]) => {
			if (value === undefined) return;

			if (Array.isArray(value)) {
				const arrayKey = key;

				return value
					.map((arrayItem) => {
						const arrayItemKey = Object.keys(arrayItem);
						const arrayItemValue = Object.values(arrayItem);
						return `${arrayKey}${encodeURI(`[${arrayItemKey}]`)}=${encodeURI(String(arrayItemValue))}`;
					})
					.join('&');
			}

			return `${encodeURI(key)}=${encodeURI(String(value))}`;
		})
		.join('&');

export const isResponseError = (data: unknown): data is ResponseError => {
	return (
		typeof data === 'object' &&
		data !== null &&
		'error' in data &&
		'exception' in data &&
		'message' in data &&
		'path' in data &&
		'status' in data &&
		'timestamp' in data
	);
};
