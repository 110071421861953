import { QuadrantInput } from 'features/DiscoveryInsights/components';
import { QuadrantInputValidator } from './QuadrantInputValidator';

export class QuadrantInputFormatter {
	constructor(private _defaultValue: number) {}

	public format(input: QuadrantInput): QuadrantInput {
		const validInput = this._validate(input);
		return this._format(validInput);
	}

	private _format(input: QuadrantInput): QuadrantInput {
		const rounded = this._whenLastDigitNotAcceptableThenRoundDownToZero(input);
		const withoutComma = this._replaceCommaWithDot(rounded);
		return this._makeSureThatInputHasSpecifiedFloatingAccuracy(withoutComma);
	}

	private _validate(input: QuadrantInput): QuadrantInput {
		const inputIsInvalid = !new QuadrantInputValidator().validate(input);
		if (inputIsInvalid) {
			return this._defaultValue.toString();
		}
		return input;
	}

	private _whenLastDigitNotAcceptableThenRoundDownToZero(value: QuadrantInput): QuadrantInput {
		const lastDigit = this._getLastDigit(value);
		const acceptableLastDigit = 5;
		if (lastDigit != acceptableLastDigit) {
			return this._withoutLastDigit(value);
		} else {
			return value.toString();
		}
	}

	private _replaceCommaWithDot(text: QuadrantInput) {
		return text.replace(',', '.');
	}

	private _makeSureThatInputHasSpecifiedFloatingAccuracy(input: QuadrantInput) {
		const numberOfDigits = 2;
		return Number(input).toFixed(numberOfDigits);
	}

	private _withoutLastDigit(value: QuadrantInput): QuadrantInput {
		const first = 0;
		const last = 3;
		return value.toString().substring(first, last);
	}

	private _getLastDigit(value: QuadrantInput) {
		const lastDigitIndex = 3;
		return Number(value.toString().split('')[lastDigitIndex]);
	}
}
