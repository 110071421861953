import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { NotificationTypes, useNotifications } from 'common/components';
import { HttpResponseStatusCodes } from 'common/types';
import {
	useImportParticipantsDataValidation,
	useImportParticipantsFormData,
} from 'features/ImportParticipants/components/hooks';
import { ImportParticipantsLayout } from 'features/ImportParticipants/views';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useImportParticipantsCsvMutation } from 'store/api';

export const ImportParticipants = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const { validateForm } = useImportParticipantsDataValidation();
	const { getImportParticipantsFormData } = useImportParticipantsFormData();
	const navigate = useNavigate();
	const { pushNotification } = useNotifications();

	const [importParticipants] = useImportParticipantsCsvMutation();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);

		const formData = await getImportParticipantsFormData();

		const validate = () => validateForm(formData);
		const isFormValid = await validate();

		if (!isFormValid) return;

		try {
			await importParticipants({
				file: formData.file as File,
				filename: formData.filename as string,
			}).unwrap();

			pushNotification({ message: 'Import completed.', type: NotificationTypes.Success });
			navigate('/assessees');
		} catch (error) {
			const errorObj = error as FetchBaseQueryError;

			if (errorObj.status === HttpResponseStatusCodes.BAD_REQUEST) {
				const errorMessage = errorObj.data as string;
				pushNotification({ message: errorMessage, type: NotificationTypes.Error });
			}
		}
	};

	return (
		<ImportParticipantsLayout
			id="import-participants"
			title="Import Participants"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
