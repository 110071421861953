import {
	AssignmentParticipantsResponse,
	useLazyGetAssignmentParticipantsByProjectIdQuery,
	AssignmentStatusByProjectIdOptions,
} from 'store/api';
import { ApiAssignmentParticipants, AssignmentParticipants } from 'common/types';
import { transformAssignParticipantsResponse } from './mappers';
import { getAssignmentParticipantsEmptyResponse } from 'store/api';
import { useCriteriaConfiguration } from '../participants/useCriteriaConfiguration';

export const useAssignParticipants = () => {
	const criteriaConfiguration = useCriteriaConfiguration();
	const emptyResponse = getAssignmentParticipantsEmptyResponse();

	const [getAssignmentParticipantsByProjectId, assignmentParticipantsByProjectId] =
		useLazyGetAssignmentParticipantsByProjectIdQuery();

	const assignmentParticipants = getAssignmentParticipantsData();

	return {
		getAssignmentParticipants,
		assignmentParticipants: transform(assignmentParticipants),
	};

	function getAssignmentParticipantsData(): AssignmentParticipantsResponse<ApiAssignmentParticipants> {
		if (!assignmentParticipantsByProjectId.isSuccess) return emptyResponse;

		return assignmentParticipantsByProjectId.data;
	}

	async function getAssignmentParticipants(
		options: AssignmentStatusByProjectIdOptions,
	): Promise<AssignmentParticipantsResponse<ApiAssignmentParticipants>> {
		const response = await getAssignmentParticipantsByProjectId(options);

		if (!response || response.data === undefined) {
			return emptyResponse;
		}

		return response.data;
	}

	function transform(
		assignmentParticipants: AssignmentParticipantsResponse<ApiAssignmentParticipants>,
	): AssignmentParticipantsResponse<AssignmentParticipants> {
		return transformAssignParticipantsResponse(assignmentParticipants, criteriaConfiguration);
	}
};
