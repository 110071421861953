import { combineReducers } from '@reduxjs/toolkit';
import { additionalQuestionsSliceReducer } from './additionalQuestionsSlice';
import { automaticClosureConditionsReducer } from './automaticClosureConditionsSlice';
import { projectDetailsSliceReducer } from './projectDetailsSlice';

export const createProjectReducers = combineReducers({
	projectDetails: projectDetailsSliceReducer,
	automaticClosureConditions: automaticClosureConditionsReducer,
	additionalQuestions: additionalQuestionsSliceReducer,
});
