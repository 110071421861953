import { getReducedTenantsListResponse, useGetReducedTenantsListQuery } from 'store/api';
import { mapGetReducedTenantsListResponse } from './mappers';

export const useReducedTenants = () => {
	const reducedTenants = useGetReducedTenantsListQuery();
	const emptyResponse = getReducedTenantsListResponse();

	const dataToTransform = reducedTenants.isSuccess ? reducedTenants.data : emptyResponse;
	return {
		reducedTenants: mapGetReducedTenantsListResponse(dataToTransform),
		areReducedTenantsFetched: reducedTenants.isSuccess,
	};
};
