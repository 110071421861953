import { BenchmarksNamesKey, GraphDnaSettings } from 'store/index';

export const getPersonalBenchmarkSelection = (settings: Record<BenchmarksNamesKey, GraphDnaSettings>) => {
	return [
		settings.benchmark1.personal.selected,
		settings.benchmark2.personal.selected,
		settings.benchmark3.personal.selected,
		settings.benchmark4.personal.selected,
		settings.benchmark5.personal.selected,
	];
};

export const getOrganisationalBenchmarkSelection = (settings: Record<BenchmarksNamesKey, GraphDnaSettings>) => {
	return [
		settings.benchmark1.organisational.selected,
		settings.benchmark2.organisational.selected,
		settings.benchmark3.organisational.selected,
		settings.benchmark4.organisational.selected,
		settings.benchmark5.organisational.selected,
	];
};

export const getPnoBenchmarkSelection = (settings: Record<BenchmarksNamesKey, GraphDnaSettings>) => {
	return [
		settings.benchmark1.pno.selected,
		settings.benchmark2.pno.selected,
		settings.benchmark3.pno.selected,
		settings.benchmark4.pno.selected,
		settings.benchmark5.pno.selected,
	];
};
