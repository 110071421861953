import { PredictiveResult } from 'store/api/predictiveApi';
import { useOddsRatioCreator } from 'features/Graphs/OddsRatioGraph';
import { useExportPredictiveExportMutation } from 'store/api';
import { downloadFile } from 'common/utils';
import { OddsRatioConfig } from 'features/Graphs/OddsRatioGraph/hooks/useOddsRatioCreator.types';

export const usePredictivePPTExport = () => {
	const [exportGraphs] = useExportPredictiveExportMutation();
	return (data: PredictiveResult, config: OddsRatioConfig) => {
		const personalGraph = useOddsRatioCreator(data, config).buildPersonalGraph();
		const organisationalGraph = useOddsRatioCreator(data, config).buildOrganisationalGraph();

		const timestampConversion = 1000;
		const timestamp: number = Date.now() / timestampConversion;

		const request = {
			timestamp,
			verticalGraphs: {
				verticalBarWithAllOrganisationBenchmarks: organisationalGraph.getExportedSVG(),
				verticalBarWithAllPersonalBenchmarks: personalGraph.getExportedSVG(),
			},
		};
		exportGraphs(request)
			.unwrap()
			.then((file) => {
				downloadFile(file.objectUrl, file.filename);
			});
	};
};
