import { CreateParticipantBody, useLazyCheckParticipantEmailAvailabilityQuery } from 'store/api';
import { RegexPatterns } from 'common/constants';

export type ParticipantFormValidationProperties = { shouldCheckForParticipantEmailUniqueness: boolean };

export const useParticipantFormValidation = () => {
	const [validateAvailability] = useLazyCheckParticipantEmailAvailabilityQuery();

	const validateForm = async (data: CreateParticipantBody, props?: ParticipantFormValidationProperties) => {
		let isEmailAvailable = true;
		if (props?.shouldCheckForParticipantEmailUniqueness) {
			const emailAddress = await validateAvailability({ assesseeEmail: data.emailAddress }).unwrap();
			isEmailAvailable = emailAddress.available;
		}

		return [
			shouldBeProperEmail(data.emailAddress),
			required(data.firstName),
			required(data.lastName),
			isEmailAvailable,
		].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function shouldBeProperEmail(data: string) {
		return required(data) && !!data.match(RegexPatterns.Email);
	}

	function required(data: string) {
		const normalizedData = data.replace(RegexPatterns.AllWhitespaceChars, '');

		return normalizedData != null && !!normalizedData.length;
	}
};
