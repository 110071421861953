import * as React from 'react';

type CallbackFunction = (time: number) => void;

function useInterval(callback: CallbackFunction, timeout: number) {
	const passedCallback = React.useRef(callback);

	const handler = React.useCallback(() => passedCallback.current(new Date().getTime()), []);

	// Store latest callback
	React.useEffect(() => {
		passedCallback.current = callback;
	}, [callback]);

	// Set up/teardown the interval
	React.useEffect(() => {
		if (timeout) {
			const id = window.setInterval(handler, timeout);
			return () => window.clearInterval(id);
		}
	}, [handler, timeout]);
}

export default useInterval;
