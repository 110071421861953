import { PageLayout } from 'common/components';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { ImportParticipantsLayoutProps } from './ImportParticipantsLayout.types';
import { ParticipantsFileForm } from 'features/ImportParticipants/components';

export const ImportParticipantsLayout = ({
	id,
	title,
	subtitle,
	onSaveButtonClick,
	saveTrigger,
}: ImportParticipantsLayoutProps) => {
	return (
		<PageLayout
			title={title}
			subtitle={subtitle}
			id={id}
		>
			<div>
				<ParticipantsFileForm saveTrigger={saveTrigger} />
				<SaveButtonContainer>
					<ButtonWrapper>
						<StyledButton onClick={() => onSaveButtonClick()}>Save</StyledButton>
					</ButtonWrapper>
				</SaveButtonContainer>
			</div>
		</PageLayout>
	);
};
