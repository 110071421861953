import { ZIndexes } from 'common/constants';
import styled, { css } from 'styled-components';
import { NotificationTypes, StyledNotificationProps } from './Notification.types';

const errorType = css`
	color: ${(props) => props.theme.colors.common.white};
	background-color: ${(props) => props.theme.colors.primary.main};
`;

const warningType = css`
	color: ${(props) => props.theme.colors.common.white};
	background-color: ${(props) => props.theme.colors.common.darkGrey};
`;

const infoType = css`
	color: ${(props) => props.theme.colors.common.white};
	background-color: ${(props) => props.theme.colors.tertiary.main};
`;

const successType = css`
	color: ${(props) => props.theme.colors.common.black};
	background-color: ${(props) => props.theme.colors.common.white};
	border: 2px solid ${(props) => props.theme.colors.primary.main};
`;

export const StyledNotificationContainer = styled.div`
	width: 15%;
	height: fit-content;
	max-height: 90%;
	overflow: hidden;

	z-index: ${ZIndexes.notificationContainer};

	position: absolute;

	top: 5%;
	right: 5%;

	overflow: hidden;
`;

export const StyledNotification = styled.pre<StyledNotificationProps>`
	width: 100%;
	height: fit-content;
	padding: 1.5rem;
	border-radius: 15px;
	margin-bottom: 1rem;
	font-family: 'Mukta';

	white-space: pre-wrap;

	z-index: ${ZIndexes.notification};

	${({ $type }) => $type === NotificationTypes.Error && errorType};
	${({ $type }) => $type === NotificationTypes.Warning && warningType};
	${({ $type }) => $type === NotificationTypes.Info && infoType};
	${({ $type }) => $type === NotificationTypes.Success && successType};
`;
