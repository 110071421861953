import { OddsRatio } from 'store/api/predictiveApi';
import { OddsRatioDescription } from 'features/Graphs/OddsRatioGraph';
import { IDescription } from '@support/alderbrooke-d3-graphs/dist/VerticalBar/IChartData';

export class GraphDescriptionFactory {
	public createDescription(data: OddsRatio, description: OddsRatioDescription): IDescription {
		const nAmount = `(n = ${data.participantsSelected})`;
		const rounding = 4;
		const r = data.multipleR ? data.multipleR.toFixed(rounding) : '0.0000';
		return {
			title: description.title,
			subtitle: `${description.subtitle} ${nAmount}`,
			subtitle2: data.behaviours,
			multipleR: `Multiple R=${r}`,
		};
	}
}
