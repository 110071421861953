import { ApiDimension, ApiDimensionLegend, Dimension, DimensionsColorMap, DimensionPair } from 'common/types';
import { getCapitalizedText } from 'common/utils';
import { AllDimensionsResponse, DimensionsLegendResponse } from 'store/api';

const getDimensionKeyPattern = (dimensionsLength: number, currentIndex: number): string => {
	const dimensionKeyNumber = dimensionsLength - currentIndex;
	const oneDigitNumberLimit = 10;
	const isDigit = dimensionKeyNumber < oneDigitNumberLimit;
	if (isDigit) {
		return `D0${dimensionKeyNumber}`;
	}

	return `D${dimensionKeyNumber}`;
};

export const mapGetAllDimensionsResponse = (
	response: AllDimensionsResponse<ApiDimension>,
	apiDimensionsLegend: DimensionsLegendResponse<ApiDimensionLegend> | undefined,
	dimensionColorMap: DimensionsColorMap | undefined,
): AllDimensionsResponse<Dimension> => {
	const apiDimensions = response;
	const mappedDimensions: DimensionPair<Dimension>[] = apiDimensions.map((apiDimension, index) => {
		const { contrary, primary } = apiDimension;
		const dimensionKeyPattern = getDimensionKeyPattern(apiDimensions.length, index);

		return {
			primary: {
				dimensionId: primary.id,
				contraryDimensionId: primary.contraryId,
				name: getCapitalizedText(primary.name),
				isPrimary: primary.primary,
				dimensionKey: `${dimensionKeyPattern}A`,
				legendText: apiDimensionsLegend
					? apiDimensionsLegend.legendsByDimensionKey[`${dimensionKeyPattern}A`]
					: undefined,
				color: dimensionColorMap ? `#${dimensionColorMap[`${dimensionKeyPattern}A`]}` : undefined,
			},
			contrary: {
				dimensionId: contrary.id,
				contraryDimensionId: contrary.contraryId,
				name: getCapitalizedText(contrary.name),
				isPrimary: contrary.primary,
				dimensionKey: `${dimensionKeyPattern}B`,
				legendText: apiDimensionsLegend
					? apiDimensionsLegend.legendsByDimensionKey[`${dimensionKeyPattern}B`]
					: undefined,
				color: dimensionColorMap ? `#${dimensionColorMap[`${dimensionKeyPattern}B`]}` : undefined,
			},
		};
	});

	return mappedDimensions.reverse();
};
