import { ApiFramework, Framework } from 'common/types';
import { AllFrameworksResponse, getAllFrameworksEmptyResponse } from 'store/api';
import { useGetAllFrameworksQuery } from 'store/api';
import { mapGetAllFrameworkResponse } from './mappers';

export const useFrameworks = () => {
	const allFrameworks = useGetAllFrameworksQuery();
	const emptyResponse = getAllFrameworksEmptyResponse();
	const apiFrameworks = getApiFrameworksData();

	function getApiFrameworksData(): AllFrameworksResponse<ApiFramework> {
		if (!allFrameworks.isSuccess) return emptyResponse;

		return allFrameworks.data;
	}

	function transform(apiFrameworks: AllFrameworksResponse<ApiFramework>): AllFrameworksResponse<Framework> {
		return mapGetAllFrameworkResponse(apiFrameworks);
	}

	return { frameworks: transform(apiFrameworks), areFrameworksFetched: allFrameworks.isSuccess };
};
