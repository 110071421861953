import IChartCfg from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartCfg';
/* eslint-disable no-magic-numbers */
export const useQuadrantChartConfig = (): IChartCfg => {
	return {
		axisX: {
			domain: [0, 5],
			ticks: 5,
			lineStyle: { stroke: 'rgb(89, 89, 89)', fill: 'none', 'fill-opacity': '0.75', 'stroke-width': '1px' },
			textStyle: { 'font-size': '10px', 'font-family': 'Arial, "Liberation Sans"' },
		},
		axisY: {
			domain: [5, 0],
			ticks: 5,
			lineStyle: { stroke: 'rgb(89, 89, 89)', fill: 'none', 'fill-opacity': '0.75', 'stroke-width': '1px' },
			textStyle: { 'font-size': '10px', 'font-family': 'Arial, "Liberation Sans"' },
		},
		canvas: {
			margins: {
				left: 50,
				right: 20,
				bottom: 55,
				top: 50,
			},
			width: 1200,
			height: 500,
			opacity: 1,
		},
		dots: {
			opacity: 0.8,
		},

		graphTitle: 'QUADRANT DISPERSION FOR PERSONAL VS ORGANISATIONAL BEHAVIOURS',
	};
};
