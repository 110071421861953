import { useGetRoadmapsQuery } from 'store/api/roadmapApi/roadmapApi';
import { ApiRoadmapSummary, RoadmapSummary } from 'store/api/roadmapApi/roadmapApi.types';

export const useRoadmaps = () => {
	const roadmaps = useGetRoadmapsQuery();
	const emptyResponse: ApiRoadmapSummary[] = [];
	const apiData = getApiData();

	function getApiData(): ApiRoadmapSummary[] {
		if (!roadmaps.isSuccess) return emptyResponse;

		return roadmaps.data;
	}

	function transform(roadmaps: ApiRoadmapSummary[]): RoadmapSummary[] {
		return roadmaps.map((roadmap) => ({ ...roadmap }));
	}

	return { data: transform(apiData), areRoadmapsFetched: roadmaps.isSuccess };
};
