export type DistributionTableColorPreset = {
	$firstColumnColor: string;
	$firstTextColor: string;

	$secondColumnColor: string;
	$secondTextColor: string;

	$thirdColumnColor: string;
	$thirdTextColor: string;

	$fourthColumnColor: string;
	$fourthTextColor: string;

	$fifthColumnColor: string;
	$fifthTextColor: string;
};

export type ScrollableContainerProps = {
	$shouldScroll: boolean;
};

export const DistributionTableColumnId = {
	Category: 'category',
	First: 'first',
	Second: 'second',
	Third: 'third',
	Fourth: 'fourth',
	Fifth: 'fifth',
	Total: 'total',
} as const;
