import { HeatmapGraphProps } from './HeatmapGraph.types';
import { useHeatmapCreator } from 'features/Graphs/HeatmapGraph/hooks/useHeatmapCreator';
import { GraphContainerWithTooltips } from '../common/components';
import { useDimensions } from 'common/hooks';

export const HeatmapGraph = ({ containerId, config, data, colorConfig }: HeatmapGraphProps) => {
	const { dimensions } = useDimensions();
	const buildGraph = useHeatmapCreator(config, data, colorConfig).createGraph;

	return (
		<GraphContainerWithTooltips
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
			dimensions={dimensions}
		/>
	);
};
