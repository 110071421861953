import { ChartSettingsOptionItem } from 'features/Simulation/components/settings';
import { COLOR_PRESET_IDS } from 'features/Simulation/components/settings/ChartSettingsOptions/constants';
import { ColorPresetsProps } from 'features/DiscoveryInsights/components';
import { OptionsContainer } from './styled';

export const ColorPresets = ({ colorPreset, onColorPresetChange }: ColorPresetsProps) => {
	return (
		<OptionsContainer>
			<ChartSettingsOptionItem
				checkButtonType="radio"
				checked={colorPreset === COLOR_PRESET_IDS.one}
				onClick={() => onColorPresetChange(COLOR_PRESET_IDS.one)}
			>
				Colour scale Preset 1
			</ChartSettingsOptionItem>
			<ChartSettingsOptionItem
				checkButtonType="radio"
				checked={colorPreset === COLOR_PRESET_IDS.two}
				onClick={() => onColorPresetChange(COLOR_PRESET_IDS.two)}
			>
				Colour scale Preset 2
			</ChartSettingsOptionItem>
			<ChartSettingsOptionItem
				checkButtonType="radio"
				checked={colorPreset === COLOR_PRESET_IDS.three}
				onClick={() => onColorPresetChange(COLOR_PRESET_IDS.three)}
			>
				Colour scale Preset 3 (Colour-blind friendly)
			</ChartSettingsOptionItem>
		</OptionsContainer>
	);
};
