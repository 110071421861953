import { Cluster } from 'common/types';
import { TensionGraphClusterExport } from './useTensionGraphClusterExportService.types';
import { TensionGraphRequest, TensionGraphSVGFactory } from 'features/Graphs/TensionGraph';

export function useCreateExport(request: TensionGraphRequest, tensionGraphSVGFactory: TensionGraphSVGFactory) {
	return createExport;

	function createExport(cluster: Cluster): TensionGraphClusterExport {
		const clusterId = cluster.clusterId.replace('-', '');
		const legendConfiguration = {
			enabled: true,
			onChange: () => null,
			position: createChartPosition(),
			toggleVisible: false,
		};
		const chartContent = tensionGraphSVGFactory({
			...request,
			clusterId,
			legend: legendConfiguration,
		});
		chartContent.dispose();
		return {
			title: createTitle(cluster),
			content: [
				{
					svg: { content: chartContent.getSvgAsXml() },
					dimensions: createExportDimensions(),
				},
			],
		};
	}

	function createChartPosition() {
		return { x: 10, y: 520 };
	}

	function createTitle(cluster: Cluster) {
		const benchmarkAlias = request.benchmarkAlias;
		return `${cluster.name} ${benchmarkAlias}`;
	}

	function createExportDimensions() {
		return {
			x: 20,
			y: 100,
			width: 1000,
			height: 800,
		};
	}
}
