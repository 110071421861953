import { CommonColorPresets } from 'features/Graphs/colorPresets';
import { PieChart } from '../../PieChart';
import { OdnaPieChartProps } from './OdnaPieChart.types';
import { pieChartConfig } from '../../PieChartConfig';
import { useOdnaPieChartData } from 'features/Graphs/PieChart';
import { useSelector } from 'react-redux';
import { selectPieChartCombinedODNA, selectPieChartODNA } from 'store/slices';

export const OdnaPieChart = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
	colorPresetIndex,
}: OdnaPieChartProps) => {
	const colorPreset = CommonColorPresets[colorPresetIndex];
	const config = pieChartConfig;

	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const odna = combinedMode ? useSelector(selectPieChartCombinedODNA) : useSelector(selectPieChartODNA);
	const pieData = useOdnaPieChartData(odna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<PieChart
			containerId={containerId}
			colorConfig={colorPreset}
			config={config}
			data={pieData}
		/>
	);
};
