import {
	selectChartSettings,
	setChartSettingsIncludeClusters,
	setChartSettingsPreset,
	setChartSettingsShowOneGraph,
	toggleChartSettingsView,
} from 'store/slices';
import { OptionsContainer, ActionButtonsContainer, CloseButton, SaveCloseButton, SaveButtonsWrapper } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { ChartSettingsOptionItem } from '../ChartSettingsOptionItem';
import { ChartSettingsOptionsProps } from './ChartSettingsOptions.types';
import { useState } from 'react';
import { COLOR_PRESET_IDS } from './constants';
import { PptxExportButton } from 'features/Graphs/PptxExportButton';

export const ChartSettingsOptions = ({ onClose, onSaveAndClose }: ChartSettingsOptionsProps) => {
	const chartSettings = useSelector(selectChartSettings);
	const [showDataOnOneGraph, setShowDataOnOneGraph] = useState(chartSettings.showDataOnOneGraph);
	const [includeClusters, setIncludeClusters] = useState(chartSettings.includeClusters);
	const [preset, setPreset] = useState(chartSettings.preset);
	const dispatch = useDispatch();

	const onCloseButtonClick = () => {
		onClose();
		save();
		dispatch(toggleChartSettingsView(false));
	};

	const onCloseAndSaveButtonClick = () => {
		onSaveAndClose();
		save();
		dispatch(toggleChartSettingsView(false));
	};

	function save() {
		dispatch(setChartSettingsShowOneGraph(showDataOnOneGraph));
		dispatch(setChartSettingsIncludeClusters(includeClusters));
		dispatch(setChartSettingsPreset(preset));
	}

	function saveSettingsBeforeDownload() {
		save();
		onClose();
	}

	return (
		<>
			<OptionsContainer>
				<ChartSettingsOptionItem
					checkButtonType="checkbox"
					checked={showDataOnOneGraph}
					onClick={() => setShowDataOnOneGraph((showDataOnOneGraph) => !showDataOnOneGraph)}
				>
					Show PDNA and ODNA data in one graph
				</ChartSettingsOptionItem>
				<ChartSettingsOptionItem
					checkButtonType="checkbox"
					checked={includeClusters}
					onClick={() => setIncludeClusters((includeClusters) => !includeClusters)}
				>
					Include Clusters
				</ChartSettingsOptionItem>
				<ChartSettingsOptionItem
					checkButtonType="radio"
					checked={preset === COLOR_PRESET_IDS.one}
					onClick={() => setPreset(COLOR_PRESET_IDS.one)}
				>
					Colour scale Preset 1
				</ChartSettingsOptionItem>
				<ChartSettingsOptionItem
					checkButtonType="radio"
					checked={preset === COLOR_PRESET_IDS.two}
					onClick={() => setPreset(COLOR_PRESET_IDS.two)}
				>
					Colour scale Preset 2
				</ChartSettingsOptionItem>
				<ChartSettingsOptionItem
					checkButtonType="radio"
					checked={preset === COLOR_PRESET_IDS.three}
					onClick={() => setPreset(COLOR_PRESET_IDS.three)}
				>
					Colour scale Preset 3 (Colour-blind friendly)
				</ChartSettingsOptionItem>
			</OptionsContainer>
			<ActionButtonsContainer>
				<PptxExportButton onBeforeClick={saveSettingsBeforeDownload} />
				<SaveButtonsWrapper>
					<CloseButton onClick={onCloseButtonClick}>Close</CloseButton>
					<SaveCloseButton
						onClick={onCloseAndSaveButtonClick}
						variant="outlined"
					>
						Save & Close
					</SaveCloseButton>
				</SaveButtonsWrapper>
			</ActionButtonsContainer>
		</>
	);
};
