import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCheckTenantIdAvailabilityMutation } from 'store/api';
import { OrganisationInformationState, setOrganisationInformationState } from 'store/slices';
import {
	handleRequiredFormTelephoneBlur,
	handleRequiredFormValue,
	createFormState,
	handleFormMailBlur,
} from 'common/utils';
import { OrganisationInformationProps } from './OrganisationInformation.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const OrganisationInformation = ({
	saveTrigger,
	organizationName: initialOrganizationName,
	organisationId: initialOrganizationId,
	purchaseOrderReference: initialPurchaseOrderReference,
	supplierAgreementReference: initialSupplierAgreementReference,
	telephoneNumberOnSwitchboard: initialTelephoneNumberOnSwitchboard,
	projectSenderEmailAddress: initialProjectSenderEmailAddress,
	disableOrganisationId,
	disableOrganisationName,
	disablePurchaseOrderReference,
	disableSupplierAgreementReference,
}: OrganisationInformationProps) => {
	const [purchaseOrderReference, setPurchaseOrderReference] = useState(
		emptyIfNullOrUndefined(initialPurchaseOrderReference),
	);
	const [supplierAgreementReference, setSupplierAgreementReference] = useState(
		emptyIfNullOrUndefined(initialSupplierAgreementReference),
	);
	const [validateAvailability] = useCheckTenantIdAvailabilityMutation();

	const [organisationName, setOrganisationName] = useState<RequiredFormInputState>(
		createFormState(initialOrganizationName),
	);

	const [organisationId, setOrganisationId] = useState<RequiredFormInputState>(
		createFormState(initialOrganizationId),
	);

	const [telephoneNumber, setTelephoneNumber] = useState<RequiredFormInputState>(
		createFormState(initialTelephoneNumberOnSwitchboard),
	);

	const [emailAddress, setEmailAddress] = useState<RequiredFormInputState>(
		createFormState(initialProjectSenderEmailAddress),
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(organisationName.value, 'Tenant name is required!', setOrganisationName);
		handleRequiredFormValue(organisationId.value, 'Organisation ID is required!', setOrganisationId);
		handleRequiredFormTelephoneBlur(
			telephoneNumber.value,
			'Telephone number is required!',
			'Telephone number is not in correct format!',
			setTelephoneNumber,
		);

		validateOrganisationIdAvailability();
	};

	const validateOrganisationIdAvailability = () => {
		if (disableOrganisationId) {
			return;
		}

		if (organisationId.value) {
			validateAvailability({ tenantShortId: organisationId.value })
				.unwrap()
				.then((success) => {
					setOrganisationId({
						value: organisationId.value,
						error: !success.available,
						errorText: 'Organisation ID already exists!',
					});
				});
		}
	};

	const setSliceState = () => {
		const sliceState: OrganisationInformationState = {
			emailAddress: emailAddress.value,
			organisationId: organisationId.value,
			organisationName: organisationName.value,
			purchaseOrderReference: purchaseOrderReference,
			supplierAgreementReference: supplierAgreementReference,
			telephoneNumber: telephoneNumber.value,
		};

		dispatch(setOrganisationInformationState(sliceState));
	};

	return (
		<>
			<Input
				{...organisationName}
				name="Name of organisation"
				placeholder="Name of legal entity"
				maxLength={50}
				errorPlacement="right"
				disabled={disableOrganisationName}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Tenant name is required!', setOrganisationName);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Tenant name is required!', setOrganisationName);
					setSliceState();
				}}
			/>
			<Input
				{...organisationId}
				name="Organisation ID"
				placeholder="Max 10 characters"
				maxLength={10}
				errorPlacement="right"
				disabled={disableOrganisationId}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Organisation ID is required!', setOrganisationId);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Organisation ID is required!', setOrganisationId);
					validateOrganisationIdAvailability();
					setSliceState();
				}}
			/>
			<Input
				name="Purchase Order reference"
				maxLength={30}
				errorPlacement="right"
				value={purchaseOrderReference}
				disabled={disablePurchaseOrderReference}
				formInput
				onChange={(event) => {
					setPurchaseOrderReference(event.target.value);
				}}
				onBlur={(event) => {
					setPurchaseOrderReference(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				name="Supplier agreement reference"
				maxLength={30}
				value={supplierAgreementReference}
				disabled={disableSupplierAgreementReference}
				formInput
				onChange={(event) => {
					setSupplierAgreementReference(event.target.value);
				}}
				onBlur={(event) => {
					setSupplierAgreementReference(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				{...telephoneNumber}
				name="Telephone number of switchboard"
				placeholder="Please use international format"
				maxLength={30}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Telephone number is required!', setTelephoneNumber);
				}}
				onBlur={(event) => {
					handleRequiredFormTelephoneBlur(
						event.target.value,
						'Telephone number is required!',
						'Telephone number is not in correct format!',
						setTelephoneNumber,
					);
					setSliceState();
				}}
			/>
			<Input
				{...emailAddress}
				name="Project sender email address"
				placeholder="This address will be used for Invitation/Reminder/Send Report emails as FROM address"
				maxLength={50}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setEmailAddress({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormMailBlur(
						event.target.value,
						'E-mail address does not have correct format!',
						setEmailAddress,
					);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
