import { NotificationTypes, useNotifications } from 'common/components';
import { Navigate } from 'react-router-dom';

export const NotFound = () => {
	const { pushNotification } = useNotifications();

	pushNotification({
		message: 'Page not found',
		type: NotificationTypes.Error,
	});
	return <Navigate to="/projects" />;
};
