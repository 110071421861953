import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StyledIconButtonProps, StyledIconButtonLinkProps } from './IconButton.types';

const primaryColor = css`
	color: ${(props) => props.theme.colors.primary.main};
`;

const secondaryColor = css`
	color: ${(props) => props.theme.colors.secondary.main};
`;

const disabledClass = css`
	cursor: default;
	pointer-events: none;
	color: ${({ theme }) => theme.colors.disabled.main} !important;
`;

const commonStyles = css<StyledIconButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
	background-color: transparent;
	cursor: pointer;
	font-size: 1.5rem;
	border-radius: 50%;
	border: none;

	${({ $color }) => $color === 'secondary' && secondaryColor};
	${({ $color }) => $color === 'primary' && primaryColor};
	${({ disabled }) => disabled && disabledClass};
`;

export const StyledIconButton = styled.button<StyledIconButtonProps>`
	${commonStyles}
`;

export const StyledIconButtonLink = styled(Link)<StyledIconButtonLinkProps>`
	${commonStyles}
`;
