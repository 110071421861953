import styled from 'styled-components';
import { theme } from 'common/constants';

export const StyledPasswordConditionsBox = styled.div`
	width: 35%;
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	top: 50%;
	left: 18%;

	background: ${theme.colors.common.lightSilver};

	padding: 1%;
	padding-right: 4rem;
	padding-left: 1.5rem;
	transform: translate(-50%, -50%);

	border: 2px black solid;
	border-radius: 35px;
`;

export const ListHeader = styled.p`
	font-size: 1.5rem;
	margin-bottom: 0;
	margin-top: 0;
`;

export const ConditionsList = styled.ul`
	font-size: 1.5rem;
	margin: 0;
	padding-left: 1.65rem;
`;
