import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'common/components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledUserMenuDropdownProps } from './UserMenu.types';

export const UserMenuContainer = styled.div`
	z-index: 2;
	pointer-events: none;
`;

export const UserMenuButton = styled(Button)`
	width: 100%;
	text-transform: none;
	letter-spacing: normal;
	color: ${({ theme }) => theme.colors.text.secondary};
	font-weight: 300;
	font-size: 0.95rem;
	padding: 1rem 0;
	margin-top: auto;
	border-bottom: 1px solid ${({ theme }) => theme.colors.common.silver};
	border-top: 1px solid ${({ theme }) => theme.colors.common.silver};
	border-left: none;
	border-right: none;
	border-radius: 0%;
	background-color: ${({ theme }) => theme.colors.common.white};
	pointer-events: all;
`;

export const UsernameParagraph = styled.p`
	width: 60%;
	word-break: break-word;
`;

export const UserAvatarIcon = styled(FontAwesomeIcon)`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.secondary.medium};
`;

export const DropdownMenuIcon = styled(FontAwesomeIcon)`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.text.secondary};
`;

export const UserMenuDropdown = styled.ul<StyledUserMenuDropdownProps>`
	border-bottom: 2px solid ${({ theme }) => theme.colors.common.silver};
	border-top: 1px solid ${({ theme }) => theme.colors.common.silver};
	background-color: ${({ theme }) => theme.colors.common.lightSilver};
	list-style-type: none;
	padding: 0;
	margin: 0;
	pointer-events: all;

	visibility: ${({ $isHidden }) => $isHidden && 'hidden'};
`;

export const UserMenuDropdownItem = styled.li`
	margin: 0;
	padding: 0.25rem 0 0.25rem 1.2vw;
	font-size: 1.1rem;

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.common.silver};
	}
`;

export const UserMenuLink = styled(Link)`
	display: flex;
	color: ${({ theme }) => theme.colors.text.secondary};
	text-decoration: none;
`;
