import { charts } from '@support/alderbrooke-d3-graphs';
import { RadarGraphConfig, RadarGraphData } from 'features/Graphs';

export const useRadarGraphCreator = (config: RadarGraphConfig, data: RadarGraphData) => {
	return {
		createGraph,
		createExportGraph,
	};

	function createGraph() {
		const graph = new charts.Radar.Builder(config, data);
		return graph.build(false);
	}

	function createExportGraph() {
		const radarHeightWithNoTitle = 25;
		const header = { ...config.header, height: radarHeightWithNoTitle };
		const newConfig = { ...config, header };

		const graph = new charts.Radar.Builder(newConfig, data);
		graph.useAbsoluteDimensioning();

		const builderCfg = { includeTitle: false };
		return graph.build(true, builderCfg);
	}
};
