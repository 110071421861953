import styled from 'styled-components';

export const StyledRoadMapGraphContainer = styled.div<{ $visible: boolean }>`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: ${({ $visible }) => ($visible ? '1' : '0')};
	pointer-events: ${({ $visible }) => ($visible ? 'all' : 'none')}; ;
`;
