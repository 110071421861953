import {
	useGetBehaviourFocusResultBenchmarksMutation,
	useGetBehaviourFocusResultBenchmarksPerProjectMutation,
} from 'store/api/behaviourFocus/behaviourFocusApi';
import {
	ApiBehaviourFocusRequest,
	ApiBehaviourFocusResponse,
	BenchmarkGroupWithParticipantCount,
	BenchmarkGroupAverageScore,
} from 'store/api/behaviourFocus/behaviourFocusApi.types';
import { BenchmarkConfigurationId } from 'store/api';
import { ILineChartData, ILineChartPoint, ILineChartSeries } from '@support/alderbrooke-d3-graphs/dist/Line/types';
import { BenchmarkGroupLabels } from 'features/BehaviourFocusResult/views';
import { SelectedDimension } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent/BehaviourPairModalContent.types';
import { isResponseError } from 'common/utils';
import { HttpResponseStatusCodes } from 'common/types';

export const useBehaviourFocus = () => {
	const [getBehaviourFocus] = useGetBehaviourFocusResultBenchmarksMutation();
	const [getBehaviourFocusPerProject] = useGetBehaviourFocusResultBenchmarksPerProjectMutation();

	return (
		projectId: string | undefined,
		benchmarkConfigurationId: BenchmarkConfigurationId,
		dimensions: SelectedDimension[],
		groupAliases: BenchmarkGroupLabels,
	) => {
		const request: ApiBehaviourFocusRequest = {
			benchmarkConfigurationId: benchmarkConfigurationId,
			dimensions: dimensions.map((d) => d.dimensionId),
		};

		const fetchData = () => {
			if (projectId) {
				return getBehaviourFocusPerProject({ ...request, projectId });
			} else {
				return getBehaviourFocus(request);
			}
		};

		return fetchData()
			.unwrap()
			.then((data) => {
				vetoIfBadRequest(data);
				return mapBehaviourFocus(data);
			});

		function vetoIfBadRequest(data: unknown) {
			const error = isResponseError(data) ? data : undefined;
			const errorObj = error ? { error: error.error, status: error.status, data: error.message } : undefined;

			if (errorObj && errorObj.status === HttpResponseStatusCodes.BAD_REQUEST) {
				const message = errorObj.data as string;
				throw new Error(message);
			}
		}

		function mapBehaviourFocus(data: ApiBehaviourFocusResponse): ILineChartData {
			const pdna = findDataByType(data, 'PDNA');

			const odna = findDataByType(data, 'ODNA');

			const series: ILineChartSeries = {
				pdna: pdna ? pdna.averagesPerBenchmark.map(createPoint) : [],
				odna: odna ? odna.averagesPerBenchmark.map(createPoint) : [],
			};

			const perDna = data.benchmarkGroupAverageScoresByAssessmentDTOS;
			const allScores = perDna.flatMap((b) => b.averagesPerBenchmark);

			const groupIds = new Set(allScores.map(getGroupId));

			const nonUniqueGroups = allScores.map(createGroupFromBenchmarkWithParticipantCount);

			const groups = Array.from(groupIds).map(findGroup);

			return {
				groups,
				behaviours: dimensions.map((d) => d.name),
				series,
			};

			function findGroup(searchedId: number) {
				const found = nonUniqueGroups.find((id) => id.groupId === searchedId);
				if (!found) {
					throw Error();
				}
				return found;
			}
		}

		function findDataByType(data: ApiBehaviourFocusResponse, type: string) {
			const perDna = data.benchmarkGroupAverageScoresByAssessmentDTOS;
			return perDna.filter((point) => point.assessmentType === type).pop();
		}

		function createPoint(point: BenchmarkGroupAverageScore): ILineChartPoint {
			return {
				groupId: getGroupId(point),
				value: point.averageScoreForSelectedDimensions,
			};
		}

		function getGroupId(point: BenchmarkGroupWithParticipantCount) {
			return parseInt(point.benchmarkGroupId.replace('B', ''));
		}

		function getNumberOfParticipantsFromGroup(group: BenchmarkGroupWithParticipantCount) {
			return group.numberOfParticipantsIncluded;
		}

		function getGroupName(point: BenchmarkGroupWithParticipantCount) {
			const alias = groupAliases.findAlias(getGroupId(point));
			const label = alias.length ? alias : point.benchmarkGroupId;
			return `${label}\n(N⠀=⠀${getNumberOfParticipantsFromGroup(point)})`;
		}

		function createGroupFromBenchmarkWithParticipantCount(apiGroup: BenchmarkGroupWithParticipantCount) {
			return {
				groupId: getGroupId(apiGroup),
				name: getGroupName(apiGroup),
			};
		}
	};
};
