import { ApiGraphResponseBenchmark } from 'store/api';
import { toDimensionScoreMap, toEnabledDimensionMap } from 'features/Graphs/TensionGraph';
import { DimensionTextId } from 'features/Graphs/TensionGraph';

const zero = 0;
const first = 1;
export const createBenchmark = (data: ApiGraphResponseBenchmark) => {
	const values = toDimensionScoreMap(data.dimensionKeyValueMap);

	const hasData = () => {
		return data !== undefined;
	};

	const getSelectedDimensions = (): DimensionTextId[] => {
		const dimensionSelection = Array.from(toEnabledDimensionMap(data.dimensionKeyCCBMap));
		const shouldBeSelected = (member: [string, boolean]) => member[first];
		const extractName = (dimension: [string, boolean]) => dimension[zero];

		return dimensionSelection.filter(shouldBeSelected).map(extractName);
	};

	const findByDimension = (name: DimensionTextId): number => {
		if (!hasData()) {
			return zero;
		}
		const value = values.get(name);
		if (value == null) {
			return zero;
		}
		return value;
	};

	const getParticipantAmount = () => {
		if (!hasData()) {
			return zero;
		}
		return data.numberOfParticipants;
	};

	return {
		findByDimension,
		getSelectedDimensions,
		hasData,
		getParticipantAmount,
	};
};
