import { Input } from 'common/components/Input';
import styled from 'styled-components';
import { ErrorText, InputContainer, InputLabel, StyledInput as $StyledInput } from 'common/components/Input/styled';

export const BenchmarksContainer = styled.div`
	border-radius: 2.5rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	display: grid;
	align-items: start;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	padding: 2rem 4rem 0.5rem 4rem;
	background-color: ${({ theme }) => theme.colors.common.white};
	border: 1px solid ${({ theme }) => theme.colors.common.silver};
`;

export const StyledInputContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const StyledErrorText = styled.span`
	display: flex;
	align-items: center;
	line-height: 1rem;
	margin-left: 7rem;
	margin-bottom: 0rem;
	color: ${({ theme }) => theme.colors.error.main};
`;

export const StyledInput = styled(Input)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	color: ${({ theme }) => theme.colors.common.darkGrey};
	margin-bottom: ${({ error }) => (error ? '0.5em' : '1.5em')};
	margin-left: 2em;
	${$StyledInput} {
		height: 2.8rem;
	}

	${InputLabel} {
		margin-bottom: 0em;
	}

	${InputContainer} {
		width: 80%;
		border-color: ${({ theme }) => theme.colors.common.silver};
		&:hover,
		&:focus-within {
			border-color: ${({ theme }) => theme.colors.common.black};
		}
	}

	${ErrorText} {
		margin-left: 0em;
	}
`;
