import { getFilenameFromResponse, rootApi } from 'store/api';

import { GraphsExport, GraphsExportResponse } from 'store/api/graphsExportApi/graphsExportApi.types';
import { format } from 'date-fns';
import { DateFormatPatterns } from 'common/constants';

export const graphsExportApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		exportGraphs: builder.mutation<GraphsExportResponse, GraphsExport>({
			query: (data) => ({
				url: `/api/viewmode/results/pptx`,
				body: data,
				method: 'POST',
				responseHandler: async (response) => {
					if (!response.ok) return;
					const currentDate = format(new Date(), DateFormatPatterns.Export);
					const defaultFilename = `simulation_chart_${currentDate}.pptx`;
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});

export const { useExportGraphsMutation } = graphsExportApi;
