import styled from 'styled-components';

export const NavbarContainer = styled.nav`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${({ theme }) => theme.colors.common.silver};
`;

export const NavbarList = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 1.75rem 0 1.75rem 0.75rem;
`;
