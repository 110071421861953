import { StyledPage } from 'common/components/UnderConstructionPage/styled';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const UnderConstructionPage = (props: { title: string }) => {
	return (
		<StyledPage>
			<h1>Sorry!</h1>
			<FontAwesomeIcon icon={faScrewdriverWrench} />
			<h3>Page {props.title} is under construction.</h3>
		</StyledPage>
	);
};
