import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 1rem;
	margin-right: 3rem;
	height: calc(100% - 1rem);
	display: flex;
	flex-direction: column;
`;

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.text.secondary};
	margin: 0;
	font-size: 1.7rem;
	line-height: 32px;
	font-weight: 400;
`;

export const Subtitle = styled.h2`
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.text.secondary};
	margin: 0;
	font-size: 1.7rem;
	line-height: 32px;
	font-weight: 400;
`;

export const SelectedCounterText = styled.p`
	margin: 0;
	margin-left: auto;
	font-size: 1.4rem;
`;

export const SelectedFrameworkText = styled.p`
	margin: 0;
	margin-left: auto;
	font-size: 1.4rem;
`;
