import { User } from 'common/types';
import { LoginRequest } from '.';
import { rootApi } from 'store/api';
import { mapLoggedUserResponse } from 'store/api/authApi/mapper';

export const authApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<User, LoginRequest>({
			query: (credentials) => ({
				url: 'login',
				method: 'POST',
				body: credentials,
			}),
			invalidatesTags: ['Auth'],
			transformResponse: mapLoggedUserResponse,
		}),
		getLoggedInUser: builder.query<User, void>({
			query: () => ({
				url: 'api/logged-user',
				method: 'GET',
			}),
			providesTags: ['Auth'],
			transformResponse: mapLoggedUserResponse,
		}),
		logout: builder.mutation<null, void>({
			query: () => ({
				url: 'logout',
				method: 'POST',
			}),
		}),
	}),
});

export const { useLoginMutation, useGetLoggedInUserQuery, useLogoutMutation } = authApi;
