import styled, { css } from 'styled-components';

export const StyledToggle = styled.div`
	width: 132px;
	height: 52px;
	border-radius: 30px;
	border: 6px solid ${({ theme }) => theme.colors.primary.main};
	${'.react-switch-bg'} {
		background: none !important;
	}
`;

const labelStyle = css`
	display: flex;
	alignitems: center;
	height: 100%;
	flex-direction: row-reverse;
	color: black;
	font-weight: 700;
	font-size: 25px;
`;

export const StyledLeftSideLabel = styled.div`
	${labelStyle}
	padding-right: 12px;
	flex-direction: row-reverse;
`;

export const StyledRightSideLabel = styled.div`
	${labelStyle}
	padding-left: 15px;
	justify-content: flex-end;
`;
