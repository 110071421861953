import { SelectionPanel, SelectionPanelItem } from 'common/components';
import { RoadMapPath } from 'features/RoadMap/views/RoadMapView/RoadMapView.types';
import { useState } from 'react';
import { Roadmap } from 'store/api/roadmapApi';

export const RoadMapSelectionPanel = ({
	roadmap,
	onItemSelection,
}: {
	roadmap: Roadmap;
	onItemSelection: (id: string) => void;
}) => {
	const [selectedRoadmapId, setSelectedRoadmapId] = useState('0');
	const selectors = createRoadmapSelectors(roadmap);
	const minimumSelectorsNumber = 2;

	const getClusterPanelItems = () => {
		return selectors.map((path) => {
			return (
				<SelectionPanelItem
					key={path.id}
					id={path.id}
					itemData={path}
				>
					{path.name}
				</SelectionPanelItem>
			);
		});
	};

	return selectors.length < minimumSelectorsNumber ? (
		<></>
	) : (
		<SelectionPanel<RoadMapPath>
			id="roadmap-selection-panel"
			selectedItemId={selectedRoadmapId}
			onSelect={(item) => {
				setSelectedRoadmapId(item.id);
				onItemSelection(item.id);
			}}
			customButtonVariants={{ notSelected: 'outlinedFilled' }}
		>
			{getClusterPanelItems()}
		</SelectionPanel>
	);
};

function createRoadmapSelectors(roadmap: Roadmap) {
	const paths: RoadMapPath[] = [];

	const createPathName = (index: number) => `Path ${index}`;

	const mainPath = 1;
	roadmap?.mainRoadmapGraph.edges.length &&
		paths.push({
			id: '0',
			name: createPathName(mainPath),
			data: roadmap?.mainRoadmapGraph,
		});

	const alternatePath = 2;
	roadmap?.alternateRoadmapGraph.edges.length &&
		paths.push({
			id: '1',
			name: createPathName(alternatePath),
			data: roadmap?.alternateRoadmapGraph,
		});

	const secondaryAlternatePath = 3;
	roadmap?.secondaryAlternateRoadmapGraph.edges.length &&
		paths.push({
			id: '2',
			name: createPathName(secondaryAlternatePath),
			data: roadmap?.secondaryAlternateRoadmapGraph,
		});
	return paths;
}
