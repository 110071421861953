import { BenchmarkConfigurationId } from 'store/api';
import {
	useLazyGetCriteriaViewQuery,
	useLazyGetHighlightViewQuery,
	useLazyGetTotalViewQuery,
} from 'store/api/discoveryInsightApi';
import { ProjectId } from 'common/types';
import { DiscoveryQuadrantGraphData } from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import { ApiCriteriaId, ApiQuadrantCenter } from 'store/api/discoveryInsightApi';

export const useDiscoveryInsightQuadrantData = () => {
	const [fetchTotalView] = useLazyGetTotalViewQuery();
	const [fetchCriteriaView] = useLazyGetCriteriaViewQuery();
	const [fetchHighlightView] = useLazyGetHighlightViewQuery();

	return (
		benchmarkConfigurationId: BenchmarkConfigurationId,
		selectedCriterion: ApiCriteriaId,
		highlightCriteria: ApiCriteriaId[],
		quadrantCenter: ApiQuadrantCenter,
		totalThreshold: number,
		projectId?: ProjectId,
	): Promise<DiscoveryQuadrantGraphData> => {
		const baseRequest = { benchmarkConfigurationId, projectId };
		const baseQuadrantRequest = { ...baseRequest, quadrantCenter };
		const request = { ...baseQuadrantRequest, criteriaId: selectedCriterion };
		const highlightRequest = { ...baseQuadrantRequest, criteria: highlightCriteria, totalThreshold };

		const totalView = fetchTotalView(request);
		const criteriaView = fetchCriteriaView(request);
		const highlightView = fetchHighlightView(highlightRequest);

		const first = 0;
		const second = 1;
		const third = 2;
		return Promise.all([totalView, criteriaView, highlightView]).then((result) => {
			const totalView = result[first].data;
			const criteriaView = result[second].data;
			const highlights = result[third].data;

			if (!totalView || !criteriaView || !highlights) {
				throw Error();
			}
			return {
				totalView,
				criteriaView,
				highlights,
			};
		});
	};
};
