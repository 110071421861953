import { Column } from '@tanstack/react-table';
import { getUTCDate, isDate, parseDateString } from 'common/utils';
import { DateInput, NotificationTypes, useNotifications } from 'common/components';
import { isKeyDownEventType } from 'common/components/DateInput/utils';
import { DateFormatPatterns, DateFilterPickerRange, RegexPatterns } from 'common/constants';
import { format } from 'date-fns';
import { useState } from 'react';

export function DateFilter<T>(column: Column<T>) {
	const filterValue = column.getFilterValue();

	const onApplyDateFilter = (filterDate: Date | null) => {
		column.setFilterValue(filterDate);
	};

	const dateFilterValue = isDate(filterValue) ? filterValue : null;

	const minDate = DateFilterPickerRange.MinDate;
	const maxDate = DateFilterPickerRange.MaxDate;
	const { pushNotification } = useNotifications();

	const [dateString, setDateString] = useState('');
	const [isDateInputError, setIsDateInputError] = useState(false);

	const displayNotification = (message: string) => {
		pushNotification({
			message: message,
			type: NotificationTypes.Warning,
		});
	};

	const handleWrongDateFormatError = () => {
		displayNotification('Wrong date format. Use this format instead: dd/mm/yyyy.');
		setIsDateInputError(true);
	};

	const handleTooEarlyDateError = () => {
		const formattedMinDate = format(minDate, DateFormatPatterns.Default);
		displayNotification(`Wrong date. Chosen date should be after ${formattedMinDate}.`);
		setIsDateInputError(true);
	};

	const handleTooLateDateError = () => {
		const formattedMaxDate = format(maxDate, DateFormatPatterns.Default);
		displayNotification(`Wrong date. Chosen date should be before ${formattedMaxDate}.`);
		setIsDateInputError(true);
	};

	const onChangeRawDateString = (event: React.FocusEvent<HTMLInputElement, Element>) => {
		const dateString = event.currentTarget.value;
		setDateString(dateString);
	};

	const checkDateStringValidation = (): boolean => {
		const normalizedDateString = dateString.replace(RegexPatterns.AllWhitespaceChars, '');

		const filterDate = parseDateString(normalizedDateString);
		if (!filterDate) {
			if (normalizedDateString === '') {
				setIsDateInputError(false);
			}
			if (normalizedDateString !== '') {
				handleWrongDateFormatError();
			}
			return false;
		}

		if (filterDate < minDate) {
			handleTooEarlyDateError();
			return false;
		}

		if (filterDate > maxDate) {
			handleTooLateDateError();
			return false;
		}

		return true;
	};

	const onSelectFilterDate = (date: Date | null, event: React.SyntheticEvent) => {
		// This is a hack for not selecting the default today's date as
		// filter when the user will submit the invalid date by clicking
		// 'Enter' key without changing current input value.
		if (isKeyDownEventType(event) && !checkDateStringValidation()) {
			onApplyDateFilter(null);
			return;
		}

		if (!date) {
			onApplyDateFilter(null);
			return;
		}

		const utcDate = getUTCDate(date);
		if (utcDate < minDate) {
			onApplyDateFilter(null);
			return;
		}

		if (utcDate > maxDate) {
			onApplyDateFilter(null);
			return;
		}

		const formattedDateString = format(utcDate, DateFormatPatterns.Default);

		onApplyDateFilter(utcDate);
		setDateString(formattedDateString);
		setIsDateInputError(false);
	};

	const applyFilterFromDateString = () => {
		if (!checkDateStringValidation()) {
			onApplyDateFilter(null);
			return;
		}

		const normalizedDateString = dateString.replace(RegexPatterns.AllWhitespaceChars, '');
		const filterDate = parseDateString(normalizedDateString);
		if (!filterDate) return;

		onApplyDateFilter(filterDate);
		setIsDateInputError(false);
	};

	return (
		<DateInput
			inputValue={dateString}
			dateValue={dateFilterValue}
			minDate={minDate}
			maxDate={maxDate}
			placeholder="Search..."
			inputProps={{ error: isDateInputError }}
			onClickOutside={applyFilterFromDateString}
			onSelectDate={onSelectFilterDate}
			onEnterKeydown={applyFilterFromDateString}
			onInputChange={onChangeRawDateString}
		/>
	);
}
