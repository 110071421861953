import { usePptxExportService } from 'features/Graphs/PptxExportButton/hooks/usePptxExportService';
import { useGraphBase } from 'features/Graphs/PptxExportButton/hooks/useGraphBase';
import { useSelector } from 'react-redux';
import { selectBenchmarksSettings, selectChartSettings } from 'store/slices';
import {
	getOrganisationalBenchmarkSelection,
	getPersonalBenchmarkSelection,
	getPnoBenchmarkSelection,
} from 'features/Simulation/views/SimulationView/utils/getBenchmarkSelection';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { DownloadButton, DownloadIcon } from './styled';
import { useEffect, useState } from 'react';
import { PptxExportButtonProps } from 'features/Graphs/PptxExportButton';

export const PptxExportButton = ({ onBeforeClick }: PptxExportButtonProps) => {
	const { projectId } = useParams();

	const benchmarkSettings = useSelector(selectBenchmarksSettings);
	const chartSettings = useSelector(selectChartSettings);
	const pdnaBenchmarkIndexes = getPersonalBenchmarkSelection(benchmarkSettings);
	const odnaBenchmarkIndexes = getOrganisationalBenchmarkSelection(benchmarkSettings);
	const pnoBenchmarkIndexes = getPnoBenchmarkSelection(benchmarkSettings);

	const zero = 0;
	const [downloadTrigger, setDownloadTrigger] = useState(zero);
	useEffect(() => {
		if (!downloadTrigger) {
			return;
		}
		downloadGraphsPptx();
	}, [downloadTrigger]);

	const combinedMode = chartSettings.showDataOnOneGraph;
	const graphBase = useGraphBase(combinedMode);

	const includeClusters = chartSettings.includeClusters;
	const colorPresetId = chartSettings.preset;

	const downloadGraphsPptx = usePptxExportService(
		projectId,
		graphBase,
		combinedMode,
		includeClusters,
		pdnaBenchmarkIndexes,
		odnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
		colorPresetId,
		nonConfigurableQuadrantCenter(),
	);
	return (
		<DownloadButton
			variant="text"
			underlineOnHover
			color="inherit"
			startIcon={<DownloadIcon icon={faDownload} />}
			onClick={() => {
				onBeforeClick();
				triggerDownload();
			}}
		>
			Download PPT
		</DownloadButton>
	);

	function triggerDownload() {
		const one = 1;
		setDownloadTrigger(downloadTrigger + one);
	}

	function nonConfigurableQuadrantCenter() {
		return { x: 2.5, y: 2.5 };
	}
};
