import { CSSTransition } from 'react-transition-group';
import { MenuContainer, SettingsMenuOverlay } from './styled';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectIsChartSettingsViewOpened } from 'store/slices';
import { SettingsMenuViewProps } from 'features/Simulation/views/SettingsMenuView/SettingsMenuView.types';

export const SettingsMenuView = ({ children }: SettingsMenuViewProps) => {
	const isSettingsMenuOpened = useSelector(selectIsChartSettingsViewOpened);
	const settingsMenuRef = useRef(null);
	return (
		<>
			<CSSTransition
				in={isSettingsMenuOpened}
				ref={settingsMenuRef}
				timeout={250}
			>
				<SettingsMenuOverlay ref={settingsMenuRef} />
			</CSSTransition>
			<CSSTransition
				in={isSettingsMenuOpened}
				ref={settingsMenuRef}
				timeout={750}
			>
				<MenuContainer>{children}</MenuContainer>
			</CSSTransition>
		</>
	);
};
