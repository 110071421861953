import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { TableAction, Tenant } from 'common/types';
import { ProjectColumnId } from 'features/Tables/hooks/useProjectsData/ProjectColumnIds';
import { Tooltip } from 'common/components';
import { faFileImage, faListCheck, faMessage, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { TenantColumnId } from './TenantsColumnIds';
import { TableIconButton, TableWrapper } from 'features/Tables/components';

export const useTenantsData = () => {
	const actions = React.useMemo<TableAction<Tenant>[]>(
		() => [
			{
				label: 'Edit Client',
				icon: faPenToSquare,
				getHref: (row: Tenant) => `/tenants/${row.tenantId}`,
			},
			{
				label: 'Upload Client Logo',
				icon: faFileImage,
				getHref: (row: Tenant) => `/tenants/${row.tenantId}/branding`,
			},
			{
				label: 'Selection Criteria Config',
				icon: faListCheck,
				getHref: (row: Tenant) => `/tenants/${row.tenantId}/criteriaConfiguration`,
			},
			{
				label: 'Configure dimension tooltips',
				icon: faMessage,
				getHref: (row: Tenant) => `/tenants/${row.tenantId}/dimensionLegends`,
			},
		],
		[],
	);

	const columns = React.useMemo<ColumnDef<Tenant>[]>(
		() => [
			{
				id: TenantColumnId.ShortId,
				header: 'Short ID',
				accessorKey: 'shortId',
			},
			{
				id: TenantColumnId.Name,
				header: 'Name',
				accessorKey: 'name',
				accessorFn: (row: Tenant) => {
					return row.name;
				},
			},
			{
				id: ProjectColumnId.Actions,
				header: 'Actions',
				accessorFn: (row) => row,
				cell: ({ getValue }) => {
					return (
						<TableWrapper>
							{actions.map((action) => {
								const tenant = getValue<Tenant>();
								if (action.isHidden?.(tenant)) return;
								return (
									<Tooltip
										key={action.label}
										content={action.label}
										delay={0}
									>
										<TableIconButton
											icon={action.icon}
											href={action.getHref ? action.getHref(tenant) : undefined}
										/>
									</Tooltip>
								);
							})}
						</TableWrapper>
					);
				},
				enableSorting: false,
				enableColumnFilter: false,
			},
		],
		[],
	);

	return { columns };
};
