import styled from 'styled-components';

export const StyledThumb = styled.div`
	${'div'} {
		height: 30px;
		width: 30px;
		background-color: ${({ theme }) => theme.colors.primary.main};
		border-radius: 50%;
		border: 2px solid ${({ theme }) => theme.colors.common.white};
		cursor: grab;
	}
`;

export const StyledTrack = styled.div`
	${'.track'} {
		border-radius: 1vh;
		height: 10px;
		margin-top: 10px;
		background-color: ${({ theme }) => theme.colors.primary.main};
	}
	${'.track-1'} {
		background-color: ${({ theme }) => theme.colors.common.darkGrey};
	}
`;
