import { TableQueryParams, AssignmentParticipants } from 'common/types';
import { useAssignParticipantsToProject } from 'features/Tables/hooks';
import { PageLayout, Table } from 'common/components';
import { useState } from 'react';
import { ColumnFilter } from '@tanstack/react-table';
import { TableQueryTrigger } from 'common/types';
import { SelectedCounterText } from './styled';
import { StyledInput, TableContainer, InputWrapper } from './styled';
import { useParams } from 'react-router-dom';
import { useProjectDetails } from 'common/hooks';
import { useAssignParticipants } from 'common/hooks';

export const ParticipantSelection = () => {
	const { projectId } = useParams();
	const { projectDetails } = useProjectDetails({ projectId: projectId });
	const [tableQueryTrigger, setTableQueryTrigger] = useState<TableQueryTrigger>();
	const { columns, visibleColumns } = useAssignParticipantsToProject(triggerAssignParticipantDataReload);
	const { getAssignmentParticipants, assignmentParticipants } = useAssignParticipants();

	const projectShortId = projectDetails?.shortId ?? '';
	function ParticipantSelectionTabPanel() {
		const onQueryChange = async (queryParams: TableQueryParams): Promise<void> => {
			try {
				await getAssignmentParticipants(createRequest(queryParams));
			} catch {}
		};

		return (
			<>
				<Table<AssignmentParticipants>
					tableTypeId="assign-participants"
					visibleColumns={visibleColumns}
					columns={columns}
					currentPageIndex={assignmentParticipants.pageNumber}
					pageCount={assignmentParticipants.totalPages}
					data={assignmentParticipants.content}
					onQueryChange={onQueryChange}
					queryTrigger={tableQueryTrigger}
				/>
			</>
		);
	}

	return (
		<PageLayout
			title="Project Organisation"
			subtitle="Select and assign participants to a project"
			id="project-organization"
			RightSideComponent={
				<SelectedCounterText>{`${assignmentParticipants.totalAssigned} participants selected`}</SelectedCounterText>
			}
		>
			<InputWrapper>
				<StyledInput
					readOnly
					name="Project Short ID"
					value={projectShortId}
				/>
			</InputWrapper>
			<TableContainer>{ParticipantSelectionTabPanel()}</TableContainer>
		</PageLayout>
	);

	function createFilters(filters: ColumnFilter[]) {
		const mappedFilters: { [key: string]: string }[] = filters.map((filter) => {
			return {
				[filter.id]: filter.value as string,
			};
		});
		return mappedFilters;
	}

	function getOptionalProjectId() {
		return projectId || '';
	}

	function createRequest(queryParams: TableQueryParams) {
		const { orderBy, direction, size, pageNumber, filters } = queryParams;
		const mappedFilters = createFilters(filters);
		return {
			projectId: getOptionalProjectId(),
			direction: direction,
			filters: mappedFilters,
			list: [],
			pageNumber: pageNumber,
			size: size,
			orderBy: orderBy,
		};
	}

	function triggerAssignParticipantDataReload() {
		return setTableQueryTrigger(new TableQueryTrigger());
	}
};
