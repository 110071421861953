import {
	useClientFormWithUniquenessValidation,
	useCreateClientFormData,
} from 'features/ClientConfiguration/components';
import { ClientFormLayout } from 'features/ClientConfiguration/views';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateTenantMutation } from 'store/api';

export const CreateTenants = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const { data } = useCreateClientFormData();
	const [createTenant] = useCreateTenantMutation();
	const navigate = useNavigate();
	const { validateForm } = useClientFormWithUniquenessValidation();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const validationParameters = { shouldCheckForShortIdUniqueness: true };
		const validate = () => validateForm(data, validationParameters);
		validate().then((formIsValid) => {
			if (!formIsValid) return;

			createTenant(data)
				.unwrap()
				.then((result) => {
					if (result.id) {
						navigate('/tenants');
					}
				});
		});
	};

	return (
		<ClientFormLayout
			id="create-client"
			title="CREATE CLIENT"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
