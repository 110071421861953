import { useEffect, useState } from 'react';
import { usePredictivePPTExport } from 'features/Predictive/hooks';
import { DownloadButton, DownloadIcon } from 'features/Graphs/PptxExportButton';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PredictiveExportButtonProps } from 'features/Predictive/components/PredictiveExportButton/PredictiveExportButton.types';

export const PredictiveExportButton = ({ data, settings }: PredictiveExportButtonProps) => {
	const zero = 0;
	const [downloadTrigger, setDownloadTrigger] = useState(zero);
	useEffect(() => {
		if (!downloadTrigger) {
			return;
		}

		downloadGraphsPptx();
	}, [downloadTrigger]);
	const downloadPPT = usePredictivePPTExport();

	return (
		<DownloadButton
			variant="text"
			underlineOnHover
			color="inherit"
			startIcon={<DownloadIcon icon={faDownload} />}
			onClick={onDownloadButtonClick}
		>
			Download PPT
		</DownloadButton>
	);

	function onDownloadButtonClick() {
		const one = 1;
		setDownloadTrigger(downloadTrigger + one);
	}

	function downloadGraphsPptx() {
		const quartileInfoTextSize = 17;
		const exportConfig = {
			enableQuartileInfo: settings.quartileInfoEnabled,
			quartileInfoTextSize,
		};
		downloadPPT(data, exportConfig);
	}
};
