export type ExportTypesProps = {
	id: string;
	name: string;
	shortId: string;
	tenantProjectId: string;
};

const first = 0;

export const ExportTypes = [
	{ id: 'ALL', name: 'All data', shortId: 'all data' },
	{ id: 'COMPUTED', name: 'Computed data', shortId: 'computed data' },
] as ExportTypesProps[];

export const defaultExportType = ExportTypes[first].name;
