import { BenchmarkId, DimensionScore, DimensionTextId } from 'features/Graphs/TensionGraph';
import { ApiGraphResponseBenchmark, ApiGraphResponseClusteredResult } from 'store/api';
import { createBenchmark } from 'features/Graphs/TensionGraph';
import { toBenchmarkMap } from 'features/Graphs/TensionGraph';

export const createCluster = (data: ApiGraphResponseClusteredResult) => {
	const getSelectedDimensions = (benchmark: BenchmarkId) => {
		const pdna = findPDNA(benchmark);
		const odna = findODNA(benchmark);

		if (pdna.hasData()) {
			return pdna.getSelectedDimensions();
		}
		if (odna.hasData()) {
			return odna.getSelectedDimensions();
		}
		return [];
	};

	const findBothScoresWhereSelected = (benchmark: BenchmarkId): DimensionScore[] => {
		const selected = getSelectedDimensions(benchmark);
		const pdna = findPDNA(benchmark);
		const odna = findODNA(benchmark);

		const mergePdnaAndOdna = (dimension: DimensionTextId): DimensionScore => {
			const pdnaScore = pdna.findByDimension(dimension);
			const odnaScore = odna.findByDimension(dimension);
			return { name: dimension, odnaScore, pdnaScore };
		};
		return selected.sort().map(mergePdnaAndOdna);
	};
	if (!data.ALL_PDNA) {
		throw Error('ALL_PDNA not found');
	}
	if (!data.ALL_ODNA) {
		throw Error('ALL_ODNA not found');
	}
	const allPdna = toBenchmarkMap(data.ALL_PDNA);
	const allOdna = toBenchmarkMap(data.ALL_ODNA);

	const nonNull = (data: ApiGraphResponseBenchmark | undefined) => {
		if (data === undefined) {
			throw Error();
		}
		return data;
	};
	const findPDNA = (benchmark: BenchmarkId) => createBenchmark(nonNull(allPdna.get(benchmark)));
	const findODNA = (benchmark: BenchmarkId) => createBenchmark(nonNull(allOdna.get(benchmark)));

	return {
		data,
		findBothScoresWhereSelected: findBothScoresWhereSelected,
		findOdnaBenchmark: findODNA,
		findPdnaBenchmark: findPDNA,
	};
};
