import { QuadrantLegendCreator } from 'features/Graphs/QuadrantGraph/QuadrantGraph.types';
import { ApiCategoryDistributionDTO, ApiPerBenchmarkDistribution, ApiQuarterlyDistributionSummary } from 'store/api';
import { ILegendData } from '@support/alderbrooke-d3-graphs/dist/Quadrant/IChartData';

export class BenchmarkLegendDistributionCreator implements QuadrantLegendCreator<ApiPerBenchmarkDistribution> {
	constructor(private _labels: Map<string, string>) {}

	createLegend(data: ApiQuarterlyDistributionSummary<ApiPerBenchmarkDistribution>): ILegendData {
		const benchmarks = this._createBenchmarks(data);

		const createSummaryFromQuarter = (quarter: ApiPerBenchmarkDistribution) =>
			benchmarks.map((benchmark) => this._createDistributionSummary(benchmark, quarter));

		const leftTop = createSummaryFromQuarter(data.q1);
		const rightTop = createSummaryFromQuarter(data.q2);
		const leftBottom = createSummaryFromQuarter(data.q3);
		const rightBottom = createSummaryFromQuarter(data.q4);

		return {
			leftTop,
			rightTop,
			leftBottom,
			rightBottom,
		};
	}

	_createBenchmarks(data: ApiQuarterlyDistributionSummary<ApiPerBenchmarkDistribution>) {
		const label = 0;
		const value = 1;
		const onlyThoseSelected = (benchmark: [string, ApiCategoryDistributionDTO]) => benchmark[value].totalAmount;
		const toBenchmarkPerDistribution = (o: unknown) => o as [string, ApiCategoryDistributionDTO];
		const onlyLabel = (entry: [string, ApiCategoryDistributionDTO]) => entry[label];

		return Object.entries(data.q1).map(toBenchmarkPerDistribution).filter(onlyThoseSelected).map(onlyLabel);
	}

	_createDistributionSummary(benchmark: string, quarter: ApiPerBenchmarkDistribution) {
		const firstQuarter = this._getBenchmarkData(benchmark, quarter);
		const groupLabel = this._labels.get(benchmark) || benchmark;
		return this._createDistribution(groupLabel, firstQuarter);
	}

	_createDistribution(label: string, data: ApiCategoryDistributionDTO) {
		const fractionDigits = 2;
		const percentage = data.percentage.toFixed(fractionDigits);
		const total = `${data.membersAmount}/${data.totalAmount}`;
		return `${label}: ${percentage}% ${total}`;
	}

	_getBenchmarkData(benchmark: string, data: ApiPerBenchmarkDistribution): ApiCategoryDistributionDTO {
		switch (benchmark) {
			case 'b1':
				return data.b1;
			case 'b2':
				return data.b2;
			case 'b3':
				return data.b3;
			case 'b4':
				return data.b4;
			case 'b5':
				return data.b5;
			default:
				return {
					category: benchmark,
					membersAmount: 0,
					totalAmount: 0,
					percentage: 0,
				};
		}
	}
}
