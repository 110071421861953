import { ApiTenantAdmin, TenantAdmin } from 'common/types';
import { AllTenantsAdminsResponse } from 'store/api';

export const mapGetAllTenantsAdminsResponse = (
	response: AllTenantsAdminsResponse<ApiTenantAdmin>,
): AllTenantsAdminsResponse<TenantAdmin> => {
	const { tadmins } = response;

	const mappedTenantsAdmins: TenantAdmin[] = tadmins.map((tenantAdmin) => {
		const { id, ...tenantAdminCopy } = tenantAdmin;
		return {
			...tenantAdminCopy,
			tenantAdminId: id,
		};
	});

	return { tadmins: mappedTenantsAdmins };
};
