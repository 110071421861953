import { useParticipantDetails } from 'common/hooks';
import {
	ParticipantFormValidationProperties,
	useParticipantFormData,
	useParticipantFormValidation,
} from 'features/Participants/components/ParticipantForm/hooks';
import { ParticipantFormLayout } from 'features/Participants/views';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditParticipantMutation } from 'store/api';

export const EditParticipant = () => {
	const navigate = useNavigate();
	const { assesseeId: participantId } = useParams();
	const { participantDetails } = useParticipantDetails({ participantId: participantId });

	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const { validateForm } = useParticipantFormValidation();
	const { participantFormData } = useParticipantFormData();
	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const [editParticipant] = useEditParticipantMutation();

	const onSaveButtonClick = async () => {
		if (!participantDetails) return;

		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const validationParameters: ParticipantFormValidationProperties = {
			shouldCheckForParticipantEmailUniqueness:
				participantDetails.emailAddress !== participantFormData.emailAddress,
		};
		const validate = () => validateForm(participantFormData, validationParameters);
		const isFormValid = await validate();

		if (!isFormValid) return;

		editParticipant({ ...participantFormData, id: participantDetails.participantId }).then(() => {
			navigate('/assessees');
		});
	};

	const getEditedParticipantData = () => {
		if (!participantDetails) return;
		const {
			emailAddress,
			firstName,
			lastName,
			participantId: _,
			middleName,
			salutation,
			title,
			...criteria
		} = participantDetails;

		return {
			criteriaInformation: criteria,
			participantInformation: {
				emailAddress,
				firstName,
				lastName,
				middleName,
				salutation,
				title,
			},
		};
	};

	const editParticipantData = getEditedParticipantData();

	if (!participantDetails) return <></>;

	return (
		<ParticipantFormLayout
			id="edit-participant"
			title="EDIT PARTICIPANT"
			saveTrigger={saveTrigger}
			criteriaInformationProps={{ criteria: editParticipantData?.criteriaInformation }}
			participantInformationProps={editParticipantData?.participantInformation}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
