import { useGetMyOrganisationQuery } from 'store/api';
import { mapApiTenant } from 'common/hooks';
import { ApiMyOrganization, MyOrganization } from 'common/types';

export const useMyOrganisation = () => {
	const myOrganisation = useGetMyOrganisationQuery();
	const myOrganisationData = getMyOrganization();

	function getMyOrganization(): ApiMyOrganization | null {
		if (!myOrganisation.isSuccess) return null;

		return myOrganisation.data;
	}

	function transform(myOrganization: ApiMyOrganization): MyOrganization {
		return mapApiTenant(myOrganization);
	}

	if (myOrganisationData == null) {
		return { myOrganisation: null };
	}

	return { myOrganisation: transform(myOrganisationData) };
};
