import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BillingContactState, setBillingContactState } from 'store/slices';
import { handleFormMailBlur, handleRequiredFormValue, handleFormTelephoneBlur, createFormState } from 'common/utils';
import { BillingContactProps } from 'features/ClientConfiguration/components/CreateClient/BillingContact/BillingContact.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const BillingContact = ({
	saveTrigger,
	disableBillingContactIM,
	disableBillingContactName,
	disableBillingContactEmail,
	disableBillingContactTelephone,
	billingContactIM: initialBillingContactIM,
	billingContactName: initialBillingContactName,
	billingContactEmail: initialBillingContactEmail,
	billingContactTelephone: initialBillingContactTelephone,
}: BillingContactProps) => {
	const [billingContactIM, setBillingContactIM] = useState(emptyIfNullOrUndefined(initialBillingContactIM));

	const [billingContactName, setBillingContactName] = useState<RequiredFormInputState>(
		createFormState(initialBillingContactName),
	);

	const [billingContactEmail, setBillingContactEmail] = useState<RequiredFormInputState>(
		createFormState(initialBillingContactEmail),
	);

	const [billingContactTelephone, setBillingContactTelephone] = useState<RequiredFormInputState>(
		createFormState(initialBillingContactTelephone),
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(billingContactName.value, 'Billing contact name is required', setBillingContactName);
		handleFormMailBlur(
			billingContactEmail.value,
			'Billing contact email does not have correct format!',
			setBillingContactEmail,
		);
		handleFormTelephoneBlur(
			billingContactTelephone.value,
			'Billing contact telephone is not in correct format!',
			setBillingContactTelephone,
		);
	};

	const setSliceState = () => {
		const sliceState: BillingContactState = {
			billingContactEmail: billingContactEmail.value,
			billingContactIM: billingContactIM,
			billingContactName: billingContactName.value,
			billingContactTelephone: billingContactTelephone.value,
		};

		dispatch(setBillingContactState(sliceState));
	};

	return (
		<>
			<Input
				{...billingContactName}
				name="Name of billing contact"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingContactName}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(
						event.target.value,
						'Billing contact name is required!',
						setBillingContactName,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(
						event.target.value,
						'Billing contact name is required!',
						setBillingContactName,
					);
					setSliceState();
				}}
			/>
			<Input
				{...billingContactEmail}
				name="Email of billing contact"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingContactEmail}
				formInput
				onChange={(event) => {
					setBillingContactEmail({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormMailBlur(
						event.target.value,
						'Billing contact email does not have correct format!',
						setBillingContactEmail,
					);
					setSliceState();
				}}
			/>
			<Input
				{...billingContactTelephone}
				name="Billing contact telephone"
				maxLength={30}
				errorPlacement="right"
				disabled={disableBillingContactTelephone}
				formInput
				onChange={(event) => {
					setBillingContactTelephone({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormTelephoneBlur(
						event.target.value,
						'Billing contact telephone is not in correct format!',
						setBillingContactTelephone,
					);
					setSliceState();
				}}
			/>
			<Input
				name="IM contact of billing contact"
				maxLength={30}
				value={billingContactIM}
				disabled={disableBillingContactIM}
				formInput
				onChange={(event) => {
					setBillingContactIM(event.target.value);
				}}
				onBlur={(event) => {
					setBillingContactIM(event.target.value);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
