import { rootApi } from 'store/api';
import { ApiPredictiveRequest, ApiPredictiveResult } from 'store/api/predictiveApi/predictiveApi.types';

export const predictiveViewApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getPredictiveGraphData: builder.query<ApiPredictiveResult, ApiPredictiveRequest>({
			query: (opts) => ({
				url: `api/predictive/results`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
		getPredictiveGraphDataFromProject: builder.query<ApiPredictiveResult, ApiPredictiveRequest>({
			query: (opts) => ({
				url: `api/predictive/project/${opts.projectId}/results/`,
				params: {
					benchmarkConfigId: opts.benchmarkConfigurationId.id,
				},
			}),
		}),
	}),
});
export const {
	useGetPredictiveGraphDataQuery,
	useLazyGetPredictiveGraphDataQuery,
	useLazyGetPredictiveGraphDataFromProjectQuery,
} = predictiveViewApi;
