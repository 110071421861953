export const getBehaviourTooltipDirection = (behaviourArrayIndex: number): 'top' | 'left' | 'right' => {
	const modThree = 3;
	const zeroModResult = 0;
	const oneModResult = 1;
	const twoModResult = 2;

	const isThirdGridRow = behaviourArrayIndex % modThree === zeroModResult;
	if (isThirdGridRow) {
		return 'right';
	}

	const isSecondGridRow = behaviourArrayIndex % modThree === oneModResult;
	if (isSecondGridRow) {
		return 'top';
	}

	const isFirstGridRow = behaviourArrayIndex % modThree === twoModResult;
	if (isFirstGridRow) {
		return 'left';
	}

	return 'top';
};
