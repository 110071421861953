import { rootApi } from 'store/api';
import {
	BenchmarkConfigurationId,
	BenchmarkParticipantId,
} from 'store/api/benchmarkConfigurationApi/benchmarkConfigurationApi.types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const benchmarkConfigurationApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		createBenchmarkConfiguration: builder.mutation<BenchmarkConfigurationId, BenchmarkParticipantId[]>({
			query: (opts) => ({
				url: 'api/benchmark-configurations',
				method: 'POST',
				body: opts,
			}),
		}),
		copyBenchmarkConfiguration: builder.mutation<BenchmarkConfigurationId, BenchmarkConfigurationId>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.id}/actions/copy`,
				method: 'POST',
			}),
		}),
		removeBenchmarkConfiguration: builder.mutation<void, BenchmarkConfigurationId>({
			query: (opts) => ({
				url: `api/benchmark-configurations/${opts.id}`,
				method: 'DELETE',
			}),
		}),
		validateBenchmarkConfiguration: builder.mutation<FetchBaseQueryError, BenchmarkConfigurationId>({
			query: (opts) => ({
				url: `api/roadmap-benchmark-configurations/${opts.id}/actions/validate`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useCreateBenchmarkConfigurationMutation,
	useCopyBenchmarkConfigurationMutation,
	useRemoveBenchmarkConfigurationMutation,
	useValidateBenchmarkConfigurationMutation,
} = benchmarkConfigurationApi;
