import {
	IBarColorStyle,
	IBarFontStyle,
	IBarStyle,
	IBarTheme,
	IFontStyle,
} from '@support/alderbrooke-d3-graphs/dist/Tension/parts/bar/IBarTheme';
import { IHeatMapCellScale } from '@support/alderbrooke-d3-graphs/dist/Heatmap/HeatmapColorStyle';
import {
	Color,
	HeatmapTableStyle,
	HexColor,
	TensionGraphStyle,
	TensionGraphTheme,
	TensionPresetId,
} from './useColorPreset.types';

export const useTensionGraphColorPresetService = function () {
	const defaultFontStyle = createFontStyle('black', 'white');
	const defaultBarFontStyle = createBarFontStyle(defaultFontStyle, defaultFontStyle);
	const firstPreset = 0;
	const secondPreset = 1;
	const thirdPreset = 2;

	return {
		getPreset: (presetId: TensionPresetId): TensionGraphTheme => {
			switch (presetId) {
				case firstPreset:
					return createPreset1();
				case secondPreset:
					return createPreset2();
				case thirdPreset:
					return createPreset3();
				default:
					return createPreset1();
			}
		},
	};

	function createPreset1() {
		const scaleColor = {
			color1: cell('#fe4646', 'black'),
			color2: cell('#fed346', 'black'),
			color3: cell('#F0F0F0', 'black'),
			color4: cell('#c3dfad', 'black'),
			color5: cell('#85a66f', 'black'),
		};

		const barTheme = createBarTheme(
			createBarStyle('#b32a7a', defaultBarFontStyle),
			createBarStyle('#482164', defaultBarFontStyle),
		);

		return create(tension(barTheme), heatmap(scaleColor));
	}

	function createPreset2() {
		const scaleColor = {
			color1: cell('#3A3559', 'white'),
			color2: cell('#6D4E9E', 'white'),
			color3: cell('#F0F0F0', 'black'),
			color4: cell('#D773B8', 'white'),
			color5: cell('#A72F83', 'white'),
		};

		const barTheme = createBarTheme(
			createBarStyle('#8FAADC', defaultBarFontStyle),
			createBarStyle('#A5A5A5', defaultBarFontStyle),
		);

		return create(tension(barTheme), heatmap(scaleColor));
	}

	function createPreset3() {
		const scaleColor = {
			color1: cell('#4A3F3C', 'white'),
			color2: cell('#CB6417', 'white'),
			color3: cell('#999999', 'white'),
			color4: cell('#87C9EE', 'white'),
			color5: cell('#3286B4', 'white'),
		};

		const inside = createFontStyle('black', 'black');
		const fontStyle = createBarFontStyle(inside, defaultFontStyle);

		const barTheme = createBarTheme(
			createBarStyle('#232F41', defaultBarFontStyle),
			createBarStyle('#EFA603', fontStyle),
		);

		return create(tension(barTheme), heatmap(scaleColor));
	}

	function normalStyle(positive: IBarColorStyle, negative: IBarColorStyle): IBarStyle {
		return {
			borderColor: 'black',
			negative: negative,
			positive: positive,
		};
	}

	function createFontStyle(border: HexColor, color: HexColor): IFontStyle {
		return { border, color };
	}

	function createBarFontStyle(inside: IFontStyle, outside: IFontStyle): IBarFontStyle {
		return { inside, outside };
	}

	function createBarStyle(color: HexColor, font: IBarFontStyle): IBarColorStyle {
		if (font === undefined) {
			return {
				color,
				font: defaultBarFontStyle,
			};
		}
		return {
			color,
			font,
		};
	}

	function createBarTheme(positive: IBarColorStyle, negative: IBarColorStyle): IBarTheme {
		const style = normalStyle(positive, negative);
		return {
			normal: style,
			onHover: style,
		};
	}

	function tension(barTheme: IBarTheme): TensionGraphStyle {
		return { barTheme };
	}

	function heatmap(scaleColor: IHeatMapCellScale): HeatmapTableStyle {
		return { scaleColor };
	}

	function create(tension: TensionGraphStyle, heatmapTable: HeatmapTableStyle): TensionGraphTheme {
		return { tension, heatmapTable };
	}

	function cell(backgroundColor: Color, textColor: Color) {
		if (textColor === undefined) {
			textColor = 'black';
		}
		return { backgroundColor, textColor };
	}
};
