import { ChartColors } from 'common/constants';
import { GraphDnaSettings } from '../benchmarkSlice.types';

export const defaultBenchmark1: GraphDnaSettings = {
	personal: {
		selected: true,
		lineType: 'SOLID',
		lineColor: ChartColors.SlimyGreen,
	},
	organisational: {
		selected: true,
		lineType: 'DASHED',
		lineColor: ChartColors.ChineseGold,
	},
	pno: {
		selected: false,
	},
};

export const defaultBenchmark2: GraphDnaSettings = {
	personal: {
		selected: true,
		lineType: 'SOLID',
		lineColor: ChartColors.Olivine,
	},
	organisational: {
		selected: true,
		lineType: 'DASHED',
		lineColor: ChartColors.BlueViolet,
	},
	pno: {
		selected: false,
	},
};

export const defaultBenchmark3: GraphDnaSettings = {
	personal: {
		selected: true,
		lineType: 'SOLID',
		lineColor: ChartColors.SeaSerpent,
	},
	organisational: {
		selected: true,
		lineType: 'DASHED',
		lineColor: ChartColors.DarkBlue,
	},
	pno: {
		selected: false,
	},
};

export const defaultBenchmark4: GraphDnaSettings = {
	personal: {
		selected: true,
		lineType: 'SOLID',
		lineColor: ChartColors.Ochre,
	},
	organisational: {
		selected: true,
		lineType: 'DASHED',
		lineColor: ChartColors.KombuGreen,
	},
	pno: {
		selected: false,
	},
};

export const defaultBenchmark5: GraphDnaSettings = {
	personal: {
		selected: true,
		lineType: 'SOLID',
		lineColor: ChartColors.MorningBlue,
	},
	organisational: {
		selected: true,
		lineType: 'DASHED',
		lineColor: ChartColors.DeepLilac,
	},
	pno: {
		selected: false,
	},
};
