import { useDispatch, useSelector } from 'react-redux';
import {
	AccuracyChartBenchmark,
	AccuracyChartCluster,
	setAccuracyChartCombinedODNA,
	setAccuracyChartCombinedPDNA,
	setAccuracyChartODNA,
	setAccuracyChartPDNA,
} from 'store/slices/graphSlices';
import {
	defaultAccuracyChartBarColor,
	defaultAccuracyChartLabel,
} from 'features/Graphs/AccuracyChart/AccuracyChartConfig';
import { ProjectResultsGraphType, ProjectResultsGraphTypes } from 'features/Graphs';
import { assignBenchmarks, getBenchmarkName, getResponseClusters } from '../utils';
import { ApiGraphResponseBenchmark, ApiGraphResponseBenchmarks, ApiResultResponse } from 'store/api';
import { isCombinedPdna, isPdna } from 'features/Graphs/utils';
import { selectBenchmarkNames } from 'store/index';

export const useAccuracyChart = (result: ApiResultResponse) => {
	const benchmarkNames = useSelector(selectBenchmarkNames);
	const dispatch = useDispatch();

	function prepareValue(value: number | undefined) {
		const defaultValue = 0;
		const divider = 100;
		const minValue = 0.02;
		const maxValue = 1;
		if (!value) return defaultValue;

		const standardizedValue = value / divider;

		if (standardizedValue < minValue) {
			return minValue;
		}
		if (standardizedValue > maxValue) {
			return maxValue;
		}

		return standardizedValue;
	}

	function prepareLabel(benchmarkIndex: number, labelType: string) {
		const benchmarkName = getBenchmarkName(benchmarkNames, benchmarkIndex);
		return benchmarkName ? `${benchmarkName} - ${labelType}` : `b${benchmarkIndex} - ${labelType}`;
	}

	function prepareBenchmark(benchmark: ApiGraphResponseBenchmark | undefined, label: string) {
		return {
			colors: {
				accuracy: defaultAccuracyChartBarColor,
			},
			data: [
				{
					label,
					series: [
						{
							key: 'accuracy',
							value: prepareValue(benchmark?.accuracy),
						},
					],
				},
			],
			labels: {
				accuracy: defaultAccuracyChartLabel,
			},
		};
	}

	function createAccuracyBenchmarksBenchmarks(benchmarks: ApiGraphResponseBenchmarks, type: ProjectResultsGraphType) {
		const labelType = isPdna(type) || isCombinedPdna(type) ? 'PDNA' : 'ODNA';
		const benchmarksData: AccuracyChartBenchmark[] = [];

		const firstBenchmarkIndex = 1;
		const secondBenchmarkIndex = 2;
		const thirdBenchmarkIndex = 3;
		const fourthBenchmarkIndex = 4;
		const fifthBenchmarkIndex = 5;

		benchmarksData.push(prepareBenchmark(benchmarks.BENCHMARK_1, prepareLabel(firstBenchmarkIndex, labelType)));
		benchmarksData.push(prepareBenchmark(benchmarks.BENCHMARK_2, prepareLabel(secondBenchmarkIndex, labelType)));
		benchmarksData.push(prepareBenchmark(benchmarks.BENCHMARK_3, prepareLabel(thirdBenchmarkIndex, labelType)));
		benchmarksData.push(prepareBenchmark(benchmarks.BENCHMARK_4, prepareLabel(fourthBenchmarkIndex, labelType)));
		benchmarksData.push(prepareBenchmark(benchmarks.BENCHMARK_5, prepareLabel(fifthBenchmarkIndex, labelType)));

		return benchmarksData;
	}

	function prepareAccuracyChartData(type: ProjectResultsGraphType) {
		const clusters: AccuracyChartCluster[] = [];
		const responseClusters = getResponseClusters(result);

		Object.values(responseClusters).map((cluster) => {
			const benchmarks: ApiGraphResponseBenchmarks = {};
			assignBenchmarks(benchmarks, cluster, type);

			const benchmarksData: AccuracyChartBenchmark[] = createAccuracyBenchmarksBenchmarks(benchmarks, type);

			const currentCluster: AccuracyChartCluster = {
				benchmarks: benchmarksData,
			};
			clusters.push(currentCluster);
		});

		return { clusters: clusters };
	}

	const dataODNA = prepareAccuracyChartData(ProjectResultsGraphTypes.ODNA);
	const dataPDNA = prepareAccuracyChartData(ProjectResultsGraphTypes.PDNA);
	const dataCombinedPDNA = prepareAccuracyChartData(ProjectResultsGraphTypes.COMBINED_PDNA);
	const dataCombinedODNA = prepareAccuracyChartData(ProjectResultsGraphTypes.COMBINED_ODNA);

	dispatch(setAccuracyChartODNA(dataODNA));
	dispatch(setAccuracyChartPDNA(dataPDNA));
	dispatch(setAccuracyChartCombinedPDNA(dataCombinedPDNA));
	dispatch(setAccuracyChartCombinedODNA(dataCombinedODNA));
};
