import styled from 'styled-components';

export const Title = styled.h2`
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.text.secondary};
	margin: 0;
	font-size: 1.5rem;
	line-height: 32px;
	font-weight: 300;
	margin-top: 1rem;
`;

export const Hr = styled.hr`
	margin: 3rem 0 2rem 0;
`;
