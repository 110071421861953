import { useSelector } from 'react-redux';
import {
	selectRadarCombinedODNA,
	selectRadarCombinedPDNA,
	selectRadarConfig,
	selectRadarODNA,
	selectRadarOPDNA,
	selectRadarPDNA,
} from 'store/slices/graphSlices';
import { RadarGraph } from 'features/Graphs';
import { OPdnaRadarGraphProps } from './OPdnaRadarGraph.types';
import { useOPdnaRadarGraphData } from 'features/Graphs/RadarGraph/hooks/useOPdnaRadarGraphData';

export const OPdnaRadarGraph = ({
	containerId,
	clusterIndex,
	odnaBenchmarkIndexes,
	pdnaBenchmarkIndexes,
	pnoBenchmarkIndexes,
}: OPdnaRadarGraphProps) => {
	const config = useSelector(selectRadarConfig);

	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const odna = combinedMode ? useSelector(selectRadarCombinedODNA) : useSelector(selectRadarODNA);
	const pdna = combinedMode ? useSelector(selectRadarCombinedPDNA) : useSelector(selectRadarPDNA);
	const title = useSelector(selectRadarOPDNA);
	const base = { title, odna, pdna };
	const radarData = useOPdnaRadarGraphData(
		base,
		clusterIndex,
		odnaBenchmarkIndexes,
		pdnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
	);
	return (
		<RadarGraph
			config={config}
			containerId={containerId}
			data={radarData}
		/>
	);
};
