import { OPdnaDispersionChart } from 'features/Graphs/DispersionChart/hoc';
import { SimulationViewDispersionProps } from './SimulationViewDispersion.types';
import { StyledContainer } from './styled';

export const SimulationViewDispersion = ({
	personal,
	organisational,
	pno,
	selectedClusterIndex,
	colorPresetIndex,
}: SimulationViewDispersionProps) => {
	return (
		<>
			<StyledContainer id="1" />
			<>
				<OPdnaDispersionChart
					containerId={'1'}
					clusterIndex={selectedClusterIndex}
					pdnaBenchmarkIndexes={personal}
					odnaBenchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					colorPresetIndex={colorPresetIndex}
				/>
			</>
		</>
	);
};
