import { useRoadmapGraphDataConverter } from 'features/RoadMap/views/RoadMapGraph/hooks';
import { RoadMapGraphStylesheet } from './RoadMapGraphStylesheet';
import { RoadMapGraphLayout } from './RoadMapGraphLayout';
import cytoscape, { Core } from 'cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscapeKlay from 'cytoscape-klay';
import popper from 'cytoscape-popper';
import { RoadmapGraph } from 'store/api/roadmapApi';
import { setupPopper } from './Popper';
import { RoadMapSettingsOptions } from 'features/RoadMap/components/RoadMapSettingsPanel/';
import { useEffect, useState } from 'react';

export const RoadMapGraph = ({
	data,
	style,
	setCy,
	exportStarted,
}: {
	data: RoadmapGraph;
	style: RoadMapSettingsOptions;
	setCy: (cy: Core) => void;
	exportStarted: boolean;
}) => {
	const chartData = useRoadmapGraphDataConverter(data);
	const [graphAlreadySet, setGraphAlreadySet] = useState(false);
	cytoscape.use(cytoscapeKlay);
	cytoscape.use(popper);

	const maxZoom = 4;

	const changePoppersVisibility = (poppers: NodeListOf<Element>, oldValue: string, newValue: string) => {
		for (let i = 0; i < poppers.length; i++) {
			const popperStyle = poppers[i].getAttribute('style');
			poppers[i].setAttribute('style', popperStyle?.replace(oldValue, newValue) || '');
		}
	};

	useEffect(() => {
		const poppers = document.querySelectorAll('div[data-popper-placement]');
		if (exportStarted && poppers.length) {
			changePoppersVisibility(poppers, 'visible', 'hidden');
		} else if (poppers.length) {
			changePoppersVisibility(poppers, 'hidden', 'visible');
		}
	}, [exportStarted]);

	return (
		<CytoscapeComponent
			id="cytoscape"
			elements={chartData}
			stylesheet={RoadMapGraphStylesheet(style, exportStarted)}
			style={{ width: '100%', height: '100%' }}
			layout={RoadMapGraphLayout}
			maxZoom={maxZoom}
			minZoom={1}
			wheelSensitivity={0.2}
			cy={(cy) => {
				if (!graphAlreadySet) {
					setupPopper(cy);
					setGraphAlreadySet(true);
					cy.zoom(maxZoom);
					setCy(cy);
				}
			}}
		/>
	);
};
