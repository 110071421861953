import { RadarGraphProps } from './RadarGraph.types';
import { useRadarGraphCreator } from './hooks/useRadarGraphCreator';
import { useDimensions } from 'common/hooks';
import { GraphContainerWithTooltips } from '../common/components';

export const RadarGraph = ({ containerId, config, data }: RadarGraphProps) => {
	const { dimensions } = useDimensions();
	const buildGraph = useRadarGraphCreator(config, data).createGraph;

	return (
		<GraphContainerWithTooltips
			containerId={containerId}
			data={data}
			buildGraph={buildGraph}
			dimensions={dimensions}
		/>
	);
};
