import { rootApi, getFilenameFromResponse } from 'store/api';
import { DatabaseExportOptions, GetDatabaseExportCsvResponse } from './databaseExportApi.types';

export const databaseExportApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getExportedFile: builder.mutation<GetDatabaseExportCsvResponse, DatabaseExportOptions>({
			query: (opts) => ({
				method: 'POST',
				url: `/api/exports/results/csv`,
				body: {
					exportType: opts.exportType,
					projectId: opts.projectId,
					tenantId: opts.tenantId,
				},
				responseHandler: async (response) => {
					if (!response.ok) return;
					const defaultFilename = 'exported_results.csv';
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});

export const { useGetExportedFileMutation } = databaseExportApi;
