import { Criterion } from 'common/types';

export class AtLeastOneCriteriaShouldBeEnabled extends Error {}
export class CriteriaHasInvalidFormat extends Error {}
export const useDiscoveryInsightsCriteriaValidator = () => {
	return {
		validate,
	};

	function checkRequirements(criterion: Criterion): boolean {
		return required(criterion) && nameIsNotToLong(criterion.name) && nameIsNotEmpty(criterion.name);
	}

	function validate(criteria: Criterion[]) {
		const atLeastOneShouldBeEnabled = criteria.some(shouldBeEnabled);
		if (!atLeastOneShouldBeEnabled) {
			throw new AtLeastOneCriteriaShouldBeEnabled();
		}

		const allCriteriaMetRequirements = criteria.map(checkRequirements).every(requirementIsMet);
		if (!allCriteriaMetRequirements) {
			throw new CriteriaHasInvalidFormat();
		}
		return true;
	}

	function required(criterion: Criterion) {
		return criterion !== undefined;
	}

	function nameIsNotToLong(name: string) {
		const lengthLimit = 30;
		return name.length <= lengthLimit;
	}

	function nameIsNotEmpty(name: string) {
		const notEmpty = 0;
		return name.length !== notEmpty;
	}

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function shouldBeEnabled(criteria: Criterion) {
		return criteria.enabled;
	}
};
