import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumberOfItemsOdd } from 'common/utils';
import { BehaviourListItem } from '../BehaviourListItem';
import { BehaviourListProps } from './BehaviourList.types';
import { GridList, StyledButton } from './styled';

export const BehaviourList = ({ children, onAddNewBehaviourClick }: BehaviourListProps) => {
	const getListItems = () => {
		const listItems = children;
		const ListItemsWithEmptyEndElement = (
			<>
				{listItems}
				<BehaviourListItem hideDeleteIcon />
			</>
		);

		if (isNumberOfItemsOdd(listItems)) {
			return ListItemsWithEmptyEndElement;
		}

		return listItems;
	};

	const areChildrenPresent = !!children.length;

	return (
		<>
			<GridList $disabledBorder={!areChildrenPresent}>{getListItems()}</GridList>
			<StyledButton
				variant="filled"
				startIcon={<FontAwesomeIcon icon={faPlus} />}
				onClick={onAddNewBehaviourClick}
			>
				Add New Behaviours
			</StyledButton>
		</>
	);
};
