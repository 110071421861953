import { Input } from 'common/components';
import { RequiredFormInputState } from 'common/types';
import { useEffect, useState } from 'react';
import {
	createFormState,
	emptyIfNullOrUndefined,
	handleRequiredFormMailBlur,
	handleRequiredFormValue,
} from 'common/utils';
import { ParticipantInformationProps } from './ParticipantInformation.types';
import { useLazyCheckParticipantEmailAvailabilityQuery } from 'store/api';
import { ParticipantInformationState, setParticipantInformationState } from 'store/slices';
import { useDispatch } from 'react-redux';

export const ParticipantInformation = ({ saveTrigger, ...initialValues }: ParticipantInformationProps) => {
	const [participantIdEmail, setParticipantIdEmail] = useState<RequiredFormInputState>(
		createFormState(initialValues.emailAddress),
	);
	const [firstName, setFirstName] = useState<RequiredFormInputState>(createFormState(initialValues.firstName));
	const [middleName, setMiddleName] = useState(emptyIfNullOrUndefined(initialValues.middleName));
	const [lastName, setLastName] = useState<RequiredFormInputState>(createFormState(initialValues.lastName));
	const [salutation, setSalutation] = useState(emptyIfNullOrUndefined(initialValues.salutation));
	const [title, setTitle] = useState(emptyIfNullOrUndefined(initialValues.title));

	const [validateAvailability] = useLazyCheckParticipantEmailAvailabilityQuery();
	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormMailBlur(
			participantIdEmail.value,
			'E-mail is required!',
			'E-mail does not have correct format!',
			setParticipantIdEmail,
		);
		handleRequiredFormValue(firstName.value, 'First Name is required!', setFirstName);
		handleRequiredFormValue(lastName.value, 'Last Name is required!', setLastName);
		validateParticipantEmailAvailability();
	};

	const validateParticipantEmailAvailability = async () => {
		if (initialValues.emailAddress === participantIdEmail.value) {
			handleRequiredFormMailBlur(
				participantIdEmail.value,
				'E-mail is required!',
				'E-mail does not have correct format!',
				setParticipantIdEmail,
			);
			return;
		}

		if (participantIdEmail.value) {
			const { available } = await validateAvailability({ assesseeEmail: participantIdEmail.value }).unwrap();

			if (!available) {
				setParticipantIdEmail({
					value: participantIdEmail.value,
					error: !available,
					errorText: 'This email is already used!',
				});
			}
		}
	};

	const setSliceState = () => {
		const sliceState: ParticipantInformationState = {
			firstName: firstName.value,
			lastName: lastName.value,
			middleName: middleName,
			emailAddress: participantIdEmail.value,
			salutation: salutation,
			title: title,
		};

		dispatch(setParticipantInformationState(sliceState));
	};

	return (
		<>
			<Input
				{...participantIdEmail}
				name="Email"
				errorPlacement="right"
				formInput
				maxLength={50}
				required
				onChange={(event) => {
					handleRequiredFormMailBlur(
						event.target.value,
						'E-mail is required!',
						'E-mail does not have correct format!',
						setParticipantIdEmail,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormMailBlur(
						event.target.value,
						'E-mail is required!',
						'E-mail does not have correct format!',
						setParticipantIdEmail,
					);
					validateParticipantEmailAvailability();
					setSliceState();
				}}
			/>
			<Input
				{...firstName}
				name="First Name"
				errorPlacement="right"
				maxLength={30}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'First Name is required!', setFirstName);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'First Name is required!', setFirstName);
					setSliceState();
				}}
			/>
			<Input
				value={middleName}
				name="Middle Names"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setMiddleName(event.target.value);
				}}
				onBlur={(event) => {
					setMiddleName(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				{...lastName}
				name="Last Name"
				errorPlacement="right"
				formInput
				maxLength={30}
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Last Name is required!', setLastName);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Last Name is required!', setLastName);
					setSliceState();
				}}
			/>
			<Input
				value={salutation}
				name="Salutation"
				maxLength={10}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setSalutation(event.target.value);
				}}
				onBlur={(event) => {
					setSalutation(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				value={title}
				name="Title"
				maxLength={50}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setTitle(event.target.value);
				}}
				onBlur={(event) => {
					setTitle(event.target.value);
					setSliceState();
				}}
			/>
		</>
	);
};
