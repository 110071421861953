import { PageLayout, NotificationTypes, useNotifications, DropdownItem, FormDropdown } from 'common/components';
import { useState } from 'react';
import { downloadFile } from 'common/utils';
import { useClientProjects, useTenants } from 'common/hooks';
import { Tenant, TenantProject } from 'common/types';
import { ExportButton, ExportButtonBlock, ButtonWrapper } from './styled';
import { useGetExportedFileMutation } from 'store/api';
import { ExportTypes, defaultExportType } from './DatabaseExport.types';

export const DatabaseExport = () => {
	const { projects } = useClientProjects();
	const { tenants } = useTenants();
	const [exportFile] = useGetExportedFileMutation();
	const { pushNotification } = useNotifications();
	const [client, setClient] = useState('');
	const [projectShortId, setProjectShortId] = useState('');
	const [dataExportType, setDataExportType] = useState(defaultExportType);

	const tenantClients = tenants.filter(function (tenant: Tenant) {
		return projects.some(function (project) {
			return project.tenantId === tenant.tenantId;
		});
	});

	const exportedDataClients = tenantClients.map((item: Tenant) => ({
		projectId: item.tenantId,
		tenantId: item.tenantId,
		name: item.name,
		id: item.tenantId,
	}));

	const getTenantProjects = (client: string, projects: TenantProject[]) => {
		const tenant = exportedDataClients.find((currentValue) => currentValue.name === client);
		const tenantProjects = client
			? projects.filter(function (project) {
					return tenant?.tenantId === project.tenantId;
			  })
			: [];
		return tenantProjects;
	};

	const tenantProjects = getTenantProjects(client, projects);
	const exportedDataProject = tenantProjects.map((item: TenantProject) => ({
		projectId: item.projectId,
		tenantProjectId: item.tenantProjectId,
		shortId: item.shortId,
	}));

	const handleDropdownClientList = (newValue: string) => {
		setClient(newValue);
		setProjectShortId('');
	};

	const handleDropdownProjectShortIDList = (newValue: string) => {
		setProjectShortId(newValue);
	};

	const handleDropdownExportTypesList = (newValue: string) => {
		setDataExportType(newValue);
	};

	const createExportInfo = () => {
		const clientId = exportedDataClients.find((currentValue) => currentValue.name === client);
		const projectsId = exportedDataProject.find((currentValue) => currentValue.shortId === projectShortId);
		const exportType = ExportTypes.find((currentValue) => currentValue.name === dataExportType);
		const exportInfo = {
			exportType: exportType?.id ?? defaultExportType,
			projectId: projectsId?.tenantProjectId ?? '',
			tenantId: clientId?.tenantId ?? '',
		};
		return exportInfo;
	};

	const onExportCSVButtonClick = async () => {
		const exportInfo = createExportInfo();
		try {
			const response = await exportFile(exportInfo).unwrap();
			downloadFile(response.objectUrl, response.filename);
		} catch {
			pushNotification({
				message: `Select a client and project ID to export.`,
				type: NotificationTypes.Warning,
			});
		}
	};

	const dataBaseExportList = (data: TenantProject[], selectedValue?: string) => {
		const tenantsProjects = data.map((tenantProject) => {
			const tenantProjectName = tenantProject.name ?? tenantProject.shortId;
			const tenantProjectKey = tenantProject.tenantId ?? tenantProject.shortId;
			const tenantProjectValue = tenantProject.name ?? tenantProject.shortId;
			return (
				<DropdownItem
					key={tenantProjectKey}
					value={tenantProjectValue}
				>
					{tenantProjectName}
				</DropdownItem>
			);
		});
		if (selectedValue === '') {
			tenantsProjects.unshift(
				<DropdownItem
					key="empty"
					value=""
				>
					{''}
				</DropdownItem>,
			);
		}
		return tenantsProjects;
	};

	return (
		<PageLayout
			title="Database Export"
			id="client-dimension-tooltips-configuration"
		>
			<FormDropdown
				value={client}
				onChange={(newValue) => handleDropdownClientList(newValue)}
				customLabel={client}
				title={'Client'}
			>
				{dataBaseExportList(tenantClients, client)}
			</FormDropdown>
			<FormDropdown
				disabled={!client}
				value={projectShortId}
				onChange={(newValue) => handleDropdownProjectShortIDList(newValue)}
				customLabel={projectShortId}
				title={'Project Short ID'}
			>
				{dataBaseExportList(tenantProjects, projectShortId)}
			</FormDropdown>
			<FormDropdown
				value={dataExportType}
				onChange={(newValue) => handleDropdownExportTypesList(newValue)}
				customLabel={dataExportType}
				title={'Export type'}
			>
				{dataBaseExportList(ExportTypes)}
			</FormDropdown>
			<ExportButtonBlock>
				<ButtonWrapper>
					<ExportButton
						onClick={() => {
							onExportCSVButtonClick();
						}}
					>
						EXPORT
					</ExportButton>
				</ButtonWrapper>
			</ExportButtonBlock>
		</PageLayout>
	);
};
