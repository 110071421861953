import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledImgLink = styled(NavLink)`
	width: 100%;
	height: fit-content;
	max-height: 20%;
	display: flex;
`;

export const StyledLogo = styled.img`
	width: 100%;
	object-fit: contain;
	padding: 0.625rem;
	border-top: 1px solid ${({ theme }) => theme.colors.common.silver};
`;
