import { ZIndexes } from 'common/constants';
import styled from 'styled-components';

const MENU_WIDTH_REM = 50;
export const MenuContainer = styled.div`
	position: absolute;
	height: 100vh;
	z-index: ${ZIndexes.simulationSettingsMenu};
	background-color: ${({ theme }) => theme.colors.common.white};
	transform: ${`translate3d(-${MENU_WIDTH_REM}rem, 0, 0)`};

	width: ${`${MENU_WIDTH_REM}rem`};

	&.enter {
		visibility: visible;
	}

	&.enter-active {
		visibility: visible;
		transform: translate3d(0, 0, 0);
		transition: transform 750ms ease-in-out;
	}

	&.enter-done {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	&.exit {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	&.exit-active {
		visibility: visible;
		transform: ${`translate3d(-${MENU_WIDTH_REM}rem, 0, 0)`};
		transition: transform 750ms ease-in-out;
	}

	&.exit-done {
		transform: translate3d(0, 0, 0);
		visibility: hidden;
	}
`;

export const SettingsMenuOverlay = styled.div`
	position: absolute;
	height: 100vh;
	width: 100vw;
	visibility: hidden;
	opacity: 0;
	background-color: ${({ theme }) => theme.colors.common.black};
	z-index: ${ZIndexes.simulationSettingsMenuOverlay};

	&.enter {
		visibility: visible;
		opacity: 0;
	}

	&.enter-active {
		visibility: visible;
		opacity: 0.5;
		transition: opacity 250ms ease-in;
	}

	&.enter-done {
		visibility: visible;
		opacity: 0.5;
	}

	&.exit {
		visibility: visible;
		opacity: 0.5;
	}

	&.exit-active {
		visibility: visible;
		opacity: 0;
		transition: opacity 250ms ease-in;
	}

	&.exit-done {
		visibility: hidden;
		opacity: 0;
	}
`;
