import { ApiReducedTenant, ApiTenant, ReducedTenant, Tenant } from 'common/types';
import { AllTenantsResponse, ReducedTenantsListResponse } from 'store/api';

export const mapGetAllTenantsResponse = (response: AllTenantsResponse<ApiTenant>): AllTenantsResponse<Tenant> => {
	const { tenants } = response;

	const mappedTenants: Tenant[] = tenants.map((tenant) => {
		return mapApiTenant(tenant);
	});

	return { tenants: mappedTenants };
};

export const mapApiTenant = (tenant: ApiTenant): Tenant => {
	const { id, ...tenantCopy } = tenant;
	return {
		...tenantCopy,
		tenantId: id,
	};
};

export const mapGetReducedTenantsListResponse = (
	response: ReducedTenantsListResponse<ApiReducedTenant>,
): ReducedTenantsListResponse<ReducedTenant> => {
	const apiReducedTenants = response;

	const mappedReducedTenants: ReducedTenant[] = apiReducedTenants.map((reducedTenant) => {
		return mapApiReducedTenant(reducedTenant);
	});

	return mappedReducedTenants;
};

export const mapApiReducedTenant = (apiReducedTenant: ApiReducedTenant): ReducedTenant => {
	const { id, ...tenantCopy } = apiReducedTenant;
	return {
		...tenantCopy,
		tenantId: id,
	};
};
