export const TableSortingDirections = {
	Asc: 'ASC',
	Desc: 'DESC',
} as const;

export const TableSelectionFilters = {
	Selected: 'SELECTED',
	Unselected: 'UNSELECTED',
	All: 'ALL',
} as const;
