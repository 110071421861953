import { OptionalExceptFor } from 'common/types';

export const NotificationTypes = {
	/**
	 * Error - Notification type to be used when the result isn't necessarily a direct result of user interaction. For example when user session expired.
	 */
	Error: 'error',
	/**
	 * Warning - Notification type to be used when the result is a direct result of user interaction. For example wrong data input into filter field.
	 */
	Warning: 'warning',
	/**
	 * Info - Notification type to be used when the notification is just an information. The result is neither a success nor a fail.
	 */
	Info: 'info',
	/**
	 * Success - Notification type to be used when action ended successfully.
	 */
	Success: 'success',
} as const;

export type NotificationType = typeof NotificationTypes[keyof typeof NotificationTypes];

export interface NotificationsContextProps {
	children: React.ReactNode;
	interval: number;
}

export interface NotificationPropsWithoutTimeout {
	id: string;
	message: string | React.ReactNode;
	type: NotificationType;
	disableAutoHide?: false;
	timeout?: never;
}

export interface NotificationPropsWithTimeout {
	id: string;
	message: string | React.ReactNode;
	type: NotificationType;
	disableAutoHide?: boolean;
	timeout?: number;
}

export interface StyledNotificationProps {
	$type: NotificationType;
}

export type AddNotification = OptionalExceptFor<
	NotificationPropsWithoutTimeout | NotificationPropsWithTimeout,
	'message'
>;

export type NotificationsContextApi = {
	notifications: (NotificationPropsWithoutTimeout | NotificationPropsWithTimeout)[];
	pushNotification: (notification: AddNotification) => string;
	clearNotification: (id: string) => null;
	hideNotification: (id: string) => null;
};
