import { ForwardedRef, forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { PopperProps } from './Popper.types';
import { Container } from './styled';

const initBottomPosition = 0;
const initLeftPosition = 0;
const _Popper = ({ anchorRef, isVisible, children }: PopperProps, customPopperRef: ForwardedRef<HTMLDivElement>) => {
	const [bottomPosition, setBottomPosition] = useState(initBottomPosition);
	const [leftPosition, setLeftPosition] = useState(initLeftPosition);
	const popperRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!anchorRef.current) return;

		const popperComponentRef = customPopperRef ?? popperRef;
		if (!popperComponentRef) return;
		const anchorElRect = anchorRef.current.getBoundingClientRect();
		setBottomPosition(anchorElRect.bottom);
		setLeftPosition(anchorElRect.left);
	}, [isVisible, anchorRef]);

	return (
		<Container
			$isVisible={isVisible}
			$bottom={bottomPosition}
			$left={leftPosition}
			ref={customPopperRef ?? popperRef}
		>
			{children}
		</Container>
	);
};

export const Popper = forwardRef<HTMLDivElement, PopperProps>(_Popper);

Popper.displayName = 'Popper';
