import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStakeholderContactState, StakeholderContactState } from 'store/slices';
import { handleFormMailBlur, handleFormTelephoneBlur, createFormState } from 'common/utils';
import { emptyIfNullOrUndefined } from 'common/utils';

import { StakeholderContractProps } from './StakeholderContact.types';
import { RequiredFormInputState } from 'common/types';

export const StakeholderContact = ({
	saveTrigger,
	secondaryContactIM: initialSecondaryContactIM,
	secondaryContactMail: initialSecondaryContactMail,
	secondaryContactMobile: initialSecondaryContactMobile,
	secondaryContactName: initialSecondaryContactName,
	secondaryContactTelephone: initialSecondaryContactTelephone,
}: StakeholderContractProps) => {
	const [secondaryContactName, setSecondaryContactName] = useState(
		emptyIfNullOrUndefined(initialSecondaryContactName),
	);
	const [secondaryContactIM, setSecondaryContactIM] = useState(emptyIfNullOrUndefined(initialSecondaryContactIM));

	const [secondaryContactMail, setSecondaryContactMail] = useState<RequiredFormInputState>(
		createFormState(initialSecondaryContactMail),
	);

	const [secondaryContactTelephone, setSecondaryContactTelephone] = useState<RequiredFormInputState>(
		createFormState(initialSecondaryContactTelephone),
	);

	const [secondaryContactMobile, setSecondaryContactMobile] = useState<RequiredFormInputState>(
		createFormState(initialSecondaryContactMobile),
	);

	const dispatch = useDispatch();

	useEffect(() => {
		setSliceState();
	}, [saveTrigger]);

	const setSliceState = () => {
		const sliceState: StakeholderContactState = {
			secondaryContactIM: secondaryContactIM,
			secondaryContactMail: secondaryContactMail.value,
			secondaryContactMobile: secondaryContactMobile.value,
			secondaryContactName: secondaryContactName,
			secondaryContactTelephone: secondaryContactTelephone.value,
		};

		dispatch(setStakeholderContactState(sliceState));
	};

	return (
		<>
			<Input
				name="Name of secondary contact"
				maxLength={50}
				value={secondaryContactName}
				formInput
				onChange={(event) => {
					setSecondaryContactName(event.target.value);
				}}
				onBlur={(event) => {
					setSecondaryContactName(event.target.value);
					setSliceState();
				}}
			/>
			<Input
				{...secondaryContactMail}
				name="Email of secondary contact"
				maxLength={50}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setSecondaryContactMail({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormMailBlur(
						event.target.value,
						'Secondary contact email does not have correct format!',
						setSecondaryContactMail,
					);
					setSliceState();
				}}
			/>
			<Input
				{...secondaryContactTelephone}
				name="Telephone of secondary contact"
				placeholder="Please use international format"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setSecondaryContactTelephone({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormTelephoneBlur(
						event.target.value,
						'Secondary contact telephone is not in correct format!',
						setSecondaryContactTelephone,
					);
					setSliceState();
				}}
			/>
			<Input
				{...secondaryContactMobile}
				name="Mobile number of secondary contact"
				placeholder="Please use international format"
				maxLength={30}
				errorPlacement="right"
				formInput
				onChange={(event) => {
					setSecondaryContactMobile({
						value: event.target.value,
						error: false,
						errorText: '',
					});
				}}
				onBlur={(event) => {
					handleFormTelephoneBlur(
						event.target.value,
						'Secondary contact mobile is not in correct format!',
						setSecondaryContactMobile,
					);
					setSliceState();
				}}
			/>
			<Input
				name="IM contact of secondary contact"
				maxLength={30}
				value={secondaryContactIM}
				formInput
				onChange={(event) => {
					setSecondaryContactIM(event.target.value);
				}}
				onBlur={(event) => {
					setSecondaryContactIM(event.target.value);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
