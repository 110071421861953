import styled, { css } from 'styled-components';
import {
	StyledDisabledProps,
	StyledErrorProps,
	StyledFormInputProps,
	StyledPlacementProps,
	StyledReadOnlyProps,
	StyledTextProps,
} from './Input.types';

const leftPlacement = css`
	flex-direction: row;
	justify-content: flex-start;
`;

const topPlacement = css`
	flex-direction: column;
	justify-content: flex-start;
`;

const checkboxRightPlacement = css`
	flex-direction: row;
`;

const errorRightPlacement = css`
	flex-direction: row;
`;

const errorBottomPlacement = css`
	flex-direction: column;
`;

const blackErrorText = css`
	color: ${({ theme }) => theme.colors.text.primary};
`;

const errorText = css`
	color: ${({ theme }) => theme.colors.error.main};
`;

const errorBorder = css`
	border-color: ${({ theme }) => theme.colors.error.main} !important;
`;

const fullInputWidth = css`
	width: 100%;
`;

const ContainerFormStyling = css`
	align-items: baseline;
	margin: 1rem;
`;

const InputContainerFormStyling = css`
	min-width: 45%;
	max-width: 45%;
`;

const InputLabelFormStyling = css`
	flex: 1;
	justify-content: flex-end;
	margin: 0;
`;

const InputWrapperFormStyling = css`
	flex: 2;
`;

const additionalIconStyling = css`
	align-items: center;
`;

const disabled = css`
	border-color: ${({ theme }) => theme.colors.disabled.main};
	color: ${({ theme }) => theme.colors.disabled.main};
	background-color: ${({ theme }) => theme.colors.disabled.light};
	pointer-events: none;
	cursor: default;
	& > ::placeholder {
		color: ${({ theme }) => theme.colors.disabled.main};
	}
`;

const readOnly = css`
	border-color: ${({ theme }) => theme.colors.common.grey};
	background-color: ${({ theme }) => theme.colors.common.white};
	pointer-events: none;
`;

export const CheckboxContainer = styled.div`
	margin-right: 1.25rem;
	margin-left: 0.3rem;
`;

export const Container = styled.div<StyledPlacementProps & StyledFormInputProps>`
	display: flex;

	${({ $labelPlacement }) => $labelPlacement === 'left' && leftPlacement};
	${({ $labelPlacement }) => $labelPlacement === 'top' && topPlacement};
	${({ $formInput }) => $formInput && ContainerFormStyling}
`;

export const InputContainer = styled.div<
	StyledErrorProps & StyledPlacementProps & StyledDisabledProps & StyledFormInputProps & StyledReadOnlyProps
>`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
	border: 1px solid;
	border-radius: 5px;
	font-size: 1rem;
	overflow: hidden;

	${({ $errorTextAlwaysVisible }) => $errorTextAlwaysVisible && blackErrorText};
	${({ $error }) => $error && errorText};
	border-color: ${({ theme }) => theme.colors.common.black};
	${({ $error, $disabledErrorBorder }) => $error && !$disabledErrorBorder && errorBorder};

	margin: ${({ $labelPlacement }) => $labelPlacement === 'left' && '0 1rem 0 1rem'};
	margin: ${({ $labelPlacement }) => $labelPlacement === 'top' && '0 0 0.5rem 0'};
	margin: ${({ $disableMargin }) => $disableMargin && '0'};
	${({ $disabled }) => $disabled && disabled};
	${({ $readOnly }) => $readOnly && readOnly};
	${({ $fullWidth }) => $fullWidth && fullInputWidth};
	${({ $formInput }) => $formInput && InputContainerFormStyling};
`;

export const InputLabel = styled.label<
	StyledErrorProps & StyledTextProps & StyledPlacementProps & StyledFormInputProps
>`
	display: flex;
	align-items: center;
	white-space: nowrap;
	${({ $errorTextAlwaysVisible }) => $errorTextAlwaysVisible && blackErrorText};
	${({ $error }) => $error && errorText};
	margin-bottom: ${({ $isErrorTextDeclared }) => $isErrorTextDeclared && '1.5rem'};
	margin-left: ${({ $labelPlacement }) => $labelPlacement === 'top' && '0.2rem'};
	text-transform: ${({ $labelTextTransform }) => $labelTextTransform};
	${({ $formInput }) => $formInput && InputLabelFormStyling};
`;

export const StyledInput = styled.input`
	width: 100%;
	outline: none;
	border: none;
	background-image: none;
	background-color: transparent;
	box-shadow: none;
	color: inherit;
	padding: 0.7rem;
	font-size: inherit;
`;

export const StyledEndElement = styled.div`
	padding: 0.7rem 0.7rem 0.7rem 0;
`;

export const ErrorText = styled.span<StyledErrorProps>`
	display: flex;
	align-items: center;
	min-height: ${({ $isErrorTextDeclared }) => $isErrorTextDeclared && '1.5rem'};
	line-height: 1.5rem;
	margin-left: 1rem;
	${({ $error }) => ($error ? errorText : blackErrorText)};
`;

export const InputWrapper = styled.div<Pick<StyledErrorProps, '$errorPlacement'> & StyledFormInputProps>`
	display: flex;
	width: 100%;
	${({ $additionalIcon }) => $additionalIcon && additionalIconStyling};
	${({ $formInput }) => $formInput && InputWrapperFormStyling};
	${({ $errorPlacement }) => $errorPlacement === 'bottom' && errorBottomPlacement};
	${({ $errorPlacement }) => $errorPlacement === 'right' && errorRightPlacement};
	${({ $checkbox }) => $checkbox && checkboxRightPlacement};
`;
