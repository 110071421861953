import { Input } from 'common/components';
import { RequiredFormInputState } from 'common/types';
import { useEffect, useState } from 'react';
import { useLazyCheckManagerIdAvailabilityQuery } from 'store/api';
import {
	createFormState,
	handleRequiredFormMailBlur,
	handleRequiredFormPasswordBlur,
	handleRequiredFormRepeatPasswordBlur,
	handleRequiredFormValue,
} from 'common/utils';
import { ManagerFormProps } from './ManagerForm.types';
import { PasswordValidator } from 'features/AuthManagement/views/ChangePasswordBox/utils';
import { CreateManagerSlice, setCreateManagerState } from 'store/slices';
import { useDispatch } from 'react-redux';

export const ManagerForm = ({ saveTrigger }: ManagerFormProps) => {
	const [validateAvailability] = useLazyCheckManagerIdAvailabilityQuery();

	const [managerIdEmail, setManagerIdEmail] = useState<RequiredFormInputState>(createFormState());
	const [managerName, setManagerName] = useState<RequiredFormInputState>(createFormState());

	const [password, setPassword] = useState<RequiredFormInputState>(createFormState());
	const [confirmPassword, setConfirmPassword] = useState<RequiredFormInputState>(createFormState());

	const validator = new PasswordValidator();
	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormMailBlur(
			managerIdEmail.value,
			'APD Mgr ID is required!',
			'APD Manager email does not have correct format!',
			setManagerIdEmail,
		);
		handleRequiredFormValue(managerName.value, 'APD Manager name is required!', setManagerName);
		handleRequiredFormPasswordBlur(
			password.value,
			'APD Manager password is required!',
			'Password must contain at least: 10 characters, one symbol, three digits, lowercase and uppercase letter!',
			validator,
			setPassword,
		);
		handleRequiredFormRepeatPasswordBlur(
			password.value,
			confirmPassword.value,
			'Password confirmation is required!',
			'Retyped password does not match original one!',
			validator,
			setConfirmPassword,
		);
		validateOrganisationIdAvailability();
	};

	const validateOrganisationIdAvailability = async () => {
		if (managerIdEmail.value) {
			const { available } = await validateAvailability({ username: managerIdEmail.value }).unwrap();

			if (!available) {
				setManagerIdEmail({
					value: managerIdEmail.value,
					error: !available,
					errorText: 'APD Manager already exists!',
				});
			}
		}
	};

	const setSliceState = () => {
		const sliceState: CreateManagerSlice = {
			username: managerIdEmail.value,
			name: managerName.value,
			password: password.value,
			retypedPassword: confirmPassword.value,
		};

		dispatch(setCreateManagerState(sliceState));
	};

	return (
		<>
			<Input
				{...managerIdEmail}
				name="Email (APD Mgr ID)"
				maxLength={80}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormMailBlur(
						event.target.value,
						'APD Mgr ID is required!',
						'APD Manager email does not have correct format!',
						setManagerIdEmail,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormMailBlur(
						event.target.value,
						'APD Mgr ID is required!',
						'APD Manager email does not have correct format!',
						setManagerIdEmail,
					);
					validateOrganisationIdAvailability();
					setSliceState();
				}}
			/>
			<Input
				{...managerName}
				name="Name"
				maxLength={30}
				errorPlacement="right"
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'APD Manager name is required!', setManagerName);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'APD Manager name is required!', setManagerName);
					setSliceState();
				}}
			/>
			<Input
				{...password}
				name="Password"
				maxLength={20}
				errorPlacement="right"
				formInput
				type="password"
				autoComplete="new-password"
				required
				onChange={(event) => {
					handleRequiredFormPasswordBlur(
						event.target.value,
						'APD Manager password is required!',
						'Password must contain at least: 10 characters, one symbol, three digits, lowercase and uppercase letter!',
						validator,
						setPassword,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormPasswordBlur(
						event.target.value,
						'APD Manager password is required!',
						'Password must contain at least: 10 characters, one symbol, three digits, lowercase and uppercase letter!',
						validator,
						setPassword,
					);
					setSliceState();
				}}
			/>
			<Input
				{...confirmPassword}
				name="Confirm Password"
				maxLength={20}
				errorPlacement="right"
				formInput
				required
				type="password"
				autoComplete="new-password"
				onChange={(event) => {
					handleRequiredFormRepeatPasswordBlur(
						password.value,
						event.target.value,
						'Password confirmation is required!',
						'Retyped password does not match original one!',
						validator,
						setConfirmPassword,
					);
				}}
				onBlur={(event) => {
					handleRequiredFormRepeatPasswordBlur(
						password.value,
						event.target.value,
						'Password confirmation is required!',
						'Retyped password does not match original one!',
						validator,
						setConfirmPassword,
					);
					setSliceState();
				}}
			/>
		</>
	);
};
