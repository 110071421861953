import { AddNotification, Input, NotificationTypes, useNotifications } from 'common/components';
import { CredentialsBox } from 'features/AuthManagement/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordResponse, useChangePasswordMutation, useLogoutMutation, resetStore } from 'store';
import { ButtonContainer, StyledButton } from './styled';
import { PasswordValidationResult, PasswordValidator, parseBackendValidationResponse } from './utils';

export const ChangePasswordBox = () => {
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [repeatedNewPassword, setRepeatedNewPassword] = useState('');
	const [notificationId, setNotificationId] = useState('');

	const [oldPasswordError, setOldPasswordError] = useState(false);
	const [newPasswordError, setNewPasswordError] = useState(false);
	const [repeatedNewPasswordError, setRepeatedNewPasswordError] = useState(false);

	const navigate = useNavigate();
	const { pushNotification, hideNotification } = useNotifications();
	const [changePassword] = useChangePasswordMutation();
	const [logout] = useLogoutMutation();

	const validator = new PasswordValidator();

	const goToPreviousPage = () => {
		const toPreviousPage = -1;
		navigate(toPreviousPage);
	};

	const handleConfirmClick = async () => {
		const allFieldsAreFilled = checkIfAllFieldsAreFilled();

		if (!allFieldsAreFilled) {
			handleEmptyFields();
			return;
		}

		const validation = validator.validatePasswords(newPassword, repeatedNewPassword);

		if (!validation.success) {
			handleValidationFail(validation.message);
			return;
		}
		await changePassword({
			oldPassword,
			newPassword,
		})
			.unwrap()
			.then((success: ChangePasswordResponse) => {
				const parsedBackendValidation = parseBackendValidationResponse(success);
				handleBackendValidation(parsedBackendValidation);
			})
			.catch((fail: ChangePasswordResponse) => {
				const parsedBackendValidation = parseBackendValidationResponse(fail);
				handleBackendValidation(parsedBackendValidation);
			});
	};

	const onCancelButtonClick = () => {
		hideNotification(notificationId);
		goToPreviousPage();
	};

	const checkIfAllFieldsAreFilled = () => {
		return !!oldPassword && !!newPassword && !!repeatedNewPassword;
	};

	const handleEmptyFields = () => {
		setOldPasswordError(!oldPassword);
		setNewPasswordError(!newPassword);
		setRepeatedNewPasswordError(!repeatedNewPassword);

		replaceNotification({
			message: 'All fields are required',
			type: NotificationTypes.Warning,
			disableAutoHide: true,
		});
	};

	const handleValidationFail = (errorMessage: string) => {
		setOldPasswordError(false);
		setNewPasswordError(true);
		setRepeatedNewPasswordError(true);

		replaceNotification({
			message: errorMessage,
			disableAutoHide: true,
			type: NotificationTypes.Warning,
		});
	};

	const handleBackendValidation = async (backendValidation: PasswordValidationResult) => {
		setNewPasswordError(false);
		setRepeatedNewPasswordError(false);

		if (backendValidation.success) {
			resetStore();
			handleBackendValidationSuccess(backendValidation.message);
			await logout();
			return;
		}

		handleBackendValidationFail(backendValidation.message);
		return;
	};

	const handleBackendValidationSuccess = (message: string) => {
		replaceNotification({
			message,
			type: NotificationTypes.Success,
		});

		goToPreviousPage();
	};

	const handleBackendValidationFail = (message: string) => {
		replaceNotification({
			message,
			disableAutoHide: true,
			type: NotificationTypes.Warning,
		});

		setOldPasswordError(true);
	};

	const replaceNotification = (notification: AddNotification) => {
		hideNotification(notificationId);
		setNotificationId(pushNotification(notification));
	};

	return (
		<CredentialsBox title="Change Password">
			<form>
				<Input
					name="Enter Old Password:"
					labelTextTransform="uppercase"
					labelPlacement="top"
					type="password"
					autoComplete="current-password"
					value={oldPassword}
					error={oldPasswordError}
					onChange={(event) => {
						setOldPassword(event.currentTarget.value);
					}}
				/>
				<Input
					name="Enter New Password:"
					labelPlacement="top"
					labelTextTransform="uppercase"
					type="password"
					autoComplete="new-password"
					value={newPassword}
					error={newPasswordError}
					onChange={(event) => {
						setNewPassword(event.currentTarget.value);
					}}
				/>
				<Input
					name="Repeat New Password:"
					labelPlacement="top"
					labelTextTransform="uppercase"
					type="password"
					autoComplete="new-password"
					value={repeatedNewPassword}
					error={repeatedNewPasswordError}
					onChange={(event) => {
						setRepeatedNewPassword(event.currentTarget.value);
					}}
				/>
				<ButtonContainer>
					<StyledButton
						variant="filled"
						color="primary"
						underlineOnHover
						onClick={handleConfirmClick}
					>
						Confirm
					</StyledButton>
					<StyledButton
						variant="filled"
						color="inherit"
						underlineOnHover
						onClick={onCancelButtonClick}
					>
						Cancel
					</StyledButton>
				</ButtonContainer>
			</form>
		</CredentialsBox>
	);
};
