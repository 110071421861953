import styled, { css } from 'styled-components';
import { StyledTabPanelContainerProps } from './TabPanel.types';

const contentHidden = css`
	display: none;
`;

export const TabPanelContainer = styled.div<StyledTabPanelContainerProps>`
	display: flex;
	flex-direction: column;
	width: 100%;

	${({ hidden }) => hidden && contentHidden};
`;
