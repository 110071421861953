import { OddsRatio } from 'store/api/predictiveApi';
import {
	IVerticalBarGraph,
	OddsRatioCfg,
	OddsRatioDescription,
	OddsRatioLegendCfg,
} from 'features/Graphs/OddsRatioGraph/OddsRatioGraph.types';
import { charts } from '@support/alderbrooke-d3-graphs';
import SvgElement from '@support/alderbrooke-d3-graphs/dist/Common/SvgElement';
import { IChartData, IDescription } from '@support/alderbrooke-d3-graphs/dist/VerticalBar/IChartData';
import { YAxisScaler } from 'features/Graphs/OddsRatioGraph';
import { GraphDataFactory } from './GraphDataFactory';
import { GraphDescriptionFactory } from './GraphDescriptionFactory';
import { SVGGraph } from 'store/api/predictiveExportApi';

export class VerticalBarFactory {
	public createVerticalBar(
		title: string,
		data: OddsRatio,
		description: OddsRatioDescription,
		config: OddsRatioCfg,
		seriesConfig: OddsRatioLegendCfg,
	): IVerticalBarGraph {
		const graphData = {
			data: this._createGraphData(data),
			colors: this._createColors(seriesConfig),
			labels: this._createLabels(seriesConfig),
			description: this._createDescription(data, description),
		};

		const axisY = this._prepareScaledAxisY(graphData, config);
		const legend = { ...config.legend, title };
		const overwrittenConfig = { ...config, legend, axisY };
		const builder = new charts.VerticalBar.Builder(overwrittenConfig, graphData);

		return {
			getExportedSVG(): SVGGraph {
				builder.useAbsoluteDimensioning();
				const svg = builder.build();
				svg.dispose();
				return svg.getSvgAsXml();
			},
			getSVG(): SvgElement {
				return builder.build();
			},
			dispose() {
				builder.dispose();
			},
		};
	}

	private _createColors(seriesConfig: OddsRatioLegendCfg) {
		return {
			oddsFor: seriesConfig.verticalBarChartColors.oddsFor,
			oddsAgainst: seriesConfig.verticalBarChartColors.oddsAgainst,
		};
	}

	private _createLabels(seriesConfig: OddsRatioLegendCfg) {
		return {
			oddsFor: seriesConfig.verticalBarChartLabels.oddsFor,
			oddsAgainst: seriesConfig.verticalBarChartLabels.oddsAgainst,
		};
	}

	private _createDescription(data: OddsRatio, description: OddsRatioDescription): IDescription {
		return new GraphDescriptionFactory().createDescription(data, description);
	}

	private _prepareScaledAxisY(graphData: IChartData, config: OddsRatioCfg) {
		return new YAxisScaler().prepareScaledAxisY(graphData, config);
	}

	private _createGraphData(data: OddsRatio) {
		return new GraphDataFactory().create(data);
	}
}
