import { Dimension } from 'common/types';
import { BehaviourPairTileButton } from '../BehaviourPairTileButton';
import { BehaviourPairTileProps } from './BehaviourPairTile.types';
import { TileContainer } from './styled';

export const BehaviourPairTile = ({
	primaryDimension,
	contraryDimension,
	tooltipDirection,
	selectedDimensionId,
	onSelect,
}: BehaviourPairTileProps) => {
	const getSelectionVariant = (behaviour: Dimension) => {
		if (selectedDimensionId === undefined) return;

		return behaviour.dimensionId === selectedDimensionId ? 'selected' : 'not-selected';
	};

	return (
		<TileContainer>
			<BehaviourPairTileButton
				dimension={primaryDimension}
				selectionVariant={getSelectionVariant(primaryDimension)}
				onClick={onSelect}
				tooltipDirection={tooltipDirection}
			/>
			<BehaviourPairTileButton
				dimension={contraryDimension}
				selectionVariant={getSelectionVariant(contraryDimension)}
				onClick={onSelect}
				tooltipDirection={tooltipDirection}
			/>
		</TileContainer>
	);
};
