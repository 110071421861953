import styled from 'styled-components';

export const Textarea = styled.textarea`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	resize: none;
	outline: none;
	height: 100%;
	padding: 1rem;

	border-radius: 10px;
	border: 1px solid ${({ theme }) => theme.colors.common.black};
	height: 21.25rem;
	font-family: 'Mukta';

	&:focus {
		border: 2px solid ${({ theme }) => theme.colors.primary.main};
	}
`;
