import { DispersionChartConfig } from './DispersionChart.types';

export const dispersionChartConfig: DispersionChartConfig = {
	canvas: {
		margins: {
			left: 60,
			right: 0,
			bottom: 100,
			top: 50,
		},
		width: 1200,
		height: 500,
	},
	legend: {
		height: 85,
		strokeWidth: 4,
		textLeftMargin: 5,
		textRightMargin: 15,
		textSize: 10,
		textStyle: { 'font-weight': 'bold', 'font-family': 'Arial', fill: '#000000' },
		topMargin: 65,
		leftOffset: 0,
	},
	summary: {
		cellHeight: 39,
		cellWidth: 86,

		spacing: 18,
		columnSpacing: 8,
		rowSpacing: 4,

		textStyle: { 'font-size': '12px' },
		headerMainTextStyle: { 'font-family': 'Arial', 'font-size': '12px', 'font-weight': 'bold' },
		headerSubTextStyle: { 'font-family': 'Arial', 'font-size': '12px', 'font-weight': 'bold' },
		backgroundColor: '#d8d8d8',

		leftIdentifier: 'Per',
		rightIdentifier: 'Org',
	},
	ranges: [
		{ label: '<40%', from: 0, to: 39, color: '#ed6c6d' },
		{ label: '40% - 50%', from: 40, to: 49, color: '#ffe082' },
		{ label: '50% - 60%', from: 50, to: 59, color: '#f5f5f5' },
		{ label: '60% - 70%', from: 60, to: 69, color: '#c1d2b9' },
		{ label: '70% - 100%', from: 70, to: 100, color: '#6d9e6e' },
	],
	leftRangeDataIdentifier: 'pdna',
	colorOpacity: 1,
};
