import { charts } from '@support/alderbrooke-d3-graphs';
import { HeatmapGraphColorConfig, HeatmapGraphConfig, HeatmapGraphData } from 'features/Graphs/HeatmapGraph';

export const useHeatmapCreator = (
	config: HeatmapGraphConfig,
	data: HeatmapGraphData,
	colorConfig: HeatmapGraphColorConfig,
) => {
	return { createGraph, createGraphExport };

	function createGraph() {
		const graph = createBuilder();
		return graph.build();
	}

	function createGraphExport() {
		const graph = createBuilder();
		graph.useAbsoluteDimensioning();
		return graph.build();
	}

	function createBuilder() {
		return new charts.Heatmap.Builder(config, data, colorConfig);
	}
};
