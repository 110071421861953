import { charts } from '@support/alderbrooke-d3-graphs';
import {
	DispersionChartColorConfig,
	DispersionChartConfig,
	DispersionChartData,
} from 'features/Graphs/DispersionChart';

export const useDispersionChartCreator = (
	data: DispersionChartData,
	config: DispersionChartConfig,
	colorConfig: DispersionChartColorConfig,
) => {
	return { createGraph, createGraphExport };

	function createGraph() {
		const graph = createBuilder();
		return graph.build();
	}

	function createGraphExport() {
		const graph = createBuilder();
		graph.useAbsoluteDimensioning();
		return graph.build();
	}

	function createBuilder() {
		return new charts.DataDistribution.Builder(config, data, colorConfig);
	}
};
