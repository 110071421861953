import { RedirectingMasterLogo } from 'common/components/RedirectingMasterLogo';
import styled from 'styled-components';

export const StyledFooter = styled.div`
	position: absolute;
	left: 0;
	bottom: 2%;

	width: 100%;
	height: 3.5rem;

	display: inline-flex;
`;

export const StyledParagraph = styled.p`
	color: grey;
	font-size: 24px;
	margin: 0.5% 1% 0 1%;
	text-align: left;
`;

export const FooterLogo = styled(RedirectingMasterLogo)`
	height: 100%;
`;
