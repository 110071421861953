import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledTableIconButton } from './styled';
import React from 'react';
import { TableIconButtonProps } from './TableIconButton.types';

export const TableIconButton = ({ icon, href, onClick }: TableIconButtonProps) => {
	return (
		<StyledTableIconButton
			href={href}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={icon} />
		</StyledTableIconButton>
	);
};
