import { PageLayout } from 'common/components';
import { AliasedCriterion } from './AliasedCriterion/AliasedCriterion';
import { RoadmapCriteriaModalProps } from './RoadmapCriteriaModal.types';

export const RoadmapCriteriaModal = ({ criteria }: RoadmapCriteriaModalProps) => {
	const getEnabledCriteria = () => {
		return criteria?.map((criterion) => (
			<AliasedCriterion
				key={criterion.criteriaId}
				criterionName={criterion.defaultName}
				criterionAlias={criterion.aliasName}
			/>
		));
	};
	return (
		<PageLayout
			title="Roadmap Criteria"
			id="roadmap-criteria"
		>
			{getEnabledCriteria()}
		</PageLayout>
	);
};
