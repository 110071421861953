import { useEffect, useState } from 'react';
import {
	DiscoveryInsightModeSelectionProps,
	QuadrantGraphType,
	QuadrantGraphTypes,
} from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantTypeSelection/QuadrantTypeSelection.types';
import { StyledButton, StyledCriteriaAndLegendSelection, StyledModeSelection, StyledRow } from './styled';
import { useSelector } from 'react-redux';
import { selectChartSettings } from 'store/slices';
import { CriteriaMenuDropdown } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/CriteriaMenuDropdown/CriteriaMenuDropdown';
import { QuadrantLegendSortingDropdown } from 'features/DiscoveryInsights/components/DiscoveryInsightResultsMenu/components/QuadrantLegendSortingDropdown/QuadrantLegendSortingDropdown';

export const QuadrantTypeSelection = ({
	criteria,
	criterion: initialCriterion,
	quadrantSorting: initialSorting,
	quadrantType: initialQuadrantType,
	onStateChange,
}: DiscoveryInsightModeSelectionProps) => {
	const [quadrantType, setQuadrantType] = useState(initialQuadrantType);
	const [criterion, setCriterion] = useState(initialCriterion);
	const [sorting, setSorting] = useState(initialSorting);
	const [selectCriteriaVisible, setSelectCriteriaVisible] = useState(false);
	const [quadrantLegendSortingVisible, setQuadrantLegendSortingVisible] = useState(false);

	const chartSettings = useSelector(selectChartSettings);

	useEffect(updateVisibilityOfAdditionalDropdownsBasedOnSidePanel, [chartSettings]);

	useEffect(updateVisibilityOfAdditionalDropdowns, [quadrantType]);

	useEffect(() => {
		onStateChange(quadrantType, sorting, criterion);
	}, [quadrantType, criterion, sorting]);

	return (
		<StyledRow>
			<StyledModeSelection>
				{button('Total Views', QuadrantGraphTypes.TOTAL_VIEW)}
				{button('Criteria View', QuadrantGraphTypes.CRITERIA_VIEW)}
				{button('Highlights', QuadrantGraphTypes.HIGHLIGHTS)}
			</StyledModeSelection>
			<StyledCriteriaAndLegendSelection>
				<StyledRow>
					{criteriaDropdown()} {legendSortingDropdown()}
				</StyledRow>
			</StyledCriteriaAndLegendSelection>
		</StyledRow>
	);

	function filledIfSelected(selectedMode: QuadrantGraphType) {
		return quadrantType === selectedMode ? 'filled' : 'outlined';
	}

	function criteriaDropdown() {
		return (
			<CriteriaMenuDropdown
				criterion={criterion}
				criteria={criteria}
				visible={selectCriteriaVisible}
				setCriterion={setCriterion}
			/>
		);
	}

	function legendSortingDropdown() {
		return (
			<QuadrantLegendSortingDropdown
				sorting={sorting}
				visible={quadrantLegendSortingVisible}
				setValue={setSorting}
			/>
		);
	}

	function button(text: string, mode: QuadrantGraphType) {
		return (
			<StyledButton
				variant={filledIfSelected(mode)}
				onClick={() => setQuadrantType(mode)}
			>
				{text}
			</StyledButton>
		);
	}

	function updateVisibilityOfAdditionalDropdownsBasedOnSidePanel() {
		if (chartSettings.isOpened) {
			updateVisibilityOfAdditionalDropdowns();
		} else {
			hideDropdownPanel();
		}
	}

	function updateVisibilityOfAdditionalDropdowns() {
		switch (quadrantType) {
			case QuadrantGraphTypes.CRITERIA_VIEW:
				setSelectCriteriaVisible(true);
				setQuadrantLegendSortingVisible(true);
				break;
			case QuadrantGraphTypes.HIGHLIGHTS:
				setSelectCriteriaVisible(false);
				setQuadrantLegendSortingVisible(true);
				break;
			case QuadrantGraphTypes.TOTAL_VIEW:
				hideDropdownPanel();
				break;
		}
	}

	function hideDropdownPanel() {
		setSelectCriteriaVisible(false);
		setQuadrantLegendSortingVisible(false);
	}
};
