import { DimensionTranslator } from 'features/Graphs/TensionGraph';
import {
	BenchmarkId,
	DimensionScore,
	DimensionSummary,
	DimensionTranslatorFactory,
	ParticipantAmount,
	QuestionnaireStatus,
	TensionChartDataRepository,
	TensionGraphData,
} from 'features/Graphs/TensionGraph';
import { ClusterId } from 'common/types';
export const useTensionChartDataProvider = function (
	tensionChartDataRepository: TensionChartDataRepository,
	translatorFactory: DimensionTranslatorFactory,
) {
	function getData(clusterId: ClusterId, benchmarkId: BenchmarkId) {
		const data = tensionChartDataRepository.findBy(benchmarkId, clusterId);
		const dimensionScores = data.dimensionScores;
		const dimensionTranslator = translatorFactory.create();

		const entries = prepareDataForChart(dimensionTranslator, dimensionScores);
		return createResult(benchmarkId, entries, data.participants, data.questionnaireStatus);
	}

	return { getData: getData };

	function prepareDataForChart(
		translationRepository: DimensionTranslator,
		dimensionScores: DimensionScore[],
	): DimensionSummary[] {
		return dimensionScores.map((dimension) => {
			const name = dimension.name;

			const translation = translationRepository.translateDimension(name);

			const pdna = dimension.pdnaScore;
			const odna = dimension.odnaScore;

			return {
				dimension: { name: name, primary: translation.primary, secondary: translation.contrary },
				odnaScore: odna,
				pdnaScore: pdna,
			};
		});
	}

	function createResult(
		benchmark: BenchmarkId,
		entries: DimensionSummary[],
		participants: ParticipantAmount,
		questionnaireStatus: QuestionnaireStatus,
	): TensionGraphData {
		return {
			benchmarkName: benchmark,
			entries,
			questionnaireStatus,
			pdnaAmount: participants.pdna,
			odnaAmount: participants.odna,
		};
	}
};
