import { PageLayout } from 'common/components';
import { useAdditionalQuestions } from 'common/hooks';
import { AdditionalQuestion } from 'common/types';
import { QuestionItem } from 'features/AdditionalQuestions/components';
import { useParams } from 'react-router-dom';
import { QuestionsEmptyText } from './styled';

export const AdditionalQuestions = () => {
	const { projectId } = useParams();
	const { additionalQuestions } = useAdditionalQuestions({ projectId: projectId });

	const getQuestionsItems = (questions: AdditionalQuestion[]): JSX.Element[] => {
		const sortedQuestions = questions
			.slice()
			.sort((questionA, questionB) => questionA.criterion - questionB.criterion);
		return sortedQuestions.map((question) => (
			<QuestionItem
				key={question.code}
				text={question.question}
				answers={question.answers}
				criteriaNumber={question.criterion}
				questionCode={question.code}
			/>
		));
	};

	return (
		<PageLayout
			title="Project Additional Questions"
			id="project-additional-questions"
		>
			{additionalQuestions && !!additionalQuestions.length ? (
				getQuestionsItems(additionalQuestions)
			) : (
				<QuestionsEmptyText>Additional questions were not defined for this project.</QuestionsEmptyText>
			)}
		</PageLayout>
	);
};
