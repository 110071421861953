import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StyledButtonProps, StyledCommonProps, StyledLinkButtonProps } from './Button.types';

const primaryColor = css`
	color: ${(props) => props.theme.colors.primary.main};
	border-color: ${(props) => props.theme.colors.primary.main};
	background-color: ${(props) => props.theme.colors.primary.main};
`;

const secondaryColor = css`
	color: ${(props) => props.theme.colors.secondary.main};
	border-color: ${(props) => props.theme.colors.secondary.main};
	background-color: ${(props) => props.theme.colors.secondary.main};
`;

const tertiaryColor = css`
	color: #8f2a7f;
	border-color: #8f2a7f;
	background-color: #8f2a7f;
`;

const inheritColor = css`
	color: inherit;
	background-color: inherit;
	border-color: inherit;
`;

const onHoverUnderline = css`
	:hover {
		text-decoration: underline;
	}
`;

const commonDisabled = css`
	cursor: default;
	pointer-events: none;
`;

const fadedStyles = css`
	opacity: ${({ theme }) => theme.opacity.main};
`;

const outlinedVariant = css<StyledButtonProps>`
	background-color: transparent;

	${({ disabled, theme }) =>
		disabled &&
		`
		${commonDisabled}
		color: ${theme.colors.disabled.main};
		border-color: ${theme.colors.disabled.main};
	`}
`;

const outlinedFilled = css`
	${outlinedVariant};
	background-color: ${(props) => props.theme.colors.common.white};
`;

const textVariant = css<StyledButtonProps>`
	border-color: transparent;
	background-color: transparent;

	${({ disabled, theme }) =>
		disabled &&
		`
		${commonDisabled}
		color: ${theme.colors.disabled.main};
	`}
`;

const filledVariant = css<StyledButtonProps>`
	border-color: transparent;
	color: ${(props) => props.theme.colors.common.white};

	${({ disabled, theme }) =>
		disabled &&
		`
		${commonDisabled}
		background-color: ${theme.colors.disabled.main};
		color: ${theme.colors.common.white};
	`}
`;

const commonStyles = css<StyledCommonProps>`
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1rem;
	padding: 0.5rem 1rem;
	font-family: 'Mukta';
	border-radius: 10px;
	border: 2px solid;
	display: flex;
	width: fit-content;
	align-items: center;

	cursor: ${({ $readOnly }) => ($readOnly ? 'default' : 'pointer')};
	justify-content: ${({ $isIconVisible }) => ($isIconVisible ? 'space-evenly' : 'center')};
	${({ $underlineOnHover }) => $underlineOnHover && onHoverUnderline};
	${({ $color }) => $color === 'secondary' && secondaryColor};
	${({ $color }) => $color === 'primary' && primaryColor};
	${({ $color }) => $color === 'tertiary' && tertiaryColor};
	${({ $color }) => $color === 'inherit' && inheritColor};
	${({ $variant }) => $variant === 'outlined' && outlinedVariant};
	${({ $variant }) => $variant === 'outlinedFilled' && outlinedFilled};
	${({ $variant }) => $variant === 'filled' && filledVariant};
	${({ $variant }) => $variant === 'text' && textVariant};
	${({ $faded, disabled }) => $faded && !disabled && fadedStyles};
`;

export const StyledButton = styled.button<StyledButtonProps>`
	${commonStyles};
`;

export const StyledLinkButton = styled(Link)<StyledLinkButtonProps>`
	${commonStyles};
	text-decoration: none;
`;

export const StyledIcon = styled.span`
	display: flex;
`;
