import { PageLayout, Table } from 'common/components';
import { useTenants } from 'common/hooks';
import { useTenantsData } from 'features/Tables/hooks';
import { CreateClientButton } from './styled';
import { Tenant } from 'common/types';
import { useEffect, useState } from 'react';
import { filterAndSortClientTableData } from 'features/Tables/utils';

export const ClientConfiguration = () => {
	const { columns } = useTenantsData();
	const { tenants, areTenantsFetched } = useTenants();

	const [filteredTenants, setFilteredTenants] = useState<Tenant[]>([]);

	useEffect(() => {
		setFilteredTenants(tenants);
	}, [areTenantsFetched]);

	return (
		<PageLayout
			title="Client Configuration"
			subtitle="Create new clients and manage client configuration data"
			id="client-configuration"
		>
			<CreateClientButton
				href="/tenants/create"
				variant="outlined"
			>
				Add Client
			</CreateClientButton>

			<Table<Tenant>
				tableTypeId="client-configuration"
				disableColumnSelector
				columns={columns}
				data={filteredTenants}
				onQueryChange={(queryParams) => filterAndSortClientTableData(queryParams, tenants, setFilteredTenants)}
			/>
		</PageLayout>
	);
};
