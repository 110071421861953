import { ClientFormLayout } from 'features/ClientConfiguration/views';
import { useState } from 'react';
import {
	BillingAddressProps,
	BillingContactProps,
	BillingInformationProps,
	OrganisationAddressProps,
	OrganisationInformationProps,
	StakeholderContractProps,
	StakeholderInformationProps,
} from 'features/ClientConfiguration/components';
import { useMyOrganisation } from 'common/hooks';
import { TenantDetailsExtractor } from 'router/pages';
import { useCreateClientFormData, useClientFormValidation } from 'features/ClientConfiguration/components';
import { NotificationTypes, useNotifications } from 'common/components';
import { useEditMyOrganisationMutation } from 'store/api';

export const MyOrganisation = () => {
	const initialSaveTriggerValue = 0;
	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);
	const [editMyOrganisation] = useEditMyOrganisationMutation();
	const { pushNotification } = useNotifications();
	const { data: myOrganisationForm } = useCreateClientFormData();
	const { validateForm } = useClientFormValidation();
	const { myOrganisation } = useMyOrganisation();

	if (!myOrganisation) {
		return <div />;
	}
	const myOrganisationDetails = new TenantDetailsExtractor(myOrganisation);

	return (
		<ClientFormLayout
			id="my-organisation"
			title="MY ORGANISATION"
			subtitle="EDIT INFORMATION"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
			organisationInformation={organisationInformation()}
			organisationAddress={organisationAddress()}
			stakeholderInformation={stakeholderInformation()}
			stakeholderContact={stakeholderContact()}
			billingInformation={billingInformation()}
			billingAddress={billingAddress()}
			billingContact={billingContact()}
		/>
	);
	async function onSaveButtonClick() {
		const saveTriggerIncrement = 1;
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);

		if (!myOrganisation) {
			return;
		}

		validateForm(myOrganisationForm).then((formIsValid) => {
			if (!formIsValid) return;
			const versionedData = { ...myOrganisationForm, version: myOrganisation.version };
			editMyOrganisation(versionedData).then(notifyAboutSuccess);
		});
	}

	function notifyAboutSuccess() {
		pushNotification({
			message: 'My organisation details successfully updated.',
			type: NotificationTypes.Success,
		});
	}

	function organisationInformation(): OrganisationInformationProps {
		const organisationInformation = myOrganisationDetails.getOrganisationInformation();
		return {
			...organisationInformation,
			disableOrganisationName: true,
			disablePurchaseOrderReference: true,
			disableSupplierAgreementReference: true,
		};
	}

	function organisationAddress(): OrganisationAddressProps {
		const organisationAddress = myOrganisationDetails.getOrganizationAddress();

		return {
			...organisationAddress,
			disableCountry: true,
		};
	}

	function stakeholderInformation(): StakeholderInformationProps {
		return myOrganisationDetails.getStakeholderInformation();
	}

	function stakeholderContact(): StakeholderContractProps {
		return myOrganisationDetails.getStakeholderContact();
	}

	function billingInformation(): BillingInformationProps {
		const billingInformation = myOrganisationDetails.getBillingInformation();
		return {
			...billingInformation,
			disableVatNumber: true,
			disableBillingCountry: true,
			disableInvoiceEmail: true,
			disableBillingEntity: true,
		};
	}

	function billingAddress(): BillingAddressProps {
		const billingAddress = myOrganisationDetails.getBillingAddress();
		return {
			...billingAddress,
			disableFirstBillingAddress: true,
			disableSecondBillingAddress: true,
			disableBillingCity: true,
			disableBillingPostcode: true,
		};
	}
	function billingContact(): BillingContactProps {
		const billingContract = myOrganisationDetails.getBillingContract();
		return {
			...billingContract,
			disableBillingContactIM: true,
			disableBillingContactName: true,
			disableBillingContactEmail: true,
			disableBillingContactTelephone: true,
		};
	}
};
