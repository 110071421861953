import { IChartData } from '@support/alderbrooke-d3-graphs/dist/VerticalBar/IChartData';
import { OddsRatioCfg } from 'features/Graphs/OddsRatioGraph';

export class YAxisScaler {
	public prepareScaledAxisY(graphData: IChartData, config: OddsRatioCfg) {
		const first = 0;
		const values = graphData.data.map((value) => value.series[first].value);
		const scaleResolution = 0.25;
		return {
			...config.axisY,
			domain: [this._getMinRange(scaleResolution, values), this._getMaxRange(scaleResolution, values)],
		};
	}

	private _getMinRange(scaleResolution: number, values: number[]) {
		const one = 1;
		const minValue = Math.min.apply(null, values);
		return ((minValue - (minValue % scaleResolution)) / scaleResolution - one) * scaleResolution;
	}

	private _getMaxRange(scaleResolution: number, values: number[]) {
		const one = 1;
		const maxValue = Math.max.apply(null, values);
		return ((maxValue - (maxValue % scaleResolution)) / scaleResolution + one) * scaleResolution;
	}
}
