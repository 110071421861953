import { useCommonParticipants, useProjectParticipants } from 'common/hooks';

type UseParticipantsProps = {
	projectId?: string;
};

export const useParticipants = (option?: UseParticipantsProps) => {
	if (option && option.projectId) {
		return useProjectParticipants();
	} else {
		return useCommonParticipants();
	}
};
