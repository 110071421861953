import { OPdnaPieChart, OdnaPieChart, PdnaPieChart } from 'features/Graphs/PieChart';
import { SimulationViewPieChartProps } from './SimulationViewPieChart.types';
import { ChartContainers } from '../ChartContainers/ChartContainers';

export const SimulationViewPieChart = ({
	showDataOnOneGraph,
	personal,
	organisational,
	pno,
	selectedClusterIndex,
	colorPresetIndex,
}: SimulationViewPieChartProps) => {
	return (
		<>
			<ChartContainers showDataOnOneGraph={showDataOnOneGraph} />
			<>
				<PdnaPieChart
					containerId={'1'}
					clusterIndex={selectedClusterIndex}
					benchmarkIndexes={personal}
					pnoBenchmarkIndexes={pno}
					colorPresetIndex={colorPresetIndex}
				/>
				<OdnaPieChart
					containerId={'2'}
					clusterIndex={selectedClusterIndex}
					benchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					colorPresetIndex={colorPresetIndex}
				/>
				<OPdnaPieChart
					containerId={'3'}
					clusterIndex={selectedClusterIndex}
					pdnaBenchmarkIndexes={personal}
					odnaBenchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					colorPresetIndex={colorPresetIndex}
				/>
			</>
		</>
	);
};
