import { Container, StyledInput } from './styled';
import { ChartLineSettingsItem } from '../ChartLineSettingsItem';
import { BenchmarkSettingsControlProps } from './BenchmarkSettingsControl.types';
import { GraphBenchmarkSettings } from 'store/index';

export const BenchmarkSettingsControl = ({
	benchmarkSettings,
	benchmarkName,
	onBenchmarkNameChange,
	onBenchmarkSettingsChange,
}: BenchmarkSettingsControlProps) => {
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = event;

		onBenchmarkNameChange(value);
	};

	const personalChange = (newSettings: GraphBenchmarkSettings) => {
		onBenchmarkSettingsChange({
			...benchmarkSettings,
			personal: newSettings,
			pno: {
				selected: newSettings.selected ? false : benchmarkSettings.pno.selected,
			},
		});
	};

	const organisationalChange = (newSettings: GraphBenchmarkSettings) => {
		onBenchmarkSettingsChange({
			...benchmarkSettings,
			organisational: newSettings,
			pno: {
				selected: newSettings.selected ? false : benchmarkSettings.pno.selected,
			},
		});
	};

	const pnoChange = (newSettings: GraphBenchmarkSettings) => {
		onBenchmarkSettingsChange({
			...benchmarkSettings,
			pno: newSettings,
		});
	};

	const isInputFilled = !!benchmarkName.length;
	return (
		<Container>
			<StyledInput
				$isEmpty={!isInputFilled}
				maxLength={24}
				value={benchmarkName}
				onChange={onInputChange}
			/>
			<ChartLineSettingsItem
				selected={benchmarkSettings.personal.selected}
				initialState={benchmarkSettings.personal}
				onChange={personalChange}
			>
				Personal
			</ChartLineSettingsItem>
			<ChartLineSettingsItem
				selected={benchmarkSettings.organisational.selected}
				initialState={benchmarkSettings.organisational}
				onChange={organisationalChange}
			>
				Organisational
			</ChartLineSettingsItem>
			<ChartLineSettingsItem
				selected={benchmarkSettings.pno.selected}
				initialState={{ ...benchmarkSettings.pno, lineColor: '0045D0', lineType: 'DASHED' }}
				colorLinePickerDisabled
				onChange={pnoChange}
			>
				P&O
			</ChartLineSettingsItem>
		</Container>
	);
};
