import { NotificationsContainer, NotificationsProvider } from 'common/components/Notifications';
import { theme } from 'common/constants';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { router } from './router';
import { GlobalStyle } from './styled';
import 'common/assets/fonts/fontFaces.css';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { Suspense } from 'common/components';

export const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<GlobalStyle />
					<NotificationsProvider interval={1}>
						<Suspense />
						<RouterProvider router={router} />
						<NotificationsContainer />
					</NotificationsProvider>
				</PersistGate>
			</Provider>
		</ThemeProvider>
	);
};
