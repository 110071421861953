import { ChartContainersProps } from './ChartContainers.types';
import { StyledContainer, StyledFullChartContainer, StyledHalfChartContainer } from './styled';

export const ChartContainers = ({ showDataOnOneGraph }: ChartContainersProps) => {
	return (
		<>
			<StyledContainer>
				<StyledHalfChartContainer
					id="1"
					hidden={showDataOnOneGraph ? true : false}
				></StyledHalfChartContainer>
				<StyledHalfChartContainer
					id="2"
					hidden={showDataOnOneGraph ? true : false}
				></StyledHalfChartContainer>
				<StyledFullChartContainer
					id="3"
					hidden={showDataOnOneGraph ? false : true}
				></StyledFullChartContainer>
			</StyledContainer>
		</>
	);
};
