export const useColorPreset = (presetId: number) => {
	const firstColorPreset = {
		first: {
			backgroundColor: '#ED6C6D',
			text: 'black',
		},
		second: {
			backgroundColor: '#FFDF7F',
			text: 'black',
		},
		third: {
			backgroundColor: '#F0F0F0',
			text: 'black',
		},
		fourth: {
			backgroundColor: '#C1D2B9',
			text: 'black',
		},
		fifth: {
			backgroundColor: '#6D9E6E',
			text: 'black',
		},
	};
	const secondColorPreset = {
		first: {
			backgroundColor: '#534E6F',
			text: 'white',
		},
		second: {
			backgroundColor: '#8370B4',
			text: 'white',
		},
		third: {
			backgroundColor: '#F5F5F5',
			text: 'black',
		},
		fourth: {
			backgroundColor: '#E39DCD',
			text: 'black',
		},
		fifth: {
			backgroundColor: '#C26EA8',
			text: 'white',
		},
	};

	const thirdColorPreset = {
		first: {
			backgroundColor: '#817B6F',
			text: 'white',
		},
		second: {
			backgroundColor: '#BE804D',
			text: 'black',
		},
		third: {
			backgroundColor: '#B8B8B8',
			text: 'black',
		},
		fourth: {
			backgroundColor: '#89CBF0',
			text: 'black',
		},
		fifth: {
			backgroundColor: '#539DC9',
			text: 'white',
		},
	};
	const first = 0;
	const second = 1;
	const third = 2;
	switch (presetId) {
		case first:
			return firstColorPreset;
		case second:
			return secondColorPreset;
		case third:
			return thirdColorPreset;
		default:
			return firstColorPreset;
	}
};
