import { Input, NotificationTypes, PageLayout, useNotifications } from 'common/components';
import { useCriteriaConfigurationSettings } from 'common/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CriterionSetting } from 'store/api/criteriaApi/criteriaApi.types';
import { ButtonWrapper, SaveButtonContainer, StyledButton } from './styled';
import { validateForm } from './utils';

export const CriteriaConfiguration = () => {
	const { criteriaConfiguration, updateCriteriaConfiguration } = useCriteriaConfigurationSettings();
	const [criteria, setCriteria] = useState<CriterionSetting[]>([]);
	const { pushNotification } = useNotifications();
	const navigate = useNavigate();

	useEffect(() => {
		if (!criteriaConfiguration) return;

		setCriteria(criteriaConfiguration.criteria);
	}, [criteriaConfiguration]);

	const handleValueChange = (index: number, value: string) => {
		const newArray = criteria.map((criterion, i) => {
			if (index === i) {
				return { ...criterion, name: value };
			} else {
				return criterion;
			}
		});
		setCriteria(newArray);
	};

	const handleCheckboxChange = (index: number, state: boolean) => {
		const newArray = criteria.map((criterion, i) => {
			if (index === i) {
				return { ...criterion, enabled: state };
			} else {
				return criterion;
			}
		});
		setCriteria(newArray);
	};

	const handleSave = () => {
		const isFormValid = validateForm(criteria);

		if (!isFormValid) return;

		updateCriteriaConfiguration(criteria)
			.unwrap()
			.then((failed: unknown) => {
				if (failed) {
					pushNotification({
						message: 'Something went wrong during the last operation!',
						type: NotificationTypes.Error,
					});
					return;
				}

				pushNotification({
					message: 'Criteria configuration updated.',
					type: NotificationTypes.Success,
				});

				navigate('/tenants');
			});
	};

	const getInputs = () => {
		if (!criteriaConfiguration) return;

		return criteria.map((criterion, index) => (
			<Input
				key={criterion.number}
				name={`Criteria ${criterion.number}*`}
				value={criterion.name}
				onChange={(event) => handleValueChange(index, event.target.value)}
				formInput
				checkbox
				error={!criterion.name}
				errorText="Criterion name is required!"
				errorPlacement="right"
				maxLength={30}
				checked={criterion.enabled}
				onCheckboxChange={() => {
					handleCheckboxChange(index, !criterion.enabled);
				}}
			/>
		));
	};

	return (
		<PageLayout
			title="Criteria Configuration"
			id="criteria-configuration"
		>
			{getInputs()}
			<SaveButtonContainer>
				<ButtonWrapper>
					<StyledButton onClick={() => handleSave()}>Save</StyledButton>
				</ButtonWrapper>
			</SaveButtonContainer>
		</PageLayout>
	);
};
