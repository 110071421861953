import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, NotificationTypes, Portal, useNotifications } from 'common/components';
import {
	BackgroundOverlay,
	SideButton,
	SideButtonsContainer,
} from 'features/Predictive/views/PredictiveViewResults/styled';
import { SettingsMenuView } from 'features/Simulation/views/SettingsMenuView';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleChartSettingsView } from 'store/index';
import {
	AssignedCriteriaGroup,
	UpdateRoadmapResponse,
	useSaveProjectsRoadmapMutation,
	useSaveRoadmapMutation,
	useUpdateRoadmapMutation,
} from 'store/api/roadmapApi';
import { RoadMapSettingsOptions, RoadMapSettingsPanel } from 'features/RoadMap/components';
import { AllRoadMapsGraphs } from 'features/RoadMap/views/RoadMapGraph/components/AllRoadMapsGraphs';
import { ChartColor } from 'common/types';
import { RoadMapSelectionPanel } from 'features/RoadMap/components/RoadMapSelectionPanel/RoadMapSelectionPanel';
import { useNavigate } from 'react-router-dom';
import { ColorSelector } from './colorSelector';
import { RoadMapViewProps } from './RoadMapView.types';
import { ButtonsContainer, ViewCriteriaButton } from './styled';
import { RoadmapCriteriaModal } from '../RoadMapGraph/components/RoadmapCriteriaModal';
import { BenchmarkedCriterionState } from 'features/RoadMap/components/RoadMapCriteriaSelection/RoadMapCriteriaSelection.types';

export const RoadMapView = ({ roadmap, isOpen, onClose, id, projectId, criteria }: RoadMapViewProps) => {
	const colorSelector = new ColorSelector();

	const firstIndex = 0;
	const initialExportTrigger = 0;
	const exportTriggerIncrement = 1;

	const backgroundOverlayRef = useRef(null);
	const [selectedRoadmapId, setSelectedRoadmapId] = useState('0');
	const dispatch = useDispatch();

	const [blackArrows, setBlackArrows] = useState(true);
	const [nodeColor, setNodeColor] = useState<ChartColor>(colorSelector.getChartColor(roadmap.colourPresetId.id));
	const [textColor, setTextColor] = useState('white');
	const [criteriaNumber, setCriteriaNumber] = useState(true);
	const [exportTrigger, setExportTrigger] = useState(initialExportTrigger);
	const [roadmapId, setRoadmapId] = useState(id || '');
	const [newRoadmapName, setNewRoadmapName] = useState(roadmap.roadmapName);
	const [isCriteriaModalOpen, setIsCriteriaModalOpen] = useState(false);
	const navigate = useNavigate();

	const incrementExportTrigger = () => {
		setExportTrigger((exportTrigger) => exportTrigger + exportTriggerIncrement);
	};

	const resetExportTrigger = () => {
		setExportTrigger(initialExportTrigger);
	};

	const [saveRoadmap] = useSaveRoadmapMutation();
	const [saveProjectsRoadmap] = useSaveProjectsRoadmapMutation();
	const [updateRoadmap] = useUpdateRoadmapMutation();
	const { pushNotification } = useNotifications();

	const closeCallback = (options: RoadMapSettingsOptions) => {
		dispatch(toggleChartSettingsView(false));
		setBlackArrows(options.blackArrows);
		setNodeColor(options.nodeColor);
		setTextColor(options.textColor);
		setCriteriaNumber(options.criteriaNumber);
		resetExportTrigger();
	};

	const isBenchmarkedCriterionState = (
		criteria: BenchmarkedCriterionState[] | AssignedCriteriaGroup[],
	): criteria is BenchmarkedCriterionState[] => {
		return (criteria as BenchmarkedCriterionState[])[firstIndex].enabled !== undefined;
	};

	const trimCriteria = (): AssignedCriteriaGroup[] => {
		if (!criteria) return [];
		const assignedCriteria: AssignedCriteriaGroup[] = [];
		for (let i = 0; i < criteria?.length; i++) {
			if (isBenchmarkedCriterionState(criteria) && criteria[i].enabled) {
				assignedCriteria.push({
					criteriaId: parseInt(criteria[i].id),
					benchmarkConfigurationId: criteria[i].benchmarkConfigurationId?.id || '',
					defaultName: criteria[i].defaultName,
					aliasName: criteria[i].name,
				});
			}
		}

		return assignedCriteria;
	};

	const notifyAboutSuccess = (message: string) => {
		pushNotification({
			message,
			type: NotificationTypes.Success,
		});
	};

	const notifyAboutAlreadyTakenName = () => {
		pushNotification({
			message: 'Roadmap name already taken, please create a unique name.',
			type: NotificationTypes.Warning,
		});
	};

	const saveProjectsRoadmapCallback = (projectId: string, options: RoadMapSettingsOptions) => {
		saveProjectsRoadmap({
			projectId,
			requestBody: {
				...roadmap,
				roadmapName: newRoadmapName,
				colourPresetId: { id: colorSelector.getColorId(options.nodeColor) },
				allCriteriaAssigned: trimCriteria(),
			},
		})
			.unwrap()
			.then((response) => {
				if (response.id) {
					setRoadmapId(response.id);
					notifyAboutSuccess('Roadmap successfully saved');
				} else {
					notifyAboutAlreadyTakenName();
				}
			});
	};

	const updateRoadmapCallback = (options: RoadMapSettingsOptions) => {
		updateRoadmap({
			roadmapId,
			name: newRoadmapName,
			colourPresetId: colorSelector.getColorId(options.nodeColor),
		})
			.unwrap()
			.then((response: UpdateRoadmapResponse) => {
				if (response.id) {
					setRoadmapId(response.id);
					notifyAboutSuccess('Roadmap successfully updated');
				} else {
					notifyAboutAlreadyTakenName();
				}
			});
	};

	const saveRoadmapCallback = (options: RoadMapSettingsOptions) => {
		saveRoadmap({
			...roadmap,
			roadmapName: newRoadmapName,
			colourPresetId: { id: colorSelector.getColorId(options.nodeColor) },
			allCriteriaAssigned: trimCriteria(),
		})
			.unwrap()
			.then((response) => {
				if (response.id) {
					setRoadmapId(response.id);
					notifyAboutSuccess('Roadmap successfully saved');
				} else {
					notifyAboutAlreadyTakenName();
				}
			});
	};

	return isOpen ? (
		<Portal wrapperId="roadmap-view-root">
			<BackgroundOverlay ref={backgroundOverlayRef}>
				<SettingsMenuView>
					<RoadMapSettingsPanel
						initialSelectedColor={colorSelector.getChartColor(roadmap.colourPresetId.id)}
						roadmapName={newRoadmapName}
						outcomes={roadmap.numberOfOutcomes}
						onClose={(options) => closeCallback(options)}
						onExportClick={incrementExportTrigger}
						onSaveClick={(options) => {
							closeCallback(options);
							const newProjectRoadmap = projectId && !roadmapId;
							const newNonProjectRoadmap = !projectId && !roadmapId;
							const existingRoadmap = !!roadmapId;

							if (newProjectRoadmap) {
								saveProjectsRoadmapCallback(projectId, options);
								return;
							}

							if (newNonProjectRoadmap) {
								saveRoadmapCallback(options);
								return;
							}

							if (existingRoadmap) {
								updateRoadmapCallback(options);
								return;
							}
						}}
						onNameChange={(newName) => {
							setNewRoadmapName(newName);
						}}
						excludedCriteria={roadmap.notIncludedCriteriaGroups}
					/>
				</SettingsMenuView>
				<SideButtonsContainer>
					<SideButton onClick={() => dispatch(toggleChartSettingsView(true))}>
						<FontAwesomeIcon icon={faGear} />
					</SideButton>
					<SideButton
						onClick={() => {
							onClose();
							resetExportTrigger();
							if (projectId && roadmapId) {
								navigate(`/road-map/${projectId}/overview`);
							} else if (roadmapId) {
								navigate('/road-map/overview');
							}
						}}
					>
						<FontAwesomeIcon icon={faHouse} />
					</SideButton>
				</SideButtonsContainer>
				<AllRoadMapsGraphs
					roadmap={roadmap}
					exportTrigger={exportTrigger}
					selectedRoadmap={selectedRoadmapId}
					style={{ blackArrows, nodeColor, textColor, criteriaNumber }}
				/>
				<ButtonsContainer>
					<RoadMapSelectionPanel
						onItemSelection={(id) => setSelectedRoadmapId(id)}
						roadmap={roadmap}
					/>
					<ViewCriteriaButton
						variant="outlinedFilled"
						onClick={() => setIsCriteriaModalOpen(true)}
					>
						View criteria
					</ViewCriteriaButton>
				</ButtonsContainer>
				<Modal
					id="roadmap-criteria-modal"
					isOpen={isCriteriaModalOpen}
					onClose={() => setIsCriteriaModalOpen(false)}
					className="primary"
				>
					<RoadmapCriteriaModal
						criteria={isBenchmarkedCriterionState(criteria) ? trimCriteria() : criteria}
					/>
				</Modal>
			</BackgroundOverlay>
		</Portal>
	) : (
		<></>
	);
};
