import { ChartSelectorProps } from './ChartSelector.types';
import { SimulationViewAccuracy } from './SimulationViewAccuracy';
import { SimulationViewDispersion } from './SimulationViewDispersion';
import { SimulationViewHeatmap } from './SimulationViewHeatmap/SimulationViewHeatmap';
import { SimulationViewPieChart } from './SimulationViewPieChart';
import { SimulationViewQuadrant } from './SimulationViewQuadrant';
import { SimulationViewRadar } from './SimulationViewRadar';

export const ChartSelector = ({
	chartType,
	showDataOnOneGraph,
	personal,
	organisational,
	pno,
	selectedClusterIndex,
	colorPresetIndex,
	tensionGraphFactory,
}: ChartSelectorProps) => {
	switch (chartType) {
		case 'radar':
			return (
				<SimulationViewRadar
					showDataOnOneGraph={showDataOnOneGraph}
					personal={personal}
					organisational={organisational}
					pno={pno}
					selectedClusterIndex={selectedClusterIndex}
				/>
			);
		case 'indicator':
			return (
				<SimulationViewPieChart
					showDataOnOneGraph={showDataOnOneGraph}
					personal={personal}
					organisational={organisational}
					pno={pno}
					selectedClusterIndex={selectedClusterIndex}
					colorPresetIndex={colorPresetIndex}
				/>
			);
		case 'responseQuality':
			return (
				<SimulationViewAccuracy
					showDataOnOneGraph={showDataOnOneGraph}
					personal={personal}
					organisational={organisational}
					pno={pno}
					selectedClusterIndex={selectedClusterIndex}
				/>
			);
		case 'heatmap':
			return (
				<SimulationViewHeatmap
					selectedClusterIndex={selectedClusterIndex}
					personal={personal}
					organisational={organisational}
					pno={pno}
					colorPresetIndex={colorPresetIndex}
					tensionGraphFactory={tensionGraphFactory}
				/>
			);
		case 'dataDistribution':
			return (
				<SimulationViewDispersion
					personal={personal}
					organisational={organisational}
					pno={pno}
					selectedClusterIndex={selectedClusterIndex}
					colorPresetIndex={colorPresetIndex}
				/>
			);
		case 'quadrant':
			return (
				<SimulationViewQuadrant
					selectedClusterIndex={selectedClusterIndex}
					colorPresetIndex={colorPresetIndex}
				/>
			);
		default:
			return <></>;
	}
};
