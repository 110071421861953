import { getFilenameFromResponse, rootApi } from 'store/api';
import {
	TensionChartExportPayload,
	TensionChartExportResponse,
} from 'store/api/tensionGraphExportApi/tensionGraphExportApi.types';

export const tensionGraphExportApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		exportTensionGraph: builder.mutation<TensionChartExportResponse, TensionChartExportPayload>({
			query: (data) => ({
				url: `/api/pptx`,
				body: data,
				method: 'POST',
				responseHandler: async (response) => {
					if (!response.ok) return;
					const defaultFilename = data.filename;
					const filename = getFilenameFromResponse(response, defaultFilename);
					const blob = await response.blob();
					return {
						filename: filename,
						objectUrl: URL.createObjectURL(blob),
					};
				},
			}),
		}),
	}),
});

export const { useExportTensionGraphMutation } = tensionGraphExportApi;
