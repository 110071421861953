import { flexRender } from '@tanstack/react-table';

import { StyledTHeadLabel } from './styled';
import { TableHeadLabelProps } from './TableHeadLabel.types';
import React from 'react';
import { TableSortControls } from 'common/components';

export const TableHeadLabel = <T extends object>({ header }: TableHeadLabelProps<T>) => {
	const { column } = header;
	const isColumnSortable = column.getCanSort();

	const handleClick = () => {
		if (isColumnSortable) {
			column.toggleSorting();
		}
	};

	return (
		<StyledTHeadLabel
			$sortable={isColumnSortable}
			onClick={handleClick}
		>
			{addHeader()}
			{addSortControlsIfEnabled()}
		</StyledTHeadLabel>
	);

	function addHeader() {
		return flexRender(column.columnDef.header, header.getContext());
	}

	function addSortControlsIfEnabled() {
		const sortDirection = column.getIsSorted();
		const columnCannotBeSorted = !isColumnSortable;
		if (columnCannotBeSorted) {
			return null;
		}

		if (!sortDirection) {
			return <TableSortControls sortDirection={'none'} />;
		}
		return <TableSortControls sortDirection={sortDirection} />;
	}
};
