import { AccuracyChartConfig } from './AccuracyChart.types';

const domainMin = 0;
const domainMax = 1;

export const defaultAccuracyChartBarColor = '#A6A6A6';
export const defaultAccuracyChartLabel = 'Accuracy';

export const accuracyChartConfig: AccuracyChartConfig = {
	axisX: {
		domain: [domainMin, domainMax],
		ticks: 10,
		lineStyle: { stroke: '#4e4d50', fill: 'none', 'stroke-width': '1px' },
		textStyle: { 'font-size': '14px', 'font-family': 'Arial, "Liberation Sans"' },
	},
	axisY: {
		padding: 0.3,
		textStyle: {
			'font-size': '14px',
			'font-weight': 'bold',
			'font-family': 'Arial, "Liberation Sans"',
		},
		label: {
			leftPadding: 20,
			rightPadding: 10,
		},
	},
	bar: {
		textStyle: { 'font-size': '14px', 'font-family': 'Arial, "Liberation Sans"' },
	},
	legend: {
		squareSize: 10,
		squareTopMargin: 4,
		textLeftMargin: 5,
		textRightMargin: 15,
		textStyle: {
			'font-size': '16px',
			'font-weight': 'bold',
			'font-family': 'Arial, "Liberation Sans"',
			fill: '#3a3a3a',
		},
		topMargin: 20,
		leftMargin: 150,
	},
	canvas: {
		margins: {
			left: 100,
			right: 20,
			bottom: 40,
			top: 75,
		},
		width: 600,
		height: 450,
	},
};
