import { RadarGraphCategoryLabel, RadarGraphDataSeries } from 'features/Graphs';
import { modifyLabel } from 'features/Graphs/RadarGraph/hoc/OPdnaRadarGraph/utils';
import { RadarGraphBase } from 'features/Graphs/PptxExportButton';

export const useOPdnaRadarGraphData = (
	base: RadarGraphBase,
	clusterIndex: number,
	odnaBenchmarkIndexes: boolean[],
	pdnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const notFoundIndex = -1;

	const odna = base.odna;
	const pdna = base.pdna;

	const checkedPdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : pdnaBenchmarkIndexes;
	const checkedOdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : odnaBenchmarkIndexes;
	const benchmarksLength = pdnaBenchmarkIndexes.length;

	const opdna = base.title;
	const data: RadarGraphDataSeries[] = [];

	for (let benchmarkIndex = 0; benchmarkIndex < benchmarksLength; benchmarkIndex++) {
		const pdnaBenchmarkEnabled = checkedPdnaBenchmarks[benchmarkIndex];
		const odnaBenchmarkEnabled = checkedOdnaBenchmarks[benchmarkIndex];
		const pdnaValueExists = pdna.clusters[clusterIndex].benchmarks[benchmarkIndex].data.length;
		const odnaValueExists = odna.clusters[clusterIndex].benchmarks[benchmarkIndex].data.length;

		if (pdnaBenchmarkEnabled && pdnaValueExists) {
			const selectedCluster = pdna.clusters[clusterIndex];
			const currentBenchmark = selectedCluster.benchmarks[benchmarkIndex];

			const modifiedBenchmark = modifyLabel(currentBenchmark, 'PDNA');

			data.push(modifiedBenchmark.data);
		}

		if (odnaBenchmarkEnabled && odnaValueExists) {
			const selectedCluster = odna.clusters[clusterIndex];
			const currentBenchmark = selectedCluster.benchmarks[benchmarkIndex];

			const modifiedBenchmark = modifyLabel(currentBenchmark, 'ODNA');

			data.push(modifiedBenchmark.data);
		}
	}

	const atLeastOneBenchmarkChecked = checkedOdnaBenchmarks.includes(true) || checkedPdnaBenchmarks.includes(true);
	const benchmarkContainsData = data.length;

	const shouldGetCategoryLabels = atLeastOneBenchmarkChecked && benchmarkContainsData;
	const pdnaCategoryLabelsIndex = pdna.clusters[clusterIndex].benchmarks.findIndex(
		(value) => value.categoryLabels.length,
	);

	const odnaCategoryLabelsIndex = odna.clusters[clusterIndex].benchmarks.findIndex(
		(value) => value.categoryLabels.length,
	);

	const categoryLabels: RadarGraphCategoryLabel[] =
		shouldGetCategoryLabels && pdnaCategoryLabelsIndex > notFoundIndex
			? pdna.clusters[clusterIndex].benchmarks[pdnaCategoryLabelsIndex].categoryLabels
			: odna.clusters[clusterIndex].benchmarks[odnaCategoryLabelsIndex].categoryLabels;

	return {
		categoryLabels,
		data,
		title: opdna.title,
	};
};
