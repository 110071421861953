import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const TabListItem = styled.li`
	margin: 0 0.25rem;

	&:first-of-type {
		margin-left: 0.5rem;
	}

	&:last-of-type {
		margin-right: 0.5rem;
	}
`;

export const StyledButton = styled(Button)`
	padding: 0 0.5rem;
	font-weight: 300;
`;
