import { GraphEdge, GraphVertex, RoadmapGraph } from 'store/api/roadmapApi';
import { ElementDefinition } from 'cytoscape';

export const useRoadmapGraphDataConverter = (data: RoadmapGraph) => {
	const nodes: ElementDefinition[] = data.vertices.map(createNode);
	const edges: ElementDefinition[] = data.edges.map(createEdge);
	return joinTogether(nodes, edges);

	function joinTogether(nodes: ElementDefinition[], edges: ElementDefinition[]) {
		const result: ElementDefinition[] = [];
		nodes.forEach((n) => result.push(n));
		edges.forEach((n) => result.push(n));

		return result;
	}

	function createEdge(edge: GraphEdge): ElementDefinition {
		const rounding = 3;

		return {
			data: {
				label: edge.label.toFixed(rounding),
				source: edge.from.id,
				target: edge.to.id,
			},
		};
	}

	function createNode(node: GraphVertex): ElementDefinition {
		const nodeIdAsText = node.id + '';

		return { data: { id: nodeIdAsText, label: node.name, index: node.id } };
	}
};
