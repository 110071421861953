import { ApiProjectSummery } from 'common/types';
import { ProjectSummeryStatusResponse, useLazyGetProjectSummeryQuery, ProjectSummaryOptions } from 'store/api';
import { getProjectSummaryEmptyResponse } from 'store/api';

export const useProjectSummary = () => {
	const emptyResponse = getProjectSummaryEmptyResponse();
	const [fetchProjectSummary, fetchedData] = useLazyGetProjectSummeryQuery();

	const data = getProjectSummaryData();

	return {
		getProjectSummary,
		projectSummary: data ? transform(data) : emptyResponse,
	};

	async function getProjectSummary(
		options: ProjectSummaryOptions,
	): Promise<ProjectSummeryStatusResponse<ApiProjectSummery>> {
		const response = await fetchProjectSummary(options);

		if (!response || response.data === undefined) {
			return emptyResponse;
		}

		return response.data;
	}

	function getProjectSummaryData(): ProjectSummeryStatusResponse<ApiProjectSummery> | undefined {
		if (!fetchedData.isSuccess) return emptyResponse;

		return fetchedData.data;
	}

	function transform(
		projects: ProjectSummeryStatusResponse<ApiProjectSummery>,
	): ProjectSummeryStatusResponse<ApiProjectSummery> {
		return projects;
	}
};
