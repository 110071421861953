import { ProjectId } from 'common/types';
import { useState } from 'react';
import { BenchmarkConfigurationId, CultureViewResults } from 'store/api';
import { SimulationView } from 'features/Simulation/views';
import { useCultureViewResults } from 'common/hooks';
import { useCultureViewResultsFromProject } from 'common/hooks';
import { BenchmarkComparisonView } from 'common/components/BenchmarkComparisonView';
import { NotificationTypes, useNotifications } from 'common/components';
import { CommonFiveBenchmarkTab } from 'common/components/BenchmarkComparisonView/components/CommonFiveBenchmarkTab';

export const CultureView = () => {
	const [isSimulationViewOpened, setIsSimulationViewOpened] = useState(false);
	const [queryResult, setQueryResult] = useState<CultureViewResults>();
	const { pushNotification } = useNotifications();

	const fetchResults = useCultureViewResults();
	const fetchResultsFromProject = useCultureViewResultsFromProject();

	const onViewButtonClick = async (benchmarkConfigurationId?: BenchmarkConfigurationId, projectId?: ProjectId) => {
		fetchResultData(benchmarkConfigurationId, projectId)
			.then((result) => {
				setIsSimulationViewOpened(true);
				setQueryResult(result);
			})
			.catch((error) => {
				error.message &&
					pushNotification({
						message: error.message,
						type: NotificationTypes.Error,
					});
			});
	};

	const closeSimulationView = () => {
		setIsSimulationViewOpened(false);
	};

	function fetchResultData(benchmarkConfigurationId?: BenchmarkConfigurationId, projectId?: ProjectId) {
		if (benchmarkConfigurationId == undefined) {
			throw Error();
		}

		if (projectId) {
			return fetchResultsFromProject(benchmarkConfigurationId, projectId);
		}

		return fetchResults(benchmarkConfigurationId);
	}

	const canShowSimulationView =
		queryResult && 'benchmarksAndSelectedParticipantClusterResults' in queryResult.commonResults;

	return (
		<>
			{canShowSimulationView && (
				<SimulationView
					isOpened={isSimulationViewOpened}
					onClose={closeSimulationView}
					queryResult={queryResult as CultureViewResults}
				/>
			)}
			<BenchmarkComparisonView
				id="culture-view"
				pageName="Culture View"
				onViewClick={onViewButtonClick}
				benchmarkTabPanel={(benchmarkConfigId) => (
					<CommonFiveBenchmarkTab
						id={'culture-view'}
						benchmarkConfigurationId={benchmarkConfigId}
					/>
				)}
			/>
		</>
	);
};
