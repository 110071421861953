import {
	AssessedParticipantsOptions,
	AssessedParticipantsResponse,
	useLazyGetAllAssessedParticipantsQuery,
} from 'store/api';
import { Participant, ApiParticipant } from 'common/types';
import { transformParticipantsResponse } from './mappers';
import { getParticipantsEmptyResponse } from 'store/api';
import { useCriteriaConfiguration } from './useCriteriaConfiguration';

export const useAssessedParticipants = () => {
	const criteriaConfiguration = useCriteriaConfiguration();
	const emptyResponse = getParticipantsEmptyResponse();
	const [getAllAssessedParticipants, allAssessedParticipants] = useLazyGetAllAssessedParticipantsQuery();
	const assessedParticipants = getAssessedParticipantsData();

	return {
		getAssessedParticipants,
		assessedParticipants: transform(assessedParticipants),
	};

	function getAssessedParticipantsData(): AssessedParticipantsResponse<ApiParticipant> {
		if (!allAssessedParticipants.isSuccess) return emptyResponse;

		return allAssessedParticipants.data;
	}

	async function getAssessedParticipants(
		option: AssessedParticipantsOptions,
	): Promise<AssessedParticipantsResponse<Participant>> {
		try {
			const response = await getAllAssessedParticipants(option).unwrap();

			return transform(response);
		} catch {
			return emptyResponse;
		}
	}

	function transform(
		participants: AssessedParticipantsResponse<ApiParticipant>,
	): AssessedParticipantsResponse<Participant> {
		return transformParticipantsResponse(participants, criteriaConfiguration);
	}
};
