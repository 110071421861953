import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { AdditionalQuestionsState } from './additionalQuestionsSlice.types';

const initialState: AdditionalQuestionsState = {
	fileObjectUrl: undefined,
	introduction: undefined,
	filename: undefined,
};

export const additionalQuestionsSlice = createSlice({
	name: 'additionalQuestions',
	initialState,
	reducers: {
		setAdditionalQuestionsState: (state, { payload }: PayloadAction<AdditionalQuestionsState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setAdditionalQuestionsState } = additionalQuestionsSlice.actions;

export const additionalQuestionsSliceReducer = additionalQuestionsSlice.reducer;

export const selectAdditionalQuestionsState = (state: RootState) => {
	return state.createProjectReducers.additionalQuestions;
};
