import { OPdnaAccuracyChart, OdnaAccuracyChart, PdnaAccuracyChart } from 'features/Graphs/AccuracyChart';
import { ChartContainers } from '../ChartContainers';
import { SimulationViewAccuracyProps } from './SimulationViewAccuracy.types';

export const SimulationViewAccuracy = ({
	showDataOnOneGraph,
	personal,
	organisational,
	pno,
	selectedClusterIndex,
}: SimulationViewAccuracyProps) => {
	return (
		<>
			<ChartContainers showDataOnOneGraph={showDataOnOneGraph} />
			<>
				<PdnaAccuracyChart
					containerId="1"
					benchmarkIndexes={personal}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
				<OdnaAccuracyChart
					containerId="2"
					benchmarkIndexes={organisational}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
				<OPdnaAccuracyChart
					containerId="3"
					odnaBenchmarkIndexes={organisational}
					pdnaBenchmarkIndexes={personal}
					pnoBenchmarkIndexes={pno}
					clusterIndex={selectedClusterIndex}
				/>
			</>
		</>
	);
};
