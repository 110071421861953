import { ApiProjectDetails, ProjectDetails } from 'common/types';
import { ProjectDetailsResponse, useGetProjectDetailsQuery } from 'store/api';
import { mapGetProjectDetailsResponse } from './mappers';

type UseProjectDetailsProps = {
	projectId?: string;
};

export const useProjectDetails = (option?: UseProjectDetailsProps) => {
	const projectDetails = useGetProjectDetailsQuery(
		{ projectId: option?.projectId as string },
		{ skip: option?.projectId == null },
	);

	const project = getProjectDetailsData();

	return {
		projectDetails: project ? transform(project) : undefined,
	};

	function getProjectDetailsData(): ProjectDetailsResponse<ApiProjectDetails> | undefined {
		if (!projectDetails.isSuccess) return undefined;

		return projectDetails.data;
	}

	function transform(projects: ProjectDetailsResponse<ApiProjectDetails>): ProjectDetailsResponse<ProjectDetails> {
		return mapGetProjectDetailsResponse(projects);
	}
};
