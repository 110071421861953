import { TableAssignHeader } from 'common/components';
import React from 'react';
import { OnAssignationChangeCallback } from 'features/Tables/hooks';
import { useEditAllParticipantsAssignmentToProjectMutation } from 'store/api';
import { useParams } from 'react-router-dom';
import { useSuspenseManually } from 'common/hooks';

export type AssignHeaderProps = {
	filters: { [key: string]: string };
	assignName: string;
	onAssignationChange: OnAssignationChangeCallback;
};

export const AssignHeaderHeader = ({ filters, assignName, onAssignationChange }: AssignHeaderProps) => {
	const { projectId } = useParams();
	const [assignToggle] = useEditAllParticipantsAssignmentToProjectMutation();
	const suspense = useSuspenseManually();

	return (
		<TableAssignHeader
			key={assignName}
			title={assignName}
			selected={false}
			onAssignAllClick={(event) => onAssign(event)}
			onRemoveAllClick={(event) => onUnAssign(event)}
		/>
	);

	function onAssign(event: React.MouseEvent) {
		suspense.turnOn();
		event.stopPropagation();
		const request = {
			assign: true,
			filters: filters,
		};
		if (projectId) {
			assignToggle({ ...request, projectId }).then(onAssignationChange);
		}
	}

	function onUnAssign(event: React.MouseEvent) {
		suspense.turnOn();
		event.stopPropagation();
		const request = {
			assign: false,
			filters: filters,
		};
		if (projectId) {
			assignToggle({ ...request, projectId }).then(onAssignationChange);
		}
	}
};
