import { useState, useEffect, useRef } from 'react';

type UseClickOutsideListenerReturnType<T, R> = {
	ref: React.RefObject<T>;
	additionalRef: React.RefObject<R>;
	wasClickedOutsideRefComponent: boolean;
};

type UseClickOutsideListenerProps = {
	onClickAway?: () => void;
};

export const useClickOutsideListener = <T, R>(
	props?: UseClickOutsideListenerProps,
): UseClickOutsideListenerReturnType<T, R> => {
	const ref = useRef<T>(null);
	const additionalRef = useRef<R>(null);
	const [wasClickedOutsideRefComponent, setWasClickedOutsideRefComponent] = useState(false);

	const handleClickOutside = (e: MouseEvent) => {
		const isNodeType = e.target instanceof Node;
		const isHtmlElementType = ref.current instanceof HTMLElement;

		const connectedRefIsNodeType = e.target instanceof Node;
		const connectedRefIsHtmlElementType = additionalRef.current instanceof HTMLElement;

		const refConditions = ref.current && isHtmlElementType && isNodeType && !ref.current.contains(e.target);
		const connectedRefConditions =
			additionalRef.current &&
			connectedRefIsNodeType &&
			connectedRefIsHtmlElementType &&
			!additionalRef.current.contains(e.target);

		if (refConditions && connectedRefConditions) {
			setWasClickedOutsideRefComponent(true);

			if (props?.onClickAway) {
				props.onClickAway();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return { ref, wasClickedOutsideRefComponent, additionalRef };
};
