import { Framework } from 'common/types';
import { RegexPatterns } from 'common/constants';

const lowToHighSorting = 1;
const highToLowSorting = -1;

export const sortFrameworks = (frameworks: Framework[]): Framework[] => {
	return frameworks.sort((firstCompareElement, secondCompareElement) =>
		firstCompareElement.name > secondCompareElement.name ? lowToHighSorting : highToLowSorting,
	);
};

export const getMissingNumbers = (frameworks: Framework[]) => {
	const fameworksNumbers = frameworks.map((currentValue) =>
		Number(currentValue.name.replace(RegexPatterns.OnlyDigits, '')),
	);
	const count = fameworksNumbers.length + lowToHighSorting;
	const missingNumbers = [];
	for (let i = 1; i <= count; i++) {
		if (fameworksNumbers.indexOf(i) === highToLowSorting) {
			missingNumbers.push(i);
		}
	}
	return missingNumbers;
};
