import { CommonColorPresets } from 'features/Graphs/colorPresets';
import { PieChart } from '../../PieChart';
import { pieChartConfig } from '../../PieChartConfig';
import { PdnaPieChartProps } from './PdnaPieChart.types';
import { usePdnaPieChartData } from 'features/Graphs/PieChart';
import { useSelector } from 'react-redux';
import { selectPieChartCombinedPDNA, selectPieChartPDNA } from 'store/slices';

export const PdnaPieChart = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
	colorPresetIndex,
}: PdnaPieChartProps) => {
	const colorPreset = CommonColorPresets[colorPresetIndex];
	const config = pieChartConfig;

	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const pdna = combinedMode ? useSelector(selectPieChartCombinedPDNA) : useSelector(selectPieChartPDNA);
	const pieData = usePdnaPieChartData(pdna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<PieChart
			containerId={containerId}
			colorConfig={colorPreset}
			config={config}
			data={pieData}
		/>
	);
};
