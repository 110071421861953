import { Button } from '../Button';
import { ButtonsContainer, StyledTextContainer, StyledUnsavedChangesModal } from './styled';
import { UnsavedChangesModalProps } from './UnsavedChangesModal.types';

export const UnsavedChangesModal = ({ saveAndLeave, leave, close }: UnsavedChangesModalProps) => {
	return (
		<StyledUnsavedChangesModal>
			<StyledTextContainer>
				<p>You have unsaved changes, are you sure you want to leave without saving?</p>
			</StyledTextContainer>
			<ButtonsContainer>
				<Button
					color="tertiary"
					onClick={() => leave()}
				>
					Leave without saving
				</Button>
				<Button
					color="primary"
					onClick={() => {
						saveAndLeave();
					}}
				>
					Save and leave
				</Button>
				<Button
					variant="outlined"
					onClick={() => close()}
				>
					Cancel
				</Button>
			</ButtonsContainer>
		</StyledUnsavedChangesModal>
	);
};
