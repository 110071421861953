import { ApiTenant } from 'common/types';
import { rootApi, EditMyOrganisationBody } from 'store/api';

export const myOrganisationApi = rootApi.injectEndpoints({
	endpoints: (builder) => ({
		getMyOrganisation: builder.query<ApiTenant, void>({
			query: () => ({
				url: `/api/mytenant`,
			}),
			providesTags: ['Tenants'],
		}),
		editMyOrganisation: builder.mutation<void, EditMyOrganisationBody>({
			query: (data: EditMyOrganisationBody) => ({
				url: `/api/mytenant`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Tenants'],
		}),
	}),
});

export const { useGetMyOrganisationQuery, useEditMyOrganisationMutation } = myOrganisationApi;
