import { GraphContainer } from 'features/Graphs/common/components';
import { useOddsRatioCreator } from 'features/Graphs/OddsRatioGraph';
import { OddsRatioGraphProps } from 'features/Graphs/OddsRatioGraph';
import { OddsRatioGraphContainer, StyledGraph } from './styled';

export const OddsRatioGraph = ({ data, config }: OddsRatioGraphProps) => {
	const buildPersonalGraph = () => useOddsRatioCreator(data, config).buildPersonalGraph().getSVG();
	const buildOrganisationalGraph = () => useOddsRatioCreator(data, config).buildOrganisationalGraph().getSVG();

	const personalGraphId = '1';
	const organisationalGraphId = '2';
	return (
		<OddsRatioGraphContainer>
			<StyledGraph id={personalGraphId} />
			<StyledGraph id={organisationalGraphId} />

			<GraphContainer
				containerId={personalGraphId}
				data={config}
				buildGraph={buildPersonalGraph}
			/>

			<GraphContainer
				containerId={organisationalGraphId}
				data={config}
				buildGraph={buildOrganisationalGraph}
			/>
		</OddsRatioGraphContainer>
	);
};
