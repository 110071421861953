import styled, { css } from 'styled-components';
import { DistributionTableColorPreset, ScrollableContainerProps } from './DistributionTable.types';

const scrollBar = css`
	::-webkit-scrollbar {
		margin-top: 50px;
	}

	::-webkit-scrollbar-track {
		margin-top: 100px;
	}
`;

export const StyledScrollableContainer = styled.div<ScrollableContainerProps>`
	margin-left: 2rem;
	overflow-x: hidden;
	width: 95%;
	height: 85%;
	font-size: 20px;
	line-height: 20px;
	overflow-y: ${({ $shouldScroll }) => ($shouldScroll ? 'auto' : 'hidden')};
	${scrollBar}
	pointer-events: all;
`;

export const StyledTitle = styled.div`
	color: ${({ theme }) => theme.colors.common.white};
	background-color: ${({ theme }) => theme.colors.common.darkGrey};
	padding: 10px;
	font-weight: 400;
	letter-spacing: 2px;
	border-radius: 10px 10px 0px 0px;
	font-size: 1.2rem;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const tableNavigationColor = css`
	background-color: #d8d8d8 !important;
`;

export const StyledTableContainer = styled.div<DistributionTableColorPreset>`
	text-align: center !important;

	${'thead'} {
		${'tr'} {
			${tableNavigationColor}
			${'div'} {
				color: ${({ theme }) => theme.colors.common.black} !important;
			}
			${'td:nth-child(1)'} {
				display: flex;
				justify-content: center;
			}
			${'td'} {
				border-left: 1px solid ${({ theme }) => theme.colors.common.white} !important;
				border-right: 1px solid ${({ theme }) => theme.colors.common.white} !important;

				border-top-right-radius: 0px !important;
				border-top-left-radius: 0px !important;

				${':nth-child(1) div'} {
					font-weight: 600;
				}
			}
			${':nth-child(2)'} {
				display: none;
			}
		}
	}

	${'td'} {
		word-break: normal;
		vertical-align: inherit !important;
	}

	${'tbody tr td'} {
		border: 1px solid ${({ theme }) => theme.colors.common.white} !important;

		${':nth-child(1)'} {
			${tableNavigationColor}
		}

		${':nth-child(2)'} {
			background-color: ${({ $firstColumnColor }) => $firstColumnColor} !important;
			color: ${({ $firstTextColor }) => $firstTextColor} !important;
		}
		${':nth-child(3)'} {
			background-color: ${({ $secondColumnColor }) => $secondColumnColor} !important;
			color: ${({ $secondTextColor }) => $secondTextColor} !important;
		}
		${':nth-child(4)'} {
			background-color: ${({ $thirdColumnColor }) => $thirdColumnColor} !important;
			color: ${({ $thirdTextColor }) => $thirdTextColor} !important;
		}
		${':nth-child(5)'} {
			background-color: ${({ $fourthColumnColor }) => $fourthColumnColor} !important;
			color: ${({ $fourthTextColor }) => $fourthTextColor} !important;
		}
		${':nth-child(6)'} {
			background-color: ${({ $fifthColumnColor }) => $fifthColumnColor} !important;
			color: ${({ $fifthTextColor }) => $fifthTextColor} !important;
		}
		${':nth-child(7)'} {
			background-color: ${({ theme }) => theme.colors.common.darkGrey} !important;
			color: ${({ theme }) => theme.colors.common.white};
		}
	}
`;
