import { ColumnFilter } from '@tanstack/react-table';
import { TableQueryParams } from 'common/types';
import { isEmptyArray, filterWithQuotation, isDate } from 'common/utils';
import { isSameDay } from 'date-fns';
import { compare } from 'features/Tables/utils/sorting';

export const filterAndSortClientTableData = <T extends object>(
	queryParams: TableQueryParams,
	data: T[],
	setFilteredData: React.Dispatch<React.SetStateAction<T[]>>,
	customSorting?: (orderBy: string, direction: string, first: T, second: T) => number,
): void => {
	const filtered = filter(data);
	const sorted = sort(filtered);
	setFilteredData([...sorted]);

	function sort(data: T[]): T[] {
		const { orderBy, direction } = queryParams;
		if (!orderBy) {
			return data;
		}

		return data.sort((first, second) => {
			const sortingFunction = customSorting || compare;
			return sortingFunction(orderBy, direction, first, second);
		});
	}

	function filter(data: T[]): T[] {
		const { filters } = queryParams;
		const isNoFilterApplied = isEmptyArray(filters);

		if (isNoFilterApplied) {
			return data;
		}

		const createFilteredRow = (row: T, filter: ColumnFilter) => {
			return Object.entries(row).some(([rowKey, rowValue]) => {
				const filterId = filter.id;
				const filterContent = filter.value;
				const isFilterStringType = typeof rowValue === 'string' && typeof filterContent === 'string';
				const columnCannotBeFiltered = () => rowKey.toLowerCase() !== filterId.toLowerCase();

				if (columnCannotBeFiltered()) {
					return null;
				}

				if (isFilterStringType) {
					return filterWithQuotation(rowValue, filterContent);
				}

				const isFilterDateType = isDate(filterContent);
				const isRowValueDateType = isDate(rowValue);
				const canBeTreatedAsDate = isFilterDateType && isRowValueDateType;
				if (canBeTreatedAsDate) {
					const filterDate = filterContent;
					return isSameDay(rowValue, filterDate);
				}
			});
		};

		const tableData = [...data];

		filters.forEach((filter) => {
			const filtered = tableData.filter((row) => createFilteredRow(row, filter));
			const start = 0;
			tableData.splice(start, tableData.length, ...filtered);
		});

		return [...tableData];
	}
};
