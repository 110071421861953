import {
	ParticipantFormValidationProperties,
	useParticipantFormData,
	useParticipantFormValidation,
} from 'features/Participants/components/ParticipantForm/hooks';
import { ParticipantFormLayout } from 'features/Participants/views';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateParticipantMutation } from 'store/api';

export const CreateParticipant = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const { validateForm } = useParticipantFormValidation();
	const { participantFormData: createParticipantData } = useParticipantFormData();
	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const [createParticipant] = useCreateParticipantMutation();
	const navigate = useNavigate();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const validationParameters: ParticipantFormValidationProperties = {
			shouldCheckForParticipantEmailUniqueness: true,
		};
		const validate = () => validateForm(createParticipantData, validationParameters);
		const isFormValid = await validate();

		if (!isFormValid) return;

		createParticipant(createParticipantData).then(() => {
			navigate('/assessees');
		});
	};

	return (
		<ParticipantFormLayout
			id="create-participant"
			title="CREATE PARTICIPANT"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
