import { TableSortingDirections } from 'common/constants';

export const compare = (fieldName: string, direction: string, first: object, second: object) => {
	const firstValue = extractField(fieldName, first);
	const secondValue = extractField(fieldName, second);
	const shouldBeEqual = 0;
	if (firstValue == secondValue) {
		return shouldBeEqual;
	}

	const lesserThan = -1;
	const greaterThan = 1;
	const naturalDirection = firstValue > secondValue ? greaterThan : lesserThan;
	const sortDirerionModifier = direction === TableSortingDirections.Desc ? lesserThan : greaterThan;
	return naturalDirection * sortDirerionModifier;
};

export const extractField = <T extends object>(fieldName: string, entry: object): T => {
	const fieldNameIndex = 0;
	const fieldValueIndex = 1;
	const first = 0;
	const foundByFieldName = Object.entries(entry).filter((e) => e[fieldNameIndex] === fieldName);
	if (!foundByFieldName.length) {
		throw new Error(`There's no field in ${entry} named ${fieldName}`);
	}
	return foundByFieldName[first][fieldValueIndex];
};
