import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { OrganisationAddressState } from '.';

const initialState: OrganisationAddressState = {
	country: '',
	firstAddress: '',
	secondAddress: '',
	city: '',
	postcode: '',
};

export const organisationAddressSlice = createSlice({
	name: 'organisationAddress',
	initialState,
	reducers: {
		setOrganisationAddressState: (state, { payload }: PayloadAction<OrganisationAddressState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setOrganisationAddressState } = organisationAddressSlice.actions;

export const organisationAddressSliceReducer = organisationAddressSlice.reducer;

export const getOrganisationAddressState = (state: RootState) => {
	return state.createClientReducers.organisationAddress;
};
