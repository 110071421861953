import { ManagerValidationProperties, useManagerFormDataValidation } from 'features/ClientConfiguration/components';
import { ManagerFormLayout } from 'features/ClientConfiguration/views';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateManagerMutation } from 'store/api';
import { selectCreateManagerState } from 'store/slices';

export const CreateManager = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const createManagerData = useSelector(selectCreateManagerState);
	const { validateForm } = useManagerFormDataValidation();
	const [createManager] = useCreateManagerMutation();
	const navigate = useNavigate();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const validationParameters: ManagerValidationProperties = { shouldCheckForManagerIdUniqueness: true };
		const validate = () => validateForm(createManagerData, validationParameters);
		const isFormValid = await validate();

		if (!isFormValid) return;

		const response = await createManager(createManagerData).unwrap();

		if (response.id) {
			navigate('/apdManagers');
		}
	};

	return (
		<ManagerFormLayout
			id="create-manager"
			saveTrigger={saveTrigger}
			onSaveButtonClick={onSaveButtonClick}
		/>
	);
};
