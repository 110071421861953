import styled from 'styled-components';
import { fadeInAnimation, fadeInAnimationDuration, fadeInTransition } from 'common/components/AnimatedGraphContainers';

export const GraphContainer = styled.div`
	pointer-events: all;
	display: flex;
	justify-content: space-evenly;

	animation-name: ${fadeInAnimation};
	animation-duration: ${fadeInAnimationDuration};
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	${fadeInTransition}
`;
