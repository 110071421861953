export const AssessmentTypes = {
	ODNA: 'ODNA',
	PDNA: 'PDNA',
	OPDNA: 'OPDNA',
} as const;

export const TableAssessmentTypes = {
	ODNA: 'ODNA',
	PDNA: 'PDNA',
	OPDNA: 'O&PDNA',
} as const;
