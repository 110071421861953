import { BenchmarkQuadrantGraph } from 'features/Graphs/QuadrantGraph/BenchmarkQuadrantGraph';
import { SimulationViewQuadrantProps } from './SimulationViewQuadrant.types';
import { StyledContainer, StyledFullChartContainer } from './styled';

export const SimulationViewQuadrant = ({ selectedClusterIndex, colorPresetIndex }: SimulationViewQuadrantProps) => {
	return (
		<>
			<StyledContainer>
				<StyledFullChartContainer id="1"></StyledFullChartContainer>
			</StyledContainer>
			<>
				<BenchmarkQuadrantGraph
					containerId={'1'}
					colorPresetId={colorPresetIndex}
					clusterIndex={selectedClusterIndex}
				/>
			</>
		</>
	);
};
