import { StyledAnchor, StyledLinkList, StyledListItem } from './styled';

export const LinkList = () => {
	return (
		<StyledLinkList id="footer-menu">
			<StyledListItem>
				<StyledAnchor
					href="https://ipsychtec.com/helpdesk/"
					target="_blank"
					rel="noreferrer"
				>
					Helpdesk
				</StyledAnchor>
			</StyledListItem>
			<StyledListItem>
				<StyledAnchor
					href="https://ipsychtec.com/faq/"
					target="_blank"
					rel="noreferrer"
				>
					FAQ
				</StyledAnchor>
			</StyledListItem>
		</StyledLinkList>
	);
};
