import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { CreateClientAdminSlice } from './createClientAdminSlice.types';

const initialState: CreateClientAdminSlice = {
	name: '',
	password: '',
	retypedPassword: '',
	tenantId: '',
	username: '',
};

export const createClientAdminSlice = createSlice({
	name: 'createClientAdmin',
	initialState,
	reducers: {
		setCreateClientAdminState: (state, { payload }: PayloadAction<CreateClientAdminSlice>) => {
			state = payload;
			return state;
		},

		resetCreateClientAdminState: () => initialState,
	},
});

export const { resetCreateClientAdminState, setCreateClientAdminState } = createClientAdminSlice.actions;

export const selectCreateClientAdminState = (state: RootState) => state.createClientAdmin;
