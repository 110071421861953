import { format } from 'date-fns';
import { DateFormatPatterns } from 'common/constants';
import { ProjectSummaryPanelProps } from './ProjectSummaryPanel.types';
import { StyledInput } from './styled';

export const ProjectSummaryPanel = ({ projectShortId, closeDate }: ProjectSummaryPanelProps) => {
	const formattedCloseDate = closeDate ? format(closeDate, DateFormatPatterns.Default) : undefined;

	return (
		<>
			<StyledInput
				readOnly
				name="Project short ID"
				value={projectShortId ?? ''}
			/>
			<StyledInput
				readOnly
				name="Close date"
				value={formattedCloseDate ?? ''}
			/>
		</>
	);
};
