import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { BillingInformationState } from '.';

const initialState: BillingInformationState = {
	billingEntity: '',
	billingCountry: '',
	billingMail: '',
	vatNumber: '',
};

export const billingInformationSlice = createSlice({
	name: 'billingInformation',
	initialState,
	reducers: {
		setBillingInformationState: (state, { payload }: PayloadAction<BillingInformationState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setBillingInformationState } = billingInformationSlice.actions;

export const billingInformationSliceReducer = billingInformationSlice.reducer;

export const getBillingInformationState = (state: RootState) => {
	return state.createClientReducers.billingInformation;
};
