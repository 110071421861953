import { Input, NotificationTypes, useNotifications } from 'common/components';
import { CredentialsBox } from 'features/AuthManagement/components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from './styled';
import { useLoginMutation } from 'store/api';
import { setUser } from 'store';

export const LoginBox = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pushNotification } = useNotifications();
	const [login] = useLoginMutation();

	const handleLoginButtonClick = async () => {
		const credentialsNotFilled = !username || !password;

		if (credentialsNotFilled) {
			pushNotification({
				message: 'Not all required fields are filled.',
				type: NotificationTypes.Warning,
				timeout: 5000,
			});

			return;
		}
		try {
			const user = await login({ username, password }).unwrap();
			dispatch(setUser(user));
			navigate('/');
		} catch {
			pushNotification({
				message: 'Wrong username or password or your account is locked. Please try again.',
				type: NotificationTypes.Error,
				timeout: 5000,
			});
		}
	};

	return (
		<CredentialsBox title="Log In">
			<form>
				<Input
					name="User ID:"
					labelPlacement="top"
					type="text"
					autoComplete="username"
					value={username}
					placeholder="Enter your email address"
					onChange={(event) => {
						setUsername(event.currentTarget.value);
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							handleLoginButtonClick();
						}
					}}
				/>
				<Input
					name="Password:"
					labelPlacement="top"
					type="password"
					autoComplete="current-password"
					placeholder="Enter password"
					value={password}
					onChange={(event) => {
						setPassword(event.currentTarget.value);
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							handleLoginButtonClick();
						}
					}}
				/>
				<StyledButton
					variant="filled"
					underlineOnHover
					onClick={() => {
						handleLoginButtonClick();
					}}
				>
					Log in
				</StyledButton>
			</form>
		</CredentialsBox>
	);
};
