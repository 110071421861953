import { PageLayout, Table } from 'common/components';
import { useProjects } from 'common/hooks';
import { Project } from 'common/types';
import { useProjectsData } from 'features/Tables/hooks';
import { filterAndSortClientTableData } from 'features/Tables/utils';
import { useEffect, useState } from 'react';
import { CreateParticipantButton, Wrapper } from './styled';

export const ProjectsPage = () => {
	const { columns } = useProjectsData();
	const { projects, areProjectsFetched } = useProjects();
	const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

	useEffect(() => {
		setFilteredProjects(projects);
	}, [areProjectsFetched]);

	return (
		<PageLayout
			title="Project Organization"
			subtitle="Overview / Configure Projects / Manage Actions"
			id="projects"
		>
			<Wrapper>
				<CreateParticipantButton
					href="/projects/create"
					variant="outlined"
				>
					Create Project
				</CreateParticipantButton>
				<Table<Project>
					tableTypeId="projects"
					disableColumnSelector
					columns={columns}
					data={filteredProjects}
					onQueryChange={(queryParams) =>
						filterAndSortClientTableData(queryParams, projects, setFilteredProjects)
					}
				/>
			</Wrapper>
		</PageLayout>
	);
};
