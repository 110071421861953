import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { BillingReportSlice } from './billingReportSlice.types';

const initialState: BillingReportSlice = {
	startDate: '',
	endDate: '',
};

export const billingReportSlice = createSlice({
	name: 'billingReport',
	initialState,
	reducers: {
		setBillingReportState: (state, { payload }: PayloadAction<BillingReportSlice>) => {
			state = payload;
			return state;
		},

		resetBillingReportState: () => initialState,
	},
});

export const { setBillingReportState } = billingReportSlice.actions;

export const selectBillingReportState = (state: RootState) => state.billingReport;
