import { ProjectDetails, ApiProjectDetails, ApiProject, Project } from 'common/types';
import { getDateFromUnixUTC } from 'common/utils';
import { ProjectDetailsResponse, AllProjectsResponse } from 'store/api';

export const mapGetProjectDetailsResponse = (
	response: ProjectDetailsResponse<ApiProjectDetails>,
): ProjectDetailsResponse<ProjectDetails> => {
	const { closeDate, closureCriteria } = response;
	return {
		...response,
		closeDate: closeDate ? getDateFromUnixUTC(closeDate) : null,
		closureCriteria: closureCriteria
			? {
					...closureCriteria,
					closureDate: closureCriteria.closureDate ? getDateFromUnixUTC(closureCriteria.closureDate) : null,
			  }
			: null,
	};
};

export const mapGetAllProjectsResponse = (response: AllProjectsResponse<ApiProject>): AllProjectsResponse<Project> => {
	const { projects } = response;

	const mappedProjects: Project[] = projects.map((project) => ({
		...project,
		projectId: project.id,
		tenantId: project.tenantId,
		startDate: getDateFromUnixUTC(project.startDate),
		closeDate: project.closeDate ? getDateFromUnixUTC(project.closeDate) : null,
	}));

	return { projects: mappedProjects };
};
