import { ChangePasswordResponse } from 'store/api';
import { PasswordValidationResult } from './PasswordValidator';

const MESSAGES: Record<string, string> = {
	SUCCESS: 'Password changed successfully',
	PASSWORD_CURRENTLY_USED: 'Password currently in use',
	PASSWORD_UNAUTHORIZED: 'Invalid old password',
};

export const parseBackendValidationResponse = (response: ChangePasswordResponse): PasswordValidationResult => {
	const responseIndex = 0;

	if (response[responseIndex] && 'type' in response[responseIndex]) {
		const responseType = response[responseIndex].type;
		const responseMessage = response[responseIndex].message;

		if (responseType === 'INTERNAL') {
			return handleBackendError(responseMessage);
		}
	}

	return handleBackendSuccess();
};

const handleBackendSuccess = (): PasswordValidationResult => {
	return {
		success: true,
		message: prepareMessage('SUCCESS'),
	};
};

const handleBackendError = (message: string): PasswordValidationResult => {
	return {
		success: false,
		message: prepareMessage(message),
	};
};

const prepareMessage = (message: string) => {
	return MESSAGES[message];
};
