import { useSelector } from 'react-redux';
import { CreateTenantBody } from 'store/api';
import {
	getBillingAddressState,
	getBillingContactState,
	getBillingInformationState,
	getOrganisationAddressState,
	getOrganisationInformationState,
	getStakeholderContactState,
	getStakeholderInformationState,
} from 'store/slices';

export const useCreateClientFormData = () => {
	const billingAddressState = useSelector(getBillingAddressState);
	const billingContactState = useSelector(getBillingContactState);
	const billingInformationState = useSelector(getBillingInformationState);
	const organisationAddressState = useSelector(getOrganisationAddressState);
	const organisationInformationState = useSelector(getOrganisationInformationState);
	const stakeholderContactState = useSelector(getStakeholderContactState);
	const stakeholderInformationState = useSelector(getStakeholderInformationState);

	const data: CreateTenantBody = {
		address: {
			cityOrState: organisationAddressState.city,
			line1: organisationAddressState.firstAddress,
			line2: organisationAddressState.secondAddress,
			zipCode: organisationAddressState.postcode,
		},
		apdPOReference: organisationInformationState.supplierAgreementReference,
		billingAddress: {
			cityOrState: billingAddressState.billingCity,
			line1: billingAddressState.firstBillingAddress,
			line2: billingAddressState.secondBillingAddress,
			zipCode: billingAddressState.billingPostcode,
		},
		billingContact: {
			email: billingContactState.billingContactEmail,
			im: billingContactState.billingContactIM,
			tel: billingContactState.billingContactTelephone,
		},
		billingContactName: billingContactState.billingContactName,
		billingCountry: billingInformationState.billingCountry,
		billingInvoiceEmail: billingInformationState.billingMail,
		billingOrg: billingInformationState.billingEntity,
		businessContact: {
			email: stakeholderInformationState.stakeholderMail,
			im: stakeholderInformationState.stakeholderIM,
			mobile: stakeholderInformationState.stakeholderMobile,
			tel: stakeholderInformationState.stakeholderTelephone,
		},
		businessContactName: stakeholderInformationState.stakeholderName,
		businessTel: organisationInformationState.telephoneNumber,
		clientPOReference: organisationInformationState.purchaseOrderReference,
		country: organisationAddressState.country,
		emailSenderAddress: organisationInformationState.emailAddress,
		hrContact: {
			email: stakeholderContactState.secondaryContactMail,
			im: stakeholderContactState.secondaryContactIM,
			mobile: stakeholderContactState.secondaryContactMobile,
			tel: stakeholderContactState.secondaryContactTelephone,
		},
		hrContactName: stakeholderContactState.secondaryContactName,
		language: 'ENGLISH',
		name: organisationInformationState.organisationName,
		shortId: organisationInformationState.organisationId,
		vatNumber: billingInformationState.vatNumber,
	};

	return { data };
};
