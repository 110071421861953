import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const ModalContainer = styled.div`
	width: 80vw;
	height: 70vh;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h2`
	font-size: 2rem;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.text.secondary};
`;

export const GridContainer = styled.div`
	display: grid;
	height: 70%;
	margin-top: auto;
	margin-bottom: auto;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 1rem;
	column-gap: 0.5rem;
`;

export const ConfirmButton = styled(Button)`
	margin-top: auto;
	margin-left: auto;
	margin-right: 3rem;
	padding: 0.5rem 3rem;
`;
