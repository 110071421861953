import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

	body,
	html,
	#root {
		height: 100%;
		margin: 0;
		padding: 0;
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
		font-family: 'Mukta';
	}

	button, input {
		font-family: 'Mukta';
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px  ${({ theme }) => theme.colors.common.lightGrey}; 
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background:  ${({ theme }) => theme.colors.scrollbar.main};
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background:  ${({ theme }) => theme.colors.scrollbar.hovered};
	}
`;
