import { useDimensions } from 'common/hooks';
import { Dimension } from 'common/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEditedFrameworkDimensionsForSelectedCluster } from 'store/slices';
import { ConfirmButton, GridContainer, ModalContainer, Title } from './styled';
import { getBehaviourTooltipDirection } from './utils';
import { BehaviourPairTile } from 'features/ClusterConfiguration/components/BehaviourPairTile';
import { SelectedDimension } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent/BehaviourPairModalContent.types';

export const BehaviourPairModalContent = ({
	selectedDimensions,
	onConfirm,
}: {
	onConfirm: (dimensions: Dimension[]) => void;
	selectedDimensions: SelectedDimension[];
}) => {
	const { dimensions } = useDimensions();

	const dispatch = useDispatch();

	const [editedDimensions, setEditedDimensions] = useState<Dimension[]>([]);

	useEffect(() => {
		if (dimensions.length) {
			setEditedDimensions(findSelectedDimensions());
		}
	}, [dimensions.length]);

	const onClickConfirmButton = () => {
		if (onConfirm) {
			onConfirm(editedDimensions);
		}

		dispatch(setEditedFrameworkDimensionsForSelectedCluster(editedDimensions));
	};

	const onSelectDimension = (dimension: Dimension) => {
		const isDimensionAlreadySelected = editedDimensions.some(
			(editedDimension) => editedDimension.dimensionId === dimension.dimensionId,
		);

		if (isDimensionAlreadySelected) return;

		const newSelectedDimensions: Dimension[] = [...editedDimensions];
		const foundOppositeDimensionIndex = editedDimensions.findIndex(
			(editedDimension) => editedDimension.dimensionId === dimension.contraryDimensionId,
		);

		const notFoundIndex = -1;
		if (foundOppositeDimensionIndex !== notFoundIndex) {
			const elementsToRemove = 1;
			newSelectedDimensions.splice(foundOppositeDimensionIndex, elementsToRemove);
		}

		newSelectedDimensions.push(dimension);

		setEditedDimensions(newSelectedDimensions);
	};

	const getBehaviourPairTiles = () => {
		return dimensions.map((dimension, index) => {
			const selectedBehaviour = editedDimensions?.find(
				(editedDimension) =>
					editedDimension.dimensionId === dimension.primary.dimensionId ||
					editedDimension.dimensionId === dimension.contrary.dimensionId,
			);

			const selectedDimensionId = selectedBehaviour?.dimensionId;

			return (
				<BehaviourPairTile
					tooltipDirection={getBehaviourTooltipDirection(index)}
					key={dimension.primary.dimensionId}
					primaryDimension={dimension.primary}
					onSelect={onSelectDimension}
					contraryDimension={dimension.contrary}
					selectedDimensionId={selectedDimensionId}
				/>
			);
		});
	};

	return (
		<ModalContainer>
			<Title>Behaviour Selection</Title>
			<GridContainer>{getBehaviourPairTiles()}</GridContainer>
			<ConfirmButton onClick={onClickConfirmButton}>Done</ConfirmButton>
		</ModalContainer>
	);

	function findSelectedDimensions() {
		const allDimensions = dimensions.flatMap((dimensions) => [dimensions.primary, dimensions.contrary]);

		return selectedDimensions.map((selected) => {
			const found = allDimensions.find((dimension) => dimension.dimensionId === selected.dimensionId);
			if (found === undefined) {
				throw Error(`Dimension of id ${selected.dimensionId} not found`);
			}
			return found;
		});
	}
};
