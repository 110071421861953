import { NotificationTypes, Portal, useNotifications } from 'common/components';
import { BackgroundOverlay, ChartContentContainer, SideButton, SideButtonsContainer } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import { SettingsMenuView } from '../../../Simulation/views/SettingsMenuView';
import { useDispatch } from 'react-redux';
import { toggleChartSettingsView } from 'store/slices';
import { BehaviourFocusViewResultsProps } from './BehaviourFocusResults.types';
import { GraphContainer } from 'features/Graphs/common/components';
import { LineChartBuilder } from 'features/BehaviourFocusResult/views/BehaviourViewResults/LineChartBuilder';
import { StyledGraph } from 'features/Graphs/OddsRatioGraph/styled';
import { useBehaviourFocus } from 'features/BehaviourFocusResult/hooks';
import { useEffect, useState } from 'react';
import { BehaviourFocusResultMenu } from 'features/BehaviourFocusResult/components/BehaviourFocusResultMenu/BehaviourFocusResultMenu';
import { SelectedDimension } from 'features/BehaviourFocusResult/components/BehaviourPairModalContent/BehaviourPairModalContent.types';
import { ILineChartData } from '@support/alderbrooke-d3-graphs/dist/Line/types';

export const BehaviourFocusViewResults = ({
	isOpened,
	onClose,
	projectId,
	benchmarkConfigurationId,
	benchmarkLabels,
}: BehaviourFocusViewResultsProps) => {
	const dispatch = useDispatch();
	const { pushNotification } = useNotifications();

	const [behaviourFocusData, setBehaviourFocusData] = useState<ILineChartData>();
	const collectiveId = 0;
	const [selectedDimensions, setSelectedDimensions] = useState<SelectedDimension[]>([
		{
			dimensionId: collectiveId,
			name: 'Collective',
		},
	]);

	const fetchData = useBehaviourFocus();

	useEffect(() => {
		fetchData(projectId, benchmarkConfigurationId, selectedDimensions, benchmarkLabels)
			.then((data) => setBehaviourFocusData(data))
			.catch((exception) => {
				pushNotification({
					message: exception.message,
					type: NotificationTypes.Error,
					timeout: 5000,
				});
				onClose();
			});
	}, [selectedDimensions]);

	return isOpened ? (
		<Portal wrapperId="behaviour-focus-view-root">
			<BackgroundOverlay>
				<SettingsMenuView>
					<BehaviourFocusResultMenu
						dimensions={selectedDimensions}
						onDimensionsChange={(dimensions) => setSelectedDimensions(dimensions)}
					/>
				</SettingsMenuView>
				<SideButtonsContainer>
					<SideButton onClick={() => dispatch(toggleChartSettingsView(true))}>
						<FontAwesomeIcon icon={faGear} />
					</SideButton>
					<SideButton onClick={onClose}>
						<FontAwesomeIcon icon={faHouse} />
					</SideButton>
				</SideButtonsContainer>
				<ChartContentContainer>{graph()}</ChartContentContainer>
			</BackgroundOverlay>
		</Portal>
	) : (
		<></>
	);

	function graph() {
		return (
			<>
				<StyledGraph id={'1'} />
				{behaviourFocusData && (
					<GraphContainer
						containerId="1"
						data={[]}
						buildGraph={() => new LineChartBuilder(behaviourFocusData).build()}
					/>
				)}
			</>
		);
	}
};
