import { FooterLogo, StyledFooter, StyledParagraph } from './styled';

export const Footer = () => {
	return (
		<StyledFooter>
			<StyledParagraph>Powered by:</StyledParagraph>
			<FooterLogo />
		</StyledFooter>
	);
};
