import { StyledButton, StyledIcon, StyledLinkButton } from './styled';
import { ButtonProps } from './Button.types';

export const Button = ({
	variant = 'filled',
	color = 'primary',
	disabled,
	faded,
	startIcon,
	endIcon,
	onClick,
	underlineOnHover = false,
	readOnly = false,
	className,
	children,
	href,
	buttonRef,
	id,
}: ButtonProps) => {
	const onButtonClick = (): void => {
		if (disabled) return;
		if (!onClick) return;

		onClick();
	};

	const CommonContent = (
		<>
			{startIcon && <StyledIcon>{startIcon}</StyledIcon>}
			{children}
			{endIcon && <StyledIcon>{endIcon}</StyledIcon>}
		</>
	);
	if (href !== undefined) {
		return (
			<StyledLinkButton
				to={href}
				ref={buttonRef}
				$color={color}
				$isIconVisible={startIcon !== undefined || endIcon !== undefined}
				$variant={variant}
				$faded={faded}
				$underlineOnHover={underlineOnHover}
				$readOnly={readOnly}
				disabled={disabled}
				onClick={onButtonClick}
				id={id}
				className={className}
			>
				{CommonContent}
			</StyledLinkButton>
		);
	}

	return (
		<StyledButton
			type="button"
			$color={color}
			ref={buttonRef}
			$isIconVisible={startIcon !== undefined || endIcon !== undefined}
			$variant={variant}
			$faded={faded}
			$underlineOnHover={underlineOnHover}
			$readOnly={readOnly}
			disabled={disabled}
			onClick={onButtonClick}
			id={id}
			className={className}
		>
			{CommonContent}
		</StyledButton>
	);
};
