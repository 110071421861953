import { EditProjectBody } from 'store/api';

export const useEditProjectFormDataValidation = () => {
	const validateForm = (data: EditProjectBody) => {
		return [required(data.description)].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function required(data: string) {
		return data.trim() != null && !!data.trim().length;
	}
};
