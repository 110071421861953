import styled, { css } from 'styled-components';
import { StyledTHeadLabelProps } from './TableHeadLabel.types';

const sortableHeadLabel = css`
	justify-content: space-between;
	text-align: left;
	cursor: pointer;
	user-select: none;
`;

export const StyledTHeadLabel = styled.div<StyledTHeadLabelProps>`
	display: flex;
	justify-content: flex-start;
	vertical-align: start;
	color: ${({ theme }) => theme.colors.common.white};
	text-align: center;
	overflow-wrap: anywhere;

	${({ $sortable }) => $sortable && sortableHeadLabel}
`;
