import { Input } from 'common/components/Input';
import { StyledInput as $StyledInput } from 'common/components/Input/styled';
import styled from 'styled-components';

export const StyledTableFilter = styled.div`
	cursor: pointer;
`;

export const StyledInput = styled(Input)`
	${$StyledInput} {
		cursor: pointer;
	}
`;
