import { PageLayout, Table } from 'common/components';
import { useTenantsAdmins } from 'common/hooks';
import { useTenantsAdminsData } from 'features/Tables/hooks';
import { CreateClientButton } from './styled';
import { TenantAdmin } from 'common/types';
import { useEffect, useState } from 'react';
import { filterAndSortClientTableData } from 'features/Tables/utils';
import { DeleteClientAdminConfirmDialog } from 'features/ClientAdmins/components';
import { UnlockClientAdminConfirmDialog } from 'features/ClientAdmins/components';

export const ClientAdministrators = () => {
	const [filteredTenantsAdmins, setFilteredTenantsAdmins] = useState<TenantAdmin[]>([]);
	const [isDeleteConfirmDialogOpened, setIsDeleteConfirmDialogOpened] = useState(false);
	const [isUnlockConfirmDialogOpened, setIsUnlockConfirmDialogOpened] = useState(false);

	const { columns, tenantAdminToDelete, tenantAdminToUnlock } = useTenantsAdminsData(
		openDeleteConfirmDialog,
		openUnlockConfirmDialog,
	);
	const { areTenantsAdminsFetched, tenantsAdmins } = useTenantsAdmins();

	const refreshTrigger = createRefreshTrigger();

	useEffect(() => {
		setFilteredTenantsAdmins(tenantsAdmins);
	}, [areTenantsAdminsFetched, refreshTrigger]);

	return (
		<PageLayout
			title="Client Administrator Management"
			id="client-administrators"
		>
			{tenantAdminToDelete && (
				<DeleteClientAdminConfirmDialog
					isOpen={isDeleteConfirmDialogOpened}
					tenantAdminToDelete={tenantAdminToDelete}
					onClose={closeDeleteConfirmDialog}
				/>
			)}
			{tenantAdminToUnlock && (
				<UnlockClientAdminConfirmDialog
					isOpen={isUnlockConfirmDialogOpened}
					tenantAdminToUnlock={tenantAdminToUnlock}
					onClose={closeUnlockConfirmDialog}
				/>
			)}
			<CreateClientButton
				href="/tadmins/create"
				variant="outlined"
			>
				Add Administrator
			</CreateClientButton>

			<Table<TenantAdmin>
				tableTypeId="tenants-admins"
				disableColumnSelector
				columns={columns}
				data={filteredTenantsAdmins}
				onQueryChange={(queryParams) =>
					filterAndSortClientTableData(queryParams, tenantsAdmins, setFilteredTenantsAdmins)
				}
			/>
		</PageLayout>
	);

	function createRefreshTrigger() {
		const shouldBeUnlocked = (admin: TenantAdmin) => !admin.accountLocked;
		const unlockedTenantAdmins = tenantsAdmins.filter(shouldBeUnlocked);
		return [unlockedTenantAdmins.length, tenantsAdmins.length].join('');
	}

	function closeDeleteConfirmDialog() {
		setIsDeleteConfirmDialogOpened(false);
	}

	function openDeleteConfirmDialog() {
		setIsDeleteConfirmDialogOpened(true);
	}

	function closeUnlockConfirmDialog() {
		setIsUnlockConfirmDialogOpened(false);
	}

	function openUnlockConfirmDialog() {
		setIsUnlockConfirmDialogOpened(true);
	}
};
