import { AccuracyChartDataValues } from 'features/Graphs/AccuracyChart';
import { prepareAccuracyData } from 'features/Graphs/AccuracyChart/hoc/utils/prepareData';
import { AccuracyGraphBase } from 'features/Graphs/PptxExportButton';

export const useOPdnaAccuracyChartData = (
	base: AccuracyGraphBase,
	clusterIndex: number,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const defaultIndex = 0;
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = base.pdna;
	const odna = base.odna;
	const checkedPdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : pdnaBenchmarkIndexes;
	const checkedOdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : odnaBenchmarkIndexes;

	const benchmarksLength = pdnaBenchmarkIndexes.length;

	const data: AccuracyChartDataValues[] = [];

	for (let benchmarkIndex = 0; benchmarkIndex < benchmarksLength; benchmarkIndex++) {
		const pdnaBenchmarkEnabled = checkedPdnaBenchmarks[benchmarkIndex];
		const odnaBenchmarkEnabled = checkedOdnaBenchmarks[benchmarkIndex];

		const pdnaValueExists =
			pdna.clusters[clusterIndex].benchmarks[benchmarkIndex].data[defaultIndex].series[defaultIndex].value;
		const odnaValueExists =
			odna.clusters[clusterIndex].benchmarks[benchmarkIndex].data[defaultIndex].series[defaultIndex].value;

		if (pdnaBenchmarkEnabled && pdnaValueExists) {
			data.push(...pdna.clusters[clusterIndex].benchmarks[benchmarkIndex].data);
		}

		if (odnaBenchmarkEnabled && odnaValueExists) {
			data.push(...odna.clusters[clusterIndex].benchmarks[benchmarkIndex].data);
		}
	}

	const atLeastOneBenchmarkChecked = checkedPdnaBenchmarks.includes(true) || checkedOdnaBenchmarks.includes(true);

	return prepareAccuracyData(pdna, data, atLeastOneBenchmarkChecked, clusterIndex);
};
