import { PagedParticipantsOptions, ParticipantsResponse, useLazyGetAllParticipantsQuery } from 'store/api';
import { Participant, ApiParticipant } from 'common/types';
import { transformParticipantsResponse } from './mappers';
import { getParticipantsEmptyResponse } from 'store/api';
import { useCriteriaConfiguration } from './useCriteriaConfiguration';

export const useCommonParticipants = () => {
	const criteriaConfiguration = useCriteriaConfiguration();
	const emptyResponse = getParticipantsEmptyResponse();
	const [getAllParticipants, allParticipants] = useLazyGetAllParticipantsQuery();
	const participants = getParticipantsData();

	return {
		getParticipants,
		participants: transform(participants),
	};

	function getParticipantsData(): ParticipantsResponse<ApiParticipant> {
		if (!allParticipants.isSuccess) return emptyResponse;

		return allParticipants.data;
	}

	async function getParticipants(option: PagedParticipantsOptions): Promise<ParticipantsResponse<Participant>> {
		try {
			const response = await getAllParticipants(option).unwrap();

			return transform(response);
		} catch {
			return emptyResponse;
		}
	}

	function transform(participants: ParticipantsResponse<ApiParticipant>): ParticipantsResponse<Participant> {
		return transformParticipantsResponse(participants, criteriaConfiguration);
	}
};
