import styled from 'styled-components';

export const OddsRatioGraphContainer = styled('div')`
	height: 90vh;
	width: 100vw;
	display: flex;
	justify-content: space-evenly;
`;

export const StyledGraph = styled('div')`
	margin-top: 5vh;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
`;
