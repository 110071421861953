import { downloadFile, getUTCDateFromString } from 'common/utils';
import { getUnixTime } from 'date-fns';
import { useBillingReportFormDataValidation } from 'features/BillingReport/components/hooks';
import { BillingReportFormLayout } from 'features/BillingReport/views';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GetBillingReportCsvBody, useGetBillingReportCsvMutation } from 'store/api';
import { selectBillingReportState } from 'store/slices';

export const BillingReport = () => {
	const initialSaveTriggerValue = 0;
	const saveTriggerIncrement = 1;

	const [saveTrigger, setSaveTrigger] = useState(initialSaveTriggerValue);

	const billingReport = useSelector(selectBillingReportState);

	const [getBillingReportCsv] = useGetBillingReportCsvMutation();
	const { validateForm } = useBillingReportFormDataValidation();

	const onSaveButtonClick = async () => {
		setSaveTrigger((saveTrigger) => saveTrigger + saveTriggerIncrement);
		const isFormValid = await validateForm(billingReport);
		if (!isFormValid) return;

		const utcStartDate = getUTCDateFromString(billingReport.startDate);
		const utcEndDate = getUTCDateFromString(billingReport.endDate);

		if (!utcStartDate || !utcEndDate) return;
		const startDateTimestamp = getUnixTime(utcStartDate);
		const endDateTimestamp = getUnixTime(utcEndDate);
		const getBillingReportCsvBody: GetBillingReportCsvBody = {
			startDate: startDateTimestamp,
			endDate: endDateTimestamp,
		};

		try {
			const response = await getBillingReportCsv(getBillingReportCsvBody).unwrap();
			downloadFile(response.objectUrl, response.filename);
		} catch {}
	};
	return (
		<BillingReportFormLayout
			id="billing-report"
			onSaveButtonClick={onSaveButtonClick}
			saveTrigger={saveTrigger}
		/>
	);
};
