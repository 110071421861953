import { BoxHeader } from 'features/AuthManagement/components';
import { CredentialsBoxProps } from './CredentialsBox.styles';
import { StyledCredentialsBox } from './styled';

export const CredentialsBox = ({ title, children }: CredentialsBoxProps) => {
	return (
		<StyledCredentialsBox>
			<BoxHeader title={title} />
			{children}
		</StyledCredentialsBox>
	);
};
