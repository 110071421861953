import { ProjectId } from 'common/types';
import {
	BenchmarkConfigurationId,
	CultureViewResults,
	useLazyGetCommonGraphDataFromProjectQuery,
	useLazyGetGraphConfigurationQuery,
	useLazyGetQuadrantDataFromProjectQuery,
} from 'store/api';
import { mapCultureViewResults } from './mappers';
import { vetoIfBadRequest } from '../predictiveView/mappers';

const first = 0;
const second = 1;
const third = 2;
export const useCultureViewResultsFromProject = () => {
	const [fetchResultByProject] = useLazyGetCommonGraphDataFromProjectQuery();
	const [fetchQuadrantResultByProject] = useLazyGetQuadrantDataFromProjectQuery();
	const [fetchConfig] = useLazyGetGraphConfigurationQuery();

	return (benchmarkConfigurationId: BenchmarkConfigurationId, projectId: ProjectId): Promise<CultureViewResults> => {
		const request = { benchmarkConfigurationId, projectId };
		const commonRequest = fetchResultByProject(request);
		const quadrantRequest = fetchQuadrantResultByProject(request);
		const configRequest = fetchConfig();
		return Promise.all([commonRequest, quadrantRequest, configRequest]).then((result) => {
			const commonResults = result[first].data;
			const quadrantResults = result[second].data;
			const configResults = result[third].data;
			vetoIfBadRequest(commonResults);
			return mapCultureViewResults(commonResults, quadrantResults, configResults);
		});
	};
};
