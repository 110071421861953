import { PageLayoutProps } from './PageLayout.types';
import { Container, HeaderContainer, Subtitle, Title, TitleContainer } from './styled';

export const PageLayout = ({ title, subtitle, children, className, id, RightSideComponent }: PageLayoutProps) => {
	return (
		<Container
			id={id}
			className={className}
		>
			<HeaderContainer id={`${id}-header`}>
				<TitleContainer>
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
				</TitleContainer>
				{RightSideComponent}
			</HeaderContainer>
			{children}
		</Container>
	);
};
