import { HeatmapGraphColorPresets } from 'features/Graphs/colorPresets/heatmapGraphColorPresets';
import { HeatmapGraph } from '../../HeatmapGraph';
import { OPdnaHeatmapGraphProps } from './OPdnaHeatmapGraph.types';
import { heatmapGraphConfig } from '../../HeatmapGraphConfig';
import { useHeatmapGraphData } from 'features/Graphs/HeatmapGraph';
import { useSelector } from 'react-redux';
import {
	selectHeatmapGraphCombinedODNA,
	selectHeatmapGraphCombinedPDNA,
	selectHeatmapGraphODNA,
	selectHeatmapGraphPDNA,
} from 'store/slices';
import { NotificationTypes, useNotifications } from 'common/components';

export const OPdnaHeatmapGraph = ({
	containerId,
	clusterIndex,
	pdnaBenchmarkIndexes,
	odnaBenchmarkIndexes,
	pnoBenchmarkIndexes,
	colorPresetIndex,
	onBenchmarkClick,
}: OPdnaHeatmapGraphProps) => {
	const { pushNotification } = useNotifications();
	const colorConfig = HeatmapGraphColorPresets[colorPresetIndex];
	const config = heatmapGraphConfig;

	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = combinedMode ? useSelector(selectHeatmapGraphCombinedPDNA) : useSelector(selectHeatmapGraphPDNA);
	const odna = combinedMode ? useSelector(selectHeatmapGraphCombinedODNA) : useSelector(selectHeatmapGraphODNA);
	const graphBase = { pdna, odna };
	const heatmapData = useHeatmapGraphData(
		graphBase,
		clusterIndex,
		pdnaBenchmarkIndexes,
		odnaBenchmarkIndexes,
		pnoBenchmarkIndexes,
		onBenchmarkClick,
	);

	heatmapData.data.forEach((data) => {
		if (!data.left || !data.right) {
			data.onHeaderClick = () => {
				pushNotification({
					message: 'You need Personal and Organisational data to display Tension Chart',
					type: NotificationTypes.Info,
				});
			};
		}
	});

	return (
		<HeatmapGraph
			colorConfig={colorConfig}
			config={config}
			containerId={containerId}
			data={heatmapData}
		/>
	);
};
