import { CustomFormItemLayout } from '../CustomFormItemLayout';
import { FormTextareaProps } from './FormTextarea.types';
import { StyledTextarea } from './styled';

export const FormTextarea = ({ title, error, errorText, required, minHeight, ...textareaProps }: FormTextareaProps) => {
	return (
		<CustomFormItemLayout
			title={title}
			error={error}
			errorText={errorText}
			required={required}
		>
			<StyledTextarea
				$minHeight={minHeight}
				$error={error}
				{...textareaProps}
			/>
		</CustomFormItemLayout>
	);
};
