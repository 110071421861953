import CommonColorPreset from '@support/alderbrooke-d3-graphs/dist/Common/ICommonColorPreset';
import { charts } from '@support/alderbrooke-d3-graphs';
import { PieChartConfig, PieChartData } from 'features/Graphs/PieChart';

export const usePieChartCreator = (config: PieChartConfig, data: PieChartData, colorConfig: CommonColorPreset) => {
	return { createGraph, createExportGraph };

	function createGraph() {
		const graph = createBuilder();
		return graph.build();
	}

	function createExportGraph() {
		const graph = createBuilder();
		graph.useAbsoluteDimensioning();
		return graph.build();
	}

	function createBuilder() {
		return new charts.Curve.Builder(config, data, colorConfig);
	}
};
