import styled, { css } from 'styled-components';

import { StyledTHead } from '../TableHead/styled';
import { StyledTr } from '../TableRow/styled';
import { StyledTd } from '../TableCell/styled';
import { StyledTHeadLabel } from '../TableHeadLabel/styled';
import { StyledTBody } from '../TableBody/styled';
import { StyledTableContentProps } from './TableContent.types';

export const TableWrapperParticipants = styled.div`
	display: flex;
	${StyledTd}:last-of-type {
		width: 16.6%;
	}
`;

export const TableWrapperBenchmarks = styled.div`
	display: flex;
`;

const primaryLightVariant = css<StyledTableContentProps>`
	${StyledTHead} {
		background-color: ${({ theme }) => theme.colors.primary.light};

		${StyledTr} ${StyledTd} ${StyledTHeadLabel} {
			color: ${({ theme }) => theme.colors.common.black};
		}
	}
`;

const primaryVariant = css<StyledTableContentProps>`
	${StyledTHead} {
		background-color: ${({ theme }) => theme.colors.primary.main};

		${StyledTr} ${StyledTd} ${StyledTHeadLabel} {
			color: ${({ theme }) => theme.colors.common.white};
		}
	}
`;

const coverBorderContentOnStickyScroll = css<StyledTableContentProps>`
	content: '';
	position: absolute;
	top: -1px;
	width: 1px;
	height: 6px;
	background-color: ${({ theme }) => theme.colors.common.lightGrey};
`;

const tableRoundedCorners = css`
	${StyledTHead} > ${StyledTr}:first-of-type > ${StyledTd}:first-of-type {
		position: relative;
		border-top-left-radius: 10px;
		&::before {
			left: -1px;
			right: auto;
			${coverBorderContentOnStickyScroll}
		}
	}

	${StyledTHead} > ${StyledTr}:first-of-type > ${StyledTd}:last-of-type {
		position: relative;
		border-top-right-radius: 10px;
		&::after {
			left: auto;
			right: -1px;
			${coverBorderContentOnStickyScroll}
		}
	}

	${StyledTBody} > ${StyledTr}:last-of-type > ${StyledTd}:first-of-type {
		border-bottom-left-radius: 10px;
	}

	${StyledTBody} > ${StyledTr}:last-of-type > ${StyledTd}:last-of-type {
		border-bottom-right-radius: 10px;
	}
`;

const tableBorders = css`
	${StyledTr} > ${StyledTd} {
		border: 1px solid ${({ theme }) => theme.colors.common.grey};
		border-right: none;
		border-top: none;
	}

	${StyledTHead} > ${StyledTr}:first-of-type > ${StyledTd} {
		border-top: 1px solid ${({ theme }) => theme.colors.common.grey};
		border-bottom: none;
	}

	${StyledTr} > ${StyledTd}:last-of-type {
		border-right: 1px solid ${({ theme }) => theme.colors.common.grey};
	}

	${StyledTr}:last-of-type > ${StyledTd} {
		border-bottom: 1px solid ${({ theme }) => theme.colors.common.grey};
	}
`;

export const StyledTable = styled.table<StyledTableContentProps>`
	flex-grow: 1;
	width: 100%;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.colors.common.white};
	table-layout: fixed;
	border-spacing: 0;

	${tableRoundedCorners};
	${tableBorders};

	${({ $variant }) => $variant === 'primary' && primaryVariant};
	${({ $variant }) => $variant === 'primary-light' && primaryLightVariant};
`;
