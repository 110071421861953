import styled from 'styled-components';
import { Input } from 'common/components/Input';
import { InputContainer, StyledInput as $StyledInput } from 'common/components/Input/styled';

export const SelectedCounterText = styled.p`
	margin: 0;
	margin-left: auto;
	font-size: 1.4rem;
`;

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const SelectedCounterValue = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
`;

export const StyledInput = styled(Input)`
	display: flex;
	justify-content: space-between;
	width: 30%;
	margin: 0;
	color: ${({ theme }) => theme.colors.common.darkGrey};

	${$StyledInput} {
		height: 2.8rem;
	}

	${InputContainer} {
		width: 12rem;
	}
`;

export const TableContainer = styled.div``;
