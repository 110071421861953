import {
	dataDistributionChartIcon,
	heatmapChartIcon,
	indicatorChartIcon,
	radarChartIcon,
	responseQualityChartIcon,
	scatterChartIcon,
} from 'common/assets/images';
import { ChartTypes } from 'common/constants';
import { ChartType } from 'common/types';
import { ChartTypeMenu, ChartTypeMenuItem } from 'features/Simulation/components';

export const SimulationViewChartTypeMenu = ({
	chosenChartType,
	onSelect,
}: {
	chosenChartType: ChartType;
	onSelect: (chartType: ChartType) => void;
}) => {
	return (
		<ChartTypeMenu
			selectedChartType={chosenChartType}
			onSelect={onSelect}
		>
			<ChartTypeMenuItem
				iconSrc={radarChartIcon}
				chartType={ChartTypes.Radar}
			/>
			<ChartTypeMenuItem
				iconSrc={indicatorChartIcon}
				chartType={ChartTypes.Indicator}
			/>
			<ChartTypeMenuItem
				iconSrc={responseQualityChartIcon}
				chartType={ChartTypes.ResponseQuality}
			/>
			<ChartTypeMenuItem
				iconSrc={heatmapChartIcon}
				chartType={ChartTypes.Heatmap}
			/>
			<ChartTypeMenuItem
				iconSrc={dataDistributionChartIcon}
				chartType={ChartTypes.DataDistribution}
			/>
			<ChartTypeMenuItem
				iconSrc={scatterChartIcon}
				chartType={ChartTypes.Quadrant}
			/>
		</ChartTypeMenu>
	);
};
