import { IconButton } from 'common/components/IconButton';
import styled from 'styled-components';
import { StyledIconButtonProps } from './ChartTypeMenuItemLabel.types';

export const MENU_ITEM_ICON_SIZE_PX = 81;

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
	padding: 0;
	width: ${MENU_ITEM_ICON_SIZE_PX}px;
	height: ${MENU_ITEM_ICON_SIZE_PX}px;

	opacity: ${({ $isSelected }) => ($isSelected ? '1' : '0.5')};
	transition: opacity 250ms ease-in-out;

	:hover {
		opacity: 1;
	}
`;

export const IconImage = styled.img`
	width: 75px;
	height: 75px;
`;
