import styled from 'styled-components';
import { Dropdown } from 'common/components/Dropdown';
import { DropdownButton } from 'common/components/Dropdown/styled';
import { DropdownItemListItem } from 'common/components/Dropdown/DropdownItemLabel/styled';

export const StyledDropdown = styled(Dropdown)`
	${DropdownButton} {
		width: 100%;
		padding: 0.7rem;
		font-size: 1rem;
		color: ${({ theme }) => theme.colors.text.primary};
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
		border: 1px solid;
		border-radius: 5px;
		border-color: ${({ theme }) => theme.colors.common.black};
	}

	${DropdownItemListItem} {
		letter-spacing: 1px;
	}
`;
