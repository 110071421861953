import { Button } from 'common/components/Button';
import { StyledIcon } from 'common/components/Button/styled';
import styled, { css } from 'styled-components';
import { GridListItem } from '../BehaviourListItem/styled';
import { StyledGridList } from './BehaviourList.types';

const borderListItems = css`
	& > ${GridListItem} {
		border-bottom: 1px solid ${({ theme }) => theme.colors.common.grey};
		border-right: 1px solid ${({ theme }) => theme.colors.common.grey};
	}

	& > ${GridListItem}:nth-child(even) {
		border-right: none;
	}

	& > ${GridListItem}:first-child {
		border-top-left-radius: 10px;
	}

	& > ${GridListItem}:nth-child(2) {
		border-top-right-radius: 10px;
	}

	${GridListItem}:nth-child(2n + 1):nth-last-child(-n + 2) {
		border-bottom: none;
		border-bottom-left-radius: 10px;
	}

	${GridListItem}:nth-child(2n + 1):nth-last-child(-n + 2) ~ ${GridListItem} {
		border-bottom: none;
		border-bottom-right-radius: 10px;
	}
`;

const rowColors = css`
	${GridListItem}:nth-child(4n), ${GridListItem}:nth-child(4n-1) {
		background: ${({ theme }) => theme.colors.common.silver};
	}
	${GridListItem}:nth-child(4n-2), ${GridListItem}:nth-child(4n-3) {
		background: ${({ theme }) => theme.colors.common.lightGrey};
	}
`;

export const GridList = styled.ul<StyledGridList>`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 0;
	padding: 0;
	list-style-type: none;
	border: ${({ theme, $disabledBorder }) => !$disabledBorder && `1px solid ${theme.colors.common.grey}`};
	border-radius: 10px;

	${borderListItems}
	${rowColors}
`;

export const StyledButton = styled(Button)`
	margin-top: 0.5rem;
	width: 100%;
	justify-content: center;

	${StyledIcon} {
		margin: 0 0.5rem;
	}
`;
