import {
	Modal,
	NotificationTypes,
	SelectionPanel,
	SelectionPanelItem,
	UnsavedChangesModal,
	useNotifications,
} from 'common/components';
import { DefaultClusterNames } from 'common/constants';
import { useFrameworkDetails, useFrameworks } from 'common/hooks';
import { Dimension, DimensionCluster } from 'common/types';
import { deepEqual } from 'common/utils';
import {
	BehaviourList,
	BehaviourListItem,
	BehaviourPairModalContent,
	ConfirmableNameInput,
	ContentWrapper,
	DescriptionTextarea,
} from 'features/ClusterConfiguration/components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useParams } from 'react-router-dom';
import { useEditFrameworkMutation } from 'store/api';
import {
	selectEditedDimensionsForChosenCluster,
	selectEditedFramework,
	selectSelectedEditedDimensionCluster,
	setChosenEditedDimensionClusterId,
	setEditedFramework,
	setEditedFrameworkClusterName,
	setEditedFrameworkDescription,
	setEditedFrameworkDimensionsForSelectedCluster,
	setEditedFrameworkName,
} from 'store/slices';
import { ClusterSelectionPanelContainer, ConfigurationContent, SaveChangesButton, StyledPageLayout } from './styled';
import {
	formatNewName,
	getUnselectedClustersNames,
	getUnselectedFrameworksNames,
	validateClusterName,
	validateFrameworkName,
} from './utils';

export const ClusterConfigurationDetails = () => {
	const firstDimensionCluster = 0;
	const maxFrameworkNameLength = 32;
	const maxClusterNameLength = 32;
	const maxDescriptionLength = 400;

	const dispatch = useDispatch();
	const [saveEditedFramework] = useEditFrameworkMutation();
	const { pushNotification } = useNotifications();
	const { frameworkId } = useParams();
	const { frameworks } = useFrameworks();
	const { frameworkDetails, isFrameworkDetailsFetched } = useFrameworkDetails({ frameworkId });

	const [isBehaviourModalOpened, setIsBehaviourModalOpened] = useState(false);
	const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);

	const editedFramework = useSelector(selectEditedFramework);
	const editedDimensions = useSelector(selectEditedDimensionsForChosenCluster);
	const selectedCluster = useSelector(selectSelectedEditedDimensionCluster);
	const [frameworkChanged, setFrameworkChanged] = useState(false);
	const blocker = useBlocker(frameworkChanged);

	const isClusterNameEditable =
		!!selectedCluster?.selectedDimensions.length &&
		selectedCluster?.cluster.defaultName !== DefaultClusterNames.Cl00;

	useEffect(() => {
		if (frameworkDetails && editedFramework) {
			setFrameworkChanged(!deepEqual(frameworkDetails, editedFramework));
		}
	}, [editedFramework]);

	useEffect(() => {
		if (blocker.state === 'blocked' && !frameworkChanged) {
			blocker.reset();
		} else if (blocker.state === 'blocked') {
			setIsUnsavedChangesModalOpen(true);
		}
	}, [blocker, frameworkChanged]);

	useEffect(() => {
		if (isFrameworkDetailsFetched && frameworkDetails && frameworkDetails.clusters[firstDimensionCluster]) {
			dispatch(setEditedFramework(frameworkDetails));
			dispatch(
				setChosenEditedDimensionClusterId(frameworkDetails.clusters[firstDimensionCluster].cluster.clusterId),
			);
		}
	}, [isFrameworkDetailsFetched]);

	const onConfirmFrameworkName = (newFrameworkName: string) => {
		if (!editedFramework) return;

		const formattedNewName = formatNewName(newFrameworkName);
		const otherFrameworkNames = getUnselectedFrameworksNames(frameworks, editedFramework);
		const validation = validateFrameworkName(formattedNewName, otherFrameworkNames);

		if (validation.success) {
			dispatch(setEditedFrameworkName(formattedNewName));
			return;
		}

		pushNotification({
			message: validation.message,
			type: NotificationTypes.Warning,
		});
	};

	const onConfirmClusterName = (newClusterName: string) => {
		if (!frameworkDetails || !selectedCluster) return;

		const otherClusterNames = getUnselectedClustersNames(frameworkDetails, selectedCluster);
		const formattedNewName = formatNewName(newClusterName);
		const validation = validateClusterName(formattedNewName, otherClusterNames);

		if (validation.success) {
			if (!frameworkDetails) return;

			dispatch(setEditedFrameworkClusterName({ clusterName: formattedNewName }));

			return;
		}

		pushNotification({
			message: validation.message,
			type: NotificationTypes.Warning,
		});
	};

	const onDeleteBehaviour = (dimension: Dimension) => {
		if (!editedDimensions) return;

		const newEditedDimensions = editedDimensions.filter(
			(editedDimension) => editedDimension.dimensionId !== dimension.dimensionId,
		);

		const noDimensions = !newEditedDimensions.length;
		if (noDimensions && selectedCluster) {
			dispatch(setEditedFrameworkClusterName({ clusterName: selectedCluster.cluster.defaultName }));
		}
		dispatch(setEditedFrameworkDimensionsForSelectedCluster(newEditedDimensions));
	};

	const getClusterPanelItems = () => {
		if (!editedFramework) return [<></>];

		return editedFramework.clusters.map((dimensionCluster) => {
			const { cluster, selectedDimensions } = dimensionCluster;
			const doesHaveDimensions = !!selectedDimensions.length;

			return (
				<SelectionPanelItem
					key={cluster.clusterId}
					id={cluster.clusterId}
					fadedWhenInactive={!doesHaveDimensions}
					itemData={dimensionCluster}
				>
					{cluster.name}
				</SelectionPanelItem>
			);
		});
	};

	const getBehaviourList = () => {
		if (!selectedCluster) return [<></>];

		const isFirstCluster = selectedCluster.cluster.defaultName === DefaultClusterNames.Cl00;
		const oneDimensionLength = 1;
		const isOnlyOneDimensionSelected = selectedCluster.selectedDimensions.length === oneDimensionLength;
		const isDeleteIconHidden = isFirstCluster && isOnlyOneDimensionSelected;

		const ListItems = selectedCluster.selectedDimensions.map((dimension) => (
			<BehaviourListItem
				key={dimension.dimensionId}
				onDeleteClick={() => onDeleteBehaviour(dimension)}
				hideDeleteIcon={isDeleteIconHidden}
			>
				{dimension.name}
			</BehaviourListItem>
		));

		return (
			<BehaviourList onAddNewBehaviourClick={() => setIsBehaviourModalOpened(true)}>{ListItems}</BehaviourList>
		);
	};

	const handleSaveChanges = (redirectAfterSave = false) => {
		if (editedFramework) {
			saveEditedFramework({ framework: editedFramework })
				.then(() => {
					pushNotification({
						message: 'Changes saved successfully',
						type: NotificationTypes.Success,
					});
					setFrameworkChanged(false);
					if (redirectAfterSave) {
						blocker.proceed?.();
					}
				})
				.catch(() => {
					pushNotification({
						message: 'Something went wrong',
						type: NotificationTypes.Error,
					});
				});
		}
	};

	return (
		<>
			<Modal
				id="behaviours-modal-behaviour-selection"
				isOpen={isBehaviourModalOpened}
				hiddenCloseButton
				onClose={() => setIsBehaviourModalOpened(false)}
			>
				<BehaviourPairModalContent
					selectedDimensions={selectedCluster?.selectedDimensions}
					onConfirm={() => {
						setIsBehaviourModalOpened(false);
					}}
				/>
			</Modal>
			<Modal
				id="behaviours-modal-unsaved-changes"
				isOpen={isUnsavedChangesModalOpen}
				hiddenCloseButton
				onClose={() => {
					setIsUnsavedChangesModalOpen(false);
				}}
				className="primary"
			>
				<UnsavedChangesModal
					saveAndLeave={() => handleSaveChanges(true)}
					leave={() => blocker.proceed?.()}
					close={() => setIsUnsavedChangesModalOpen(false)}
				/>
			</Modal>
			<StyledPageLayout
				title="Cluster Configuration"
				id="cluster-configuration-details"
				RightSideComponent={
					<SaveChangesButton
						id="save-changes-button"
						onClick={handleSaveChanges}
					>
						Save Changes
					</SaveChangesButton>
				}
			>
				<ConfirmableNameInput
					id="framework-name"
					value={editedFramework?.name || ''}
					label="Current Framework:"
					onConfirm={onConfirmFrameworkName}
					maxLength={maxFrameworkNameLength}
				/>
				<ConfigurationContent id="content">
					<ContentWrapper
						id="content-framework-description"
						title="Framework Description"
					>
						<DescriptionTextarea
							value={editedFramework?.description || ''}
							onChange={(e) => dispatch(setEditedFrameworkDescription(e.target.value))}
							maxLength={maxDescriptionLength}
						/>
					</ContentWrapper>
					{selectedCluster && (
						<ContentWrapper
							id="content-behaviours"
							title={selectedCluster.cluster.name}
						>
							{getBehaviourList()}
						</ContentWrapper>
					)}
				</ConfigurationContent>
				<ClusterSelectionPanelContainer id="clusters-details">
					<ConfirmableNameInput
						id="clusters-details-cluster-name"
						value={selectedCluster?.cluster.name || ''}
						label="Cluster Name:"
						onConfirm={onConfirmClusterName}
						readOnly={!isClusterNameEditable}
						maxLength={maxClusterNameLength}
					/>
					{selectedCluster && (
						<SelectionPanel<DimensionCluster>
							id="clusters-details-cluster-selection-panel"
							selectedItemId={selectedCluster.cluster.clusterId}
							onSelect={(item) => dispatch(setChosenEditedDimensionClusterId(item.cluster.clusterId))}
						>
							{getClusterPanelItems()}
						</SelectionPanel>
					)}
				</ClusterSelectionPanelContainer>
			</StyledPageLayout>
		</>
	);
};
