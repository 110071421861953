import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { rootApi } from './api';
import {
	authSlice,
	authSliceReducer,
	benchmarkSlice,
	frameworkSlice,
	createClientReducers,
	createClientAdminSlice,
	billingReportSlice,
	createManagerSlice,
	createProjectReducers,
	suspenseSlice,
	importParticipantsSlice,
	createParticipantReducers,
	chartSettingsSlice,
	behaviourFocusSlice,
} from './slices';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {
	accuracyChartSlice,
	dispersionChartSlice,
	quadrantSlice,
	heatmapGraphSlice,
	pieChartSlice,
	radarGraphSlice,
} from './slices/graphSlices';
import { tensionGraphConfigurationSlice } from 'store/slices';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'rootApi',
		'benchmark',
		'createClientAdmin',
		'createManager',
		'suspense',
		'billingReport',
		'importParticipants',
		'radarGraph',
		'pieChart',
		'accuracyChart',
		'heatmapGraph',
		'dispersionChart',
		'criteriaInformation',
		'participantsInformation',
		'chartSettings',
	],
};

const rootReducer = combineReducers({
	[rootApi.reducerPath]: rootApi.reducer,
	auth: authSliceReducer,
	benchmark: benchmarkSlice.reducer,
	framework: frameworkSlice.reducer,
	createClientReducers,
	createClientAdmin: createClientAdminSlice.reducer,
	createProjectReducers,
	createParticipantReducers,
	createManager: createManagerSlice.reducer,
	billingReport: billingReportSlice.reducer,
	importParticipants: importParticipantsSlice.reducer,
	suspense: suspenseSlice.reducer,
	radarGraph: radarGraphSlice.reducer,
	chartSettings: chartSettingsSlice.reducer,
	pieChart: pieChartSlice.reducer,
	quadrantGraph: quadrantSlice.reducer,
	heatmapGraph: heatmapGraphSlice.reducer,
	accuracyChart: accuracyChartSlice.reducer,
	dispersionChart: dispersionChartSlice.reducer,
	tensionGraphConfig: tensionGraphConfigurationSlice.reducer,
	behaviourFocus: behaviourFocusSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(rootApi.middleware),
});

export const resetStore = () => {
	const { dispatch } = store;
	dispatch(authSlice.actions.reset());
	dispatch(rootApi.util.resetApiState());
	dispatch(tensionGraphConfigurationSlice.actions.reset());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
