import { faUsersGear, faBuildingUser, faFileExport, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavbarItem } from 'features/Navigation/components';
import { NavbarList } from '../styled';

export const ManagerNavbar = () => {
	return (
		<NavbarList>
			<NavbarItem
				pageName="Client Configuration"
				path="/tenants"
				icon={<FontAwesomeIcon icon={faUsersGear} />}
			/>
			<NavbarItem
				pageName="Client Administrators"
				path="/tadmins"
				icon={<FontAwesomeIcon icon={faBuildingUser} />}
			/>
			<NavbarItem
				pageName="Billing Report"
				path="/billingReport"
				icon={<FontAwesomeIcon icon={faCreditCard} />}
			/>
			<NavbarItem
				pageName="Database Export"
				path="/databaseExport"
				icon={<FontAwesomeIcon icon={faFileExport} />}
			/>
		</NavbarList>
	);
};
