import { CreateTenantBody } from 'store/api';
import { RegexPatterns } from 'common/constants';

export const useClientFormValidation = () => {
	const validateForm = async (data: CreateTenantBody) => {
		return [
			required(data.name),
			required(data.shortId),
			shouldBeProperTelephone(data.businessTel),
			shouldBeProperEmailIfPresent(data.emailSenderAddress),
			required(data.country),
			required(data.address.cityOrState),
			required(data.address.zipCode),
			required(data.shortId),
			required(data.businessContactName),
			required(data.billingOrg),
			required(data.billingCountry),
			shouldBeProperEmail(data.billingInvoiceEmail),
			required(data.billingAddress.cityOrState),
			required(data.billingAddress.zipCode),
			required(data.billingContactName),
		].every(requirementIsMet);
	};

	return { validateForm };

	function requirementIsMet(shouldBeTrue: boolean) {
		return shouldBeTrue;
	}

	function shouldBeProperTelephone(data: string) {
		return required(data) && !!data.match(RegexPatterns.InternationalTelephoneFormat);
	}

	function shouldBeProperEmail(data: string) {
		return required(data) && shouldBeProperEmailIfPresent(data);
	}

	function shouldBeProperEmailIfPresent(data: string) {
		const emailIsEmpty = !data.length;
		if (emailIsEmpty) {
			return true;
		}
		return !!data.match(RegexPatterns.Email);
	}

	function required(data: string) {
		return data != null && !!data.length;
	}
};
