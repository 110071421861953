import { ApiManager, Manager } from 'common/types';
import { useGetAllManagersQuery, getAllManagersEmptyResponse } from 'store/api';
import { mapGetAllManagersResponse } from './mappers';

export const useManagers = () => {
	const allManagers = useGetAllManagersQuery();
	const emptyResponse = getAllManagersEmptyResponse();
	const managers = getManagers();

	function getManagers() {
		if (!allManagers.isSuccess) return emptyResponse.apdmanagers;

		return allManagers.data.apdmanagers;
	}

	function transform(apiManagers: ApiManager[]): Manager[] {
		return mapGetAllManagersResponse(apiManagers);
	}

	return { managers: transform(managers), areManagersFetched: allManagers.isSuccess };
};
