import { useDeactivateParticipantMutation } from 'store/api';
import { ConfirmationDialog, NotificationTypes, useNotifications } from 'common/components';
import { DeactivateParticipantConfirmationDialogProps } from './DeactivateParticipantConfirmationDialog.types';

export const DeactivateParticipantConfirmationDialog = ({
	isOpen,
	participantId,
	participantName,
	onClose,
	...additionalModalProps
}: DeactivateParticipantConfirmationDialogProps) => {
	const [deactivateParticipant] = useDeactivateParticipantMutation();
	const { pushNotification } = useNotifications();

	const onDeactivateParticipantAction = async () => {
		if (!participantId) return;

		try {
			await deactivateParticipant({ participantId: participantId }).unwrap();
			pushNotification({
				message: 'Participant successfully deactivated',
				type: NotificationTypes.Success,
			});
		} catch {}
	};

	return (
		<ConfirmationDialog
			id="dectivate-participant-modal"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={onDeactivateParticipantAction}
			title={`Are you sure that you want to deactivate ${participantName}?`}
			{...additionalModalProps}
		/>
	);
};
