import { ColumnDef } from '@tanstack/react-table';
import { QuestionnaireTypeFilter } from 'common/components';
import { AssignmentStatus } from 'common/types';
import { DateFilter } from 'common/components';
import { Criterion } from 'common/types';
import { useGetLoggedInUserQuery } from 'store/api';
import React, { useState, useEffect } from 'react';
import { AssignmentStatusDataColumnIds } from './assignmentStatusData';
import { getDateFromUnixUTC } from 'common/utils';
import { format } from 'date-fns';
import { AssessmentTypes, DateFormatPatterns } from 'common/constants';

export const useAssignmentStatusData = () => {
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<AssignmentStatus[]>([]);

	const loggedUser = useGetLoggedInUserQuery();
	const [criteriaColumns, setCriteriaColumns] = useState<ColumnDef<AssignmentStatus>[] | undefined>(undefined);
	const assignmentStatuColumns: ColumnDef<AssignmentStatus>[] = [
		{
			id: AssignmentStatusDataColumnIds.email,
			header: 'Email',
			accessorFn: (row) => row.email,
		},
		{
			id: AssignmentStatusDataColumnIds.firstName,
			header: 'First Name',
			accessorFn: (row) => row.first,
		},
		{
			id: AssignmentStatusDataColumnIds.lastName,
			header: 'Last Name',
			accessorFn: (row) => row.last,
		},
		{
			id: AssignmentStatusDataColumnIds.type,
			header: 'Type',
			accessorFn: (row) => row.type,
			meta: {
				customFilterComponent: (props) => QuestionnaireTypeFilter(props, [AssessmentTypes.OPDNA]),
			},
		},
		{
			id: AssignmentStatusDataColumnIds.questionnaireDoneDate,
			header: 'Quest. Done',
			accessorFn: (row) => {
				return (
					row.questionnaireDoneDate &&
					format(getDateFromUnixUTC(parseFloat(row.questionnaireDoneDate)), DateFormatPatterns.Default)
				);
			},
			meta: {
				customFilterComponent: DateFilter,
			},
		},
		{
			id: AssignmentStatusDataColumnIds.assesseeDeletionDate,
			header: 'Deletion',
			accessorFn: (row) => {
				return (
					row.deletionTimestamp &&
					format(getDateFromUnixUTC(row.deletionTimestamp), DateFormatPatterns.Default)
				);
			},

			meta: {
				customFilterComponent: DateFilter,
			},
		},
	];

	useEffect(() => {
		async function fetchCriteriaColumns() {
			const columns = createCriteriaColumns();
			setCriteriaColumns(columns);
		}

		fetchCriteriaColumns();
	}, [loggedUser]);

	const columns = React.useMemo<ColumnDef<AssignmentStatus>[]>(
		() => (criteriaColumns ? assignmentStatuColumns.concat(criteriaColumns) : assignmentStatuColumns),
		[criteriaColumns],
	);

	const visibleColumns = columns.filter((col) =>
		[
			AssignmentStatusDataColumnIds.email,
			AssignmentStatusDataColumnIds.firstName,
			AssignmentStatusDataColumnIds.lastName,
			AssignmentStatusDataColumnIds.type,
			AssignmentStatusDataColumnIds.questionnaireDoneDate,
			AssignmentStatusDataColumnIds.assesseeDeletionDate,
		].includes(col.id as string),
	);

	return {
		columns,
		visibleColumns,
		selectedParticipantIds,
		setSelectedParticipantIds,
	};

	function createCriteriaColumns(): ColumnDef<AssignmentStatus>[] {
		const criteria = loggedUser.data?.configuration.criteriaConfiguration ?? [];

		const createColumn = (criterion: Criterion): ColumnDef<AssignmentStatus> => ({
			id: `c${criterion.id}`,
			header: criterion.name,
			accessorFn: (assignmentStatus: AssignmentStatus) => assignmentStatus.criteria.findById(criterion.id).name,
		});

		return criteria.map(createColumn);
	}
};
