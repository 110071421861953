import styled, { css } from 'styled-components';
import { ContainerProps } from './ChartSettingsOptionItem.types';

const disabledStyle = css`
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
`;

export const Container = styled.div<ContainerProps>`
	display: flex;
	cursor: pointer;
	margin: 0.25rem 0;

	${({ $disabled }) => $disabled && disabledStyle};
`;

export const Title = styled.p`
	margin: 0 0 0 1rem;
`;
