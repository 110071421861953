import { useSelector } from 'react-redux';
import { selectRadarCombinedODNA, selectRadarConfig, selectRadarODNA } from 'store/slices/graphSlices';
import { OdnaRadarGraphProps } from './OdnaRadarGraph.types';
import { RadarGraph, useOdnaRadarGraphData } from 'features/Graphs';

export const OdnaRadarGraph = ({
	containerId,
	clusterIndex,
	benchmarkIndexes,
	pnoBenchmarkIndexes,
}: OdnaRadarGraphProps) => {
	const config = useSelector(selectRadarConfig);
	const combinedMode = pnoBenchmarkIndexes.includes(true);
	const odna = combinedMode ? useSelector(selectRadarCombinedODNA) : useSelector(selectRadarODNA);
	const radarData = useOdnaRadarGraphData(odna, clusterIndex, benchmarkIndexes, pnoBenchmarkIndexes);
	return (
		<RadarGraph
			config={config}
			containerId={containerId}
			data={radarData}
		/>
	);
};
