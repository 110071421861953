import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SuspenseState } from '.';
import { RootState } from 'store/store';

const initialState: SuspenseState = {
	isSuspenseManualTriggerActive: false,
};

export const suspenseSlice = createSlice({
	name: 'suspense',
	initialState,
	reducers: {
		setSuspenseManualTriggerVisibility: (state, { payload }: PayloadAction<boolean>) => {
			state.isSuspenseManualTriggerActive = payload;
		},
		resetSuspenseSlice: () => initialState,
	},
});

export const { setSuspenseManualTriggerVisibility, resetSuspenseSlice } = suspenseSlice.actions;
export const suspenseSliceReducer = suspenseSlice.reducer;
export const selectIsSuspenseManualTriggerActive = (state: RootState) => state.suspense.isSuspenseManualTriggerActive;
