import { BenchMarksPanelProps } from './BenchmarksPanel.types';
import { BenchmarksContainer, UnassignButton } from './styled';
import { DefaultBenchmarkInputs } from './BenchmarkInputSelector';

export const BenchmarksPanel = ({ onUnassignButtonClick }: BenchMarksPanelProps) => {
	return (
		<BenchmarksContainer>
			<DefaultBenchmarkInputs />
			<UnassignButton onClick={onUnassignButtonClick}>Unassign all participants from benchmarks</UnassignButton>
		</BenchmarksContainer>
	);
};
