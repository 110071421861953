import { ContentWrapperProps } from './ContentWrapper.types';
import { Container, HeaderText } from './styled';

export const ContentWrapper = ({ children, title, id }: ContentWrapperProps) => {
	return (
		<Container id={id}>
			<HeaderText>{title}</HeaderText>
			{children}
		</Container>
	);
};
