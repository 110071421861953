import { useSelector } from 'react-redux';
import {
	selectAccuracyChartCombinedODNA,
	selectAccuracyChartCombinedPDNA,
	selectAccuracyChartODNA,
	selectAccuracyChartPDNA,
	selectDispersionChartCombinedODNA,
	selectDispersionChartCombinedPDNA,
	selectDispersionChartODNA,
	selectDispersionChartPDNA,
	selectHeatmapGraphCombinedODNA,
	selectHeatmapGraphCombinedPDNA,
	selectHeatmapGraphODNA,
	selectHeatmapGraphPDNA,
	selectPieChartCombinedODNA,
	selectPieChartCombinedPDNA,
	selectPieChartODNA,
	selectPieChartPDNA,
	selectQuadrantState,
	selectRadarCombinedODNA,
	selectRadarCombinedPDNA,
	selectRadarODNA,
	selectRadarOPDNA,
	selectRadarPDNA,
} from 'store/slices';
import { GraphBase } from 'features/Graphs/PptxExportButton/';

export const useGraphBase = (combinedMode: boolean): GraphBase => {
	const quadrantBase = useSelector(selectQuadrantState);
	return {
		pieChartBase: pieChartBase(),
		radarGraphBase: radarGraphBase(),
		accuracyGraphBase: accuracyGraphBase(),
		dispersionChartBase: dispersionChartBase(),
		heatmapChartBase: heatmapChartBase(),
		quadrantBase,
	};

	function pieChartBase() {
		const pdnaPieChartData = combinedMode
			? useSelector(selectPieChartCombinedPDNA)
			: useSelector(selectPieChartPDNA);
		const odnaPieChartData = combinedMode
			? useSelector(selectPieChartCombinedODNA)
			: useSelector(selectPieChartODNA);
		return {
			pdna: pdnaPieChartData,
			odna: odnaPieChartData,
		};
	}

	function radarGraphBase() {
		const odnaRadarGraphData = combinedMode ? useSelector(selectRadarCombinedODNA) : useSelector(selectRadarODNA);
		const pdnaRadarGraphData = combinedMode ? useSelector(selectRadarCombinedPDNA) : useSelector(selectRadarPDNA);
		const title = useSelector(selectRadarOPDNA);
		return {
			odna: odnaRadarGraphData,
			pdna: pdnaRadarGraphData,
			title: title,
		};
	}

	function accuracyGraphBase() {
		const pdnaAccuracyGraphData = combinedMode
			? useSelector(selectAccuracyChartPDNA)
			: useSelector(selectAccuracyChartCombinedPDNA);
		const odnaAccuracyGraphData = combinedMode
			? useSelector(selectAccuracyChartODNA)
			: useSelector(selectAccuracyChartCombinedODNA);
		return {
			pdna: pdnaAccuracyGraphData,
			odna: odnaAccuracyGraphData,
		};
	}

	function dispersionChartBase() {
		const pdnaDispersionChartData = combinedMode
			? useSelector(selectDispersionChartCombinedPDNA)
			: useSelector(selectDispersionChartPDNA);
		const odnaDispersionChartData = combinedMode
			? useSelector(selectDispersionChartCombinedODNA)
			: useSelector(selectDispersionChartODNA);
		return {
			pdna: pdnaDispersionChartData,
			odna: odnaDispersionChartData,
		};
	}

	function heatmapChartBase() {
		const pdnaHeatmapChartData = combinedMode
			? useSelector(selectHeatmapGraphCombinedPDNA)
			: useSelector(selectHeatmapGraphPDNA);
		const odnaHeatmapChartData = combinedMode
			? useSelector(selectHeatmapGraphCombinedODNA)
			: useSelector(selectHeatmapGraphODNA);

		return {
			pdna: pdnaHeatmapChartData,
			odna: odnaHeatmapChartData,
		};
	}
};
