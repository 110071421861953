import React from 'react';
import { Outlet } from 'react-router-dom';

import { Sidebar } from 'features/Sidebar/views/Sidebar';

import { LayoutWrapper, Main } from './styled';

export const PageContext = React.createContext({});

export const Root = () => {
	return (
		<PageContext.Provider value="">
			<LayoutWrapper>
				<Sidebar />
				<Main>
					<Outlet />
				</Main>
			</LayoutWrapper>
		</PageContext.Provider>
	);
};
