import { useState } from 'react';
import { StyledInput } from './styled';

export const ResultThreshold = ({ value, onChange }: { value: number; onChange: (totalThreshold: number) => void }) => {
	const zero = 0;
	const minThreshold = 1;
	const maxThreshold = 100;
	const maxThreeDigitValue = 999;

	const [tempTotalThreshold, setTempTotalThreshold] = useState(value);
	const [totalThresholdFocused, setTotalThresholdFocused] = useState(false);

	function onThresholdKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		const disallowedKeys = ['+', '-', 'e', 'E', ',', '.'];
		if (disallowedKeys.includes(event.key)) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function onThresholdChange(event: React.ChangeEvent<HTMLInputElement>) {
		const eventValue = parseInt(event.target.value);
		if ((eventValue <= maxThreeDigitValue && eventValue > zero) || isNaN(eventValue)) {
			setTempTotalThreshold(eventValue);
		}
	}

	function onThresholdBlur(event: React.ChangeEvent<HTMLInputElement>) {
		const eventValue = parseInt(event.target.value);
		setTotalThresholdFocused(false);

		if (eventValue <= maxThreshold && eventValue >= minThreshold) {
			onChange(eventValue);
		} else {
			setTempTotalThreshold(value);
		}
	}

	return (
		<StyledInput
			type="number"
			min="1"
			max="100"
			name="Minimum N to be included in results:"
			errorPlacement="right"
			errorText="Enter a value from 1-100"
			errorTextAlwaysVisible={totalThresholdFocused}
			error={tempTotalThreshold < minThreshold || tempTotalThreshold > maxThreshold}
			value={tempTotalThreshold}
			onKeyDown={(event) => onThresholdKeyDown(event)}
			onFocus={() => setTotalThresholdFocused(true)}
			onChange={(event) => onThresholdChange(event)}
			onBlur={(event) => onThresholdBlur(event)}
		/>
	);
};
