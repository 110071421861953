import { CreateTenantBody, useCheckTenantIdAvailabilityMutation } from 'store/api';
import { useClientFormValidation } from 'features/ClientConfiguration/components/CreateClient/hooks';

export type ClientValidationProperties = { shouldCheckForShortIdUniqueness: boolean };

export const useClientFormWithUniquenessValidation = () => {
	const [validateAvailability] = useCheckTenantIdAvailabilityMutation();
	const { validateForm: validate } = useClientFormValidation();
	const validateForm = async (data: CreateTenantBody, props?: ClientValidationProperties) => {
		const shortId = await validateAvailability({ tenantShortId: data.shortId }).unwrap();
		if (props?.shouldCheckForShortIdUniqueness && !shortId.available) {
			return false;
		}
		return validate(data);
	};

	return { validateForm };
};
