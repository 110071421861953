import { useGetTenantQuery } from 'store/api';
import { ApiTenant, Tenant, TenantId } from 'common/types';
import { mapApiTenant } from 'common/hooks/tenants/mappers';

export const useTenant = (tenantId: TenantId) => {
	const tenant = useGetTenantQuery(tenantId);
	const tenantData = getTenantsData();

	function getTenantsData(): ApiTenant | null {
		if (!tenant.isSuccess) return null;

		return tenant.data;
	}

	function transform(tenant: ApiTenant): Tenant {
		return mapApiTenant(tenant);
	}

	if (tenantData == null) {
		return { tenant: null, areTenantsFetched: false };
	}

	return { tenant: transform(tenantData), areTenantsFetched: tenant.isSuccess };
};
