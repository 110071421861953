import styled from 'styled-components';
import { Checkbox } from 'common/components';

export const StyledCheckbox = styled(Checkbox)`
	padding: 0.5rem 0.5rem 2.5rem 0.5rem;
`;

export const StyledQuartileInfo = styled.div`
	padding-bottom: 1rem;
	padding-top: 1rem;
`;
