import { QuadrantLegendSortingType, QuadrantLegendSortingTypes } from 'features/DiscoveryInsights/components';
import { CategoryDistribution } from 'store/api/discoveryInsightApi';

const negativeModifier = -1;
const positiveModifier = 1;
const zeroModifier = 0;
export const useComparatorFactory = () => {
	return {
		createComparator,
	};

	function compareCategoryDistributions(
		valueExtractor: (obj: CategoryDistribution) => number,
		a: CategoryDistribution,
		b: CategoryDistribution,
		ascending: boolean,
	) {
		const ascendingOrder = ascending ? negativeModifier : positiveModifier;

		const valueA = valueExtractor(a);
		const valueB = valueExtractor(b);

		if (valueA < valueB) {
			return positiveModifier * ascendingOrder;
		}
		if (valueA > valueB) {
			return negativeModifier * ascendingOrder;
		}

		const bothValuesAreEqual = a.percentage === b.percentage;
		if (bothValuesAreEqual) {
			return compareTotalAmount(a, b);
		}

		return zeroModifier;
	}

	function compareTotalAmount(a: CategoryDistribution, b: CategoryDistribution) {
		const bothTotalAmountAreEqual = a.totalAmount === b.totalAmount;
		if (bothTotalAmountAreEqual) {
			return compareAlphabetically(a, b);
		}

		return a.totalAmount < b.totalAmount ? positiveModifier : negativeModifier;
	}

	function compareAlphabetically(a: CategoryDistribution, b: CategoryDistribution) {
		return a.category.localeCompare(b.category);
	}

	function createComparator(sortingMode: QuadrantLegendSortingType) {
		const getAmount = (category: CategoryDistribution) => category.membersAmount;
		const getPercentage = (category: CategoryDistribution) => category.percentage;
		const ascending = true;
		const descending = false;

		switch (sortingMode) {
			case QuadrantLegendSortingTypes.PERCENTAGE_ASCENDING:
				return (a: CategoryDistribution, b: CategoryDistribution) =>
					compareCategoryDistributions(getPercentage, a, b, ascending);
			case QuadrantLegendSortingTypes.PERCENTAGE_DESCENDING:
				return (a: CategoryDistribution, b: CategoryDistribution) =>
					compareCategoryDistributions(getPercentage, a, b, descending);
			case QuadrantLegendSortingTypes.NUMBER_ASCENDING:
				return (a: CategoryDistribution, b: CategoryDistribution) =>
					compareCategoryDistributions(getAmount, a, b, ascending);
			case QuadrantLegendSortingTypes.NUMBER_DESCENDING:
				return (a: CategoryDistribution, b: CategoryDistribution) =>
					compareCategoryDistributions(getAmount, a, b, descending);
			default:
				return (a: CategoryDistribution, b: CategoryDistribution) =>
					compareCategoryDistributions(getPercentage, a, b, descending);
		}
	}
};
