import { Input } from 'common/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BillingInformationState, setBillingInformationState } from 'store/slices';
import { handleRequiredFormMailBlur, handleRequiredFormValue, createFormState } from 'common/utils';
import { BillingInformationProps } from './BillingInformation.types';
import { emptyIfNullOrUndefined } from 'common/utils';
import { RequiredFormInputState } from 'common/types';

export const BillingInformation = ({
	saveTrigger,
	disableVatNumber,
	disableBillingCountry,
	disableInvoiceEmail,
	disableBillingEntity,
	vatNumber: initialVatNumber,
	invoiceEmail: initialInvoiceEmail,
	billingCountry: initialBillingCountry,
	billingEntity: initialBillingEntity,
}: BillingInformationProps) => {
	const [vatNumber, setVatNumber] = useState(emptyIfNullOrUndefined(initialVatNumber));

	const [billingEntity, setBillingEntity] = useState<RequiredFormInputState>(createFormState(initialBillingEntity));

	const [billingCountry, setBillingCountry] = useState<RequiredFormInputState>(
		createFormState(initialBillingCountry),
	);

	const [billingMail, setBillingMail] = useState<RequiredFormInputState>(createFormState(initialInvoiceEmail));

	const dispatch = useDispatch();

	useEffect(() => {
		if (saveTrigger) {
			validateFormSlice();
		}
		setSliceState();
	}, [saveTrigger]);

	const validateFormSlice = () => {
		handleRequiredFormValue(billingEntity.value, 'Billing entity is required!', setBillingEntity);
		handleRequiredFormValue(billingCountry.value, 'Billing country is required!', setBillingCountry);
		handleRequiredFormMailBlur(
			billingMail.value,
			'Invoice email is required!',
			'Invoice email does not have correct format!',
			setBillingMail,
		);
	};

	const setSliceState = () => {
		const sliceState: BillingInformationState = {
			billingCountry: billingCountry.value,
			billingEntity: billingEntity.value,
			billingMail: billingMail.value,
			vatNumber: vatNumber,
		};

		dispatch(setBillingInformationState(sliceState));
	};

	return (
		<>
			<Input
				{...billingEntity}
				name="Billing entity"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingEntity}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing entity is required!', setBillingEntity);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing entity is required!', setBillingEntity);
					setSliceState();
				}}
			/>
			<Input
				{...billingCountry}
				name="Billing Country"
				maxLength={50}
				errorPlacement="right"
				disabled={disableBillingCountry}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing country is required!', setBillingCountry);
				}}
				onBlur={(event) => {
					handleRequiredFormValue(event.target.value, 'Billing country is required!', setBillingCountry);
					setSliceState();
				}}
			/>
			<Input
				{...billingMail}
				name="Email to send invoices to"
				maxLength={50}
				errorPlacement="right"
				disabled={disableInvoiceEmail}
				formInput
				required
				onChange={(event) => {
					handleRequiredFormValue(event.target.value, 'Invoice email is required!', setBillingMail);
				}}
				onBlur={(event) => {
					handleRequiredFormMailBlur(
						event.target.value,
						'Invoice email is required!',
						'Invoice email does not have correct format!',
						setBillingMail,
					);
					setSliceState();
				}}
			/>
			<Input
				name="VAT number"
				placeholder="Please use international format"
				maxLength={30}
				value={vatNumber}
				disabled={disableVatNumber}
				formInput
				onChange={(event) => {
					setVatNumber(event.target.value);
				}}
				onBlur={(event) => {
					setVatNumber(event.target.value);
					setSliceState();
				}}
			/>
			<hr />
		</>
	);
};
