import { TabsHeaderList } from './styled';
import { TabLabel } from './TabLabel';
import { TabsProps } from './Tabs.types';

export const Tabs = ({ children, onChange, value, className }: TabsProps) => {
	return (
		<TabsHeaderList className={className}>
			{/*The index as a key here is used only because the children tab list will always be static */}
			{children.map((tab, index) => (
				<TabLabel
					key={index}
					tabIndex={index}
					faded={tab.props.faded}
					isActive={value === index}
					label={tab.props.label}
					onTabClick={onChange}
				/>
			))}
		</TabsHeaderList>
	);
};
