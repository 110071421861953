import { IconButton } from 'common/components/IconButton';
import styled from 'styled-components';

export const GridListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 1rem;
	color: ${({ theme }) => theme.colors.text.secondary};
`;

export const BehaviourNameText = styled.p`
	margin: 0;
	font-size: 1rem;
`;

export const StyledIconButton = styled(IconButton)`
	font-size: 1rem;
	padding: 0;
	color: ${({ theme }) => theme.colors.text.secondary};
`;
