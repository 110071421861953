import { heatmapGraphConfig } from 'features/Graphs/HeatmapGraph/HeatmapGraphConfig';
import { Color, HeatmapTableStyle } from 'features/Graphs/TensionGraph';

const cellStyle = (textColor: Color, backgroundColor: Color) => {
	return { textColor, backgroundColor };
};

export function useHeatmapChartStyleFactory() {
	const createDefaultHeatMapConfig = () => {
		const heatMapConfig = {
			...heatmapGraphConfig,
			mainTableScale: {
				...heatmapGraphConfig.mainTableScale,
				offsetX: -200,
			},
		};
		return heatMapConfig;
	};

	const createCategoryCellStyle = () => {
		return cellStyle('black', createDefaultHeatMapConfig().categoryLabels.backgroundColor);
	};

	const create = (heatmapStyle: HeatmapTableStyle) => {
		const scaleColor = heatmapStyle.scaleColor;

		const createOnHoverStyle = () => {
			return {
				categoryCellStyle: createCategoryCellStyle(),
				scaleColor: scaleColor,
			};
		};

		return {
			defaultConfig: createDefaultHeatMapConfig(),
			scaleColor: scaleColor,
			onHover: createOnHoverStyle(),
		};
	};

	return { create };
}
