import { ConfirmableNameInput } from 'features/ClusterConfiguration/components';
import { StyledInput } from 'features/ClusterConfiguration/components/ConfirmableNameInput/styled';
import { StyledInput as InnerStyledInput } from 'common/components/Input/styled';
import styled from 'styled-components';
import { Button } from 'common/components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
`;

export const Hr = styled.hr`
	margin: 2rem 0 1rem 0;
`;

export const StyledConfirmableNameInput = styled(ConfirmableNameInput)`
	${StyledInput} {
		border: 2px solid ${({ theme }) => theme.colors.common.greyX11};
		border-radius: 7px;
		width: 75%;
		${InnerStyledInput} {
			color: ${({ theme }) => theme.colors.common.black};
			background-color: ${({ theme }) => theme.colors.common.white};
		}
	}
`;

export const FirstContainer = styled.div`
	display: flex;
`;

export const SecondContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	margin-top: 10%;
	flex-direction: column;
	align-items: flex-end;
`;

export const ButtonWithMargin = styled(Button)`
	margin-bottom: 2%;
`;

export const ExcludedCriteriaContainer = styled.div`
	margin-top: 1rem;
	height: 3.5rem;
`;
