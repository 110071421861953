import {
	ApiAssignmentParticipants,
	Criterion,
	AssignmentParticipants,
	Pagination,
	CriterionId,
	CriteriaRepository,
} from 'common/types';
import { AssignmentParticipantsResponse } from 'store/api';

export const transformAssignParticipantsResponse = (
	response: AssignmentParticipantsResponse<ApiAssignmentParticipants>,
	criteriaConfiguration: Criterion[],
): AssignmentParticipantsResponse<AssignmentParticipants> => {
	const apiAssignmentStatuses = response.content;
	const pagination = createPagination(response);
	return {
		...pagination,
		totalAssigned: response.totalAssigned,
		content: apiAssignmentStatuses.map((content) => createAssignParticipants(criteriaConfiguration, content)),
	};
};

function createAssignParticipants(
	criteriaConfiguration: Criterion[],
	assignmentParticipants: ApiAssignmentParticipants,
): AssignmentParticipants {
	const { c: _c, ...participantCopy } = assignmentParticipants;
	return {
		...participantCopy,
		criteria: createCriteria(criteriaConfiguration, assignmentParticipants.c),
	};
}

function createCriteria(criteriaConfiguration: Criterion[], criteriaMap: Record<string, string>) {
	const criteria: Criterion[] = criteriaConfiguration.map((criterionConfig) => {
		const id: CriterionId = criterionConfig.id;
		const decreasedByOne = 1;
		const criteriaValue = criteriaMap[parseInt(id) - decreasedByOne];
		return { id, name: criteriaValue, enabled: criterionConfig.enabled };
	});

	return new CriteriaRepository(criteria);
}

function createPagination(response: AssignmentParticipantsResponse<ApiAssignmentParticipants>): Pagination {
	return {
		pageNumber: response.pageNumber,
		totalPages: response.totalPages,
	};
}
