import { DimensionCluster, Framework, FrameworkDetails } from 'common/types';
import { validationResult } from '.';

export const validateFrameworkName = (newName: string, alreadyExistingNames: string[]): validationResult => {
	if (!newName) {
		return {
			success: false,
			message: 'Framework name not provided.',
		};
	}

	const nameAlreadyExists = checkIfNameAlreadyExists(newName, alreadyExistingNames);
	if (nameAlreadyExists) {
		return {
			success: false,
			message: 'Framework name already taken, please create a unique name.',
		};
	}

	return {
		success: true,
		message: '',
	};
};

export const validateClusterName = (name: string, alreadyExistingNames: string[]): validationResult => {
	if (!name) {
		return {
			success: false,
			message: 'Cluster name not provided',
		};
	}

	const nameAlreadyExists = checkIfNameAlreadyExists(name, alreadyExistingNames);
	if (nameAlreadyExists) {
		return {
			success: false,
			message: 'Cluster name already taken, please create a unique name',
		};
	}

	return {
		success: true,
		message: '',
	};
};

const checkIfNameAlreadyExists = (name: string, names: string[]) => {
	names = names.map((name) => name.toLowerCase());

	return names.includes(name.toLowerCase());
};

export const formatNewName = (name: string) => {
	return name.trim();
};

export const getUnselectedFrameworksNames = (frameworks: Framework[], editedFramework: Framework) => {
	const frameworkNames: string[] = [];
	frameworks.map((framework) => {
		const currentFramework = editedFramework?.frameworkId === framework.frameworkId;
		if (!currentFramework) {
			frameworkNames.push(framework.name);
		}
	});

	return frameworkNames;
};

export const getUnselectedClustersNames = (frameworkDetails: FrameworkDetails, selectedCluster: DimensionCluster) => {
	const clusterNames: string[] = [];

	frameworkDetails.clusters.map((cluster) => {
		const currentCluster = cluster.cluster.clusterId === selectedCluster?.cluster.clusterId;
		if (!currentCluster) {
			clusterNames.push(cluster.cluster.name);
		}
	});

	return clusterNames;
};
