import { ApiParticipantDetails, ParticipantDetails } from 'common/types';
import { useGetParticipantDetailsQuery } from 'store/api';
import { mapGetParticipantDetailsResponse } from './mappers';

type UseParticipantDetailsProps = {
	participantId?: string;
};

export const useParticipantDetails = (option?: UseParticipantDetailsProps) => {
	const participantDetails = useGetParticipantDetailsQuery(
		{ participantId: option?.participantId as string },
		{ skip: option?.participantId == null },
	);

	const participant = getParticipantDetailsData();

	return {
		participantDetails: participant ? transform(participant) : undefined,
	};

	function getParticipantDetailsData(): ApiParticipantDetails | undefined {
		if (!participantDetails.isSuccess) return undefined;

		return participantDetails.data;
	}

	function transform(participant: ApiParticipantDetails): ParticipantDetails {
		return mapGetParticipantDetailsResponse(participant);
	}
};
