import { DateFormatPatterns, RegexPatterns } from 'common/constants';
import { getUTCDate, parseDateString } from 'common/utils';
import { format } from 'date-fns';
import { useState } from 'react';
import { CustomFormItemLayout } from '../CustomFormItemLayout';
import { DateInput } from '../DateInput';
import { isKeyDownEventType } from '../DateInput/utils';
import { FormDateInputProps } from './FormDateInput.types';

export const FormDateInput = ({
	title,
	error,
	errorText,
	required,
	dateInputProps,
	value,
	disabled,
	onChange,
}: FormDateInputProps) => {
	const { minDate, maxDate } = dateInputProps;
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	const onChangeRawDateString = (event: React.FocusEvent<HTMLInputElement, Element>) => {
		const dateString = event.currentTarget.value;
		onChange(dateString);
	};

	const validateDateString = () => {
		if (!value) return false;

		const normalizedDateString = value.replace(RegexPatterns.AllWhitespaceChars, '');

		const filterDate = parseDateString(normalizedDateString);
		if (!filterDate) return false;

		if (minDate && filterDate < minDate) return false;

		if (maxDate && filterDate > maxDate) return false;

		return true;
	};

	const onSelectDateValue = (date: Date | null, event: React.SyntheticEvent) => {
		// This is a hack for not selecting the default today's date as
		// filter when the user will submit the invalid date by clicking
		// 'Enter' key without changing current input value.
		if (isKeyDownEventType(event) && !validateDateString()) {
			setSelectedDate(null);
			return;
		}

		if (!date) {
			setSelectedDate(null);
			return;
		}

		const utcDate = getUTCDate(date);
		if (minDate && utcDate < minDate) {
			setSelectedDate(null);
			return;
		}

		if (maxDate && utcDate > maxDate) {
			setSelectedDate(null);
			return;
		}

		const formattedDateString = format(utcDate, DateFormatPatterns.Default);

		setSelectedDate(utcDate);
		onChange(formattedDateString);
	};

	const applyDateValueFromDateString = () => {
		if (!validateDateString()) {
			setSelectedDate(null);
			return;
		}
		const normalizedDateString = value.replace(RegexPatterns.AllWhitespaceChars, '');
		const filterDate = parseDateString(normalizedDateString);
		if (!filterDate) return;

		setSelectedDate(filterDate);
	};

	return (
		<CustomFormItemLayout
			title={title}
			error={error}
			errorText={errorText}
			required={required}
		>
			<DateInput
				inputValue={value}
				dateValue={selectedDate}
				minDate={minDate}
				maxDate={maxDate}
				disableErrorOnFocus
				inputProps={{ error: error }}
				onCalendarClose={dateInputProps?.onCalendarClose}
				reactDatePickerProps={dateInputProps?.reactDatePickerProps}
				disabled={disabled}
				onClickOutside={applyDateValueFromDateString}
				onSelectDate={onSelectDateValue}
				onEnterKeydown={applyDateValueFromDateString}
				onInputChange={onChangeRawDateString}
			/>
		</CustomFormItemLayout>
	);
};
