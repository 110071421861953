import styled from 'styled-components';

export const StyledContainer = styled('div')`
	width: 90vw;
	display: flex;
	justify-content: space-evenly;
	pointer-events: all;
`;

export const StyledFullChartContainer = styled('div')`
	margin-top: 5vh;
	pointer-events: all;
	width: 90vw;
`;
