import { ChartTypeMenuProps } from './ChartTypeMenu.types';
import { ChosenItemBox, MenuContainer, MenuContent, MenuHeader } from './styled';
import { ChartTypeMenuItemLabel, MENU_ITEM_ICON_SIZE_PX } from '../ChartTypeMenuItemLabel';
import { useState } from 'react';

export const ChartTypeMenu = <T,>({ children, selectedChartType, onSelect }: ChartTypeMenuProps<T>) => {
	const initialOffsetPx = 0;
	const [chosenItemBoxOffsetPx, setChosenItemBoxOffsetPx] = useState(initialOffsetPx);
	const MenuItems = children?.map(({ props }, index) => {
		const isSelected = selectedChartType === props.chartType;

		const onMenuItemClick = () => {
			if (onSelect) {
				onSelect(props.chartType);
			}

			setChosenItemBoxOffsetPx(index * MENU_ITEM_ICON_SIZE_PX);
		};

		return (
			<ChartTypeMenuItemLabel
				key={props.chartType + ''}
				iconSrc={props.iconSrc}
				isSelected={isSelected}
				onClick={onMenuItemClick}
			/>
		);
	});

	return (
		<MenuContainer>
			<MenuHeader>Chart Type</MenuHeader>
			<MenuContent>
				<ChosenItemBox offsetY={chosenItemBoxOffsetPx} />
				{MenuItems}
			</MenuContent>
		</MenuContainer>
	);
};
