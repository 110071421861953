import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { AutomaticClosureConditionsState } from './automaticClosureConditionsSlice.types';

const initialState: AutomaticClosureConditionsState = {
	closureDate: undefined,
	percentOfCompletedQuestionnaires: undefined,
};

export const automaticClosureConditionsSlice = createSlice({
	name: 'automaticClosureConditions',
	initialState,
	reducers: {
		setAutomaticClosureConditionsSlice: (state, { payload }: PayloadAction<AutomaticClosureConditionsState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setAutomaticClosureConditionsSlice } = automaticClosureConditionsSlice.actions;

export const automaticClosureConditionsReducer = automaticClosureConditionsSlice.reducer;

export const SelectAutomaticClosureConditionsState = (state: RootState) => {
	return state.createProjectReducers.automaticClosureConditions;
};
