import { BenchmarkConfigurationId } from 'store/api';
import { ProjectId } from 'common/types';

export type ApiPredictiveResult = {
	oddsRatios: OddsRatio[];
};

export type PredictiveResult = ApiPredictiveResult;

export type ApiPredictiveRequest = {
	projectId?: ProjectId;
	benchmarkConfigurationId: BenchmarkConfigurationId;
};

export class PredictiveViewValidationError extends Error {
	constructor(text: string) {
		super(text);
	}
}

export type OddsRatio = {
	firstQuartile: Quartile;
	secondQuartile: Quartile;
	thirdQuartile: Quartile;
	fourthQuartile: Quartile;
	behaviours: string;
	multipleR: number;
	participantsSelected: number;
};

export type Quartile = {
	occurrencesHigher: number;
	occurrencesLower: number;
	odds: number;
};
