import { AccuracyChartDna } from 'store/slices';
import { prepareSingleDnaGraphData } from 'features/Graphs/AccuracyChart/hoc/utils/prepareData';

export const usePdnaAccuracyChartData = (
	pdna: AccuracyChartDna,
	clusterIndex: number,
	benchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const checkedBenchmarks = combinedMode ? pnoBenchmarkIndexes : benchmarkIndexes;

	return prepareSingleDnaGraphData(pdna, checkedBenchmarks, clusterIndex);
};
