import { CriteriaDropdown, DistributionTableViewProps } from 'features/DiscoveryInsights/components';
import { DistributionPercentageDnaTypes } from 'features/DiscoveryInsights/views/DiscoveryInsightResults';
import { PercentageDistributionPerCluster } from 'store/api/discoveryInsightApi';
import { DNATypeToggle } from 'features/DiscoveryInsights/components';
import { DistributionTable } from 'features/DiscoveryInsights/components/DistributionTableView/components';
import { StyledCentered, StyledPanel } from 'features/DiscoveryInsights/components';

export const DistributionTableView = ({
	colorPresetId,
	selectedCriterion,
	clusterId,
	criteria,
	distributionDna,
	data,
	groupFilterThreshold,
	onDistributionCriteriaChange,
	onDnaTypeChange,
}: DistributionTableViewProps) => {
	const selectedData = getSelectedDataByToggle();
	return (
		<>
			<StyledPanel>
				<StyledCentered>
					<DNATypeToggle
						dnaType={distributionDna}
						setDnaType={onDnaTypeChange}
					/>
				</StyledCentered>
				<CriteriaDropdown
					criterion={selectedCriterion}
					criteria={criteria}
					setCriterion={onDistributionCriteriaChange}
				/>
			</StyledPanel>
			<div>
				<DistributionTable
					groupFilterThreshold={groupFilterThreshold}
					colorPresetId={colorPresetId}
					data={selectedData}
				/>
			</div>
		</>
	);

	function findBySelectedCluster(data: PercentageDistributionPerCluster[]) {
		const found = data.find((e) => e.ordinal === clusterId.replace('-', ''));
		if (!found) {
			throw Error();
		}
		return found.distributions;
	}

	function getSelectedDataByToggle() {
		const data = getDataBaseOnSelectedDNA();
		return findBySelectedCluster(data);
	}

	function getDataBaseOnSelectedDNA() {
		switch (distributionDna) {
			case DistributionPercentageDnaTypes.PDNA:
				return data.pdna;
			case DistributionPercentageDnaTypes.ODNA:
				return data.odna;
		}
		throw Error();
	}
};
