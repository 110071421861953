import {
	BenchmarkConfigurationId,
	CultureViewResults,
	useLazyGetCommonGraphDataQuery,
	useLazyGetGraphConfigurationQuery,
	useLazyGetQuadrantDataQuery,
} from 'store/api';
import { mapCultureViewResults } from './mappers';
import { vetoIfBadRequest } from '../predictiveView/mappers';

const first = 0;
const second = 1;
const third = 2;
export const useCultureViewResults = () => {
	const [fetchResult] = useLazyGetCommonGraphDataQuery();
	const [fetchQuadrantResult] = useLazyGetQuadrantDataQuery();
	const [fetchConfig] = useLazyGetGraphConfigurationQuery();

	return (benchmarkConfigurationId: BenchmarkConfigurationId): Promise<CultureViewResults> => {
		const request = { benchmarkConfigurationId };
		const commonRequest = fetchResult(request);
		const quadrantRequest = fetchQuadrantResult(request);
		const configRequest = fetchConfig();
		return Promise.all([commonRequest, quadrantRequest, configRequest]).then((result) => {
			const commonResults = result[first].data;
			const quadrantResults = result[second].data;
			const configResults = result[third].data;
			vetoIfBadRequest(commonResults);
			return mapCultureViewResults(commonResults, quadrantResults, configResults);
		});
	};
};
