import { IconButton } from 'common/components/IconButton';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;

	& > * {
		margin-right: 0.3rem;
	}
`;

export const Header = styled.p`
	display: flex;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
`;

export const StyledIconButton = styled(IconButton)`
	font-size: 1.5rem;
	padding: 0;
	margin: 0 0.2rem;
	height: fit-content;
	color: ${({ theme }) => theme.colors.common.white};
`;

export const IconsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	margin-top: 0.25rem;
`;
