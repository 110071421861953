import { RedirectingMasterLogo } from 'common/components/RedirectingMasterLogo';
import styled from 'styled-components';

export const StyledLogo = styled(RedirectingMasterLogo)`
	display: flex;
	width: 100%;
	object-fit: contain;
	padding: 1rem;
	margin-top: auto;
`;
