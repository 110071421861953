import { ProjectId } from 'common/types';
import { useState } from 'react';
import { BenchmarkConfigurationId } from 'store/api';
import { BenchmarkComparisonView } from 'common/components/BenchmarkComparisonView';
import { PredictiveViewResults } from 'features/Predictive/views';
import { usePredictiveViewResults } from 'common/hooks/predictiveView/usePredictiveViewResults';
import { usePredictiveViewResultsFromProject } from 'common/hooks/predictiveView/usePredictiveViewResultsFromProject';
import { PredictiveResult } from 'store/api/predictiveApi/predictiveApi.types';
import { NotificationTypes, useNotifications } from 'common/components';
import { CommonFiveBenchmarkTab } from 'common/components/BenchmarkComparisonView/components/CommonFiveBenchmarkTab';

export const PredictiveViewPage = () => {
	const [isPredictiveViewOpened, setIsPredictiveViewOpened] = useState(false);
	const fetchResults = usePredictiveViewResults();
	const fetchResultsFromProject = usePredictiveViewResultsFromProject();
	const { pushNotification } = useNotifications();
	const [data, setData] = useState<PredictiveResult | undefined>(undefined);

	const onViewButtonClick = async (benchmarkConfigurationId?: BenchmarkConfigurationId, projectId?: ProjectId) => {
		if (benchmarkConfigurationId == undefined) {
			throw Error();
		}

		fetchResultData(benchmarkConfigurationId, projectId)
			.then((result) => {
				if (!result) {
					return;
				}
				setData(result);
				setIsPredictiveViewOpened(true);
			})
			.catch((e) => {
				pushNotification({
					message: e.message,
					type: NotificationTypes.Error,
				});
			});
	};

	const closePredictiveView = () => {
		setIsPredictiveViewOpened(false);
	};

	function fetchResultData(benchmarkConfigurationId: BenchmarkConfigurationId, projectId?: ProjectId) {
		if (projectId) {
			return fetchResultsFromProject(benchmarkConfigurationId, projectId);
		}

		return fetchResults(benchmarkConfigurationId);
	}

	return (
		<>
			{data && (
				<PredictiveViewResults
					data={data}
					isOpened={isPredictiveViewOpened}
					onClose={closePredictiveView}
				/>
			)}
			<BenchmarkComparisonView
				id="predictive-view"
				pageName="Predictive View"
				onViewClick={onViewButtonClick}
				benchmarkTabPanel={(benchmarkConfigId) => (
					<CommonFiveBenchmarkTab
						id={'predictive-view'}
						benchmarkConfigurationId={benchmarkConfigId}
					/>
				)}
			/>
		</>
	);
};
