import styled from 'styled-components';
import { StyledChosenItemBoxProps } from './ChartTypeMenu.types';
import { ZIndexes } from 'common/constants';

export const MenuContainer = styled.div`
	z-index: ${ZIndexes.simulationNavigation};
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 1rem;
	right: 1rem;
`;

export const MenuContent = styled.div`
	position: relative;
`;

export const MenuHeader = styled.p`
	margin: 0;
	text-transform: uppercase;
	font-weight: 200;
`;

export const ChosenItemBox = styled.div.attrs<StyledChosenItemBoxProps>(({ offsetY }) => ({
	style: {
		transform: `translate3d(0, ${offsetY}px, 0)`,
	},
}))<StyledChosenItemBoxProps>`
	border: 3px solid ${({ theme }) => theme.colors.primary.main};
	border-radius: 0;
	height: 81px;
	width: 81px;
	position: absolute;

	transition: transform 400ms ease-in-out;
`;
