import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
`;

export const ChartConfigGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1.5rem;
`;

export const Hr = styled.hr`
	margin: 2rem 0 1rem 0;
`;

export const ActionsMenuContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 18rem;
`;
