import { Button } from 'common/components/Button';
import styled from 'styled-components';

export const SaveButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	::before {
		content: '';
		flex: 1;
	}
`;

export const ButtonWrapper = styled.div`
	flex: 2;
	margin-left: 2rem;
`;

export const StyledButton = styled(Button)`
	margin-right: 1rem;
	margin-bottom: 1rem;
	width: 45%;
`;
