import { RoadMapSettingsOptions } from '../RoadMapSettingsPanel';

export const RoadMapGraphStylesheet = (style: RoadMapSettingsOptions, exportStarted: boolean) => [
	{
		selector: 'node',
		style: {
			'background-color': `#${style.nodeColor}`,
			label: exportStarted ? 'data(label)' : style.criteriaNumber ? 'data(index)' : '',
			'font-size': '9rem',
			textValign: exportStarted ? undefined : 'center',
			color: exportStarted ? 'black' : style.textColor,
		},
	},

	{
		selector: 'edge',
		style: {
			label: 'data(label)',
			width: 1,
			'line-color': style.blackArrows ? 'black' : `#${style.nodeColor}`,
			'target-arrow-color': style.blackArrows ? 'black' : `#${style.nodeColor}`,
			'target-arrow-shape': 'triangle',
			'curve-style': 'bezier',
			'font-size': '5rem',
			color: `black`,
			'text-margin-y': -10,
			css: {
				'text-valign': 'top',
			},
		},
	},
	{
		selector: 'edge[label]',
		style: {
			css: {
				label: 'data(label)',
				'text-rotation': 'autorotate',
				'text-margin-x': '0px',
				'text-margin-y': '0px',
				'font-size': '1px',
			},
		},
	},
];
