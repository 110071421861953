import styled from 'styled-components';
import { Button } from 'common/components';
import { StyledIcon } from 'common/components/Button/styled';

export const StyledButton = styled(Button)`
	margin-top: -1.5rem;
	margin-bottom: -1.5rem;
	width: 15rem;
	border-color: ${({ theme }) => theme.colors.common.mediumSilver};
	color: ${({ theme }) => theme.colors.common.darkBlue};
	font-weight: bolder;

	${StyledIcon} {
		font-size: 2rem;
	}
`;
