import {
	ApiDimensionScores,
	ApiEnabledDimensions,
	ApiGraphResponseBenchmark,
	ApiGraphResponseBenchmarks,
	ApiGraphResponseCluster,
	ApiGraphResponseResults,
} from 'store/api';

export const toBenchmarkMap = (data: ApiGraphResponseBenchmarks) => {
	return toMap<ApiGraphResponseBenchmark>(data);
};

export const toClusterMap = (data: ApiGraphResponseResults) => {
	return toMap<ApiGraphResponseCluster>(data);
};

export const toEnabledDimensionMap = (data: ApiEnabledDimensions) => {
	return toMap<boolean>(data);
};

export const toDimensionScoreMap = (data: ApiDimensionScores) => {
	return toMap<number>(data);
};

type ObjectToMap<T> = { [s: string]: T };
type ParameterMap<T> = Map<string, T>;

const toMap = <T>(data: ObjectToMap<T>): ParameterMap<T> => {
	const map = new Map<string, T>();
	Object.entries<T>(data).forEach((entry) => {
		const parameterIndex = 0;
		const valueIndex = 1;
		const key = entry[parameterIndex];
		const value = entry[valueIndex];
		map.set(key, value);
	});
	return map;
};
