import { ApiTenantAdmin, TenantAdmin } from 'common/types';
import { AllTenantsAdminsResponse, getAllTenantsAdminsEmptyResponse, useGetAllTenantsAdminsQuery } from 'store/api';
import { mapGetAllTenantsAdminsResponse } from './mappers';

export const useTenantsAdmins = () => {
	const allTenantsAdmins = useGetAllTenantsAdminsQuery();
	const emptyResponse = getAllTenantsAdminsEmptyResponse();
	const tenantsAdmins = getTenantsAdminsData();

	function getTenantsAdminsData(): AllTenantsAdminsResponse<ApiTenantAdmin> {
		if (!allTenantsAdmins.isSuccess) return emptyResponse;

		return allTenantsAdmins.data;
	}

	function transform(tenants: AllTenantsAdminsResponse<ApiTenantAdmin>): AllTenantsAdminsResponse<TenantAdmin> {
		return mapGetAllTenantsAdminsResponse(tenants);
	}

	return { tenantsAdmins: transform(tenantsAdmins).tadmins, areTenantsAdminsFetched: allTenantsAdmins.isSuccess };
};
