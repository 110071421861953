import { ParticipantDetails, ParticipantDetailsCriteriaKey } from 'common/types';
import { useSelector } from 'react-redux';
import { selectCriteriaInformationState, selectParticipantInformationState } from 'store/slices';

export const useParticipantFormData = () => {
	const participantInformation = useSelector(selectParticipantInformationState);
	const criteriaInformation = useSelector(selectCriteriaInformationState);

	const filteredParticipantsCriteria: Record<ParticipantDetailsCriteriaKey, string> = Object.fromEntries(
		Object.entries(criteriaInformation.criteria).filter(([_, criterion]) => criterion !== ''),
	);
	const data: Omit<ParticipantDetails, 'participantId'> = {
		...filteredParticipantsCriteria,
		firstName: participantInformation.firstName,
		lastName: participantInformation.lastName,
		emailAddress: participantInformation.emailAddress,
		middleName: participantInformation.middleName,
		salutation: participantInformation.salutation,
		title: participantInformation.title,
	};

	return { participantFormData: data };
};
