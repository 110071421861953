import { PieChartDna } from 'store/slices';
import { prepareSingleDnaGraphData } from 'features/Graphs/PieChart/hoc/utils';

export const usePdnaPieChartData = (
	pdna: PieChartDna,
	clusterIndex: number,
	benchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
) => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const checkedBenchmarks = combinedMode ? pnoBenchmarkIndexes : benchmarkIndexes;

	return prepareSingleDnaGraphData(pdna, checkedBenchmarks, clusterIndex);
};
