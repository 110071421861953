import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ParticipantInformationState } from './participantInformationSlice.types';

const initialState: ParticipantInformationState = {
	firstName: '',
	lastName: '',
	emailAddress: '',
	middleName: undefined,
	salutation: undefined,
	title: undefined,
};

export const participantInformationSlice = createSlice({
	name: 'participantInformation',
	initialState,
	reducers: {
		setParticipantInformationState: (state, { payload }: PayloadAction<ParticipantInformationState>) => {
			state = payload;
			return state;
		},
	},
});

export const { setParticipantInformationState } = participantInformationSlice.actions;

export const selectParticipantInformationState = (state: RootState) => {
	return state.createParticipantReducers.participantsInformation;
};
