import styled, { css } from 'styled-components';
import { StyledIcon } from '../Button/styled';
import { Dropdown } from '../Dropdown';
import { DropdownItemListItem } from '../Dropdown/DropdownItemLabel/styled';
import { DropdownButton, DropdownContent } from '../Dropdown/styled';
import { StyledErrorProps } from './FormDropdown.types';

const errorStyle = css`
	border-color: ${({ theme }) => theme.colors.error.main};
	color: ${({ theme }) => theme.colors.error.main};
`;

export const StyledDropdown = styled(Dropdown)<StyledErrorProps>`
	${DropdownButton} {
		width: 100%;
		padding: 0.7rem;
		font-size: 1.1vw;
		line-height: 0.6rem;
		color: ${({ theme }) => theme.colors.text.primary};
		background-color: ${({ theme }) => theme.colors.common.lightGrey};
		height: 3.1em;
		border: 1px solid;
		border-radius: 5px;
		border-color: ${({ theme }) => theme.colors.common.black};

		${({ $error }) => $error && errorStyle};

		${StyledIcon} {
			margin-left: auto;
		}

		:disabled {
			color: ${({ theme }) => theme.colors.disabled.main};
			background-color: ${({ theme }) => theme.colors.disabled.light};
			border-color: ${({ theme }) => theme.colors.disabled.main};
		}
	}

	${DropdownContent} {
		top: 90%;
	}

	${DropdownItemListItem} {
		letter-spacing: 1px;
		min-height: 2.15rem;
	}
`;
