import styled from 'styled-components';
import { Button } from '../Button';
import { Modal } from '../Modal';

export const StyledModal = styled(Modal)`
	display: flex;
	flex-direction: column;
	border: 2px solid ${({ theme }) => theme.colors.primary.main};
	border-radius: 10px;
`;

export const ConfirmButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 0.5rem;
`;

export const StyledButton = styled(Button)`
	margin: 0.125rem;
	width: 5.625rem;
`;
