import { Tenant } from 'common/types';
import { emptyIfNullOrUndefined } from 'common/utils';

export class TenantDetailsExtractor {
	constructor(private _tenant: Tenant) {}

	getOrganizationAddress() {
		return {
			country: emptyIfNullOrUndefined(this._tenant?.country),
			firstAddressLine: emptyIfNullOrUndefined(this._tenant?.address?.line1),
			secondAddressLine: emptyIfNullOrUndefined(this._tenant?.address?.line2),
			city: emptyIfNullOrUndefined(this._tenant?.address?.cityOrState),
			postcodeZIP: emptyIfNullOrUndefined(this._tenant?.address?.zipCode),
		};
	}

	getStakeholderInformation() {
		return {
			projectStakeholderIMContact: emptyIfNullOrUndefined(this._tenant?.businessContact?.im),
			projectStakeholderTelephoneNumber: emptyIfNullOrUndefined(this._tenant?.businessContact?.tel),
			projectStakeholderMobileNumber: emptyIfNullOrUndefined(this._tenant?.businessContact?.mobile),
			projectStakeholderEmail: emptyIfNullOrUndefined(this._tenant?.businessContact?.email),
			projectStakeholderName: emptyIfNullOrUndefined(this._tenant?.businessContactName),
		};
	}

	getStakeholderContact() {
		return {
			secondaryContactIM: emptyIfNullOrUndefined(this._tenant?.hrContact?.im),
			secondaryContactMail: emptyIfNullOrUndefined(this._tenant?.hrContact?.email),
			secondaryContactMobile: emptyIfNullOrUndefined(this._tenant?.hrContact?.mobile),
			secondaryContactName: emptyIfNullOrUndefined(this._tenant?.hrContactName),
			secondaryContactTelephone: emptyIfNullOrUndefined(this._tenant?.hrContact?.tel),
		};
	}

	getBillingInformation() {
		return {
			vatNumber: emptyIfNullOrUndefined(this._tenant?.vatNumber),
			invoiceEmail: emptyIfNullOrUndefined(this._tenant?.billingInvoiceEmail),
			billingCountry: emptyIfNullOrUndefined(this._tenant?.billingCountry),
			billingEntity: emptyIfNullOrUndefined(this._tenant?.billingOrg),
		};
	}

	getBillingAddress() {
		return {
			firstBillingAddress: emptyIfNullOrUndefined(this._tenant?.billingAddress?.line1),
			secondBillingAddress: emptyIfNullOrUndefined(this._tenant?.billingAddress?.line2),
			billingCity: emptyIfNullOrUndefined(this._tenant?.billingAddress?.cityOrState),
			billingPostcode: emptyIfNullOrUndefined(this._tenant?.billingAddress?.zipCode),
		};
	}

	getBillingContract() {
		return {
			billingContactIM: emptyIfNullOrUndefined(this._tenant?.businessContact?.im),
			billingContactName: emptyIfNullOrUndefined(this._tenant?.billingContactName),
			billingContactEmail: emptyIfNullOrUndefined(this._tenant?.businessContact?.email),
			billingContactTelephone: emptyIfNullOrUndefined(this._tenant?.businessContact?.tel),
		};
	}

	getOrganisationInformation() {
		return {
			disableOrganisationId: true,
			organizationName: emptyIfNullOrUndefined(this._tenant?.name),
			organisationId: emptyIfNullOrUndefined(this._tenant?.shortId),
			purchaseOrderReference: emptyIfNullOrUndefined(this._tenant?.clientPOReference),
			supplierAgreementReference: emptyIfNullOrUndefined(this._tenant?.apdPOReference),
			telephoneNumberOnSwitchboard: emptyIfNullOrUndefined(this._tenant?.businessTel),
			projectSenderEmailAddress: emptyIfNullOrUndefined(this._tenant?.emailSenderAddress),
		};
	}
}
